import { useTranslation } from 'react-i18next';

import { Icon, Typography } from '@happypal-tech/design-system';
import { cn } from '@src/features-new/ui/cn';
import { Link } from '@tanstack/react-router';

type MobileTabBarSuspendedProps = {
  className?: string;
};

const mobileTabBarItemStyles =
  'flex flex-col flex-1 h-full gap-1 justify-center items-center text-grey transition-colors duration-300';
const mobileTabBarItemActiveStyles = 'text-primary font-bold';
const mobileTabBarItemInactiveStyles = 'font-semibold';
export const MobileTabBarSuspended = ({
  className,
}: MobileTabBarSuspendedProps) => {
  const { t } = useTranslation();

  return (
    <menu
      className={cn(
        'bg-white drop-shadow-md flex items-center justify-around',
        className,
      )}
    >
      <Link
        to="/suspended/hub"
        className={mobileTabBarItemStyles}
        activeProps={{
          className: mobileTabBarItemActiveStyles,
        }}
        inactiveProps={{
          className: mobileTabBarItemInactiveStyles,
        }}
      >
        {({ isActive }) => (
          <>
            <Icon
              name={
                isActive ? 'SpecialSimpleUserSolid' : 'SpecialSimpleUserOutline'
              }
              size={24}
            />
            <Typography type="legend">
              {t('navigation.menu.items.mobile.hub')}
            </Typography>
          </>
        )}
      </Link>
    </menu>
  );
};
