import { initReactI18next } from 'react-i18next';

import 'intl-pluralrules'; // Polyfill for old version of iOS
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import { env } from '@env';
import { format, isValid } from '@services/dateManager.service';
import dayjs from 'dayjs';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import RelativeTime from 'dayjs/plugin/relativeTime';
import Dinero from 'dinero.js';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

dayjs.extend(LocalizedFormat);
dayjs.extend(RelativeTime);
dayjs.extend(CustomParseFormat);
dayjs.extend(duration);

export enum LOCALES {
  FR = 'fr',
  EN = 'en',
}

export const fallbackLng = LOCALES.FR;

i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng,
    supportedLngs: Object.values(LOCALES),
    returnNull: false,
    defaultNS: 'translation',
    ns: ['translation', 'core', 'common', 'errors'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      skipOnVariables: false,
    },

    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      queryStringParams: env.REACT_APP_VERSION
        ? { v: env.REACT_APP_VERSION }
        : {},
    },

    detection: {
      order: ['localStorage', 'navigator'],
      lookupLocalStorage: 'i18nextLng',
    },

    react: {
      useSuspense: true,
    },
  })
  .then(() => {
    i18next.on('languageChanged', () => {
      document.documentElement.lang = i18next.language;
    });

    i18next.services.formatter?.add('date', (value, lng, options) => {
      const date = new Date(value);

      return isValid(date) ? format(date, options.format || 'L') : value;
    });

    i18next.services.formatter?.add('date_relative', (value) => {
      const date = dayjs(value).locale(i18next.language);

      return date.isValid() ? date.fromNow() : value;
    });

    i18next.services.formatter?.add(
      'currency',
      (value: Dinero.Dinero | number, lng, options) => {
        const dinero =
          typeof value === 'number'
            ? Dinero({ amount: value, currency: 'EUR' })
            : value;

        const formats = {
          short: '$0,0',
          long: '$0,0.00',
        };

        return dinero
          .setLocale(i18next.language)
          .toFormat(
            options.format in formats
              ? formats[options.format as keyof typeof formats]
              : '$0,0.00',
          );
      },
    );
  });

export default i18next;
