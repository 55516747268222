import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DetailsKeyPoints,
  DetailsKeyPointsProps,
} from '@src/components/molecules/DetailsKeyPoints/DetailsKeyPoints';
import { getOfferPictoInfos } from '@utils/offerUtils';
import { unique } from 'radash';

import { CampaignKeyPointsFragment } from './CampaignKeyPoints.generated';

type CampaignKeyPointsProps = {
  campaign: CampaignKeyPointsFragment;
  hasRemainingDiscountSubventions: boolean;
};

export function CampaignKeyPoints(props: CampaignKeyPointsProps) {
  const { campaign, hasRemainingDiscountSubventions } = props;
  const { t } = useTranslation();

  const tags = useMemo(() => {
    const tags: DetailsKeyPointsProps['tags'] = [];
    const uniqOfferTypes = unique(
      campaign.offersPagination.nodes.map((offer) => offer.__typename),
    );

    uniqOfferTypes.forEach((offerType) => {
      const offerPictoInfos = getOfferPictoInfos(offerType, t);

      tags.push({
        label: offerPictoInfos.t_key,
        type: offerPictoInfos.icon,
      });
    });

    if (hasRemainingDiscountSubventions) {
      tags.push({
        label: t('common:subsidized', 'Subventionnée'),
        type: 'SparksOutline',
      });
    }

    return tags;
  }, [campaign.offersPagination.nodes, hasRemainingDiscountSubventions, t]);

  return (
    <DetailsKeyPoints
      title={t('campaignDetails.details', "Détails de l'offre")}
      tags={tags}
    />
  );
}
