/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  BadgeStandalone,
  Checkbox,
  Typography,
} from '@happypal-tech/design-system';
import { checkCategoryState } from '@src/features-new/catalog/components/DropdownMenuCategories/DropdownMenuCategories';
import { Accordion } from '@src/features-new/design-system/components/atoms/Accordion/Accordion';
import { CategoryWithSubCategories } from '@src/routes/_authenticated-layout/catalog/index/route';
import { cx } from 'class-variance-authority';

interface AccordionCategoriesProps {
  activeCategories: string[];
  categories: CategoryWithSubCategories[];
  onSelectCategory: (
    id: string,
    nextStatus: checkCategoryState,
    parentCategoryId?: string,
  ) => void;
}

export const AccordionCategories = ({
  categories,
  activeCategories,
  onSelectCategory,
}: AccordionCategoriesProps) => {
  const { t } = useTranslation('catalog', {
    keyPrefix: 'components.CatalogSearchFilters',
  });
  const [categoryAccordionOpen, setCategoryAccordionOpen] = useState<string[]>(
    [],
  );

  const count = activeCategories.length;

  return (
    <Accordion.Item value="categories">
      <Accordion.Trigger
        className={cx(
          'group p-4',
          'data-[state=open]:text-neutral-darkest data-[state=closed]:text-neutral-dark',
        )}
        subClassName="w-full"
      >
        <Typography
          type="subtitle"
          semibold
          className={cx(
            'flex gap-2 items-center',
            'group-data-[state=open]:text-neutral-darkest group-data-[state=closed]:text-neutral-dark',
          )}
        >
          {t('categories')}
          <BadgeStandalone
            color="primary"
            showDot={count !== 0}
            title={t('categories')}
          />
        </Typography>
      </Accordion.Trigger>
      <div className="flex flex-col gap-2">
        {categories.map((category) => {
          const subCatSelectionned = category.subCategories.filter(
            (subCategory) => activeCategories.includes(subCategory.id),
          );
          const isActiveParentCategory =
            subCatSelectionned.length > 0
              ? 'indeterminate'
              : activeCategories.includes(category.id);
          const getCategoryStatus = () => {
            if (subCatSelectionned.length > 0) {
              return 'indeterminate';
            }
            if (
              isActiveParentCategory === 'indeterminate' ||
              isActiveParentCategory === false
            ) {
              return true;
            }
            return false;
          };

          const categoryStatus = getCategoryStatus();
          return (
            <Accordion.Content key={category.id} className="py-2 pl-3 mx-4">
              <Accordion
                type="multiple"
                value={categoryAccordionOpen}
                onValueChange={setCategoryAccordionOpen}
              >
                <Accordion.Item value={category.id}>
                  <>
                    <div className="flex items-center">
                      <Checkbox
                        onCheckedChange={() =>
                          onSelectCategory(category.id, categoryStatus)
                        }
                        checked={isActiveParentCategory}
                        key={category.id}
                        className={cx(
                          isActiveParentCategory === 'indeterminate'
                            ? 'bg-primary-lightest hover:bg-primary-lighter'
                            : undefined,
                          'w-full pl-3 pr-10 py-2 hover:bg-neutral-lightest focus:bg-neutral-lightest data-[state=checked]:bg-primary-lightest data-[state=checked]:hover:bg-primary-lighter data-[state=checked]:focus:bg-primary-lighter rounded-[0.625rem]',
                        )}
                        label={
                          <div className="flex gap-2 items-center">
                            {category.iconUrl && (
                              <img
                                src={category.iconUrl}
                                className="flex justify-center w-6 h-6 shrink-0 object-contain"
                                alt={category.name}
                              />
                            )}
                            <Typography type="body" semibold>
                              {category.name}
                            </Typography>
                          </div>
                        }
                      />
                      <Accordion.Trigger className="group ml-3" />
                    </div>
                    <div className="pt-3 ml-6">
                      {category.subCategories.map((subCategory) => (
                        <Accordion.Content key={subCategory.id}>
                          <Checkbox
                            key={subCategory.id}
                            checked={activeCategories.includes(subCategory.id)}
                            onCheckedChange={() =>
                              onSelectCategory(
                                subCategory.id,
                                !activeCategories.includes(subCategory.id),
                                category.id,
                              )
                            }
                            id={subCategory.id}
                            label={
                              <Typography type="body" semibold>
                                {subCategory.name}
                              </Typography>
                            }
                            className="p-2 mb-1 w-full hover:bg-neutral-lightest focus:bg-neutral-lightest data-[state=checked]:bg-primary-lightest data-[state=checked]:hover:bg-primary-lighter data-[state=checked]:focus:bg-primary-lighter rounded-[0.625rem]"
                          />
                        </Accordion.Content>
                      ))}
                    </div>
                  </>
                </Accordion.Item>
              </Accordion>
            </Accordion.Content>
          );
        })}
      </div>
    </Accordion.Item>
  );
};
