import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/_authenticated-layout/admin/company/customization/$',
)({
  loader: ({ context, navigate }) => {
    if (context.company?.features.blockUpdateCompany) {
      return navigate({ to: '/admin/company', replace: true });
    }
  },
});
