import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

export const Route = createFileRoute(
  '/_authenticated-layout/admin/happenings/preview/$happeningId',
)({
  validateSearch: z.object({
    redirect_status: z
      .string()
      .optional()
      .catch(() => undefined),
  }),
});
