import { createContext, Dispatch, SetStateAction, useContext } from 'react';

export type FloatingProviderContext = {
  floatingItemCount?: number;
  setFloatingItemCount?: Dispatch<SetStateAction<number>>;
};

export const FloatingProviderContext = createContext<FloatingProviderContext>({
  floatingItemCount: 0,
  setFloatingItemCount: () => undefined,
});

export const useFloatingContext = () => useContext(FloatingProviderContext);
