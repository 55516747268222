import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from '@happypal-tech/design-system';
import { BadgeFilter } from '@src/features-new/catalog/components/BadgeFilter';
import { DropdownElementGroup } from '@src/features-new/catalog/components/DropdownElement/Group';
import { DropdownElementLine } from '@src/features-new/catalog/components/DropdownElement/Line';
import { Accordion } from '@src/features-new/design-system/components/atoms/Accordion/Accordion';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@src/features-new/ui/DropdownMenu/DropdownMenu';

import { DropdownMenuCategoriesFragment } from './DropdownMenuCategories.generated';

interface DropdownMenuCategoriesProps {
  categories: (DropdownMenuCategoriesFragment & {
    subCategories: DropdownMenuCategoriesFragment[];
  })[];
  onSelect: (
    id: string,
    nextStatus: checkCategoryState,
    parentCategoryId?: string,
  ) => void;
  activeCategories: string[];
}

export type checkCategoryState = boolean | 'indeterminate';

export const DropdownMenuCategories = (props: DropdownMenuCategoriesProps) => {
  const { categories, onSelect, activeCategories } = props;
  const [accordionOpen, setAccordionOpen] = useState<string[]>([]);

  const { t } = useTranslation('catalog', {
    keyPrefix: 'components.CatalogSearchFilters',
  });

  const onDropdownChange = () => {
    const categoriesWithActiveSubCategories = categories.filter((category) =>
      category.subCategories.some((subCategory) =>
        activeCategories.includes(subCategory.id),
      ),
    );
    setAccordionOpen(
      categoriesWithActiveSubCategories.map((category) => category.id),
    );
  };

  return (
    <DropdownMenu onOpenChange={onDropdownChange}>
      <DropdownMenuTrigger className="group" asChild>
        <Button variant="stroke" type="button" color="neutral">
          <div className="flex gap-2 items-center">
            {t('categories')}
            <Icon
              name="ChevronUpOutline"
              size={16}
              className="transition-transform group-data-[state='closed']:-rotate-180"
            />
          </div>
          <BadgeFilter showDot={activeCategories.length !== 0} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-[26.25rem] max-h-[28rem] overflow-auto">
        <Accordion
          type="multiple"
          value={accordionOpen}
          onValueChange={setAccordionOpen}
        >
          {categories.map((category) => {
            const subCatSelectionned = category.subCategories.filter(
              (subCategory) => activeCategories.includes(subCategory.id),
            );
            const isActiveParentCategory =
              subCatSelectionned.length > 0
                ? 'indeterminate'
                : activeCategories.includes(category.id);
            const getCategoryStatus = () => {
              if (subCatSelectionned.length > 0) {
                return 'indeterminate';
              }
              if (
                isActiveParentCategory === 'indeterminate' ||
                isActiveParentCategory === false
              ) {
                return true;
              }
              return false;
            };

            const categoryStatus = getCategoryStatus();
            return (
              <DropdownElementGroup
                key={category.id}
                src={category.iconUrl ?? undefined}
                id={category.id}
                label={category.name}
                onSelect={() => onSelect(category.id, categoryStatus)}
                isActive={isActiveParentCategory}
                className={
                  isActiveParentCategory === 'indeterminate'
                    ? 'bg-primary-lightest hover:bg-primary-lighter focus:bg-neutral-lightest'
                    : undefined
                }
              >
                {category.subCategories.map((subCategory) => (
                  <DropdownElementLine
                    key={subCategory.id}
                    id={subCategory.id}
                    label={subCategory.name}
                    onSelect={() =>
                      onSelect(
                        subCategory.id,
                        !activeCategories.includes(subCategory.id),
                        category.id,
                      )
                    }
                    isActive={activeCategories.includes(subCategory.id)}
                  />
                ))}
              </DropdownElementGroup>
            );
          })}
        </Accordion>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
