import { cx } from 'class-variance-authority';

enum SeparatorOrientation {
  horizontal = 'horizontal',
  vertical = 'vertical',
}

type SeparatorProps = {
  top?: number;
  bottom?: number;
  orientation?: keyof typeof SeparatorOrientation;
  className?: string;
};

export const Separator = ({
  top = 6,
  bottom = 6,
  orientation = SeparatorOrientation.horizontal,
  className,
}: SeparatorProps) => {
  const spacings =
    orientation === SeparatorOrientation.horizontal
      ? `mt-${top} mb-${bottom}`
      : `ml-${top} mr-${bottom}`;
  return (
    <div
      className={cx(
        'bg-neutral-lighter',
        spacings,
        {
          'h-px': orientation === SeparatorOrientation.horizontal,
          'w-px': orientation === SeparatorOrientation.vertical,
        },
        className,
      )}
    />
  );
};
