// importcheck_strk_24from '@src/assets/icons/check_strk_24.svg?react';
import advantage_fill_24 from '@src/assets/icons/advantage_fill_24.svg?react';
import advantage_strk_24 from '@src/assets/icons/advantage_strk_24.svg?react';
import archive_strk_16 from '@src/assets/icons/archive_strk_16.svg?react';
import arrow_bottom_strk_16 from '@src/assets/icons/arrow-bottom_strk_16.svg?react';
import arrow_left_strk_16 from '@src/assets/icons/arrow-left_strk_16.svg?react';
import arrow_right_strk_16 from '@src/assets/icons/arrow-right_strk_16.svg?react';
import arrow_up_strk_16 from '@src/assets/icons/arrow-up_strk_16.svg?react';
import attachment_strk_16 from '@src/assets/icons/attachment_strk_16.svg?react';
import bank_strk_16 from '@src/assets/icons/bank_strk_16.svg?react';
import calendar_fill_24 from '@src/assets/icons/calendar_fill_24.svg?react';
import calendar_strk_1_16 from '@src/assets/icons/calendar_strk_1_16.svg?react';
import calendar_strk_2_16 from '@src/assets/icons/calendar_strk_2_16.svg?react';
import calendar_strk_24 from '@src/assets/icons/calendar_strk_24.svg?react';
import cart_strk_16 from '@src/assets/icons/cart_strk_16.svg?react';
import challenge_fill_24 from '@src/assets/icons/challenge_fill_24.svg?react';
import challenge_strk_24 from '@src/assets/icons/challenge_strk_24.svg?react';
import check_strk_12 from '@src/assets/icons/check_strk_12.svg?react';
import check_strk_16 from '@src/assets/icons/check_strk_16.svg?react';
import check_strk_48 from '@src/assets/icons/check_strk_48.svg?react';
import check_circle_fill_16 from '@src/assets/icons/check-circle_fill_16.svg?react';
import check_circle_strk_16 from '@src/assets/icons/check-circle_strk_16.svg?react';
import chevron_bottom_fill_16 from '@src/assets/icons/chevron-bottom_fill_16.svg?react';
import chevron_bottom_strk_12 from '@src/assets/icons/chevron-bottom_strk_12.svg?react';
import chevron_bottom_strk_16 from '@src/assets/icons/chevron-bottom_strk_16.svg?react';
import chevron_left_fill_16 from '@src/assets/icons/chevron-left_fill_16.svg?react';
import chevron_left_strk_12 from '@src/assets/icons/chevron-left_strk_12.svg?react';
import chevron_left_strk_16 from '@src/assets/icons/chevron-left_strk_16.svg?react';
import chevron_right_fill_16 from '@src/assets/icons/chevron-right_fill_16.svg?react';
import chevron_right_strk_12 from '@src/assets/icons/chevron-right_strk_12.svg?react';
import chevron_right_strk_16 from '@src/assets/icons/chevron-right_strk_16.svg?react';
import chevron_up_fill_16 from '@src/assets/icons/chevron-up_fill_16.svg?react';
import chevron_up_strk_12 from '@src/assets/icons/chevron-up_strk_12.svg?react';
import chevron_up_strk_16 from '@src/assets/icons/chevron-up_strk_16.svg?react';
// importchevron_up_down_medium_strk_16from '@src/assets/icons/chevron-up-down-medium_strk_16.svg?react';
import chevron_up_down_strk_16 from '@src/assets/icons/chevron-up-down_strk_16.svg?react';
import clock_fill_16 from '@src/assets/icons/clock_fill_16.svg?react';
import clock_strk_16 from '@src/assets/icons/clock_strk_16.svg?react';
import communication_fill_24 from '@src/assets/icons/communication_fill_24.svg?react';
import communication_strk_24 from '@src/assets/icons/communication_strk_24.svg?react';
import credit_card_strk_16 from '@src/assets/icons/credit-card_strk_16.svg?react';
import deal_fill_24 from '@src/assets/icons/deal_fill_24.svg?react';
import deal_strk_24 from '@src/assets/icons/deal_strk_24.svg?react';
import discount_strk_16 from '@src/assets/icons/discount_strk_16.svg?react';
import doc_strk_16 from '@src/assets/icons/doc_strk_16.svg?react';
// importdoc_strk_24from '@src/assets/icons/doc_strk_24.svg?react';
import download_strk_16 from '@src/assets/icons/download_strk_16.svg?react';
import edit_strk_1_16 from '@src/assets/icons/edit_strk_1_16.svg?react';
import edit_strk_2_16 from '@src/assets/icons/edit_strk_2_16.svg?react';
import edit_strk_3_16 from '@src/assets/icons/edit_strk_3_16.svg?react';
import ellipsis_horizontal_strk_16 from '@src/assets/icons/ellipsis_horizontal_strk_16.svg?react';
import ellipsis_vertical_strk_16 from '@src/assets/icons/ellipsis_vertical_strk_16.svg?react';
import export_strk_16 from '@src/assets/icons/export_strk_16.svg?react';
import eye_off_strk_16 from '@src/assets/icons/eye-off_strk_16.svg?react';
import eye_on_strk_16 from '@src/assets/icons/eye-on_strk_16.svg?react';
import facebook_fill_16 from '@src/assets/icons/facebook_fill_16.svg?react';
import feed_fill_24 from '@src/assets/icons/feed_fill_24.svg?react';
import feed_strk_24 from '@src/assets/icons/feed_strk_24.svg?react';
import file_duplicate_strk_16 from '@src/assets/icons/file-duplicate_strk_16.svg?react';
import filter_strk_16 from '@src/assets/icons/filter_strk_16.svg?react';
import folder_duplicate_strk_16 from '@src/assets/icons/folder-duplicate_strk_16.svg?react';
import forbidden_strk_16 from '@src/assets/icons/forbidden_strk_16.svg?react';
import game_fill_24 from '@src/assets/icons/game_fill_24.svg?react';
import game_strk_24 from '@src/assets/icons/game_strk_24.svg?react';
// importgear_strk_16from '@src/assets/icons/gear_strk_16.svg?react';
import geoloc_strk_16 from '@src/assets/icons/geoloc_strk_16.svg?react';
import gift_fill_24 from '@src/assets/icons/gift_fill_24.svg?react';
import gift_strk_24 from '@src/assets/icons/gift_strk_24.svg?react';
import grab_max_strk_16 from '@src/assets/icons/grab-max_strk_16.svg?react';
import grab_min_strk_16 from '@src/assets/icons/grab-min_strk_16.svg?react';
import grid_strk_1_16 from '@src/assets/icons/grid_strk_1_16.svg?react';
import grid_strk_2_16 from '@src/assets/icons/grid_strk_2_16.svg?react';
import grid_column_fill_24 from '@src/assets/icons/grid-column_fill_24.svg?react';
import grid_column_strk_24 from '@src/assets/icons/grid-column_strk_24.svg?react';
import heart_fill_16 from '@src/assets/icons/heart_fill_16.svg?react';
import heart_strk_16 from '@src/assets/icons/heart_strk_16.svg?react';
import home_fill_24 from '@src/assets/icons/home_fill_24.svg?react';
import home_strk_24 from '@src/assets/icons/home_strk_24.svg?react';
import infinite_strk_16 from '@src/assets/icons/infinite_strk_16.svg?react';
import info_circle_fill_16 from '@src/assets/icons/info-circle_fill_16.svg?react';
import info_circle_strk_16 from '@src/assets/icons/info-circle_strk_16.svg?react';
import invoice_strk_16 from '@src/assets/icons/invoice_strk_16.svg?react';
// importinvoice_strk_24from '@src/assets/icons/invoice_strk_24.svg?react';
import key_strk_16 from '@src/assets/icons/key_strk_16.svg?react';
import label_strk_16 from '@src/assets/icons/label_strk_16.svg?react';
import left_align_light_strk_16 from '@src/assets/icons/left-align-light_strk_16.svg?react';
import link_strk_16 from '@src/assets/icons/link_strk_16.svg?react';
import linkedin_fill_16 from '@src/assets/icons/linkedin_fill_16.svg?react';
import list_strk_16 from '@src/assets/icons/list_strk_16.svg?react';
import loader_strk_16 from '@src/assets/icons/loader_strk_16.svg?react';
import lock_strk_16 from '@src/assets/icons/lock_strk_16.svg?react';
import mail_strk_16 from '@src/assets/icons/mail_strk_16.svg?react';
import maximize_strk_1_16 from '@src/assets/icons/maximize_strk_1_16.svg?react';
import maximize_strk_2_16 from '@src/assets/icons/maximize_strk_2_16.svg?react';
import minimize_strk_1_16 from '@src/assets/icons/minimize_strk_1_16.svg?react';
import minimize_strk_2_16 from '@src/assets/icons/minimize_strk_2_16.svg?react';
import minus_strk_12 from '@src/assets/icons/minus_strk_12.svg?react';
import minus_strk_16 from '@src/assets/icons/minus_strk_16.svg?react';
import minus_circle_fill_16 from '@src/assets/icons/minus-circle_fill_16.svg?react';
import minus_circle_strk_16 from '@src/assets/icons/minus-circle_strk_16.svg?react';
// importminus_strk_16from '@src/assets/icons/minus_strk_16.svg?react';
import newspaper_fill_24 from '@src/assets/icons/newspaper_fill_24.svg?react';
import newspaper_strk_24 from '@src/assets/icons/newspaper_strk_24.svg?react';
import notif_strk_16 from '@src/assets/icons/notif_strk_16.svg?react';
import notification_strk_24 from '@src/assets/icons/notification_strk_24.svg?react';
import paint_strk_16 from '@src/assets/icons/paint_strk_16.svg?react';
import pending_strk_16 from '@src/assets/icons/pending_strk_16.svg?react';
import phone_oncall_strk_16 from '@src/assets/icons/phone-oncall_strk_16.svg?react';
import picture_strk_16 from '@src/assets/icons/picture_strk_16.svg?react';
import pin_strk_16 from '@src/assets/icons/pin_strk_16.svg?react';
import plus_strk_12 from '@src/assets/icons/plus_strk_12.svg?react';
import plus_circle_fill_16 from '@src/assets/icons/plus-circle_fill_16.svg?react';
import plus_circle_strk_16 from '@src/assets/icons/plus-circle_strk_16.svg?react';
import power_strk_16 from '@src/assets/icons/power_strk_16.svg?react';
import question_strk_16 from '@src/assets/icons/question_strk_16.svg?react';
import question_circle_fill_16 from '@src/assets/icons/question-circle_fill_16.svg?react';
import question_circle_strk_16 from '@src/assets/icons/question-circle_strk_16.svg?react';
import refresh_strk_16 from '@src/assets/icons/refresh_strk_16.svg?react';
import refund_strk_1_16 from '@src/assets/icons/refund_strk_1_16.svg?react';
import refund_strk_2_16 from '@src/assets/icons/refund_strk_2_16.svg?react';
import search_fill_24 from '@src/assets/icons/search_fill_24.svg?react';
// importsearch_strk_16from '@src/assets/icons/search_strk_16.svg?react';
import search_strk_24 from '@src/assets/icons/search_strk_24.svg?react';
import share_strk_16 from '@src/assets/icons/share_strk_16.svg?react';
import sparks_strk_16 from '@src/assets/icons/sparks_strk_16.svg?react';
import star_fill_16 from '@src/assets/icons/star_fill_16.svg?react';
import star_strk_16 from '@src/assets/icons/star_strk_16.svg?react';
import subvention_fill_24 from '@src/assets/icons/subvention_fill_24.svg?react';
import subvention_strk_24 from '@src/assets/icons/subvention_strk_24.svg?react';
import suitcase_fill_24 from '@src/assets/icons/suitcase_fill_24.svg?react';
import suitcase_strk_16 from '@src/assets/icons/suitcase_strk_16.svg?react';
import suitcase_strk_24 from '@src/assets/icons/suitcase_strk_24.svg?react';
import sync_strk_16 from '@src/assets/icons/sync_strk_16.svg?react';
import thumb_down_strk_16 from '@src/assets/icons/thumb-down_strk_16.svg?react';
import thumb_up_strk_16 from '@src/assets/icons/thumb-up_strk_16.svg?react';
import ticket_strk_16 from '@src/assets/icons/ticket_strk_16.svg?react';
import trash_strk_16 from '@src/assets/icons/trash_strk_16.svg?react';
import undo_strk_16 from '@src/assets/icons/undo_strk_16.svg?react';
import unlock_strk_16 from '@src/assets/icons/unlock_strk_16.svg?react';
import upload_strk_16 from '@src/assets/icons/upload_strk_16.svg?react';
import user_fill_1_24 from '@src/assets/icons/user_fill_1_24.svg?react';
import user_strk_1_16 from '@src/assets/icons/user_strk_1_16.svg?react';
import user_strk_2_16 from '@src/assets/icons/user_strk_2_16.svg?react';
import users_fill_1_24 from '@src/assets/icons/users_fill_1_24.svg?react';
import users_strk_1_24 from '@src/assets/icons/users_strk_1_24.svg?react';
import users_strk_2_16 from '@src/assets/icons/users_strk_2_16.svg?react';
import warning_circle_fill_16 from '@src/assets/icons/warning-circle_fill_16.svg?react';
import warning_circle_strk_16 from '@src/assets/icons/warning-circle_strk_16.svg?react';
import warning_triangle_fill_16 from '@src/assets/icons/warning-triangle_fill_16.svg?react';
import warning_triangle_strk_16 from '@src/assets/icons/warning-triangle_strk_16.svg?react';
import without_contact_strk_16 from '@src/assets/icons/without-contact_strk_16.svg?react';
import x_strk_12 from '@src/assets/icons/x_strk_12.svg?react';
import x_strk_16 from '@src/assets/icons/x_strk_16.svg?react';
import x_circle_fill_16 from '@src/assets/icons/x-circle_fill_16.svg?react';
import x_circle_strk_16 from '@src/assets/icons/x-circle_strk_16.svg?react';

export const icons = {
  advantage: {
    default: advantage_strk_24,
    fill: advantage_fill_24,
  },
  archive: {
    default: archive_strk_16,
  },
  arrowBottom: {
    default: arrow_bottom_strk_16,
  },
  arrowLeft: {
    default: arrow_left_strk_16,
  },
  arrowRight: {
    default: arrow_right_strk_16,
  },
  arrowUp: {
    default: arrow_up_strk_16,
  },
  attachment: {
    default: attachment_strk_16,
  },
  bank: {
    default: bank_strk_16,
  },
  calendar: {
    default: calendar_strk_24,
    fill: calendar_fill_24,
    v1: calendar_strk_1_16,
    v2: calendar_strk_2_16,
  },
  cart: {
    default: cart_strk_16,
  },
  challenge: {
    default: challenge_strk_24,
    fill: challenge_fill_24,
  },
  checkCircle: {
    default: check_circle_strk_16,
    fill: check_circle_fill_16,
  },
  check: {
    default: check_strk_16,
    '12': check_strk_12,
    '48': check_strk_48,
  },
  chevronBottom: {
    fill: chevron_bottom_fill_16,
    default: chevron_bottom_strk_16,
    '12': chevron_bottom_strk_12,
  },
  chevronLeft: {
    fill: chevron_left_fill_16,
    default: chevron_left_strk_16,
    '12': chevron_left_strk_12,
  },
  chevronRight: {
    fill: chevron_right_fill_16,
    default: chevron_right_strk_16,
    '12': chevron_right_strk_12,
  },
  chevronUp: {
    fill: chevron_up_fill_16,
    default: chevron_up_strk_16,
    '12': chevron_up_strk_12,
  },
  chevronUpDown: {
    default: chevron_up_down_strk_16,
  },
  clock: {
    fill: clock_fill_16,
    default: clock_strk_16,
  },
  communication: {
    fill: communication_fill_24,
    default: communication_strk_24,
  },
  creditCard: {
    default: credit_card_strk_16,
  },
  deal: {
    fill: deal_fill_24,
    default: deal_strk_24,
  },
  document: {
    default: doc_strk_16,
  },
  discount: {
    default: discount_strk_16,
  },
  download: {
    default: download_strk_16,
  },
  edit: {
    default: edit_strk_1_16,
    v2: edit_strk_2_16,
    v3: edit_strk_3_16,
  },
  ellipsisVertical: {
    default: ellipsis_vertical_strk_16,
  },
  ellipsisHorizontal: {
    default: ellipsis_horizontal_strk_16,
  },
  export: {
    default: export_strk_16,
  },
  eyeOff: {
    default: eye_off_strk_16,
  },
  eyeOn: {
    default: eye_on_strk_16,
  },
  facebook: {
    default: facebook_fill_16,
  },
  feed: {
    default: feed_strk_24,
    fill: feed_fill_24,
  },
  fileDuplicate: {
    default: file_duplicate_strk_16,
  },
  filter: {
    default: filter_strk_16,
  },
  folderDuplicate: {
    default: folder_duplicate_strk_16,
  },
  forbidden: {
    default: forbidden_strk_16,
  },
  game: {
    default: game_strk_24,
    fill: game_fill_24,
  },
  geoloc: {
    default: geoloc_strk_16,
  },
  gift: {
    default: gift_strk_24,
    fill: gift_fill_24,
  },
  grabMax: {
    default: grab_max_strk_16,
  },
  grabMin: {
    default: grab_min_strk_16,
  },
  gridColumn: {
    default: grid_column_strk_24,
    fill: grid_column_fill_24,
  },
  grid: {
    default: grid_strk_1_16,
    v2: grid_strk_2_16,
  },
  heart: {
    fill: heart_fill_16,
    default: heart_strk_16,
  },
  home: {
    fill: home_fill_24,
    default: home_strk_24,
  },
  infinite: {
    default: infinite_strk_16,
  },
  infoCircle: {
    fill: info_circle_fill_16,
    default: info_circle_strk_16,
  },
  invoice: {
    default: invoice_strk_16,
  },
  key: {
    default: key_strk_16,
  },
  label: {
    default: label_strk_16,
  },
  leftAlign: { default: left_align_light_strk_16 },
  link: {
    default: link_strk_16,
  },
  linkedin: {
    default: linkedin_fill_16,
  },
  list: {
    default: list_strk_16,
  },
  loader: {
    default: loader_strk_16,
  },
  lock: {
    default: lock_strk_16,
  },
  mail: {
    default: mail_strk_16,
  },
  maximize: {
    default: maximize_strk_1_16,
    bis: maximize_strk_2_16,
  },
  minimize: {
    default: minimize_strk_1_16,
    bis: minimize_strk_2_16,
  },
  minusCircle: {
    default: minus_circle_strk_16,
    fill: minus_circle_fill_16,
  },
  minus: {
    default: minus_strk_16,
    '12': minus_strk_12,
  },
  newspaper: {
    default: newspaper_strk_24,
    fill: newspaper_fill_24,
  },
  notif: {
    default: notif_strk_16,
  },
  notification: {
    default: notification_strk_24,
  },
  paint: {
    default: paint_strk_16,
  },
  pending: {
    default: pending_strk_16,
  },
  phoneOncall: {
    default: phone_oncall_strk_16,
  },
  picture: {
    default: picture_strk_16,
  },
  pin: {
    default: pin_strk_16,
  },
  plusCircle: {
    default: plus_circle_strk_16,
    fill: plus_circle_fill_16,
  },
  plus: {
    default: plus_strk_12,
  },
  power: {
    default: power_strk_16,
  },
  questionCircle: {
    default: question_circle_strk_16,
    fill: question_circle_fill_16,
  },
  question: {
    default: question_strk_16,
  },
  refresh: {
    default: refresh_strk_16,
  },
  refund: {
    default: refund_strk_1_16,
    v2: refund_strk_2_16,
  },
  search: {
    default: search_strk_24,
    fill: search_fill_24,
  },
  share: {
    default: share_strk_16,
  },
  sparks: {
    default: sparks_strk_16,
  },
  star: {
    default: star_strk_16,
    fill: star_fill_16,
  },
  subvention: {
    default: subvention_strk_24,
    fill: subvention_fill_24,
  },
  suitcase: {
    default: suitcase_strk_24,
    fill: suitcase_fill_24,
    '16': suitcase_strk_16,
  },
  sync: {
    default: sync_strk_16,
  },
  thumbDown: {
    default: thumb_down_strk_16,
  },
  thumbUp: {
    default: thumb_up_strk_16,
  },
  ticket: {
    default: ticket_strk_16,
  },
  trash: {
    default: trash_strk_16,
  },
  undo: {
    default: undo_strk_16,
  },
  unlock: { default: unlock_strk_16 },
  upload: { default: upload_strk_16 },
  user: {
    default: user_strk_1_16,
    fill: user_fill_1_24,
    v2: user_strk_2_16,
  },
  users: {
    default: users_strk_1_24,
    fill: users_fill_1_24,
    v2: users_strk_2_16,
  },
  warningCircle: {
    default: warning_circle_strk_16,
    fill: warning_circle_fill_16,
  },
  warningTriangle: {
    default: warning_triangle_strk_16,
    fill: warning_triangle_fill_16,
  },
  withoutContact: {
    default: without_contact_strk_16,
  },
  xCircle: {
    default: x_circle_strk_16,
    fill: x_circle_fill_16,
  },
  x: {
    default: x_strk_16,
    '12': x_strk_12,
  },
};
