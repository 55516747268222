import { useId, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  filterDiacritics,
  InputCombobox,
  InputComboboxItem,
  InputSelect,
  Typography,
  useDebounce,
} from '@happypal-tech/design-system';
import { Card } from '@src/components/atoms/Card';
import { Icon } from '@src/components/atoms/Icon';
import { SwitchAroundMe } from '@src/features-new/catalog/components/FilterGeo/SwitchAroundMe/SwitchAroundMe';
import { GeoRadiusInput } from '@src/features-new/catalog/components/ui/GeoRadiusInput/GeoRadiusInput';
import { useAnalytics } from '@src/hooks/use-analytics';
import { coordsToString, stringToCoords } from '@src/utils/geoloc';
import regions from 'src/routes/_authenticated-layout/catalog/index/provinces.compressed.json';

export const DEFAULT_RADIUS = 50;
const MAX_RADIUS = 100;
const MIN_RADIUS = 1;

const provinces = regions.map((item) => {
  return { label: item.n, value: item.c };
});

interface FilterGeoProps {
  value?: {
    lat?: number;
    lng?: number;
    radius?: number;
    province?: string;
  } | null;
  onChange: (
    value: {
      lat?: number;
      lng?: number;
      radius?: number;
      province?: string;
    } | null,
  ) => void;
}

export const FilterGeo = (props: FilterGeoProps) => {
  const { value, onChange } = props;
  const { track } = useAnalytics();
  const { t } = useTranslation('catalog', {
    keyPrefix: 'components.Geo.FilterGeo',
  });

  const [localRadius, setLocalRadius] = useState<number | null>(
    value?.radius ?? DEFAULT_RADIUS,
  );
  const currentProvinceItem =
    value?.province && regions.find((item) => item.c === value.province);
  const [search, setSearch] = useState<string>(
    currentProvinceItem ? currentProvinceItem.n : '',
  );

  const switchAroundMeId = useId();

  const handleProvinceChange = (provinceItem: InputComboboxItem | null) => {
    if (!provinceItem) {
      setSearch('');
      onChange(null);
      return;
    }
    setSearch(provinceItem.label);

    onChange({
      lat: undefined,
      lng: undefined,
      radius: undefined,
      province: provinceItem.value,
    });
  };

  const handleSelectProvinceChange = (province: string) => {
    if (!province) {
      onChange(null);
      return;
    }

    onChange({
      lat: undefined,
      lng: undefined,
      radius: undefined,
      province,
    });
  };

  const handleGeoChange = (newValue: string | null) => {
    if (newValue === null) {
      onChange(null);
      return;
    }

    setSearch('');

    track({
      type: 'click catalog button around me',
    });

    const [lat, lng] = stringToCoords(newValue).map(parseFloat);

    onChange({
      lat: lat!,
      lng: lng!,
      radius: value?.radius ?? DEFAULT_RADIUS,
      province: undefined,
    });
  };

  const onRadiusChange = (radiusValue: number | null) => {
    if (radiusValue) {
      onChange({
        lat: value!.lat,
        lng: value!.lng,
        radius: radiusValue,
        province: undefined,
      });
    }
  };

  const debouncedRadiusChange = useDebounce(onRadiusChange);

  const handleRadiusChange = (value: number | null) => {
    setLocalRadius(value);
    debouncedRadiusChange(value);
  };

  const aroundMeEnabled = value && 'lat' in value && 'lng' in value;

  return (
    <div className="flex flex-col gap-2">
      <div>
        <Typography type="body" bold className="text-sm mb-2">
          {t('referenceRegions')}
        </Typography>
        <div className="hidden md:block">
          <InputCombobox
            placeholder={t('placeholder')}
            value={
              currentProvinceItem
                ? {
                    label: currentProvinceItem.n,
                    value: currentProvinceItem.c,
                  }
                : undefined
            }
            onChange={handleProvinceChange}
            items={filterDiacritics(provinces, 'label', search)}
            prepend={
              <Icon name="geoloc" size="small" className="text-neutral-dark" />
            }
            onSearch={(search) => {
              setSearch(search);
            }}
            search={search}
            popoverClassName="z-modal"
            emptyText={t('noResults')}
          />
        </div>
        <div className="md:hidden">
          <InputSelect
            placeholder={t('placeholder')}
            value={currentProvinceItem ? currentProvinceItem.c : ''}
            onChange={handleSelectProvinceChange}
            options={provinces}
            className="z-modal"
          />
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div className="border-t w-full" />
        <Typography type="body" className="mx-4 text-neutral-dark">
          {t('or')}
        </Typography>
        <div className="border-t w-full" />
      </div>
      <SwitchAroundMe
        id={switchAroundMeId}
        value={
          value && value.lat && value.lng
            ? coordsToString([value.lat, value.lng])
            : null
        }
        onChange={handleGeoChange}
      />
      {!!value && aroundMeEnabled && (
        <Card bgColor="grey-light" className="p-4">
          <GeoRadiusInput
            value={localRadius}
            onChange={handleRadiusChange}
            max={MAX_RADIUS}
            min={MIN_RADIUS}
          />
        </Card>
      )}
    </div>
  );
};
