/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect } from 'react';

import { env } from '@env';
import satismeter from 'satismeter-loader';

import { SatismeterViewerFragment } from './satismeter.generated';

type SatismeterProps = {
  viewer: SatismeterViewerFragment;
};

export const Satismeter = (props: SatismeterProps) => {
  const { viewer } = props;

  if (!env.REACT_APP_SATISMETER_KEY) return null;

  useEffect(() => {
    satismeter({
      writeKey: env.REACT_APP_SATISMETER_KEY,
      userId: viewer.id,
      traits: {
        email: viewer.email,
        firstName: viewer.firstName,
        lastName: viewer.lastName,
        userIsAdminOfCompany: viewer.company?.viewerIsAdmin,
        companyId: viewer.company?.id,
        companySlug: viewer.company?.slug,
        companyName: viewer.company?.name,
        createdAt: viewer.createdAt.toISOString(),
        firstLoginAt: viewer.firstLoginAt?.toISOString(),
      },
    });
  }, [viewer]);

  return null;
};
