import { Loader } from '@src/components/atoms/Loader';
import {
  RegisterPageDocument,
  RegisterPageQuery,
  RegisterPageQueryVariables,
} from '@src/routes/auth/claim-invitation/~route.generated';
import { createFileRoute, notFound } from '@tanstack/react-router';
import { z } from 'zod';

export const Route = createFileRoute('/auth/claim-invitation')({
  validateSearch: z.object({
    slug: z
      .string()
      .optional()
      .catch(() => undefined),
    email: z
      .string()
      .email()
      .optional()
      .catch(() => undefined),
    token: z
      .string()
      .optional()
      .catch(() => undefined),
  }),
  loaderDeps: ({ search }) => {
    const { slug } = search;
    return { slug };
  },
  loader: async ({ deps, context }) => {
    const { slug } = deps;

    if (!slug) {
      throw notFound();
    }
    const { data } = await context.apolloClient.query<
      RegisterPageQuery,
      RegisterPageQueryVariables
    >({
      query: RegisterPageDocument,
      variables: {
        slug: slug!,
      },
    });

    const companyPublic = data.companyPublic;

    return { companyPublic };
  },
  pendingComponent: () => <Loader />,
});
