import { ComponentProps, useId, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Icon,
  Spin,
  Switch,
  toast,
  Typography,
} from '@happypal-tech/design-system';
import { coordsToString, getCurrentPosition } from '@src/utils/geoloc';
import { cx } from 'class-variance-authority';

import './SwitchAroundMe.css';

interface SwitchAroundMeSpecific {
  value: string | null;
  onChange: (value: string | null) => void;
}

interface SwitchAroundMeProps
  extends Omit<ComponentProps<typeof Switch>, keyof SwitchAroundMeSpecific>,
    SwitchAroundMeSpecific {}
export const SwitchAroundMe = ({
  value,
  onChange,
  id,
  ...props
}: SwitchAroundMeProps) => {
  const [isLoadingGeoloc, setLoadingGeoloc] = useState(false);

  const internalSwitchAroundMeId = useId();
  const switchAroundMeId = id ?? internalSwitchAroundMeId;

  const { t } = useTranslation('catalog', {
    keyPrefix: 'components.Geo.CatalogProvincesCombobox',
  });

  const handleGeolocError = () => toast.error(t('error'));

  const onSwitchChange = async (checked: boolean) => {
    if (!checked) {
      return onChange(null);
    }
    try {
      setLoadingGeoloc(true);
      const geo = await getCurrentPosition();
      setLoadingGeoloc(false);
      return onChange(
        coordsToString([geo.coords.latitude, geo.coords.longitude]),
      );
    } catch {
      setLoadingGeoloc(false);
      handleGeolocError();
    }
  };

  const switchValue = value !== null;
  const isActive = switchValue || isLoadingGeoloc;
  return (
    <div className="flex flex-col items-start gap-2">
      <div
        className={cx(
          'flex items-center w-full gap-x-2 group rounded-xl py-2.5 px-4 text-neutral-dark  hover:text-neutral-darkest focus:text-neutral-darkest',
          {
            'around-me-switch--active': isActive,
          },
          {
            'shadow-border hover:shadow-neutral-dark': !isActive,
          },
        )}
      >
        <Icon
          size={16}
          name="TargetOutline"
          className={cx({ 'text-primary': isActive })}
        />
        <Typography className="grow" type="body" asChild>
          <label htmlFor={switchAroundMeId}>{t('aroundMe')}</label>
        </Typography>
        {isLoadingGeoloc && <Spin />}
        <Switch
          value={switchValue}
          onChange={onSwitchChange}
          className={cx('group-hover:bg-neutral', {
            'group-hover:bg-primary': switchValue,
          })}
          id={switchAroundMeId}
          {...props}
        />
      </div>

      <Typography className="flex gap-2 text-neutral-dark" type="note">
        <Icon size={16} name="InfoCircleOutline" />
        {t('hint')}
      </Typography>
    </div>
  );
};
