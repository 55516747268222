import { ValuesFromZ } from '@happypal-tech/design-system';
import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

const validateSearch = z.object({
  subventionId: z.string().optional(),
  reimbursementId: z.string().optional(),
  drawerName: z
    .enum(['detail', 'reimbursementFunnel', 'reimbursementDetail'])
    .optional(),
  drawerTab: z.enum(['reimbursement']).optional(),
});

export type AdvantageRouteSearch = ValuesFromZ<typeof validateSearch>;

export const Route = createFileRoute('/_authenticated-layout/advantages/')({
  validateSearch,
});
