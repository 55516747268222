import {
  ElementRef,
  FocusEvent,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Button, useBreakpoint } from '@happypal-tech/design-system';
import { replaceUnwantedChars } from '@src/features-new/catalog/utils/replaceUnwantedChars';
import { ButtonResetSuggestions } from '@src/features-new/core/CatalogSearchBar/ButtonResetSuggestions';
import { InstantSearchWrapperSuggestions } from '@src/features-new/core/CatalogSearchBar/InstantSearchWrapperSuggestions';
import { CatalogSearchBarSuggestionsCombobox } from '@src/features-new/core/CatalogSearchBar/SuggestionsCombobox';
import { SuggestionsDrawer } from '@src/features-new/core/CatalogSearchBar/SuggestionsDrawer';
import { useCatalogSearch } from '@src/features-new/core/CatalogSearchBar/useCatalogSearch';
import { cn } from '@src/features-new/ui/cn';
import { useAnalytics } from '@src/hooks/use-analytics';
import { useNavigate } from '@tanstack/react-router';
import { cx } from 'class-variance-authority';

type CatalogSearchBarProps = {
  className?: string;
  alwaysShowIcon?: boolean;
  alwaysShowInput?: boolean;
  hideSearchButton?: boolean;
};

const prepareForRefine = (query: string) => {
  const cleanQuery = replaceUnwantedChars(query);
  return cleanQuery.trim();
};
export function CatalogSearchBar(props: CatalogSearchBarProps) {
  const { className, alwaysShowIcon, alwaysShowInput, hideSearchButton } =
    props;
  const { track } = useAnalytics();
  const navigate = useNavigate();

  const [value, setValue, refine] = useCatalogSearch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isHigherThanMobile = useBreakpoint('tablet');

  const [showMobileInput, setShowMobileInput] = useState(alwaysShowInput);
  const hideMobileInput = () => {
    if (alwaysShowInput) return;
    setShowMobileInput(false);
  };

  const searchBarRef = useRef<HTMLInputElement>(null);
  const clearButtonRef = useRef<ElementRef<typeof Button>>(null);
  const searchButtonRef = useRef<ElementRef<typeof Button>>(null);

  const { t } = useTranslation('core', {
    keyPrefix: 'components.CatalogSearchBar',
  });
  const onMobileSearchButtonClick = () => {
    setShowMobileInput(true);
    searchBarRef.current?.focus();
  };

  const handleSearchClick = () => {
    const cleanSearch = prepareForRefine(value);
    if (cleanSearch !== '') {
      track({
        type: 'complete catalog search',
        payload: {
          click_all_results: false,
          click_enter_key: false,
          click_submit_button: true,
          click_suggestions: false,
          search_keyword: cleanSearch,
          search_output: 'click',
        },
      });
    }

    refine(cleanSearch);

    searchBarRef.current?.focus();
  };

  const handleClearClick = () => {
    setValue('');
    refine('');
    searchBarRef.current?.focus();
  };

  const handleBlur = (e?: FocusEvent<HTMLInputElement>) => {
    const closestAncestorButton = e?.relatedTarget?.closest('button');
    if (
      closestAncestorButton === clearButtonRef.current ||
      closestAncestorButton === searchButtonRef.current
    ) {
      e?.preventDefault();
      return;
    }
    return hideMobileInput();
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
      case 'Enter': {
        const cleanSearch = prepareForRefine(event.currentTarget.value);
        if (cleanSearch !== '') {
          track({
            type: 'complete catalog search',
            payload: {
              click_all_results: false,
              click_enter_key: true,
              click_submit_button: false,
              click_suggestions: false,
              search_keyword: cleanSearch,
              search_output: 'enter',
            },
          });
        }

        return refine(cleanSearch);
      }
      case 'Escape': {
        if (isMenuOpen) {
          // already handled
          return;
        }
        return searchBarRef.current?.blur();
      }
    }
  };

  const onSearch = (nextSearch: string) => {
    setValue(nextSearch);
  };

  const onExploreCampaign = () => {
    const cleanSearch = prepareForRefine(value);
    track({
      type: 'complete catalog search',
      payload: {
        click_all_results: false,
        click_enter_key: false,
        click_submit_button: false,
        click_suggestions: true,
        search_keyword: cleanSearch,
        search_output: 'click',
      },
    });
  };

  const onClickCompleteSearch = (query: string) => {
    const cleanSearch = prepareForRefine(query);
    track({
      type: 'complete catalog search',
      payload: {
        click_all_results: true,
        click_enter_key: false,
        click_submit_button: false,
        click_suggestions: false,
        search_keyword: cleanSearch,
        search_output: 'click',
      },
    });
    return refine(cleanSearch);
  };

  const onClickSeeAll = () => {
    track({
      type: 'click all results no suggestions',
    });
    return navigate({
      to: '/catalog',
    });
  };

  useEffect(() => {
    // close menu when responsive changes, fixes layout issues
    setIsMenuOpen(false);
  }, [isHigherThanMobile]);

  return (
    <InstantSearchWrapperSuggestions>
      <div className={cn('flex-1 flex justify-between', className)}>
        {isHigherThanMobile ? (
          <CatalogSearchBarSuggestionsCombobox
            ref={searchBarRef}
            className={cn(
              'hidden md:flex',
              '@container overflow-hidden transition-width',
              'sm:w-full',
              showMobileInput ? 'w-full' : 'w-0',
            )}
            isMenuOpen={isMenuOpen}
            search={value}
            onSearch={onSearch}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            onOpenChange={setIsMenuOpen}
            onExploreCampaign={onExploreCampaign}
            onClickCompleteSearch={onClickCompleteSearch}
            onClickSeeAll={onClickSeeAll}
            placeholder={t('placeholder')}
            append={
              value === '' && hideSearchButton ? null : (
                <div className="flex items-center gap-1 -mr-2">
                  <ButtonResetSuggestions
                    ref={clearButtonRef}
                    className={cx(value === '' && 'hidden')}
                    icon="CrossOutline"
                    variant="ghost"
                    size="small"
                    color="neutral"
                    aria-label={t('clear')}
                    onClick={handleClearClick}
                  />
                  {!hideSearchButton && (
                    <Button
                      ref={searchButtonRef}
                      className="hidden @sm:inline-flex"
                      icon="SearchOutline"
                      variant="plain"
                      color="primary"
                      size="small"
                      aria-label={t('search')}
                      onClick={handleSearchClick}
                    />
                  )}
                </div>
              )
            }
          />
        ) : (
          <SuggestionsDrawer
            ref={searchBarRef}
            className={cn(
              '@container overflow-hidden transition-width',
              'sm:w-full',
              showMobileInput ? 'w-full' : 'w-0',
            )}
            opened={isMenuOpen}
            onOpenChange={setIsMenuOpen}
            search={value}
            onSearch={onSearch}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            onExploreCampaign={onExploreCampaign}
            onClickCompleteSearch={onClickCompleteSearch}
            onClickSeeAll={onClickSeeAll}
            placeholder={t('placeholder')}
            append={
              value === '' && hideSearchButton ? null : (
                <div className="flex items-center gap-1 -mr-2">
                  <ButtonResetSuggestions
                    ref={clearButtonRef}
                    className={cx(value === '' && 'hidden')}
                    icon="CrossOutline"
                    variant="ghost"
                    size="small"
                    color="neutral"
                    aria-label={t('clear')}
                    onClick={handleClearClick}
                  />
                  {!hideSearchButton && (
                    <Button
                      ref={searchButtonRef}
                      className="hidden @sm:inline-flex"
                      icon="SearchOutline"
                      variant="plain"
                      color="primary"
                      size="small"
                      aria-label={t('search')}
                      onClick={handleSearchClick}
                    />
                  )}
                </div>
              )
            }
          />
        )}

        <Button
          className={cn(
            'sm:hidden',
            showMobileInput && !alwaysShowIcon && 'hidden',
          )}
          icon="SearchOutline"
          variant="ghost"
          color="neutral"
          size="small"
          aria-label={t('search')}
          onClick={onMobileSearchButtonClick}
        />
      </div>
    </InstantSearchWrapperSuggestions>
  );
}
