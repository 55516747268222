/* eslint-disable @typescript-eslint/no-explicit-any */
import { Typography } from '@happypal-tech/design-system';
import { type Cell, type Column, flexRender } from '@tanstack/react-table';

import { Skeleton } from '../../../feedback/Skeleton/Skeleton';
import { COLUMN_SELECTION_ID } from '../Table.constants';

import { TableCell } from './TableCell';

interface TableBodyCellProps {
  cell: Cell<any, any>;
}

export const TableBodyCell = (props: TableBodyCellProps) => {
  const { cell } = props;

  return (
    <TableCell column={cell.column} className="px-3 py-2">
      <Typography
        type="body"
        className="w-full overflow-hidden text-ellipsis text-neutral-darkest"
      >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </Typography>
    </TableCell>
  );
};

interface TableBodyCellSkeletonProps {
  column: Column<any, any>;
}

const TableBodyCellSkeleton = (props: TableBodyCellSkeletonProps) => {
  const { column } = props;

  const isFullWidth = [COLUMN_SELECTION_ID].includes(column.id);

  return (
    <TableCell column={column} className="px-3 py-2">
      <Skeleton.Typography type="body" rows={isFullWidth ? [100] : undefined} />
    </TableCell>
  );
};

TableBodyCell.Skeleton = TableBodyCellSkeleton;
