import { Geolocation } from '@capacitor/geolocation';
import { Position } from '@capacitor/geolocation/dist/esm/definitions';
import { isMobileNative } from '@utils/isMobileNative';

type geolocFunction = (latitude: string, longitude: string) => void;

export const ensureUserPosition = (callback: geolocFunction): void => {
  const latitude = localStorage.getItem('userPosition:latitude');
  const longitude = localStorage.getItem('userPosition:longitude');

  if (latitude && longitude) {
    callback.call(this, latitude, longitude);
  }

  getCurrentPosition()
    .then(({ coords }) => {
      const newLatitude = coords.latitude.toString();
      const newLongitude = coords.longitude.toString();

      localStorage.setItem('userPosition:latitude', newLatitude);
      localStorage.setItem('userPosition:longitude', newLongitude);

      if (!latitude || !longitude) {
        callback.call(this, newLatitude, newLongitude);
      }
    })
    .catch((error) => {
      console.error('an error occured while fetching geo position:', error);
    });
};

export const getCurrentPosition = (): Promise<Position> => {
  if (isMobileNative()) {
    return Geolocation.getCurrentPosition();
  } else {
    return new Promise<Position>((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(resolve, reject),
    );
  }
};

export const coordsToString = (coords: [lat: number, lon: number]) =>
  coords.join(', ');

export function stringToCoords<Param extends string | undefined>(
  latlon: Param,
): Param extends string ? string[] : undefined;
export function stringToCoords(latlon?: string): string[] | undefined {
  return latlon?.split(', ');
}
