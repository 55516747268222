import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/_authenticated-layout/admin/relation/mail',
)({
  beforeLoad: async ({ context }) => {
    const breadcrumb = {
      label: 'Mail',
      to: '/admin/relation/mail',
    };

    return {
      ...context,
      getBreadcrumbEntry: () => [breadcrumb],
    };
  },
});
