import { ApolloError } from '@apollo/client';
import { toast } from '@happypal-tech/design-system';
import {
  isFieldValidationError,
  isKnownApiErrorCode,
} from '@src/constants/Errors';

import i18next from '../i18n/i18n';

export function apiErrorCatcher(
  error: unknown,
  onError?: (error: unknown) => void,
) {
  const defaultErrorHandler = (tWarn?: string) => {
    if (tWarn) {
      // eslint-disable-next-line no-console
      console.warn('Api error translation missing for:', tWarn);
    }
    toast.error(i18next.t('errors:default'));
  };

  if (!(error instanceof ApolloError)) {
    return defaultErrorHandler();
  }

  error.graphQLErrors.map((graphError) => {
    switch (graphError.extensions?.code) {
      case 'validation-error': {
        if (!isFieldValidationError(graphError.extensions.fields)) {
          return defaultErrorHandler();
        }
        let keyTranslationAlreadyMissing = false;
        Object.entries(graphError.extensions.fields).forEach(([key, value]) => {
          Object.keys(value).map((errorCode) => {
            if (keyTranslationAlreadyMissing) return;
            if (!i18next.exists(`errors:api.validation.${errorCode}`)) {
              keyTranslationAlreadyMissing = true;
              return defaultErrorHandler(`errors:api.validation.${errorCode}`);
            }
            onError?.({ [key]: errorCode });
          });
        });
        break;
      }
      case 'payment-limit/ceiling-reached': {
        return toast.error(
          i18next.t(`errors:api.payment-limit/ceiling-reached`),
        );
      }
      default:
        if (
          !graphError.extensions?.code ||
          !isKnownApiErrorCode(graphError.extensions?.code)
        ) {
          // Handle Stripe error when transaction amount is too small
          // @ts-expect-error graphql migration broke this
          if (graphError.extensions?.exception?.code === 'amount_too_small') {
            return toast.error(i18next.t(`errors:api.amount_too_small`));
          }
          return defaultErrorHandler();
        }
        if (!i18next.exists(`errors:api.${graphError.extensions?.code}`)) {
          return defaultErrorHandler(
            `errors:api.${graphError.extensions?.code}`,
          );
        }
        onError?.(graphError.extensions.code);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        toast.error(i18next.t(`errors:api.${graphError.extensions?.code}`));
        break;
    }
  });
}
