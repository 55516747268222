import { useTranslation } from 'react-i18next';

import { Switch, Typography } from '@happypal-tech/design-system';
import { useNavigate } from '@tanstack/react-router';
import { cx } from 'class-variance-authority';

type AdminModeToggleProps = {
  className?: string;
  value: 'user' | 'admin';
};

export const AdminModeToggle = (props: AdminModeToggleProps) => {
  const { className, value } = props;
  const { t } = useTranslation('core', {
    keyPrefix: 'components.adminModeToggle',
  });
  const navigate = useNavigate();

  const toggleRoleMode = () => {
    navigate({ to: value === 'admin' ? '/home' : '/admin/dashboard' });
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      toggleRoleMode();
    }
  };

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={handleKeyPress}
      onClick={toggleRoleMode}
      className={cx(
        'group flex w-full items-center justify-center gap-2 py-3 transition-all',
        {
          'bg-neutral-lightest text-neutral-dark hover:bg-neutral-lighter hover:text-neutral-darkest':
            value === 'user',
          'bg-primary-lightest text-neutral-darkest hover:bg-primary-lighter':
            value === 'admin',
        },
        className,
      )}
    >
      <Switch
        size="large"
        value={value === 'admin'}
        className={cx('!shadow-none', {
          'group-hover:bg-neutral': value === 'user',
          'group-hover:bg-primary-dark': value === 'admin',
        })}
      />

      <Typography type="body" semibold className="select-none">
        {t('label')}
      </Typography>
    </div>
  );
};
