import {
  useForm as rhUseForm,
  UseFormProps,
  UseFormReturn as RHUseFormReturn,
} from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { AnyObjectSchema, Asserts } from 'yup';

export type UseFormReturn<
  S extends AnyObjectSchema,
  T extends Asserts<S>,
> = RHUseFormReturn<T> & { validationSchema: S };

type useFormProps<S extends AnyObjectSchema, T extends Asserts<S>> = Omit<
  UseFormProps<T>,
  'resolver'
> & {
  validationSchema: S;
};

export const useForm = <S extends AnyObjectSchema, T extends Asserts<S>>(
  props: useFormProps<S, T>,
): UseFormReturn<S, T> => {
  const { validationSchema, ...rest } = props;

  return {
    ...rhUseForm({
      resolver: yupResolver(validationSchema),
      criteriaMode: 'all',
      mode: 'onTouched',
      ...rest,
    }),
    validationSchema,
  };
};
