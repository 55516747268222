import { AppMenuModalGDPR } from '@src/features-new/core/AppMenu/components/AppMenuModalGDPR/AppMenuModalGDPR';
import { createFileRoute, Navigate } from '@tanstack/react-router';
import { z } from 'zod';

const Component = () => {
  const { redirect, deconnect } = Route.useSearch();
  if (typeof deconnect === 'string') {
    return <Navigate to="/" />;
  }
  return <AppMenuModalGDPR redirect={redirect} />;
};

export const Route = createFileRoute(
  '/_authenticated-layout/partners/happymedia/',
)({
  validateSearch: z.object({
    redirect: z
      .string()
      .transform((v) => (v ? new URL(v, window.origin).pathname : undefined))
      .optional(),
    deconnect: z.string().optional(),
  }),
  component: Component,
});
