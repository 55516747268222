import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';

const MAINTENANCE_CODE = 'maintenance/in-progress';

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  for (const err of graphQLErrors ?? []) {
    if (
      err.extensions?.code === MAINTENANCE_CODE &&
      window.location.pathname !== '/maintenance'
    ) {
      window.location.href = '/maintenance';
      return;
    }

    if (
      err.name === 'UnauthorizedError' &&
      window.location.pathname !== '/auth/login'
    ) {
      window.location.href = '/auth/login';
      return;
    }

    Sentry.captureException(err);
    console.error('unhandled graphql error', { graphQLErrors });
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});
