import { useTranslation } from 'react-i18next';

import { Spin } from '@happypal-tech/design-system';
import { EmptyState } from '@src/components/molecules/EmptyState';
import { createFileRoute } from '@tanstack/react-router';

const NotFound = () => {
  const { t } = useTranslation('auth');

  return <EmptyState title={t('auth.errors.userNotFound')} />;
};

export const Route = createFileRoute('/_authenticated-layout/admin/home')({
  pendingComponent: () => (
    <div className="flex justify-center h-screen items-center">
      <Spin />
    </div>
  ),
  notFoundComponent: NotFound,
});
