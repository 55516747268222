import { useState } from 'react';

import { Separator } from '@components/atoms/Separator';
import { CampaignNps } from '@components/organisms/CampaignDetails/CampaignNps/CampaignNps';
import { CampaignDiscountInsert } from '@src/components/molecules/CampaignDiscountInsert';
import { CampaignFavorite } from '@src/features-new/favorites/components/CampaignFavorite/CampaignFavorite';
import { useNavigate } from '@tanstack/react-router';
import { AnimatePresence, motion } from 'framer-motion';

import { CampaignOffers } from '../CampaignOffers';
import { OffersListPlaceFilterModule } from '../OffersListPlaceFilterModule';

import { CampaignBrandHeadlines } from './CampaignBrandHeadlines';
import { CampaignCover } from './CampaignCover';
import { CampaignDescription } from './CampaignDescription';
import { CampaignDetailsFragment } from './CampaignDetails.generated';
import { CampaignKeyPoints } from './CampaignKeyPoints';

type CampaignDetailsProps = {
  campaignId: string;
  campaign: CampaignDetailsFragment | null;
  loading: boolean;
  queryID?: string;
};

export function CampaignDetails(props: CampaignDetailsProps) {
  const { campaignId, campaign, loading, queryID } = props;
  const navigate = useNavigate();

  const [selectedCity, setSelectedCity] = useState<string | null>(() => {
    return null;
  });

  const activeSubventionEdge =
    campaign?.viewerRemainingDiscountSubventionsPagination.edges[0];
  const locations = campaign?.locationsPagination.locations || [];

  const handleCitySelect = (city: string | null) => {
    navigate({
      to: '/explore/$campaignId',
      params: {
        campaignId: campaignId,
      },
      search: {
        queryID,
      },
    });

    setSelectedCity(city);
  };

  return (
    <div className="flex w-full max-w-full flex-grow flex-col 2xl:rounded-xl 2xl:pt-8">
      <CampaignCover campaign={campaign} loading={loading} />
      <CampaignBrandHeadlines campaign={campaign} loading={loading} />
      {campaign && (
        <div className="px-4 md:hidden">
          <CampaignFavorite campaign={campaign} fluid />
        </div>
      )}
      <div className="flex flex-col-reverse lg:flex-row auth-layout-container">
        <div className="flex flex-grow flex-col p-4 md:p-0 md:pr-14">
          <div className="flex items-center justify-between gap-4">
            {campaign && (
              <>
                <CampaignKeyPoints
                  campaign={campaign}
                  hasRemainingDiscountSubventions={!!activeSubventionEdge}
                />
                <div className="hidden md:block">
                  <CampaignFavorite queryID={queryID} campaign={campaign} />
                </div>
              </>
            )}
          </div>

          <Separator />
          <CampaignDescription campaign={campaign} loading={loading} />
        </div>
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="z-30 h-full flex-shrink-0 md:w-103 lg:mx-4 lg:-mt-32 lg:mb-10"
          >
            {activeSubventionEdge && (
              <CampaignDiscountInsert activeSubvention={activeSubventionEdge} />
            )}
            <div className="md:p-4 lg:rounded-2xl lg:bg-white lg:p-7 lg:shadow">
              <div className="flex flex-grow flex-col">
                {locations.length > 1 && (
                  <OffersListPlaceFilterModule
                    locations={locations}
                    onChange={handleCitySelect}
                    value={selectedCity}
                  />
                )}
                {campaign && (
                  <CampaignOffers
                    activeSubventionEdge={activeSubventionEdge}
                    campaignId={campaignId}
                    queryID={queryID}
                    selectedCity={selectedCity}
                  />
                )}
              </div>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
      {campaign && (
        <div className="px-4">
          <CampaignNps campaign={campaign} />
        </div>
      )}
    </div>
  );
}
