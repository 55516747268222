import { Portal } from '@radix-ui/react-portal';
import { cn } from '@src/features-new/ui/cn';

interface OverlayProps {
  isOpen?: boolean;
}
export const Overlay = ({ isOpen }: OverlayProps) => {
  return (
    <Portal>
      <div
        aria-hidden
        className={cn(
          'fixed inset-0 min-h-full bg-neutral-overlay/50 opacity-0 transition-opacity duration-300 pointer-events-none',
          isOpen && 'opacity-100 pointer-events-auto',
        )}
      />
    </Portal>
  );
};
