import { ComponentProps } from 'react';

import { cn } from '@src/features-new/ui/cn';

interface AppHeaderProfileMenuImageProps extends ComponentProps<'img'> {}
export const AppHeaderProfileMenuImage = ({
  className,
  ...props
}: AppHeaderProfileMenuImageProps) => (
  <div className="w-10 h-10 p-2 flex items-center justify-center bg-neutral-lightest rounded-lg">
    {/* // decorative images should have empty alt @see https://www.w3.org/WAI/tutorials/images/decorative/ */}
    <img className={cn('h-6 w-6 shrink-0', className)} alt="" {...props} />
  </div>
);
