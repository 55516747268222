import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, {
  ActionMeta,
  ClassNamesConfig,
  GroupBase,
  MenuPlacement,
  OptionsOrGroups,
  SelectComponentsConfig,
  SingleValue,
  StylesConfig,
} from 'react-select';
import {
  AsyncPaginate,
  LoadOptions,
  ReduceOptions,
} from 'react-select-async-paginate';

//----------------------------------------------------
// IMPORTANT
// Ce component est un copié-collé de InputSelect avec du style mis à jour pour coller avec le tunnel création Happening
// Il est PROVISOIRE et ne doit pas être réutilisé ailleur !
//----------------------------------------------------

export type Option = {
  value: string;
  label: string;
};

export interface GroupedOption<T extends Option> {
  label: string;
  options: T[];
}

export type InputSelectDSProps<T extends Option = Option> = {
  label?: string;
  placeholder?: string;
  defaultValue?: T;
  isSearchable?: boolean;
  isClearable?: boolean;
  value?: T | null;
  options: OptionsOrGroups<T, GroupedOption<T>>;
  onChange: (newValue: SingleValue<T>, actionMeta: ActionMeta<T>) => void;
  onInputChange?: (inputValue: string) => void;
  width?: number;
  error?: boolean;
  inputSpacing?: {
    x?: number;
    y?: number;
  };
  customComponents?: Partial<SelectComponentsConfig<T, false, GroupBase<T>>>;
  customStyles?: StylesConfig<T, IsMulti>;
  maxMenuHeight?: number;
  menuIsOpen?: boolean;
  openMenuOnClick?: boolean;
  onMenuClose?: () => void;
  onMenuOpen?: () => void;
  noBorder?: boolean;
  loading?: boolean;
  disabled?: boolean;
  // Async paginate
  loadMore?: LoadOptions<T, GroupBase<T>, never>;
  cacheUniqs?: ReadonlyArray<unknown>;
  reduceOptions?: ReduceOptions<T, GroupBase<T>, never>;
  menuPlacement?: MenuPlacement;
  className?: string;
};

export type IsMulti = false;

export const NullIndicatorSeparator = () => null;

export const InputSelectDS = <T extends Option>({
  label,
  options,
  isSearchable = false,
  isClearable = false,
  defaultValue,
  value,
  placeholder,
  width,
  onChange,
  onInputChange,
  error,
  inputSpacing,
  customComponents,
  customStyles,
  menuIsOpen,
  maxMenuHeight = 300,
  openMenuOnClick,
  onMenuOpen,
  onMenuClose,
  noBorder,
  loading,
  disabled = false,
  loadMore,
  cacheUniqs,
  reduceOptions,
  menuPlacement = 'auto',
  className,
}: InputSelectDSProps<T>) => {
  const { t } = useTranslation('common');

  const classNames: ClassNamesConfig<T> = {
    control: (state) => {
      const color = error
        ? '!border-warning'
        : state.isFocused
          ? '!border-primary'
          : '!border-grey-mediumLight';
      const size = noBorder ? '!border-0' : '!border';
      return `${size} ${color} !rounded-2xl !cursor-pointer h-12 !shadow-none`;
    },
    placeholder: () => '!text-grey !text-sm',
    singleValue: () => '!text-black !text-sm',
    dropdownIndicator: (state) => {
      return state.isFocused
        ? error
          ? '!text-warning'
          : '!text-primary'
        : '!text-grey';
    },
    option: (state) => {
      return `!text-sm !rounded-lg !text-black my-1 mx-0 !cursor-pointer ${
        state.isSelected || state.isFocused ? '!bg-grey-light' : '!bg-white'
      }`;
    },
    menu: () => '!m-0 !rounded-2xl overflow-hidden !shadow',
    menuList: () => 'overflow-auto !py-3 !px-4',
    menuPortal: () => 'z-50',
  };

  const defaultCustomStyles: StylesConfig<T, IsMulti> = {
    control: (provided) => {
      return {
        ...provided,
        width: width ? width : '100%',
      };
    },
    valueContainer: (provided) => ({
      ...provided,
      padding: `${inputSpacing?.x !== undefined ? inputSpacing.x : '0'} ${
        inputSpacing?.y !== undefined ? inputSpacing.y : '12px'
      }`,
    }),
  };

  const styles = { ...defaultCustomStyles, ...customStyles };

  const containerRef = useRef<HTMLDivElement>(null);
  const [rootEl, setRootEl] = useState<HTMLElement>();

  useEffect(() => {
    // If select is displayed in modal we find the closest modal parent and set the rootEl to it
    setRootEl(
      containerRef.current?.closest<HTMLDivElement>('div[role="dialog"]') ||
        undefined,
    );
  }, [containerRef]);

  const selectProps = {
    defaultValue,
    value,
    options,
    styles,
    classNames,
    menuIsOpen,
    maxMenuHeight,
    components: {
      IndicatorSeparator: NullIndicatorSeparator,
      ...customComponents,
    },
    noOptionsMessage: () => t('filters.noResult', 'Aucun résultat'),
    placeholder,
    onChange,
    onInputChange,
    isSearchable,
    isClearable,
    menuPortalTarget: rootEl,
    isLoading: loading,
    isDisabled: disabled,
    openMenuOnClick,
    onMenuOpen,
    onMenuClose,
    menuPlacement,
  };

  // FIXME: Dynamic tag depending on loadMore
  return (
    <div ref={containerRef} className={className}>
      {label && (
        <label className="mb-3 flex justify-between font-body text-sm font-bold">
          {label}
        </label>
      )}

      {loadMore ? (
        <AsyncPaginate
          loadOptions={loadMore}
          cacheUniqs={cacheUniqs}
          reduceOptions={reduceOptions}
          {...selectProps}
        />
      ) : (
        <Select {...selectProps} menuPlacement={menuPlacement} />
      )}
    </div>
  );
};
