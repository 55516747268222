import { useEffect, useState } from 'react';
import {
  useConfigure,
  useInstantSearch,
  useRefinementList,
  useSearchBox,
} from 'react-instantsearch';

import { AlgoliaOfferType } from '@src/features-new/catalog/components/DropdownMenuOfferTypes/DropdownMenuOfferTypes';
import { DropdownMenuSubventionsFragment } from '@src/features-new/catalog/components/DropdownMenuSubventions/DropdownMenuSubventions.generated';
import { REFINEMENT_ATTRIBUTES } from '@src/features-new/catalog/constants/refinementAttributes';
import { OfferType } from '@src/graphql/generated/types';
import { CatalogSearchValues } from '@src/routes/_authenticated-layout/catalog/index/route';
import { Polygon } from '@src/utils/generatedCompressedPolygones';
import { coordsToString } from '@src/utils/geoloc';
import { useSearch } from '@tanstack/react-router';

export const useRefinements = (
  subventionsMap: Record<string, DropdownMenuSubventionsFragment>,
) => {
  const { setIndexUiState } = useInstantSearch();
  const [regionResult, setRegionResult] = useState<Polygon | undefined>();
  const searchParams = useSearch({
    from: '/_authenticated-layout/catalog/',
  });

  const hasGeoSearchParams =
    searchParams.lat !== undefined && searchParams.lng && searchParams.radius;

  const [sub] = searchParams.sub ?? [];

  const subFilters = sub
    ? subventionsMap[sub]?.facetAttributes ?? undefined
    : undefined;

  // virtual query
  useSearchBox();

  // virtual refinement lists
  useRefinementList({
    attribute: REFINEMENT_ATTRIBUTES.CATEGORIES_IDS,
    operator: 'or',
  });

  useRefinementList({
    attribute: REFINEMENT_ATTRIBUTES.TYPES,
    operator: 'or',
  });

  useEffect(() => {
    const getPolygonesModuleImport = () => {
      const moduleImport = import.meta.glob<Polygon[]>(
        'src/routes/_authenticated-layout/catalog/index/polygones.compressed.json',
        {
          import: 'default',
        },
      );

      return moduleImport[
        '/src/routes/_authenticated-layout/catalog/index/polygones.compressed.json'
      ];
    };

    const getRegionsCompressedFile = async () => {
      if (!searchParams.province) {
        setRegionResult(undefined);
        return;
      }

      const module = getPolygonesModuleImport();

      if (module) {
        const regions = await module();

        const region = regions.find((item) => item.c === searchParams.province);

        if (!region) {
          setRegionResult(undefined);
          return;
        }

        setRegionResult(region);
      }
    };
    getRegionsCompressedFile();
  }, [searchParams.province]);

  // virtual configure
  const { refine: refineConfig } = useConfigure({
    aroundLatLng: hasGeoSearchParams
      ? coordsToString([searchParams.lat, searchParams.lng])
      : undefined,
    aroundRadius: searchParams.radius ? searchParams.radius * 1000 : undefined,
    insidePolygon: regionResult?.p as
      | readonly (readonly number[])[]
      | undefined,
    filters: subFilters,
  });

  useEffect(() => {
    setIndexUiState(convertUrlParamsToAlgoliaState(searchParams));
    return () => {
      refineConfig({
        aroundLatLng: '',
        aroundRadius: 'all',
        filters: '',
      });
    };
  }, [refineConfig, searchParams, setIndexUiState]);
};

type IndexUiStateProps = Parameters<
  ReturnType<typeof useInstantSearch>['setIndexUiState']
>[0];

function convertUrlParamsToAlgoliaState(
  params: CatalogSearchValues,
): IndexUiStateProps {
  const types =
    params.type?.flatMap(convertUrlOfferTypeToAlgoliaOfferType) ?? [];
  const categoryIds = params.cat ?? [];

  return {
    query: params.q,
    refinementList: {
      [REFINEMENT_ATTRIBUTES.TYPES]: types,
      [REFINEMENT_ATTRIBUTES.CATEGORIES_IDS]: categoryIds,
    },
  };
}

function convertUrlOfferTypeToAlgoliaOfferType(
  urlOfferType: AlgoliaOfferType,
): OfferType[] {
  switch (urlOfferType) {
    case 'gift_card':
      return [OfferType.GiftCard];
    case 'ticket':
      return [OfferType.Ticket];
    case 'url':
      return [OfferType.Base, OfferType.Url];
    case 'promo_code':
      return [OfferType.CouponClaimable, OfferType.CouponPublic];
    default:
      return [];
  }
}
