import { OfferItemFragment } from '@components/organisms/OfferItem/OfferItem.generated';
import { IconName } from '@happypal-tech/design-system';
import { TFunction } from 'i18next';

export type OfferKindsType = OfferItemFragment;

export function hasExpired(offer?: OfferKindsType) {
  let date;
  switch (offer?.__typename) {
    case 'OfferGiftCard':
    case 'OfferTicket':
      if (offer.product.expiresAt) {
        date = offer.product?.expiresAt;
        break;
      }
      // We dont mind about offer.product.expiresInDays because it is the number of days after the purchase
      return false;
    case 'OfferCouponClaimable':
      date = offer.expiresAt;
      break;
    case 'OfferCouponPublic':
      date = offer.coupon?.expiresAt;
      break;
    default:
      return false;
  }

  if (date) {
    return date < new Date();
  }
  return false;
}

export function getOfferType(offerType: OfferItemFragment['__typename']) {
  switch (offerType) {
    case 'OfferCouponClaimable':
    case 'OfferCouponPublic':
    case 'OfferUrl':
      return 'coupon';
    case 'OfferBase':
      return 'internal';
    case 'OfferGiftCard':
      return 'gift';
    case 'OfferTicket':
      return 'ticket';
    default:
      return 'coupon';
  }
}

/**
 * @param prefixTKey The translation key prefix refering to an offer type label
 */
export function getOfferPictoInfos(
  offerType: OfferItemFragment['__typename'],
  t: TFunction,
): {
  icon: IconName;
  t_key: string;
} {
  switch (getOfferType(offerType)) {
    case 'coupon':
      return {
        icon: 'LabelOutline',
        t_key: t('offerType.label.discountCode'),
      };
    case 'internal':
      return {
        icon: 'SuitCaseOutline',
        t_key: t('offerType.label.internal'),
      };
    case 'gift':
      return {
        icon: 'GiftOutline',
        t_key: t('offerType.label.giftCard'),
      };
    case 'ticket': {
      return {
        icon: 'TicketOutline',
        t_key: t('offerType.label.ticket'),
      };
    }
  }
}

const API_MAX_ITEM_QUANTITY_COUNT = 11;

export function getProductVariantQuantity(
  inventoryQuantity: undefined | null | number,
): number {
  const UNKNOWN_QTY = -1;
  if (
    inventoryQuantity === undefined ||
    inventoryQuantity === null ||
    inventoryQuantity === UNKNOWN_QTY ||
    inventoryQuantity === API_MAX_ITEM_QUANTITY_COUNT
  ) {
    return Infinity;
  }
  return inventoryQuantity;
}
