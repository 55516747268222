import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { toast } from '@happypal-tech/design-system';
import { Loader } from '@src/components/atoms/Loader';
import { createFileRoute, Navigate } from '@tanstack/react-router';

export const NotFoundComponent = () => {
  const { t } = useTranslation('errors');

  useEffect(() => {
    toast.error(t('router.subventionDetails.notFound'));
  }, [t]);

  return <Navigate to="/admin/subventions/list/" replace />;
};

export const Route = createFileRoute(
  '/_authenticated-layout/admin/subventions/detail/$subventionId',
)({
  pendingComponent: () => <Loader />,
  notFoundComponent: NotFoundComponent,
});
