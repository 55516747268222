import { useEffect, useMemo, useState } from 'react';

import { cx } from 'class-variance-authority';

interface PictureProps {
  className?: string;
  /** @description The source URL of the full-size image to be displayed. */
  src?: string;
  /** @description The source URL of a fallback image in case the full-size one has failed to load */
  fallbackSrc?: string;
  /** @description The source URL of the thumbnail image, to be used during loading of the full-size image. */
  thumbnailSrc?: string;
  alt?: string;

  /** @default cover */
  fit?: 'cover' | 'contain' | 'fill' | 'none' | 'scale-down';
  /** @default center */
  fitPosition?:
    | 'bottom'
    | 'center'
    | 'left'
    | 'left bottom'
    | 'left top'
    | 'right'
    | 'right bottom'
    | 'right top'
    | 'top';
}

type PictureStatus = 'loading' | 'loaded' | 'error';

/**
 * @description A component for displaying images with optional thumbnail support, using the Low-Quality Image Placeholder (LQIP) technique.
 *
 * NOTE: The sizing and position of the component should be handled by the caller.
 *
 * @example
 * ```tsx
 * <Picture
 *   alt="A picture of a cat"
 *   className="h-32 w-32"
 *   fit="cover"
 *   src="https://images.unsplash.com/photo-1574144611937-0df059b5ef3e?auto=format&fit=crop&w=600&q=60"
 *   thumbnailSrc="https://images.unsplash.com/photo-1574144611937-0df059b5ef3e?auto=format&fit=crop&w=60&q=60"
 * />
 * ```
 */
export const Picture = (props: PictureProps) => {
  const {
    className,
    src,
    thumbnailSrc,
    fit = 'cover',
    fitPosition = 'center',
    alt = '',
    fallbackSrc,
  } = props;

  const [status, setStatus] = useState<PictureStatus>('loading');

  const shouldShowFullSize =
    !thumbnailSrc || status === 'loaded' || (status === 'error' && fallbackSrc);

  useEffect(() => {
    let isMounted = true;

    if (!src) return;

    const buffer = new Image();
    buffer.onload = () => {
      if (isMounted) setStatus('loaded');
    };
    buffer.onerror = () => {
      if (isMounted) setStatus('error');
    };
    buffer.src = src;

    return () => {
      isMounted = false;
    };
  }, [src]);

  const pictureClassName = cx('absolute inset-0 h-full w-full', {
    'object-cover': fit === 'cover',
    'object-contain': fit === 'contain',
    'object-fill': fit === 'fill',
    'object-none': fit === 'none',
    'object-scale-down': fit === 'scale-down',
    'object-bottom': fitPosition === 'bottom',
    'object-center': fitPosition === 'center',
    'object-left': fitPosition === 'left',
    'object-left-bottom': fitPosition === 'left bottom',
    'object-left-top': fitPosition === 'left top',
    'object-right': fitPosition === 'right',
    'object-right-bottom': fitPosition === 'right bottom',
    'object-right-top': fitPosition === 'right top',
    'object-top': fitPosition === 'top',
  });

  const imgSrc = useMemo(() => {
    switch (status) {
      case 'error':
        return fallbackSrc;
      case 'loading':
        return thumbnailSrc;
      case 'loaded':
        return src;
    }
  }, [fallbackSrc, thumbnailSrc, src, status]);

  return (
    <div className={cx(className, 'overflow-hidden')}>
      <div className="relative h-full w-full">
        {!!thumbnailSrc && (
          <img
            className={cx(pictureClassName, 'pointer-events-none select-none')}
            src={thumbnailSrc}
            alt={alt}
            key="thumbnail"
          />
        )}
        {!!thumbnailSrc && (
          <div
            className={cx(
              'backdrop-blur pointer-events-none absolute inset-0 h-full w-full',
            )}
            key="filter"
          />
        )}
        {imgSrc ? (
          <img
            className={cx(pictureClassName, 'transition-opacity duration-500', {
              'opacity-0': !shouldShowFullSize,
              'opacity-1': shouldShowFullSize,
            })}
            src={imgSrc}
            alt={alt}
            key="picture"
          />
        ) : (
          <img
            src="/placeholder.svg"
            alt="placeholder"
            className="h-full w-full object-cover"
          />
        )}
      </div>
    </div>
  );
};
