import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInfiniteHits, useInstantSearchContext } from 'react-instantsearch';

import { useApolloClient } from '@apollo/client';
import { GQLOperations, OfferType } from '@graphql/generated/types';
import {
  FlatList,
  Progress,
  Spin,
  Typography,
} from '@happypal-tech/design-system';
import { FavoritesOrigin } from '@src/features-new/analytics/constants/track-event.constant';
import { AccordionCategories } from '@src/features-new/catalog/components/AccordionCategories/AccordionCategories';
import { AccordionGeo } from '@src/features-new/catalog/components/AccordionGeo/AccordionGeo';
import { AccordionOfferTypes } from '@src/features-new/catalog/components/AccordionOfferTypes/AccordionOfferTypes';
import { AccordionSubventions } from '@src/features-new/catalog/components/AccordionSubventions/AccordionSubventions';
import { ButtonClearRefinements } from '@src/features-new/catalog/components/ButtonClearRefinements';
import { ButtonClearRefinementsMobile } from '@src/features-new/catalog/components/ButtonClearRefinements/Mobile';
import { CatalogTitle } from '@src/features-new/catalog/components/CatalogTitle/CatalogTitle';
import { ChipsCurrentRefinements } from '@src/features-new/catalog/components/ChipsCurrentRefinements';
import { DropdownMenuCategories } from '@src/features-new/catalog/components/DropdownMenuCategories/DropdownMenuCategories';
import { DropdownMenuCategoriesFragment } from '@src/features-new/catalog/components/DropdownMenuCategories/DropdownMenuCategories.generated';
import {
  AlgoliaOfferType,
  catalogSearchTag,
  DropdownMenuOfferTypes,
} from '@src/features-new/catalog/components/DropdownMenuOfferTypes/DropdownMenuOfferTypes';
import { DropdownMenuSubventions } from '@src/features-new/catalog/components/DropdownMenuSubventions/DropdownMenuSubventions';
import { FiltersButton } from '@src/features-new/catalog/components/FiltersButton/FiltersButton';
import { PopoverGeo } from '@src/features-new/catalog/components/PopoverGeo';
import { useRefinements } from '@src/features-new/catalog/hooks/useRefinements';
import { CatalogEmptyState } from '@src/features-new/core/CatalogEmptyState/CatalogEmptyState';
import { CatalogSearchBar } from '@src/features-new/core/CatalogSearchBar/CatalogSearchBar';
import { Accordion } from '@src/features-new/design-system/components/atoms/Accordion/Accordion';
import { CampaignCard } from '@src/features-new/ui/CampaignCard/CampaignCard';
import { CampaignCardInformationFragment } from '@src/features-new/ui/CampaignCard/CampaignCard.generated';
import { CampaignCardSkeleton } from '@src/features-new/ui/CampaignCard/Skeleton';
import { cn } from '@src/features-new/ui/cn';
import { PullToRefresh } from '@src/features-new/ui/PullToRefresh/PullToRefresh';
import { useAnalytics } from '@src/hooks/use-analytics';
import {
  useCatalogCampaignInformationsLazyQuery,
  useCatalogFiltersSuspenseQuery,
} from '@src/routes/_authenticated-layout/catalog/index/~route.generated';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { omit, pick } from 'radash';
import regions from 'src/routes/_authenticated-layout/catalog/index/provinces.compressed.json';
import z from 'zod';

const REGIONS_CODES = [...regions.map((item) => item.c)] as const;

type CampaignAlgolia = {
  brand: string;
  objectID: string;
  campaignThumbnailUrl: string;
  brandLogoUrl: string;
  types: OfferType[];
  categories: string[];
  name: string;
  __queryID?: string;
  __position?: number;
};

type CatalogSubCategory = DropdownMenuCategoriesFragment & {
  parentId: string;
};

type CatalogCategoryWithSubCategories = DropdownMenuCategoriesFragment & {
  subCategories: DropdownMenuCategoriesFragment[];
};

const CATALOG_SKELETON_COUNT = 18;

const catalogSearchSchema = z
  .object({
    q: z.string().trim().optional(),
    sort: z.enum(['relevance', 'newest']).optional().catch(undefined),
    cat: z.array(z.string().uuid()).optional().catch(undefined),
    sub: z.array(z.string().uuid()).optional().catch(undefined),
    type: z.array(z.enum(catalogSearchTag)).optional().catch(undefined),
  })
  .and(
    z
      .union([
        z.object({
          lat: z.number(),
          lng: z.number(),
          radius: z.number(),
          province: z.undefined(),
        }),
        z.object({
          lat: z.undefined(),
          lng: z.undefined(),
          radius: z.undefined(),
          province: z.undefined(),
        }),
        z.object({
          province: z.string().refine((value) => REGIONS_CODES.includes(value)),
          lat: z.undefined(),
          lng: z.undefined(),
          radius: z.undefined(),
        }),
      ])
      .catch({}),
  );

export type CatalogSearchValues = z.infer<typeof catalogSearchSchema>;

const getSafeGeoSearchParams = ({
  lat,
  lng,
  radius,
  province,
}: Pick<CatalogSearchValues, 'lat' | 'lng' | 'radius' | 'province'>) => {
  if (province) {
    return { province };
  }
  if (lat !== undefined && lng !== undefined && radius !== undefined) {
    return { lat, lng, radius };
  }
  return null;
};

const CatalogRender = () => {
  const { company } = Route.useRouteContext();
  const client = useApolloClient();
  const hasSubventionsModule = company?.features.subventions === true;
  const navigate = useNavigate();
  const searchParams = Route.useSearch();
  const hasGeoSearchParams =
    searchParams.lat !== undefined ||
    (searchParams.province !== undefined &&
      REGIONS_CODES.includes(searchParams.province));
  const geoSearchParams = pick(searchParams, [
    'lat',
    'lng',
    'radius',
    'province',
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [campaignCardInformations, setCampaignCardInformations] = useState<
    Map<string, CampaignCardInformationFragment>
  >(() => new Map());
  const { track } = useAnalytics();
  const { data } = useCatalogFiltersSuspenseQuery();

  const categories = useMemo(
    () =>
      data.catalog.categoryPagination.edges.map(({ node }) => {
        const subCategories = node.childrenPagination.edges.map(
          ({ node }) => node,
        );
        return {
          ...omit(node, ['childrenPagination']),
          subCategories,
        };
      }),
    [data.catalog.categoryPagination.edges],
  );

  const categoriesFlatMap = useMemo<
    Record<string, CatalogSubCategory | CatalogCategoryWithSubCategories>
  >(
    () =>
      categories.reduce(
        (acc, category) => ({
          ...acc,
          [category.id]: category,
          ...category.subCategories.reduce(
            (subAcc, subCategory) => ({
              ...subAcc,
              [subCategory.id]: { ...subCategory, parentId: category.id },
            }),
            {},
          ),
        }),
        {},
      ),
    [categories],
  );

  const subventions = useMemo(
    () => data.viewer?.activeSubventionsWithAvailableAmount ?? [],
    [data.viewer?.activeSubventionsWithAvailableAmount],
  );
  const subventionsMap = useMemo(
    () =>
      subventions.reduce<Record<string, (typeof subventions)[number]>>(
        (acc, subvention) => ({ ...acc, [subvention.id]: subvention }),
        {},
      ),
    [subventions],
  );

  const { t: tDrawer } = useTranslation('catalog', {
    keyPrefix: 'components.CatalogFiltersDrawer',
  });
  const { t: tOfferTypes } = useTranslation('core', {
    keyPrefix: 'offerTypes',
  });

  const { t: tGeo } = useTranslation('catalog', {
    keyPrefix: 'components.Geo.CatalogProvincesCombobox',
  });

  const [accordionOpen, setAccordionOpen] = useState<string[]>([]);

  const { hits, showMore, results, currentPageHits, isLastPage } =
    useInfiniteHits<CampaignAlgolia>({
      showPrevious: false,
    });
  const { status, helper } = useInstantSearchContext();

  // TODO: Remove this and use skeletons
  const { nbHits, query, page } = results ?? {
    nbHits: 0,
    query: undefined,
    page: 0,
  };

  const nextPage = helper?.state.page;
  const isFetchingMore = (nextPage ?? page) > page;

  const resultsAreEmpty =
    status === 'idle' && showMore !== undefined && nbHits === 0;

  const handleTypeSelect = (type: AlgoliaOfferType) => {
    if (!searchParams.type?.includes(type)) {
      track({
        type: 'filter catalog campaigns',
        payload: {
          filter_type: 'offer_type',
        },
      });
    }

    navigate({
      to: '/catalog',
      from: '/catalog',
      replace: true,
      search: (prev) => {
        const newType = prev.type?.includes(type)
          ? prev.type.filter((t) => t !== type)
          : [...(prev.type ?? []), type];

        return { ...prev, type: newType.length > 0 ? newType : undefined };
      },
    });
  };

  const handleCategorySelect = (
    categoryId: string,
    nextStatus?: boolean | 'indeterminate',
    parentCategoryId?: string,
  ) => {
    if (parentCategoryId && nextStatus === true) {
      track({
        type: 'filter catalog campaigns',
        payload: {
          category_filter_name: categoriesFlatMap[categoryId]?.name,
          filter_type: 'subcategory',
        },
      });
    }

    if (
      !searchParams.cat?.includes(categoryId) &&
      !parentCategoryId &&
      nextStatus === true
    ) {
      track({
        type: 'filter catalog campaigns',
        payload: {
          category_filter_name: categoriesFlatMap[categoryId]?.name,
          filter_type: 'category',
        },
      });
    }

    navigate({
      to: '/catalog',
      from: '/catalog',
      replace: true,
      search: (prev) => {
        const category = categoriesFlatMap[categoryId];
        const subCategories =
          category && 'subCategories' in category
            ? category.subCategories
            : undefined;
        const subCatSelectionned = subCategories?.filter((subCategory) =>
          prev.cat?.includes(subCategory.id),
        );

        const getNewCat = () => {
          if (nextStatus === true || nextStatus === 'indeterminate') {
            const cat = [...(prev.cat ?? []), categoryId];

            if (parentCategoryId) {
              return cat.filter((c) => c !== parentCategoryId);
            }

            return cat.filter(
              (c) => !subCatSelectionned?.map((sub) => sub.id).includes(c),
            );
          }
          if (
            prev.cat?.includes(categoryId) &&
            subCatSelectionned &&
            subCatSelectionned.length > 0
          ) {
            return prev.cat.filter((c) => c !== categoryId);
          }

          if (nextStatus === false || nextStatus === undefined) {
            const cat = [...(prev.cat ?? [])].filter((c) => c !== categoryId);

            return cat.filter(
              (c) => !subCatSelectionned?.map((sub) => sub.id).includes(c),
            );
          }
          return prev.cat ?? [];
        };

        const newCat = getNewCat();

        return { ...prev, cat: newCat.length > 0 ? newCat : undefined };
      },
    });
  };

  const handleSubventionSelect = (subventionId: string) => {
    if (!searchParams.sub?.includes(subventionId)) {
      track({
        type: 'filter catalog campaigns',
        payload: {
          filter_type: 'benefit',
        },
      });
    }

    navigate({
      to: '/catalog',
      from: '/catalog',
      replace: true,
      search: (prev) => {
        const newSub = prev.sub?.includes(subventionId) ? [] : [subventionId];

        return { ...prev, sub: newSub.length > 0 ? newSub : undefined };
      },
    });
  };

  const handleGeoChange = (
    value: {
      lat?: number;
      lng?: number;
      radius?: number;
      province?: string;
    } | null,
  ) => {
    if (
      value !== null &&
      (searchParams.lat !== value.lat ||
        searchParams.lng !== value.lng ||
        searchParams.radius !== value.radius ||
        searchParams.province !== value.province)
    ) {
      track({
        type: 'filter catalog campaigns',
        payload: {
          filter_type: 'place',
        },
      });
    }

    navigate({
      to: '/catalog',
      from: '/catalog',
      replace: true,
      search: (prev) => {
        if (value === null) {
          return {
            ...prev,
            lat: undefined,
            lng: undefined,
            radius: undefined,
            province: undefined,
          };
        }

        if (value.province) {
          return {
            ...prev,
            lat: undefined,
            lng: undefined,
            radius: undefined,
            province: value.province,
          };
        }

        if (value.lat && value.lng && value.radius) {
          return {
            ...prev,
            province: undefined,
            lat: value.lat,
            lng: value.lng,
            radius: value.radius,
          };
        }
        return { ...prev };
      },
    });
  };

  const currentRefinements = useMemo(() => {
    return [
      ...(searchParams.type ?? []).map((type) => ({
        label: tOfferTypes(type),
        value: `type:${type}`,
      })),
      ...(searchParams.sub ?? []).map((sub) => ({
        label: subventionsMap[sub]?.name ?? '',
        value: `sub:${sub}`,
      })),
      ...(searchParams.cat ?? []).map((cat) => ({
        label: categoriesFlatMap[cat]?.name ?? '',
        value: `cat:${cat}`,
      })),
      ...(hasGeoSearchParams
        ? [
            {
              label: searchParams.province
                ? regions.find((item) => item.c === searchParams.province)?.n ??
                  ''
                : tGeo('aroundMe'),
              value: 'geo',
            },
          ]
        : []),
    ];
  }, [
    searchParams.type,
    searchParams.sub,
    searchParams.cat,
    searchParams.province,
    hasGeoSearchParams,
    tGeo,
    tOfferTypes,
    subventionsMap,
    categoriesFlatMap,
  ]);

  const handleRefinementRemove = (refinement: { value: string }) => {
    const [type, value] = refinement.value.split(':');

    switch (type) {
      case 'type':
        handleTypeSelect(value as AlgoliaOfferType);
        break;
      case 'sub':
        handleSubventionSelect(value as string);
        break;
      case 'cat':
        handleCategorySelect(value as string, false);
        break;
      case 'geo':
        handleGeoChange(null);
        break;
      default:
        break;
    }
  };

  useRefinements(subventionsMap);

  const [catalogCampaignInformationsLazyQuery] =
    useCatalogCampaignInformationsLazyQuery();

  useEffect(() => {
    setIsLoading(false);
  }, [page]);

  const campaignIds = useMemo(
    () =>
      currentPageHits
        .filter((hit) => !campaignCardInformations.has(hit.objectID))
        .map((hit) => hit.objectID),
    [currentPageHits, campaignCardInformations],
  );

  const refreshCardInfo = (id: string) => {
    catalogCampaignInformationsLazyQuery({
      variables: {
        campaignIds: [id],
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        setCampaignCardInformations((oldMap) => {
          const newMap = new Map(oldMap);
          data.campaignCardInformations.forEach((info) => {
            newMap.set(info.campaignId, info);
          });
          return newMap;
        });
      },
    });
  };

  useEffect(() => {
    if (campaignIds.length === 0) {
      return;
    }
    catalogCampaignInformationsLazyQuery({
      variables: {
        campaignIds: campaignIds,
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        setCampaignCardInformations((oldMap) => {
          if (data.campaignCardInformations.length === 0) {
            return oldMap;
          }
          const newMap = new Map(oldMap);
          data.campaignCardInformations.forEach((info) => {
            newMap.set(info.campaignId, info);
          });
          return newMap;
        });
      },
    });
  }, [campaignIds, catalogCampaignInformationsLazyQuery]);

  const handleRefetch = async () => {
    await client.refetchQueries({
      include: [
        GQLOperations.Query.CatalogFilters,
        GQLOperations.Query.CatalogCampaignInformations,
      ],
    });
  };

  return (
    <PullToRefresh onRefresh={handleRefetch}>
      <div className="auth-layout-container flex flex-col gap-4 relative">
        <Progress
          className={cn(
            'absolute top-0 left-0 right-0 transition-opacity delay-150 duration-300 opacity-0',
            {
              'opacity-100': isLoading,
            },
          )}
          value={null}
          variant="linear"
        />
        <CatalogTitle
          totalCount={nbHits}
          search={query}
          className="hidden md:flex"
        />
        <div className="bg-neutral-container flex gap-6 items-center">
          <div
            className={cn(
              'hidden',
              'md:flex md:items-center md:gap-3 md:grow md:flex-wrap',
            )}
          >
            <div className="flex items-center gap-3">
              <DropdownMenuCategories
                categories={categories}
                activeCategories={searchParams.cat ?? []}
                onSelect={handleCategorySelect}
              />
              {hasSubventionsModule && (
                <DropdownMenuSubventions
                  subventions={subventions}
                  activeSubventions={searchParams.sub ?? []}
                  onSelect={handleSubventionSelect}
                />
              )}
            </div>
            <div className="flex items-center gap-3">
              <PopoverGeo
                value={getSafeGeoSearchParams(geoSearchParams)}
                onChange={handleGeoChange}
              />
              <DropdownMenuOfferTypes
                activeOfferTypes={searchParams.type ?? []}
                onSelect={handleTypeSelect}
              />
              <ButtonClearRefinements />
            </div>
          </div>
          <div className={cn('flex flex-col gap-6 w-full', 'md:hidden')}>
            <div className="flex gap-4">
              <CatalogSearchBar alwaysShowInput hideSearchButton />
              <FiltersButton>
                <ButtonClearRefinementsMobile />
                {/* <Typography type="heading-3" bold className="py-4">
                {tDrawer('sorter')}
              </Typography> */}
                <Typography type="heading-3" bold className="-mx-3">
                  {tDrawer('filters')}
                </Typography>
                <Accordion
                  type="multiple"
                  value={accordionOpen}
                  onValueChange={setAccordionOpen}
                  className="-mx-6"
                >
                  <AccordionCategories
                    activeCategories={searchParams.cat ?? []}
                    categories={categories}
                    onSelectCategory={handleCategorySelect}
                  />
                  {hasSubventionsModule && (
                    <AccordionSubventions
                      activeSubventions={searchParams.sub ?? []}
                      onSelect={handleSubventionSelect}
                      subventions={subventions}
                    />
                  )}
                  <AccordionOfferTypes
                    activeOfferTypes={searchParams.type ?? []}
                    onSelect={handleTypeSelect}
                  />
                  <AccordionGeo
                    value={getSafeGeoSearchParams(geoSearchParams)}
                    onChange={handleGeoChange}
                  />
                </Accordion>
              </FiltersButton>
            </div>
            <CatalogTitle totalCount={nbHits} search={query} />
          </div>
        </div>
        {currentRefinements.length > 0 && (
          <ChipsCurrentRefinements
            refinements={currentRefinements}
            onRemove={handleRefinementRemove}
            className="pb-2 md:pb-0"
          />
        )}
        {resultsAreEmpty ? (
          <CatalogEmptyState search={query} showResetFilters />
        ) : (
          <FlatList
            items={hits}
            renderItem={(hit) => (
              <CampaignCard
                brand={hit.brand}
                campaignId={hit.objectID}
                campaignThumbnailUrl={hit.campaignThumbnailUrl}
                brandLogoUrl={hit.brandLogoUrl}
                types={hit.types}
                categories={hit.categories}
                name={hit.name}
                queryID={hit.__queryID}
                position={hit.__position}
                campaignInfo={campaignCardInformations.get(hit.objectID)}
                className={cn(
                  'transition-opacity duration-300 delay-150 opacity-100',
                  !isFetchingMore && isLoading && 'opacity-50',
                )}
                onFavoriteStatusChange={() => refreshCardInfo(hit.objectID)}
                pageTrackingValue={FavoritesOrigin.catalogPage}
              />
            )}
            renderItemSkeleton={() => <CampaignCardSkeleton />}
            loading={status === 'stalled' || isLoading}
            loadingCount={CATALOG_SKELETON_COUNT}
            keyExtractor={(hit) => hit.objectID}
            onEndReached={() => {
              if (!isLastPage && !isLoading) {
                setIsLoading(true);
                showMore();
              }
            }}
            endReachedThreshold={0.1}
            wrapperClassName="grid grid-cols-[repeat(auto-fill,_minmax(256px,_1fr))] gap-6"
          />
        )}
      </div>
    </PullToRefresh>
  );
};

export interface CategoryWithSubCategories
  extends DropdownMenuCategoriesFragment {
  subCategories: DropdownMenuCategoriesFragment[];
}

export const Route = createFileRoute('/_authenticated-layout/catalog/')({
  component: CatalogRender,
  validateSearch: (v): CatalogSearchValues => {
    const reset = Object.fromEntries(Object.keys(v).map((k) => [k, undefined]));
    const validated = catalogSearchSchema.safeParse(v);

    if (!validated.success) {
      return {
        ...reset,
        // sort: 'relevance',
      };
    } else {
      // const sort = validated.data.sort ?? 'relevance';

      return {
        ...reset,
        ...validated.data,
        // sort,
      };
    }
  },

  loaderDeps: ({ search }) => search,
  pendingComponent: () => <Spin />,
});
