import { CampaignDetailsPageComponent } from '@src/features/campaigns/pages/CampaignDetailsPage/CampaignDetailsPage';
import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

export const Route = createFileRoute(
  '/_authenticated-layout/explore/$campaignId',
)({
  component: ExploreCampaignId,

  validateSearch: z.object({
    queryID: z
      .string()
      .optional()
      .catch(() => undefined),
  }),
});

function ExploreCampaignId() {
  const { campaignId } = Route.useParams();
  const { queryID } = Route.useSearch();

  return (
    <CampaignDetailsPageComponent queryID={queryID} campaignId={campaignId} />
  );
}
