import { Skeleton } from '@happypal-tech/design-system';

const PictureSkeleton = () => (
  <Skeleton className="rounded-xl border border-white border-x-4 border-y-4 h-[64px] w-[64px] " />
);

const ChipSkeleton = () => (
  <Skeleton className="font-semibold py-[3px] px-2 rounded-lg h-6 w-1/3" />
);

export const CampaignCardSkeleton = () => (
  <div className="flex flex-col gap-2">
    <div className="relative h-[200px] rounded-xl overflow-hidden">
      <div
        className="absolute inset-0"
        style={{
          background:
            'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 23.53%, rgba(0, 0, 0, 0.75) 100%)',
        }}
      />
      <Skeleton className="w-full h-full" />
      <div className="absolute z-0 left-0 right-0 bottom-0 mx-2 mb-2 flex justify-between items-end">
        <PictureSkeleton />
        <ChipSkeleton />
      </div>
    </div>
    <div className="flex flex-col gap-1">
      <Skeleton.Typography
        type="caption"
        className="text-neutral-dark font-semibold my-1"
      />
      <Skeleton.Typography type="heading-4" className="font-bold" />
    </div>
  </div>
);
