import { DayPicker, DayPickerSingleProps } from 'react-day-picker';

import { useLocalization } from '@src/i18n/localization.context';

import 'react-day-picker/dist/style.css';

export type FormInputDateContentProps = Pick<
  DayPickerSingleProps,
  | 'selected'
  | 'onSelect'
  | 'numberOfMonths'
  | 'fromDate'
  | 'fromMonth'
  | 'fromYear'
  | 'toDate'
  | 'toMonth'
  | 'toYear'
  | 'showOutsideDays'
  | 'showWeekNumber'
  | 'footer'
  | 'disabled'
  | 'hidden'
  | 'month'
  | 'onMonthChange'
>;

export const FormInputDateContent = (props: FormInputDateContentProps) => {
  const { dateFnsLocale } = useLocalization();

  return (
    <div className="rounded bg-white p-3 shadow">
      <DayPicker
        // FIXME: remove .dbp class from styles.css
        style={{ margin: 0 }}
        locale={dateFnsLocale}
        mode="single"
        captionLayout="dropdown"
        fromYear={props.fromYear ?? 1900}
        classNames={{
          day_selected: 'text-white bg-primary',
        }}
        {...props}
      />
    </div>
  );
};
