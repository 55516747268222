/* eslint-disable @typescript-eslint/no-explicit-any */
import { type ReactNode } from 'react';

import { type Column } from '@tanstack/react-table';
import { cx } from 'class-variance-authority';

interface TableCellProps {
  className?: string;
  children: ReactNode;
  column: Column<any, any>;
}

export const TableCell = (props: TableCellProps) => {
  const { className, children, column } = props;

  return (
    <div
      className={cx(className, 'flex-1 overflow-hidden')}
      style={{
        minWidth: column.columnDef.minSize,
        maxWidth: column.columnDef.maxSize,
        flexBasis: column.getSize(),
      }}
    >
      {children}
    </div>
  );
};
