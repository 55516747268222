import { useTranslation } from 'react-i18next';

import { InputNumber, Slider, Typography } from '@happypal-tech/design-system';

export type GeoRadiusInputProps = {
  value: number | null;
  onChange?: (value: GeoRadiusInputProps['value']) => void;
  min?: number;
  max?: number;
};

export const GeoRadiusInput = (props: GeoRadiusInputProps) => {
  const { value, onChange, min, max } = props;
  const { t } = useTranslation('catalog', {
    keyPrefix: 'components.Geo.GeoRadiusInput',
  });

  return (
    <div className="flex flex-col gap-2 text-start">
      <Typography type="body" bold className="text-sm">
        {t('label')}
      </Typography>

      <Slider
        value={value}
        min={min}
        max={max}
        onChange={(value) => {
          if (value === null) return;

          return onChange?.(value);
        }}
      />
      <InputNumber
        value={value}
        min={min}
        max={max}
        onChange={(value) => {
          const isOverMax = value && max && value > max;
          const isUnderMin = value && min && value < min;

          if (isOverMax) return;
          if (isUnderMin) return;

          return onChange?.(value);
        }}
      />
    </div>
  );
};
