import { Currency } from 'dinero.js';
import i18next from 'i18next';

export function applyPercentage(value: number, percentage: number) {
  return Math.round(value * (1 - percentage / 100));
}

export const capitalizeText = (text: string) =>
  text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

// TODO Fix useFormatPrice or use this method
export const formatPrice = (amount: number, currency: string) => {
  return new Intl.NumberFormat(i18next.language, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(amount / 100);
};

export function getCurrencySymbol(locale: string, currency: Currency) {
  return (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();
}

export const humanFileSize = (bytes: number, si = false, precision = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** precision;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(precision) + ' ' + units[u];
};

/**
 *
 * @param {string} email email address
 * @returns {string} obfuscated email address
 */
export const obfuscateEmail = (email: string): string => {
  const [name, domain] = email.split('@') as [string, string];
  return `${name[0]}${name[1]}${new Array(name.length).join('*')}@${new Array(
    domain.length - 4,
  ).join('*')}${domain.substring(domain.length - 4)}`;
};
