import { CartButton } from '@src/components/organisms/CartButton';
import { MiniNotificationCenterButton } from '@src/components/organisms/Notifications/MiniNotificationCenterButton';
import { CatalogSearchBar } from '@src/features-new/core/CatalogSearchBar/CatalogSearchBar';
import { RemainingSubventionsHeaderButton } from '@src/features-new/subvention/components/RemainingSubventionsButtons/RemainingSubventionsHeaderButton';
import {
  getBackgroundColor,
  isBackgroundDark,
} from '@src/features-new/ui/background';
import { cn } from '@src/features-new/ui/cn';

import { AppHeaderProfile } from './AppHeaderProfile/AppHeaderProfile';
import {
  AppHeaderCompanyFragment,
  AppHeaderViewerFragment,
} from './AppHeader.generated';

type AppHeaderProps = {
  className?: string;
  company: AppHeaderCompanyFragment;
  viewer: AppHeaderViewerFragment;
};

export const AppHeader = (props: AppHeaderProps) => {
  const { className, company, viewer } = props;

  const identityCustomisation = company.featureConfig.identityCustomisation;

  const backgroundColor = getBackgroundColor(
    identityCustomisation.isEnabled,
    identityCustomisation.colorSecondary,
  );
  const backgroundIsDark = isBackgroundDark(backgroundColor);

  return (
    <div
      className={cn(
        'flex justify-end items-center border-b border-blue-light p-2 gap-2',
        {
          'text-white': backgroundIsDark,
          'text-neutral-darkest': !backgroundIsDark,
        },
        className,
      )}
      style={{ backgroundColor }}
    >
      <div className="flex flex-1 justify-between gap-3">
        <CatalogSearchBar className="max-w-[30rem]" />
        <RemainingSubventionsHeaderButton.withProxy className="mx-3" />
      </div>
      <div className="flex justify-center items-center gap-2">
        <MiniNotificationCenterButton
          viewer={viewer}
          isBackgroundDark={backgroundIsDark}
        />
        <CartButton isBackgroundDark={backgroundIsDark} />
      </div>
      <AppHeaderProfile viewer={viewer} />
    </div>
  );
};
