import {
  MaintenanceDocument,
  MaintenanceQuery,
  MaintenanceQueryVariables,
} from '@src/routes/maintenance/~route.generated';
import { createFileRoute, redirect } from '@tanstack/react-router';

export interface MaintenanceData {
  startsAt: string;
  endsAt?: string;
  explanation: string;
}

export const Route = createFileRoute('/maintenance')({
  loader: async ({ context }) => {
    const { error } = await context.apolloClient.query<
      MaintenanceQuery,
      MaintenanceQueryVariables
    >({
      query: MaintenanceDocument,
      errorPolicy: 'all',
    });
    const maintenanceError = error?.graphQLErrors.find(
      (graphQlError) =>
        graphQlError.extensions.code === 'maintenance/in-progress',
    );
    if (!maintenanceError) {
      throw redirect({ to: '/' });
    }
    return maintenanceError.extensions.data as MaintenanceData;
  },
});
