/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  BigInt: { input: number; output: number; }
  /** A field whose value is a Currency: https://en.wikipedia.org/wiki/ISO_4217. */
  Currency: { input: string; output: string; }
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: { input: Date; output: Date; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: Date; output: Date; }
  /** The format is a strict subset of JSON, is human readable, and easily parsible by machines. Deltas can describe any Quill document, includes all text and formatting information, without the ambiguity and complexity of HTML */
  Delta: { input: any; output: any; }
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any; }
  /** A field whose value is a valid decimal degrees latitude number (53.471): https://en.wikipedia.org/wiki/Latitude */
  Latitude: { input: any; output: any; }
  /** A field whose value is a valid decimal degrees longitude number (53.471): https://en.wikipedia.org/wiki/Longitude */
  Longitude: { input: any; output: any; }
};

export type Address = Node & {
  __typename?: 'Address';
  address1: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country: CountryCode;
  createdAt: Scalars['DateTime']['output'];
  formattedAddress?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  latitude?: Maybe<Scalars['Latitude']['output']>;
  longitude?: Maybe<Scalars['Longitude']['output']>;
  placeId?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  zip: Scalars['String']['output'];
};

export type AddressCreateInput = {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  /** A string composed of two-letter country codes, compliant with ISO 3166 standard */
  country: Scalars['String']['input'];
  latitude?: InputMaybe<Scalars['Latitude']['input']>;
  longitude?: InputMaybe<Scalars['Longitude']['input']>;
  placeId?: InputMaybe<Scalars['String']['input']>;
  /** A string composed of three-letter region country codes, compliant with ISO 3166 standard */
  province?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  zip: Scalars['String']['input'];
};

export type AddressData = {
  __typename?: 'AddressData';
  address1?: Maybe<TrackableString>;
  address2?: Maybe<TrackableString>;
  city?: Maybe<TrackableString>;
  country?: Maybe<TrackableString>;
  province?: Maybe<TrackableString>;
  zip?: Maybe<TrackableString>;
};

export type AddressUpdateInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  /** A string composed of two-letter country codes, compliant with ISO 3166 standard */
  country?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['Latitude']['input']>;
  longitude?: InputMaybe<Scalars['Longitude']['input']>;
  placeId?: InputMaybe<Scalars['String']['input']>;
  /** A string composed of three-letter region country codes, compliant with ISO 3166 standard */
  province?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type AddressesNormalizeArgs = {
  provinces?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AddressesNormalizeOutput = {
  __typename?: 'AddressesNormalizeOutput';
  addresses: Array<Address>;
};

export type AdminComment = DeletableNode & Node & {
  __typename?: 'AdminComment';
  author: User;
  content: Scalars['String']['output'];
  contentDelta: Scalars['Delta']['output'];
  contentHTML: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AdminCommentArgsFilter = {
  AND?: InputMaybe<Array<AdminCommentArgsFilter>>;
  OR?: InputMaybe<Array<AdminCommentArgsFilter>>;
  id?: InputMaybe<FilterIdArgs>;
  targetId?: InputMaybe<FilterIdArgs>;
  targetType?: InputMaybe<AdminCommentFilterArgsFilterAdminCommentTargetType>;
};

export type AdminCommentArgsSorter = {
  createdAt?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type AdminCommentCreateInput = {
  content: Scalars['Delta']['input'];
};

export type AdminCommentCreateOutput = {
  __typename?: 'AdminCommentCreateOutput';
  adminComment: AdminComment;
};

export type AdminCommentDeleteOutput = {
  __typename?: 'AdminCommentDeleteOutput';
  adminComment: AdminComment;
};

export type AdminCommentEdge = Edge & {
  __typename?: 'AdminCommentEdge';
  cursor: Scalars['ID']['output'];
  node: AdminComment;
};

export type AdminCommentFilterArgsFilterAdminCommentTargetType = {
  in?: InputMaybe<Array<AdminCommentTargetType>>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<AdminCommentTargetType>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<AdminCommentTargetType>;
  notIn?: InputMaybe<Array<AdminCommentTargetType>>;
};

export type AdminCommentPagination = Pagination & {
  __typename?: 'AdminCommentPagination';
  edges: Array<AdminCommentEdge>;
  nodes: Array<AdminComment>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export enum AdminCommentTargetType {
  Brand = 'BRAND',
  Campaign = 'CAMPAIGN',
  Company = 'COMPANY',
  Offer = 'OFFER',
  Order = 'ORDER',
  StripePaymentIntent = 'STRIPE_PAYMENT_INTENT',
  User = 'USER'
}

export type AdminCommentUpdateInput = {
  content?: InputMaybe<Scalars['Delta']['input']>;
};

export type AdminCommentUpdateOutput = {
  __typename?: 'AdminCommentUpdateOutput';
  adminComment: AdminComment;
};

export type Alert = DeletableNode & Node & {
  __typename?: 'Alert';
  configuration: Scalars['JSONObject']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  recipientsCohort: CohortUser;
  triggerPagination: AlertTriggerPagination;
  type: AlertType;
  updatedAt: Scalars['DateTime']['output'];
};


export type AlertTriggerPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<AlertTriggerArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type AlertArgsFilter = {
  type?: InputMaybe<FilterStringArgs>;
};

export type AlertArgsSorter = {
  createdAt?: InputMaybe<SortDirection>;
};

export type AlertCreateInput = {
  cohortUserId: Scalars['ID']['input'];
  configuration: Scalars['JSONObject']['input'];
  type: AlertType;
};

export type AlertCreateOutput = {
  __typename?: 'AlertCreateOutput';
  alert: Alert;
};

export type AlertDeleteOutput = {
  __typename?: 'AlertDeleteOutput';
  alert: Alert;
};

export type AlertEdge = Edge & {
  __typename?: 'AlertEdge';
  cursor: Scalars['ID']['output'];
  node: Alert;
};

export type AlertPagination = Pagination & {
  __typename?: 'AlertPagination';
  edges: Array<AlertEdge>;
  nodes: Array<Alert>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AlertTrigger = Node & {
  __typename?: 'AlertTrigger';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  payload: Scalars['JSONObject']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AlertTriggerArgsSorter = {
  createdAt?: InputMaybe<SortDirection>;
};

export type AlertTriggerEdge = Edge & {
  __typename?: 'AlertTriggerEdge';
  cursor: Scalars['ID']['output'];
  node: AlertTrigger;
};

export type AlertTriggerPagination = Pagination & {
  __typename?: 'AlertTriggerPagination';
  edges: Array<AlertTriggerEdge>;
  nodes: Array<AlertTrigger>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export enum AlertType {
  CompanyUserFirstSignIn = 'COMPANY_USER_FIRST_SIGN_IN',
  MangopayUserFraudulant = 'MANGOPAY_USER_FRAUDULANT',
  MangopayWalletBalanceDecrease = 'MANGOPAY_WALLET_BALANCE_DECREASE'
}

export type AlgoliaIndexName = {
  __typename?: 'AlgoliaIndexName';
  entity: Scalars['String']['output'];
  environment: Scalars['String']['output'];
  locale: Scalars['String']['output'];
  txt: Scalars['String']['output'];
};

export type ApiCreatedData = EventData & {
  __typename?: 'ApiCreatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  name?: Maybe<TrackableString>;
  scopes?: Maybe<TrackableApiKeyScopes>;
};

export type ApiDeletedData = EventData & {
  __typename?: 'ApiDeletedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
};

export type ApiKey = DeletableNode & Node & {
  __typename?: 'ApiKey';
  clientId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  requestPagination: ApiRequestPagination;
  scopes: Array<ApiKeyScope>;
  updatedAt: Scalars['DateTime']['output'];
};


export type ApiKeyRequestPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ApiKeyCreateInput = {
  name: Scalars['String']['input'];
  scopes: Array<ApiKeyScope>;
};

export type ApiKeyCreateOutput = {
  __typename?: 'ApiKeyCreateOutput';
  apiKey: ApiKey;
  clientSecret: Scalars['String']['output'];
};

export type ApiKeyDeleteOutput = {
  __typename?: 'ApiKeyDeleteOutput';
  apiKey: ApiKey;
};

export type ApiKeyEdge = Edge & {
  __typename?: 'ApiKeyEdge';
  cursor: Scalars['ID']['output'];
  node: ApiKey;
};

export type ApiKeyPagination = Pagination & {
  __typename?: 'ApiKeyPagination';
  edges: Array<ApiKeyEdge>;
  nodes: Array<ApiKey>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export enum ApiKeyScope {
  UserAll = 'USER_ALL',
  UserCreate = 'USER_CREATE',
  UserUpdate = 'USER_UPDATE'
}

export type ApiKeyUpdateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  scopes?: InputMaybe<Array<ApiKeyScope>>;
};

export type ApiKeyUpdateOutput = {
  __typename?: 'ApiKeyUpdateOutput';
  apiKey: ApiKey;
};

export type ApiRequest = DeletableNode & Node & {
  __typename?: 'ApiRequest';
  apiKey: ApiKey;
  body?: Maybe<Scalars['JSONObject']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  duration: Scalars['Int']['output'];
  endedAt: Scalars['DateTime']['output'];
  headers?: Maybe<Scalars['JSONObject']['output']>;
  host: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  idempotencyKey?: Maybe<Scalars['String']['output']>;
  ip: Scalars['String']['output'];
  method: ApiRequestMethod;
  params?: Maybe<Scalars['JSONObject']['output']>;
  path: Scalars['String']['output'];
  result?: Maybe<Scalars['JSONObject']['output']>;
  startedAt: Scalars['DateTime']['output'];
  statusCode?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ApiRequestEdge = Edge & {
  __typename?: 'ApiRequestEdge';
  cursor: Scalars['ID']['output'];
  node: ApiRequest;
};

export enum ApiRequestMethod {
  Delete = 'DELETE',
  Get = 'GET',
  Options = 'OPTIONS',
  Post = 'POST',
  Put = 'PUT'
}

export type ApiRequestPagination = Pagination & {
  __typename?: 'ApiRequestPagination';
  edges: Array<ApiRequestEdge>;
  nodes: Array<ApiRequest>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ApiUpdatedData = EventData & {
  __typename?: 'ApiUpdatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  name?: Maybe<TrackableString>;
  scopes?: Maybe<TrackableApiKeyScopes>;
};

export type AppdragBillet = Node & {
  __typename?: 'AppdragBillet';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AppdragCarteManuelle = Node & {
  __typename?: 'AppdragCarteManuelle';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum AppdragCarteManuelleStatus {
  InProgress = 'IN_PROGRESS',
  Sent = 'SENT',
  SentMail = 'SENT_MAIL'
}

export type AppdragCompany = {
  __typename?: 'AppdragCompany';
  Account_Manager: Scalars['String']['output'];
  Nb_de_collaborateurs: Scalars['String']['output'];
  code: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isMigrated: Scalars['Int']['output'];
  logo: Scalars['String']['output'];
  nom: Scalars['String']['output'];
  nomHPP: Scalars['String']['output'];
  premium: Scalars['Int']['output'];
};

export type AppdragExportTransactionsOutput = {
  __typename?: 'AppdragExportTransactionsOutput';
  appdragCompanyId?: Maybe<Scalars['Int']['output']>;
};

export type AppdragMigrationCompanyDeleteInput = {
  appdragCompanyId: Scalars['Int']['input'];
};

export type AppdragMigrationCompanyDeleteOutput = {
  __typename?: 'AppdragMigrationCompanyDeleteOutput';
  companyId: Scalars['ID']['output'];
};

export type AppdragMigrationSynchronization = Node & {
  __typename?: 'AppdragMigrationSynchronization';
  appdragCompanyId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  error?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  status: AppdragMigrationSynchronizationStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export type AppdragMigrationSynchronizationCreateInput = {
  appdragCompanyId: Scalars['Int']['input'];
};

export type AppdragMigrationSynchronizationCreateOutput = {
  __typename?: 'AppdragMigrationSynchronizationCreateOutput';
  synchronization: AppdragMigrationSynchronization;
};

export type AppdragMigrationSynchronizationEdge = Edge & {
  __typename?: 'AppdragMigrationSynchronizationEdge';
  cursor: Scalars['ID']['output'];
  node: AppdragMigrationSynchronization;
};

export enum AppdragMigrationSynchronizationStatus {
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  Failed = 'FAILED'
}

export type AppdragMigrationsSynchronizationsPagination = Pagination & {
  __typename?: 'AppdragMigrationsSynchronizationsPagination';
  edges: Array<AppdragMigrationSynchronizationEdge>;
  nodes: Array<AppdragMigrationSynchronization>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AppdragOrder = Node & {
  __typename?: 'AppdragOrder';
  company?: Maybe<Company>;
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['Currency']['output'];
  id: Scalars['ID']['output'];
  invoice: Scalars['String']['output'];
  invoiceFile: File;
  productItems: Array<AppdragOrderLineItem>;
  totalAmountAfterTaxes: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

export type AppdragOrderInvoice = Node & {
  __typename?: 'AppdragOrderInvoice';
  appdragOrderId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AppdragOrderLineItem = {
  __typename?: 'AppdragOrderLineItem';
  amount: Scalars['Int']['output'];
  carteManuelle: Scalars['Boolean']['output'];
  carteManuelleCode?: Maybe<Scalars['String']['output']>;
  carteManuelleStatus?: Maybe<AppdragCarteManuelleStatus>;
  currency: Scalars['String']['output'];
  downloadableProducts?: Maybe<Array<File>>;
  id: Scalars['Int']['output'];
  logo: Scalars['String']['output'];
  name: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  totalAmount: Scalars['Int']['output'];
  urlProducts?: Maybe<Array<Scalars['String']['output']>>;
};

export type AppdragPerk = {
  __typename?: 'AppdragPerk';
  MIGRATION_export: Scalars['Int']['output'];
  actif: Scalars['Int']['output'];
  codeListe: Scalars['String']['output'];
  codePromo: Scalars['String']['output'];
  codeType: Scalars['String']['output'];
  code_unique: Scalars['Int']['output'];
  conditionsOffre: Scalars['String']['output'];
  creationDate: Scalars['DateTime']['output'];
  dateDebut?: Maybe<Scalars['DateTime']['output']>;
  dateFin?: Maybe<Scalars['DateTime']['output']>;
  descriptionOffre: Scalars['String']['output'];
  format: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  remiseMax?: Maybe<Scalars['Int']['output']>;
  source: Scalars['String']['output'];
  titreOffre: Scalars['String']['output'];
  unite: Scalars['String']['output'];
  urlExterne: Scalars['String']['output'];
};

export type Article = DeletableNode & Highlightable & IArticleTranslation & Node & Publishable & Translatable & Translation & {
  __typename?: 'Article';
  /** @deprecated use "isExclusive" instead */
  accessMode: ArticleAccessMode;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  attachmentsPagination: FilesPagination;
  auditTrailPagination: AuditTrailPagination;
  author?: Maybe<User>;
  category: ArticleCategory;
  cohort?: Maybe<CohortUser>;
  contentDelta: Scalars['Delta']['output'];
  contentHTML: Scalars['String']['output'];
  contentText: Scalars['String']['output'];
  cover?: Maybe<Picture>;
  coverURL?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isExclusive: Scalars['Boolean']['output'];
  isHighlighted: Scalars['Boolean']['output'];
  locale: Scalars['ID']['output'];
  publicationPagination: PublicationPagination;
  title: Scalars['String']['output'];
  /** Args is not used. */
  translationsPagination: ArticleTranslationsPagination;
  updatedAt: Scalars['DateTime']['output'];
};


export type ArticleAttachmentsPaginationArgs = {
  filter?: InputMaybe<FilesFilterArgs>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type ArticleAuditTrailPaginationArgs = {
  filter?: InputMaybe<AuditTrailArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<AuditTrailArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type ArticlePublicationPaginationArgs = {
  filter?: InputMaybe<PublicationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<PublicationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type ArticleTranslationsPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export enum ArticleAccessMode {
  Blacklist = 'BLACKLIST',
  Whitelist = 'WHITELIST'
}

export type ArticleAddAttachmentInput = {
  attachmentIds: Array<Scalars['ID']['input']>;
};

export type ArticleAddAttachmentOutput = {
  __typename?: 'ArticleAddAttachmentOutput';
  article: Article;
};

export type ArticleArchivedData = EventData & {
  __typename?: 'ArticleArchivedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
};

export type ArticleCategoriesPagination = Pagination & {
  __typename?: 'ArticleCategoriesPagination';
  edges: Array<ArticleCategoryEdge>;
  nodes: Array<ArticleCategory>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ArticleCategory = DeletableNode & IArticleCategoryTranslation & Node & Translatable & Translation & {
  __typename?: 'ArticleCategory';
  articlesPagination: ArticlesPagination;
  company?: Maybe<Company>;
  cover?: Maybe<Picture>;
  coverURL?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  locale: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** Args is not used. */
  translationsPagination: ArticleCategoryTranslationsPagination;
  updatedAt: Scalars['DateTime']['output'];
};


export type ArticleCategoryArticlesPaginationArgs = {
  filter?: InputMaybe<ArticlesPaginationFilterArgs>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<ArticlesPaginationSorterArgs>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type ArticleCategoryTranslationsPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ArticleCategoryArgsFilter = {
  company?: InputMaybe<CompaniesArgsFilter>;
  id?: InputMaybe<FilterIdArgs>;
};

export type ArticleCategoryArgsSorter = {
  createdAt?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type ArticleCategoryCreateInput = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  coverId?: InputMaybe<Scalars['ID']['input']>;
  localeId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type ArticleCategoryCreateOutput = {
  __typename?: 'ArticleCategoryCreateOutput';
  articleCategory: ArticleCategory;
};

export type ArticleCategoryDeleteOutput = {
  __typename?: 'ArticleCategoryDeleteOutput';
  articleCategory: ArticleCategory;
};

export type ArticleCategoryEdge = Edge & {
  __typename?: 'ArticleCategoryEdge';
  cursor: Scalars['ID']['output'];
  node: ArticleCategory;
};

export type ArticleCategoryTranslation = IArticleCategoryTranslation & Node & Translation & {
  __typename?: 'ArticleCategoryTranslation';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  locale: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ArticleCategoryTranslationCreateInput = {
  name: Scalars['String']['input'];
};

export type ArticleCategoryTranslationCreateOutput = {
  __typename?: 'ArticleCategoryTranslationCreateOutput';
  articleCategory: ArticleCategory;
  articleCategoryTranslation: ArticleCategoryTranslation;
};

export type ArticleCategoryTranslationDeleteOutput = {
  __typename?: 'ArticleCategoryTranslationDeleteOutput';
  articleCategory: ArticleCategory;
  articleCategoryTranslation: ArticleCategoryTranslation;
};

export type ArticleCategoryTranslationEdge = Edge & TranslationEdge & {
  __typename?: 'ArticleCategoryTranslationEdge';
  cursor: Scalars['ID']['output'];
  node: ArticleCategoryTranslation;
};

export type ArticleCategoryTranslationUpdateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ArticleCategoryTranslationUpdateOutput = {
  __typename?: 'ArticleCategoryTranslationUpdateOutput';
  articleCategory: ArticleCategory;
  articleCategoryTranslation: ArticleCategoryTranslation;
};

export type ArticleCategoryTranslationsPagination = Pagination & TranslationsPagination & {
  __typename?: 'ArticleCategoryTranslationsPagination';
  edges: Array<ArticleCategoryTranslationEdge>;
  nodes: Array<ArticleCategoryTranslation>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ArticleCategoryUpdateInput = {
  coverId?: InputMaybe<Scalars['ID']['input']>;
  localeId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ArticleCategoryUpdateOutput = {
  __typename?: 'ArticleCategoryUpdateOutput';
  articleCategory: ArticleCategory;
};

export type ArticleCreateInput = {
  /** Only for super admin, default is whitelist */
  accessMode?: InputMaybe<ArticleAccessMode>;
  attachmentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  cohortId?: InputMaybe<Scalars['ID']['input']>;
  cohortTarget?: InputMaybe<CohortTarget>;
  content?: InputMaybe<Scalars['Delta']['input']>;
  coverId?: InputMaybe<Scalars['ID']['input']>;
  excludedCompanyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  includedCompanyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  isExclusive?: InputMaybe<Scalars['Boolean']['input']>;
  selectionMode?: InputMaybe<CohortSelectionMode>;
  title?: InputMaybe<Scalars['String']['input']>;
  translations?: InputMaybe<Array<ArticleTranslationCreateInput>>;
  usersArgsFilter?: InputMaybe<UsersArgsFilter>;
};

export type ArticleCreateOutput = {
  __typename?: 'ArticleCreateOutput';
  article: Article;
};

export type ArticleCreatedData = EventData & {
  __typename?: 'ArticleCreatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  cohortId?: Maybe<TrackableString>;
  cohortTarget?: Maybe<TrackableCohortTarget>;
};

export type ArticleDeleteOutput = {
  __typename?: 'ArticleDeleteOutput';
  article: Article;
};

export type ArticleDeletedData = EventData & {
  __typename?: 'ArticleDeletedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
};

export type ArticleEdge = Edge & {
  __typename?: 'ArticleEdge';
  cursor: Scalars['ID']['output'];
  node: Article;
};

export type ArticlePaginationArgsFilterName = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type ArticleRemoveAttachmentInput = {
  attachmentIds: Array<Scalars['ID']['input']>;
};

export type ArticleRemoveAttachmentOutput = {
  __typename?: 'ArticleRemoveAttachmentOutput';
  article: Article;
};

export type ArticleTranslation = IArticleTranslation & Node & Translation & {
  __typename?: 'ArticleTranslation';
  contentDelta: Scalars['Delta']['output'];
  contentHTML: Scalars['String']['output'];
  contentText: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  locale: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ArticleTranslationCreateInput = {
  content: Scalars['Delta']['input'];
  localeId?: InputMaybe<Scalars['ID']['input']>;
  title: Scalars['String']['input'];
};

export type ArticleTranslationCreateOutput = {
  __typename?: 'ArticleTranslationCreateOutput';
  article: Article;
  articleTranslation: ArticleTranslation;
};

export type ArticleTranslationCreatedData = EventData & {
  __typename?: 'ArticleTranslationCreatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  articleId?: Maybe<TrackableString>;
  contentDelta?: Maybe<TrackableDelta>;
  contentHTML?: Maybe<TrackableString>;
  contentText?: Maybe<TrackableString>;
  title?: Maybe<TrackableString>;
};

export type ArticleTranslationDeleteOutput = {
  __typename?: 'ArticleTranslationDeleteOutput';
  article: Article;
  articleTranslation: ArticleTranslation;
};

export type ArticleTranslationDeletedData = EventData & {
  __typename?: 'ArticleTranslationDeletedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
};

export type ArticleTranslationEdge = Edge & TranslationEdge & {
  __typename?: 'ArticleTranslationEdge';
  cursor: Scalars['ID']['output'];
  node: ArticleTranslation;
};

export type ArticleTranslationUpdateInput = {
  content?: InputMaybe<Scalars['Delta']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ArticleTranslationUpdateOutput = {
  __typename?: 'ArticleTranslationUpdateOutput';
  article: Article;
  articleTranslation: ArticleTranslation;
};

export type ArticleTranslationUpdatedData = EventData & {
  __typename?: 'ArticleTranslationUpdatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  contentDelta?: Maybe<TrackableDelta>;
  contentHTML?: Maybe<TrackableString>;
  contentText?: Maybe<TrackableString>;
  title?: Maybe<TrackableString>;
};

export type ArticleTranslationsPagination = Pagination & TranslationsPagination & {
  __typename?: 'ArticleTranslationsPagination';
  edges: Array<ArticleTranslationEdge>;
  nodes: Array<ArticleTranslation>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ArticleUnarchivedData = EventData & {
  __typename?: 'ArticleUnarchivedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
};

export type ArticleUpdateInput = {
  /** Only for super admin, default is whitelist */
  accessMode?: InputMaybe<ArticleAccessMode>;
  attachmentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  cohortId?: InputMaybe<Scalars['ID']['input']>;
  cohortTarget?: InputMaybe<CohortTarget>;
  content?: InputMaybe<Scalars['Delta']['input']>;
  coverId?: InputMaybe<Scalars['ID']['input']>;
  excludedCompanyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  includedCompanyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  isExclusive?: InputMaybe<Scalars['Boolean']['input']>;
  selectionMode?: InputMaybe<CohortSelectionMode>;
  title?: InputMaybe<Scalars['String']['input']>;
  usersArgsFilter?: InputMaybe<UsersArgsFilter>;
};

export type ArticleUpdateOutput = {
  __typename?: 'ArticleUpdateOutput';
  article: Article;
};

export type ArticleUpdatedData = EventData & {
  __typename?: 'ArticleUpdatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  cohortId?: Maybe<TrackableString>;
  cohortTarget?: Maybe<TrackableCohortTarget>;
};

export type ArticlesFilterArgsAccessMode = {
  in?: InputMaybe<Array<ArticleAccessMode>>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<ArticleAccessMode>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<ArticleAccessMode>;
  notIn?: InputMaybe<Array<ArticleAccessMode>>;
};

export type ArticlesPagination = Pagination & {
  __typename?: 'ArticlesPagination';
  edges: Array<ArticleEdge>;
  nodes: Array<Article>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ArticlesPaginationFilterArgs = {
  AND?: InputMaybe<Array<ArticlesPaginationFilterArgs>>;
  OR?: InputMaybe<Array<ArticlesPaginationFilterArgs>>;
  accessMode?: InputMaybe<ArticlesFilterArgsAccessMode>;
  archivedAt?: InputMaybe<FilterDateArgs>;
  author?: InputMaybe<UsersArgsFilter>;
  category?: InputMaybe<ArticleCategoryArgsFilter>;
  company?: InputMaybe<CompaniesArgsFilter>;
  createdAt?: InputMaybe<FilterDateArgs>;
  excludedCompanies?: InputMaybe<CompaniesArgsFilter>;
  id?: InputMaybe<FilterIdArgs>;
  includedCompanies?: InputMaybe<CompaniesArgsFilter>;
  name?: InputMaybe<ArticlePaginationArgsFilterName>;
  updatedAt?: InputMaybe<FilterDateArgs>;
};

export type ArticlesPaginationSorterArgs = {
  createdAt?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type ArticlesRelationFilter = {
  NONE?: InputMaybe<ArticlesPaginationFilterArgs>;
  SOME?: InputMaybe<ArticlesPaginationFilterArgs>;
};

export type AuditTrail = Node & WithAuthor & {
  __typename?: 'AuditTrail';
  author?: Maybe<User>;
  createdAt: Scalars['DateTime']['output'];
  data: EventData;
  eventType: EventTypes;
  id: Scalars['ID']['output'];
  target: Node;
  updatedAt: Scalars['DateTime']['output'];
};

export type AuditTrailArgsFilter = {
  AND?: InputMaybe<Array<AuditTrailArgsFilter>>;
  OR?: InputMaybe<Array<AuditTrailArgsFilter>>;
  author?: InputMaybe<UsersArgsFilter>;
  createdAt?: InputMaybe<FilterDateArgs>;
  eventType?: InputMaybe<FilterEventTypesArgs>;
  targetId?: InputMaybe<FilterIdArgs>;
};

export type AuditTrailArgsSorter = {
  createdAt?: InputMaybe<SortDirection>;
  eventType?: InputMaybe<SortDirection>;
};

export type AuditTrailEdge = Edge & {
  __typename?: 'AuditTrailEdge';
  cursor: Scalars['ID']['output'];
  node: AuditTrail;
};

export type AuditTrailPagination = Pagination & {
  __typename?: 'AuditTrailPagination';
  edges: Array<AuditTrailEdge>;
  nodes: Array<AuditTrail>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AuthenticationForceResetPasswordOutput = {
  __typename?: 'AuthenticationForceResetPasswordOutput';
  user: User;
};

export type AuthenticationGeneratePasswordResetTokenOutput = {
  __typename?: 'AuthenticationGeneratePasswordResetTokenOutput';
  url: Scalars['String']['output'];
};

export type AuthenticationPasswordUpdateInput = {
  newPassword: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type AuthenticationPasswordUpdateOutput = {
  __typename?: 'AuthenticationPasswordUpdateOutput';
  user: User;
};

export type AuthenticationSessionTokenCreateOutput = {
  __typename?: 'AuthenticationSessionTokenCreateOutput';
  token: Scalars['String']['output'];
};

export type AuthorOutput = {
  __typename?: 'AuthorOutput';
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export type BankAccountAddress = {
  __typename?: 'BankAccountAddress';
  address1: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  province?: Maybe<Scalars['String']['output']>;
  zip: Scalars['String']['output'];
};

export type BeneficiariesArgsFilter = {
  AND?: InputMaybe<Array<BeneficiariesArgsFilter>>;
  OR?: InputMaybe<Array<BeneficiariesArgsFilter>>;
  acceptedAt?: InputMaybe<FilterDateArgs>;
  acceptedBy?: InputMaybe<UsersArgsFilter>;
  birthDate?: InputMaybe<FilterDateOnlyArgs>;
  firstName?: InputMaybe<FilterStringArgs>;
  lastName?: InputMaybe<FilterStringArgs>;
  proofs?: InputMaybe<FileRelationFilter>;
  rejectedAt?: InputMaybe<FilterDateArgs>;
  rejectedBy?: InputMaybe<UsersArgsFilter>;
  rejectedReason?: InputMaybe<FilterStringArgs>;
  rejectedType?: InputMaybe<BeneficiariesArgsFilterRejectedType>;
  relationship?: InputMaybe<BeneficiariesArgsFilterRelationship>;
  status?: InputMaybe<BeneficiariesArgsFilterStatus>;
  unionDate?: InputMaybe<FilterDateOnlyArgs>;
};

export type BeneficiariesArgsFilterRejectedType = {
  in?: InputMaybe<Array<BeneficiaryRejectedType>>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<BeneficiaryRejectedType>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<BeneficiaryRejectedType>;
  notIn?: InputMaybe<Array<BeneficiaryRejectedType>>;
};

export type BeneficiariesArgsFilterRelationship = {
  in?: InputMaybe<Array<BeneficiaryRelationship>>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<BeneficiaryRelationship>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<BeneficiaryRelationship>;
  notIn?: InputMaybe<Array<BeneficiaryRelationship>>;
};

export type BeneficiariesArgsFilterStatus = {
  in?: InputMaybe<Array<BeneficiaryStatus>>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<BeneficiaryStatus>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<BeneficiaryStatus>;
  notIn?: InputMaybe<Array<BeneficiaryStatus>>;
};

export type BeneficiariesArgsSorter = {
  acceptedAt?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  rejectedAt?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type BeneficiariesPagination = Pagination & {
  __typename?: 'BeneficiariesPagination';
  edges: Array<BeneficiaryEdge>;
  nodes: Array<Beneficiary>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type BeneficiariesRelationFilter = {
  EVERY?: InputMaybe<BeneficiariesArgsFilter>;
  NONE?: InputMaybe<BeneficiariesArgsFilter>;
  SOME?: InputMaybe<BeneficiariesArgsFilter>;
};

export type BeneficiariesSynchronization = {
  companyId: Scalars['ID']['input'];
};

export type Beneficiary = DeletableNode & Node & TrailableNode & {
  __typename?: 'Beneficiary';
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  acceptedBy?: Maybe<User>;
  auditTrailPagination: AuditTrailPagination;
  birthDate: Scalars['Date']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastFollowUpDate?: Maybe<Scalars['DateTime']['output']>;
  lastName: Scalars['String']['output'];
  proofsPagination: FilesPagination;
  rejectedAt?: Maybe<Scalars['DateTime']['output']>;
  rejectedBy?: Maybe<User>;
  rejectedReason?: Maybe<Scalars['String']['output']>;
  rejectedType?: Maybe<BeneficiaryRejectedType>;
  relationship: BeneficiaryRelationship;
  status: BeneficiaryStatus;
  unionDate?: Maybe<Scalars['Date']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};


export type BeneficiaryAuditTrailPaginationArgs = {
  filter?: InputMaybe<AuditTrailArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<AuditTrailArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type BeneficiaryProofsPaginationArgs = {
  filter?: InputMaybe<FilesFilterArgs>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type BeneficiaryAcceptOutput = {
  __typename?: 'BeneficiaryAcceptOutput';
  beneficiary: Beneficiary;
  user: User;
};

export type BeneficiaryAcceptedData = EventData & {
  __typename?: 'BeneficiaryAcceptedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
};

export type BeneficiaryAddedProofdData = EventData & WithFiles & {
  __typename?: 'BeneficiaryAddedProofdData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  files: Array<File>;
};

export type BeneficiaryCompanyConfig = FeatureConfig & {
  __typename?: 'BeneficiaryCompanyConfig';
  canUpdateChildren: Scalars['Boolean']['output'];
  canUpdatePartner: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** This id matches the company ID */
  id: Scalars['ID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type BeneficiaryCreateInput = {
  birthDate: Scalars['Date']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  proofIds: Array<Scalars['ID']['input']>;
  relationship: BeneficiaryRelationship;
  unionDate?: InputMaybe<Scalars['Date']['input']>;
};

export type BeneficiaryCreateOutput = {
  __typename?: 'BeneficiaryCreateOutput';
  beneficiary: Beneficiary;
  user: User;
};

export type BeneficiaryCreatedData = BeneficiaryDataFields & EventData & WithFiles & {
  __typename?: 'BeneficiaryCreatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  birthDate?: Maybe<TrackableDate>;
  files: Array<File>;
  firstName?: Maybe<TrackableString>;
  lastName?: Maybe<TrackableString>;
  relationship?: Maybe<TrackableBeneficiaryRelationship>;
  unionDate?: Maybe<TrackableDate>;
};

export type BeneficiaryDataFields = {
  birthDate?: Maybe<TrackableDate>;
  firstName?: Maybe<TrackableString>;
  lastName?: Maybe<TrackableString>;
  relationship?: Maybe<TrackableBeneficiaryRelationship>;
  unionDate?: Maybe<TrackableDate>;
};

export type BeneficiaryDeleteOutput = {
  __typename?: 'BeneficiaryDeleteOutput';
  beneficiary: Beneficiary;
  user: User;
};

export type BeneficiaryDeletedData = EventData & {
  __typename?: 'BeneficiaryDeletedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
};

export type BeneficiaryEdge = Edge & {
  __typename?: 'BeneficiaryEdge';
  cursor: Scalars['ID']['output'];
  node: Beneficiary;
};

export type BeneficiaryFeatureEnableOutput = {
  __typename?: 'BeneficiaryFeatureEnableOutput';
  beneficiaryCompanyConfig: BeneficiaryCompanyConfig;
};

export type BeneficiaryPagination = CorePagination & {
  __typename?: 'BeneficiaryPagination';
  edges: Array<BeneficiaryEdge>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type BeneficiaryPaginationFilter = {
  statuses?: InputMaybe<Array<BeneficiaryStatus>>;
  user?: InputMaybe<UserPaginationFilter>;
};

export type BeneficiaryPaginationSorter = {
  direction: SortDirection;
  field: BeneficiaryPaginationSorterField;
};

export enum BeneficiaryPaginationSorterField {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type BeneficiaryProofAddInput = {
  proofIds: Array<Scalars['ID']['input']>;
};

export type BeneficiaryProofAddOutput = {
  __typename?: 'BeneficiaryProofAddOutput';
  beneficiary: Beneficiary;
};

export type BeneficiaryProofRemoveInput = {
  proofIds: Array<Scalars['ID']['input']>;
};

export type BeneficiaryProofRemoveOutput = {
  __typename?: 'BeneficiaryProofRemoveOutput';
  beneficiary: Beneficiary;
};

export type BeneficiaryRejectFollowUpOutput = {
  __typename?: 'BeneficiaryRejectFollowUpOutput';
  beneficiary: Beneficiary;
  user: User;
};

export type BeneficiaryRejectInput = {
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  rejectedType: BeneficiaryRejectedType;
};

export type BeneficiaryRejectOutput = {
  __typename?: 'BeneficiaryRejectOutput';
  beneficiary: Beneficiary;
  user: User;
};

export type BeneficiaryRejectedData = EventData & {
  __typename?: 'BeneficiaryRejectedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  rejectedReason?: Maybe<Scalars['String']['output']>;
  rejectedType: BeneficiaryRejectedType;
};

/** Beneficiary Rejected Type */
export enum BeneficiaryRejectedType {
  BeneficiaryAlreadyExist = 'BENEFICIARY_ALREADY_EXIST',
  ExpiredDocument = 'EXPIRED_DOCUMENT',
  InvalidDocument = 'INVALID_DOCUMENT',
  InvalidInformations = 'INVALID_INFORMATIONS',
  Other = 'OTHER',
  UnreadableDocument = 'UNREADABLE_DOCUMENT'
}

/** Beneficiary Relationship */
export enum BeneficiaryRelationship {
  Child = 'CHILD',
  Partner = 'PARTNER'
}

export type BeneficiaryRemovedProofdData = EventData & WithFile & {
  __typename?: 'BeneficiaryRemovedProofdData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  file: File;
};

/** Beneficiary Status */
export enum BeneficiaryStatus {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export enum BeneficiaryTypeFilter {
  Child = 'CHILD',
  ChildAndPartner = 'CHILD_AND_PARTNER',
  ChildOnly = 'CHILD_ONLY',
  None = 'NONE',
  Partner = 'PARTNER',
  PartnerOnly = 'PARTNER_ONLY',
  Some = 'SOME'
}

export type BeneficiaryUpdateInput = {
  birthDate?: InputMaybe<Scalars['Date']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  proofIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  relationship?: InputMaybe<BeneficiaryRelationship>;
  unionDate?: InputMaybe<Scalars['Date']['input']>;
};

export type BeneficiaryUpdateOutput = {
  __typename?: 'BeneficiaryUpdateOutput';
  beneficiary: Beneficiary;
  user: User;
};

export type BeneficiaryUpdatedData = EventData & {
  __typename?: 'BeneficiaryUpdatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  birthDate?: Maybe<TrackableDate>;
  firstName?: Maybe<TrackableString>;
  lastName?: Maybe<TrackableString>;
  relationship?: Maybe<TrackableBeneficiaryRelationship>;
  unionDate?: Maybe<TrackableDate>;
};

export type Brand = IBrandTranslation & Node & Translatable & Translation & {
  __typename?: 'Brand';
  appdragId?: Maybe<Scalars['Int']['output']>;
  auditTrailPagination: AuditTrailPagination;
  campaignPagination: CampaignPagination;
  cover?: Maybe<Picture>;
  coverURL?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  descriptionDelta?: Maybe<Scalars['Delta']['output']>;
  descriptionHTML?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isExclusive: Scalars['Boolean']['output'];
  locale: Scalars['ID']['output'];
  logo?: Maybe<Picture>;
  logoURL?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  offersPagination: OffersPagination;
  productPagination: ProductPagination;
  storePagination: StorePagination;
  /** Args is not used. */
  translationsPagination: BrandTranslationsPagination;
  updatedAt: Scalars['DateTime']['output'];
};


export type BrandAuditTrailPaginationArgs = {
  filter?: InputMaybe<AuditTrailArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<AuditTrailArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type BrandCampaignPaginationArgs = {
  filter?: InputMaybe<CampaignPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CampaignPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type BrandOffersPaginationArgs = {
  filter?: InputMaybe<OffersArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<OffersArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type BrandProductPaginationArgs = {
  filter?: InputMaybe<ProductPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<ProductPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type BrandStorePaginationArgs = {
  filter?: InputMaybe<StoreArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<StoreArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type BrandTranslationsPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type BrandCreateInput = {
  coverId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['Delta']['input']>;
  isExclusive?: InputMaybe<Scalars['Boolean']['input']>;
  localeId: Scalars['ID']['input'];
  logoId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type BrandCreateOutput = {
  __typename?: 'BrandCreateOutput';
  brand: Brand;
};

export type BrandCreatedData = EventData & WithCover & WithLogo & {
  __typename?: 'BrandCreatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  cover?: Maybe<Picture>;
  description?: Maybe<TrackableDelta>;
  isExclusive?: Maybe<TrackableBoolean>;
  locale?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Picture>;
  name?: Maybe<TrackableString>;
};

export type BrandEdge = Edge & {
  __typename?: 'BrandEdge';
  cursor: Scalars['ID']['output'];
  node: Brand;
};

export type BrandMergeDuplicateInput = {
  sourceIds: Array<Scalars['ID']['input']>;
  targetId: Scalars['ID']['input'];
};

export type BrandMergeDuplicateOutput = {
  __typename?: 'BrandMergeDuplicateOutput';
  brand: Brand;
};

export type BrandTranslation = IBrandTranslation & Node & Translation & {
  __typename?: 'BrandTranslation';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  descriptionDelta?: Maybe<Scalars['Delta']['output']>;
  descriptionHTML?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isGenerated: Scalars['Boolean']['output'];
  isMaster: Scalars['Boolean']['output'];
  locale: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type BrandTranslationCreateInput = {
  description?: InputMaybe<Scalars['Delta']['input']>;
  name: Scalars['String']['input'];
};

export type BrandTranslationCreateOutput = {
  __typename?: 'BrandTranslationCreateOutput';
  brand: Brand;
  brandTranslation: BrandTranslation;
};

export type BrandTranslationCreatedData = BrandTranslationDataFields & EventData & {
  __typename?: 'BrandTranslationCreatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  description?: Maybe<TrackableDelta>;
  locale: Scalars['ID']['output'];
  name?: Maybe<TrackableString>;
};

export type BrandTranslationDataFields = {
  locale: Scalars['ID']['output'];
};

export type BrandTranslationDeleteOutput = {
  __typename?: 'BrandTranslationDeleteOutput';
  brand: Brand;
  brandTranslation: BrandTranslation;
};

export type BrandTranslationDeletedData = BrandTranslationDataFields & EventData & {
  __typename?: 'BrandTranslationDeletedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  locale: Scalars['ID']['output'];
};

export type BrandTranslationEdge = Edge & TranslationEdge & {
  __typename?: 'BrandTranslationEdge';
  cursor: Scalars['ID']['output'];
  node: BrandTranslation;
};

export type BrandTranslationUpdateInput = {
  description?: InputMaybe<Scalars['Delta']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type BrandTranslationUpdateOutput = {
  __typename?: 'BrandTranslationUpdateOutput';
  brand: Brand;
  brandTranslation: BrandTranslation;
};

export type BrandTranslationUpdatedData = BrandTranslationDataFields & EventData & {
  __typename?: 'BrandTranslationUpdatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  description?: Maybe<TrackableDelta>;
  locale: Scalars['ID']['output'];
  name?: Maybe<TrackableString>;
};

export type BrandTranslationsPagination = Pagination & TranslationsPagination & {
  __typename?: 'BrandTranslationsPagination';
  edges: Array<BrandTranslationEdge>;
  nodes: Array<BrandTranslation>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type BrandUpdateInput = {
  coverId?: InputMaybe<Scalars['ID']['input']>;
  isExclusive?: InputMaybe<Scalars['Boolean']['input']>;
  logoId?: InputMaybe<Scalars['ID']['input']>;
};

export type BrandUpdateOutput = {
  __typename?: 'BrandUpdateOutput';
  brand: Brand;
};

export type BrandUpdatedData = EventData & WithCover & WithLogo & {
  __typename?: 'BrandUpdatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  cover?: Maybe<Picture>;
  isExclusive?: Maybe<TrackableBoolean>;
  logo?: Maybe<Picture>;
};

export type BrandsArgsFilter = {
  AND?: InputMaybe<Array<BrandsArgsFilter>>;
  OR?: InputMaybe<PictureFilterArgs>;
  campaigns?: InputMaybe<CampaignRelationArgsFilter>;
  categories?: InputMaybe<FilterIdArgs>;
  cover?: InputMaybe<PictureFilterArgs>;
  hasGeneratedTranslations?: InputMaybe<Scalars['Boolean']['input']>;
  hasMissingTranslations?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<FilterIdArgs>;
  isExclusive?: InputMaybe<FilterBoolArgs>;
  logo?: InputMaybe<PictureFilterArgs>;
  name?: InputMaybe<BrandsArgsFilterName>;
  offers?: InputMaybe<OffersRelationFilter>;
  products?: InputMaybe<BrandsArgsFilterProducts>;
  search?: InputMaybe<Scalars['String']['input']>;
  store?: InputMaybe<StoreArgsFilter>;
};

export type BrandsArgsFilterName = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type BrandsArgsFilterProducts = {
  min?: InputMaybe<Scalars['Int']['input']>;
};

export type BrandsArgsSorter = {
  campaignCount?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  offerCount?: InputMaybe<SortDirection>;
  productCount?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type BrandsPagination = Pagination & {
  __typename?: 'BrandsPagination';
  edges: Array<BrandEdge>;
  nodes: Array<Brand>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type BudgetBySubventionSchema = {
  __typename?: 'BudgetBySubventionSchema';
  /** @deprecated Use budgetBigInt instead */
  budget: Scalars['Int']['output'];
  budgetBigInt: Scalars['BigInt']['output'];
  /** @deprecated Use consumedBigInt instead */
  consumed: Scalars['Int']['output'];
  consumedBigInt: Scalars['BigInt']['output'];
  subventionSchema: SubventionSchema;
};

export type BudgetBySubventionSchemaOutput = {
  __typename?: 'BudgetBySubventionSchemaOutput';
  /** @deprecated Use budgetTotalBigInt instead */
  budgetTotal: Scalars['Int']['output'];
  budgetTotalBigInt: Scalars['BigInt']['output'];
  /** @deprecated Use consumedTotalBigInt instead */
  consumedTotal: Scalars['Int']['output'];
  consumedTotalBigInt: Scalars['BigInt']['output'];
  subventionSchemas: Array<BudgetBySubventionSchema>;
};

export type Campaign = DeletableNode & ICampaignTranslation & Node & Publishable & Translatable & Translation & {
  __typename?: 'Campaign';
  auditTrailPagination: AuditTrailPagination;
  /** @deprecated Use bestOffer on CatalogCampaignEdge instead */
  bestOffer?: Maybe<Offer>;
  brand: Brand;
  cardInfos?: Maybe<CampaignCardInfos>;
  cardMetadata?: Maybe<CardMetadata>;
  categoriesPagination: CategoriesPagination;
  country: CountryCode;
  cover?: Maybe<Picture>;
  coverURL?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionDelta?: Maybe<Scalars['Delta']['output']>;
  descriptionHTML?: Maybe<Scalars['String']['output']>;
  endsAt?: Maybe<Scalars['DateTime']['output']>;
  favorite?: Maybe<FavoriteCampaign>;
  id: Scalars['ID']['output'];
  instructions?: Maybe<Scalars['String']['output']>;
  instructionsDelta?: Maybe<Scalars['Delta']['output']>;
  instructionsHTML?: Maybe<Scalars['String']['output']>;
  isExclusive: Scalars['Boolean']['output'];
  isFavorite: Scalars['Boolean']['output'];
  isHighlighted: Scalars['Boolean']['output'];
  locale: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  netPromoterScore?: Maybe<NetPromoterScore>;
  offersPagination: OffersPagination;
  parentCategoriesPagination: CategoriesPagination;
  publicationPagination: PublicationPagination;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  startsAt?: Maybe<Scalars['DateTime']['output']>;
  status: CampaignStatus;
  thumbnail?: Maybe<Picture>;
  thumbnailURL?: Maybe<Scalars['String']['output']>;
  /** Args is not used. */
  translationsPagination: CampaignTranslationsPagination;
  updatedAt: Scalars['DateTime']['output'];
  userSubventionsPagination: UserSubventionPagination;
  /** Filter not applicable */
  viewerHasRemainingDiscountSubventions: Scalars['Boolean']['output'];
  viewerRemainingDiscountSubventions: Array<SubventionDiscount>;
  viewerRemainingDiscountSubventionsPagination: UserDiscountSubventionPagination;
};


export type CampaignAuditTrailPaginationArgs = {
  filter?: InputMaybe<AuditTrailArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<AuditTrailArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CampaignCategoriesPaginationArgs = {
  filter?: InputMaybe<CategoriesArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CategoriesArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CampaignOffersPaginationArgs = {
  filter?: InputMaybe<OffersArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<OffersArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CampaignParentCategoriesPaginationArgs = {
  filter?: InputMaybe<CategoriesArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CategoriesArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CampaignPublicationPaginationArgs = {
  filter?: InputMaybe<PublicationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<PublicationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CampaignTranslationsPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CampaignUserSubventionsPaginationArgs = {
  filter?: InputMaybe<UserSubventionArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<UserSubventionArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CampaignViewerHasRemainingDiscountSubventionsArgs = {
  filter?: InputMaybe<SubventionArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<SubventionArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CampaignViewerRemainingDiscountSubventionsPaginationArgs = {
  filter?: InputMaybe<SubventionArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<SubventionArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type CampaignArgsFilter = {
  AND?: InputMaybe<Array<CampaignArgsFilter>>;
  OR?: InputMaybe<Array<CampaignArgsFilter>>;
  brand?: InputMaybe<BrandsArgsFilter>;
  categories?: InputMaybe<CategoriesRelationFilter>;
  categoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** This filter is only available at the root pagination */
  cities?: InputMaybe<CampaignsArgsFilterCities>;
  cover?: InputMaybe<PictureFilterArgs>;
  createdAt?: InputMaybe<FilterDateArgs>;
  /** This filter is only available at the root pagination */
  distance?: InputMaybe<CampaignsArgsFilterDistance>;
  hasGeneratedTranslations?: InputMaybe<Scalars['Boolean']['input']>;
  hasMissingTranslations?: InputMaybe<Scalars['Boolean']['input']>;
  highlights?: InputMaybe<CampaignHighlightsRelationFilter>;
  id?: InputMaybe<FilterIdArgs>;
  isExclusive?: InputMaybe<FilterBoolArgs>;
  name?: InputMaybe<FilterStringArgs>;
  offers?: InputMaybe<OffersRelationFilter>;
  /** This filter is only available at the root pagination */
  provinces?: InputMaybe<FilterStringArgs>;
  status?: InputMaybe<CampaignArgsFilterStatus>;
  updatedAt?: InputMaybe<FilterDateArgs>;
};

export type CampaignArgsFilterName = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type CampaignArgsFilterStatus = {
  in?: InputMaybe<Array<CampaignStatus>>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<CampaignStatus>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<CampaignStatus>;
  notIn?: InputMaybe<Array<CampaignStatus>>;
};

export type CampaignCardInfos = {
  __typename?: 'CampaignCardInfos';
  campaignId: Scalars['String']['output'];
  discount?: Maybe<DiscountUnion>;
  /** Number of discount subventions that are available and have funds remaining for this campaign */
  discountSubventionsCount: Scalars['Int']['output'];
  /** Number of fund subventions that are available and have funds remaining for this campaign */
  fundSubventionsCount: Scalars['Int']['output'];
  isFavorite: Scalars['Boolean']['output'];
};

export type CampaignCreateInput = {
  brandId: Scalars['ID']['input'];
  country: CountryCode;
  coverId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['Delta']['input']>;
  endsAt?: InputMaybe<Scalars['DateTime']['input']>;
  instructions?: InputMaybe<Scalars['Delta']['input']>;
  isExclusive?: InputMaybe<Scalars['Boolean']['input']>;
  localeId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  startsAt?: InputMaybe<Scalars['DateTime']['input']>;
  thumbnailId?: InputMaybe<Scalars['ID']['input']>;
};

export type CampaignCreateOutput = {
  __typename?: 'CampaignCreateOutput';
  campaign: Campaign;
};

export type CampaignCreatedData = CampaignCreationDataFields & EventData & WithCover & WithThumbnail & {
  __typename?: 'CampaignCreatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  country?: Maybe<TrackableString>;
  cover?: Maybe<Picture>;
  description?: Maybe<TrackableDelta>;
  endsAt?: Maybe<TrackableDateTime>;
  instructions?: Maybe<TrackableDelta>;
  isExclusive?: Maybe<TrackableBoolean>;
  name?: Maybe<TrackableString>;
  startsAt?: Maybe<TrackableDateTime>;
  thumbnail?: Maybe<Picture>;
};

export type CampaignCreationDataFields = {
  country?: Maybe<TrackableString>;
  description?: Maybe<TrackableDelta>;
  endsAt?: Maybe<TrackableDateTime>;
  instructions?: Maybe<TrackableDelta>;
  isExclusive?: Maybe<TrackableBoolean>;
  name?: Maybe<TrackableString>;
  startsAt?: Maybe<TrackableDateTime>;
};

export type CampaignDeleteOutput = {
  __typename?: 'CampaignDeleteOutput';
  campaign: Campaign;
};

export type CampaignDemoteOutput = {
  __typename?: 'CampaignDemoteOutput';
  campaign: Campaign;
};

export type CampaignDemotedData = EventData & {
  __typename?: 'CampaignDemotedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
};

export type CampaignEdge = Edge & {
  __typename?: 'CampaignEdge';
  cursor: Scalars['ID']['output'];
  node: Campaign;
};

export type CampaignHighlight = Node & {
  __typename?: 'CampaignHighlight';
  /** @deprecated Use discover instead */
  auditTrailPagination: AuditTrailPagination;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type CampaignHighlightAuditTrailPaginationArgs = {
  filter?: InputMaybe<AuditTrailArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<AuditTrailArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type CampaignHighlightCreatedData = EventData & {
  __typename?: 'CampaignHighlightCreatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
};

export type CampaignHighlightDeletedData = EventData & {
  __typename?: 'CampaignHighlightDeletedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
};

export type CampaignHighlightFilter = {
  campaign?: InputMaybe<CampaignsArgsFilter>;
  company?: InputMaybe<CompaniesArgsFilter>;
  id?: InputMaybe<FilterIdArgs>;
  position?: InputMaybe<FilterIntArgs>;
};

export type CampaignHighlightReorderInput = {
  campaignIds: Array<Scalars['ID']['input']>;
};

export type CampaignHighlightReorderOutput = {
  __typename?: 'CampaignHighlightReorderOutput';
  campaigns: Array<Campaign>;
};

export type CampaignHighlightsRelationFilter = {
  NONE?: InputMaybe<CampaignHighlightFilter>;
  SOME?: InputMaybe<CampaignHighlightFilter>;
};

export type CampaignOfferArgsFilter = {
  categoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  types?: InputMaybe<Array<OfferType>>;
};

export type CampaignPagination = Pagination & {
  __typename?: 'CampaignPagination';
  categories: Array<CampaignsPaginationCategoriesEnumFilter>;
  /** @deprecated Please use locations instead */
  cities: Array<Scalars['String']['output']>;
  edges: Array<CampaignEdge>;
  locations: Array<PaginationEnumFilter>;
  nodes: Array<Campaign>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CampaignPaginationArgsFilter = {
  AND?: InputMaybe<Array<CampaignArgsFilter>>;
  OR?: InputMaybe<Array<CampaignArgsFilter>>;
  brand?: InputMaybe<BrandsArgsFilter>;
  categories?: InputMaybe<CategoriesRelationFilter>;
  categoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** This filter is only available at the root pagination */
  cities?: InputMaybe<CampaignsArgsFilterCities>;
  cover?: InputMaybe<PictureFilterArgs>;
  createdAt?: InputMaybe<FilterDateArgs>;
  /** This filter is only available at the root pagination */
  distance?: InputMaybe<CampaignsArgsFilterDistance>;
  hasDiscount?: InputMaybe<FilterBoolArgs>;
  hasGeneratedTranslations?: InputMaybe<Scalars['Boolean']['input']>;
  hasMissingTranslations?: InputMaybe<Scalars['Boolean']['input']>;
  highlights?: InputMaybe<CampaignHighlightsRelationFilter>;
  id?: InputMaybe<FilterIdArgs>;
  isExclusive?: InputMaybe<FilterBoolArgs>;
  name?: InputMaybe<FilterStringArgs>;
  offers?: InputMaybe<OffersRelationFilter>;
  /** This filter is only available at the root pagination */
  provinces?: InputMaybe<FilterStringArgs>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<CampaignArgsFilterStatus>;
  subventionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  subventionSchemaId?: InputMaybe<Scalars['ID']['input']>;
  updatedAt?: InputMaybe<FilterDateArgs>;
};

export type CampaignPaginationArgsSorter = {
  brand?: InputMaybe<SortDirection>;
  categoryCount?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  distance?: InputMaybe<SortDirection>;
  endsAt?: InputMaybe<SortDirection>;
  expiresAt?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  offerCount?: InputMaybe<SortDirection>;
  offerPublishedAt?: InputMaybe<SortDirection>;
  offerPublishedCount?: InputMaybe<SortDirection>;
  physicalStore?: InputMaybe<SortDirection>;
  publishedAt?: InputMaybe<SortDirection>;
  startsAt?: InputMaybe<SortDirection>;
  status?: InputMaybe<SortDirection>;
  totalUniqueViewCount?: InputMaybe<SortDirection>;
  totalViewCount?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type CampaignPromoteOutput = {
  __typename?: 'CampaignPromoteOutput';
  campaign: Campaign;
};

export type CampaignPromotedData = EventData & {
  __typename?: 'CampaignPromotedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
};

export type CampaignPublishOutput = {
  __typename?: 'CampaignPublishOutput';
  campaign: Campaign;
};

export type CampaignPublishedData = EventData & {
  __typename?: 'CampaignPublishedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
};

export type CampaignRelationArgsFilter = {
  EVERY: CampaignArgsFilter;
  NONE?: InputMaybe<CampaignArgsFilter>;
  SOME?: InputMaybe<CampaignArgsFilter>;
};

export type CampaignSelection = ICampaignSelectionTranslation & Node & Translatable & Translation & {
  __typename?: 'CampaignSelection';
  brandsPagination: BrandsPagination;
  campaignPagination: CampaignPagination;
  cover: Picture;
  coverURL: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  descriptionDelta: Scalars['Delta']['output'];
  descriptionHTML: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isExclusive: Scalars['Boolean']['output'];
  locale: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  status: CampaignSelectionStatus;
  /** Args is not used. */
  translationsPagination: CampaignSelectionTranslationsPagination;
  updatedAt: Scalars['DateTime']['output'];
};


export type CampaignSelectionBrandsPaginationArgs = {
  filter?: InputMaybe<BrandsArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<BrandsArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CampaignSelectionCampaignPaginationArgs = {
  filter?: InputMaybe<CampaignPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CampaignPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CampaignSelectionTranslationsPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type CampaignSelectionCampaignsAddOutput = {
  __typename?: 'CampaignSelectionCampaignsAddOutput';
  campaignSelection: CampaignSelection;
  campaigns: Array<Campaign>;
};

export type CampaignSelectionCampaignsRemoveOutput = {
  __typename?: 'CampaignSelectionCampaignsRemoveOutput';
  campaignSelection: CampaignSelection;
  campaigns: Array<Campaign>;
};

export type CampaignSelectionCreateInput = {
  coverId: Scalars['ID']['input'];
  description: Scalars['Delta']['input'];
  isExclusive?: InputMaybe<Scalars['Boolean']['input']>;
  localeId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type CampaignSelectionCreateOutput = {
  __typename?: 'CampaignSelectionCreateOutput';
  campaignSelection: CampaignSelection;
};

export type CampaignSelectionDeleteOutput = {
  __typename?: 'CampaignSelectionDeleteOutput';
  campaignSelectionId: Scalars['String']['output'];
};

export type CampaignSelectionPaginationEdge = Edge & {
  __typename?: 'CampaignSelectionPaginationEdge';
  cursor: Scalars['ID']['output'];
  node: CampaignSelection;
};

export type CampaignSelectionPublishOutput = {
  __typename?: 'CampaignSelectionPublishOutput';
  campaignSelection: CampaignSelection;
};

export enum CampaignSelectionStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED'
}

export type CampaignSelectionTranslation = ICampaignSelectionTranslation & Node & Translation & {
  __typename?: 'CampaignSelectionTranslation';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  descriptionDelta: Scalars['Delta']['output'];
  descriptionHTML: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  locale: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CampaignSelectionTranslationCreateInput = {
  description: Scalars['Delta']['input'];
  name: Scalars['String']['input'];
};

export type CampaignSelectionTranslationCreateOutput = {
  __typename?: 'CampaignSelectionTranslationCreateOutput';
  campaignSelection: CampaignSelection;
  campaignSelectionTranslation: CampaignSelectionTranslation;
};

export type CampaignSelectionTranslationDeleteOutput = {
  __typename?: 'CampaignSelectionTranslationDeleteOutput';
  campaignSelection: CampaignSelection;
  campaignSelectionTranslation: CampaignSelectionTranslation;
};

export type CampaignSelectionTranslationEdge = Edge & TranslationEdge & {
  __typename?: 'CampaignSelectionTranslationEdge';
  cursor: Scalars['ID']['output'];
  node: CampaignSelectionTranslation;
};

export type CampaignSelectionTranslationUpdateInput = {
  description?: InputMaybe<Scalars['Delta']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CampaignSelectionTranslationUpdateOutput = {
  __typename?: 'CampaignSelectionTranslationUpdateOutput';
  campaignSelection: CampaignSelection;
  campaignSelectionTranslation: CampaignSelectionTranslation;
};

export type CampaignSelectionTranslationsPagination = Pagination & TranslationsPagination & {
  __typename?: 'CampaignSelectionTranslationsPagination';
  edges: Array<CampaignSelectionTranslationEdge>;
  nodes: Array<CampaignSelectionTranslation>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CampaignSelectionUnpublishOutput = {
  __typename?: 'CampaignSelectionUnpublishOutput';
  campaignSelection: CampaignSelection;
};

export type CampaignSelectionUpdateInput = {
  coverId?: InputMaybe<Scalars['ID']['input']>;
  isExclusive?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CampaignSelectionUpdateOutput = {
  __typename?: 'CampaignSelectionUpdateOutput';
  campaignSelection: CampaignSelection;
};

export type CampaignSelectionsArgsFilter = {
  AND?: InputMaybe<Array<CampaignSelectionsArgsFilter>>;
  OR?: InputMaybe<Array<CampaignSelectionsArgsFilter>>;
  campaigns?: InputMaybe<CampaignSelectionsArgsFilterCampaigns>;
  id?: InputMaybe<FilterIdArgs>;
  includedCompanies?: InputMaybe<CompaniesArgsFilter>;
  isExclusive?: InputMaybe<FilterBoolArgs>;
  name?: InputMaybe<CampaignSelectionsArgsFilterName>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<CampaignSelectionsArgsFilterStatus>;
};

export type CampaignSelectionsArgsFilterCampaigns = {
  include?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type CampaignSelectionsArgsFilterName = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type CampaignSelectionsArgsFilterStatus = {
  include?: InputMaybe<Array<CampaignSelectionStatus>>;
};

export type CampaignSelectionsArgsSorter = {
  createdAt?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type CampaignSelectionsPagination = Pagination & {
  __typename?: 'CampaignSelectionsPagination';
  edges: Array<CampaignSelectionPaginationEdge>;
  nodes: Array<CampaignSelection>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CampaignSelectionsRelationFilter = {
  NONE?: InputMaybe<CampaignSelectionsArgsFilter>;
  SOME?: InputMaybe<CampaignSelectionsArgsFilter>;
};

export enum CampaignStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED'
}

export type CampaignTranslation = ICampaignTranslation & Node & Translation & {
  __typename?: 'CampaignTranslation';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  descriptionDelta?: Maybe<Scalars['Delta']['output']>;
  descriptionHTML?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  instructions?: Maybe<Scalars['String']['output']>;
  instructionsDelta?: Maybe<Scalars['Delta']['output']>;
  instructionsHTML?: Maybe<Scalars['String']['output']>;
  isGenerated: Scalars['Boolean']['output'];
  isMaster: Scalars['Boolean']['output'];
  locale: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CampaignTranslationCreateInput = {
  description?: InputMaybe<Scalars['Delta']['input']>;
  instructions?: InputMaybe<Scalars['Delta']['input']>;
  name: Scalars['String']['input'];
};

export type CampaignTranslationCreateOutput = {
  __typename?: 'CampaignTranslationCreateOutput';
  campaign: Campaign;
  campaignTranslation: CampaignTranslation;
};

export type CampaignTranslationCreatedData = CampaignTranslationDataFields & EventData & {
  __typename?: 'CampaignTranslationCreatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  description?: Maybe<TrackableDelta>;
  instructions?: Maybe<TrackableDelta>;
  locale: Scalars['ID']['output'];
  name?: Maybe<TrackableString>;
};

export type CampaignTranslationDataFields = {
  locale: Scalars['ID']['output'];
};

export type CampaignTranslationDeleteOutput = {
  __typename?: 'CampaignTranslationDeleteOutput';
  campaign: Campaign;
  campaignTranslation: CampaignTranslation;
};

export type CampaignTranslationDeletedData = CampaignTranslationDataFields & EventData & {
  __typename?: 'CampaignTranslationDeletedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  locale: Scalars['ID']['output'];
};

export type CampaignTranslationEdge = Edge & TranslationEdge & {
  __typename?: 'CampaignTranslationEdge';
  cursor: Scalars['ID']['output'];
  node: CampaignTranslation;
};

export type CampaignTranslationUpdateInput = {
  description?: InputMaybe<Scalars['Delta']['input']>;
  instructions?: InputMaybe<Scalars['Delta']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CampaignTranslationUpdateOutput = {
  __typename?: 'CampaignTranslationUpdateOutput';
  campaign: Campaign;
  campaignTranslation: CampaignTranslation;
};

export type CampaignTranslationUpdatedData = CampaignTranslationDataFields & EventData & {
  __typename?: 'CampaignTranslationUpdatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  description?: Maybe<TrackableDelta>;
  instructions?: Maybe<TrackableDelta>;
  locale: Scalars['ID']['output'];
  name?: Maybe<TrackableString>;
};

export type CampaignTranslationsPagination = Pagination & TranslationsPagination & {
  __typename?: 'CampaignTranslationsPagination';
  edges: Array<CampaignTranslationEdge>;
  nodes: Array<CampaignTranslation>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CampaignUnpublishOutput = {
  __typename?: 'CampaignUnpublishOutput';
  campaign: Campaign;
};

export type CampaignUnpublishedData = EventData & {
  __typename?: 'CampaignUnpublishedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
};

export type CampaignUpdateInput = {
  coverId?: InputMaybe<Scalars['ID']['input']>;
  endsAt?: InputMaybe<Scalars['DateTime']['input']>;
  isExclusive?: InputMaybe<Scalars['Boolean']['input']>;
  startsAt?: InputMaybe<Scalars['DateTime']['input']>;
  thumbnailId?: InputMaybe<Scalars['ID']['input']>;
};

export type CampaignUpdateOutput = {
  __typename?: 'CampaignUpdateOutput';
  campaign: Campaign;
};

export type CampaignUpdatedData = EventData & WithCover & WithThumbnail & {
  __typename?: 'CampaignUpdatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  cover?: Maybe<Picture>;
  endsAt?: Maybe<TrackableDateTime>;
  isExclusive?: Maybe<TrackableBoolean>;
  startsAt?: Maybe<TrackableDateTime>;
  thumbnail?: Maybe<Picture>;
};

export type CampaignsArgsFilter = {
  AND?: InputMaybe<Array<CampaignsArgsFilter>>;
  OR?: InputMaybe<Array<CampaignsArgsFilter>>;
  brand?: InputMaybe<BrandsArgsFilter>;
  categories?: InputMaybe<CategoriesRelationFilter>;
  cities?: InputMaybe<CampaignsArgsFilterCities>;
  city?: InputMaybe<Scalars['String']['input']>;
  cover?: InputMaybe<PictureFilterArgs>;
  distance?: InputMaybe<CampaignsArgsFilterDistance>;
  hasDiscount?: InputMaybe<FilterBoolArgs>;
  highlights?: InputMaybe<CampaignHighlightsRelationFilter>;
  id?: InputMaybe<FilterIdArgs>;
  isExclusive?: InputMaybe<FilterBoolArgs>;
  name?: InputMaybe<CampaignArgsFilterName>;
  offers?: InputMaybe<OffersRelationFilter>;
  provinces?: InputMaybe<FilterStringArgs>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<CampaignArgsFilterStatus>;
  subventionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type CampaignsArgsFilterCities = {
  include?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type CampaignsArgsFilterDistance = {
  max?: InputMaybe<Scalars['Int']['input']>;
};

export type CampaignsArgsSorter = {
  brand?: InputMaybe<SortDirection>;
  categoryCount?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  distance?: InputMaybe<SortDirection>;
  endsAt?: InputMaybe<SortDirection>;
  expiresAt?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  offerCount?: InputMaybe<SortDirection>;
  offerPublishedAt?: InputMaybe<SortDirection>;
  offerPublishedCount?: InputMaybe<SortDirection>;
  physicalStore?: InputMaybe<SortDirection>;
  publishedAt?: InputMaybe<SortDirection>;
  startsAt?: InputMaybe<SortDirection>;
  status?: InputMaybe<SortDirection>;
  totalUniqueViewCount?: InputMaybe<SortDirection>;
  totalViewCount?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type CampaignsPagination = Pagination & {
  __typename?: 'CampaignsPagination';
  categories: Array<CampaignsPaginationCategoriesEnumFilter>;
  /** @deprecated Please use locations instead */
  cities: Array<Scalars['String']['output']>;
  edges: Array<CampaignEdge>;
  locations: Array<PaginationEnumFilter>;
  nodes: Array<Campaign>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CampaignsPaginationCategoriesEnumFilter = {
  __typename?: 'CampaignsPaginationCategoriesEnumFilter';
  count: Scalars['Int']['output'];
  value: Category;
};

export type CardMetadata = {
  __typename?: 'CardMetadata';
  bestOffer?: Maybe<Offer>;
  bestOfferDiscount?: Maybe<Discount>;
  bestOfferVoucher?: Maybe<Voucher>;
};

export type Cart = Node & {
  __typename?: 'Cart';
  amounts: CartAmounts;
  cartItemPagination: CartItemPagination;
  cartItemsQuantity: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  subventionsPagination: CartAvailableSubventionPagination;
  updatedAt: Scalars['DateTime']['output'];
  useSubventions: Scalars['Boolean']['output'];
  user: User;
};


export type CartCartItemPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CartItemArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CartSubventionsPaginationArgs = {
  filter?: InputMaybe<CartAvailableSubventionArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type CartAmounts = {
  __typename?: 'CartAmounts';
  currency: Scalars['Currency']['output'];
  /** Total discount amount applied by discount subvention to the cart */
  discountSubventionsAmount: Scalars['Int']['output'];
  /** Total discount quantity applied by all discount subvention to the cart */
  discountSubventionsTotalQuantity: Scalars['Int']['output'];
  /** Total discount amount applied by HappyPal to the cart */
  discountsAmount: Scalars['Int']['output'];
  /** Additional fees price with taxes applied by happypal on the cart */
  feesAmountAfterTaxes: Scalars['Int']['output'];
  /** Additional fees price without taxes applied by happypal on the cart */
  feesAmountBeforeTaxes: Scalars['Int']['output'];
  /** Total amount used on subvention */
  fundsAmount: Scalars['Int']['output'];
  /** Total price of the cart products already discounted with taxes */
  pricesAmountAfterTaxes: Scalars['Int']['output'];
  /** Total price of the cart products already discounted without taxes */
  pricesAmountBeforeTaxes: Scalars['Int']['output'];
  /** Total price with taxes that the user will pay (including discounts and potential fees) */
  totalAmountAfterTaxes: Scalars['Int']['output'];
  /** Total price without taxes (including discounts and potential fees) */
  totalAmountBeforeTaxes: Scalars['Int']['output'];
  /** Total allowance available for a specific cart which has been / could have been used by the user. It's used for analytics. */
  totalCartAllowanceAvailable: Scalars['Int']['output'];
};

export type CartArgsSorter = {
  createdAt?: InputMaybe<SortDirection>;
};

export type CartAvailableSubventionArgsFilter = {
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  selectedAmount?: InputMaybe<FilterIntArgs>;
};

export type CartAvailableSubventionEdge = Edge & {
  __typename?: 'CartAvailableSubventionEdge';
  cursor: Scalars['ID']['output'];
  /** Wether or not the subvention will be used at checkout */
  isEnabled: Scalars['Boolean']['output'];
  /** Total amount of cartItems eligible for this subvention maxed by the subvention amount */
  maxCartEligibleAmount: Scalars['Int']['output'];
  node: Subvention;
  /** Remaining amount on the subvention before use */
  remainingSubventionAmount: Scalars['Int']['output'];
  /** Amount of the subvention used at checkout */
  selectedAmount: Scalars['Int']['output'];
};

export type CartAvailableSubventionPagination = Pagination & {
  __typename?: 'CartAvailableSubventionPagination';
  edges: Array<CartAvailableSubventionEdge>;
  nodes: Array<Subvention>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CartCheckoutOutput = {
  __typename?: 'CartCheckoutOutput';
  cart: Cart;
  clientSecret?: Maybe<Scalars['String']['output']>;
  order: Order;
  paymentIntent?: Maybe<StripePaymentIntent>;
  url: Scalars['String']['output'];
};

export type CartEdge = Edge & {
  __typename?: 'CartEdge';
  cursor: Scalars['ID']['output'];
  node: Cart;
};

export type CartEmptyOutput = {
  __typename?: 'CartEmptyOutput';
  cart: Cart;
};

export type CartFundSubventionEnableInput = {
  subventionId: Scalars['ID']['input'];
};

export type CartFundSubventionEnableOutput = {
  __typename?: 'CartFundSubventionEnableOutput';
  cart: Cart;
};

export type CartItem = Node & {
  __typename?: 'CartItem';
  amounts: CartItemAmounts;
  cart: Cart;
  createdAt: Scalars['DateTime']['output'];
  customAmount?: Maybe<Scalars['Int']['output']>;
  customDiscountAmount?: Maybe<Scalars['Int']['output']>;
  discountSubvention?: Maybe<Subvention>;
  hasExpired: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  metadata: CartItemMetadata;
  offer: Offer;
  productVariant: ProductVariant;
  quantity: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CartItemAmounts = {
  __typename?: 'CartItemAmounts';
  /** Value of the product in the store (with taxes) */
  amount: Scalars['Int']['output'];
  currency: Scalars['Currency']['output'];
  /** Value discounted by HappyPal to apply on the field "amount" */
  discountAmount: Scalars['Int']['output'];
  /** Value discounted by discount subvention to apply on the field "amount" */
  discountSubventionAmount: Scalars['Int']['output'];
  discountSubventionQuantity: Scalars['Int']['output'];
  /**
   * Value discounted by discount subvention to apply on the field "amount"
   * @deprecated Use "discountSubventionAmount" instead
   */
  subventionDiscountAmount: Scalars['Int']['output'];
  totalDiscountAmount: Scalars['Int']['output'];
  totalDiscountSubventionAmount: Scalars['Int']['output'];
  totalPriceAfterTaxes: Scalars['Int']['output'];
  totalPriceBeforeTaxes: Scalars['Int']['output'];
  /** Unit price discounted with taxes */
  unitPriceAfterTaxes: Scalars['Int']['output'];
  /** Unit price discounted without taxes */
  unitPriceBeforeTaxes: Scalars['Int']['output'];
};

export type CartItemArgsSorter = {
  createdAt?: InputMaybe<SortDirection>;
};

export type CartItemCreateInput = {
  customAmount?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<CartItemMetadataCreateInput>;
  quantity: Scalars['Int']['input'];
};

export type CartItemCreateOutput = {
  __typename?: 'CartItemCreateOutput';
  cart: Cart;
  cartItem: CartItem;
  productVariant: ProductVariant;
};

export type CartItemDeleteOutput = {
  __typename?: 'CartItemDeleteOutput';
  cart: Cart;
  cartItem: CartItem;
};

export type CartItemEdge = Edge & {
  __typename?: 'CartItemEdge';
  cursor: Scalars['ID']['output'];
  node: CartItem;
};

export type CartItemMetadata = {
  __typename?: 'CartItemMetadata';
  beneficiaries?: Maybe<Array<CartItemMetadataBeneficiary>>;
  date?: Maybe<Scalars['Date']['output']>;
  /** Time with format "HH:MM" */
  time?: Maybe<Scalars['String']['output']>;
};

export type CartItemMetadataBeneficiary = {
  __typename?: 'CartItemMetadataBeneficiary';
  birthdate?: Maybe<Scalars['Date']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

export type CartItemMetadataBeneficiaryCreateInput = {
  birthdate?: InputMaybe<Scalars['Date']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type CartItemMetadataCreateInput = {
  beneficiaries?: InputMaybe<Array<CartItemMetadataBeneficiaryCreateInput>>;
  date?: InputMaybe<Scalars['Date']['input']>;
  time?: InputMaybe<Scalars['String']['input']>;
};

export type CartItemMetadataUpdateInput = {
  beneficiaries?: InputMaybe<Array<CartItemMetadataBeneficiaryCreateInput>>;
  date?: InputMaybe<Scalars['Date']['input']>;
  time?: InputMaybe<Scalars['String']['input']>;
};

export type CartItemPagination = Pagination & {
  __typename?: 'CartItemPagination';
  edges: Array<CartItemEdge>;
  nodes: Array<CartItem>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CartItemUpdateInput = {
  metadata?: InputMaybe<CartItemMetadataUpdateInput>;
  quantity: Scalars['Int']['input'];
};

export type CartItemUpdateOutput = {
  __typename?: 'CartItemUpdateOutput';
  cart: Cart;
  cartItem: CartItem;
  productVariant: ProductVariant;
};

export type CartOutput = {
  __typename?: 'CartOutput';
  cart: Cart;
};

export type CartPagination = Pagination & {
  __typename?: 'CartPagination';
  edges: Array<CartEdge>;
  nodes: Array<Cart>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type Catalog = {
  __typename?: 'Catalog';
  accessMode: CatalogAccessMode;
  campaign?: Maybe<Campaign>;
  campaignPagination: CatalogCampaignPagination;
  categoryPagination: CatalogCategoryPagination;
  company?: Maybe<Company>;
  excludedBrandPagination?: Maybe<BrandsPagination>;
  excludedCampaignPagination?: Maybe<CampaignPagination>;
  excludedCategoryPagination?: Maybe<CategoriesPagination>;
  excludedOfferPagination?: Maybe<OffersPagination>;
  id: Scalars['ID']['output'];
  includedBrandPagination?: Maybe<BrandsPagination>;
  includedCampaignPagination?: Maybe<CampaignPagination>;
  includedOfferPagination?: Maybe<OffersPagination>;
  provinces: Array<Scalars['String']['output']>;
};


export type CatalogCampaignArgs = {
  campaignId: Scalars['ID']['input'];
};


export type CatalogCampaignPaginationArgs = {
  filter?: InputMaybe<CatalogCampaignPaginationFilter>;
  position?: InputMaybe<Position>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CatalogCampaignPaginationSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CatalogCategoryPaginationArgs = {
  filter?: InputMaybe<CatalogCategoryPaginationFilter>;
};


export type CatalogExcludedBrandPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CatalogExcludedCampaignPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CatalogExcludedCategoryPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CatalogExcludedOfferPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CatalogIncludedBrandPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CatalogIncludedCampaignPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CatalogIncludedOfferPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export enum CatalogAccessMode {
  Blacklist = 'BLACKLIST',
  Whitelist = 'WHITELIST'
}

export type CatalogBrandUpdateInput = {
  brandIds: Array<Scalars['ID']['input']>;
};

export type CatalogBrandUpdateOutput = {
  __typename?: 'CatalogBrandUpdateOutput';
  catalog: Catalog;
};

export type CatalogCampaignEdge = {
  __typename?: 'CatalogCampaignEdge';
  bestOffer: Offer;
  /** Only explicit associations */
  categories: Array<Category>;
  cursor: Scalars['ID']['output'];
  node: Campaign;
  /** Expose parent categories. Implicit associations are included */
  parentCategories: Array<Category>;
};

export type CatalogCampaignPagination = {
  __typename?: 'CatalogCampaignPagination';
  edges: Array<CatalogCampaignEdge>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CatalogCampaignPaginationFilter = {
  categoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  hasDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  /** has discount or are subsidized by given subvention ids */
  hasDiscountOrIsSubsidizedBy?: InputMaybe<Array<Scalars['String']['input']>>;
  location?: InputMaybe<CatalogCampaignPaginationFilterLocation>;
  offerTypes?: InputMaybe<Array<OfferType>>;
  search?: InputMaybe<Scalars['String']['input']>;
  subventionIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CatalogCampaignPaginationFilterLocation = {
  availableCountry?: InputMaybe<Scalars['Boolean']['input']>;
  availableOnline?: InputMaybe<Scalars['Boolean']['input']>;
  provinces?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Distance in meters. Min. 0. Max. 100000. */
  range?: InputMaybe<Scalars['Int']['input']>;
};

export type CatalogCampaignPaginationSorter = {
  direction: SortDirection;
  field: CatalogCampaignPaginationSorterField;
};

export enum CatalogCampaignPaginationSorterField {
  CreatedAt = 'CREATED_AT',
  Distance = 'DISTANCE',
  Relevance = 'RELEVANCE',
  TotalViewCount = 'TOTAL_VIEW_COUNT'
}

export type CatalogCampaignUpdateInput = {
  campaignIds: Array<Scalars['ID']['input']>;
};

export type CatalogCampaignUpdateOutput = {
  __typename?: 'CatalogCampaignUpdateOutput';
  catalog: Catalog;
};

export type CatalogCategoryEdge = {
  __typename?: 'CatalogCategoryEdge';
  cursor: Scalars['ID']['output'];
  node: Category;
};

export type CatalogCategoryPagination = {
  __typename?: 'CatalogCategoryPagination';
  edges: Array<CatalogCategoryEdge>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CatalogCategoryPaginationFilter = {
  parentId?: InputMaybe<Scalars['ID']['input']>;
};

export type CatalogCategoryUpdateInput = {
  categoryIds: Array<Scalars['ID']['input']>;
};

export type CatalogCategoryUpdateOutput = {
  __typename?: 'CatalogCategoryUpdateOutput';
  catalog: Catalog;
};

export type CatalogCreateInput = {
  accessMode: CatalogAccessMode;
};

export type CatalogCreateOutput = {
  __typename?: 'CatalogCreateOutput';
  catalog: Catalog;
};

export type CatalogDeleteOutput = {
  __typename?: 'CatalogDeleteOutput';
  catalog: Catalog;
};

export type CatalogEdge = {
  __typename?: 'CatalogEdge';
  cursor: Scalars['ID']['output'];
  node: Catalog;
};

export type CatalogOfferUpdateInput = {
  offerIds: Array<Scalars['ID']['input']>;
};

export type CatalogOfferUpdateOutput = {
  __typename?: 'CatalogOfferUpdateOutput';
  catalog: Catalog;
};

export type CatalogPagination = {
  __typename?: 'CatalogPagination';
  edges: Array<CatalogEdge>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CatalogPaginationFilter = {
  includedBrandId?: InputMaybe<Scalars['ID']['input']>;
  includedCampaignId?: InputMaybe<Scalars['ID']['input']>;
  includedOfferId?: InputMaybe<Scalars['ID']['input']>;
};

export type CategoriesArgsCampaignsCount = {
  min?: InputMaybe<Scalars['Int']['input']>;
};

export type CategoriesArgsFilter = {
  AND?: InputMaybe<Array<CategoriesArgsFilter>>;
  OR?: InputMaybe<Array<CategoriesArgsFilter>>;
  campaignCount?: InputMaybe<CategoriesArgsCampaignsCount>;
  highlights?: InputMaybe<CategoriesArgsFilterHighlights>;
  id?: InputMaybe<FilterIdArgs>;
  name?: InputMaybe<CategoriesArgsFilterName>;
  offers?: InputMaybe<OffersRelationFilter>;
  parent?: InputMaybe<CategoriesArgsFilter>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type CategoriesArgsFilterHighlights = {
  companyId?: InputMaybe<CategoryHighlightCompanyFilter>;
};

export type CategoriesArgsFilterName = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type CategoriesArgsSorter = {
  campaignCount?: InputMaybe<SortDirection>;
  childrenCount?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
};

export type CategoriesPagination = Pagination & {
  __typename?: 'CategoriesPagination';
  edges: Array<CategoryEdge>;
  nodes: Array<Category>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CategoriesRelationFilter = {
  EVERY?: InputMaybe<CategoriesArgsFilter>;
  NONE?: InputMaybe<CategoriesArgsFilter>;
  SOME?: InputMaybe<CategoriesArgsFilter>;
};

export type Category = Highlightable & ICategoryTranslation & Node & Translatable & Translation & {
  __typename?: 'Category';
  auditTrailPagination: AuditTrailPagination;
  brandsPagination: BrandsPagination;
  campaignPagination: CampaignPagination;
  childrenPagination: CategoriesPagination;
  createdAt: Scalars['DateTime']['output'];
  icon?: Maybe<Picture>;
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isHighlighted: Scalars['Boolean']['output'];
  locale: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  offersPagination: OffersPagination;
  parent?: Maybe<Category>;
  /** Args is not used. */
  translationsPagination: CategoryTranslationsPagination;
  updatedAt: Scalars['DateTime']['output'];
};


export type CategoryAuditTrailPaginationArgs = {
  filter?: InputMaybe<AuditTrailArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<AuditTrailArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CategoryBrandsPaginationArgs = {
  filter?: InputMaybe<BrandsArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<BrandsArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CategoryCampaignPaginationArgs = {
  filter?: InputMaybe<CampaignPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CampaignPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CategoryChildrenPaginationArgs = {
  filter?: InputMaybe<CategoriesArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CategoriesArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CategoryOffersPaginationArgs = {
  filter?: InputMaybe<OffersArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<OffersArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CategoryTranslationsPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type CategoryCreateInput = {
  iconId?: InputMaybe<Scalars['ID']['input']>;
  localeId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  parentCategoryId?: InputMaybe<Scalars['ID']['input']>;
};

export type CategoryCreateOutput = {
  __typename?: 'CategoryCreateOutput';
  category: Category;
};

export type CategoryCreatedData = EventData & {
  __typename?: 'CategoryCreatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  locale: Scalars['ID']['output'];
  name?: Maybe<TrackableString>;
};

export type CategoryDeleteOutput = {
  __typename?: 'CategoryDeleteOutput';
  category: Category;
};

export type CategoryDeletedData = EventData & {
  __typename?: 'CategoryDeletedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
};

export type CategoryEdge = Edge & {
  __typename?: 'CategoryEdge';
  cursor: Scalars['ID']['output'];
  node: Category;
};

export type CategoryHighlightCompanyFilter = {
  is?: InputMaybe<Scalars['String']['input']>;
  isNot?: InputMaybe<Scalars['String']['input']>;
};

export type CategoryTranslation = ICategoryTranslation & Node & Translation & {
  __typename?: 'CategoryTranslation';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  locale: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CategoryTranslationCreateInput = {
  name: Scalars['String']['input'];
};

export type CategoryTranslationCreateOutput = {
  __typename?: 'CategoryTranslationCreateOutput';
  category: Category;
  categoryTranslation: CategoryTranslation;
};

export type CategoryTranslationCreatedData = CategoryTranslationDataFields & EventData & {
  __typename?: 'CategoryTranslationCreatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  locale: Scalars['ID']['output'];
  name?: Maybe<TrackableString>;
};

export type CategoryTranslationDataFields = {
  locale: Scalars['ID']['output'];
};

export type CategoryTranslationDeleteOutput = {
  __typename?: 'CategoryTranslationDeleteOutput';
  category: Category;
  categoryTranslation: CategoryTranslation;
};

export type CategoryTranslationDeletedData = CategoryTranslationDataFields & EventData & {
  __typename?: 'CategoryTranslationDeletedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  locale: Scalars['ID']['output'];
};

export type CategoryTranslationEdge = Edge & TranslationEdge & {
  __typename?: 'CategoryTranslationEdge';
  cursor: Scalars['ID']['output'];
  node: CategoryTranslation;
};

export type CategoryTranslationUpdateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CategoryTranslationUpdateOutput = {
  __typename?: 'CategoryTranslationUpdateOutput';
  category: Category;
  categoryTranslation: CategoryTranslation;
};

export type CategoryTranslationUpdatedData = CategoryTranslationDataFields & EventData & {
  __typename?: 'CategoryTranslationUpdatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  locale: Scalars['ID']['output'];
  name?: Maybe<TrackableString>;
};

export type CategoryTranslationsPagination = Pagination & TranslationsPagination & {
  __typename?: 'CategoryTranslationsPagination';
  edges: Array<CategoryTranslationEdge>;
  nodes: Array<CategoryTranslation>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CategoryUpdateInput = {
  iconId?: InputMaybe<Scalars['ID']['input']>;
};

export type CategoryUpdateOutput = {
  __typename?: 'CategoryUpdateOutput';
  category: Category;
};

export type CategoryUpdatedData = EventData & WithFile & {
  __typename?: 'CategoryUpdatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  file: File;
};

export type Cohort = {
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  metadata: CohortMetadata;
  query: Scalars['JSONObject']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CohortBeneficiary = Cohort & DeletableNode & Node & {
  __typename?: 'CohortBeneficiary';
  beneficiariesPagination: BeneficiariesPagination;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  metadata: CohortMetadata;
  query: Scalars['JSONObject']['output'];
  refreshOptions: CohortRefreshOptions;
  refreshesPagination: CohortRefreshPagination;
  updatedAt: Scalars['DateTime']['output'];
};


export type CohortBeneficiaryBeneficiariesPaginationArgs = {
  filter?: InputMaybe<BeneficiariesArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<BeneficiariesArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CohortBeneficiaryRefreshesPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CohortRefreshArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type CohortCompany = Cohort & DeletableNode & Node & {
  __typename?: 'CohortCompany';
  companyPagination: CompaniesPagination;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  metadata: CohortMetadata;
  query: Scalars['JSONObject']['output'];
  refreshOptions: CohortRefreshOptions;
  refreshesPagination: CohortRefreshPagination;
  updatedAt: Scalars['DateTime']['output'];
};


export type CohortCompanyCompanyPaginationArgs = {
  filter?: InputMaybe<CompaniesArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CompaniesArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CohortCompanyRefreshesPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CohortRefreshArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type CohortConfiguration = {
  __typename?: 'CohortConfiguration';
  /** Record<Model, Record<string, ModelProperty>> */
  models: Scalars['JSONObject']['output'];
  /** Model[] */
  roots: Array<Scalars['String']['output']>;
};

export type CohortCreateData = EventData & {
  __typename?: 'CohortCreateData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  metadata?: Maybe<TrackableCohortMetadata>;
  query: TrackableRecord;
  refreshOptions?: Maybe<TrackableCohortRefreshOptions>;
  target: CohortTarget;
};

export type CohortCreateInput = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  metadata: CohortMetadataInput;
  query: Scalars['JSONObject']['input'];
  refreshOptions?: InputMaybe<CohortRefreshOptionsInput>;
  target: CohortTarget;
};

export type CohortCreateOutput = {
  __typename?: 'CohortCreateOutput';
  cohort: Cohort;
};

export type CohortDeleteData = EventData & {
  __typename?: 'CohortDeleteData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
};

export type CohortDeleteOutput = {
  __typename?: 'CohortDeleteOutput';
  cohort: Cohort;
};

export type CohortMetadata = {
  __typename?: 'CohortMetadata';
  selectionMode?: Maybe<CohortSelectionMode>;
};

export type CohortMetadataInput = {
  selectionMode?: InputMaybe<CohortSelectionMode>;
};

export type CohortOffer = Cohort & DeletableNode & Node & {
  __typename?: 'CohortOffer';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  metadata: CohortMetadata;
  offersPagination: OffersPagination;
  query: Scalars['JSONObject']['output'];
  refreshOptions: CohortRefreshOptions;
  refreshesPagination: CohortRefreshPagination;
  updatedAt: Scalars['DateTime']['output'];
};


export type CohortOfferOffersPaginationArgs = {
  cached?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<OffersArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<OffersArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CohortOfferRefreshesPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CohortRefreshArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type CohortRefresh = Node & {
  __typename?: 'CohortRefresh';
  addedEntities?: Maybe<CohortRefresh>;
  addedEntitiesPagination: CohortRefreshEntityPagination;
  cohort: CohortUnion;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  removedEntitiesPagination: CohortRefreshEntityPagination;
  updatedAt: Scalars['DateTime']['output'];
};


export type CohortRefreshAddedEntitiesPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CohortRefreshRemovedEntitiesPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type CohortRefreshArgsSorter = {
  createdAt?: InputMaybe<SortDirection>;
};

export type CohortRefreshEdge = Edge & {
  __typename?: 'CohortRefreshEdge';
  cursor: Scalars['ID']['output'];
  node: CohortRefresh;
};

export type CohortRefreshEntityEdge = Edge & {
  __typename?: 'CohortRefreshEntityEdge';
  cursor: Scalars['ID']['output'];
  node: Cohort;
};

export type CohortRefreshEntityPagination = Pagination & {
  __typename?: 'CohortRefreshEntityPagination';
  edges: Array<CohortRefreshEntityEdge>;
  nodes: Array<CohortRefresh>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CohortRefreshOptions = {
  __typename?: 'CohortRefreshOptions';
  canAddEntity: Scalars['Boolean']['output'];
  canRemoveEntity: Scalars['Boolean']['output'];
  refreshFrequency?: Maybe<Scalars['String']['output']>;
};

export type CohortRefreshOptionsInput = {
  canAddEntity?: InputMaybe<Scalars['Boolean']['input']>;
  canRemoveEntity?: InputMaybe<Scalars['Boolean']['input']>;
  /** A valid CRON string */
  refreshFrequency?: InputMaybe<Scalars['String']['input']>;
};

export type CohortRefreshOutput = {
  __typename?: 'CohortRefreshOutput';
  cohort: Cohort;
};

export type CohortRefreshPagination = Pagination & {
  __typename?: 'CohortRefreshPagination';
  edges: Array<CohortRefreshEdge>;
  nodes: Array<CohortRefresh>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export enum CohortSelectionMode {
  All = 'ALL',
  Filter = 'FILTER',
  Manual = 'MANUAL'
}

export enum CohortTarget {
  Beneficiary = 'Beneficiary',
  Company = 'Company',
  Offer = 'Offer',
  User = 'User'
}

export type CohortUnion = CohortBeneficiary | CohortCompany | CohortOffer | CohortUser;

export type CohortUpdateData = EventData & {
  __typename?: 'CohortUpdateData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  metadata?: Maybe<TrackableCohortMetadata>;
  query?: Maybe<TrackableRecord>;
  refreshOptions?: Maybe<TrackableCohortRefreshOptions>;
};

export type CohortUpdateInput = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  metadata: CohortMetadataInput;
  query?: InputMaybe<Scalars['JSONObject']['input']>;
  refreshOptions?: InputMaybe<CohortRefreshOptionsInput>;
};

export type CohortUpdateOutput = {
  __typename?: 'CohortUpdateOutput';
  cohort: Cohort;
};

export type CohortUser = Cohort & DeletableNode & Node & {
  __typename?: 'CohortUser';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  metadata: CohortMetadata;
  query: Scalars['JSONObject']['output'];
  refreshOptions: CohortRefreshOptions;
  refreshesPagination: CohortRefreshPagination;
  updatedAt: Scalars['DateTime']['output'];
  userPagination: UsersPagination;
};


export type CohortUserRefreshesPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CohortRefreshArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CohortUserUserPaginationArgs = {
  cached?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UsersArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<UsersArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type CompaniesArgsFilter = {
  AND?: InputMaybe<Array<CompaniesArgsFilter>>;
  OR?: InputMaybe<Array<CompaniesArgsFilter>>;
  displayName?: InputMaybe<FilterStringArgs>;
  email?: InputMaybe<FilterStringArgs>;
  excludedArticles?: InputMaybe<ArticlesRelationFilter>;
  id?: InputMaybe<FilterIdArgs>;
  includedArticles?: InputMaybe<ArticlesRelationFilter>;
  includedCampaignSelections?: InputMaybe<CampaignSelectionsRelationFilter>;
  isDemo?: InputMaybe<FilterBoolArgs>;
  membershipCount?: InputMaybe<FilterIntArgs>;
  memberships?: InputMaybe<CompanyMembershipListRelationFilter>;
  name?: InputMaybe<FilterStringArgs>;
};

export type CompaniesArgsSorter = {
  createdAt?: InputMaybe<SortDirection>;
  displayName?: InputMaybe<SortDirection>;
  email?: InputMaybe<SortDirection>;
  membershipCount?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
  userCount?: InputMaybe<SortDirection>;
};

export type CompaniesPagination = Pagination & {
  __typename?: 'CompaniesPagination';
  edges: Array<CompanyEdge>;
  nodes: Array<Company>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type Company = DeletableNode & Node & {
  __typename?: 'Company';
  accountManager?: Maybe<User>;
  activeCompanyFees?: Maybe<CompanyFees>;
  activeSurvey?: Maybe<Survey>;
  address?: Maybe<Address>;
  admin: CompanyAdmin;
  apiKeyPagination: ApiKeyPagination;
  apiRequestPagination: ApiRequestPagination;
  appdragId?: Maybe<Scalars['Int']['output']>;
  appdragMetas?: Maybe<CompanyAppdragMetas>;
  articleCategories: Array<ArticleCategory>;
  auditTrailPagination: AuditTrailPagination;
  beneficiaryPagination: BeneficiaryPagination;
  brandViewsPagination: CompanyBrandViewPagination;
  campaignHighlightedPagination: CampaignsPagination;
  catalog?: Maybe<Catalog>;
  categoryExpensesPagination: CompanyExpenseCategoryPagination;
  categoryOfferCliksPagination: CompanyOfferClickCategoryPagination;
  chargebeeId?: Maybe<Scalars['String']['output']>;
  childrenPagination: CompaniesPagination;
  /** @deprecated Please use identityCustomisation.colorPrimary instead */
  color?: Maybe<Scalars['String']['output']>;
  commercialPackage?: Maybe<CompanyCommercialPackage>;
  companyDocumentsPagination: CompanyDocumentsPagination;
  companyFeesPagination: CompanyFeesPagination;
  createdAt: Scalars['DateTime']['output'];
  customFieldsPagination: CompanyCustomFieldsPagination;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  discountPagination: DiscountPagination;
  /** @deprecated Use 'company.discoverCatalogCampaignPagination' instead */
  discoverCampaignPagination: CampaignsPagination;
  discoverCatalogCampaignPagination: CatalogCampaignPagination;
  /** @deprecated do not use */
  discoverCategoryPagination: CategoriesPagination;
  /** @deprecated Do not use */
  discoverPagination: DiscoverPagination;
  /** The name the company has chosen to display */
  displayName: Scalars['String']['output'];
  drive?: Maybe<CompanyFolder>;
  email?: Maybe<Scalars['String']['output']>;
  excludedArticlesPagination: ArticlesPagination;
  excludedCampaignPagination: CampaignPagination;
  excludedCampaignSelectionsPagination: CampaignSelectionsPagination;
  featureConfig: CompanyFeatureConfig;
  features: CompanyFeaturesProperties;
  gamesPagination: GamesPagination;
  /** @deprecated Please use homeCustomisation instead */
  greetingsMessage?: Maybe<Scalars['String']['output']>;
  happeningPagination: HappeningPagination;
  hubspotId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  includedArticlesPagination: ArticlesPagination;
  includedCampaignSelectionsPagination: CampaignSelectionsPagination;
  intraCommunityVat?: Maybe<Scalars['String']['output']>;
  invitationsReceived: InvitationsPagination;
  isDemo: Scalars['Boolean']['output'];
  logo?: Maybe<Picture>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  mangopayUser?: Maybe<MangopayUser>;
  membershipsPagination: CompanyMembershipsPagination;
  metabaseStatsDashboardToken: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** @deprecated Use 'company.discoverCatalogCampaignPagination' instead */
  newDiscoverCampaignPagination: CampaignPagination;
  persona?: Maybe<CompanyPersona>;
  pointsOfContactPagination: PointsOfContactPagination;
  publicationsPagination: PublicationPagination;
  questionCategoryPagination: QuestionCategoryPagination;
  questionsPagination: QuestionsPagination;
  registrationNumber?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  ssoProviderPagination: SsoProviderPagination;
  stripeConnectedAccount?: Maybe<StripeConnectedAccount>;
  subsidiaryTree: Scalars['JSONObject']['output'];
  subventionInvoicePagination: SubventionInvoicePagination;
  subventionPagination: CompanySubventionPagination;
  survey?: Maybe<Survey>;
  surveys: Array<Survey>;
  thresholdBudgetForSubventions: Scalars['Int']['output'];
  thresholdForDiscounts?: Maybe<Scalars['Int']['output']>;
  thresholdForFunds?: Maybe<Scalars['Int']['output']>;
  /** @deprecated use CompanySubventionPagination budgetTotal resolver instead */
  totalBudgetSubvention: Scalars['Int']['output'];
  totalCampaignsVisits: Scalars['Int']['output'];
  totalSavings: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  viewerIsAdmin: Scalars['Boolean']['output'];
  viewerIsSuspended: Scalars['Boolean']['output'];
  /** @deprecated Please use homeCustomisation instead */
  welcomeMessage?: Maybe<Scalars['String']['output']>;
};


export type CompanyApiKeyPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyApiRequestPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyAuditTrailPaginationArgs = {
  filter?: InputMaybe<AuditTrailArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<AuditTrailArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyBeneficiaryPaginationArgs = {
  filter?: InputMaybe<BeneficiaryPaginationFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<Array<BeneficiaryPaginationSorter>>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyBrandViewsPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyCampaignHighlightedPaginationArgs = {
  filter?: InputMaybe<CampaignsArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CampaignsArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyCategoryExpensesPaginationArgs = {
  filter?: InputMaybe<CompanyExpenseCategoryArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyCategoryOfferCliksPaginationArgs = {
  filter?: InputMaybe<CompanyOfferClickCategoryArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyChildrenPaginationArgs = {
  filter?: InputMaybe<CompaniesArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CompaniesArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyCompanyDocumentsPaginationArgs = {
  filter?: InputMaybe<CompanyDocumentsArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CompanyDocumentsArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyCompanyFeesPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CompanyFeesArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyCustomFieldsPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyDiscountPaginationArgs = {
  filter?: InputMaybe<DiscountArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<DiscountArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyDiscoverCampaignPaginationArgs = {
  filter?: InputMaybe<DiscoverTargetPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyDiscoverCatalogCampaignPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyDiscoverCategoryPaginationArgs = {
  filter?: InputMaybe<DiscoverTargetPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyDiscoverPaginationArgs = {
  filter?: InputMaybe<DiscoverPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyExcludedArticlesPaginationArgs = {
  filter?: InputMaybe<ArticlesPaginationFilterArgs>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<ArticlesPaginationSorterArgs>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyExcludedCampaignPaginationArgs = {
  filter?: InputMaybe<CampaignPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CampaignPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyExcludedCampaignSelectionsPaginationArgs = {
  filter?: InputMaybe<CampaignSelectionsArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CampaignSelectionsArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyGamesPaginationArgs = {
  filter?: InputMaybe<GamesPaginationFilterArgs>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<GamesPaginationSorterArgs>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyHappeningPaginationArgs = {
  filter?: InputMaybe<HappeningPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<HappeningPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyIncludedArticlesPaginationArgs = {
  filter?: InputMaybe<ArticlesPaginationFilterArgs>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<ArticlesPaginationSorterArgs>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyIncludedCampaignSelectionsPaginationArgs = {
  filter?: InputMaybe<CampaignSelectionsArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CampaignSelectionsArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyInvitationsReceivedArgs = {
  filter?: InputMaybe<InvitationsArgsFilterInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<NotificationsArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyMembershipsPaginationArgs = {
  filter?: InputMaybe<CompanyMembershipsArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CompanyMembershipsArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyNewDiscoverCampaignPaginationArgs = {
  filter?: InputMaybe<DiscoverTargetPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyPointsOfContactPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyPublicationsPaginationArgs = {
  filter?: InputMaybe<PublicationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<PublicationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
  withGlobal?: InputMaybe<Scalars['Boolean']['input']>;
};


export type CompanyQuestionCategoryPaginationArgs = {
  filter?: InputMaybe<QuestionCategoryPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<QuestionCategoryPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyQuestionsPaginationArgs = {
  filter?: InputMaybe<QuestionsPaginationFilters>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanySsoProviderPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanySubventionInvoicePaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<SubventionInvoiceArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanySubventionPaginationArgs = {
  filter?: InputMaybe<SubventionArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<SubventionArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanySurveyArgs = {
  surveyId: Scalars['ID']['input'];
};

/** Restricted to company admins. Holds admin data for a company. */
export type CompanyAdmin = {
  __typename?: 'CompanyAdmin';
  gatheringPagination: GatheringPagination;
  id: Scalars['ID']['output'];
  membershipsMetadataContracts: Array<PaginationEnumFilter>;
  membershipsMetadataJobTitles: Array<PaginationEnumFilter>;
  membershipsMetadataWorksites: Array<PaginationEnumFilter>;
};


/** Restricted to company admins. Holds admin data for a company. */
export type CompanyAdminGatheringPaginationArgs = {
  filter?: InputMaybe<GatheringPaginationFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<Array<GatheringPaginationSorter>>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyAppdragMetas = {
  __typename?: 'CompanyAppdragMetas';
  accountManager?: Maybe<Scalars['String']['output']>;
  dateLancement?: Maybe<Scalars['DateTime']['output']>;
  happycompta?: Maybe<Scalars['Boolean']['output']>;
  happycomptaExpiration?: Maybe<Scalars['DateTime']['output']>;
  happycomptaUrl?: Maybe<Scalars['String']['output']>;
  jeux?: Maybe<Scalars['String']['output']>;
  nbCollaborateurs?: Maybe<Scalars['String']['output']>;
  premium?: Maybe<Scalars['Boolean']['output']>;
  regionVilles?: Maybe<Scalars['String']['output']>;
  ssoExtClientId?: Maybe<Scalars['String']['output']>;
  ssoExtToken?: Maybe<Scalars['String']['output']>;
};

export type CompanyArticleExcludeInput = {
  articleIds: Array<Scalars['ID']['input']>;
};

export type CompanyArticleExcludeOutput = {
  __typename?: 'CompanyArticleExcludeOutput';
  company: Company;
};

export type CompanyArticleIncludeActionInput = {
  articleIds: Array<Scalars['ID']['input']>;
};

export type CompanyArticleIncludeActionOutput = {
  __typename?: 'CompanyArticleIncludeActionOutput';
  company: Company;
};

export type CompanyBrandViewEdge = Edge & {
  __typename?: 'CompanyBrandViewEdge';
  cursor: Scalars['ID']['output'];
  node: Brand;
  viewCount: Scalars['Int']['output'];
};

export type CompanyBrandViewPagination = Pagination & {
  __typename?: 'CompanyBrandViewPagination';
  edges: Array<CompanyBrandViewEdge>;
  nodes: Array<Brand>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
  viewCount: Scalars['Int']['output'];
};

export type CompanyCampaignSelectionExcludeInput = {
  campaignSelectionIds: Array<Scalars['ID']['input']>;
};

export type CompanyCampaignSelectionExcludeOutput = {
  __typename?: 'CompanyCampaignSelectionExcludeOutput';
  company: Company;
};

export type CompanyCampaignSelectionIncludeActionInput = {
  campaignSelectionIds: Array<Scalars['ID']['input']>;
};

export type CompanyCampaignSelectionIncludeActionOutput = {
  __typename?: 'CompanyCampaignSelectionIncludeActionOutput';
  company: Company;
};

export enum CompanyCommercialPackage {
  Premium = 'PREMIUM',
  PremiumPlus = 'PREMIUM_PLUS',
  Standard = 'STANDARD',
  Starter = 'STARTER'
}

export type CompanyCreateInput = {
  accountManagerId?: InputMaybe<Scalars['ID']['input']>;
  chargebeeId?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  commercialPackage?: InputMaybe<CompanyCommercialPackage>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  greetingsMessage?: InputMaybe<Scalars['String']['input']>;
  hubspotId?: InputMaybe<Scalars['String']['input']>;
  intraCommunityVat?: InputMaybe<Scalars['String']['input']>;
  isDemo: Scalars['Boolean']['input'];
  logoId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['ID']['input']>;
  persona: CompanyPersona;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  thresholdForDiscounts?: InputMaybe<Scalars['Int']['input']>;
  thresholdForFunds?: InputMaybe<Scalars['Int']['input']>;
  welcomeMessage?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyCreateOutput = {
  __typename?: 'CompanyCreateOutput';
  company: Company;
};

export type CompanyCreatedData = CompanyData & EventData & WithPicture & {
  __typename?: 'CompanyCreatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  chargebeeId?: Maybe<TrackableString>;
  color?: Maybe<TrackableString>;
  displayName?: Maybe<TrackableString>;
  email?: Maybe<TrackableString>;
  hubspotId?: Maybe<TrackableString>;
  intraCommunityVat?: Maybe<TrackableString>;
  isDemo?: Maybe<TrackableBoolean>;
  name?: Maybe<TrackableString>;
  picture?: Maybe<Picture>;
  thresholdForDiscounts?: Maybe<TrackableNumber>;
  thresholdForFunds?: Maybe<TrackableNumber>;
};

export type CompanyCustomField = Node & {
  __typename?: 'CompanyCustomField';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  label: Scalars['String']['output'];
  type: CompanyCustomFieldType;
  updatedAt: Scalars['DateTime']['output'];
};

export type CompanyCustomFieldCreateInput = {
  key: Scalars['String']['input'];
  label: Scalars['String']['input'];
  type: CompanyCustomFieldType;
};

export type CompanyCustomFieldCreateOutput = {
  __typename?: 'CompanyCustomFieldCreateOutput';
  company: Company;
  companyCustomField: CompanyCustomField;
};

export type CompanyCustomFieldDeleteOutput = {
  __typename?: 'CompanyCustomFieldDeleteOutput';
  company: Company;
  companyCustomField: CompanyCustomField;
};

export type CompanyCustomFieldEdge = Edge & {
  __typename?: 'CompanyCustomFieldEdge';
  cursor: Scalars['ID']['output'];
  node: CompanyCustomField;
};

export enum CompanyCustomFieldType {
  Integer = 'INTEGER',
  String = 'STRING'
}

export type CompanyCustomFieldUpdateInput = {
  label?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<CompanyCustomFieldType>;
};

export type CompanyCustomFieldUpdateOutput = {
  __typename?: 'CompanyCustomFieldUpdateOutput';
  company: Company;
  companyCustomField: CompanyCustomField;
};

export type CompanyCustomFieldsPagination = Pagination & {
  __typename?: 'CompanyCustomFieldsPagination';
  edges: Array<CompanyCustomFieldEdge>;
  nodes: Array<CompanyCustomField>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CompanyData = {
  chargebeeId?: Maybe<TrackableString>;
  color?: Maybe<TrackableString>;
  displayName?: Maybe<TrackableString>;
  email?: Maybe<TrackableString>;
  hubspotId?: Maybe<TrackableString>;
  intraCommunityVat?: Maybe<TrackableString>;
  isDemo?: Maybe<TrackableBoolean>;
  name?: Maybe<TrackableString>;
  thresholdForDiscounts?: Maybe<TrackableNumber>;
  thresholdForFunds?: Maybe<TrackableNumber>;
};

export type CompanyDocument = DeletableNode & Highlightable & Node & Publishable & {
  __typename?: 'CompanyDocument';
  auditTrailPagination: AuditTrailPagination;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  file: File;
  id: Scalars['ID']['output'];
  isHighlighted: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  parent: CompanyFolder;
  pinnedAt?: Maybe<Scalars['DateTime']['output']>;
  publicationPagination: PublicationPagination;
  updatedAt: Scalars['DateTime']['output'];
};


export type CompanyDocumentAuditTrailPaginationArgs = {
  filter?: InputMaybe<AuditTrailArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<AuditTrailArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyDocumentPublicationPaginationArgs = {
  filter?: InputMaybe<PublicationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<PublicationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyDocumentCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  fileId: Scalars['ID']['input'];
  folderId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyDocumentCreateOutput = {
  __typename?: 'CompanyDocumentCreateOutput';
  companyDocument: CompanyDocument;
  companyFolder: CompanyFolder;
};

export type CompanyDocumentCreatedEventData = EventData & {
  __typename?: 'CompanyDocumentCreatedEventData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
};

export type CompanyDocumentDeleteOutput = {
  __typename?: 'CompanyDocumentDeleteOutput';
  companyDocument: CompanyDocument;
  companyFolder: CompanyFolder;
};

export type CompanyDocumentDeletedEventData = EventData & {
  __typename?: 'CompanyDocumentDeletedEventData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
};

export type CompanyDocumentUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyDocumentUpdateOutput = {
  __typename?: 'CompanyDocumentUpdateOutput';
  companyDocument: CompanyDocument;
  companyFolder: CompanyFolder;
};

export type CompanyDocumentUpdatedEventData = EventData & {
  __typename?: 'CompanyDocumentUpdatedEventData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
};

export type CompanyDocumentsArgsFilter = {
  AND?: InputMaybe<Array<CompanyDocumentsArgsFilter>>;
  OR?: InputMaybe<Array<CompanyDocumentsArgsFilter>>;
  createdAt?: InputMaybe<FilterDateArgs>;
  updatedAt?: InputMaybe<FilterDateArgs>;
};

export type CompanyDocumentsArgsSorter = {
  createdAt?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type CompanyDocumentsEdge = Edge & {
  __typename?: 'CompanyDocumentsEdge';
  cursor: Scalars['ID']['output'];
  node: CompanyDocument;
};

export type CompanyDocumentsPagination = Pagination & {
  __typename?: 'CompanyDocumentsPagination';
  edges: Array<CompanyDocumentsEdge>;
  nodes: Array<CompanyDocument>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CompanyDriveCreateInput = {
  name: Scalars['String']['input'];
};

export type CompanyDriveCreateOutput = {
  __typename?: 'CompanyDriveCreateOutput';
  companyFolder: CompanyFolder;
};

export type CompanyEdge = Edge & {
  __typename?: 'CompanyEdge';
  cursor: Scalars['ID']['output'];
  node: Company;
};

export type CompanyExpenseCategoryArgsFilter = {
  createdAt?: InputMaybe<FilterDateArgs>;
  productType?: InputMaybe<CompanyExpenseCategoryArgsFilterProductType>;
};

export type CompanyExpenseCategoryArgsFilterProductType = {
  in?: InputMaybe<Array<ProductType>>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<ProductType>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<ProductType>;
  notIn?: InputMaybe<Array<ProductType>>;
};

export type CompanyExpenseCategoryEdge = Edge & {
  __typename?: 'CompanyExpenseCategoryEdge';
  amount: Scalars['Int']['output'];
  cursor: Scalars['ID']['output'];
  node: Category;
  transactionCount: Scalars['Int']['output'];
};

export type CompanyExpenseCategoryPagination = Pagination & {
  __typename?: 'CompanyExpenseCategoryPagination';
  amount: Scalars['Int']['output'];
  edges: Array<CompanyExpenseCategoryEdge>;
  nodes: Array<Category>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
  transactionCount: Scalars['Int']['output'];
};

/** Company Feature */
export enum CompanyFeature {
  BannerMobile = 'BANNER_MOBILE',
  Beneficiaries = 'BENEFICIARIES',
  BlockCreateUpdateUser = 'BLOCK_CREATE_UPDATE_USER',
  BlockUpdateCompany = 'BLOCK_UPDATE_COMPANY',
  Communication = 'COMMUNICATION',
  CommunicationMode = 'COMMUNICATION_MODE',
  Games = 'GAMES',
  Happenings = 'HAPPENINGS',
  HappyCompta = 'HAPPY_COMPTA',
  Mailing = 'MAILING',
  MangopayBankingAlias = 'MANGOPAY_BANKING_ALIAS',
  SkipFederatedSsoPage = 'SKIP_FEDERATED_SSO_PAGE',
  SpecificHighlights = 'SPECIFIC_HIGHLIGHTS',
  Subventions = 'SUBVENTIONS',
  SubventionsDiscount = 'SUBVENTIONS_DISCOUNT',
  SubventionsFund = 'SUBVENTIONS_FUND',
  SubventionsRefund = 'SUBVENTIONS_REFUND',
  SubventionsReimbursement = 'SUBVENTIONS_REIMBURSEMENT'
}

export type CompanyFeatureConfig = {
  __typename?: 'CompanyFeatureConfig';
  beneficiary: BeneficiaryCompanyConfig;
  footerCustomisation: FooterCustomisationConfig;
  franceBillet: FranceBilletCompanyConfig;
  gathering: GatheringCompanyConfig;
  happyMedia: HappyMediaConfig;
  homeCustomisation: HomeCustomisationConfig;
  /** This id matches the company ID */
  id: Scalars['ID']['output'];
  identityCustomisation: IdentityCustomisationConfig;
  menuCustomisation: MenuCustomisationConfig;
  survey: SurveyFeatureConfig;
  userIntegration: UserIntegrationCompanyConfig;
};

export type CompanyFeatureDisableInput = {
  feature: CompanyFeature;
  value?: InputMaybe<FeatureAccessMode>;
};

export type CompanyFeatureDisableOutput = {
  __typename?: 'CompanyFeatureDisableOutput';
  company: Company;
};

export type CompanyFeatureDisabledData = EventData & FeatureOptions & {
  __typename?: 'CompanyFeatureDisabledData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  feature: CompanyFeature;
};

export type CompanyFeatureEnableInput = {
  feature: CompanyFeature;
  value?: InputMaybe<FeatureAccessMode>;
};

export type CompanyFeatureEnableOutput = {
  __typename?: 'CompanyFeatureEnableOutput';
  company: Company;
};

export type CompanyFeatureEnabledData = EventData & FeatureOptions & {
  __typename?: 'CompanyFeatureEnabledData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  feature: CompanyFeature;
};

export type CompanyFeaturesProperties = {
  __typename?: 'CompanyFeaturesProperties';
  bannerMobile?: Maybe<Scalars['Boolean']['output']>;
  beneficiaries?: Maybe<Scalars['Boolean']['output']>;
  blockCreateUpdateUser?: Maybe<Scalars['Boolean']['output']>;
  blockUpdateCompany?: Maybe<Scalars['Boolean']['output']>;
  communication?: Maybe<Scalars['Boolean']['output']>;
  communicationMode?: Maybe<FeatureAccessMode>;
  games?: Maybe<FeatureAccessMode>;
  happenings?: Maybe<Scalars['Boolean']['output']>;
  happyCompta?: Maybe<Scalars['Boolean']['output']>;
  mailing?: Maybe<Scalars['Boolean']['output']>;
  mangopayBankingAlias?: Maybe<Scalars['Boolean']['output']>;
  skipFederatedSSOPage?: Maybe<Scalars['Boolean']['output']>;
  specificHighlights?: Maybe<Scalars['Boolean']['output']>;
  subventions?: Maybe<Scalars['Boolean']['output']>;
  subventionsDiscount?: Maybe<Scalars['Boolean']['output']>;
  subventionsFund?: Maybe<Scalars['Boolean']['output']>;
  subventionsRefund?: Maybe<Scalars['Boolean']['output']>;
  subventionsReimbursement?: Maybe<Scalars['Boolean']['output']>;
};

export type CompanyFees = DeletableNode & Node & {
  __typename?: 'CompanyFees';
  collectionMethod: CompanyFeesCollectionMethod;
  company: Company;
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['Currency']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  flat: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  max: Scalars['Int']['output'];
  min: Scalars['Int']['output'];
  percent: Scalars['Float']['output'];
  taxRate?: Maybe<TaxRate>;
  updatedAt: Scalars['DateTime']['output'];
};

export type CompanyFeesArgsSorter = {
  createdAt?: InputMaybe<SortDirection>;
  deletedAt?: InputMaybe<SortDirection>;
};

export enum CompanyFeesCollectionMethod {
  Company = 'COMPANY',
  User = 'USER'
}

export type CompanyFeesCreateInput = {
  collectionMethod: CompanyFeesCollectionMethod;
  currency: Scalars['String']['input'];
  flat: Scalars['Int']['input'];
  max: Scalars['Int']['input'];
  min: Scalars['Int']['input'];
  percent: Scalars['Float']['input'];
  taxRateId?: InputMaybe<Scalars['ID']['input']>;
};

export type CompanyFeesCreateOutput = {
  __typename?: 'CompanyFeesCreateOutput';
  company: Company;
  companyFees: CompanyFees;
};

export type CompanyFeesCreatedData = CompanyFeesCreationDataFields & EventData & {
  __typename?: 'CompanyFeesCreatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  collectionMethod: TrackableCompanyFeesCollectionMethod;
  currency: TrackableString;
  flat: TrackableNumber;
  max: TrackableNumber;
  min: TrackableNumber;
  percent: TrackableNumber;
};

export type CompanyFeesCreationDataFields = {
  collectionMethod: TrackableCompanyFeesCollectionMethod;
  currency: TrackableString;
  flat: TrackableNumber;
  max: TrackableNumber;
  min: TrackableNumber;
  percent: TrackableNumber;
};

export type CompanyFeesDeleteOutput = {
  __typename?: 'CompanyFeesDeleteOutput';
  company: Company;
  companyFees: CompanyFees;
};

export type CompanyFeesDeletedData = EventData & {
  __typename?: 'CompanyFeesDeletedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
};

export type CompanyFeesEdge = Edge & {
  __typename?: 'CompanyFeesEdge';
  cursor: Scalars['ID']['output'];
  node: CompanyFees;
};

export type CompanyFeesPagination = Pagination & {
  __typename?: 'CompanyFeesPagination';
  edges: Array<CompanyFeesEdge>;
  nodes: Array<CompanyFees>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CompanyFeesUpdateInput = {
  collectionMethod: CompanyFeesCollectionMethod;
  currency: Scalars['String']['input'];
  flat: Scalars['Int']['input'];
  max: Scalars['Int']['input'];
  min: Scalars['Int']['input'];
  percent: Scalars['Float']['input'];
  taxRateId?: InputMaybe<Scalars['ID']['input']>;
};

export type CompanyFeesUpdateOutput = {
  __typename?: 'CompanyFeesUpdateOutput';
  company: Company;
  companyFees: CompanyFees;
};

export type CompanyFolder = DeletableNode & Highlightable & Node & Publishable & {
  __typename?: 'CompanyFolder';
  allDocumentsPagination: CompanyDocumentsPagination;
  auditTrailPagination: AuditTrailPagination;
  companyDocumentsPagination: CompanyDocumentsPagination;
  companyFoldersPagination: CompanyFoldersPagination;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isHighlighted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  parent?: Maybe<CompanyFolder>;
  publicationPagination: PublicationPagination;
  updatedAt: Scalars['DateTime']['output'];
};


export type CompanyFolderAllDocumentsPaginationArgs = {
  filter?: InputMaybe<CompanyDocumentsArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CompanyDocumentsArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyFolderAuditTrailPaginationArgs = {
  filter?: InputMaybe<AuditTrailArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<AuditTrailArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyFolderCompanyDocumentsPaginationArgs = {
  filter?: InputMaybe<CompanyDocumentsArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CompanyDocumentsArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyFolderCompanyFoldersPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CompanyFoldersArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type CompanyFolderPublicationPaginationArgs = {
  filter?: InputMaybe<PublicationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<PublicationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyFolderCompressAndExportInput = {
  companyFolderId: Scalars['ID']['input'];
};

export type CompanyFolderCompressAndExportOutput = {
  __typename?: 'CompanyFolderCompressAndExportOutput';
  file: File;
};

export type CompanyFolderCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  parentId: Scalars['ID']['input'];
};

export type CompanyFolderCreateOutput = {
  __typename?: 'CompanyFolderCreateOutput';
  companyFolder: CompanyFolder;
};

export type CompanyFolderCreatedEventData = EventData & {
  __typename?: 'CompanyFolderCreatedEventData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
};

export type CompanyFolderDeleteOutput = {
  __typename?: 'CompanyFolderDeleteOutput';
  companyFolder: CompanyFolder;
};

export type CompanyFolderDeletedEventData = EventData & {
  __typename?: 'CompanyFolderDeletedEventData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
};

export type CompanyFolderUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyFolderUpdateOutput = {
  __typename?: 'CompanyFolderUpdateOutput';
  companyFolder: CompanyFolder;
};

export type CompanyFolderUpdatedEventData = EventData & {
  __typename?: 'CompanyFolderUpdatedEventData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
};

export type CompanyFoldersArgsSorter = {
  createdAt?: InputMaybe<SortDirection>;
};

export type CompanyFoldersEdge = Edge & {
  __typename?: 'CompanyFoldersEdge';
  cursor: Scalars['ID']['output'];
  node: CompanyFolder;
};

export type CompanyFoldersPagination = Pagination & {
  __typename?: 'CompanyFoldersPagination';
  edges: Array<CompanyFoldersEdge>;
  nodes: Array<CompanyFolder>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CompanyIncludeAddData = EventData & FeatureOptions & {
  __typename?: 'CompanyIncludeAddData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  entity: Scalars['String']['output'];
  feature: CompanyFeature;
};

export type CompanyIncludeRemoveData = EventData & FeatureOptions & {
  __typename?: 'CompanyIncludeRemoveData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  entity: Scalars['String']['output'];
  feature: CompanyFeature;
};

export type CompanyMembersImportDryRunInput = {
  users: Array<CompanyMembersImportInputUser>;
};

export type CompanyMembersImportInput = {
  users: Array<CompanyMembersImportInputUser>;
};

export type CompanyMembersImportInputMembership = {
  contract?: InputMaybe<Scalars['String']['input']>;
  deactivated?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  joinedAt?: InputMaybe<Scalars['String']['input']>;
  leftAt?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['JSONObject']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<CompanyMembershipCreateInputUser>;
  worksite?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyMembersImportInputUser = {
  avatarId?: InputMaybe<Scalars['ID']['input']>;
  billingAddress?: InputMaybe<CompanyMembersImportInputUserAddress>;
  birthdate?: InputMaybe<Scalars['String']['input']>;
  companyMembership?: InputMaybe<CompanyMembersImportInputMembership>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  isShippingSameAsBillingAddress?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** A string composed of two-letter country codes, compliant with ISO 3166 standard */
  nationality?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<UserRole>;
  shippingAddress?: InputMaybe<CompanyMembersImportInputUserAddress>;
};

export type CompanyMembersImportInputUserAddress = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  province?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyMembersImportOutput = {
  __typename?: 'CompanyMembersImportOutput';
  memberships: Array<CompanyMembership>;
  users: Array<User>;
};

export type CompanyMembership = Node & TrailableNode & {
  __typename?: 'CompanyMembership';
  auditTrailPagination: AuditTrailPagination;
  company: Company;
  contract?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customFields: Scalars['JSONObject']['output'];
  deactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  jobTitle?: Maybe<Scalars['String']['output']>;
  joinedAt?: Maybe<Scalars['Date']['output']>;
  leftAt?: Maybe<Scalars['Date']['output']>;
  role: CompanyMembershipRole;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  worksite?: Maybe<Scalars['String']['output']>;
};


export type CompanyMembershipAuditTrailPaginationArgs = {
  filter?: InputMaybe<AuditTrailArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<AuditTrailArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyMembershipCreateInput = {
  contract?: InputMaybe<Scalars['String']['input']>;
  deactivated?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  joinedAt?: InputMaybe<Scalars['Date']['input']>;
  leftAt?: InputMaybe<Scalars['Date']['input']>;
  metadata?: InputMaybe<Scalars['JSONObject']['input']>;
  role: CompanyMembershipRole;
  user: CompanyMembershipCreateInputUser;
  worksite?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyMembershipCreateInputUser = {
  attach?: InputMaybe<Scalars['ID']['input']>;
  create?: InputMaybe<UserCreateInput>;
};

export type CompanyMembershipCreateOutput = {
  __typename?: 'CompanyMembershipCreateOutput';
  companyMembership: CompanyMembership;
  user: User;
};

export type CompanyMembershipCreatedData = CompanyMembershipData & EventData & {
  __typename?: 'CompanyMembershipCreatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  contract?: Maybe<TrackableString>;
  deactivated?: Maybe<Scalars['Boolean']['output']>;
  jobTitle?: Maybe<TrackableString>;
  joinedAt?: Maybe<TrackableDate>;
  leftAt?: Maybe<TrackableDate>;
  metadata?: Maybe<Scalars['JSONObject']['output']>;
  role?: Maybe<TrackableCompanyMembershipRole>;
  worksite?: Maybe<TrackableString>;
};

export type CompanyMembershipData = {
  contract?: Maybe<TrackableString>;
  deactivated?: Maybe<Scalars['Boolean']['output']>;
  jobTitle?: Maybe<TrackableString>;
  joinedAt?: Maybe<TrackableDate>;
  leftAt?: Maybe<TrackableDate>;
  metadata?: Maybe<Scalars['JSONObject']['output']>;
  worksite?: Maybe<TrackableString>;
};

export type CompanyMembershipEdge = Edge & {
  __typename?: 'CompanyMembershipEdge';
  cursor: Scalars['ID']['output'];
  node: CompanyMembership;
};

export type CompanyMembershipJoinedAtArgsFilter = {
  gt?: InputMaybe<Scalars['Date']['input']>;
  gte?: InputMaybe<Scalars['Date']['input']>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<Scalars['Date']['input']>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<Scalars['Date']['input']>;
  lt?: InputMaybe<Scalars['Date']['input']>;
  lte?: InputMaybe<Scalars['Date']['input']>;
  nulls?: InputMaybe<FilterNulls>;
};

export type CompanyMembershipLeftAtArgsFilter = {
  gt?: InputMaybe<Scalars['Date']['input']>;
  gte?: InputMaybe<Scalars['Date']['input']>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<Scalars['Date']['input']>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<Scalars['Date']['input']>;
  lt?: InputMaybe<Scalars['Date']['input']>;
  lte?: InputMaybe<Scalars['Date']['input']>;
  nulls?: InputMaybe<FilterNulls>;
};

export type CompanyMembershipListRelationFilter = {
  EVERY?: InputMaybe<CompanyMembershipsArgsFilter>;
  NONE?: InputMaybe<CompanyMembershipsArgsFilter>;
  SOME?: InputMaybe<CompanyMembershipsArgsFilter>;
};

export enum CompanyMembershipRole {
  Admin = 'ADMIN',
  Member = 'MEMBER'
}

export type CompanyMembershipSeniorityArgsFilter = {
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<Scalars['Int']['input']>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<Scalars['Int']['input']>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  nulls?: InputMaybe<FilterNulls>;
};

export enum CompanyMembershipState {
  Admin = 'ADMIN',
  Created = 'CREATED',
  Deactivated = 'DEACTIVATED',
  Invited = 'INVITED',
  User = 'USER'
}

export type CompanyMembershipUpdateInput = {
  contract?: InputMaybe<Scalars['String']['input']>;
  deactivated?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  joinedAt?: InputMaybe<Scalars['Date']['input']>;
  leftAt?: InputMaybe<Scalars['Date']['input']>;
  metadata?: InputMaybe<Scalars['JSONObject']['input']>;
  role?: InputMaybe<CompanyMembershipRole>;
  user?: InputMaybe<CompanyMembershipUpdateInputUser>;
  worksite?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyMembershipUpdateInputUser = {
  update?: InputMaybe<UserUpdateInput>;
};

export type CompanyMembershipUpdateOutput = {
  __typename?: 'CompanyMembershipUpdateOutput';
  companyMembership: CompanyMembership;
};

export type CompanyMembershipUpdatedData = CompanyMembershipData & EventData & {
  __typename?: 'CompanyMembershipUpdatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  contract?: Maybe<TrackableString>;
  deactivated?: Maybe<Scalars['Boolean']['output']>;
  jobTitle?: Maybe<TrackableString>;
  joinedAt?: Maybe<TrackableDate>;
  leftAt?: Maybe<TrackableDate>;
  metadata?: Maybe<Scalars['JSONObject']['output']>;
  role?: Maybe<TrackableCompanyMembershipRole>;
  worksite?: Maybe<TrackableString>;
};

export type CompanyMembershipsArgsCustomFieldSorter = {
  direction: SortDirection;
  key: Scalars['String']['input'];
};

export type CompanyMembershipsArgsFilter = {
  AND?: InputMaybe<Array<CompanyMembershipsArgsFilter>>;
  OR?: InputMaybe<Array<CompanyMembershipsArgsFilter>>;
  company?: InputMaybe<CompaniesArgsFilter>;
  contract?: InputMaybe<FilterStringArgs>;
  customFields?: InputMaybe<Array<CompanyMembershipsArgsFilterCustomField>>;
  id?: InputMaybe<FilterIdArgs>;
  jobTitle?: InputMaybe<FilterStringArgs>;
  joinedAt?: InputMaybe<CompanyMembershipJoinedAtArgsFilter>;
  leftAt?: InputMaybe<CompanyMembershipLeftAtArgsFilter>;
  role?: InputMaybe<CompanyMembershipsArgsFilterRole>;
  seniority?: InputMaybe<CompanyMembershipSeniorityArgsFilter>;
  state?: InputMaybe<CompanyMembershipsArgsFilterState>;
  status?: InputMaybe<CompanyMembershipsArgsFilterStatus>;
  user?: InputMaybe<UsersArgsFilter>;
  worksite?: InputMaybe<FilterStringArgs>;
};

export type CompanyMembershipsArgsFilterCustomField = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  exclude?: InputMaybe<Array<Scalars['ID']['input']>>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['ID']['input']>>;
  include?: InputMaybe<Array<Scalars['ID']['input']>>;
  is?: InputMaybe<Scalars['ID']['input']>;
  isNot?: InputMaybe<Scalars['ID']['input']>;
  key: Scalars['String']['input'];
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  max?: InputMaybe<Scalars['Int']['input']>;
  min?: InputMaybe<Scalars['Int']['input']>;
  notIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyMembershipsArgsFilterRole = {
  in?: InputMaybe<Array<CompanyMembershipRole>>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<CompanyMembershipRole>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<CompanyMembershipRole>;
  notIn?: InputMaybe<Array<CompanyMembershipRole>>;
};

export type CompanyMembershipsArgsFilterState = {
  exclude?: InputMaybe<Array<CompanyMembershipState>>;
  include?: InputMaybe<Array<CompanyMembershipState>>;
};

export type CompanyMembershipsArgsFilterStatus = {
  exclude?: InputMaybe<Array<UserStatus>>;
  include?: InputMaybe<Array<UserStatus>>;
};

export type CompanyMembershipsArgsSorter = {
  birthdate?: InputMaybe<SortDirection>;
  contract?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  customFields?: InputMaybe<Array<CompanyMembershipsArgsCustomFieldSorter>>;
  email?: InputMaybe<SortDirection>;
  firstName?: InputMaybe<SortDirection>;
  jobTitle?: InputMaybe<SortDirection>;
  joinedAt?: InputMaybe<SortDirection>;
  lastName?: InputMaybe<SortDirection>;
  leftAt?: InputMaybe<SortDirection>;
  nationality?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
  worksite?: InputMaybe<SortDirection>;
};

export type CompanyMembershipsPagination = Pagination & {
  __typename?: 'CompanyMembershipsPagination';
  /** @deprecated Use company metadata instead */
  contracts: Array<PaginationEnumFilter>;
  edges: Array<CompanyMembershipEdge>;
  nodes: Array<CompanyMembership>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
  /** @deprecated Use company metadata instead */
  worksites: Array<PaginationEnumFilter>;
};

export type CompanyOfferClickCategoryArgsFilter = {
  createdAt?: InputMaybe<FilterDateArgs>;
};

export type CompanyOfferClickCategoryEdge = Edge & {
  __typename?: 'CompanyOfferClickCategoryEdge';
  clickCount: Scalars['Int']['output'];
  cursor: Scalars['ID']['output'];
  node: Category;
};

export type CompanyOfferClickCategoryPagination = Pagination & {
  __typename?: 'CompanyOfferClickCategoryPagination';
  clickCount: Scalars['Int']['output'];
  edges: Array<CompanyOfferClickCategoryEdge>;
  nodes: Array<Category>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export enum CompanyPersona {
  Esc = 'ESC',
  Hr = 'HR',
  Other = 'OTHER'
}

export type CompanyPublicOutput = {
  __typename?: 'CompanyPublicOutput';
  color?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  isMailingEnabled?: Maybe<Scalars['Boolean']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
};

export type CompanySubsidiaryImportDryOutput = {
  __typename?: 'CompanySubsidiaryImportDryOutput';
  errors: CompanySubsidiaryImportDryOutputErrors;
};

export type CompanySubsidiaryImportDryOutputErrors = {
  __typename?: 'CompanySubsidiaryImportDryOutputErrors';
  conflictingSlugs: Array<Scalars['String']['output']>;
  duplicateEmails: Array<Scalars['String']['output']>;
  existingEmails: Array<Scalars['String']['output']>;
};

export type CompanySubsidiaryImportInput = {
  rows: Array<CompanySubsidiaryImportRow>;
};

export type CompanySubsidiaryImportOutput = {
  __typename?: 'CompanySubsidiaryImportOutput';
  users: Array<User>;
};

export type CompanySubsidiaryImportRow = {
  companyName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  role: CompanyMembershipRole;
  slug: Scalars['String']['input'];
};

export type CompanySubventionEdge = Edge & {
  __typename?: 'CompanySubventionEdge';
  cursor: Scalars['ID']['output'];
  node: Subvention;
};

export type CompanySubventionPagination = Pagination & {
  __typename?: 'CompanySubventionPagination';
  budgetBySubventionSchema: BudgetBySubventionSchemaOutput;
  /** @deprecated Use budgetConsumedBigInt instead */
  budgetConsumed: Scalars['Int']['output'];
  budgetConsumedBigInt: Scalars['BigInt']['output'];
  /** @deprecated Use budgetTotalBigInt instead */
  budgetTotal: Scalars['Int']['output'];
  budgetTotalBigInt: Scalars['BigInt']['output'];
  edges: Array<SubventionEdge>;
  nodes: Array<Subvention>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CompanyUpdateInput = {
  accountManagerId?: InputMaybe<Scalars['ID']['input']>;
  address?: InputMaybe<AddressCreateInput>;
  chargebeeId?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  commercialPackage?: InputMaybe<CompanyCommercialPackage>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  greetingsMessage?: InputMaybe<Scalars['String']['input']>;
  hubspotId?: InputMaybe<Scalars['String']['input']>;
  intraCommunityVat?: InputMaybe<Scalars['String']['input']>;
  isDemo?: InputMaybe<Scalars['Boolean']['input']>;
  logoId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  persona?: InputMaybe<CompanyPersona>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
  thresholdForDiscounts?: InputMaybe<Scalars['Int']['input']>;
  thresholdForFunds?: InputMaybe<Scalars['Int']['input']>;
  welcomeMessage?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyUpdateOutput = {
  __typename?: 'CompanyUpdateOutput';
  company: Company;
};

export type CompanyUpdatedData = CompanyData & EventData & WithPicture & {
  __typename?: 'CompanyUpdatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  address?: Maybe<AddressData>;
  chargebeeId?: Maybe<TrackableString>;
  color?: Maybe<TrackableString>;
  displayName?: Maybe<TrackableString>;
  email?: Maybe<TrackableString>;
  hubspotId?: Maybe<TrackableString>;
  intraCommunityVat?: Maybe<TrackableString>;
  isDemo?: Maybe<TrackableBoolean>;
  name?: Maybe<TrackableString>;
  picture?: Maybe<Picture>;
  thresholdForDiscounts?: Maybe<TrackableNumber>;
  thresholdForFunds?: Maybe<TrackableNumber>;
};

export type Connection = {
  edges: Array<Edge>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ConnectionSorter = {
  direction: SortDirection;
};

export type CoreFilterString = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  exact?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type CorePagination = {
  edges: Array<Edge>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CorePaginationSorter = {
  direction: SortDirection;
};

/** ISO 3166-1 alpha-2 country codes */
export enum CountryCode {
  Ad = 'AD',
  Ae = 'AE',
  Af = 'AF',
  Ag = 'AG',
  Ai = 'AI',
  Al = 'AL',
  Am = 'AM',
  Ao = 'AO',
  Aq = 'AQ',
  Ar = 'AR',
  As = 'AS',
  At = 'AT',
  Au = 'AU',
  Aw = 'AW',
  Ax = 'AX',
  Az = 'AZ',
  Ba = 'BA',
  Bb = 'BB',
  Bd = 'BD',
  Be = 'BE',
  Bf = 'BF',
  Bg = 'BG',
  Bh = 'BH',
  Bi = 'BI',
  Bj = 'BJ',
  Bl = 'BL',
  Bm = 'BM',
  Bn = 'BN',
  Bo = 'BO',
  Bq = 'BQ',
  Br = 'BR',
  Bs = 'BS',
  Bt = 'BT',
  Bv = 'BV',
  Bw = 'BW',
  By = 'BY',
  Bz = 'BZ',
  Ca = 'CA',
  Cc = 'CC',
  Cd = 'CD',
  Cf = 'CF',
  Cg = 'CG',
  Ch = 'CH',
  Ci = 'CI',
  Ck = 'CK',
  Cl = 'CL',
  Cm = 'CM',
  Cn = 'CN',
  Co = 'CO',
  Cr = 'CR',
  Cu = 'CU',
  Cv = 'CV',
  Cw = 'CW',
  Cx = 'CX',
  Cy = 'CY',
  Cz = 'CZ',
  De = 'DE',
  Dj = 'DJ',
  Dk = 'DK',
  Dm = 'DM',
  Do = 'DO',
  Dz = 'DZ',
  Ec = 'EC',
  Ee = 'EE',
  Eg = 'EG',
  Eh = 'EH',
  Er = 'ER',
  Es = 'ES',
  Et = 'ET',
  Fi = 'FI',
  Fj = 'FJ',
  Fk = 'FK',
  Fm = 'FM',
  Fo = 'FO',
  Fr = 'FR',
  Ga = 'GA',
  Gb = 'GB',
  Gd = 'GD',
  Ge = 'GE',
  Gf = 'GF',
  Gg = 'GG',
  Gh = 'GH',
  Gi = 'GI',
  Gl = 'GL',
  Gm = 'GM',
  Gn = 'GN',
  Gp = 'GP',
  Gq = 'GQ',
  Gr = 'GR',
  Gs = 'GS',
  Gt = 'GT',
  Gu = 'GU',
  Gw = 'GW',
  Gy = 'GY',
  Hk = 'HK',
  Hm = 'HM',
  Hn = 'HN',
  Hr = 'HR',
  Ht = 'HT',
  Hu = 'HU',
  Id = 'ID',
  Ie = 'IE',
  Il = 'IL',
  Im = 'IM',
  In = 'IN',
  Io = 'IO',
  Iq = 'IQ',
  Ir = 'IR',
  Is = 'IS',
  It = 'IT',
  Je = 'JE',
  Jm = 'JM',
  Jo = 'JO',
  Jp = 'JP',
  Ke = 'KE',
  Kg = 'KG',
  Kh = 'KH',
  Ki = 'KI',
  Km = 'KM',
  Kn = 'KN',
  Kp = 'KP',
  Kr = 'KR',
  Kw = 'KW',
  Ky = 'KY',
  Kz = 'KZ',
  La = 'LA',
  Lb = 'LB',
  Lc = 'LC',
  Li = 'LI',
  Lk = 'LK',
  Lr = 'LR',
  Ls = 'LS',
  Lt = 'LT',
  Lu = 'LU',
  Lv = 'LV',
  Ly = 'LY',
  Ma = 'MA',
  Mc = 'MC',
  Md = 'MD',
  Me = 'ME',
  Mf = 'MF',
  Mg = 'MG',
  Mh = 'MH',
  Mk = 'MK',
  Ml = 'ML',
  Mm = 'MM',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Mq = 'MQ',
  Mr = 'MR',
  Ms = 'MS',
  Mt = 'MT',
  Mu = 'MU',
  Mv = 'MV',
  Mw = 'MW',
  Mx = 'MX',
  My = 'MY',
  Mz = 'MZ',
  Na = 'NA',
  Nc = 'NC',
  Ne = 'NE',
  Nf = 'NF',
  Ng = 'NG',
  Ni = 'NI',
  Nl = 'NL',
  No = 'NO',
  Np = 'NP',
  Nr = 'NR',
  Nu = 'NU',
  Nz = 'NZ',
  Om = 'OM',
  Pa = 'PA',
  Pe = 'PE',
  Pf = 'PF',
  Pg = 'PG',
  Ph = 'PH',
  Pk = 'PK',
  Pl = 'PL',
  Pm = 'PM',
  Pn = 'PN',
  Pr = 'PR',
  Ps = 'PS',
  Pt = 'PT',
  Pw = 'PW',
  Py = 'PY',
  Qa = 'QA',
  Re = 'RE',
  Ro = 'RO',
  Rs = 'RS',
  Ru = 'RU',
  Rw = 'RW',
  Sa = 'SA',
  Sb = 'SB',
  Sc = 'SC',
  Sd = 'SD',
  Se = 'SE',
  Sg = 'SG',
  Sh = 'SH',
  Si = 'SI',
  Sk = 'SK',
  Sl = 'SL',
  Sm = 'SM',
  Sn = 'SN',
  So = 'SO',
  Sr = 'SR',
  Ss = 'SS',
  St = 'ST',
  Sv = 'SV',
  Sx = 'SX',
  Sy = 'SY',
  Sz = 'SZ',
  Tc = 'TC',
  Td = 'TD',
  Tf = 'TF',
  Tg = 'TG',
  Th = 'TH',
  Tj = 'TJ',
  Tk = 'TK',
  Tl = 'TL',
  Tm = 'TM',
  Tn = 'TN',
  To = 'TO',
  Tr = 'TR',
  Tt = 'TT',
  Tv = 'TV',
  Tw = 'TW',
  Tz = 'TZ',
  Ua = 'UA',
  Ug = 'UG',
  Um = 'UM',
  Us = 'US',
  Uy = 'UY',
  Uz = 'UZ',
  Va = 'VA',
  Vc = 'VC',
  Ve = 'VE',
  Vg = 'VG',
  Vi = 'VI',
  Vn = 'VN',
  Vu = 'VU',
  Wf = 'WF',
  Ws = 'WS',
  Xk = 'XK',
  Ye = 'YE',
  Yt = 'YT',
  Za = 'ZA',
  Zm = 'ZM',
  Zw = 'ZW'
}

export type CouponClaimable = DeletableNode & Node & {
  __typename?: 'CouponClaimable';
  claimedAsCompany?: Maybe<Company>;
  claimedAt?: Maybe<Scalars['DateTime']['output']>;
  claimedBy?: Maybe<User>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  offer: OfferCouponClaimable;
  updatedAt: Scalars['DateTime']['output'];
  value: Scalars['String']['output'];
};

export type CouponClaimableClaimOutput = {
  __typename?: 'CouponClaimableClaimOutput';
  claimedCoupon: CouponClaimable;
};

export type CouponClaimableDeleteOutput = {
  __typename?: 'CouponClaimableDeleteOutput';
  couponClaimable: CouponClaimable;
};

export type CouponClaimableDeletedData = EventData & {
  __typename?: 'CouponClaimableDeletedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
};

export type CouponClaimableEdge = Edge & {
  __typename?: 'CouponClaimableEdge';
  cursor: Scalars['ID']['output'];
  node: CouponClaimable;
};

export type CouponClaimableUpdateInput = {
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CouponClaimableUpdateOutput = {
  __typename?: 'CouponClaimableUpdateOutput';
  couponClaimable: CouponClaimable;
};

export type CouponClaimableUpdatedData = EventData & {
  __typename?: 'CouponClaimableUpdatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  expiresAt?: Maybe<TrackableDateTime>;
  value?: Maybe<TrackableString>;
};

export type CouponClaimablesCreateInput = {
  coupons: Array<Scalars['String']['input']>;
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CouponClaimablesCreateOutput = {
  __typename?: 'CouponClaimablesCreateOutput';
  couponClaimables: Array<CouponClaimable>;
  offer: Offer;
};

export type CouponClaimablesPagination = Pagination & {
  __typename?: 'CouponClaimablesPagination';
  edges: Array<CouponClaimableEdge>;
  nodes: Array<CouponClaimable>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CouponClaimablesPaginationArgsFilter = {
  company?: InputMaybe<CompaniesArgsFilter>;
  user?: InputMaybe<UsersArgsFilter>;
};

export type CouponClaimablesPaginationArgsSorter = {
  claimedAsCompany?: InputMaybe<SortDirection>;
  claimedAt?: InputMaybe<SortDirection>;
  claimedBy?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  expiresAt?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type CouponPublic = Node & {
  __typename?: 'CouponPublic';
  createdAt: Scalars['DateTime']['output'];
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  value: Scalars['String']['output'];
};

export type CouponPublicUpdateInput = {
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CouponPublicUpdateOutput = {
  __typename?: 'CouponPublicUpdateOutput';
  coupon: CouponPublic;
};

export type CouponPublicUpdatedData = EventData & {
  __typename?: 'CouponPublicUpdatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  expiresAt?: Maybe<TrackableDateTime>;
  value?: Maybe<TrackableString>;
};

export type CreditNote = {
  __typename?: 'CreditNote';
  createdAt: Scalars['DateTime']['output'];
  file?: Maybe<File>;
  id: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
};

export type DeletableNode = {
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** User's Operating System */
export enum DeviceOs {
  Android = 'ANDROID',
  Ios = 'IOS'
}

export type Discount = {
  amountOff?: Maybe<Scalars['Int']['output']>;
  company?: Maybe<Company>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  endsAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  percentOff?: Maybe<Scalars['Float']['output']>;
  startsAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type DiscountArgsFilter = {
  endsAt?: InputMaybe<FilterDateArgs>;
  id?: InputMaybe<FilterIdArgs>;
  percentOff?: InputMaybe<FilterIntArgs>;
  startsAt?: InputMaybe<FilterDateArgs>;
};

export type DiscountArgsSorter = {
  createdAt?: InputMaybe<SortDirection>;
};

export type DiscountCreateInput = {
  amountOff?: InputMaybe<Scalars['Float']['input']>;
  endsAt?: InputMaybe<Scalars['DateTime']['input']>;
  percentOff?: InputMaybe<Scalars['Float']['input']>;
  startsAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DiscountCreateOutput = {
  __typename?: 'DiscountCreateOutput';
  company?: Maybe<Company>;
  discount: Discount;
  product: Product;
};

export type DiscountDeleteOutput = {
  __typename?: 'DiscountDeleteOutput';
  discount: Discount;
};

export type DiscountEdge = Edge & {
  __typename?: 'DiscountEdge';
  cursor: Scalars['ID']['output'];
  node: Discount;
};

export type DiscountFlat = DeletableNode & Discount & Node & {
  __typename?: 'DiscountFlat';
  amountOff: Scalars['Int']['output'];
  company?: Maybe<Company>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  endsAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  percentOff?: Maybe<Scalars['Float']['output']>;
  startsAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type DiscountPagination = Pagination & {
  __typename?: 'DiscountPagination';
  edges: Array<DiscountEdge>;
  nodes: Array<Discount>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type DiscountPercentage = DeletableNode & Discount & Node & {
  __typename?: 'DiscountPercentage';
  amountOff?: Maybe<Scalars['Int']['output']>;
  company?: Maybe<Company>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  endsAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  percentOff: Scalars['Float']['output'];
  startsAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type DiscountUnion = FlatDiscount | PercentageDiscount | RewardDiscount;

export type Discover = Node & {
  __typename?: 'Discover';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  position: Scalars['Int']['output'];
  target: DiscoverTarget;
  updatedAt: Scalars['DateTime']['output'];
};

export type DiscoverCreateInput = {
  targetId: Scalars['ID']['input'];
  targetType: DiscoverTargetType;
};

export type DiscoverCreateOutput = {
  __typename?: 'DiscoverCreateOutput';
  discover: Discover;
};

export type DiscoverDeleteOutput = {
  __typename?: 'DiscoverDeleteOutput';
  discover: Discover;
};

export type DiscoverEdge = Edge & {
  __typename?: 'DiscoverEdge';
  cursor: Scalars['ID']['output'];
  node: Discover;
};

export type DiscoverPagination = Pagination & {
  __typename?: 'DiscoverPagination';
  edges: Array<DiscoverEdge>;
  nodes: Array<Discover>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type DiscoverPaginationArgsFilter = {
  company?: InputMaybe<CompaniesArgsFilter>;
  targetType?: InputMaybe<DiscoverPaginationArgsFilterTargetType>;
};

export type DiscoverPaginationArgsFilterTargetType = {
  in?: InputMaybe<Array<DiscoverTargetType>>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<DiscoverTargetType>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<DiscoverTargetType>;
  notIn?: InputMaybe<Array<DiscoverTargetType>>;
};

export type DiscoverTarget = Campaign | Category;

export type DiscoverTargetPaginationArgsFilter = {
  isCustom?: InputMaybe<FilterBoolArgs>;
  offers?: InputMaybe<OffersRelationFilter>;
};

export enum DiscoverTargetType {
  Campaign = 'CAMPAIGN',
  Category = 'CATEGORY'
}

export type DiscoverUpdatePositionInput = {
  position?: InputMaybe<Scalars['Int']['input']>;
};

export type DiscoverUpdatePositionOutput = {
  __typename?: 'DiscoverUpdatePositionOutput';
  discover: Discover;
};

export type Edge = {
  cursor: Scalars['ID']['output'];
  node: Node;
};

export type EventData = {
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
};

export enum EventTypes {
  ApiKeyCreated = 'API_KEY_CREATED',
  ApiKeyDeleted = 'API_KEY_DELETED',
  ApiKeyUpdated = 'API_KEY_UPDATED',
  ArticleArchived = 'ARTICLE_ARCHIVED',
  ArticleCreated = 'ARTICLE_CREATED',
  ArticleDeleted = 'ARTICLE_DELETED',
  ArticleTranslationCreated = 'ARTICLE_TRANSLATION_CREATED',
  ArticleTranslationDeleted = 'ARTICLE_TRANSLATION_DELETED',
  ArticleTranslationUpdated = 'ARTICLE_TRANSLATION_UPDATED',
  ArticleUnarchived = 'ARTICLE_UNARCHIVED',
  ArticleUpdated = 'ARTICLE_UPDATED',
  BeneficiaryAccepted = 'BENEFICIARY_ACCEPTED',
  BeneficiaryAddedProof = 'BENEFICIARY_ADDED_PROOF',
  BeneficiaryCreated = 'BENEFICIARY_CREATED',
  BeneficiaryDeleted = 'BENEFICIARY_DELETED',
  BeneficiaryRejected = 'BENEFICIARY_REJECTED',
  BeneficiaryRemovedProof = 'BENEFICIARY_REMOVED_PROOF',
  BeneficiaryUpdated = 'BENEFICIARY_UPDATED',
  BrandCreated = 'BRAND_CREATED',
  BrandTranslationCreated = 'BRAND_TRANSLATION_CREATED',
  BrandTranslationDeleted = 'BRAND_TRANSLATION_DELETED',
  BrandTranslationUpdated = 'BRAND_TRANSLATION_UPDATED',
  BrandUpdated = 'BRAND_UPDATED',
  CampaignCreated = 'CAMPAIGN_CREATED',
  CampaignHighlightCreated = 'CAMPAIGN_HIGHLIGHT_CREATED',
  CampaignHighlightDeleted = 'CAMPAIGN_HIGHLIGHT_DELETED',
  CampaignPublished = 'CAMPAIGN_PUBLISHED',
  CampaignTranslationCreated = 'CAMPAIGN_TRANSLATION_CREATED',
  CampaignTranslationDeleted = 'CAMPAIGN_TRANSLATION_DELETED',
  CampaignTranslationUpdated = 'CAMPAIGN_TRANSLATION_UPDATED',
  CampaignUnpublished = 'CAMPAIGN_UNPUBLISHED',
  CampaignUpdated = 'CAMPAIGN_UPDATED',
  CategoryCreated = 'CATEGORY_CREATED',
  CategoryDeleted = 'CATEGORY_DELETED',
  CategoryTranslationCreated = 'CATEGORY_TRANSLATION_CREATED',
  CategoryTranslationDeleted = 'CATEGORY_TRANSLATION_DELETED',
  CategoryTranslationUpdated = 'CATEGORY_TRANSLATION_UPDATED',
  CategoryUpdated = 'CATEGORY_UPDATED',
  CohortCreated = 'COHORT_CREATED',
  CohortDeleted = 'COHORT_DELETED',
  CohortUpdated = 'COHORT_UPDATED',
  CompanyCreated = 'COMPANY_CREATED',
  CompanyDocumentCreated = 'COMPANY_DOCUMENT_CREATED',
  CompanyDocumentDeleted = 'COMPANY_DOCUMENT_DELETED',
  CompanyDocumentUpdated = 'COMPANY_DOCUMENT_UPDATED',
  CompanyExclusiveAdd = 'COMPANY_EXCLUSIVE_ADD',
  CompanyExclusiveRemove = 'COMPANY_EXCLUSIVE_REMOVE',
  CompanyFeatureDisabled = 'COMPANY_FEATURE_DISABLED',
  CompanyFeatureEnabled = 'COMPANY_FEATURE_ENABLED',
  CompanyFeesCreated = 'COMPANY_FEES_CREATED',
  CompanyFeesDeleted = 'COMPANY_FEES_DELETED',
  CompanyFolderCreated = 'COMPANY_FOLDER_CREATED',
  CompanyFolderDeleted = 'COMPANY_FOLDER_DELETED',
  CompanyFolderUpdated = 'COMPANY_FOLDER_UPDATED',
  CompanyUpdated = 'COMPANY_UPDATED',
  CouponClaimableDeleted = 'COUPON_CLAIMABLE_DELETED',
  CouponClaimableUpdated = 'COUPON_CLAIMABLE_UPDATED',
  CouponPublicUpdated = 'COUPON_PUBLIC_UPDATED',
  HighlightCreated = 'HIGHLIGHT_CREATED',
  HighlightDeleted = 'HIGHLIGHT_DELETED',
  MembershipCreated = 'MEMBERSHIP_CREATED',
  MembershipUpdated = 'MEMBERSHIP_UPDATED',
  NewsletterCreated = 'NEWSLETTER_CREATED',
  NewsletterDeleted = 'NEWSLETTER_DELETED',
  NewsletterDuplicated = 'NEWSLETTER_DUPLICATED',
  NewsletterSent = 'NEWSLETTER_SENT',
  NewsletterUpdated = 'NEWSLETTER_UPDATED',
  OfferCreated = 'OFFER_CREATED',
  OfferPublished = 'OFFER_PUBLISHED',
  OfferTranslationCreated = 'OFFER_TRANSLATION_CREATED',
  OfferTranslationDeleted = 'OFFER_TRANSLATION_DELETED',
  OfferTranslationUpdated = 'OFFER_TRANSLATION_UPDATED',
  OfferUnpublished = 'OFFER_UNPUBLISHED',
  OfferUpdated = 'OFFER_UPDATED',
  PointOfContactCreated = 'POINT_OF_CONTACT_CREATED',
  PointOfContactDeleted = 'POINT_OF_CONTACT_DELETED',
  PointOfContactUpdated = 'POINT_OF_CONTACT_UPDATED',
  PublicationCreated = 'PUBLICATION_CREATED',
  PublicationDeleted = 'PUBLICATION_DELETED',
  PublicationPublished = 'PUBLICATION_PUBLISHED',
  PublicationUnpublished = 'PUBLICATION_UNPUBLISHED',
  PublicationUpdated = 'PUBLICATION_UPDATED',
  SubventionAborted = 'SUBVENTION_ABORTED',
  SubventionCompleted = 'SUBVENTION_COMPLETED',
  SubventionConfirmed = 'SUBVENTION_CONFIRMED',
  SubventionCreated = 'SUBVENTION_CREATED',
  SubventionDeleted = 'SUBVENTION_DELETED',
  SubventionLocked = 'SUBVENTION_LOCKED',
  SubventionProvisionValidated = 'SUBVENTION_PROVISION_VALIDATED',
  SubventionPublished = 'SUBVENTION_PUBLISHED',
  SubventionRenamed = 'SUBVENTION_RENAMED',
  SubventionScheduled = 'SUBVENTION_SCHEDULED',
  SubventionUpdated = 'SUBVENTION_UPDATED',
  TaxRateCreated = 'TAX_RATE_CREATED',
  TaxRateDeleted = 'TAX_RATE_DELETED',
  TaxRateUpdated = 'TAX_RATE_UPDATED',
  UserBanned = 'USER_BANNED',
  UserCreated = 'USER_CREATED',
  UserUnbanned = 'USER_UNBANNED',
  UserUpdated = 'USER_UPDATED',
  VoucherCreated = 'VOUCHER_CREATED',
  VoucherDeleted = 'VOUCHER_DELETED',
  VoucherFlatDiscountUpdated = 'VOUCHER_FLAT_DISCOUNT_UPDATED',
  VoucherPercentDiscountUpdated = 'VOUCHER_PERCENT_DISCOUNT_UPDATED',
  VoucherRewardUpdated = 'VOUCHER_REWARD_UPDATED'
}

export type ExternalUrl = DeletableNode & Node & {
  __typename?: 'ExternalURL';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  hash?: Maybe<Scalars['String']['output']>;
  hostname: Scalars['String']['output'];
  href: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  origin: Scalars['String']['output'];
  password?: Maybe<Scalars['String']['output']>;
  pathname?: Maybe<Scalars['String']['output']>;
  port?: Maybe<Scalars['Int']['output']>;
  protocol: Scalars['String']['output'];
  search?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  username?: Maybe<Scalars['String']['output']>;
};

export type FavoriteCampaign = {
  __typename?: 'FavoriteCampaign';
  campaign: Campaign;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
};

export type FavoriteCampaignArgsFilter = {
  name?: InputMaybe<Scalars['String']['input']>;
  offer?: InputMaybe<CampaignOfferArgsFilter>;
};

export type FavoriteCampaignEdge = {
  __typename?: 'FavoriteCampaignEdge';
  cursor: Scalars['String']['output'];
  node: FavoriteCampaign;
};

export type FavoriteCampaignOutput = {
  __typename?: 'FavoriteCampaignOutput';
  favorite: FavoriteCampaign;
};

export type FavoritePagination = {
  __typename?: 'FavoritePagination';
  edges?: Maybe<Array<FavoriteCampaignEdge>>;
  nodes?: Maybe<Array<FavoriteCampaign>>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type FavoritePaginationArgsFilter = {
  campaign?: InputMaybe<FavoriteCampaignArgsFilter>;
};

export type FavoritePaginationSorterArgs = {
  createdAt?: InputMaybe<SortDirection>;
};

export enum FeatureAccessMode {
  Blacklist = 'BLACKLIST',
  Whitelist = 'WHITELIST'
}

export type FeatureConfig = {
  createdAt: Scalars['DateTime']['output'];
  /** This id matches the company ID */
  id: Scalars['ID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type FeatureOptions = {
  feature: CompanyFeature;
};

export type File = DeletableNode & Node & {
  __typename?: 'File';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  mimetype: Scalars['String']['output'];
  originalName?: Maybe<Scalars['String']['output']>;
  size: Scalars['Int']['output'];
  type: FileType;
  updatedAt: Scalars['DateTime']['output'];
  uploader?: Maybe<User>;
  url: Scalars['String']['output'];
};


export type FileUrlArgs = {
  downloadable?: Scalars['Boolean']['input'];
};

export type FileArgsFilterStatus = {
  in?: InputMaybe<Array<FileType>>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<FileType>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<FileType>;
  notIn?: InputMaybe<Array<FileType>>;
};

export type FileCreateInput = {
  fileName: Scalars['String']['input'];
  mimetype: Scalars['String']['input'];
  size: Scalars['Int']['input'];
  type: FileType;
};

export type FileCreateOutput = {
  __typename?: 'FileCreateOutput';
  fields: Scalars['JSONObject']['output'];
  file: File;
  url: Scalars['String']['output'];
};

export type FileEdge = Edge & {
  __typename?: 'FileEdge';
  cursor: Scalars['ID']['output'];
  node: File;
};

export type FileRelationFilter = {
  EVERY?: InputMaybe<FilesFilterArgs>;
  NONE?: InputMaybe<FilesFilterArgs>;
  SOME?: InputMaybe<FilesFilterArgs>;
};

export enum FileType {
  ArticleAttachment = 'ARTICLE_ATTACHMENT',
  ArticleCategoryCover = 'ARTICLE_CATEGORY_COVER',
  ArticleCover = 'ARTICLE_COVER',
  BeneficiaryProof = 'BENEFICIARY_PROOF',
  BrandCover = 'BRAND_COVER',
  BrandLogo = 'BRAND_LOGO',
  CampaignCover = 'CAMPAIGN_COVER',
  CampaignSelectionCover = 'CAMPAIGN_SELECTION_COVER',
  CampaignThumbnail = 'CAMPAIGN_THUMBNAIL',
  CategoryIcon = 'CATEGORY_ICON',
  CompanyFolderZip = 'COMPANY_FOLDER_ZIP',
  CompanyLogo = 'COMPANY_LOGO',
  CreditNotePdf = 'CREDIT_NOTE_PDF',
  Document = 'DOCUMENT',
  GatheringAttachment = 'GATHERING_ATTACHMENT',
  GatheringCover = 'GATHERING_COVER',
  GatheringExport = 'GATHERING_EXPORT',
  HappeningAttachment = 'HAPPENING_ATTACHMENT',
  HappeningCover = 'HAPPENING_COVER',
  InvoicePdf = 'INVOICE_PDF',
  PictureStock = 'PICTURE_STOCK',
  PointOfContactPicture = 'POINT_OF_CONTACT_PICTURE',
  ProductArticle = 'PRODUCT_ARTICLE',
  PublicationAttachment = 'PUBLICATION_ATTACHMENT',
  PublicationCover = 'PUBLICATION_COVER',
  SubventionInvoice = 'SUBVENTION_INVOICE',
  SubventionReimbursementProof = 'SUBVENTION_REIMBURSEMENT_PROOF',
  SubventionSchemaIcon = 'SUBVENTION_SCHEMA_ICON',
  UserAvatar = 'USER_AVATAR'
}

export type FilesFilterArgs = {
  type?: InputMaybe<FileArgsFilterStatus>;
};

export type FilesPagination = Pagination & {
  __typename?: 'FilesPagination';
  edges: Array<FileEdge>;
  nodes: Array<File>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type FilterBoolArgs = {
  is?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FilterDateArgs = {
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<Scalars['DateTime']['input']>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FilterDateOnlyArgs = {
  gt?: InputMaybe<Scalars['Date']['input']>;
  gte?: InputMaybe<Scalars['Date']['input']>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<Scalars['Date']['input']>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<Scalars['Date']['input']>;
  lt?: InputMaybe<Scalars['Date']['input']>;
  lte?: InputMaybe<Scalars['Date']['input']>;
};

export type FilterEventTypesArgs = {
  in?: InputMaybe<Array<EventTypes>>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<EventTypes>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<EventTypes>;
  notIn?: InputMaybe<Array<EventTypes>>;
};

export type FilterIdArgs = {
  in?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<Scalars['ID']['input']>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<Scalars['ID']['input']>;
  notIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type FilterIntArgs = {
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<Scalars['Int']['input']>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<Scalars['Int']['input']>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
};

export type FilterNationalityArgs = {
  in?: InputMaybe<Array<CountryCode>>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<CountryCode>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<CountryCode>;
  notIn?: InputMaybe<Array<CountryCode>>;
};

export enum FilterNulls {
  Excluded = 'EXCLUDED',
  Included = 'INCLUDED',
  Only = 'ONLY'
}

export type FilterRoleArgs = {
  in?: InputMaybe<Array<UserRole>>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<UserRole>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<UserRole>;
  notIn?: InputMaybe<Array<UserRole>>;
};

export type FilterStatusArgs = {
  in?: InputMaybe<Array<UserStatus>>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<UserStatus>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<UserStatus>;
  notIn?: InputMaybe<Array<UserStatus>>;
};

export type FilterStringArgs = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<Scalars['String']['input']>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type FlatDiscount = {
  __typename?: 'FlatDiscount';
  amount: Scalars['Int']['output'];
  currency: Scalars['Currency']['output'];
  percent?: Maybe<Scalars['Int']['output']>;
};

export type FooterCustomisationCallToAction = Node & {
  __typename?: 'FooterCustomisationCallToAction';
  createdAt: Scalars['DateTime']['output'];
  href: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type FooterCustomisationConfig = FeatureConfig & {
  __typename?: 'FooterCustomisationConfig';
  createdAt: Scalars['DateTime']['output'];
  /** This id matches the company ID */
  id: Scalars['ID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  layout?: Maybe<FooterCustomisationLayout>;
  updatedAt: Scalars['DateTime']['output'];
};

export type FooterCustomisationLayout = Node & {
  __typename?: 'FooterCustomisationLayout';
  callToActions: Array<FooterCustomisationCallToAction>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  socialNetworks: Array<FooterCustomisationSocialNetwork>;
  type: FooterCustomisationLayoutType;
  updatedAt: Scalars['DateTime']['output'];
  urls: Array<FooterCustomisationUrl>;
};

export enum FooterCustomisationLayoutType {
  Default = 'DEFAULT'
}

export type FooterCustomisationSocialNetwork = Node & {
  __typename?: 'FooterCustomisationSocialNetwork';
  createdAt: Scalars['DateTime']['output'];
  href: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  type: FooterSocialNetworkProvider;
  updatedAt: Scalars['DateTime']['output'];
};

export type FooterCustomisationUrl = Node & {
  __typename?: 'FooterCustomisationUrl';
  createdAt: Scalars['DateTime']['output'];
  href: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum FooterSocialNetworkProvider {
  Facebook = 'FACEBOOK',
  Instagram = 'INSTAGRAM',
  Linkedin = 'LINKEDIN',
  Twitter = 'TWITTER',
  Youtube = 'YOUTUBE'
}

export type FranceBilletCompanyConfig = FeatureConfig & {
  __typename?: 'FranceBilletCompanyConfig';
  createdAt: Scalars['DateTime']['output'];
  /** This id matches the company ID */
  id: Scalars['ID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  /** true if we share sso with france-billet */
  isSSO: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type Game = DeletableNode & Node & {
  __typename?: 'Game';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  endsAt?: Maybe<Scalars['DateTime']['output']>;
  excludedCompaniesPagination: CompaniesPagination;
  id: Scalars['ID']['output'];
  includedCompaniesPagination: CompaniesPagination;
  isExclusive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  startsAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
};


export type GameExcludedCompaniesPaginationArgs = {
  filter?: InputMaybe<CompaniesArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CompaniesArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type GameIncludedCompaniesPaginationArgs = {
  filter?: InputMaybe<CompaniesArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CompaniesArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type GameCompanyBlacklistInput = {
  companyIds: Array<Scalars['ID']['input']>;
};

export type GameCompanyBlacklistOutput = {
  __typename?: 'GameCompanyBlacklistOutput';
  game: Game;
};

export type GameCompanyWhitelistInput = {
  companyIds: Array<Scalars['ID']['input']>;
};

export type GameCompanyWhitelistOutput = {
  __typename?: 'GameCompanyWhitelistOutput';
  game: Game;
};

export type GameCreateInput = {
  endsAt?: InputMaybe<Scalars['DateTime']['input']>;
  excludedCompanyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  includedCompanyIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  isExclusive: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  startsAt?: InputMaybe<Scalars['DateTime']['input']>;
  url: Scalars['String']['input'];
};

export type GameCreateOutput = {
  __typename?: 'GameCreateOutput';
  game: Game;
};

export type GameDeleteOutput = {
  __typename?: 'GameDeleteOutput';
  game: Game;
};

export type GameEdge = Edge & {
  __typename?: 'GameEdge';
  cursor: Scalars['ID']['output'];
  node: Game;
};

export type GameUpdateInput = {
  endsAt?: InputMaybe<Scalars['DateTime']['input']>;
  isExclusive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  startsAt?: InputMaybe<Scalars['DateTime']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type GameUpdateOutput = {
  __typename?: 'GameUpdateOutput';
  game: Game;
};

export type GamesPagination = Pagination & {
  __typename?: 'GamesPagination';
  edges: Array<GameEdge>;
  nodes: Array<Game>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type GamesPaginationFilterArgs = {
  id?: InputMaybe<FilterIdArgs>;
};

export type GamesPaginationSorterArgs = {
  createdAt?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type Gathering = Highlightable & Node & {
  __typename?: 'Gathering';
  access: GatheringAccess;
  attachments: Array<File>;
  cover: Picture;
  coverURL: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  currentTicketCount: Scalars['Int']['output'];
  descriptionDelta: Scalars['Delta']['output'];
  descriptionHTML: Scalars['String']['output'];
  descriptionText: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  invitedUsers: GatheringInvitedUserPagination;
  isHighlighted: Scalars['Boolean']['output'];
  maxTicketCount?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  session: GatheringSession;
  sessions: Array<GatheringSession>;
  sessionsInfo: GatheringSessionsInfo;
  status: GatheringStatus;
  updatedAt: Scalars['DateTime']['output'];
  viewerIsNotTargetedRegistered: Scalars['Boolean']['output'];
  viewerTicketCount: Scalars['Int']['output'];
};


export type GatheringInvitedUsersArgs = {
  filter?: InputMaybe<GatheringInvitedUserPaginationFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<Array<GatheringInvitedUserPaginationSorter>>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type GatheringSessionArgs = {
  gatheringSessionId: Scalars['ID']['input'];
};

export type GatheringAccess = Node & {
  __typename?: 'GatheringAccess';
  allowedUserIds?: Maybe<Array<Scalars['ID']['output']>>;
  allowedUserPagination?: Maybe<GatheringAccessAllowedUserPagination>;
  allowedUsersQueryFilter?: Maybe<Scalars['JSONObject']['output']>;
  createdAt: Scalars['DateTime']['output'];
  frozen: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  mode: GatheringAccessMode;
  updatedAt: Scalars['DateTime']['output'];
};


export type GatheringAccessAllowedUserPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type GatheringAccessAllowedUserEdge = Edge & {
  __typename?: 'GatheringAccessAllowedUserEdge';
  cursor: Scalars['ID']['output'];
  node: User;
};

export type GatheringAccessAllowedUserPagination = Connection & {
  __typename?: 'GatheringAccessAllowedUserPagination';
  edges: Array<GatheringAccessAllowedUserEdge>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type GatheringAccessCreateInput = {
  allowedUsersList?: InputMaybe<Array<Scalars['ID']['input']>>;
  allowedUsersQueryFilter?: InputMaybe<Scalars['JSONObject']['input']>;
  frozen?: InputMaybe<Scalars['Boolean']['input']>;
  mode: GatheringAccessMode;
};

export type GatheringAccessCreateOutput = {
  __typename?: 'GatheringAccessCreateOutput';
  gatheringAccess: GatheringAccess;
};

export enum GatheringAccessMode {
  AllowAll = 'ALLOW_ALL',
  ManualSelection = 'MANUAL_SELECTION',
  Targeted = 'TARGETED'
}

export type GatheringAccessUpdateInput = {
  allowedUsersList?: InputMaybe<Array<Scalars['ID']['input']>>;
  allowedUsersQueryFilter?: InputMaybe<Scalars['JSONObject']['input']>;
  frozen?: InputMaybe<Scalars['Boolean']['input']>;
  mode: GatheringAccessMode;
};

export type GatheringAccessUpdateOutput = {
  __typename?: 'GatheringAccessUpdateOutput';
  /** @deprecated Empty Array because Registrations are no longer cancelled */
  cancelledRegistrations?: Maybe<Array<GatheringSessionRegistration>>;
  gatheringAccess: GatheringAccess;
  removedUsersCount: Scalars['Int']['output'];
};

export type GatheringCancelOutput = {
  __typename?: 'GatheringCancelOutput';
  gathering: Gathering;
};

export type GatheringCompanyConfig = FeatureConfig & {
  __typename?: 'GatheringCompanyConfig';
  createdAt: Scalars['DateTime']['output'];
  /** This id matches the company ID */
  id: Scalars['ID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  isPaidGatheringEnabled: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GatheringCreateInput = {
  attachmentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  coverId: Scalars['ID']['input'];
  descriptionDelta: Scalars['Delta']['input'];
  maxTicketCount?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
};

export type GatheringCreateOutput = {
  __typename?: 'GatheringCreateOutput';
  gathering: Gathering;
};

export type GatheringDeleteOutput = {
  __typename?: 'GatheringDeleteOutput';
  gathering: Gathering;
};

export type GatheringEdge = Edge & {
  __typename?: 'GatheringEdge';
  cursor: Scalars['ID']['output'];
  node: Gathering;
};

export type GatheringFeatureDisableOutput = {
  __typename?: 'GatheringFeatureDisableOutput';
  gatheringCompanyConfig: GatheringCompanyConfig;
};

export type GatheringFeatureEnableOutput = {
  __typename?: 'GatheringFeatureEnableOutput';
  gatheringCompanyConfig: GatheringCompanyConfig;
};

export type GatheringInvitedUserEdge = Edge & {
  __typename?: 'GatheringInvitedUserEdge';
  cursor: Scalars['ID']['output'];
  hasPendingPayment: Scalars['Boolean']['output'];
  node: User;
  registrationStatus: GatheringInvitedUserRegistrationStatus;
  sessions: Array<GatheringSession>;
  ticketCount: Scalars['Int']['output'];
};

export type GatheringInvitedUserPagination = Connection & {
  __typename?: 'GatheringInvitedUserPagination';
  edges: Array<GatheringInvitedUserEdge>;
  hasNotTargetedRegisteredUser: Scalars['Boolean']['output'];
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type GatheringInvitedUserPaginationFilter = {
  registration?: InputMaybe<GatheringInvitedUserPaginationRegistrationFilter>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type GatheringInvitedUserPaginationRegistrationFilter = {
  registered?: InputMaybe<Scalars['Boolean']['input']>;
  registeredSessionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  registrationStatus: GatheringInvitedUserRegistrationStatus;
};

export type GatheringInvitedUserPaginationSorter = {
  direction: SortDirection;
  field: GatheringInvitedUserPaginationSorterField;
};

export enum GatheringInvitedUserPaginationSorterField {
  Email = 'email',
  FirstName = 'firstName',
  LastName = 'lastName'
}

export enum GatheringInvitedUserRegistrationStatus {
  Invited = 'INVITED',
  Registered = 'REGISTERED',
  RegisteredNotTargeted = 'REGISTERED_NOT_TARGETED'
}

export type GatheringPagination = Connection & {
  __typename?: 'GatheringPagination';
  edges: Array<GatheringEdge>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type GatheringPaginationFilter = {
  isRegistrationOpened?: InputMaybe<Scalars['Boolean']['input']>;
  statuses?: InputMaybe<Array<GatheringStatus>>;
};

export type GatheringPaginationSorter = {
  direction: SortDirection;
  field: GatheringPaginationSorterField;
};

export enum GatheringPaginationSorterField {
  CreatedAt = 'createdAt',
  MaxSessionEnd = 'maxSessionEnd',
  MinSessionStart = 'minSessionStart'
}

export type GatheringPublishOutput = {
  __typename?: 'GatheringPublishOutput';
  gathering: Gathering;
};

export type GatheringRegistrationCancelOutput = {
  __typename?: 'GatheringRegistrationCancelOutput';
  cancelledRegistrations: Array<GatheringSessionRegistration>;
};

export type GatheringSession = Node & {
  __typename?: 'GatheringSession';
  address?: Maybe<Address>;
  cancelledAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  currentTicketCount: Scalars['Int']['output'];
  customLocation?: Maybe<Scalars['String']['output']>;
  gathering: Gathering;
  id: Scalars['ID']['output'];
  isPaid: Scalars['Boolean']['output'];
  maxTicketCount?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  prices: Array<GatheringSessionPrice>;
  registrationEnd: Scalars['DateTime']['output'];
  registrationStart: Scalars['DateTime']['output'];
  sessionEnd: Scalars['DateTime']['output'];
  sessionStart: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
  viewerQuotas: GatheringSessionQuotas;
  viewerRegistrations: Array<GatheringSessionRegistration>;
  viewerTicketCount: Scalars['Int']['output'];
};

export type GatheringSessionCancelOutput = {
  __typename?: 'GatheringSessionCancelOutput';
  gatheringSession: GatheringSession;
};

export type GatheringSessionCreateInput = {
  address?: InputMaybe<AddressCreateInput>;
  customLocation?: InputMaybe<Scalars['String']['input']>;
  maxTicketCount?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  prices?: InputMaybe<Array<GatheringSessionPriceCreateInput>>;
  registrationEnd: Scalars['DateTime']['input'];
  registrationStart: Scalars['DateTime']['input'];
  sessionEnd: Scalars['DateTime']['input'];
  sessionStart: Scalars['DateTime']['input'];
};

export type GatheringSessionPrice = DeletableNode & Node & {
  __typename?: 'GatheringSessionPrice';
  childrenProperties?: Maybe<GatheringSessionPriceChildrenProperties>;
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['Currency']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  targets: Array<GatheringSessionPriceTarget>;
  updatedAt: Scalars['DateTime']['output'];
  userMaxTicketCount: Scalars['Int']['output'];
  userProperties?: Maybe<GatheringSessionPriceUserProperties>;
};

export type GatheringSessionPriceChildrenProperties = {
  __typename?: 'GatheringSessionPriceChildrenProperties';
  maxAge?: Maybe<Scalars['Int']['output']>;
  minAge?: Maybe<Scalars['Int']['output']>;
};

export type GatheringSessionPriceCreateChildrenPropertiesInput = {
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
};

export type GatheringSessionPriceCreateInput = {
  childrenProperties?: InputMaybe<GatheringSessionPriceCreateChildrenPropertiesInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  targets: Array<GatheringSessionPriceTarget>;
  userMaxTicketCount?: InputMaybe<Scalars['Int']['input']>;
  userProperties?: InputMaybe<GatheringSessionPriceCreateUserPropertiesInput>;
};

export type GatheringSessionPriceCreateUserPropertiesInput = {
  contract?: InputMaybe<Array<Scalars['String']['input']>>;
  maxSeniorityInMonths?: InputMaybe<Scalars['Int']['input']>;
  minSeniorityInMonths?: InputMaybe<Scalars['Int']['input']>;
};

export type GatheringSessionPriceQuotas = {
  __typename?: 'GatheringSessionPriceQuotas';
  childrenQuota: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  partnerQuota: Scalars['Int']['output'];
  userQuota: Scalars['Int']['output'];
};

export enum GatheringSessionPriceTarget {
  Children = 'CHILDREN',
  Partner = 'PARTNER',
  User = 'USER'
}

export type GatheringSessionPriceUpdateInput = {
  childrenProperties?: InputMaybe<GatheringSessionPriceCreateChildrenPropertiesInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  targets: Array<GatheringSessionPriceTarget>;
  userMaxTicketCount?: InputMaybe<Scalars['Int']['input']>;
  userProperties?: InputMaybe<GatheringSessionPriceCreateUserPropertiesInput>;
};

export type GatheringSessionPriceUserProperties = {
  __typename?: 'GatheringSessionPriceUserProperties';
  contract?: Maybe<Array<Scalars['String']['output']>>;
  maxSeniorityInMonths?: Maybe<Scalars['Int']['output']>;
  minSeniorityInMonths?: Maybe<Scalars['Int']['output']>;
};

export type GatheringSessionPricesCreateOutput = {
  __typename?: 'GatheringSessionPricesCreateOutput';
  gatheringSessionPrices: Array<GatheringSessionPrice>;
};

export type GatheringSessionQuotas = {
  __typename?: 'GatheringSessionQuotas';
  childrenQuota: Scalars['Int']['output'];
  partnerQuota: Scalars['Int']['output'];
  priceQuotas: Array<GatheringSessionPriceQuotas>;
  userQuota: Scalars['Int']['output'];
};

export type GatheringSessionRegistration = Node & {
  __typename?: 'GatheringSessionRegistration';
  cancelledAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  status: GatheringSessionRegistrationStatus;
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['ID']['output'];
};

export type GatheringSessionRegistrationCancelOutput = {
  __typename?: 'GatheringSessionRegistrationCancelOutput';
  cancelledRegistrations: Array<GatheringSessionRegistration>;
};

export type GatheringSessionRegistrationCheckoutOutput = {
  __typename?: 'GatheringSessionRegistrationCheckoutOutput';
  checkoutSessionUrl: Scalars['String']['output'];
};

export type GatheringSessionRegistrationCreateInput = {
  tickets: Array<GatheringSessionTicketCreateInput>;
  userId: Scalars['ID']['input'];
};

export type GatheringSessionRegistrationCreateOutput = {
  __typename?: 'GatheringSessionRegistrationCreateOutput';
  sessionRegistration: GatheringSessionRegistration;
};

export enum GatheringSessionRegistrationStatus {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING'
}

export enum GatheringSessionStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  Upcoming = 'UPCOMING'
}

export type GatheringSessionTicket = Node & {
  __typename?: 'GatheringSessionTicket';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  priceId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GatheringSessionTicketCreateInput = {
  priceId: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
};

export type GatheringSessionTicketDeleteInput = {
  priceId: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
};

export type GatheringSessionTicketDeleteOutput = {
  __typename?: 'GatheringSessionTicketDeleteOutput';
  gatheringSession: GatheringSession;
};

export type GatheringSessionUpdateInput = {
  address?: InputMaybe<AddressCreateInput>;
  customLocation?: InputMaybe<Scalars['String']['input']>;
  maxTicketCount?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  prices?: InputMaybe<Array<GatheringSessionPriceUpdateInput>>;
  registrationEnd?: InputMaybe<Scalars['DateTime']['input']>;
  registrationStart?: InputMaybe<Scalars['DateTime']['input']>;
  sessionEnd?: InputMaybe<Scalars['DateTime']['input']>;
  sessionStart?: InputMaybe<Scalars['DateTime']['input']>;
};

export type GatheringSessionUpdateOutput = {
  __typename?: 'GatheringSessionUpdateOutput';
  cancelledRegistrations?: Maybe<Array<GatheringSessionRegistration>>;
  cancelledTicketsCount: Scalars['Int']['output'];
  cancelledUsersCount: Scalars['Int']['output'];
  gatheringSession: GatheringSession;
};

export type GatheringSessionUserEdge = Edge & {
  __typename?: 'GatheringSessionUserEdge';
  cursor: Scalars['ID']['output'];
  node: GatheringSession;
};

export type GatheringSessionUserPagination = Connection & {
  __typename?: 'GatheringSessionUserPagination';
  edges: Array<GatheringSessionUserEdge>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type GatheringSessionUserPaginationFilter = {
  statuses?: InputMaybe<Array<GatheringSessionStatus>>;
};

export type GatheringSessionsCreateOutput = {
  __typename?: 'GatheringSessionsCreateOutput';
  gatheringSessions: Array<GatheringSession>;
};

export type GatheringSessionsInfo = {
  __typename?: 'GatheringSessionsInfo';
  maxRegistrationEnd?: Maybe<Scalars['DateTime']['output']>;
  maxSessionEnd?: Maybe<Scalars['DateTime']['output']>;
  minRegistrationStart?: Maybe<Scalars['DateTime']['output']>;
  minSessionStart?: Maybe<Scalars['DateTime']['output']>;
  sessionCount: Scalars['Int']['output'];
  sessionsTotalMaxTicketCount?: Maybe<Scalars['Int']['output']>;
};

export enum GatheringStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Draft = 'DRAFT',
  InProgress = 'IN_PROGRESS',
  Upcoming = 'UPCOMING'
}

export type GatheringUpdateInput = {
  attachmentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  coverId?: InputMaybe<Scalars['ID']['input']>;
  descriptionDelta?: InputMaybe<Scalars['Delta']['input']>;
  maxTicketCount?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type GatheringUpdateOutput = {
  __typename?: 'GatheringUpdateOutput';
  gathering: Gathering;
};

export type GatheringUserEdge = Edge & {
  __typename?: 'GatheringUserEdge';
  cursor: Scalars['ID']['output'];
  node: Gathering;
  /** Session of the gathering the user participates to */
  sessions: Array<GatheringSession>;
  /** Tickets of the gathering the user has bought */
  tickets: Array<GatheringSessionTicket>;
};

export type GatheringUserPagination = Connection & {
  __typename?: 'GatheringUserPagination';
  edges: Array<GatheringUserEdge>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type GatheringUserPaginationFilter = {
  /** If true only returns gathering with registrations from the user. If false only returns gathering without registrations from the user */
  hasRegistrations?: InputMaybe<Scalars['Boolean']['input']>;
  isRegistrationOpened?: InputMaybe<Scalars['Boolean']['input']>;
  statuses?: InputMaybe<Array<GatheringStatus>>;
};

export type GranteeCountPreview = {
  __typename?: 'GranteeCountPreview';
  childrenCount: Scalars['Int']['output'];
  partnerCount: Scalars['Int']['output'];
  userCount: Scalars['Int']['output'];
};

/** Represents the Grantee's target */
export type GranteeTarget = Beneficiary | User;

export type Happening = Highlightable & Node & {
  __typename?: 'Happening';
  address?: Maybe<Address>;
  amountPaid?: Maybe<Scalars['Int']['output']>;
  attachmentsPagination: FilesPagination;
  cancelledAt?: Maybe<Scalars['DateTime']['output']>;
  cancelledBy?: Maybe<User>;
  cohort?: Maybe<CohortUser>;
  company?: Maybe<Company>;
  cover: Picture;
  coverURL: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  currency?: Maybe<Scalars['Currency']['output']>;
  currentSeatsCount: Scalars['Int']['output'];
  dateTransferFunds?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  descriptionDelta: Scalars['Delta']['output'];
  descriptionHTML: Scalars['String']['output'];
  fundsStatus?: Maybe<HappeningFundsStatus>;
  happeningEndsAt: Scalars['DateTime']['output'];
  happeningStartsAt: Scalars['DateTime']['output'];
  happeningStatus: HappeningStatus;
  id: Scalars['ID']['output'];
  isHighlighted: Scalars['Boolean']['output'];
  /** @deprecated replaced by maxSeatsCount */
  maxRegistrationCount?: Maybe<Scalars['Int']['output']>;
  maxSeatsCount?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  participantPagination: HappeningParticipantPagination;
  price?: Maybe<Scalars['Int']['output']>;
  registrationEndsAt: Scalars['DateTime']['output'];
  registrationPeriodStatus: HappeningRegistrationPeriodStatus;
  registrationStartsAt: Scalars['DateTime']['output'];
  /** @deprecated replaced by currentSeatsCount */
  registrationsCount: Scalars['Int']['output'];
  registrationsPagination: HappeningRegistrationPagination;
  /** @deprecated Will be removed. Use happeningStatus field instead */
  status: HappeningStatus;
  updatedAt: Scalars['DateTime']['output'];
  viewerRegistrationsPagination: HappeningRegistrationPagination;
  viewerSeatsCount: Scalars['Int']['output'];
};


export type HappeningAttachmentsPaginationArgs = {
  filter?: InputMaybe<FilesFilterArgs>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type HappeningParticipantPaginationArgs = {
  filter?: InputMaybe<UsersArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<UsersArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type HappeningRegistrationsPaginationArgs = {
  filter?: InputMaybe<HappeningRegistrationPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<HappeningRegistrationPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type HappeningViewerRegistrationsPaginationArgs = {
  filter?: InputMaybe<HappeningRegistrationPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<HappeningRegistrationPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type HappeningAddAttachmentsInput = {
  attachmentIds: Array<Scalars['ID']['input']>;
};

export type HappeningAddAttachmentsOutput = {
  __typename?: 'HappeningAddAttachmentsOutput';
  happening: Happening;
};

/** Nestable filters resolving in custom-operations */
export type HappeningArgsFilter = {
  AND?: InputMaybe<Array<HappeningArgsFilter>>;
  OR?: InputMaybe<Array<HappeningArgsFilter>>;
  createdAt?: InputMaybe<FilterDateArgs>;
  fundsStatus?: InputMaybe<HappeningArgsFilterFundsStatus>;
  happeningEndsAt?: InputMaybe<FilterDateArgs>;
  happeningStartsAt?: InputMaybe<FilterDateArgs>;
  happeningStatus?: InputMaybe<HappeningArgsFilterHappeningStatus>;
  id?: InputMaybe<FilterIdArgs>;
  name?: InputMaybe<FilterStringArgs>;
  registrationEndsAt?: InputMaybe<FilterDateArgs>;
  registrationPeriodStatus?: InputMaybe<HappeningArgsFilterRegistrationPeriodStatus>;
  registrationStartsAt?: InputMaybe<FilterDateArgs>;
  registrationStatus?: InputMaybe<HappeningArgsFilterHappeningRegistrationStatus>;
  status?: InputMaybe<HappeningArgsFilterStatus>;
  updatedAt?: InputMaybe<FilterDateArgs>;
};

export type HappeningArgsFilterFundsStatus = {
  in?: InputMaybe<Array<HappeningFundsStatus>>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<HappeningFundsStatus>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<HappeningFundsStatus>;
  notIn?: InputMaybe<Array<HappeningFundsStatus>>;
};

export type HappeningArgsFilterHappeningRegistrationStatus = {
  in?: InputMaybe<Array<HappeningRegistrationStatus>>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<HappeningRegistrationStatus>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<HappeningRegistrationStatus>;
  notIn?: InputMaybe<Array<HappeningRegistrationStatus>>;
};

export type HappeningArgsFilterHappeningStatus = {
  in?: InputMaybe<Array<HappeningStatus>>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<HappeningStatus>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<HappeningStatus>;
  notIn?: InputMaybe<Array<HappeningStatus>>;
};

export type HappeningArgsFilterRegistrationPeriodStatus = {
  in?: InputMaybe<Array<HappeningRegistrationPeriodStatus>>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<HappeningRegistrationPeriodStatus>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<HappeningRegistrationPeriodStatus>;
  notIn?: InputMaybe<Array<HappeningRegistrationPeriodStatus>>;
};

export type HappeningArgsFilterStatus = {
  in?: InputMaybe<Array<HappeningStatus>>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<HappeningStatus>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<HappeningStatus>;
  notIn?: InputMaybe<Array<HappeningStatus>>;
};

export type HappeningCancelOutput = {
  __typename?: 'HappeningCancelOutput';
  happening: Happening;
};

export type HappeningConfirmFundsTransferedOutput = {
  __typename?: 'HappeningConfirmFundsTransferedOutput';
  happening: Happening;
};

export type HappeningCreateInput = {
  address: AddressCreateInput;
  attachmentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  cohortId?: InputMaybe<Scalars['ID']['input']>;
  coverId: Scalars['ID']['input'];
  currency?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['Delta']['input'];
  happeningEndsAt: Scalars['DateTime']['input'];
  happeningStartsAt: Scalars['DateTime']['input'];
  maxRegistrationCount?: InputMaybe<Scalars['Int']['input']>;
  maxSeatsCount?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  price?: InputMaybe<Scalars['Int']['input']>;
  registrationEndsAt: Scalars['DateTime']['input'];
  registrationStartsAt: Scalars['DateTime']['input'];
};

export type HappeningCreateOutput = {
  __typename?: 'HappeningCreateOutput';
  happening: Happening;
};

export type HappeningEdge = Edge & {
  __typename?: 'HappeningEdge';
  cursor: Scalars['ID']['output'];
  node: Happening;
};

export enum HappeningFundsStatus {
  PendingTransfer = 'PENDING_TRANSFER',
  TransferDone = 'TRANSFER_DONE'
}

export type HappeningPagination = Pagination & {
  __typename?: 'HappeningPagination';
  edges: Array<HappeningEdge>;
  nodes: Array<Happening>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Custom filter resolving in the service */
export type HappeningPaginationArgsFilter = {
  AND?: InputMaybe<Array<HappeningArgsFilter>>;
  OR?: InputMaybe<Array<HappeningArgsFilter>>;
  createdAt?: InputMaybe<FilterDateArgs>;
  fundsStatus?: InputMaybe<HappeningArgsFilterFundsStatus>;
  happeningEndsAt?: InputMaybe<FilterDateArgs>;
  happeningStartsAt?: InputMaybe<FilterDateArgs>;
  happeningStatus?: InputMaybe<HappeningArgsFilterHappeningStatus>;
  id?: InputMaybe<FilterIdArgs>;
  name?: InputMaybe<FilterStringArgs>;
  registrationEndsAt?: InputMaybe<FilterDateArgs>;
  registrationPeriodStatus?: InputMaybe<HappeningArgsFilterRegistrationPeriodStatus>;
  registrationStartsAt?: InputMaybe<FilterDateArgs>;
  registrationStatus?: InputMaybe<HappeningArgsFilterHappeningRegistrationStatus>;
  status?: InputMaybe<HappeningArgsFilterStatus>;
  updatedAt?: InputMaybe<FilterDateArgs>;
};

export type HappeningPaginationArgsSorter = {
  createdAt?: InputMaybe<SortDirection>;
  happeningEndsAt?: InputMaybe<SortDirection>;
  happeningStartsAt?: InputMaybe<SortDirection>;
  maxRegistrationCount?: InputMaybe<SortDirection>;
  maxSeatsCount?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  registrationEndsAt?: InputMaybe<SortDirection>;
  registrationStartsAt?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type HappeningParticipantEdge = Edge & {
  __typename?: 'HappeningParticipantEdge';
  cursor: Scalars['ID']['output'];
  node: User;
  registrationPagination: HappeningRegistrationPagination;
};


export type HappeningParticipantEdgeRegistrationPaginationArgs = {
  filter?: InputMaybe<HappeningRegistrationPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<HappeningRegistrationPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type HappeningParticipantPagination = Pagination & {
  __typename?: 'HappeningParticipantPagination';
  edges: Array<HappeningParticipantEdge>;
  nodes: Array<User>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type HappeningRegistration = Node & {
  __typename?: 'HappeningRegistration';
  amount?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  currency?: Maybe<Scalars['Currency']['output']>;
  happening: Happening;
  id: Scalars['ID']['output'];
  invoice?: Maybe<Invoice>;
  quantity: Scalars['Int']['output'];
  status: HappeningRegistrationStatus;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

/** Nestable filters for any happening pagination query */
export type HappeningRegistrationArgsFilter = {
  AND?: InputMaybe<Array<HappeningRegistrationArgsFilter>>;
  OR?: InputMaybe<Array<HappeningRegistrationArgsFilter>>;
  createdAt?: InputMaybe<FilterDateArgs>;
  happening?: InputMaybe<HappeningArgsFilter>;
  id?: InputMaybe<FilterIdArgs>;
  updatedAt?: InputMaybe<FilterDateArgs>;
  user?: InputMaybe<UsersArgsFilter>;
};

export type HappeningRegistrationCancelOutput = {
  __typename?: 'HappeningRegistrationCancelOutput';
  happeningRegistration: HappeningRegistration;
};

export type HappeningRegistrationCreateInput = {
  quantity?: InputMaybe<Scalars['Int']['input']>;
};

export type HappeningRegistrationCreateOutput = {
  __typename?: 'HappeningRegistrationCreateOutput';
  clientSecret?: Maybe<Scalars['String']['output']>;
  happening: Happening;
  happeningRegistration: HappeningRegistration;
  paymentIntent?: Maybe<StripePaymentIntent>;
};

export type HappeningRegistrationEdge = Edge & {
  __typename?: 'HappeningRegistrationEdge';
  cursor: Scalars['ID']['output'];
  node: HappeningRegistration;
};

export type HappeningRegistrationPagination = Pagination & {
  __typename?: 'HappeningRegistrationPagination';
  edges: Array<HappeningRegistrationEdge>;
  nodes: Array<HappeningRegistration>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Filters for the root happening pagination query */
export type HappeningRegistrationPaginationArgsFilter = {
  AND?: InputMaybe<Array<HappeningRegistrationArgsFilter>>;
  OR?: InputMaybe<Array<HappeningRegistrationArgsFilter>>;
  createdAt?: InputMaybe<FilterDateArgs>;
  happening?: InputMaybe<HappeningArgsFilter>;
  id?: InputMaybe<FilterIdArgs>;
  updatedAt?: InputMaybe<FilterDateArgs>;
  user?: InputMaybe<UsersArgsFilter>;
};

export type HappeningRegistrationPaginationArgsSorter = {
  createdAt?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export enum HappeningRegistrationPeriodStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Finished = 'FINISHED',
  Scheduled = 'SCHEDULED'
}

export type HappeningRegistrationSetStatusToAwaitingStripeOutput = {
  __typename?: 'HappeningRegistrationSetStatusToAwaitingStripeOutput';
  happeningRegistration: HappeningRegistration;
};

export enum HappeningRegistrationStatus {
  AwaitingPayment = 'AWAITING_PAYMENT',
  AwaitingStripe = 'AWAITING_STRIPE',
  Cancelled = 'CANCELLED',
  Confirmed = 'CONFIRMED',
  Refunded = 'REFUNDED'
}

export type HappeningRemoveAttachmentsInput = {
  attachmentIds: Array<Scalars['ID']['input']>;
};

export type HappeningRemoveAttachmentsOutput = {
  __typename?: 'HappeningRemoveAttachmentsOutput';
  happening: Happening;
};

export enum HappeningStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Finished = 'FINISHED',
  Scheduled = 'SCHEDULED'
}

export type HappeningUpdateInput = {
  address?: InputMaybe<AddressCreateInput>;
  coverId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['Delta']['input']>;
  happeningEndsAt?: InputMaybe<Scalars['DateTime']['input']>;
  happeningStartsAt?: InputMaybe<Scalars['DateTime']['input']>;
  maxSeatsCount?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  registrationEndsAt?: InputMaybe<Scalars['DateTime']['input']>;
  registrationStartsAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type HappeningUpdateOutput = {
  __typename?: 'HappeningUpdateOutput';
  happening: Happening;
};

export type HappyMediaConfig = FeatureConfig & {
  __typename?: 'HappyMediaConfig';
  createdAt: Scalars['DateTime']['output'];
  /** This id matches the company ID */
  id: Scalars['ID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type Highlight = DeletableNode & Node & {
  __typename?: 'Highlight';
  auditTrailPagination: AuditTrailPagination;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  target: Highlightable;
  updatedAt: Scalars['DateTime']['output'];
};


export type HighlightAuditTrailPaginationArgs = {
  filter?: InputMaybe<AuditTrailArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<AuditTrailArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type HighlightArgsFilter = {
  company?: InputMaybe<CompaniesArgsFilter>;
  targetType?: InputMaybe<HighlightArgsFilterTargetType>;
};

export type HighlightArgsFilterTargetType = {
  in?: InputMaybe<Array<HighlightTargetType>>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<HighlightTargetType>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<HighlightTargetType>;
  notIn?: InputMaybe<Array<HighlightTargetType>>;
};

export type HighlightCreateInput = {
  safe?: InputMaybe<Scalars['Boolean']['input']>;
  targetId: Scalars['ID']['input'];
  targetType: HighlightTargetType;
};

export type HighlightCreateOutput = {
  __typename?: 'HighlightCreateOutput';
  highlight: Highlight;
};

export type HighlightCreatedData = EventData & {
  __typename?: 'HighlightCreatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
};

export type HighlightDeleteOutput = {
  __typename?: 'HighlightDeleteOutput';
  highlight: Highlight;
};

export type HighlightDeletedData = EventData & {
  __typename?: 'HighlightDeletedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
};

export type HighlightEdge = Edge & {
  __typename?: 'HighlightEdge';
  cursor: Scalars['ID']['output'];
  node: Highlight;
};

export type HighlightPagination = Pagination & {
  __typename?: 'HighlightPagination';
  edges: Array<HighlightEdge>;
  nodes: Array<Highlight>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type HighlightPositionUpdateInput = {
  position: Scalars['Int']['input'];
};

export type HighlightPositionUpdateOutput = {
  __typename?: 'HighlightPositionUpdateOutput';
  highlight: Highlight;
};

export enum HighlightTargetType {
  Article = 'ARTICLE',
  Category = 'CATEGORY',
  CompanyDocument = 'COMPANY_DOCUMENT',
  CompanyFolder = 'COMPANY_FOLDER',
  Gathering = 'GATHERING',
  Happening = 'HAPPENING',
  Publication = 'PUBLICATION'
}

export type Highlightable = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isHighlighted: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type HomeCustomisationBlock = Node & {
  __typename?: 'HomeCustomisationBlock';
  createdAt: Scalars['DateTime']['output'];
  /** Whether to hide the module on desktop */
  hideOnDesktop: Scalars['Boolean']['output'];
  /** Whether to hide the module on mobile */
  hideOnMobile: Scalars['Boolean']['output'];
  /** Whether to hide the module on tablet */
  hideOnTablet: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** The label to display if provided */
  label?: Maybe<Scalars['String']['output']>;
  type: HomeCustomisationBlockType;
  updatedAt: Scalars['DateTime']['output'];
};

export enum HomeCustomisationBlockType {
  ArticleCategories = 'ARTICLE_CATEGORIES',
  ArticleList = 'ARTICLE_LIST',
  CatalogCategories = 'CATALOG_CATEGORIES',
  ContactList = 'CONTACT_LIST',
  DocumentList = 'DOCUMENT_LIST',
  QuestionBox = 'QUESTION_BOX',
  Spotlight = 'SPOTLIGHT'
}

export type HomeCustomisationConfig = FeatureConfig & {
  __typename?: 'HomeCustomisationConfig';
  blocks: Array<HomeCustomisationBlock>;
  createdAt: Scalars['DateTime']['output'];
  /** This id matches the company ID */
  id: Scalars['ID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
  welcomeMessageAppend?: Maybe<Scalars['String']['output']>;
  welcomeMessagePrepend?: Maybe<Scalars['String']['output']>;
};

export type HomeCustomisationUpdateMessageInput = {
  welcomeMessageAppend?: InputMaybe<Scalars['String']['input']>;
  welcomeMessagePrepend?: InputMaybe<Scalars['String']['input']>;
};

export type HomeCustomisationUpdateMessageOutput = {
  __typename?: 'HomeCustomisationUpdateMessageOutput';
  homeCustomisation: HomeCustomisationConfig;
};

export type IArticleCategoryTranslation = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  locale: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type IArticleTranslation = {
  contentDelta: Scalars['Delta']['output'];
  contentHTML: Scalars['String']['output'];
  contentText: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  locale: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type IBrandTranslation = {
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  descriptionDelta?: Maybe<Scalars['Delta']['output']>;
  descriptionHTML?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  locale: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ICampaignSelectionTranslation = {
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  descriptionDelta: Scalars['Delta']['output'];
  descriptionHTML: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  locale: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ICampaignTranslation = {
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  descriptionDelta?: Maybe<Scalars['Delta']['output']>;
  descriptionHTML?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  instructions?: Maybe<Scalars['String']['output']>;
  instructionsDelta?: Maybe<Scalars['Delta']['output']>;
  instructionsHTML?: Maybe<Scalars['String']['output']>;
  locale: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ICategoryTranslation = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  locale: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type IfgRedemption = {
  __typename?: 'IFGRedemption';
  codes: Array<IfgRewardCode>;
  expirationDatetime?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  orderId: Scalars['String']['output'];
  reward: IfgReward;
  state: Scalars['String']['output'];
  url: Scalars['String']['output'];
  value: IfgRedemptionValue;
};

export type IfgRedemptionValue = {
  __typename?: 'IFGRedemptionValue';
  amount: Scalars['String']['output'];
  currency: Scalars['Currency']['output'];
};

export type IfgReward = {
  __typename?: 'IFGReward';
  name: Scalars['String']['output'];
  sku: Scalars['String']['output'];
  state: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type IfgRewardCode = {
  __typename?: 'IFGRewardCode';
  format: IfgRewardCodeFormat;
  name: Scalars['String']['output'];
  usage: IfgRewardCodeUsage;
  value: Scalars['String']['output'];
};

export enum IfgRewardCodeFormat {
  String = 'string',
  Url = 'url'
}

export enum IfgRewardCodeUsage {
  Barcode = 'barcode',
  Primary = 'primary',
  PrintableDocumentUrl = 'printable_document_url',
  Security = 'security',
  SecurityUrl = 'security_url',
  SecurityUrlCode = 'security_url_code',
  Url = 'url'
}

export type IOfferTranslation = {
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  descriptionDelta?: Maybe<Scalars['Delta']['output']>;
  descriptionHTML?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  locale: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type IProductTranslation = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  locale: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ISubventionSchemaTranslation = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  legalNotice?: Maybe<Scalars['String']['output']>;
  locale: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type IdentityCustomisationConfig = FeatureConfig & {
  __typename?: 'IdentityCustomisationConfig';
  colorPrimary?: Maybe<Scalars['String']['output']>;
  colorSecondary?: Maybe<Scalars['String']['output']>;
  colorTertiary?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** This id matches the company ID */
  id: Scalars['ID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type InAppNotification = Node & {
  __typename?: 'InAppNotification';
  author?: Maybe<InAppNotificationAuthor>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  readAt?: Maybe<Scalars['DateTime']['output']>;
  target?: Maybe<InAppNotificationTarget>;
  type: NotificationType;
  updatedAt: Scalars['DateTime']['output'];
};

export type InAppNotificationAuthor = Company | User;

export type InAppNotificationEdge = Edge & {
  __typename?: 'InAppNotificationEdge';
  cursor: Scalars['ID']['output'];
  node: InAppNotification;
};

export type InAppNotificationTarget = Article | Beneficiary | CompanyDocument | OfferCouponClaimable | OfferCouponPublic | OfferGiftCard | OfferTicket | OfferUrl | Publication | SubventionDiscount | SubventionFund | SubventionInvoice | User;

export type InAppNotificationsPagination = Pagination & {
  __typename?: 'InAppNotificationsPagination';
  edges: Array<InAppNotificationEdge>;
  nodes: Array<InAppNotification>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
  unreadCount: Scalars['Int']['output'];
};

export type Invitation = Node & {
  __typename?: 'Invitation';
  author?: Maybe<User>;
  claimedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  invitationLink: Scalars['String']['output'];
  receiver: User;
  revokedAt?: Maybe<Scalars['DateTime']['output']>;
  sentAt?: Maybe<Scalars['DateTime']['output']>;
  status: InvitationStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export type InvitationCreateBulkInput = {
  userIds: Array<Scalars['ID']['input']>;
};

export type InvitationCreateBulkOutput = {
  __typename?: 'InvitationCreateBulkOutput';
  invitations: Array<Invitation>;
};

export type InvitationCreateCompanyOutput = {
  __typename?: 'InvitationCreateCompanyOutput';
  count: Scalars['Int']['output'];
};

export type InvitationCreateInput = {
  userId: Scalars['ID']['input'];
};

export type InvitationCreateOutput = {
  __typename?: 'InvitationCreateOutput';
  invitation: Invitation;
};

export type InvitationEdge = Edge & {
  __typename?: 'InvitationEdge';
  cursor: Scalars['ID']['output'];
  node: Invitation;
};

export type InvitationEmailSendBulkInput = {
  userEmails: Array<Scalars['String']['input']>;
};

export type InvitationEmailSendBulkOutput = {
  __typename?: 'InvitationEmailSendBulkOutput';
  invitations: Array<Invitation>;
};

export type InvitationLinkData = {
  __typename?: 'InvitationLinkData';
  email: Scalars['String']['output'];
  invitationLink: Scalars['String']['output'];
};

export type InvitationLinkGenerateBulkInput = {
  userEmails: Array<Scalars['String']['input']>;
};

export type InvitationLinkGenerateBulkOutput = {
  __typename?: 'InvitationLinkGenerateBulkOutput';
  invitationLinksData: Array<InvitationLinkData>;
};

export type InvitationRevokeOutput = {
  __typename?: 'InvitationRevokeOutput';
  invitation: Invitation;
};

export type InvitationSendCompanyInput = {
  /** Set to true to only send new invitations that have never been sent */
  onlyNew?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InvitationSendCompanyOutput = {
  __typename?: 'InvitationSendCompanyOutput';
  count: Scalars['Int']['output'];
};

export type InvitationSendOutput = {
  __typename?: 'InvitationSendOutput';
  invitation: Invitation;
};

/** Invitation status */
export enum InvitationStatus {
  Active = 'ACTIVE',
  Claimed = 'CLAIMED',
  Expired = 'EXPIRED',
  Revoked = 'REVOKED'
}

export type InvitationsArgsFilterInput = {
  statuses?: InputMaybe<Array<InvitationStatus>>;
};

export type InvitationsPagination = Connection & {
  __typename?: 'InvitationsPagination';
  edges: Array<InvitationEdge>;
  nodes: Array<Invitation>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type Invoice = Node & {
  __typename?: 'Invoice';
  createdAt: Scalars['DateTime']['output'];
  file?: Maybe<File>;
  id: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type InvoiceEdge = Edge & {
  __typename?: 'InvoiceEdge';
  cursor: Scalars['ID']['output'];
  node: Invoice;
};

export type InvoicePaginationArgsFilter = {
  identifier?: InputMaybe<FilterStringArgs>;
};

export enum LegalPersonType {
  Business = 'BUSINESS',
  Organization = 'ORGANIZATION',
  Soletrader = 'SOLETRADER'
}

export type Locale = Node & {
  __typename?: 'Locale';
  createdAt: Scalars['DateTime']['output'];
  /** id is represented as locale value such as fr-FR or en-US */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type LocaleEdge = Edge & {
  __typename?: 'LocaleEdge';
  cursor: Scalars['ID']['output'];
  node: Locale;
};

export type LocalesArgsFilter = {
  locale?: InputMaybe<LocalesArgsFilterLocales>;
};

export type LocalesArgsFilterLocales = {
  exclude?: InputMaybe<Array<Scalars['ID']['input']>>;
  include?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type LocalesPagination = {
  edges: Array<LocaleEdge>;
  nodes: Array<Locale>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type MangopayBankAccount = Node & {
  __typename?: 'MangopayBankAccount';
  BIC?: Maybe<Scalars['String']['output']>;
  IBAN?: Maybe<Scalars['String']['output']>;
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  mangopayId: Scalars['String']['output'];
  ownerName: Scalars['String']['output'];
  type: MangopayBankAccountType;
  updatedAt: Scalars['DateTime']['output'];
};

export type MangopayBankAccountCreateInput = {
  BIC?: InputMaybe<Scalars['String']['input']>;
  IBAN: Scalars['String']['input'];
  ownerName?: InputMaybe<Scalars['String']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
  type: MangopayBankAccountType;
};

export type MangopayBankAccountCreateOutput = {
  __typename?: 'MangopayBankAccountCreateOutput';
  mangopayBankAccount: MangopayBankAccount;
};

export type MangopayBankAccountEdge = Edge & {
  __typename?: 'MangopayBankAccountEdge';
  cursor: Scalars['ID']['output'];
  node: MangopayBankAccount;
};

export type MangopayBankAccountPagination = Pagination & {
  __typename?: 'MangopayBankAccountPagination';
  edges: Array<MangopayBankAccountEdge>;
  nodes: Array<MangopayBankAccount>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type MangopayBankAccountPaginationArgsFilter = {
  active?: InputMaybe<FilterBoolArgs>;
};

/** Mangopay Bank Account Type */
export enum MangopayBankAccountType {
  Ca = 'CA',
  Gb = 'GB',
  Iban = 'IBAN',
  Other = 'OTHER',
  Us = 'US'
}

export type MangopayBankAccountUpdateInput = {
  BIC?: InputMaybe<Scalars['String']['input']>;
  IBAN: Scalars['String']['input'];
  ownerName?: InputMaybe<Scalars['String']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
};

export type MangopayBankAccountUpdateOutput = {
  __typename?: 'MangopayBankAccountUpdateOutput';
  mangopayBankAccount: MangopayBankAccount;
};

export type MangopayBankingAlias = Node & {
  __typename?: 'MangopayBankingAlias';
  BIC: Scalars['String']['output'];
  IBAN: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type MangopayBankingAliasCreateOutput = {
  __typename?: 'MangopayBankingAliasCreateOutput';
  bankingAlias: MangopayBankingAlias;
  wallet: MangopayWallet;
};

export type MangopayBankwireDirectPayIn = Node & {
  __typename?: 'MangopayBankwireDirectPayIn';
  bankAccountAddress?: Maybe<BankAccountAddress>;
  bankAccountBIC?: Maybe<Scalars['String']['output']>;
  bankAccountIBAN?: Maybe<Scalars['String']['output']>;
  bankAccountOwnerName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  debitedFundsAmount: Scalars['Int']['output'];
  debitedFundsCurrency: Scalars['Currency']['output'];
  declaredDebitedFundsAmount: Scalars['Int']['output'];
  declaredDebitedFundsCurrency: Scalars['Currency']['output'];
  declaredFeesAmount: Scalars['Int']['output'];
  declaredFeesCurrency: Scalars['Currency']['output'];
  hasUserBeenNotified: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  mangopayId: Scalars['String']['output'];
  resultMessage: Scalars['String']['output'];
  status: MangopayBankwireDirectPayInStatus;
  updatedAt: Scalars['DateTime']['output'];
  wireReference?: Maybe<Scalars['String']['output']>;
};

export type MangopayBankwireDirectPayInArgsFilter = {
  AND?: InputMaybe<Array<MangopayBankwireDirectPayInArgsFilter>>;
  OR?: InputMaybe<Array<MangopayBankwireDirectPayInArgsFilter>>;
  id?: InputMaybe<FilterIdArgs>;
  status?: InputMaybe<MangopayBankwireDirectPayInArgsFilterStatus>;
};

export type MangopayBankwireDirectPayInArgsFilterStatus = {
  in?: InputMaybe<Array<MangopayBankwireDirectPayInStatus>>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<MangopayBankwireDirectPayInStatus>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<MangopayBankwireDirectPayInStatus>;
  notIn?: InputMaybe<Array<MangopayBankwireDirectPayInStatus>>;
};

export type MangopayBankwireDirectPayInArgsSorter = {
  createdAt?: InputMaybe<SortDirection>;
};

export type MangopayBankwireDirectPayInCreateInput = {
  amount: Scalars['Int']['input'];
  currency: Scalars['Currency']['input'];
};

export type MangopayBankwireDirectPayInCreateOutput = {
  __typename?: 'MangopayBankwireDirectPayInCreateOutput';
  mangopayBankwireDirectPayIn: MangopayBankwireDirectPayIn;
};

export type MangopayBankwireDirectPayInEdge = Edge & {
  __typename?: 'MangopayBankwireDirectPayInEdge';
  cursor: Scalars['ID']['output'];
  node: MangopayBankwireDirectPayIn;
};

export type MangopayBankwireDirectPayInPagination = Pagination & {
  __typename?: 'MangopayBankwireDirectPayInPagination';
  edges: Array<MangopayBankwireDirectPayInEdge>;
  nodes: Array<MangopayBankwireDirectPayIn>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type MangopayBankwireDirectPayInRefreshOutput = {
  __typename?: 'MangopayBankwireDirectPayInRefreshOutput';
  mangopayBankwireDirectPayIn: MangopayBankwireDirectPayIn;
};

export enum MangopayBankwireDirectPayInStatus {
  Created = 'CREATED',
  Failed = 'FAILED',
  Succeeded = 'SUCCEEDED'
}

export type MangopayBankwirePayOut = Node & {
  __typename?: 'MangopayBankwirePayOut';
  bankwireReference?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  debitedFundsAmount: Scalars['Int']['output'];
  debitedFundsCurrency: Scalars['Currency']['output'];
  declaredFeesAmount: Scalars['Int']['output'];
  declaredFeesCurrency: Scalars['Currency']['output'];
  id: Scalars['ID']['output'];
  mangopayId: Scalars['String']['output'];
  resultMessage?: Maybe<Scalars['String']['output']>;
  status: MangopayTransactionStatus;
  subventionReimbursementRequest?: Maybe<SubventionReimbursementRequest>;
  updatedAt: Scalars['DateTime']['output'];
};

export type MangopayBankwirePayOutArgsFilter = {
  status?: InputMaybe<MangopayBankwirePayOutArgsFilterStatus>;
  subventionReimbursementRequest?: InputMaybe<SubventionReimbursementRequestRelationFilter>;
};

export type MangopayBankwirePayOutArgsFilterStatus = {
  in?: InputMaybe<Array<MangopayTransactionStatus>>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<MangopayTransactionStatus>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<MangopayTransactionStatus>;
  notIn?: InputMaybe<Array<MangopayTransactionStatus>>;
};

export type MangopayBankwirePayOutArgsSorter = {
  createdAt?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type MangopayBankwirePayOutPagination = Pagination & {
  __typename?: 'MangopayBankwirePayOutPagination';
  edges: Array<MangopayBankwirePayOutPaginationEdge>;
  nodes: Array<MangopayBankwirePayOut>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type MangopayBankwirePayOutPaginationEdge = Edge & {
  __typename?: 'MangopayBankwirePayOutPaginationEdge';
  cursor: Scalars['ID']['output'];
  node: MangopayBankwirePayOut;
};

export type MangopayBankwirePayOutRetryOutput = {
  __typename?: 'MangopayBankwirePayOutRetryOutput';
  mangopayBankwirePayOut: MangopayBankwirePayOut;
};

export type MangopayKycDocument = Node & {
  __typename?: 'MangopayKycDocument';
  createdAt: Scalars['DateTime']['output'];
  errors?: Maybe<Array<MangopayKycDocumentError>>;
  id: Scalars['ID']['output'];
  mangopayId: Scalars['String']['output'];
  mangopayUser?: Maybe<MangopayUser>;
  processedAt?: Maybe<Scalars['DateTime']['output']>;
  refusedReasonMessage?: Maybe<Scalars['String']['output']>;
  refusedReasonType?: Maybe<MangopayKycDocumentRefusedReasonType>;
  status: MangopayKycDocumentStatus;
  type: MangopayKycDocumentType;
  updatedAt: Scalars['DateTime']['output'];
};

export type MangopayKycDocumentArgsFilter = {
  AND?: InputMaybe<Array<MangopayKycDocumentArgsFilter>>;
  OR?: InputMaybe<Array<MangopayKycDocumentArgsFilter>>;
  createdAt?: InputMaybe<FilterDateArgs>;
  id?: InputMaybe<FilterIdArgs>;
  status?: InputMaybe<MangopayKycDocumentArgsFilterStatus>;
  type?: InputMaybe<MangopayKycDocumentArgsFilterType>;
  updatedAt?: InputMaybe<FilterDateArgs>;
};

export type MangopayKycDocumentArgsFilterStatus = {
  in?: InputMaybe<Array<MangopayKycDocumentStatus>>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<MangopayKycDocumentStatus>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<MangopayKycDocumentStatus>;
  notIn?: InputMaybe<Array<MangopayKycDocumentStatus>>;
};

export type MangopayKycDocumentArgsFilterType = {
  in?: InputMaybe<Array<MangopayKycDocumentType>>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<MangopayKycDocumentType>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<MangopayKycDocumentType>;
  notIn?: InputMaybe<Array<MangopayKycDocumentType>>;
};

export type MangopayKycDocumentCreateInput = {
  pages: Array<MangopayKycPageCreateInput>;
  type: MangopayKycDocumentType;
};

export type MangopayKycDocumentCreateOutput = {
  __typename?: 'MangopayKycDocumentCreateOutput';
  mangopayKycDocument: MangopayKycDocument;
};

export type MangopayKycDocumentEdge = Edge & {
  __typename?: 'MangopayKycDocumentEdge';
  cursor: Scalars['ID']['output'];
  node: MangopayKycDocument;
};

export type MangopayKycDocumentError = {
  __typename?: 'MangopayKycDocumentError';
  message: Scalars['String']['output'];
};

export type MangopayKycDocumentPagination = Pagination & {
  __typename?: 'MangopayKycDocumentPagination';
  edges: Array<MangopayKycDocumentEdge>;
  nodes: Array<MangopayKycDocument>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type MangopayKycDocumentPaginationArgsFilter = {
  AND?: InputMaybe<Array<MangopayKycDocumentArgsFilter>>;
  OR?: InputMaybe<Array<MangopayKycDocumentArgsFilter>>;
  createdAt?: InputMaybe<FilterDateArgs>;
  id?: InputMaybe<FilterIdArgs>;
  status?: InputMaybe<MangopayKycDocumentArgsFilterStatus>;
  type?: InputMaybe<MangopayKycDocumentArgsFilterType>;
  updatedAt?: InputMaybe<FilterDateArgs>;
};

export type MangopayKycDocumentPaginationArgsSorter = {
  createdAt?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export enum MangopayKycDocumentRefusedReasonType {
  DocumentDoNotMatchAccountData = 'DOCUMENT_DO_NOT_MATCH_ACCOUNT_DATA',
  DocumentDoNotMatchUserData = 'DOCUMENT_DO_NOT_MATCH_USER_DATA',
  DocumentFalsified = 'DOCUMENT_FALSIFIED',
  DocumentHasExpired = 'DOCUMENT_HAS_EXPIRED',
  DocumentIncomplete = 'DOCUMENT_INCOMPLETE',
  DocumentMissing = 'DOCUMENT_MISSING',
  DocumentNotAccepted = 'DOCUMENT_NOT_ACCEPTED',
  DocumentUnreadable = 'DOCUMENT_UNREADABLE',
  SpecificCase = 'SPECIFIC_CASE',
  UnderagePerson = 'UNDERAGE_PERSON'
}

export enum MangopayKycDocumentStatus {
  Created = 'CREATED',
  OutOfDate = 'OUT_OF_DATE',
  Refused = 'REFUSED',
  Validated = 'VALIDATED',
  ValidationAsked = 'VALIDATION_ASKED'
}

export enum MangopayKycDocumentType {
  AddressProof = 'ADDRESS_PROOF',
  ArticlesOfAssociation = 'ARTICLES_OF_ASSOCIATION',
  IdentityProof = 'IDENTITY_PROOF',
  RegistrationProof = 'REGISTRATION_PROOF',
  ShareholderDeclaration = 'SHAREHOLDER_DECLARATION'
}

export type MangopayKycPageCreateInput = {
  file: Scalars['String']['input'];
};

export type MangopayLegalUserCreateInput = {
  companyNumber?: InputMaybe<Scalars['String']['input']>;
  representative: Representative;
  type: LegalPersonType;
  walletCurrency: Scalars['Currency']['input'];
};

export type MangopayLegalUserCreateOutput = {
  __typename?: 'MangopayLegalUserCreateOutput';
  mangopayUser: MangopayUser;
};

export type MangopayNaturalUserCreateOutput = {
  __typename?: 'MangopayNaturalUserCreateOutput';
  mangopayUser: MangopayUser;
};

/** Mangopay Person Type */
export enum MangopayPersonType {
  Legal = 'LEGAL',
  Natural = 'NATURAL'
}

export enum MangopayTransactionStatus {
  Created = 'CREATED',
  Failed = 'FAILED',
  Succeeded = 'SUCCEEDED'
}

export type MangopayTransfer = Node & {
  __typename?: 'MangopayTransfer';
  createdAt: Scalars['DateTime']['output'];
  fundsAmount: Scalars['Int']['output'];
  fundsCurrency: Scalars['Currency']['output'];
  id: Scalars['ID']['output'];
  mangopayId: Scalars['String']['output'];
  order?: Maybe<Order>;
  orderRefund?: Maybe<OrderRefund>;
  resultMessage: Scalars['String']['output'];
  status: MangopayTransactionStatus;
  subventionReimbursementRequest?: Maybe<SubventionReimbursementRequest>;
  updatedAt: Scalars['DateTime']['output'];
};

export type MangopayTransferArgsFilter = {
  order?: InputMaybe<OrderRelationFilter>;
  orderRefund?: InputMaybe<OrderRefundRelationFilter>;
  status?: InputMaybe<MangopayTransferArgsFilterStatus>;
  subventionReimbursementRequest?: InputMaybe<SubventionReimbursementRequestRelationFilter>;
};

export type MangopayTransferArgsFilterStatus = {
  in?: InputMaybe<Array<MangopayTransactionStatus>>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<MangopayTransactionStatus>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<MangopayTransactionStatus>;
  notIn?: InputMaybe<Array<MangopayTransactionStatus>>;
};

export type MangopayTransferArgsSorter = {
  createdAt?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type MangopayTransferEdge = Edge & {
  __typename?: 'MangopayTransferEdge';
  cursor: Scalars['ID']['output'];
  node: MangopayTransfer;
};

export type MangopayTransferPagination = Pagination & {
  __typename?: 'MangopayTransferPagination';
  edges: Array<MangopayTransferEdge>;
  nodes: Array<MangopayTransfer>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type MangopayTransferRelationFilter = {
  EVERY?: InputMaybe<MangopayTransferArgsFilter>;
  NONE?: InputMaybe<MangopayTransferArgsFilter>;
  SOME?: InputMaybe<MangopayTransferArgsFilter>;
};

export type MangopayTransferRetryOutput = {
  __typename?: 'MangopayTransferRetryOutput';
  mangopayTransfer: MangopayTransfer;
};

export type MangopayTransferToCentralWalletCreateInput = {
  fundsAmount: Scalars['Int']['input'];
  fundsCurrency: Scalars['Currency']['input'];
  orderId?: InputMaybe<Scalars['ID']['input']>;
};

export type MangopayTransferToCentralWalletCreateOutput = {
  __typename?: 'MangopayTransferToCentralWalletCreateOutput';
  mangopayTransfer: MangopayTransfer;
};

export type MangopayUser = Node & {
  __typename?: 'MangopayUser';
  bankAccountsPagination: MangopayBankAccountPagination;
  createdAt: Scalars['DateTime']['output'];
  defaultWallet: MangopayWallet;
  id: Scalars['ID']['output'];
  kycLevel: MangopayUserKycLevel;
  mangopayId: Scalars['String']['output'];
  mangopayKycDocumentPagination: MangopayKycDocumentPagination;
  owner?: Maybe<MangopayUserOwner>;
  type: MangopayPersonType;
  updatedAt: Scalars['DateTime']['output'];
};


export type MangopayUserBankAccountsPaginationArgs = {
  filter?: InputMaybe<MangopayBankAccountPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type MangopayUserMangopayKycDocumentPaginationArgs = {
  filter?: InputMaybe<MangopayKycDocumentPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<MangopayKycDocumentPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

/** Mangopay user kyc level */
export enum MangopayUserKycLevel {
  Light = 'LIGHT',
  Regular = 'REGULAR'
}

export type MangopayUserOwner = Company | User;

export type MangopayWallet = Node & {
  __typename?: 'MangopayWallet';
  balance: Scalars['Int']['output'];
  bankingAlias?: Maybe<MangopayBankingAlias>;
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['Currency']['output'];
  id: Scalars['ID']['output'];
  mangopayBankwireDirectPayInPagination: MangopayBankwireDirectPayInPagination;
  mangopayId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type MangopayWalletMangopayBankwireDirectPayInPaginationArgs = {
  filter?: InputMaybe<MangopayBankwireDirectPayInArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<MangopayBankwireDirectPayInArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type MenuCustomisationConfig = FeatureConfig & {
  __typename?: 'MenuCustomisationConfig';
  createdAt: Scalars['DateTime']['output'];
  elements: Array<MenuCustomisationElement>;
  /** This id matches the company ID */
  id: Scalars['ID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type MenuCustomisationElement = Node & {
  __typename?: 'MenuCustomisationElement';
  /** Used to indicate if a link is active when the active route matches one of the aliases */
  aliases?: Maybe<Array<Scalars['String']['output']>>;
  createdAt: Scalars['DateTime']['output'];
  elements: Array<MenuCustomisationElement>;
  href?: Maybe<Scalars['String']['output']>;
  icon: MenuCustomisationElementIcon;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum MenuCustomisationElementIcon {
  Advantage = 'ADVANTAGE',
  Calendar = 'CALENDAR',
  Communication = 'COMMUNICATION',
  Deal = 'DEAL',
  Feed = 'FEED',
  Game = 'GAME',
  Gift = 'GIFT',
  Grid = 'GRID',
  Home = 'HOME',
  Newspaper = 'NEWSPAPER',
  Search = 'SEARCH',
  Subvention = 'SUBVENTION',
  Suitcase = 'SUITCASE',
  Users = 'USERS'
}

export type MigrateBrandsInput = {
  fournisseurs?: InputMaybe<Array<Scalars['Int']['input']>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type MigrateBrandsOutput = {
  __typename?: 'MigrateBrandsOutput';
  brands: Array<Brand>;
};

export type MigrateReducCeOutput = {
  __typename?: 'MigrateReducCeOutput';
  brands: Array<Brand>;
};

export type Mutation = {
  __typename?: 'Mutation';
  HappeningRegistrationSetStatusToAwaitingStripe: HappeningRegistrationSetStatusToAwaitingStripeOutput;
  addCatalogBrandExclude: CatalogBrandUpdateOutput;
  addCatalogBrandInclude: CatalogBrandUpdateOutput;
  addCatalogCampaignExclude: CatalogCampaignUpdateOutput;
  addCatalogCampaignInclude: CatalogCampaignUpdateOutput;
  addCatalogCategoryExclude: CatalogCategoryUpdateOutput;
  addCatalogOfferExclude: CatalogOfferUpdateOutput;
  addCatalogOfferInclude: CatalogOfferUpdateOutput;
  addressesNormalize: AddressesNormalizeOutput;
  adminCommentCreate: AdminCommentCreateOutput;
  adminCommentDelete: AdminCommentDeleteOutput;
  adminCommentUpdate: AdminCommentUpdateOutput;
  alertCreate: AlertCreateOutput;
  alertDelete: AlertDeleteOutput;
  apiKeyCreate: ApiKeyCreateOutput;
  apiKeyDelete: ApiKeyDeleteOutput;
  apiKeyUpdate: ApiKeyUpdateOutput;
  appdragBeneficiariesSynchronization: Scalars['Int']['output'];
  appdragExportTransactions: AppdragExportTransactionsOutput;
  appdragMigrateComments: Scalars['JSONObject']['output'];
  appdragMigratePerksIntoCampaign: CampaignCreateOutput;
  applyCategoriesToOffers: Scalars['String']['output'];
  articleAddAttachment: ArticleAddAttachmentOutput;
  articleArchive: ArticleUpdateOutput;
  articleCategoryCreate: ArticleCategoryCreateOutput;
  articleCategoryDelete: ArticleCategoryDeleteOutput;
  articleCategoryTranslationCreate: ArticleCategoryTranslationCreateOutput;
  articleCategoryTranslationDelete: ArticleCategoryTranslationDeleteOutput;
  articleCategoryTranslationUpdate: ArticleCategoryTranslationUpdateOutput;
  articleCategoryUpdate: ArticleCategoryUpdateOutput;
  articleCreate: ArticleCreateOutput;
  articleDelete: ArticleDeleteOutput;
  articleRemoveAttachment: ArticleRemoveAttachmentOutput;
  articleTranslationCreate: ArticleTranslationCreateOutput;
  articleTranslationDelete: ArticleTranslationDeleteOutput;
  articleTranslationUpdate: ArticleTranslationUpdateOutput;
  articleUnarchive: ArticleUpdateOutput;
  articleUpdate: ArticleUpdateOutput;
  authenticationForceResetPassword: AuthenticationForceResetPasswordOutput;
  authenticationGeneratePasswordResetToken: AuthenticationGeneratePasswordResetTokenOutput;
  authenticationLogout: Scalars['Boolean']['output'];
  authenticationPasswordUpdate: AuthenticationPasswordUpdateOutput;
  authenticationSessionTokenCreate: AuthenticationSessionTokenCreateOutput;
  /**
   *
   *     Accept a beneficiary.
   *
   *     Throw 'beneficiary/cannot-validate-self-beneficiary' if the beneficiary parent is the viewer.
   *
   */
  beneficiaryAccept: BeneficiaryAcceptOutput;
  /**
   *
   *     Create a beneficiary for the target user.
   *
   *     Throw 'beneficiary/concurrent-partner' if target user already have an existing Partner beneficiary.
   *
   */
  beneficiaryCreate: BeneficiaryCreateOutput;
  /**
   *
   *     Delete a beneficiary.
   *
   *     Throw 'beneficiary/concurrent-partner' if target user already have an existing Partner beneficiary.
   *
   */
  beneficiaryDelete: BeneficiaryDeleteOutput;
  beneficiaryFeatureDisable: BeneficiaryFeatureEnableOutput;
  beneficiaryFeatureDisableChildrenUpdate: BeneficiaryFeatureEnableOutput;
  beneficiaryFeatureDisablePartnerUpdate: BeneficiaryFeatureEnableOutput;
  beneficiaryFeatureEnable: BeneficiaryFeatureEnableOutput;
  beneficiaryFeatureEnableChildrenUpdate: BeneficiaryFeatureEnableOutput;
  beneficiaryFeatureEnablePartnerUpdate: BeneficiaryFeatureEnableOutput;
  /**
   *
   *     Add a beneficiary proof.
   *
   */
  beneficiaryProofAdd: BeneficiaryProofAddOutput;
  /**
   *
   *     Remove a beneficiary proof.
   *
   */
  beneficiaryProofRemove: BeneficiaryProofRemoveOutput;
  /**
   *
   *     Reject a beneficiary.
   *
   *     Throw 'beneficiary/cannot-validate-self-beneficiary' if the beneficiary parent is the viewer.
   *
   */
  beneficiaryReject: BeneficiaryRejectOutput;
  beneficiaryRejectFollowUp: BeneficiaryRejectFollowUpOutput;
  /**
   *
   *     Update a beneficiary.
   *
   *     Throw 'beneficiary/concurrent-partner' if target user already have an existing Partner beneficiary.
   *
   */
  beneficiaryUpdate: BeneficiaryUpdateOutput;
  brandCreate: BrandCreateOutput;
  brandMergeDuplicate: BrandMergeDuplicateOutput;
  brandTranslationCreate: BrandTranslationCreateOutput;
  brandTranslationDelete: BrandTranslationDeleteOutput;
  brandTranslationGenerate: BrandTranslationCreateOutput;
  brandTranslationUpdate: BrandTranslationUpdateOutput;
  brandUpdate: BrandUpdateOutput;
  campaignCreate: CampaignCreateOutput;
  campaignDelete: CampaignDeleteOutput;
  /** @deprecated Use discover instead */
  campaignDemote: CampaignDemoteOutput;
  /** @deprecated Use discover instead */
  campaignHighlightReorder: CampaignHighlightReorderOutput;
  /** @deprecated Use discover instead */
  campaignPromote: CampaignPromoteOutput;
  campaignPublish: CampaignPublishOutput;
  campaignSelectionCampaignsAdd: CampaignSelectionCampaignsAddOutput;
  campaignSelectionCampaignsRemove: CampaignSelectionCampaignsRemoveOutput;
  campaignSelectionCreate: CampaignSelectionCreateOutput;
  campaignSelectionDelete: CampaignSelectionDeleteOutput;
  campaignSelectionPublish: CampaignSelectionPublishOutput;
  campaignSelectionTranslationCreate: CampaignSelectionTranslationCreateOutput;
  campaignSelectionTranslationDelete: CampaignSelectionTranslationDeleteOutput;
  campaignSelectionTranslationUpdate: CampaignSelectionTranslationUpdateOutput;
  campaignSelectionUnpublish: CampaignSelectionUnpublishOutput;
  campaignSelectionUpdate: CampaignSelectionUpdateOutput;
  campaignTranslationCreate: CampaignTranslationCreateOutput;
  campaignTranslationDelete: CampaignTranslationDeleteOutput;
  campaignTranslationGenerate: CampaignTranslationCreateOutput;
  campaignTranslationUpdate: CampaignTranslationUpdateOutput;
  campaignUnpublish: CampaignUnpublishOutput;
  campaignUpdate: CampaignUpdateOutput;
  cartCheckout: CartCheckoutOutput;
  cartEmpty: CartEmptyOutput;
  cartItemCreate: CartItemCreateOutput;
  cartItemDelete: CartItemDeleteOutput;
  cartItemUpdate: CartItemUpdateOutput;
  cartSubventionDisable: CartFundSubventionEnableOutput;
  cartSubventionEnable: CartFundSubventionEnableOutput;
  cartSubventionsDisable: CartOutput;
  cartSubventionsEnable: CartOutput;
  catalogCompute: Scalars['Boolean']['output'];
  catalogCreate: CatalogCreateOutput;
  catalogDelete: CatalogDeleteOutput;
  categoryCreate: CategoryCreateOutput;
  categoryDelete: CategoryDeleteOutput;
  categoryTranslationCreate: CategoryTranslationCreateOutput;
  categoryTranslationDelete: CategoryTranslationDeleteOutput;
  categoryTranslationUpdate: CategoryTranslationUpdateOutput;
  categoryUpdate: CategoryUpdateOutput;
  cohortCreate: CohortCreateOutput;
  cohortDelete: CohortDeleteOutput;
  cohortRefresh: CohortRefreshOutput;
  cohortUpdate: CohortUpdateOutput;
  companyArticleExcludeAdd: CompanyArticleExcludeOutput;
  companyArticleExcludeRemove: CompanyArticleExcludeOutput;
  companyArticleIncludeAdd: CompanyArticleIncludeActionOutput;
  companyArticleIncludeRemove: CompanyArticleIncludeActionOutput;
  companyCampaignSelectionExcludeAdd: CompanyCampaignSelectionExcludeOutput;
  companyCampaignSelectionExcludeRemove: CompanyCampaignSelectionExcludeOutput;
  companyCampaignSelectionIncludeAdd: CompanyCampaignSelectionIncludeActionOutput;
  companyCampaignSelectionIncludeRemove: CompanyCampaignSelectionIncludeActionOutput;
  companyCreate: CompanyCreateOutput;
  companyCustomFieldCreate: CompanyCustomFieldCreateOutput;
  companyCustomFieldDelete: CompanyCustomFieldDeleteOutput;
  companyCustomFieldUpdate: CompanyCustomFieldUpdateOutput;
  companyDocumentCreate: CompanyDocumentCreateOutput;
  companyDocumentDelete: CompanyDocumentDeleteOutput;
  /** @deprecated Please use `highligtCreateInstead */
  companyDocumentPin: CompanyDocumentUpdateOutput;
  /** @deprecated Please use `highligtDeleteInstead */
  companyDocumentUnpin: CompanyDocumentUpdateOutput;
  companyDocumentUpdate: CompanyDocumentUpdateOutput;
  companyDriveCreate: CompanyDriveCreateOutput;
  companyFeatureDisable: CompanyFeatureDisableOutput;
  companyFeatureEnable: CompanyFeatureEnableOutput;
  companyFeesCreate: CompanyFeesCreateOutput;
  companyFeesDelete: CompanyFeesDeleteOutput;
  companyFeesUpdate: CompanyFeesUpdateOutput;
  /** @deprecated Use the REST route /company-drive/folder/:companyFolderId/export with GET method instead. */
  companyFolderCompressAndExport: CompanyFolderCompressAndExportOutput;
  companyFolderCreate: CompanyFolderCreateOutput;
  companyFolderDelete: CompanyFolderDeleteOutput;
  companyFolderUpdate: CompanyFolderUpdateOutput;
  companyMembersDryRun: Scalars['Boolean']['output'];
  companyMembersImport: CompanyMembersImportOutput;
  companyMembershipCreate: CompanyMembershipCreateOutput;
  companyMembershipUpdate: CompanyMembershipUpdateOutput;
  companyStripeConnectedAccountCreate: StripeConnectedAccountCreateOutput;
  companyStripeConnectedAccountLinkCreate: StripeConnectedAccountLinkCreateOutput;
  companySubsidiaryImportUsers: CompanySubsidiaryImportOutput;
  companySubsidiaryImportUsersDry: CompanySubsidiaryImportDryOutput;
  companyUpdate: CompanyUpdateOutput;
  computeSubventionCatalog: Scalars['Boolean']['output'];
  computeSubventionCatalogSeq: Scalars['Boolean']['output'];
  couponClaimableClaim: CouponClaimableClaimOutput;
  couponClaimableDelete: CouponClaimableDeleteOutput;
  couponClaimableUpdate: CouponClaimableUpdateOutput;
  couponClaimablesCreate: CouponClaimablesCreateOutput;
  couponPublicUpdate: CouponPublicUpdateOutput;
  createInvoiceWithConsumptionOutsideSubventionDateForAllCompanies: Scalars['Boolean']['output'];
  deleteCategories: Scalars['String']['output'];
  discountCreate: DiscountCreateOutput;
  discountDelete: DiscountDeleteOutput;
  discoverCreate: DiscoverCreateOutput;
  discoverDelete: DiscoverDeleteOutput;
  discoverUpdatePosition: DiscoverUpdatePositionOutput;
  favoriteCampaignCreate: FavoriteCampaignOutput;
  favoriteCampaignToggle?: Maybe<FavoriteCampaignOutput>;
  favoriteDelete: Scalars['Boolean']['output'];
  fileCreate: FileCreateOutput;
  gameCompanyBlacklistAdd: GameCompanyBlacklistOutput;
  gameCompanyBlacklistRemove: GameCompanyBlacklistOutput;
  gameCompanyWhitelistAdd: GameCompanyWhitelistOutput;
  gameCompanyWhitelistRemove: GameCompanyWhitelistOutput;
  gameCreate: GameCreateOutput;
  gameDelete: GameDeleteOutput;
  gameUpdate: GameUpdateOutput;
  gatheringAccessCreate: GatheringAccessCreateOutput;
  gatheringAccessRemoveManuallyInvited: GatheringAccessUpdateOutput;
  gatheringAccessUpdate: GatheringAccessUpdateOutput;
  gatheringCancel: GatheringCancelOutput;
  gatheringCreate: GatheringCreateOutput;
  gatheringDelete: GatheringDeleteOutput;
  gatheringFeatureDisable: GatheringFeatureDisableOutput;
  gatheringFeatureEnable: GatheringFeatureEnableOutput;
  gatheringPublish: GatheringPublishOutput;
  gatheringRegistrationCancel: GatheringRegistrationCancelOutput;
  gatheringSessionCancel: GatheringSessionCancelOutput;
  gatheringSessionPricesCreate: GatheringSessionPricesCreateOutput;
  gatheringSessionRegistrationCancel: GatheringSessionRegistrationCancelOutput;
  gatheringSessionRegistrationCheckout: GatheringSessionRegistrationCheckoutOutput;
  gatheringSessionRegistrationCreate: GatheringSessionRegistrationCreateOutput;
  gatheringSessionTicketDelete: GatheringSessionTicketDeleteOutput;
  gatheringSessionUpdate: GatheringSessionUpdateOutput;
  gatheringSessionsCreate: GatheringSessionsCreateOutput;
  gatheringUpdate: GatheringUpdateOutput;
  happeningAddAttachments: HappeningAddAttachmentsOutput;
  happeningCancel: HappeningCancelOutput;
  happeningConfirmFundsTransfered: HappeningConfirmFundsTransferedOutput;
  happeningCreate: HappeningCreateOutput;
  happeningRegistrationCancel: HappeningRegistrationCancelOutput;
  happeningRegistrationCreate: HappeningRegistrationCreateOutput;
  happeningRemoveAttachments: HappeningRemoveAttachmentsOutput;
  happeningUpdate: HappeningUpdateOutput;
  highlightCreate: HighlightCreateOutput;
  highlightDelete: HighlightDeleteOutput;
  highlightPositionUpdate: HighlightPositionUpdateOutput;
  homeCustomisationUpdateMessage: HomeCustomisationUpdateMessageOutput;
  /**
   *
   *     Create an invitation for the target user.
   *
   *     Throw 'invitation/user-already-active' if target user is already active.
   *
   */
  invitationCreate: InvitationCreateOutput;
  /**
   *
   *     Create invitations for provided userIds. Does not create invitations for already active users
   *
   */
  invitationCreateBulk: InvitationCreateBulkOutput;
  invitationCreateCompany: InvitationCreateCompanyOutput;
  invitationEmailSendBulk: InvitationEmailSendBulkOutput;
  invitationLinkGenerateBulk: InvitationLinkGenerateBulkOutput;
  invitationRenew: InvitationSendOutput;
  invitationRevoke: InvitationRevokeOutput;
  invitationSend: InvitationSendOutput;
  invitationSendBulk: InvitationEmailSendBulkOutput;
  invitationSendCompany: InvitationSendCompanyOutput;
  mangopayBankAccountCreate: MangopayBankAccountCreateOutput;
  mangopayBankAccountDeactivate: MangopayBankAccountCreateOutput;
  mangopayBankAccountUpdate: MangopayBankAccountUpdateOutput;
  mangopayBankingAliasCreate: MangopayBankingAliasCreateOutput;
  mangopayBankwireDirectPayInCreate: MangopayBankwireDirectPayInCreateOutput;
  mangopayBankwireDirectPayInRefresh: MangopayBankwireDirectPayInRefreshOutput;
  mangopayBankwirePayOutCreateForSubventionReimbursement: Scalars['ID']['output'];
  mangopayBankwirePayOutRetry: MangopayBankwirePayOutRetryOutput;
  mangopayKycDocumentCreate: MangopayKycDocumentCreateOutput;
  mangopayKycDocumentCreateForUser: MangopayKycDocumentCreateOutput;
  mangopayLegalUserCreate: MangopayLegalUserCreateOutput;
  mangopayNaturalUserCreate: MangopayNaturalUserCreateOutput;
  mangopayTransferFromCompanyWalletToCentralWalletCreate: MangopayTransferToCentralWalletCreateOutput;
  mangopayTransferRetry: MangopayTransferRetryOutput;
  mangopayUserSynchronization: Scalars['Boolean']['output'];
  migrateBrands: MigrateBrandsOutput;
  migrateCompany: AppdragMigrationSynchronizationCreateOutput;
  migrateCompanyDelete: AppdragMigrationCompanyDeleteOutput;
  migrateReducCe: MigrateReducCeOutput;
  netPromoterScoreCreate: NetPromoterScoreOutput;
  netPromoterScoreDelete: Scalars['Boolean']['output'];
  netPromoterScoreUpdate: NetPromoterScoreOutput;
  newsletterCreate: NewsletterCreateOutput;
  newsletterDelete: NewsletterDeleteOutput;
  newsletterDuplicate: NewsletterDuplicateOutput;
  newsletterSend: NewsletterSendOutput;
  newsletterSendTest: NewsletterSendTestOutput;
  newsletterUpdate: NewsletterUpdateOutput;
  notificationCenterRetry: Scalars['Boolean']['output'];
  notificationsRead: Scalars['Int']['output'];
  offerAclCompute: Scalars['Boolean']['output'];
  offerBaseCreate: OfferBaseCreateOutput;
  offerCouponClaimableCreate: OfferCouponClaimableCreateOutput;
  offerCouponClaimableUpdate: OfferCouponClaimableUpdateOutput;
  offerCouponPublicCreate: OfferCouponPublicCreateOutput;
  offerGiftCardCreate: OfferGiftCardCreateOutput;
  offerPublish: OfferPublishOutput;
  offerTicketCreate: OfferTicketCreateOutput;
  offerTranslationCreate: OfferTranslationCreateOutput;
  offerTranslationDelete: OfferTranslationDeleteOutput;
  offerTranslationGenerate: OfferTranslationCreateOutput;
  offerTranslationUpdate: OfferTranslationUpdateOutput;
  offerUnpublish: OfferUnpublishOutput;
  offerUpdate: OfferUpdateOutput;
  offerUrlCreate: OfferUrlCreateOutput;
  orderInvoiceGenerate: OrderInvoiceGenerateOutput;
  orderItemArticlesAdd: OrderItemArticlesAddOutput;
  orderItemArticlesRemove: OrderItemArticlesRemoveOutput;
  orderItemStatusUpdate: OrderItemStatusUpdateOutput;
  orderRefundCreate: OrderRefundCreateOutput;
  orderRefundProcess: OrderRefundProcessOutput;
  orderRefundReject: OrderRefundRejectOutput;
  orderResendOrderConfirmationEmail: OrderResendOrderConfirmationEmailOutput;
  paymentLimitCreate: PaymentLimitCreateOutput;
  paymentLimitDelete: PaymentLimitDeleteOutput;
  pictureCreate: PictureCreateOutput;
  pictureStockCreate: PictureStockCreateOutput;
  pictureStockDelete: PictureStockDeleteOutput;
  pointOfContactCreate: PointOfContactCreateOutput;
  pointOfContactDelete: PointOfContactDeleteOutput;
  pointOfContactSetPosition: PointOfContactSetPositionOutput;
  pointOfContactUpdate: PointOfContactUpdateOutput;
  productArticleBulkCreate: ProductArticleCreateBulkOutput;
  productArticleCreate: ProductArticleCreateOutput;
  productArticleDelete: ProductArticleDeleteOutput;
  productCatalogCreate: ProductCatalogCreateOutput;
  productCreate: ProductCreateOutput;
  productOrderRetry: ProductOrderRetryOutput;
  productSynchonizationCreate: ProductSynchronizationCreateOutput;
  productTranslationCreate: ProductTranslationCreateOutput;
  productTranslationDelete: ProductTranslationDeleteOutput;
  productTranslationUpdate: ProductTranslationUpdateOutput;
  productUpdate: ProductUpdateOutput;
  productVariantActivate: ProductVariantActivateOutput;
  productVariantCreate: ProductVariantCreateOutput;
  productVariantDeactivate: ProductVariantDeactivateOutput;
  productVariantStockUpdate: ProductVariantStockUpdateOutput;
  publicationAddAttachment: PublicationAddAttachmentOutput;
  publicationCreate: PublicationCreateOutput;
  publicationDelete: PublicationDeleteOutput;
  publicationPublish: PublicationCreateOutput;
  publicationRemoveAttachment: PublicationRemoveAttachmentOutput;
  publicationUnpublish: PublicationCreateOutput;
  publicationUpdate: PublicationUpdateOutput;
  publicationsRead: PublicationsReadOutput;
  pushTokenDelete: PushTokenDeleteOutput;
  pushTokenUpsert: PushTokenUpsertOutput;
  questionCategoryCreate: QuestionCategoryCreateOutput;
  questionCategoryDelete: QuestionCategoryDeleteOutput;
  questionCategoryUpdate: QuestionCategoryUpdateOutput;
  questionCreate: QuestionCreateOutput;
  questionDelete: QuestionDeleteOutput;
  questionUpdate: QuestionUpdateOutput;
  recomputeAllOrderAndOrderItemPrices: Scalars['Boolean']['output'];
  removeCatalogBrandExclude: CatalogBrandUpdateOutput;
  removeCatalogBrandInclude: CatalogBrandUpdateOutput;
  removeCatalogCampaignExclude: CatalogCampaignUpdateOutput;
  removeCatalogCampaignInclude: CatalogCampaignUpdateOutput;
  removeCatalogCategoryExclude: CatalogCategoryUpdateOutput;
  removeCatalogOfferExclude: CatalogOfferUpdateOutput;
  removeCatalogOfferInclude: CatalogOfferUpdateOutput;
  removeIpV4InIpSet: Scalars['Boolean']['output'];
  removeIpV6InIpSet: Scalars['Boolean']['output'];
  searchSynchonizationCreate: SearchSynchronizationCreateOutput;
  sendgridCompanySubuserCreate: SendgridCompanySubuserCreateOutput;
  sessionRevoke: SessionRevokeOutput;
  setupCategories: Scalars['JSONObject']['output'];
  sorewardsUserCreate: SorewardsUserCreateOutput;
  sorewardsUserGenerateLoginUrl: SorewardsUserGenerateLoginUrlOutput;
  ssoProviderOIDCCreate: SsoProviderOidcCreateOutput;
  ssoProviderSAMLCreate: SsoProviderSamlCreateOutput;
  storeCreate: StoreCreateOutput;
  storeDelete: StoreDeleteOutput;
  storeUpdate: StoreUpdateOutput;
  storesAddOffers: StoresAddOffersOutput;
  storesRemoveOffers: StoresRemoveOffersOutput;
  subventionAbort: SubventionAbortOutput;
  subventionComplete: SubventionCompleteOutput;
  subventionCompleteAll: Array<SubventionPublishOutput>;
  subventionConfirm: SubventionConfirmOutput;
  subventionCreate: SubventionCreateOutput;
  subventionDelete: SubventionDeleteOutput;
  subventionDuplicate: SubventionDuplicateOutput;
  subventionExtend: SubventionExtendOutput;
  subventionGranteeAdd: SubventionGranteeAddOutput;
  subventionGranteesRecompute: SubventionGranteesRecomputeOutput;
  subventionInvoiceCreateMonthlyForAllCompanies: Scalars['Boolean']['output'];
  subventionInvoiceLegacyRefundCreateForAllCompanies: Scalars['Boolean']['output'];
  /** @deprecated Use subventionConfirm instead */
  subventionLock: SubventionLockOutput;
  subventionPublish: SubventionPublishOutput;
  subventionPublishAll: Array<SubventionPublishOutput>;
  subventionReimbursementRequestApprove: SubventionReimbursementRequestOutput;
  subventionReimbursementRequestCancel: SubventionReimbursementRequestOutput;
  subventionReimbursementRequestCreate: SubventionReimbursementRequestCreateOutput;
  subventionReimbursementRequestReject: SubventionReimbursementRequestOutput;
  subventionRename: SubventionRenameOutput;
  /** @deprecated This mutation is deprecated and will be removed in the next version. */
  subventionSchedule: SubventionScheduleOutput;
  subventionSchemaBlacklistCompaniesAdd: SubventionSchemaBlacklistCompaniesAddOutput;
  subventionSchemaBlacklistCompaniesRemove: SubventionSchemaBlacklistCompaniesRemoveOutput;
  subventionSchemaCreate: SubventionSchemaCreateOutput;
  subventionSchemaDelete: SubventionSchemaDeleteOutput;
  subventionSchemaOffersAdd: SubventionSchemaOffersAddOutput;
  subventionSchemaOffersRemove: SubventionSchemaOffersRemoveOutput;
  subventionSchemaPublish: SubventionSchemaPublishOutput;
  subventionSchemaRestore: SubventionSchemaRestoreOutput;
  subventionSchemaUnpublish: SubventionSchemaUnpublishOutput;
  subventionSchemaUpdate: SubventionSchemaUpdateOutput;
  subventionSchemaWhitelistCompaniesAdd: SubventionSchemaWhitelistCompaniesAddOutput;
  subventionSchemaWhitelistCompaniesRemove: SubventionSchemaWhitelistCompaniesRemoveOutput;
  subventionUpdate: SubventionUpdateOutput;
  subventionValidate: SubventionValidateOutput;
  /** @deprecated This mutation is deprecated and will be removed in the next version. */
  subventionValidateProvision: SubventionValidateProvisionOutput;
  surveyCreate: SurveyCreateOutput;
  surveyDelete: SurveyDeleteOutput;
  surveyFeatureDisable: SurveyFeatureDisableOutput;
  surveyFeatureEnable: SurveyFeatureEnableOutput;
  surveyUpdate: SurveyUpdateOutput;
  taxRateCreate: TaxRateCreateOutput;
  taxRateDelete: TaxRateDeleteOutput;
  taxRateUpdate: TaxRateUpdateOutput;
  twoFactorCreate: TwoFactorCreateOutput;
  twoFactorDelete: TwoFactoreDeleteOutput;
  twoFactorEnable: TwoFactoreEnableOutput;
  twoFactorSend: Scalars['Boolean']['output'];
  userBan: UserCreateOutput;
  userCreate: UserCreateOutput;
  userDelete: UserDeleteOutput;
  userIntegrationConnectorDelete: UserIntegrationConnectorDeleteOutput;
  userIntegrationConnectorLuccaAdd: UserIntegrationLuccaCredentialsAddOutput;
  userIntegrationConnectorLuccaUpdate: UserIntegrationLuccaCredentialsUpdateOutput;
  userIntegrationConnectorSalesforceRcuAdd: UserIntegrationSalesforceRcuCredentialsAddOutput;
  userIntegrationConnectorSalesforceRcuUpdate: UserIntegrationSalesforceRcuCredentialsUpdateOutput;
  /** Set the synchronisation frequency of a connector. Super admin only. */
  userIntegrationConnectorSetFrequency: UserIntegrationConnectorSetFrequencyOutput;
  userIntegrationFeatureDisable: UserIntegrationFeatureDisableOutput;
  userIntegrationFeatureEnable: UserIntegrationFeatureEnableOutput;
  userIntegrationProviderDisable: UserIntegrationProviderDisableOutput;
  userIntegrationProviderEnable: UserIntegrationProviderEnableOutput;
  userIntegrationProviderUpdate: UserIntegrationProviderUpdateOutput;
  userIntegrationSynchronisationAbortJob: UserIntegrationSynchronisationAbortOutput;
  userIntegrationSynchronisationCreate: UserIntegrationSynchronisationCreateOutput;
  userPreferencesUpdate: UserPreferencesUpdateOutput;
  userTermsAccept: UserCreateOutput;
  userUnban: UserCreateOutput;
  userUpdate: UserUpdateOutput;
  voucherCreate: VoucherCreateOutput;
  voucherDelete: VoucherDeleteOutput;
  voucherFlatDiscountUpdate: VoucherFlatDiscountUpdateOutput;
  voucherPercentDiscountUpdate: VoucherPercentDiscountUpdateOutput;
  voucherRewardUpdate: VoucherRewardUpdateOutput;
};


export type MutationHappeningRegistrationSetStatusToAwaitingStripeArgs = {
  happeningRegistrationId: Scalars['ID']['input'];
};


export type MutationAddCatalogBrandExcludeArgs = {
  catalogId: Scalars['ID']['input'];
  input: CatalogBrandUpdateInput;
};


export type MutationAddCatalogBrandIncludeArgs = {
  catalogId: Scalars['ID']['input'];
  input: CatalogBrandUpdateInput;
};


export type MutationAddCatalogCampaignExcludeArgs = {
  catalogId: Scalars['ID']['input'];
  input: CatalogCampaignUpdateInput;
};


export type MutationAddCatalogCampaignIncludeArgs = {
  catalogId: Scalars['ID']['input'];
  input: CatalogCampaignUpdateInput;
};


export type MutationAddCatalogCategoryExcludeArgs = {
  catalogId: Scalars['ID']['input'];
  input: CatalogCategoryUpdateInput;
};


export type MutationAddCatalogOfferExcludeArgs = {
  catalogId: Scalars['ID']['input'];
  input: CatalogOfferUpdateInput;
};


export type MutationAddCatalogOfferIncludeArgs = {
  catalogId: Scalars['ID']['input'];
  input: CatalogOfferUpdateInput;
};


export type MutationAddressesNormalizeArgs = {
  input: AddressesNormalizeArgs;
};


export type MutationAdminCommentCreateArgs = {
  input: AdminCommentCreateInput;
  targetId: Scalars['ID']['input'];
  targetType: AdminCommentTargetType;
};


export type MutationAdminCommentDeleteArgs = {
  adminCommentId: Scalars['ID']['input'];
};


export type MutationAdminCommentUpdateArgs = {
  adminCommentId: Scalars['ID']['input'];
  input: AdminCommentUpdateInput;
};


export type MutationAlertCreateArgs = {
  input: AlertCreateInput;
};


export type MutationAlertDeleteArgs = {
  alertId: Scalars['ID']['input'];
};


export type MutationApiKeyCreateArgs = {
  companyId: Scalars['ID']['input'];
  input: ApiKeyCreateInput;
};


export type MutationApiKeyDeleteArgs = {
  apiKeyId: Scalars['ID']['input'];
};


export type MutationApiKeyUpdateArgs = {
  apiKeyId: Scalars['ID']['input'];
  input: ApiKeyUpdateInput;
};


export type MutationAppdragBeneficiariesSynchronizationArgs = {
  input: BeneficiariesSynchronization;
};


export type MutationAppdragMigratePerksIntoCampaignArgs = {
  campaignId: Scalars['ID']['input'];
  perkIds: Array<Scalars['Int']['input']>;
};


export type MutationArticleAddAttachmentArgs = {
  articleId: Scalars['ID']['input'];
  input: ArticleAddAttachmentInput;
};


export type MutationArticleArchiveArgs = {
  articleId: Scalars['ID']['input'];
};


export type MutationArticleCategoryCreateArgs = {
  input: ArticleCategoryCreateInput;
};


export type MutationArticleCategoryDeleteArgs = {
  articleCategoryId: Scalars['ID']['input'];
};


export type MutationArticleCategoryTranslationCreateArgs = {
  articleCategoryId: Scalars['ID']['input'];
  input: ArticleCategoryTranslationCreateInput;
  localeId: Scalars['ID']['input'];
};


export type MutationArticleCategoryTranslationDeleteArgs = {
  articleCategoryId: Scalars['ID']['input'];
  localeId: Scalars['ID']['input'];
};


export type MutationArticleCategoryTranslationUpdateArgs = {
  articleCategoryId: Scalars['ID']['input'];
  input: ArticleCategoryTranslationUpdateInput;
  localeId: Scalars['ID']['input'];
};


export type MutationArticleCategoryUpdateArgs = {
  articleCategoryId: Scalars['ID']['input'];
  input: ArticleCategoryUpdateInput;
};


export type MutationArticleCreateArgs = {
  input: ArticleCreateInput;
};


export type MutationArticleDeleteArgs = {
  articleId: Scalars['ID']['input'];
};


export type MutationArticleRemoveAttachmentArgs = {
  articleId: Scalars['ID']['input'];
  input: ArticleRemoveAttachmentInput;
};


export type MutationArticleTranslationCreateArgs = {
  articleId: Scalars['ID']['input'];
  input: ArticleTranslationCreateInput;
};


export type MutationArticleTranslationDeleteArgs = {
  articleTranslationId: Scalars['ID']['input'];
};


export type MutationArticleTranslationUpdateArgs = {
  articleTranslationId: Scalars['ID']['input'];
  input: ArticleTranslationUpdateInput;
};


export type MutationArticleUnarchiveArgs = {
  articleId: Scalars['ID']['input'];
};


export type MutationArticleUpdateArgs = {
  articleId: Scalars['ID']['input'];
  input: ArticleUpdateInput;
};


export type MutationAuthenticationForceResetPasswordArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationAuthenticationGeneratePasswordResetTokenArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationAuthenticationPasswordUpdateArgs = {
  input: AuthenticationPasswordUpdateInput;
};


export type MutationAuthenticationSessionTokenCreateArgs = {
  redirectTo?: InputMaybe<Scalars['String']['input']>;
};


export type MutationBeneficiaryAcceptArgs = {
  beneficiaryId: Scalars['ID']['input'];
};


export type MutationBeneficiaryCreateArgs = {
  input: BeneficiaryCreateInput;
  userId: Scalars['ID']['input'];
};


export type MutationBeneficiaryDeleteArgs = {
  beneficiaryId: Scalars['ID']['input'];
};


export type MutationBeneficiaryFeatureDisableArgs = {
  companyId: Scalars['ID']['input'];
};


export type MutationBeneficiaryFeatureDisableChildrenUpdateArgs = {
  companyId: Scalars['ID']['input'];
};


export type MutationBeneficiaryFeatureDisablePartnerUpdateArgs = {
  companyId: Scalars['ID']['input'];
};


export type MutationBeneficiaryFeatureEnableArgs = {
  companyId: Scalars['ID']['input'];
};


export type MutationBeneficiaryFeatureEnableChildrenUpdateArgs = {
  companyId: Scalars['ID']['input'];
};


export type MutationBeneficiaryFeatureEnablePartnerUpdateArgs = {
  companyId: Scalars['ID']['input'];
};


export type MutationBeneficiaryProofAddArgs = {
  beneficiaryId: Scalars['ID']['input'];
  input: BeneficiaryProofAddInput;
};


export type MutationBeneficiaryProofRemoveArgs = {
  beneficiaryId: Scalars['ID']['input'];
  input: BeneficiaryProofRemoveInput;
};


export type MutationBeneficiaryRejectArgs = {
  beneficiaryId: Scalars['ID']['input'];
  input: BeneficiaryRejectInput;
};


export type MutationBeneficiaryRejectFollowUpArgs = {
  beneficiaryId: Scalars['ID']['input'];
};


export type MutationBeneficiaryUpdateArgs = {
  beneficiaryId: Scalars['ID']['input'];
  input: BeneficiaryUpdateInput;
};


export type MutationBrandCreateArgs = {
  input: BrandCreateInput;
};


export type MutationBrandMergeDuplicateArgs = {
  input: BrandMergeDuplicateInput;
};


export type MutationBrandTranslationCreateArgs = {
  brandId: Scalars['ID']['input'];
  input: BrandTranslationCreateInput;
  localeId: Scalars['ID']['input'];
};


export type MutationBrandTranslationDeleteArgs = {
  brandId: Scalars['ID']['input'];
  localeId: Scalars['ID']['input'];
};


export type MutationBrandTranslationGenerateArgs = {
  brandId: Scalars['ID']['input'];
  localeId: Scalars['ID']['input'];
};


export type MutationBrandTranslationUpdateArgs = {
  brandId: Scalars['ID']['input'];
  input: BrandTranslationUpdateInput;
  localeId: Scalars['ID']['input'];
};


export type MutationBrandUpdateArgs = {
  brandId: Scalars['ID']['input'];
  input: BrandUpdateInput;
};


export type MutationCampaignCreateArgs = {
  input: CampaignCreateInput;
};


export type MutationCampaignDeleteArgs = {
  campaignId: Scalars['ID']['input'];
};


export type MutationCampaignDemoteArgs = {
  campaignId: Scalars['ID']['input'];
  companyId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationCampaignHighlightReorderArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  input: CampaignHighlightReorderInput;
};


export type MutationCampaignPromoteArgs = {
  campaignId: Scalars['ID']['input'];
  companyId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationCampaignPublishArgs = {
  campaignId: Scalars['ID']['input'];
};


export type MutationCampaignSelectionCampaignsAddArgs = {
  campaignIds: Array<Scalars['ID']['input']>;
  campaignSelectionId: Scalars['ID']['input'];
};


export type MutationCampaignSelectionCampaignsRemoveArgs = {
  campaignIds: Array<Scalars['ID']['input']>;
  campaignSelectionId: Scalars['ID']['input'];
};


export type MutationCampaignSelectionCreateArgs = {
  input: CampaignSelectionCreateInput;
};


export type MutationCampaignSelectionDeleteArgs = {
  campaignSelectionId: Scalars['ID']['input'];
};


export type MutationCampaignSelectionPublishArgs = {
  campaignSelectionId: Scalars['ID']['input'];
};


export type MutationCampaignSelectionTranslationCreateArgs = {
  campaignSelectionId: Scalars['ID']['input'];
  input: CampaignSelectionTranslationCreateInput;
  localeId: Scalars['ID']['input'];
};


export type MutationCampaignSelectionTranslationDeleteArgs = {
  campaignSelectionId: Scalars['ID']['input'];
  localeId: Scalars['ID']['input'];
};


export type MutationCampaignSelectionTranslationUpdateArgs = {
  campaignSelectionId: Scalars['ID']['input'];
  input: CampaignSelectionTranslationUpdateInput;
  localeId: Scalars['ID']['input'];
};


export type MutationCampaignSelectionUnpublishArgs = {
  campaignSelectionId: Scalars['ID']['input'];
};


export type MutationCampaignSelectionUpdateArgs = {
  campaignSelectionId: Scalars['ID']['input'];
  input: CampaignSelectionUpdateInput;
};


export type MutationCampaignTranslationCreateArgs = {
  campaignId: Scalars['ID']['input'];
  input: CampaignTranslationCreateInput;
  localeId: Scalars['ID']['input'];
};


export type MutationCampaignTranslationDeleteArgs = {
  campaignId: Scalars['ID']['input'];
  localeId: Scalars['ID']['input'];
};


export type MutationCampaignTranslationGenerateArgs = {
  campaignId: Scalars['ID']['input'];
  localeId: Scalars['ID']['input'];
};


export type MutationCampaignTranslationUpdateArgs = {
  campaignId: Scalars['ID']['input'];
  input: CampaignTranslationUpdateInput;
  localeId: Scalars['ID']['input'];
};


export type MutationCampaignUnpublishArgs = {
  campaignId: Scalars['ID']['input'];
};


export type MutationCampaignUpdateArgs = {
  campaignId: Scalars['ID']['input'];
  input: CampaignUpdateInput;
};


export type MutationCartCheckoutArgs = {
  cartId: Scalars['ID']['input'];
};


export type MutationCartEmptyArgs = {
  cartId: Scalars['ID']['input'];
};


export type MutationCartItemCreateArgs = {
  cartId: Scalars['ID']['input'];
  discountSubventionId?: InputMaybe<Scalars['ID']['input']>;
  input: CartItemCreateInput;
  productVariantId: Scalars['ID']['input'];
};


export type MutationCartItemDeleteArgs = {
  cartId: Scalars['ID']['input'];
  cartItemId: Scalars['ID']['input'];
};


export type MutationCartItemUpdateArgs = {
  cartId: Scalars['ID']['input'];
  cartItemId: Scalars['ID']['input'];
  input: CartItemUpdateInput;
};


export type MutationCartSubventionDisableArgs = {
  cartId: Scalars['ID']['input'];
  input: CartFundSubventionEnableInput;
};


export type MutationCartSubventionEnableArgs = {
  cartId: Scalars['ID']['input'];
  input: CartFundSubventionEnableInput;
};


export type MutationCartSubventionsDisableArgs = {
  cartId: Scalars['ID']['input'];
};


export type MutationCartSubventionsEnableArgs = {
  cartId: Scalars['ID']['input'];
};


export type MutationCatalogComputeArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationCatalogCreateArgs = {
  companyId: Scalars['ID']['input'];
  input: CatalogCreateInput;
};


export type MutationCatalogDeleteArgs = {
  catalogId: Scalars['ID']['input'];
};


export type MutationCategoryCreateArgs = {
  input: CategoryCreateInput;
};


export type MutationCategoryDeleteArgs = {
  categoryId: Scalars['ID']['input'];
};


export type MutationCategoryTranslationCreateArgs = {
  categoryId: Scalars['ID']['input'];
  input: CategoryTranslationCreateInput;
  localeId: Scalars['ID']['input'];
};


export type MutationCategoryTranslationDeleteArgs = {
  categoryId: Scalars['ID']['input'];
  localeId: Scalars['ID']['input'];
};


export type MutationCategoryTranslationUpdateArgs = {
  categoryId: Scalars['ID']['input'];
  input: CategoryTranslationUpdateInput;
  localeId: Scalars['ID']['input'];
};


export type MutationCategoryUpdateArgs = {
  categoryId: Scalars['ID']['input'];
  input: CategoryUpdateInput;
};


export type MutationCohortCreateArgs = {
  input: CohortCreateInput;
};


export type MutationCohortDeleteArgs = {
  cohortId: Scalars['ID']['input'];
  cohortTargetType: CohortTarget;
};


export type MutationCohortRefreshArgs = {
  cohortId: Scalars['ID']['input'];
};


export type MutationCohortUpdateArgs = {
  cohortId: Scalars['ID']['input'];
  cohortTargetType: CohortTarget;
  input: CohortUpdateInput;
};


export type MutationCompanyArticleExcludeAddArgs = {
  companyId: Scalars['ID']['input'];
  input: CompanyArticleExcludeInput;
};


export type MutationCompanyArticleExcludeRemoveArgs = {
  companyId: Scalars['ID']['input'];
  input: CompanyArticleExcludeInput;
};


export type MutationCompanyArticleIncludeAddArgs = {
  companyId: Scalars['ID']['input'];
  input: CompanyArticleIncludeActionInput;
};


export type MutationCompanyArticleIncludeRemoveArgs = {
  companyId: Scalars['ID']['input'];
  input: CompanyArticleIncludeActionInput;
};


export type MutationCompanyCampaignSelectionExcludeAddArgs = {
  companyId: Scalars['ID']['input'];
  input: CompanyCampaignSelectionExcludeInput;
};


export type MutationCompanyCampaignSelectionExcludeRemoveArgs = {
  companyId: Scalars['ID']['input'];
  input: CompanyCampaignSelectionExcludeInput;
};


export type MutationCompanyCampaignSelectionIncludeAddArgs = {
  companyId: Scalars['ID']['input'];
  input: CompanyCampaignSelectionIncludeActionInput;
};


export type MutationCompanyCampaignSelectionIncludeRemoveArgs = {
  companyId: Scalars['ID']['input'];
  input: CompanyCampaignSelectionIncludeActionInput;
};


export type MutationCompanyCreateArgs = {
  input: CompanyCreateInput;
};


export type MutationCompanyCustomFieldCreateArgs = {
  companyId: Scalars['ID']['input'];
  input: CompanyCustomFieldCreateInput;
};


export type MutationCompanyCustomFieldDeleteArgs = {
  companyCustomFieldId: Scalars['ID']['input'];
};


export type MutationCompanyCustomFieldUpdateArgs = {
  companyCustomFieldId: Scalars['ID']['input'];
  input: CompanyCustomFieldUpdateInput;
};


export type MutationCompanyDocumentCreateArgs = {
  input: CompanyDocumentCreateInput;
};


export type MutationCompanyDocumentDeleteArgs = {
  companyDocumentId: Scalars['ID']['input'];
};


export type MutationCompanyDocumentPinArgs = {
  companyDocumentId: Scalars['ID']['input'];
};


export type MutationCompanyDocumentUnpinArgs = {
  companyDocumentId: Scalars['ID']['input'];
};


export type MutationCompanyDocumentUpdateArgs = {
  companyDocumentId: Scalars['ID']['input'];
  input: CompanyDocumentUpdateInput;
};


export type MutationCompanyDriveCreateArgs = {
  companyId: Scalars['ID']['input'];
  input: CompanyDriveCreateInput;
};


export type MutationCompanyFeatureDisableArgs = {
  companyId: Scalars['ID']['input'];
  input: CompanyFeatureDisableInput;
};


export type MutationCompanyFeatureEnableArgs = {
  companyId: Scalars['ID']['input'];
  input: CompanyFeatureEnableInput;
};


export type MutationCompanyFeesCreateArgs = {
  companyId: Scalars['ID']['input'];
  input: CompanyFeesCreateInput;
};


export type MutationCompanyFeesDeleteArgs = {
  companyFeesId: Scalars['ID']['input'];
};


export type MutationCompanyFeesUpdateArgs = {
  companyFeesId: Scalars['ID']['input'];
  input: CompanyFeesUpdateInput;
};


export type MutationCompanyFolderCompressAndExportArgs = {
  input: CompanyFolderCompressAndExportInput;
};


export type MutationCompanyFolderCreateArgs = {
  input: CompanyFolderCreateInput;
};


export type MutationCompanyFolderDeleteArgs = {
  companyFolderId: Scalars['ID']['input'];
};


export type MutationCompanyFolderUpdateArgs = {
  companyFolderId: Scalars['ID']['input'];
  input: CompanyFolderUpdateInput;
};


export type MutationCompanyMembersDryRunArgs = {
  companyId: Scalars['ID']['input'];
  input: CompanyMembersImportDryRunInput;
};


export type MutationCompanyMembersImportArgs = {
  companyId: Scalars['ID']['input'];
  input: CompanyMembersImportInput;
};


export type MutationCompanyMembershipCreateArgs = {
  companyId: Scalars['ID']['input'];
  input: CompanyMembershipCreateInput;
};


export type MutationCompanyMembershipUpdateArgs = {
  companyMembershipId: Scalars['ID']['input'];
  input: CompanyMembershipUpdateInput;
};


export type MutationCompanyStripeConnectedAccountCreateArgs = {
  companyId: Scalars['ID']['input'];
};


export type MutationCompanyStripeConnectedAccountLinkCreateArgs = {
  companyId: Scalars['ID']['input'];
};


export type MutationCompanySubsidiaryImportUsersArgs = {
  companyId: Scalars['ID']['input'];
  input: CompanySubsidiaryImportInput;
};


export type MutationCompanySubsidiaryImportUsersDryArgs = {
  companyId: Scalars['ID']['input'];
  input: CompanySubsidiaryImportInput;
};


export type MutationCompanyUpdateArgs = {
  companyId: Scalars['ID']['input'];
  input: CompanyUpdateInput;
};


export type MutationComputeSubventionCatalogArgs = {
  subventionId: Scalars['String']['input'];
};


export type MutationComputeSubventionCatalogSeqArgs = {
  forceAll?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCouponClaimableClaimArgs = {
  offerId: Scalars['String']['input'];
};


export type MutationCouponClaimableDeleteArgs = {
  couponClaimableId: Scalars['ID']['input'];
};


export type MutationCouponClaimableUpdateArgs = {
  couponClaimableId: Scalars['ID']['input'];
  input: CouponClaimableUpdateInput;
};


export type MutationCouponClaimablesCreateArgs = {
  input: CouponClaimablesCreateInput;
  offerId: Scalars['ID']['input'];
};


export type MutationCouponPublicUpdateArgs = {
  couponId: Scalars['ID']['input'];
  input: CouponPublicUpdateInput;
};


export type MutationDiscountCreateArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  input: DiscountCreateInput;
  productId: Scalars['ID']['input'];
};


export type MutationDiscountDeleteArgs = {
  discountId: Scalars['ID']['input'];
};


export type MutationDiscoverCreateArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  input: DiscoverCreateInput;
};


export type MutationDiscoverDeleteArgs = {
  discoverId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationDiscoverUpdatePositionArgs = {
  discoverId?: InputMaybe<Scalars['ID']['input']>;
  input: DiscoverUpdatePositionInput;
};


export type MutationFavoriteCampaignCreateArgs = {
  campaignId: Scalars['ID']['input'];
};


export type MutationFavoriteCampaignToggleArgs = {
  campaignId: Scalars['ID']['input'];
};


export type MutationFavoriteDeleteArgs = {
  favoriteId: Scalars['ID']['input'];
};


export type MutationFileCreateArgs = {
  input: FileCreateInput;
};


export type MutationGameCompanyBlacklistAddArgs = {
  gameId: Scalars['ID']['input'];
  input: GameCompanyBlacklistInput;
};


export type MutationGameCompanyBlacklistRemoveArgs = {
  gameId: Scalars['ID']['input'];
  input: GameCompanyBlacklistInput;
};


export type MutationGameCompanyWhitelistAddArgs = {
  gameId: Scalars['ID']['input'];
  input: GameCompanyWhitelistInput;
};


export type MutationGameCompanyWhitelistRemoveArgs = {
  gameId: Scalars['ID']['input'];
  input: GameCompanyWhitelistInput;
};


export type MutationGameCreateArgs = {
  input: GameCreateInput;
};


export type MutationGameDeleteArgs = {
  gameId: Scalars['ID']['input'];
};


export type MutationGameUpdateArgs = {
  gameId: Scalars['ID']['input'];
  input: GameUpdateInput;
};


export type MutationGatheringAccessCreateArgs = {
  gatheringId: Scalars['ID']['input'];
  input: GatheringAccessCreateInput;
};


export type MutationGatheringAccessRemoveManuallyInvitedArgs = {
  gatheringId: Scalars['ID']['input'];
  userIdsToRemove: Array<Scalars['ID']['input']>;
};


export type MutationGatheringAccessUpdateArgs = {
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  gatheringId: Scalars['ID']['input'];
  input: GatheringAccessUpdateInput;
};


export type MutationGatheringCancelArgs = {
  gatheringId: Scalars['ID']['input'];
};


export type MutationGatheringCreateArgs = {
  companyId: Scalars['ID']['input'];
  input: GatheringCreateInput;
};


export type MutationGatheringDeleteArgs = {
  gatheringId: Scalars['ID']['input'];
};


export type MutationGatheringFeatureDisableArgs = {
  companyId: Scalars['ID']['input'];
};


export type MutationGatheringFeatureEnableArgs = {
  companyId: Scalars['ID']['input'];
};


export type MutationGatheringPublishArgs = {
  gatheringId: Scalars['ID']['input'];
};


export type MutationGatheringRegistrationCancelArgs = {
  gatheringId: Scalars['ID']['input'];
  registeredUserId: Scalars['ID']['input'];
};


export type MutationGatheringSessionCancelArgs = {
  gatheringSessionId: Scalars['ID']['input'];
};


export type MutationGatheringSessionPricesCreateArgs = {
  gatheringSessionId: Scalars['ID']['input'];
  inputs: Array<GatheringSessionPriceCreateInput>;
};


export type MutationGatheringSessionRegistrationCancelArgs = {
  gatheringSessionId: Scalars['ID']['input'];
  registeredUserId: Scalars['ID']['input'];
};


export type MutationGatheringSessionRegistrationCheckoutArgs = {
  sessionRegistrationId: Scalars['ID']['input'];
};


export type MutationGatheringSessionRegistrationCreateArgs = {
  gatheringSessionId: Scalars['ID']['input'];
  input: GatheringSessionRegistrationCreateInput;
};


export type MutationGatheringSessionTicketDeleteArgs = {
  gatheringSessionId: Scalars['ID']['input'];
  input: Array<GatheringSessionTicketDeleteInput>;
};


export type MutationGatheringSessionUpdateArgs = {
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  gatheringSessionId: Scalars['ID']['input'];
  input?: InputMaybe<GatheringSessionUpdateInput>;
};


export type MutationGatheringSessionsCreateArgs = {
  gatheringId: Scalars['ID']['input'];
  inputs: Array<GatheringSessionCreateInput>;
};


export type MutationGatheringUpdateArgs = {
  gatheringId: Scalars['ID']['input'];
  input: GatheringUpdateInput;
};


export type MutationHappeningAddAttachmentsArgs = {
  happeningId: Scalars['ID']['input'];
  input: HappeningAddAttachmentsInput;
};


export type MutationHappeningCancelArgs = {
  happeningId: Scalars['ID']['input'];
};


export type MutationHappeningConfirmFundsTransferedArgs = {
  happeningId: Scalars['ID']['input'];
};


export type MutationHappeningCreateArgs = {
  companyId: Scalars['ID']['input'];
  input: HappeningCreateInput;
};


export type MutationHappeningRegistrationCancelArgs = {
  happeningRegistrationId: Scalars['ID']['input'];
};


export type MutationHappeningRegistrationCreateArgs = {
  happeningId: Scalars['ID']['input'];
  input: HappeningRegistrationCreateInput;
};


export type MutationHappeningRemoveAttachmentsArgs = {
  happeningId: Scalars['ID']['input'];
  input: HappeningRemoveAttachmentsInput;
};


export type MutationHappeningUpdateArgs = {
  happeningId: Scalars['ID']['input'];
  input: HappeningUpdateInput;
};


export type MutationHighlightCreateArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  input: HighlightCreateInput;
};


export type MutationHighlightDeleteArgs = {
  highlightId?: InputMaybe<Scalars['ID']['input']>;
  highlightableId?: InputMaybe<Scalars['ID']['input']>;
  highlightableType?: InputMaybe<HighlightTargetType>;
};


export type MutationHighlightPositionUpdateArgs = {
  highlightId: Scalars['ID']['input'];
  input: HighlightPositionUpdateInput;
};


export type MutationHomeCustomisationUpdateMessageArgs = {
  companyId: Scalars['ID']['input'];
  input: HomeCustomisationUpdateMessageInput;
};


export type MutationInvitationCreateArgs = {
  input: InvitationCreateInput;
};


export type MutationInvitationCreateBulkArgs = {
  input: InvitationCreateBulkInput;
};


export type MutationInvitationCreateCompanyArgs = {
  companyId: Scalars['ID']['input'];
};


export type MutationInvitationEmailSendBulkArgs = {
  companyId: Scalars['ID']['input'];
  input: InvitationEmailSendBulkInput;
};


export type MutationInvitationLinkGenerateBulkArgs = {
  companyId: Scalars['ID']['input'];
  input: InvitationLinkGenerateBulkInput;
};


export type MutationInvitationRenewArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationInvitationRevokeArgs = {
  invitationId: Scalars['ID']['input'];
};


export type MutationInvitationSendArgs = {
  invitationId: Scalars['ID']['input'];
};


export type MutationInvitationSendBulkArgs = {
  invitationIds: Array<Scalars['ID']['input']>;
};


export type MutationInvitationSendCompanyArgs = {
  companyId: Scalars['ID']['input'];
  input: InvitationSendCompanyInput;
};


export type MutationMangopayBankAccountCreateArgs = {
  input: MangopayBankAccountCreateInput;
  userId: Scalars['ID']['input'];
};


export type MutationMangopayBankAccountDeactivateArgs = {
  mangopayBankAccountId: Scalars['ID']['input'];
};


export type MutationMangopayBankAccountUpdateArgs = {
  input: MangopayBankAccountUpdateInput;
  mangopayBankAccountId: Scalars['ID']['input'];
};


export type MutationMangopayBankingAliasCreateArgs = {
  walletId: Scalars['ID']['input'];
};


export type MutationMangopayBankwireDirectPayInCreateArgs = {
  input: MangopayBankwireDirectPayInCreateInput;
  mangopayUserId: Scalars['ID']['input'];
};


export type MutationMangopayBankwireDirectPayInRefreshArgs = {
  mangopayBankwireDirectPayInId: Scalars['ID']['input'];
};


export type MutationMangopayBankwirePayOutCreateForSubventionReimbursementArgs = {
  subventionReimbursementRequestId: Scalars['ID']['input'];
};


export type MutationMangopayBankwirePayOutRetryArgs = {
  mangopayBankwirePayOutId: Scalars['ID']['input'];
};


export type MutationMangopayKycDocumentCreateArgs = {
  input: MangopayKycDocumentCreateInput;
  mangopayUserId: Scalars['ID']['input'];
};


export type MutationMangopayKycDocumentCreateForUserArgs = {
  input: MangopayKycDocumentCreateInput;
  userId: Scalars['ID']['input'];
};


export type MutationMangopayLegalUserCreateArgs = {
  companyId: Scalars['ID']['input'];
  input: MangopayLegalUserCreateInput;
};


export type MutationMangopayNaturalUserCreateArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationMangopayTransferFromCompanyWalletToCentralWalletCreateArgs = {
  companyId: Scalars['ID']['input'];
  input: MangopayTransferToCentralWalletCreateInput;
};


export type MutationMangopayTransferRetryArgs = {
  mangopayTransferId: Scalars['ID']['input'];
};


export type MutationMangopayUserSynchronizationArgs = {
  chunkSize?: InputMaybe<Scalars['Int']['input']>;
  companyId: Scalars['ID']['input'];
};


export type MutationMigrateBrandsArgs = {
  input?: InputMaybe<MigrateBrandsInput>;
};


export type MutationMigrateCompanyArgs = {
  input: AppdragMigrationSynchronizationCreateInput;
};


export type MutationMigrateCompanyDeleteArgs = {
  input: AppdragMigrationCompanyDeleteInput;
};


export type MutationNetPromoterScoreCreateArgs = {
  campaignId: Scalars['ID']['input'];
  input: NetPromoterScoreCreateInput;
};


export type MutationNetPromoterScoreDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationNetPromoterScoreUpdateArgs = {
  id: Scalars['ID']['input'];
  input: NetPromoterScoreUpdateInput;
};


export type MutationNewsletterCreateArgs = {
  companyId: Scalars['ID']['input'];
  input: NewsletterCreateInput;
};


export type MutationNewsletterDeleteArgs = {
  newsletterId: Scalars['ID']['input'];
};


export type MutationNewsletterDuplicateArgs = {
  newsletterId: Scalars['ID']['input'];
};


export type MutationNewsletterSendArgs = {
  newsletterId: Scalars['ID']['input'];
};


export type MutationNewsletterSendTestArgs = {
  email: Scalars['String']['input'];
  newsletterId: Scalars['ID']['input'];
};


export type MutationNewsletterUpdateArgs = {
  input: NewsletterUpdateInput;
  newsletterId: Scalars['ID']['input'];
};


export type MutationNotificationCenterRetryArgs = {
  notificationRequestId: Scalars['ID']['input'];
};


export type MutationOfferAclComputeArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationOfferBaseCreateArgs = {
  input: OfferBaseCreateInput;
};


export type MutationOfferCouponClaimableCreateArgs = {
  input: OfferCouponClaimableCreateInput;
};


export type MutationOfferCouponClaimableUpdateArgs = {
  input: OfferCouponClaimableUpdateInput;
  offerId: Scalars['ID']['input'];
};


export type MutationOfferCouponPublicCreateArgs = {
  input: OfferCouponPublicCreateInput;
};


export type MutationOfferGiftCardCreateArgs = {
  input: OfferGiftCardCreateInput;
};


export type MutationOfferPublishArgs = {
  offerId: Scalars['ID']['input'];
};


export type MutationOfferTicketCreateArgs = {
  input: OfferTicketCreateInput;
};


export type MutationOfferTranslationCreateArgs = {
  input: OfferTranslationCreateInput;
  localeId: Scalars['ID']['input'];
  offerId: Scalars['ID']['input'];
};


export type MutationOfferTranslationDeleteArgs = {
  localeId: Scalars['ID']['input'];
  offerId: Scalars['ID']['input'];
};


export type MutationOfferTranslationGenerateArgs = {
  localeId: Scalars['ID']['input'];
  offerId: Scalars['ID']['input'];
};


export type MutationOfferTranslationUpdateArgs = {
  input: OfferTranslationUpdateInput;
  localeId: Scalars['ID']['input'];
  offerId: Scalars['ID']['input'];
};


export type MutationOfferUnpublishArgs = {
  offerId: Scalars['ID']['input'];
};


export type MutationOfferUpdateArgs = {
  input: OfferUpdateInput;
  offerId: Scalars['ID']['input'];
};


export type MutationOfferUrlCreateArgs = {
  input: OfferUrlCreateInput;
};


export type MutationOrderInvoiceGenerateArgs = {
  orderId: Scalars['ID']['input'];
};


export type MutationOrderItemArticlesAddArgs = {
  input: OrderItemArticlesAddInput;
  orderItemId: Scalars['ID']['input'];
};


export type MutationOrderItemArticlesRemoveArgs = {
  input: OrderItemArticlesRemoveInput;
  orderItemId: Scalars['ID']['input'];
};


export type MutationOrderItemStatusUpdateArgs = {
  orderItemId: Scalars['ID']['input'];
  status: OrderItemStatus;
};


export type MutationOrderRefundCreateArgs = {
  input: OrderRefundCreateInput;
  orderId: Scalars['ID']['input'];
};


export type MutationOrderRefundProcessArgs = {
  orderRefundId: Scalars['ID']['input'];
};


export type MutationOrderRefundRejectArgs = {
  orderRefundId: Scalars['ID']['input'];
};


export type MutationOrderResendOrderConfirmationEmailArgs = {
  orderId: Scalars['ID']['input'];
};


export type MutationPaymentLimitCreateArgs = {
  input: PaymentLimitCreateInput;
};


export type MutationPaymentLimitDeleteArgs = {
  paymentLimitId: Scalars['ID']['input'];
};


export type MutationPictureCreateArgs = {
  input: PictureCreateInput;
};


export type MutationPictureStockCreateArgs = {
  pictureId: Scalars['ID']['input'];
};


export type MutationPictureStockDeleteArgs = {
  pictureStockId: Scalars['ID']['input'];
};


export type MutationPointOfContactCreateArgs = {
  companyId: Scalars['ID']['input'];
  input: PointOfContactCreateInput;
};


export type MutationPointOfContactDeleteArgs = {
  pointOfContactId: Scalars['ID']['input'];
};


export type MutationPointOfContactSetPositionArgs = {
  input: PointOfContactSetPositionInput;
  pointOfContactId: Scalars['ID']['input'];
};


export type MutationPointOfContactUpdateArgs = {
  input: PointOfContactUpdateInput;
  pointOfContactId: Scalars['ID']['input'];
};


export type MutationProductArticleBulkCreateArgs = {
  input: ProductArticleCreateBulkInput;
  productVariantId: Scalars['ID']['input'];
};


export type MutationProductArticleCreateArgs = {
  input: ProductArticleCreateInput;
  productVariantId: Scalars['ID']['input'];
};


export type MutationProductArticleDeleteArgs = {
  productArticleId: Scalars['ID']['input'];
};


export type MutationProductCatalogCreateArgs = {
  input: ProductCatalogCreateInput;
  providerId: Scalars['ID']['input'];
};


export type MutationProductCreateArgs = {
  brandId: Scalars['ID']['input'];
  catalogId: Scalars['ID']['input'];
  input: ProductCreateInput;
};


export type MutationProductOrderRetryArgs = {
  productOrderId: Scalars['ID']['input'];
};


export type MutationProductSynchonizationCreateArgs = {
  input: ProductSynchronizationCreateInput;
};


export type MutationProductTranslationCreateArgs = {
  input: ProductTranslationCreateInput;
  productId: Scalars['ID']['input'];
};


export type MutationProductTranslationDeleteArgs = {
  productTranslationId: Scalars['ID']['input'];
};


export type MutationProductTranslationUpdateArgs = {
  input: ProductTranslationUpdateInput;
  productTranslationId: Scalars['ID']['input'];
};


export type MutationProductUpdateArgs = {
  input: ProductUpdateInput;
  productId: Scalars['ID']['input'];
};


export type MutationProductVariantActivateArgs = {
  productVariantId: Scalars['ID']['input'];
};


export type MutationProductVariantCreateArgs = {
  input: ProductVariantCreateInput;
  productId: Scalars['ID']['input'];
};


export type MutationProductVariantDeactivateArgs = {
  productVariantId: Scalars['ID']['input'];
};


export type MutationProductVariantStockUpdateArgs = {
  input: ProductVariantStockUpdateInput;
  productVariantId: Scalars['ID']['input'];
};


export type MutationPublicationAddAttachmentArgs = {
  input: PublicationAddAttachmentInput;
  publicationId: Scalars['ID']['input'];
};


export type MutationPublicationCreateArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  input: PublicationCreateInput;
  targetId?: InputMaybe<Scalars['ID']['input']>;
  targetType?: InputMaybe<PublicationTargetType>;
};


export type MutationPublicationDeleteArgs = {
  publicationId: Scalars['ID']['input'];
};


export type MutationPublicationPublishArgs = {
  publicationId: Scalars['ID']['input'];
};


export type MutationPublicationRemoveAttachmentArgs = {
  input: PublicationRemoveAttachmentInput;
  publicationId: Scalars['ID']['input'];
};


export type MutationPublicationUnpublishArgs = {
  publicationId: Scalars['ID']['input'];
};


export type MutationPublicationUpdateArgs = {
  input: PublicationUpdateInput;
  publicationId: Scalars['ID']['input'];
};


export type MutationPushTokenDeleteArgs = {
  input: PushTokenDeleteInput;
};


export type MutationPushTokenUpsertArgs = {
  input: PushTokenUpsertInput;
};


export type MutationQuestionCategoryCreateArgs = {
  companyId: Scalars['ID']['input'];
  input: QuestionCategoryCreateInput;
};


export type MutationQuestionCategoryDeleteArgs = {
  questionCategoryId: Scalars['ID']['input'];
};


export type MutationQuestionCategoryUpdateArgs = {
  input: QuestionCategoryUpdateInput;
  questionCategoryId: Scalars['ID']['input'];
};


export type MutationQuestionCreateArgs = {
  input: QuestionCreateInput;
};


export type MutationQuestionDeleteArgs = {
  questionId: Scalars['ID']['input'];
};


export type MutationQuestionUpdateArgs = {
  input: QuestionUpdateInput;
  questionId: Scalars['ID']['input'];
};


export type MutationRemoveCatalogBrandExcludeArgs = {
  catalogId: Scalars['ID']['input'];
  input: CatalogBrandUpdateInput;
};


export type MutationRemoveCatalogBrandIncludeArgs = {
  catalogId: Scalars['ID']['input'];
  input: CatalogBrandUpdateInput;
};


export type MutationRemoveCatalogCampaignExcludeArgs = {
  catalogId: Scalars['ID']['input'];
  input: CatalogCampaignUpdateInput;
};


export type MutationRemoveCatalogCampaignIncludeArgs = {
  catalogId: Scalars['ID']['input'];
  input: CatalogCampaignUpdateInput;
};


export type MutationRemoveCatalogCategoryExcludeArgs = {
  catalogId: Scalars['ID']['input'];
  input: CatalogCategoryUpdateInput;
};


export type MutationRemoveCatalogOfferExcludeArgs = {
  catalogId: Scalars['ID']['input'];
  input: CatalogOfferUpdateInput;
};


export type MutationRemoveCatalogOfferIncludeArgs = {
  catalogId: Scalars['ID']['input'];
  input: CatalogOfferUpdateInput;
};


export type MutationRemoveIpV4InIpSetArgs = {
  ipv4: Scalars['String']['input'];
};


export type MutationRemoveIpV6InIpSetArgs = {
  ipv6: Scalars['String']['input'];
};


export type MutationSearchSynchonizationCreateArgs = {
  input: SearchSynchronizationCreateInput;
};


export type MutationSendgridCompanySubuserCreateArgs = {
  companyId: Scalars['ID']['input'];
  input: SendgridCompanySubuserCreateInput;
};


export type MutationSessionRevokeArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationSorewardsUserCreateArgs = {
  input: SorewardsUserCreateInput;
  userId: Scalars['ID']['input'];
};


export type MutationSorewardsUserGenerateLoginUrlArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationSsoProviderOidcCreateArgs = {
  companyId: Scalars['ID']['input'];
  input: SsoProviderOidcCreateInput;
};


export type MutationSsoProviderSamlCreateArgs = {
  companyId: Scalars['ID']['input'];
  input: SsoProviderSamlCreateInput;
};


export type MutationStoreCreateArgs = {
  brandId: Scalars['ID']['input'];
  input: StoreCreateInput;
};


export type MutationStoreDeleteArgs = {
  storeId: Scalars['ID']['input'];
};


export type MutationStoreUpdateArgs = {
  input: StoreUpdateInput;
  storeId: Scalars['ID']['input'];
};


export type MutationStoresAddOffersArgs = {
  input: StoresAddOffersInput;
};


export type MutationStoresRemoveOffersArgs = {
  input: StoresRemoveOffersInput;
};


export type MutationSubventionAbortArgs = {
  subventionId: Scalars['ID']['input'];
};


export type MutationSubventionCompleteArgs = {
  subventionId: Scalars['ID']['input'];
};


export type MutationSubventionConfirmArgs = {
  input: SubventionConfirmInput;
  subventionId: Scalars['ID']['input'];
};


export type MutationSubventionCreateArgs = {
  companyId: Scalars['ID']['input'];
  input: SubventionCreateInput;
};


export type MutationSubventionDeleteArgs = {
  subventionId: Scalars['ID']['input'];
};


export type MutationSubventionDuplicateArgs = {
  subventionId: Scalars['ID']['input'];
};


export type MutationSubventionExtendArgs = {
  input: SubventionExtendInput;
  subventionId: Scalars['ID']['input'];
};


export type MutationSubventionGranteeAddArgs = {
  subventionId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationSubventionGranteesRecomputeArgs = {
  subventionId: Scalars['ID']['input'];
};


export type MutationSubventionInvoiceCreateMonthlyForAllCompaniesArgs = {
  isAdminUsersNotified?: InputMaybe<Scalars['Boolean']['input']>;
  start: Scalars['DateTime']['input'];
};


export type MutationSubventionLockArgs = {
  subventionId: Scalars['ID']['input'];
};


export type MutationSubventionPublishArgs = {
  subventionId: Scalars['ID']['input'];
};


export type MutationSubventionReimbursementRequestApproveArgs = {
  subventionReimbursementRequestId: Scalars['ID']['input'];
};


export type MutationSubventionReimbursementRequestCancelArgs = {
  subventionReimbursementRequestId: Scalars['ID']['input'];
};


export type MutationSubventionReimbursementRequestCreateArgs = {
  input: SubventionReimbursementRequestCreateInput;
  userId: Scalars['ID']['input'];
};


export type MutationSubventionReimbursementRequestRejectArgs = {
  input: SubventionReimbursementRequestRejectInput;
  subventionReimbursementRequestId: Scalars['ID']['input'];
};


export type MutationSubventionRenameArgs = {
  input: SubventionRenameInput;
  subventionId: Scalars['ID']['input'];
};


export type MutationSubventionScheduleArgs = {
  input: SubventionScheduleInput;
  subventionId: Scalars['ID']['input'];
};


export type MutationSubventionSchemaBlacklistCompaniesAddArgs = {
  input: SubventionSchemaBlacklistCompaniesAddInput;
  subventionSchemaId: Scalars['ID']['input'];
};


export type MutationSubventionSchemaBlacklistCompaniesRemoveArgs = {
  input: SubventionSchemaBlacklistCompaniesRemoveInput;
  subventionSchemaId: Scalars['ID']['input'];
};


export type MutationSubventionSchemaCreateArgs = {
  input: SubventionSchemaCreateInput;
};


export type MutationSubventionSchemaDeleteArgs = {
  subventionSchemaId: Scalars['ID']['input'];
};


export type MutationSubventionSchemaOffersAddArgs = {
  input: SubventionSchemaOffersAddInput;
  subventionSchemaId: Scalars['ID']['input'];
};


export type MutationSubventionSchemaOffersRemoveArgs = {
  input: SubventionSchemaOffersRemoveInput;
  subventionSchemaId: Scalars['ID']['input'];
};


export type MutationSubventionSchemaPublishArgs = {
  subventionSchemaId: Scalars['ID']['input'];
};


export type MutationSubventionSchemaRestoreArgs = {
  subventionSchemaId: Scalars['ID']['input'];
};


export type MutationSubventionSchemaUnpublishArgs = {
  subventionSchemaId: Scalars['ID']['input'];
};


export type MutationSubventionSchemaUpdateArgs = {
  input: SubventionSchemaUpdateInput;
  subventionSchemaId: Scalars['ID']['input'];
};


export type MutationSubventionSchemaWhitelistCompaniesAddArgs = {
  input: SubventionSchemaWhitelistCompaniesAddInput;
  subventionSchemaId: Scalars['ID']['input'];
};


export type MutationSubventionSchemaWhitelistCompaniesRemoveArgs = {
  input: SubventionSchemaWhitelistCompaniesRemoveInput;
  subventionSchemaId: Scalars['ID']['input'];
};


export type MutationSubventionUpdateArgs = {
  input: SubventionUpdateInput;
  subventionId: Scalars['ID']['input'];
};


export type MutationSubventionValidateArgs = {
  input: SubventionValidateInput;
  subventionId: Scalars['ID']['input'];
};


export type MutationSubventionValidateProvisionArgs = {
  subventionId: Scalars['ID']['input'];
};


export type MutationSurveyCreateArgs = {
  companyId: Scalars['ID']['input'];
  input: SurveyCreateInput;
};


export type MutationSurveyDeleteArgs = {
  surveyId: Scalars['ID']['input'];
};


export type MutationSurveyFeatureDisableArgs = {
  companyId: Scalars['ID']['input'];
};


export type MutationSurveyFeatureEnableArgs = {
  companyId: Scalars['ID']['input'];
};


export type MutationSurveyUpdateArgs = {
  input: SurveyUpdateInput;
  surveyId: Scalars['ID']['input'];
};


export type MutationTaxRateCreateArgs = {
  input: TaxRateCreateInput;
};


export type MutationTaxRateDeleteArgs = {
  taxRateId: Scalars['ID']['input'];
};


export type MutationTaxRateUpdateArgs = {
  input: TaxRateUpdateInput;
  taxRateId: Scalars['ID']['input'];
};


export type MutationTwoFactorCreateArgs = {
  input: TwoFactorCreateInput;
};


export type MutationTwoFactorDeleteArgs = {
  input: TwoFactorDeleteInput;
};


export type MutationTwoFactorEnableArgs = {
  input: TwoFactorEnableInput;
};


export type MutationTwoFactorSendArgs = {
  input: TwoFactorSendInput;
};


export type MutationUserBanArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationUserCreateArgs = {
  input: UserCreateInput;
};


export type MutationUserDeleteArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationUserIntegrationConnectorDeleteArgs = {
  companyId: Scalars['ID']['input'];
  connectorId: Scalars['ID']['input'];
};


export type MutationUserIntegrationConnectorLuccaAddArgs = {
  companyId: Scalars['ID']['input'];
  input: UserIntegrationLuccaCredentialsAddInput;
};


export type MutationUserIntegrationConnectorLuccaUpdateArgs = {
  companyId: Scalars['ID']['input'];
  connectorId: Scalars['ID']['input'];
  input: UserIntegrationLuccaCredentialsUpdateInput;
};


export type MutationUserIntegrationConnectorSalesforceRcuAddArgs = {
  companyId: Scalars['ID']['input'];
  input: UserIntegrationSalesforceRcuCredentialsAddInput;
};


export type MutationUserIntegrationConnectorSalesforceRcuUpdateArgs = {
  companyId: Scalars['ID']['input'];
  connectorId: Scalars['ID']['input'];
  input: UserIntegrationSalesforceRcuCredentialsUpdateInput;
};


export type MutationUserIntegrationConnectorSetFrequencyArgs = {
  companyId: Scalars['ID']['input'];
  connectorId: Scalars['ID']['input'];
  input: UserIntegrationConnectorSetFrequencyInput;
};


export type MutationUserIntegrationFeatureDisableArgs = {
  companyId: Scalars['ID']['input'];
};


export type MutationUserIntegrationFeatureEnableArgs = {
  companyId: Scalars['ID']['input'];
};


export type MutationUserIntegrationProviderDisableArgs = {
  companyId: Scalars['ID']['input'];
  input: UserIntegrationProviderDisableInput;
};


export type MutationUserIntegrationProviderEnableArgs = {
  companyId: Scalars['ID']['input'];
  input: UserIntegrationProviderEnableInput;
};


export type MutationUserIntegrationProviderUpdateArgs = {
  companyId: Scalars['ID']['input'];
  input: UserIntegrationProviderUpdateInput;
  providerName: UserIntegrationProviderName;
};


export type MutationUserIntegrationSynchronisationAbortJobArgs = {
  synchronisationId: Scalars['ID']['input'];
};


export type MutationUserIntegrationSynchronisationCreateArgs = {
  companyId: Scalars['ID']['input'];
  connectorId: Scalars['ID']['input'];
};


export type MutationUserPreferencesUpdateArgs = {
  input: UserPreferencesUpdateInput;
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationUserUnbanArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationUserUpdateArgs = {
  input: UserUpdateInput;
  userId: Scalars['ID']['input'];
};


export type MutationVoucherCreateArgs = {
  input: VoucherCreateInput;
  offerId: Scalars['ID']['input'];
};


export type MutationVoucherDeleteArgs = {
  voucherId: Scalars['ID']['input'];
};


export type MutationVoucherFlatDiscountUpdateArgs = {
  input: VoucherFlatDiscountUpdateInput;
  voucherId: Scalars['ID']['input'];
};


export type MutationVoucherPercentDiscountUpdateArgs = {
  input: VoucherPercentDiscountUpdateInput;
  voucherId: Scalars['ID']['input'];
};


export type MutationVoucherRewardUpdateArgs = {
  input: VoucherRewardUpdateInput;
  voucherId: Scalars['ID']['input'];
};

export type NetPromoterScore = {
  __typename?: 'NetPromoterScore';
  comment?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  score: Scalars['Int']['output'];
};

export type NetPromoterScoreCreateInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  score: Scalars['Int']['input'];
};

export type NetPromoterScoreOutput = {
  __typename?: 'NetPromoterScoreOutput';
  netPromoterScore: NetPromoterScore;
};

export type NetPromoterScoreUpdateInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  score?: InputMaybe<Scalars['Int']['input']>;
};

export type Newsletter = DeletableNode & Node & {
  __typename?: 'Newsletter';
  author: User;
  cohort?: Maybe<CohortUser>;
  company: Company;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  headlineDelta?: Maybe<Scalars['Delta']['output']>;
  headlineHTML?: Maybe<Scalars['String']['output']>;
  headlineText?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  newsletterContentPagination: NewsletterContentPagination;
  /** @deprecated Use `newsletterContentPagination` instead */
  publicationsPagination: PublicationPagination;
  recipientsPagination: UsersPagination;
  replyToEmail?: Maybe<Scalars['String']['output']>;
  senderEmail?: Maybe<Scalars['String']['output']>;
  senderName?: Maybe<Scalars['String']['output']>;
  sentAt?: Maybe<Scalars['DateTime']['output']>;
  statisticsPagination: NewsletterStatisticPagination;
  status: NewsletterStatus;
  subject?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};


export type NewsletterNewsletterContentPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type NewsletterPublicationsPaginationArgs = {
  filter?: InputMaybe<PublicationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<PublicationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type NewsletterRecipientsPaginationArgs = {
  filter?: InputMaybe<UsersArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<UsersArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type NewsletterStatisticsPaginationArgs = {
  filter?: InputMaybe<NewsletterStatisticArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type NewsletterContent = DeletableNode & Node & {
  __typename?: 'NewsletterContent';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  position: Scalars['Int']['output'];
  target: NewsletterContentTarget;
  targetType: NewsletterContentTargetType;
  updatedAt: Scalars['DateTime']['output'];
};

export type NewsletterContentEdge = Edge & {
  __typename?: 'NewsletterContentEdge';
  cursor: Scalars['ID']['output'];
  node: NewsletterContent;
};

export type NewsletterContentInput = {
  contentId: Scalars['ID']['input'];
  contentType: NewsletterContentTargetType;
};

export type NewsletterContentPagination = Pagination & {
  __typename?: 'NewsletterContentPagination';
  edges: Array<NewsletterContentEdge>;
  nodes: Array<NewsletterContent>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Represents the newsletter content's target */
export type NewsletterContentTarget = Article | CompanyDocument | Publication | SubventionDiscount | SubventionDraft | SubventionFund;

/** Represents the newsletter content's target type */
export enum NewsletterContentTargetType {
  Article = 'ARTICLE',
  CompanyDocument = 'COMPANY_DOCUMENT',
  Publication = 'PUBLICATION',
  Subvention = 'SUBVENTION'
}

export type NewsletterCreateInput = {
  cohortId?: InputMaybe<Scalars['ID']['input']>;
  contents?: InputMaybe<Array<NewsletterContentInput>>;
  headline?: InputMaybe<Scalars['Delta']['input']>;
  replyToEmail?: InputMaybe<Scalars['String']['input']>;
  selectionMode?: InputMaybe<CohortSelectionMode>;
  senderEmail?: InputMaybe<Scalars['String']['input']>;
  senderName?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  usersArgsFilter?: InputMaybe<UsersArgsFilter>;
};

export type NewsletterCreateOutput = {
  __typename?: 'NewsletterCreateOutput';
  newsletter: Newsletter;
};

export type NewsletterCreatedData = EventData & {
  __typename?: 'NewsletterCreatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  headline: TrackableDelta;
  newsletterContents: TrackableNewsletterContents;
  recipientsIds: TrackableStringArray;
  replyToEmail: TrackableString;
  senderEmail: TrackableString;
  senderName: TrackableString;
  subject: TrackableString;
};

export type NewsletterDeleteOutput = {
  __typename?: 'NewsletterDeleteOutput';
  newsletter: Newsletter;
};

export type NewsletterDeletedData = EventData & {
  __typename?: 'NewsletterDeletedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
};

export type NewsletterDuplicateOutput = {
  __typename?: 'NewsletterDuplicateOutput';
  newsletter: Newsletter;
};

export type NewsletterDuplicatedData = EventData & {
  __typename?: 'NewsletterDuplicatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
};

export type NewsletterEdge = Edge & {
  __typename?: 'NewsletterEdge';
  cursor: Scalars['ID']['output'];
  node: Newsletter;
};

export type NewsletterPagination = Pagination & {
  __typename?: 'NewsletterPagination';
  edges: Array<NewsletterEdge>;
  nodes: Array<Newsletter>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type NewsletterPaginationArgsFilter = {
  status?: InputMaybe<NewsletterPaginationFilterStatusArgs>;
};

export type NewsletterPaginationArgsSorter = {
  createdAt?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type NewsletterPaginationFilterStatusArgs = {
  in?: InputMaybe<Array<NewsletterStatus>>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<NewsletterStatus>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<NewsletterStatus>;
  notIn?: InputMaybe<Array<NewsletterStatus>>;
};

export type NewsletterSendOutput = {
  __typename?: 'NewsletterSendOutput';
  newsletter: Newsletter;
};

export type NewsletterSendTestOutput = {
  __typename?: 'NewsletterSendTestOutput';
  newsletter: Newsletter;
};

export type NewsletterSentData = EventData & {
  __typename?: 'NewsletterSentData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
};

export type NewsletterStatistic = Node & {
  __typename?: 'NewsletterStatistic';
  /** The number of emails that were not allowed to be delivered by ISPs. */
  blocked: Scalars['Int']['output'];
  /** The number of emails that bounced instead of being delivered. */
  bounced: Scalars['Int']['output'];
  /** The number of links that were clicked in your emails. */
  clicked: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** The number of emails we were able to confirm were actually delivered to a recipient. */
  delivered: Scalars['Int']['output'];
  /** The number of emails that dropped instead of being delivered, because they were bounced. */
  dropped_bounced_address: Scalars['Int']['output'];
  /** The number of emails that dropped instead of being delivered, because the email address was invalid. */
  dropped_invalid: Scalars['Int']['output'];
  /** The number of emails that dropped instead of being delivered, because the SMTPAPI Header was invalid. */
  dropped_invalid_smtpapi_header: Scalars['Int']['output'];
  /** The number of emails that dropped instead of being delivered, because the recipients list was too large. */
  dropped_recipients_list_over_quota: Scalars['Int']['output'];
  /** The number of emails that dropped instead of being delivered, because considered SPAM content. */
  dropped_spam_content: Scalars['Int']['output'];
  /** The number of emails that dropped instead of being delivered, because the recipient address is flagged as spam reporting. */
  dropped_spam_reporting_address: Scalars['Int']['output'];
  /** The number of emails that dropped instead of being delivered, because the recipient was unsubscribed. */
  dropped_unsubscribed_address: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  /** The total number of times your emails were opened by recipients. */
  opened: Scalars['Int']['output'];
  timestamp: Scalars['DateTime']['output'];
  /** The number of recipients who have marked your email as spam. */
  treatedAsSpam: Scalars['Int']['output'];
  /** The number of recipients who have unsubscribed from your emails. */
  unsubscribed: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type NewsletterStatisticAggregate = {
  __typename?: 'NewsletterStatisticAggregate';
  /** The number of emails that were not allowed to be delivered by ISPs. */
  blocked: Scalars['Int']['output'];
  /** The number of emails that bounced instead of being delivered. */
  bounced: Scalars['Int']['output'];
  /** The number of links that were clicked in your emails. */
  clicked: Scalars['Int']['output'];
  /** The number of emails we were able to confirm were actually delivered to a recipient. */
  delivered: Scalars['Int']['output'];
  /** The number of emails that dropped instead of being delivered, because they were bounced. */
  dropped_bounced_address: Scalars['Int']['output'];
  /** The number of emails that dropped instead of being delivered, because the email address was invalid. */
  dropped_invalid: Scalars['Int']['output'];
  /** The number of emails that dropped instead of being delivered, because the SMTPAPI Header was invalid. */
  dropped_invalid_smtpapi_header: Scalars['Int']['output'];
  /** The number of emails that dropped instead of being delivered, because the recipients list was too large. */
  dropped_recipients_list_over_quota: Scalars['Int']['output'];
  /** The number of emails that dropped instead of being delivered, because considered SPAM content. */
  dropped_spam_content: Scalars['Int']['output'];
  /** The number of emails that dropped instead of being delivered, because the recipient address is flagged as spam reporting. */
  dropped_spam_reporting_address: Scalars['Int']['output'];
  /** The number of emails that dropped instead of being delivered, because the recipient was unsubscribed. */
  dropped_unsubscribed_address: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  /** The total number of times your emails were opened by recipients. */
  opened: Scalars['Int']['output'];
  /** The number of recipients who have marked your email as spam. */
  treatedAsSpam: Scalars['Int']['output'];
  /** The number of recipients who have unsubscribed from your emails. */
  unsubscribed: Scalars['Int']['output'];
};

export type NewsletterStatisticArgsFilter = {
  timestamp?: InputMaybe<FilterDateArgs>;
};

export type NewsletterStatisticEdge = Edge & {
  __typename?: 'NewsletterStatisticEdge';
  cursor: Scalars['ID']['output'];
  node: NewsletterStatistic;
};

export type NewsletterStatisticPagination = Pagination & {
  __typename?: 'NewsletterStatisticPagination';
  aggregate: NewsletterStatisticAggregate;
  edges: Array<NewsletterStatisticEdge>;
  nodes: Array<NewsletterStatistic>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Newsletter Status */
export enum NewsletterStatus {
  Draft = 'DRAFT',
  Sent = 'SENT'
}

export type NewsletterUpdateInput = {
  cohortId?: InputMaybe<Scalars['ID']['input']>;
  contents?: InputMaybe<Array<NewsletterContentInput>>;
  headline?: InputMaybe<Scalars['Delta']['input']>;
  replyToEmail?: InputMaybe<Scalars['String']['input']>;
  selectionMode?: InputMaybe<CohortSelectionMode>;
  senderEmail?: InputMaybe<Scalars['String']['input']>;
  senderName?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  usersArgsFilter?: InputMaybe<UsersArgsFilter>;
};

export type NewsletterUpdateOutput = {
  __typename?: 'NewsletterUpdateOutput';
  newsletter: Newsletter;
};

export type NewsletterUpdatedData = EventData & {
  __typename?: 'NewsletterUpdatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  headline: TrackableDelta;
  newsletterContents: TrackableNewsletterContents;
  recipientsIds: TrackableStringArray;
  replyToEmail: TrackableString;
  senderEmail: TrackableString;
  senderName: TrackableString;
  subject: TrackableString;
};

export type Node = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum NotificationChannel {
  App = 'APP',
  Email = 'EMAIL',
  Push = 'PUSH'
}

export type NotificationPreference = Node & {
  __typename?: 'NotificationPreference';
  channels: Array<NotificationChannel>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  notificationType: NotificationType;
  updatedAt: Scalars['DateTime']['output'];
};

export type NotificationRequest = Node & {
  __typename?: 'NotificationRequest';
  channel: NotificationChannel;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isBroadcast: Scalars['Boolean']['output'];
  notificationType: NotificationType;
  status: NotificationRequestStatus;
  updatedAt: Scalars['DateTime']['output'];
  withUserPreferences: Scalars['Boolean']['output'];
};

export enum NotificationRequestStatus {
  Pending = 'PENDING',
  Sent = 'SENT'
}

export enum NotificationType {
  ArticlePublished = 'ARTICLE_PUBLISHED',
  BeneficiaryAccepted = 'BENEFICIARY_ACCEPTED',
  BeneficiaryDeleted = 'BENEFICIARY_DELETED',
  BeneficiaryRejected = 'BENEFICIARY_REJECTED',
  BeneficiaryRejectedFollowUp = 'BENEFICIARY_REJECTED_FOLLOW_UP',
  DocumentPublished = 'DOCUMENT_PUBLISHED',
  MangopayBankwireDirectPayInUpdated = 'MANGOPAY_BANKWIRE_DIRECT_PAY_IN_UPDATED',
  MangopayTransferFailed = 'MANGOPAY_TRANSFER_FAILED',
  OfferPublished = 'OFFER_PUBLISHED',
  PublicationPublished = 'PUBLICATION_PUBLISHED',
  SubventionInvoiceCreated = 'SUBVENTION_INVOICE_CREATED',
  SubventionPublished = 'SUBVENTION_PUBLISHED',
  UserEmailChanged = 'USER_EMAIL_CHANGED'
}

export type NotificationsArgsSorter = {
  createdAt?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type Offer = {
  appdragId?: Maybe<Scalars['Int']['output']>;
  availableCountry: Scalars['Boolean']['output'];
  availableOnline: Scalars['Boolean']['output'];
  brand: Brand;
  campaign?: Maybe<Campaign>;
  categoriesPagination: CategoriesPagination;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionDelta?: Maybe<Scalars['Delta']['output']>;
  descriptionHTML?: Maybe<Scalars['String']['output']>;
  endsAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isExclusive: Scalars['Boolean']['output'];
  locale: Scalars['ID']['output'];
  metadata: OfferMetadata;
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  startsAt?: Maybe<Scalars['DateTime']['output']>;
  status: OfferStatus;
  storePagination: StorePagination;
  /** @deprecated Use storePagination instead */
  storesPagination: StorePagination;
  subventionSchemas: Array<SubventionSchema>;
  subventionsPagination: SubventionPagination;
  /** Args is not used. */
  translationsPagination: OfferTranslationsPagination;
  updatedAt: Scalars['DateTime']['output'];
  url?: Maybe<ExternalUrl>;
  /** @deprecated Use subventionsPagination instead */
  userSubventionsPagination: UserSubventionPagination;
  voucher?: Maybe<Voucher>;
};


export type OfferCategoriesPaginationArgs = {
  filter?: InputMaybe<CategoriesArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CategoriesArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferStorePaginationArgs = {
  filter?: InputMaybe<StoreArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<StoreArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferStoresPaginationArgs = {
  filter?: InputMaybe<StoreArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<StoreArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferSubventionsPaginationArgs = {
  filter?: InputMaybe<SubventionArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<SubventionArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferTranslationsPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferUserSubventionsPaginationArgs = {
  filter?: InputMaybe<UserSubventionArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<UserSubventionArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type OfferBase = DeletableNode & IOfferTranslation & Node & Offer & TrailableNode & Translatable & {
  __typename?: 'OfferBase';
  appdragId?: Maybe<Scalars['Int']['output']>;
  auditTrailPagination: AuditTrailPagination;
  availableCountry: Scalars['Boolean']['output'];
  availableOnline: Scalars['Boolean']['output'];
  brand: Brand;
  campaign?: Maybe<Campaign>;
  categoriesPagination: CategoriesPagination;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionDelta?: Maybe<Scalars['Delta']['output']>;
  descriptionHTML?: Maybe<Scalars['String']['output']>;
  endsAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isExclusive: Scalars['Boolean']['output'];
  locale: Scalars['ID']['output'];
  metadata: OfferMetadata;
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  startsAt?: Maybe<Scalars['DateTime']['output']>;
  status: OfferStatus;
  storePagination: StorePagination;
  /** @deprecated Use storePagination instead */
  storesPagination: StorePagination;
  subventionSchemas: Array<SubventionSchema>;
  subventionsPagination: SubventionPagination;
  /** Args is not used. */
  translationsPagination: OfferTranslationsPagination;
  updatedAt: Scalars['DateTime']['output'];
  url?: Maybe<ExternalUrl>;
  /** @deprecated Use subventionsPagination instead */
  userSubventionsPagination: UserSubventionPagination;
  voucher?: Maybe<Voucher>;
};


export type OfferBaseAuditTrailPaginationArgs = {
  filter?: InputMaybe<AuditTrailArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<AuditTrailArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferBaseCategoriesPaginationArgs = {
  filter?: InputMaybe<CategoriesArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CategoriesArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferBaseStorePaginationArgs = {
  filter?: InputMaybe<StoreArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<StoreArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferBaseStoresPaginationArgs = {
  filter?: InputMaybe<StoreArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<StoreArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferBaseSubventionsPaginationArgs = {
  filter?: InputMaybe<SubventionArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<SubventionArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferBaseTranslationsPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferBaseUserSubventionsPaginationArgs = {
  filter?: InputMaybe<UserSubventionArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<UserSubventionArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type OfferBaseCreateInput = {
  availableCountry?: InputMaybe<Scalars['Boolean']['input']>;
  availableOnline?: InputMaybe<Scalars['Boolean']['input']>;
  brandId: Scalars['ID']['input'];
  campaignId: Scalars['ID']['input'];
  categoryIds: Array<Scalars['ID']['input']>;
  descriptionDelta?: InputMaybe<Scalars['Delta']['input']>;
  endsAt?: InputMaybe<Scalars['DateTime']['input']>;
  isExclusive?: InputMaybe<Scalars['Boolean']['input']>;
  localeId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  startsAt?: InputMaybe<Scalars['DateTime']['input']>;
  storeIds: Array<Scalars['ID']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type OfferBaseCreateOutput = {
  __typename?: 'OfferBaseCreateOutput';
  offer: OfferBase;
};

export type OfferCouponClaimable = DeletableNode & IOfferTranslation & Node & Offer & TrailableNode & Translatable & {
  __typename?: 'OfferCouponClaimable';
  appdragId?: Maybe<Scalars['Int']['output']>;
  auditTrailPagination: AuditTrailPagination;
  availableCountry: Scalars['Boolean']['output'];
  availableOnline: Scalars['Boolean']['output'];
  brand: Brand;
  campaign?: Maybe<Campaign>;
  categoriesPagination: CategoriesPagination;
  claimCountMax?: Maybe<Scalars['Int']['output']>;
  claimInterval: Scalars['Int']['output'];
  couponClaimablesPagination: CouponClaimablesPagination;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionDelta?: Maybe<Scalars['Delta']['output']>;
  descriptionHTML?: Maybe<Scalars['String']['output']>;
  endsAt?: Maybe<Scalars['DateTime']['output']>;
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  inventoryQuantity: Scalars['Int']['output'];
  isExclusive: Scalars['Boolean']['output'];
  locale: Scalars['ID']['output'];
  metadata: OfferMetadata;
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  startsAt?: Maybe<Scalars['DateTime']['output']>;
  status: OfferStatus;
  storePagination: StorePagination;
  /** @deprecated Use storePagination instead */
  storesPagination: StorePagination;
  subventionSchemas: Array<SubventionSchema>;
  subventionsPagination: SubventionPagination;
  /** Args is not used. */
  translationsPagination: OfferTranslationsPagination;
  updatedAt: Scalars['DateTime']['output'];
  url?: Maybe<ExternalUrl>;
  /** @deprecated Use subventionsPagination instead */
  userSubventionsPagination: UserSubventionPagination;
  viewerClaimedCouponsPagination: CouponClaimablesPagination;
  voucher?: Maybe<Voucher>;
};


export type OfferCouponClaimableAuditTrailPaginationArgs = {
  filter?: InputMaybe<AuditTrailArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<AuditTrailArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferCouponClaimableCategoriesPaginationArgs = {
  filter?: InputMaybe<CategoriesArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CategoriesArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferCouponClaimableCouponClaimablesPaginationArgs = {
  filter?: InputMaybe<CouponClaimablesPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CouponClaimablesPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferCouponClaimableStorePaginationArgs = {
  filter?: InputMaybe<StoreArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<StoreArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferCouponClaimableStoresPaginationArgs = {
  filter?: InputMaybe<StoreArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<StoreArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferCouponClaimableSubventionsPaginationArgs = {
  filter?: InputMaybe<SubventionArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<SubventionArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferCouponClaimableTranslationsPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferCouponClaimableUserSubventionsPaginationArgs = {
  filter?: InputMaybe<UserSubventionArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<UserSubventionArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferCouponClaimableViewerClaimedCouponsPaginationArgs = {
  filter?: InputMaybe<CouponClaimablesPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CouponClaimablesPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type OfferCouponClaimableCreateInput = {
  availableCountry?: InputMaybe<Scalars['Boolean']['input']>;
  availableOnline?: InputMaybe<Scalars['Boolean']['input']>;
  brandId: Scalars['ID']['input'];
  campaignId: Scalars['ID']['input'];
  categoryIds: Array<Scalars['ID']['input']>;
  claimCountMax?: InputMaybe<Scalars['Int']['input']>;
  claimInterval: Scalars['Int']['input'];
  descriptionDelta?: InputMaybe<Scalars['Delta']['input']>;
  endsAt?: InputMaybe<Scalars['DateTime']['input']>;
  isExclusive?: InputMaybe<Scalars['Boolean']['input']>;
  localeId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  startsAt?: InputMaybe<Scalars['DateTime']['input']>;
  storeIds: Array<Scalars['ID']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type OfferCouponClaimableCreateOutput = {
  __typename?: 'OfferCouponClaimableCreateOutput';
  offer: OfferCouponClaimable;
};

export type OfferCouponClaimableUpdateInput = {
  claimCountMax?: InputMaybe<Scalars['Int']['input']>;
  claimInterval?: InputMaybe<Scalars['Int']['input']>;
};

export type OfferCouponClaimableUpdateOutput = {
  __typename?: 'OfferCouponClaimableUpdateOutput';
  offer: OfferCouponClaimable;
};

export type OfferCouponPublic = DeletableNode & IOfferTranslation & Node & Offer & TrailableNode & Translatable & {
  __typename?: 'OfferCouponPublic';
  appdragId?: Maybe<Scalars['Int']['output']>;
  auditTrailPagination: AuditTrailPagination;
  availableCountry: Scalars['Boolean']['output'];
  availableOnline: Scalars['Boolean']['output'];
  brand: Brand;
  campaign?: Maybe<Campaign>;
  categoriesPagination: CategoriesPagination;
  coupon?: Maybe<CouponPublic>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionDelta?: Maybe<Scalars['Delta']['output']>;
  descriptionHTML?: Maybe<Scalars['String']['output']>;
  endsAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isExclusive: Scalars['Boolean']['output'];
  locale: Scalars['ID']['output'];
  metadata: OfferMetadata;
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  startsAt?: Maybe<Scalars['DateTime']['output']>;
  status: OfferStatus;
  storePagination: StorePagination;
  /** @deprecated Use storePagination instead */
  storesPagination: StorePagination;
  subventionSchemas: Array<SubventionSchema>;
  subventionsPagination: SubventionPagination;
  /** Args is not used. */
  translationsPagination: OfferTranslationsPagination;
  updatedAt: Scalars['DateTime']['output'];
  url?: Maybe<ExternalUrl>;
  /** @deprecated Use subventionsPagination instead */
  userSubventionsPagination: UserSubventionPagination;
  voucher?: Maybe<Voucher>;
};


export type OfferCouponPublicAuditTrailPaginationArgs = {
  filter?: InputMaybe<AuditTrailArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<AuditTrailArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferCouponPublicCategoriesPaginationArgs = {
  filter?: InputMaybe<CategoriesArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CategoriesArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferCouponPublicStorePaginationArgs = {
  filter?: InputMaybe<StoreArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<StoreArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferCouponPublicStoresPaginationArgs = {
  filter?: InputMaybe<StoreArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<StoreArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferCouponPublicSubventionsPaginationArgs = {
  filter?: InputMaybe<SubventionArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<SubventionArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferCouponPublicTranslationsPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferCouponPublicUserSubventionsPaginationArgs = {
  filter?: InputMaybe<UserSubventionArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<UserSubventionArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type OfferCouponPublicCreateInput = {
  availableCountry?: InputMaybe<Scalars['Boolean']['input']>;
  availableOnline?: InputMaybe<Scalars['Boolean']['input']>;
  brandId: Scalars['ID']['input'];
  campaignId: Scalars['ID']['input'];
  categoryIds: Array<Scalars['ID']['input']>;
  coupon: Scalars['String']['input'];
  descriptionDelta?: InputMaybe<Scalars['Delta']['input']>;
  endsAt?: InputMaybe<Scalars['DateTime']['input']>;
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  isExclusive?: InputMaybe<Scalars['Boolean']['input']>;
  localeId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  startsAt?: InputMaybe<Scalars['DateTime']['input']>;
  storeIds: Array<Scalars['ID']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type OfferCouponPublicCreateOutput = {
  __typename?: 'OfferCouponPublicCreateOutput';
  offer: OfferCouponPublic;
};

export type OfferCreatedData = EventData & {
  __typename?: 'OfferCreatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  availableCountry?: Maybe<TrackableBoolean>;
  availableOnline?: Maybe<TrackableBoolean>;
  categoryIds: Array<Scalars['ID']['output']>;
  description?: Maybe<TrackableDelta>;
  endsAt?: Maybe<TrackableDateTime>;
  isExclusive?: Maybe<TrackableBoolean>;
  locale: Scalars['ID']['output'];
  name?: Maybe<TrackableString>;
  offerType?: Maybe<TrackableOfferType>;
  offerTypeSpecificData?: Maybe<Scalars['JSONObject']['output']>;
  startsAt?: Maybe<TrackableDateTime>;
  storeIds: Array<Scalars['ID']['output']>;
  url?: Maybe<TrackableString>;
};

export type OfferEdge = Edge & {
  __typename?: 'OfferEdge';
  cursor: Scalars['ID']['output'];
  node: Offer;
};

export type OfferFilterArgsFilterOfferStatus = {
  in?: InputMaybe<Array<OfferStatus>>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<OfferStatus>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<OfferStatus>;
  notIn?: InputMaybe<Array<OfferStatus>>;
};

export type OfferFilterArgsFilterOfferType = {
  in?: InputMaybe<Array<OfferType>>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<OfferType>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<OfferType>;
  notIn?: InputMaybe<Array<OfferType>>;
};

export type OfferGiftCard = DeletableNode & IOfferTranslation & Node & Offer & OfferProduct & TrailableNode & Translatable & {
  __typename?: 'OfferGiftCard';
  appdragId?: Maybe<Scalars['Int']['output']>;
  auditTrailPagination: AuditTrailPagination;
  availableCountry: Scalars['Boolean']['output'];
  availableOnline: Scalars['Boolean']['output'];
  brand: Brand;
  campaign?: Maybe<Campaign>;
  categoriesPagination: CategoriesPagination;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionDelta?: Maybe<Scalars['Delta']['output']>;
  descriptionHTML?: Maybe<Scalars['String']['output']>;
  endsAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isExclusive: Scalars['Boolean']['output'];
  locale: Scalars['ID']['output'];
  metadata: OfferMetadata;
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  product: Product;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  startsAt?: Maybe<Scalars['DateTime']['output']>;
  status: OfferStatus;
  storePagination: StorePagination;
  /** @deprecated Use storePagination instead */
  storesPagination: StorePagination;
  subventionSchemas: Array<SubventionSchema>;
  subventionsPagination: SubventionPagination;
  /** Args is not used. */
  translationsPagination: OfferTranslationsPagination;
  updatedAt: Scalars['DateTime']['output'];
  url?: Maybe<ExternalUrl>;
  /** @deprecated Use subventionsPagination instead */
  userSubventionsPagination: UserSubventionPagination;
  voucher?: Maybe<Voucher>;
};


export type OfferGiftCardAuditTrailPaginationArgs = {
  filter?: InputMaybe<AuditTrailArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<AuditTrailArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferGiftCardCategoriesPaginationArgs = {
  filter?: InputMaybe<CategoriesArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CategoriesArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferGiftCardStorePaginationArgs = {
  filter?: InputMaybe<StoreArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<StoreArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferGiftCardStoresPaginationArgs = {
  filter?: InputMaybe<StoreArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<StoreArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferGiftCardSubventionsPaginationArgs = {
  filter?: InputMaybe<SubventionArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<SubventionArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferGiftCardTranslationsPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferGiftCardUserSubventionsPaginationArgs = {
  filter?: InputMaybe<UserSubventionArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<UserSubventionArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type OfferGiftCardCreateInput = {
  availableCountry?: InputMaybe<Scalars['Boolean']['input']>;
  availableOnline?: InputMaybe<Scalars['Boolean']['input']>;
  brandId: Scalars['ID']['input'];
  campaignId: Scalars['ID']['input'];
  categoryIds: Array<Scalars['ID']['input']>;
  descriptionDelta?: InputMaybe<Scalars['Delta']['input']>;
  endsAt?: InputMaybe<Scalars['DateTime']['input']>;
  isExclusive?: InputMaybe<Scalars['Boolean']['input']>;
  localeId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  productId: Scalars['ID']['input'];
  startsAt?: InputMaybe<Scalars['DateTime']['input']>;
  storeIds: Array<Scalars['ID']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type OfferGiftCardCreateOutput = {
  __typename?: 'OfferGiftCardCreateOutput';
  offer: OfferGiftCard;
};

export type OfferMetadata = {
  __typename?: 'OfferMetadata';
  provider?: Maybe<OfferMetadataProvider>;
};

export type OfferMetadataProvider = {
  __typename?: 'OfferMetadataProvider';
  raw?: Maybe<Scalars['JSONObject']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type OfferProduct = {
  appdragId?: Maybe<Scalars['Int']['output']>;
  availableCountry: Scalars['Boolean']['output'];
  availableOnline: Scalars['Boolean']['output'];
  brand: Brand;
  campaign?: Maybe<Campaign>;
  categoriesPagination: CategoriesPagination;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionDelta?: Maybe<Scalars['Delta']['output']>;
  descriptionHTML?: Maybe<Scalars['String']['output']>;
  endsAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isExclusive: Scalars['Boolean']['output'];
  locale: Scalars['ID']['output'];
  metadata: OfferMetadata;
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  product: Product;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  startsAt?: Maybe<Scalars['DateTime']['output']>;
  status: OfferStatus;
  storePagination: StorePagination;
  /** @deprecated Use storePagination instead */
  storesPagination: StorePagination;
  subventionSchemas: Array<SubventionSchema>;
  subventionsPagination: SubventionPagination;
  /** Args is not used. */
  translationsPagination: OfferTranslationsPagination;
  updatedAt: Scalars['DateTime']['output'];
  url?: Maybe<ExternalUrl>;
  /** @deprecated Use subventionsPagination instead */
  userSubventionsPagination: UserSubventionPagination;
  voucher?: Maybe<Voucher>;
};


export type OfferProductCategoriesPaginationArgs = {
  filter?: InputMaybe<CategoriesArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CategoriesArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferProductStorePaginationArgs = {
  filter?: InputMaybe<StoreArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<StoreArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferProductStoresPaginationArgs = {
  filter?: InputMaybe<StoreArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<StoreArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferProductSubventionsPaginationArgs = {
  filter?: InputMaybe<SubventionArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<SubventionArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferProductTranslationsPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferProductUserSubventionsPaginationArgs = {
  filter?: InputMaybe<UserSubventionArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<UserSubventionArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type OfferPublishOutput = {
  __typename?: 'OfferPublishOutput';
  offer: Offer;
};

export type OfferPublishedData = EventData & {
  __typename?: 'OfferPublishedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  status?: Maybe<TrackableOfferStatus>;
};

export enum OfferStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED'
}

export type OfferTicket = DeletableNode & IOfferTranslation & Node & Offer & OfferProduct & TrailableNode & Translatable & {
  __typename?: 'OfferTicket';
  appdragId?: Maybe<Scalars['Int']['output']>;
  auditTrailPagination: AuditTrailPagination;
  availableCountry: Scalars['Boolean']['output'];
  availableOnline: Scalars['Boolean']['output'];
  brand: Brand;
  campaign?: Maybe<Campaign>;
  categoriesPagination: CategoriesPagination;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionDelta?: Maybe<Scalars['Delta']['output']>;
  descriptionHTML?: Maybe<Scalars['String']['output']>;
  endsAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isExclusive: Scalars['Boolean']['output'];
  locale: Scalars['ID']['output'];
  metadata: OfferMetadata;
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  product: Product;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  startsAt?: Maybe<Scalars['DateTime']['output']>;
  status: OfferStatus;
  storePagination: StorePagination;
  /** @deprecated Use storePagination instead */
  storesPagination: StorePagination;
  subventionSchemas: Array<SubventionSchema>;
  subventionsPagination: SubventionPagination;
  /** Args is not used. */
  translationsPagination: OfferTranslationsPagination;
  updatedAt: Scalars['DateTime']['output'];
  url?: Maybe<ExternalUrl>;
  /** @deprecated Use subventionsPagination instead */
  userSubventionsPagination: UserSubventionPagination;
  voucher?: Maybe<Voucher>;
};


export type OfferTicketAuditTrailPaginationArgs = {
  filter?: InputMaybe<AuditTrailArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<AuditTrailArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferTicketCategoriesPaginationArgs = {
  filter?: InputMaybe<CategoriesArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CategoriesArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferTicketStorePaginationArgs = {
  filter?: InputMaybe<StoreArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<StoreArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferTicketStoresPaginationArgs = {
  filter?: InputMaybe<StoreArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<StoreArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferTicketSubventionsPaginationArgs = {
  filter?: InputMaybe<SubventionArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<SubventionArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferTicketTranslationsPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferTicketUserSubventionsPaginationArgs = {
  filter?: InputMaybe<UserSubventionArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<UserSubventionArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type OfferTicketCreateInput = {
  availableCountry?: InputMaybe<Scalars['Boolean']['input']>;
  availableOnline?: InputMaybe<Scalars['Boolean']['input']>;
  brandId: Scalars['ID']['input'];
  campaignId: Scalars['ID']['input'];
  categoryIds: Array<Scalars['ID']['input']>;
  descriptionDelta?: InputMaybe<Scalars['Delta']['input']>;
  endsAt?: InputMaybe<Scalars['DateTime']['input']>;
  isExclusive?: InputMaybe<Scalars['Boolean']['input']>;
  localeId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  productId: Scalars['ID']['input'];
  startsAt?: InputMaybe<Scalars['DateTime']['input']>;
  storeIds: Array<Scalars['ID']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type OfferTicketCreateOutput = {
  __typename?: 'OfferTicketCreateOutput';
  offer: OfferTicket;
};

export type OfferTranslation = IOfferTranslation & Node & Translation & {
  __typename?: 'OfferTranslation';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  descriptionDelta?: Maybe<Scalars['Delta']['output']>;
  descriptionHTML?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isGenerated: Scalars['Boolean']['output'];
  isMaster: Scalars['Boolean']['output'];
  locale: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type OfferTranslationCreateInput = {
  description?: InputMaybe<Scalars['Delta']['input']>;
  name: Scalars['String']['input'];
};

export type OfferTranslationCreateOutput = {
  __typename?: 'OfferTranslationCreateOutput';
  offer: Offer;
  offerTranslation: OfferTranslation;
};

export type OfferTranslationCreatedData = EventData & OfferTranslationDataFields & {
  __typename?: 'OfferTranslationCreatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  description?: Maybe<TrackableDelta>;
  locale: Scalars['ID']['output'];
  name?: Maybe<TrackableString>;
};

export type OfferTranslationDataFields = {
  locale: Scalars['ID']['output'];
};

export type OfferTranslationDeleteOutput = {
  __typename?: 'OfferTranslationDeleteOutput';
  offer: Offer;
  offerTranslation: OfferTranslation;
};

export type OfferTranslationDeletedData = EventData & OfferTranslationDataFields & {
  __typename?: 'OfferTranslationDeletedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  locale: Scalars['ID']['output'];
};

export type OfferTranslationEdge = Edge & TranslationEdge & {
  __typename?: 'OfferTranslationEdge';
  cursor: Scalars['ID']['output'];
  node: OfferTranslation;
};

export type OfferTranslationUpdateInput = {
  description?: InputMaybe<Scalars['Delta']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type OfferTranslationUpdateOutput = {
  __typename?: 'OfferTranslationUpdateOutput';
  offer: Offer;
  offerTranslation: OfferTranslation;
};

export type OfferTranslationUpdatedData = EventData & OfferTranslationDataFields & {
  __typename?: 'OfferTranslationUpdatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  description?: Maybe<TrackableDelta>;
  locale: Scalars['ID']['output'];
  name?: Maybe<TrackableString>;
};

export type OfferTranslationsPagination = Pagination & TranslationsPagination & {
  __typename?: 'OfferTranslationsPagination';
  edges: Array<OfferTranslationEdge>;
  nodes: Array<OfferTranslation>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export enum OfferType {
  Base = 'BASE',
  CouponClaimable = 'COUPON_CLAIMABLE',
  CouponPublic = 'COUPON_PUBLIC',
  GiftCard = 'GIFT_CARD',
  Ticket = 'TICKET',
  Url = 'URL'
}

export type OfferUnpublishOutput = {
  __typename?: 'OfferUnpublishOutput';
  offer: Offer;
};

export type OfferUnpublishedData = EventData & {
  __typename?: 'OfferUnpublishedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  status?: Maybe<TrackableOfferStatus>;
};

export type OfferUpdateInput = {
  availableCountry?: InputMaybe<Scalars['Boolean']['input']>;
  availableOnline?: InputMaybe<Scalars['Boolean']['input']>;
  campaignId?: InputMaybe<Scalars['ID']['input']>;
  categoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  endsAt?: InputMaybe<Scalars['DateTime']['input']>;
  isExclusive?: InputMaybe<Scalars['Boolean']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  startsAt?: InputMaybe<Scalars['DateTime']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type OfferUpdateOutput = {
  __typename?: 'OfferUpdateOutput';
  offer: Offer;
};

export type OfferUpdatedData = EventData & {
  __typename?: 'OfferUpdatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  availableCountry?: Maybe<TrackableBoolean>;
  availableOnline?: Maybe<TrackableBoolean>;
  campaignId?: Maybe<TrackableString>;
  categoryIds?: Maybe<Array<Scalars['ID']['output']>>;
  endsAt?: Maybe<TrackableDateTime>;
  isExclusive?: Maybe<TrackableBoolean>;
  offerTypeSpecificData?: Maybe<Scalars['JSONObject']['output']>;
  position?: Maybe<TrackableNumber>;
  startsAt?: Maybe<TrackableDateTime>;
  url?: Maybe<TrackableString>;
};

export type OfferUrl = DeletableNode & IOfferTranslation & Node & Offer & TrailableNode & Translatable & {
  __typename?: 'OfferUrl';
  appdragId?: Maybe<Scalars['Int']['output']>;
  auditTrailPagination: AuditTrailPagination;
  availableCountry: Scalars['Boolean']['output'];
  availableOnline: Scalars['Boolean']['output'];
  brand: Brand;
  campaign?: Maybe<Campaign>;
  categoriesPagination: CategoriesPagination;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionDelta?: Maybe<Scalars['Delta']['output']>;
  descriptionHTML?: Maybe<Scalars['String']['output']>;
  endsAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isExclusive: Scalars['Boolean']['output'];
  locale: Scalars['ID']['output'];
  metadata: OfferMetadata;
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  startsAt?: Maybe<Scalars['DateTime']['output']>;
  status: OfferStatus;
  storePagination: StorePagination;
  /** @deprecated Use storePagination instead */
  storesPagination: StorePagination;
  subventionSchemas: Array<SubventionSchema>;
  subventionsPagination: SubventionPagination;
  /** Args is not used. */
  translationsPagination: OfferTranslationsPagination;
  updatedAt: Scalars['DateTime']['output'];
  url?: Maybe<ExternalUrl>;
  /** @deprecated Use subventionsPagination instead */
  userSubventionsPagination: UserSubventionPagination;
  voucher?: Maybe<Voucher>;
};


export type OfferUrlAuditTrailPaginationArgs = {
  filter?: InputMaybe<AuditTrailArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<AuditTrailArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferUrlCategoriesPaginationArgs = {
  filter?: InputMaybe<CategoriesArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CategoriesArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferUrlStorePaginationArgs = {
  filter?: InputMaybe<StoreArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<StoreArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferUrlStoresPaginationArgs = {
  filter?: InputMaybe<StoreArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<StoreArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferUrlSubventionsPaginationArgs = {
  filter?: InputMaybe<SubventionArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<SubventionArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferUrlTranslationsPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OfferUrlUserSubventionsPaginationArgs = {
  filter?: InputMaybe<UserSubventionArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<UserSubventionArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type OfferUrlCreateInput = {
  availableCountry?: InputMaybe<Scalars['Boolean']['input']>;
  availableOnline?: InputMaybe<Scalars['Boolean']['input']>;
  brandId: Scalars['ID']['input'];
  campaignId: Scalars['ID']['input'];
  categoryIds: Array<Scalars['ID']['input']>;
  descriptionDelta?: InputMaybe<Scalars['Delta']['input']>;
  endsAt?: InputMaybe<Scalars['DateTime']['input']>;
  isExclusive?: InputMaybe<Scalars['Boolean']['input']>;
  localeId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  startsAt?: InputMaybe<Scalars['DateTime']['input']>;
  storeIds: Array<Scalars['ID']['input']>;
  url: Scalars['String']['input'];
};

export type OfferUrlCreateOutput = {
  __typename?: 'OfferUrlCreateOutput';
  offer: OfferUrl;
};

export type OffersArgsFilter = {
  AND?: InputMaybe<Array<OffersArgsFilter>>;
  OR?: InputMaybe<Array<OffersArgsFilter>>;
  availableCountry?: InputMaybe<FilterBoolArgs>;
  availableOnline?: InputMaybe<FilterBoolArgs>;
  brand?: InputMaybe<BrandsArgsFilter>;
  campaign?: InputMaybe<CampaignsArgsFilter>;
  categories?: InputMaybe<CategoriesRelationFilter>;
  cities?: InputMaybe<OffersArgsFilterCities>;
  distance?: InputMaybe<OffersArgsFilterDistance>;
  hasGeneratedTranslations?: InputMaybe<Scalars['Boolean']['input']>;
  hasMissingTranslations?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<FilterIdArgs>;
  includedCompanies?: InputMaybe<CompaniesArgsFilter>;
  isExclusive?: InputMaybe<FilterBoolArgs>;
  name?: InputMaybe<OffersArgsFilterSearchable>;
  product?: InputMaybe<ProductPaginationArgsFilter>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<OfferFilterArgsFilterOfferStatus>;
  stores?: InputMaybe<StoreArgsFilter>;
  type?: InputMaybe<OfferFilterArgsFilterOfferType>;
};

export type OffersArgsFilterCities = {
  include?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OffersArgsFilterDistance = {
  max?: InputMaybe<Scalars['Int']['input']>;
};

export type OffersArgsFilterSearchable = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type OffersArgsSorter = {
  brand?: InputMaybe<SortDirection>;
  campaign?: InputMaybe<SortDirection>;
  catalog?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  distance?: InputMaybe<SortDirection>;
  endsAt?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  physicalStore?: InputMaybe<SortDirection>;
  position?: InputMaybe<SortDirection>;
  provider?: InputMaybe<SortDirection>;
  publishedAt?: InputMaybe<SortDirection>;
  startsAt?: InputMaybe<SortDirection>;
  status?: InputMaybe<SortDirection>;
  type?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type OffersPagination = Pagination & {
  __typename?: 'OffersPagination';
  edges: Array<OfferEdge>;
  locations: Array<PaginationEnumFilter>;
  nodes: Array<Offer>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
  types: Array<OffersPaginationTypesEnumFilter>;
};

export type OffersPaginationTypesEnumFilter = {
  __typename?: 'OffersPaginationTypesEnumFilter';
  count: Scalars['Int']['output'];
  value: OfferType;
};

export type OffersRelationFilter = {
  NONE?: InputMaybe<OffersArgsFilter>;
  SOME?: InputMaybe<OffersArgsFilter>;
};

export type Order = Node & {
  __typename?: 'Order';
  /** Total number of articles inside the order */
  articleCount: Scalars['Int']['output'];
  campaigns: Array<Campaign>;
  cart: Cart;
  createdAt: Scalars['DateTime']['output'];
  creditNotes: Array<CreditNote>;
  currency: Scalars['Currency']['output'];
  /** Total discount amount applied by HappyPal to the order */
  discountsAmount: Scalars['Int']['output'];
  /** Additional fees price with taxes applied by happypal on the order */
  feesAmountAfterTaxes: Scalars['Int']['output'];
  /** Additional fees price without taxes applied by happypal on the order */
  feesAmountBeforeTaxes: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  invoice?: Maybe<Invoice>;
  mangopayTransferPagination: MangopayTransferPagination;
  orderItemPagination: OrderItemPagination;
  paymentIntent?: Maybe<StripePaymentIntent>;
  /** Total price of the order products already discounted with taxes */
  pricesAmountAfterTaxes: Scalars['Int']['output'];
  /** Total price of the order products already discounted without taxes */
  pricesAmountBeforeTaxes: Scalars['Int']['output'];
  status: OrderStatus;
  subventionConsumptionsPagination: SubventionConsumptionsPagination;
  subventionsAmount: Scalars['Int']['output'];
  /** @deprecated Will be removed */
  totalAmount: Scalars['Int']['output'];
  /** Total price with taxes that the user will pay (including discounts and potential fees) */
  totalAmountAfterTaxes: Scalars['Int']['output'];
  /** Total price without taxes (including discounts and potential fees) */
  totalAmountBeforeTaxes: Scalars['Int']['output'];
  /** Total allowance available for a specific cart which has been / could have been used by the user. It's used for analytics. */
  totalCartAllowanceAvailable: Scalars['Int']['output'];
  /** @deprecated Use order.discountsAmount */
  totalDiscountsAmount: Scalars['Int']['output'];
  /** @deprecated Use order.feesAmountAfterTaxes */
  totalFeesAmount: Scalars['Int']['output'];
  /** @deprecated Use order.totalAmountAfterTaxes */
  totalPriceAmount: Scalars['Int']['output'];
  totalRefundedAmount: Scalars['Int']['output'];
  /** @deprecated Will be removed */
  totalTaxesAmount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};


export type OrderMangopayTransferPaginationArgs = {
  filter?: InputMaybe<MangopayTransferArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<MangopayTransferArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OrderOrderItemPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<OrderItemArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OrderSubventionConsumptionsPaginationArgs = {
  filter?: InputMaybe<SubventionConsumptionsArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<SubventionConsumptionsArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type OrderCampaignFilter = {
  name?: InputMaybe<FilterStringArgs>;
};

export type OrderEdge = Edge & {
  __typename?: 'OrderEdge';
  cursor: Scalars['ID']['output'];
  node: Order;
};

export type OrderInvoiceGenerateOutput = {
  __typename?: 'OrderInvoiceGenerateOutput';
  invoice: Order;
  order: Order;
};

export type OrderItem = Node & {
  __typename?: 'OrderItem';
  /** Value of the product in the store (with taxes) */
  amount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['Currency']['output'];
  discount?: Maybe<Discount>;
  /** Value discounted by HappyPal to apply on the field "amount" */
  discountAmount: Scalars['Int']['output'];
  discountSubvention?: Maybe<Subvention>;
  /** Value discounted by subvention to apply on the field "amount" */
  discountSubventionAmount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  incident?: Maybe<OrderItemIncident>;
  metadata: OrderItemMetadata;
  offer: Offer;
  productArticlePagination: ProductArticlePagination;
  productOrdersPagination: ProductOrdersPagination;
  productVariant: ProductVariant;
  quantity: Scalars['Int']['output'];
  status: OrderItemStatus;
  subventionPagination: SubventionPagination;
  taxRate?: Maybe<TaxRate>;
  /** @deprecated Will be removed */
  totalAmount: Scalars['Int']['output'];
  /** Total value discounted by HappyPal on this item (field "quantity" * field "discountAmount") */
  totalDiscountAmount: Scalars['Int']['output'];
  /** Total value discounted by subvention on this item (field "quantity" * field "discountSubventionAmount") */
  totalDiscountSubventionAmount: Scalars['Int']['output'];
  /** @deprecated Use orderItem.discountAmount */
  totalDiscountsAmount: Scalars['Int']['output'];
  totalPriceAfterTaxes: Scalars['Int']['output'];
  /** @deprecated Use orderItem.totalPriceAfterTaxes */
  totalPriceAmount: Scalars['Int']['output'];
  totalPriceBeforeTaxes: Scalars['Int']['output'];
  totalRefundedAmount: Scalars['Int']['output'];
  /** @deprecated Will be removed */
  totalTaxesAmount: Scalars['Int']['output'];
  /** Unit price discounted with taxes */
  unitPriceAfterTaxes: Scalars['Int']['output'];
  /** Unit price discounted without taxes */
  unitPriceBeforeTaxes: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type OrderItemProductArticlePaginationArgs = {
  filter?: InputMaybe<ProductArticlePaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<ProductArticlePaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OrderItemProductOrdersPaginationArgs = {
  filter?: InputMaybe<ProductOrdersArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OrderItemSubventionPaginationArgs = {
  filter?: InputMaybe<SubventionArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<SubventionArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type OrderItemArgsSorter = {
  amount?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  quantity?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type OrderItemArticlesAddInput = {
  fileIds: Array<Scalars['ID']['input']>;
};

export type OrderItemArticlesAddOutput = {
  __typename?: 'OrderItemArticlesAddOutput';
  orderItem: OrderItem;
};

export type OrderItemArticlesRemoveInput = {
  articleIds: Array<Scalars['ID']['input']>;
};

export type OrderItemArticlesRemoveOutput = {
  __typename?: 'OrderItemArticlesRemoveOutput';
  orderItem: OrderItem;
};

export type OrderItemEdge = Edge & {
  __typename?: 'OrderItemEdge';
  cursor: Scalars['ID']['output'];
  node: OrderItem;
};

export enum OrderItemIncident {
  OutOfStock = 'OUT_OF_STOCK',
  Unknown = 'UNKNOWN'
}

export type OrderItemMetadata = {
  __typename?: 'OrderItemMetadata';
  beneficiaries?: Maybe<Array<CartItemMetadataBeneficiary>>;
  date?: Maybe<Scalars['Date']['output']>;
  /** Time with format "HH:MM" */
  time?: Maybe<Scalars['String']['output']>;
};

export type OrderItemPagination = Pagination & {
  __typename?: 'OrderItemPagination';
  edges: Array<OrderItemEdge>;
  /** @deprecated Use edges instead */
  nodes: Array<OrderItem>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type OrderItemRefund = Node & {
  __typename?: 'OrderItemRefund';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  orderItem: OrderItem;
  quantity: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type OrderItemRefundEdge = Edge & {
  __typename?: 'OrderItemRefundEdge';
  cursor: Scalars['ID']['output'];
  node: OrderItemRefund;
};

export type OrderItemRefundPagination = CorePagination & {
  __typename?: 'OrderItemRefundPagination';
  edges: Array<OrderItemRefundEdge>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export enum OrderItemStatus {
  Canceled = 'CANCELED',
  Created = 'CREATED',
  DeliveredFull = 'DELIVERED_FULL',
  DeliveredPartial = 'DELIVERED_PARTIAL',
  Draft = 'DRAFT',
  Processing = 'PROCESSING',
  RefundedFull = 'REFUNDED_FULL',
  RefundedPartial = 'REFUNDED_PARTIAL'
}

export type OrderItemStatusUpdateOutput = {
  __typename?: 'OrderItemStatusUpdateOutput';
  orderItem: OrderItem;
};

export type OrderPagination = Pagination & {
  __typename?: 'OrderPagination';
  edges: Array<OrderEdge>;
  nodes: Array<Order>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type OrderPaginationArgsFilter = {
  campaign?: InputMaybe<OrderCampaignFilter>;
  id?: InputMaybe<FilterIdArgs>;
  invoice?: InputMaybe<InvoicePaginationArgsFilter>;
  mangopayTransfer?: InputMaybe<MangopayTransferRelationFilter>;
  status?: InputMaybe<OrderPaginationArgsFilterStatus>;
  user?: InputMaybe<UsersArgsFilter>;
};

export type OrderPaginationArgsFilterStatus = {
  in?: InputMaybe<Array<OrderStatus>>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<OrderStatus>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<OrderStatus>;
  notIn?: InputMaybe<Array<OrderStatus>>;
};

export type OrderPaginationArgsSorter = {
  amountTotal?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type OrderRefund = Node & {
  __typename?: 'OrderRefund';
  createdAt: Scalars['DateTime']['output'];
  feesAmount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  order: Order;
  orderItemRefundPagination: OrderItemRefundPagination;
  status: OrderRefundStatus;
  stripeAmount: Scalars['Int']['output'];
  stripeRefund?: Maybe<StripeRefund>;
  subventionRefundPagination: SubventionRefundPagination;
  updatedAt: Scalars['DateTime']['output'];
};


export type OrderRefundOrderItemRefundPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<Array<CorePaginationSorter>>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type OrderRefundSubventionRefundPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<Array<CorePaginationSorter>>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type OrderRefundCreateInput = {
  feesAmount?: InputMaybe<Scalars['Int']['input']>;
  items: Array<OrderRefundCreateInputItem>;
  stripeAmount: Scalars['Int']['input'];
  subventions: Array<OrderRefundCreateInputSubvention>;
};

export type OrderRefundCreateInputItem = {
  orderItemId: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
};

export type OrderRefundCreateInputSubvention = {
  amount: Scalars['Int']['input'];
  subventionId: Scalars['ID']['input'];
};

export type OrderRefundCreateOutput = {
  __typename?: 'OrderRefundCreateOutput';
  order: Order;
  orderRefund: OrderRefund;
};

export type OrderRefundEdge = Edge & {
  __typename?: 'OrderRefundEdge';
  cursor: Scalars['ID']['output'];
  node: OrderRefund;
};

export type OrderRefundPagination = CorePagination & {
  __typename?: 'OrderRefundPagination';
  edges: Array<OrderRefundEdge>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type OrderRefundPaginationFilter = {
  id?: InputMaybe<FilterIdArgs>;
  orderIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  statuses?: InputMaybe<Array<OrderRefundStatus>>;
};

export type OrderRefundPaginationSorter = {
  direction: SortDirection;
  field: OrderRefundPaginationSorterField;
};

export enum OrderRefundPaginationSorterField {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type OrderRefundProcessOutput = {
  __typename?: 'OrderRefundProcessOutput';
  order: Order;
  orderRefund: OrderRefund;
};

export type OrderRefundRejectOutput = {
  __typename?: 'OrderRefundRejectOutput';
  orderRefund: OrderRefund;
};

export type OrderRefundRelationFilter = {
  NONE?: InputMaybe<OrderRefundPaginationFilter>;
  SOME?: InputMaybe<OrderRefundPaginationFilter>;
};

export enum OrderRefundStatus {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Processed = 'PROCESSED',
  Rejected = 'REJECTED',
  Validated = 'VALIDATED'
}

export type OrderRelationFilter = {
  EVERY?: InputMaybe<OrderPaginationArgsFilter>;
  NONE?: InputMaybe<OrderPaginationArgsFilter>;
  SOME?: InputMaybe<OrderPaginationArgsFilter>;
};

export type OrderResendOrderConfirmationEmailOutput = {
  __typename?: 'OrderResendOrderConfirmationEmailOutput';
  order: Order;
};

export enum OrderStatus {
  Canceled = 'CANCELED',
  DeliveredFull = 'DELIVERED_FULL',
  DeliveredPartial = 'DELIVERED_PARTIAL',
  Draft = 'DRAFT',
  Paid = 'PAID',
  RefundedFull = 'REFUNDED_FULL',
  RefundedPartial = 'REFUNDED_PARTIAL'
}

export type Pagination = {
  edges: Array<Edge>;
  nodes: Array<Node>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type PaginationEnumFilter = {
  __typename?: 'PaginationEnumFilter';
  count: Scalars['Int']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type PaginationPageInfo = {
  __typename?: 'PaginationPageInfo';
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  pageCount: Scalars['Int']['output'];
};

export type PaymentLimit = DeletableNode & Node & {
  __typename?: 'PaymentLimit';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  validUntil: Scalars['DateTime']['output'];
  /** The new limit to be used */
  value: Scalars['Int']['output'];
};

export type PaymentLimitCreateInput = {
  amount: Scalars['Int']['input'];
  userId: Scalars['ID']['input'];
  validUntil: Scalars['DateTime']['input'];
};

export type PaymentLimitCreateOutput = {
  __typename?: 'PaymentLimitCreateOutput';
  paymentLimit: PaymentLimit;
  user: User;
};

export type PaymentLimitDeleteOutput = {
  __typename?: 'PaymentLimitDeleteOutput';
  paymentLimit: PaymentLimit;
  user: User;
};

export type PercentageDiscount = {
  __typename?: 'PercentageDiscount';
  percent: Scalars['Float']['output'];
};

export type Picture = Node & {
  __typename?: 'Picture';
  createdAt: Scalars['DateTime']['output'];
  file: File;
  /** hash representing the blurhash of the image. https://blurha.sh/ */
  hash: Scalars['String']['output'];
  height: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  width: Scalars['Int']['output'];
};

export type PictureCreateInput = {
  fileName: Scalars['String']['input'];
  hash?: InputMaybe<Scalars['String']['input']>;
  height: Scalars['Int']['input'];
  mimetype: Scalars['String']['input'];
  size: Scalars['Int']['input'];
  type: FileType;
  width: Scalars['Int']['input'];
};

export type PictureCreateOutput = {
  __typename?: 'PictureCreateOutput';
  fields: Scalars['JSONObject']['output'];
  picture: Picture;
  url: Scalars['String']['output'];
};

export type PictureFilterArgs = {
  height?: InputMaybe<FilterIntArgs>;
  width?: InputMaybe<FilterIntArgs>;
};

export type PictureStock = Node & {
  __typename?: 'PictureStock';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  picture: Picture;
  updatedAt: Scalars['DateTime']['output'];
};

export type PictureStockCreateOutput = {
  __typename?: 'PictureStockCreateOutput';
  pictureStock: PictureStock;
};

export type PictureStockDeleteOutput = {
  __typename?: 'PictureStockDeleteOutput';
  pictureStock: PictureStock;
};

export type PictureStockEdge = Edge & {
  __typename?: 'PictureStockEdge';
  cursor: Scalars['ID']['output'];
  node: PictureStock;
};

export type PictureStockPagination = Pagination & {
  __typename?: 'PictureStockPagination';
  edges: Array<PictureStockEdge>;
  nodes: Array<PictureStock>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type PointOfContact = DeletableNode & Node & Publishable & {
  __typename?: 'PointOfContact';
  company: Company;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  jobTitle?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Picture>;
  position: Scalars['Int']['output'];
  publicationPagination: PublicationPagination;
  updatedAt: Scalars['DateTime']['output'];
};


export type PointOfContactPublicationPaginationArgs = {
  filter?: InputMaybe<PublicationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<PublicationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type PointOfContactCreateInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  pictureId?: InputMaybe<Scalars['String']['input']>;
};

export type PointOfContactCreateOutput = {
  __typename?: 'PointOfContactCreateOutput';
  pointOfContact: PointOfContact;
};

export type PointOfContactCreatedEventData = EventData & {
  __typename?: 'PointOfContactCreatedEventData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  jobTitle?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
};

export type PointOfContactDeleteOutput = {
  __typename?: 'PointOfContactDeleteOutput';
  pointOfContact: PointOfContact;
};

export type PointOfContactDeletedEventData = EventData & {
  __typename?: 'PointOfContactDeletedEventData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
};

export type PointOfContactEdge = Edge & {
  __typename?: 'PointOfContactEdge';
  cursor: Scalars['ID']['output'];
  node: PointOfContact;
};

export type PointOfContactSetPositionInput = {
  position: Scalars['Int']['input'];
};

export type PointOfContactSetPositionOutput = {
  __typename?: 'PointOfContactSetPositionOutput';
  pointOfContact: PointOfContact;
};

export type PointOfContactUpdateInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  pictureId?: InputMaybe<Scalars['String']['input']>;
};

export type PointOfContactUpdateOutput = {
  __typename?: 'PointOfContactUpdateOutput';
  pointOfContact: PointOfContact;
};

export type PointOfContactUpdatedEventData = EventData & {
  __typename?: 'PointOfContactUpdatedEventData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type PointsOfContactPagination = Pagination & {
  __typename?: 'PointsOfContactPagination';
  edges: Array<PointOfContactEdge>;
  nodes: Array<PointOfContact>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type Position = {
  latitude: Scalars['Latitude']['input'];
  longitude: Scalars['Longitude']['input'];
};

export type Product = DeletableNode & IProductTranslation & Node & Translatable & Translation & {
  __typename?: 'Product';
  brand: Brand;
  catalog: ProductCatalog;
  companyDiscountPagination: DiscountPagination;
  createdAt: Scalars['DateTime']['output'];
  defaultDiscount?: Maybe<Discount>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The best discount available for the viewer on the product */
  discount?: Maybe<Discount>;
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  expiresInDays?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  locale: Scalars['ID']['output'];
  metadata: ProductMetadata;
  name: Scalars['String']['output'];
  offer?: Maybe<Offer>;
  productVariantPagination: ProductVariantPagination;
  providerDiscount?: Maybe<Discount>;
  taxRatePagination: TaxRatePagination;
  /** Args is not used. */
  translationsPagination: ProductTranslationsPagination;
  type: ProductType;
  updatedAt: Scalars['DateTime']['output'];
};


export type ProductCompanyDiscountPaginationArgs = {
  filter?: InputMaybe<DiscountArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<DiscountArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductProductVariantPaginationArgs = {
  filter?: InputMaybe<ProductVariantArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<ProductVariantArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductTaxRatePaginationArgs = {
  filter?: InputMaybe<TaxRateArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<TaxRateArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductTranslationsPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

/** A ProductArticle represents a Product bought */
export type ProductArticle = Node & {
  __typename?: 'ProductArticle';
  barcodeFormat?: Maybe<Scalars['String']['output']>;
  barcodeValue?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  expiresAt?: Maybe<Scalars['Date']['output']>;
  file?: Maybe<File>;
  id: Scalars['ID']['output'];
  orderItem?: Maybe<OrderItem>;
  pin?: Maybe<Scalars['String']['output']>;
  productVariant: ProductVariant;
  updatedAt: Scalars['DateTime']['output'];
  url?: Maybe<ExternalUrl>;
};

export type ProductArticleCreateBulkInput = {
  fileIds: Array<Scalars['ID']['input']>;
};

export type ProductArticleCreateBulkOutput = {
  __typename?: 'ProductArticleCreateBulkOutput';
  productArticles: Array<ProductArticle>;
  productVariant: ProductVariant;
};

export type ProductArticleCreateInput = {
  barcodeFormat?: InputMaybe<Scalars['String']['input']>;
  barcodeValue?: InputMaybe<Scalars['String']['input']>;
  expiresAt?: InputMaybe<Scalars['Date']['input']>;
  fileId?: InputMaybe<Scalars['ID']['input']>;
  pin?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type ProductArticleCreateOutput = {
  __typename?: 'ProductArticleCreateOutput';
  productArticle: ProductArticle;
};

export type ProductArticleDeleteOutput = {
  __typename?: 'ProductArticleDeleteOutput';
  productArticle: ProductArticle;
};

export type ProductArticleEdge = Edge & {
  __typename?: 'ProductArticleEdge';
  cursor: Scalars['ID']['output'];
  node: ProductArticle;
};

export type ProductArticlePagination = Pagination & {
  __typename?: 'ProductArticlePagination';
  edges: Array<ProductArticleEdge>;
  nodes: Array<ProductArticle>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ProductArticlePaginationArgsFilter = {
  isSold?: InputMaybe<FilterBoolArgs>;
};

export type ProductArticlePaginationArgsSorter = {
  createdAt?: InputMaybe<SortDirection>;
  expiresAt?: InputMaybe<SortDirection>;
  fileName?: InputMaybe<SortDirection>;
  isUsed?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type ProductCatalog = Node & {
  __typename?: 'ProductCatalog';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  identifier: Scalars['String']['output'];
  name: Scalars['String']['output'];
  productPagination: ProductPagination;
  productVariantPagination: ProductVariantPagination;
  provider: ProductProvider;
  synchronizationsPagination: ProductSynchronizationsPagination;
  updatedAt: Scalars['DateTime']['output'];
};


export type ProductCatalogProductPaginationArgs = {
  filter?: InputMaybe<ProductPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<ProductPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductCatalogProductVariantPaginationArgs = {
  filter?: InputMaybe<ProductVariantArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<ProductVariantArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductCatalogSynchronizationsPaginationArgs = {
  filter?: InputMaybe<ProductSynchronizationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<ProductSynchronizationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductCatalogArgsFilter = {
  id?: InputMaybe<FilterIdArgs>;
  name?: InputMaybe<FilterStringArgs>;
  provider?: InputMaybe<ProductProviderArgsFilter>;
};

export type ProductCatalogArgsSorter = {
  createdAt?: InputMaybe<SortDirection>;
};

export type ProductCatalogCreateInput = {
  identifier: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type ProductCatalogCreateOutput = {
  __typename?: 'ProductCatalogCreateOutput';
  productCatalog: ProductCatalog;
};

export type ProductCatalogEdge = Edge & {
  __typename?: 'ProductCatalogEdge';
  cursor: Scalars['ID']['output'];
  node: ProductCatalog;
};

export type ProductCatalogPagination = Pagination & {
  __typename?: 'ProductCatalogPagination';
  edges: Array<ProductCatalogEdge>;
  nodes: Array<ProductCatalog>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ProductCreateInput = {
  SKU: Scalars['String']['input'];
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  expiresInDays?: InputMaybe<Scalars['Int']['input']>;
  taxRateIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  translations: Array<ProductTranslationCreateInput>;
  type: ProductType;
};

export type ProductCreateOutput = {
  __typename?: 'ProductCreateOutput';
  product: Product;
};

export type ProductEdge = Edge & {
  __typename?: 'ProductEdge';
  cursor: Scalars['ID']['output'];
  node: Product;
};

export type ProductMetadata = {
  __typename?: 'ProductMetadata';
  /** Wether or not the actual beneficary birthDate is required */
  beneficiaryBirthdateRequired?: Maybe<Scalars['Boolean']['output']>;
  /** Wether or not the actual beneficary firstName is required */
  beneficiaryFirstNameRequired?: Maybe<Scalars['Boolean']['output']>;
  /** Wether or not the actual beneficary lastName is required */
  beneficiaryLastNameRequired?: Maybe<Scalars['Boolean']['output']>;
  /** Wether or not the arrival date is required */
  dateRequired?: Maybe<Scalars['Boolean']['output']>;
  /** Wether or not the the contact informations can be shared across multiple of the same product in a cart */
  sharedBeneficaryInformation?: Maybe<Scalars['Boolean']['output']>;
  /** Wether or not the arrival time is required */
  timeRequired?: Maybe<Scalars['Boolean']['output']>;
};

export type ProductOrder = Node & {
  __typename?: 'ProductOrder';
  catalog: ProductCatalog;
  createdAt: Scalars['DateTime']['output'];
  eventsPagination: ProductOrderEventsPagination;
  id: Scalars['ID']['output'];
  isRetryable: Scalars['Boolean']['output'];
  lineItem: OrderItem;
  product: Product;
  productVariant: ProductVariant;
  provider: ProductProvider;
  status: ProductOrderStatus;
  updatedAt: Scalars['DateTime']['output'];
};


export type ProductOrderEventsPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductOrderEdge = Edge & {
  __typename?: 'ProductOrderEdge';
  cursor: Scalars['ID']['output'];
  node: ProductOrder;
};

export type ProductOrderEvent = Node & {
  __typename?: 'ProductOrderEvent';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  providerCode?: Maybe<Scalars['String']['output']>;
  providerReason?: Maybe<Scalars['String']['output']>;
  type: ProductOrderEventEnum;
  updatedAt: Scalars['DateTime']['output'];
};

export type ProductOrderEventEdge = Edge & {
  __typename?: 'ProductOrderEventEdge';
  cursor: Scalars['ID']['output'];
  node: ProductOrderEvent;
};

export enum ProductOrderEventEnum {
  DeliveredFull = 'DELIVERED_FULL',
  DeliveredPartial = 'DELIVERED_PARTIAL',
  FailedAccount = 'FAILED_ACCOUNT',
  FailedBadRequest = 'FAILED_BAD_REQUEST',
  FailedGeneric = 'FAILED_GENERIC',
  FailedProductDisabled = 'FAILED_PRODUCT_DISABLED',
  FailedProductNotFound = 'FAILED_PRODUCT_NOT_FOUND',
  FailedProductOutOfStock = 'FAILED_PRODUCT_OUT_OF_STOCK',
  FailedUnreachable = 'FAILED_UNREACHABLE',
  OrderCreated = 'ORDER_CREATED'
}

export type ProductOrderEventsPagination = Pagination & {
  __typename?: 'ProductOrderEventsPagination';
  edges: Array<ProductOrderEventEdge>;
  nodes: Array<ProductOrderEvent>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ProductOrderRetryOutput = {
  __typename?: 'ProductOrderRetryOutput';
  productOrder: ProductOrder;
};

export enum ProductOrderStatus {
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Failed = 'FAILED',
  Processing = 'PROCESSING'
}

export type ProductOrdersArgsFilter = {
  lineItems?: InputMaybe<ProductOrdersArgsFilterLineItems>;
};

export type ProductOrdersArgsFilterLineItems = {
  include?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ProductOrdersPagination = Pagination & {
  __typename?: 'ProductOrdersPagination';
  edges: Array<ProductOrderEdge>;
  nodes: Array<ProductOrder>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ProductPagination = Pagination & {
  __typename?: 'ProductPagination';
  edges: Array<ProductEdge>;
  nodes: Array<Product>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ProductPaginationArgsFilter = {
  brand?: InputMaybe<BrandsArgsFilter>;
  catalog?: InputMaybe<ProductCatalogArgsFilter>;
  expiresAt?: InputMaybe<FilterDateArgs>;
  expiresInDays?: InputMaybe<FilterIntArgs>;
  hasDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  hasDiscountAvailable?: InputMaybe<FilterBoolArgs>;
  id?: InputMaybe<FilterIdArgs>;
  name?: InputMaybe<ProductPaginationArgsFilterName>;
  offer?: InputMaybe<OffersArgsFilter>;
  type?: InputMaybe<ProductPaginationArgsFilterTypes>;
};

export type ProductPaginationArgsFilterName = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type ProductPaginationArgsFilterTypes = {
  in?: InputMaybe<Array<ProductType>>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<ProductType>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<ProductType>;
  notIn?: InputMaybe<Array<ProductType>>;
};

export type ProductPaginationArgsSorter = {
  brand?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  expiresAt?: InputMaybe<SortDirection>;
  expiresInDays?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  offer?: InputMaybe<SortDirection>;
  type?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
  variantCount?: InputMaybe<SortDirection>;
};

export type ProductProvider = Node & {
  __typename?: 'ProductProvider';
  catalogPagination: ProductCatalogPagination;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  identifier: ProductProviderIdentifier;
  isInternal: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  productPagination: ProductPagination;
  productVariantPagination: ProductVariantPagination;
  synchronizationsPagination: ProductSynchronizationsPagination;
  updatedAt: Scalars['DateTime']['output'];
};


export type ProductProviderCatalogPaginationArgs = {
  filter?: InputMaybe<ProductCatalogArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<ProductCatalogArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductProviderProductPaginationArgs = {
  filter?: InputMaybe<ProductPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<ProductPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductProviderProductVariantPaginationArgs = {
  filter?: InputMaybe<ProductVariantArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<ProductVariantArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductProviderSynchronizationsPaginationArgs = {
  filter?: InputMaybe<ProductSynchronizationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<ProductSynchronizationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductProviderArgsFilter = {
  id?: InputMaybe<FilterIdArgs>;
  isInternal?: InputMaybe<FilterBoolArgs>;
  name?: InputMaybe<FilterStringArgs>;
};

export type ProductProviderArgsSorter = {
  createdAt?: InputMaybe<SortDirection>;
};

export type ProductProviderEdge = Edge & {
  __typename?: 'ProductProviderEdge';
  cursor: Scalars['ID']['output'];
  node: ProductProvider;
};

/** Product Provider Identifier */
export enum ProductProviderIdentifier {
  Happypal = 'HAPPYPAL',
  Ifeelgoods = 'IFEELGOODS',
  Reducce = 'REDUCCE',
  Ugc = 'UGC',
  Wegift = 'WEGIFT'
}

export type ProductProviderPagination = Pagination & {
  __typename?: 'ProductProviderPagination';
  edges: Array<ProductProviderEdge>;
  nodes: Array<ProductProvider>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ProductSynchronization = Node & {
  __typename?: 'ProductSynchronization';
  author: User;
  brandsCreatedPagination: BrandsPagination;
  catalog: ProductCatalog;
  createdAt: Scalars['DateTime']['output'];
  createdCampaignPagination: CampaignPagination;
  discountsCreatedPagination: DiscountPagination;
  discountsDeletedPagination: DiscountPagination;
  discountsUpdatedPagination: DiscountPagination;
  error?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  offersCreatedPagination: OffersPagination;
  offersDeletedPagination: OffersPagination;
  offersUpdatedPagination: OffersPagination;
  productVariantsCreatedPagination: ProductVariantPagination;
  productVariantsDeletedPagination: ProductVariantPagination;
  productVariantsUpdatedPagination: ProductVariantPagination;
  productsCreatedPagination: ProductPagination;
  productsDeletedPagination: ProductPagination;
  productsUpdatedPagination: ProductPagination;
  provider: ProductProvider;
  status: ProductSynchronizationStatus;
  updatedAt: Scalars['DateTime']['output'];
};


export type ProductSynchronizationBrandsCreatedPaginationArgs = {
  filter?: InputMaybe<BrandsArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<BrandsArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductSynchronizationCreatedCampaignPaginationArgs = {
  filter?: InputMaybe<CampaignPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CampaignPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductSynchronizationDiscountsCreatedPaginationArgs = {
  filter?: InputMaybe<DiscountArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<DiscountArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductSynchronizationDiscountsDeletedPaginationArgs = {
  filter?: InputMaybe<DiscountArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<DiscountArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductSynchronizationDiscountsUpdatedPaginationArgs = {
  filter?: InputMaybe<DiscountArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<DiscountArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductSynchronizationOffersCreatedPaginationArgs = {
  filter?: InputMaybe<OffersArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<OffersArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductSynchronizationOffersDeletedPaginationArgs = {
  filter?: InputMaybe<OffersArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<OffersArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductSynchronizationOffersUpdatedPaginationArgs = {
  filter?: InputMaybe<OffersArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<OffersArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductSynchronizationProductVariantsCreatedPaginationArgs = {
  filter?: InputMaybe<ProductVariantArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<ProductVariantArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductSynchronizationProductVariantsDeletedPaginationArgs = {
  filter?: InputMaybe<ProductVariantArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<ProductVariantArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductSynchronizationProductVariantsUpdatedPaginationArgs = {
  filter?: InputMaybe<ProductVariantArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<ProductVariantArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductSynchronizationProductsCreatedPaginationArgs = {
  filter?: InputMaybe<ProductPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<ProductPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductSynchronizationProductsDeletedPaginationArgs = {
  filter?: InputMaybe<ProductPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<ProductPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductSynchronizationProductsUpdatedPaginationArgs = {
  filter?: InputMaybe<ProductPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<ProductPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductSynchronizationArgsFilter = {
  author?: InputMaybe<UsersArgsFilter>;
  catalog?: InputMaybe<ProductCatalogArgsFilter>;
  createdAt?: InputMaybe<FilterDateArgs>;
  productVariantCreatedCount?: InputMaybe<FilterIntArgs>;
  productVariantDeletedCount?: InputMaybe<FilterIntArgs>;
  productVariantUpdatedCount?: InputMaybe<FilterIntArgs>;
  provider?: InputMaybe<ProductProviderArgsFilter>;
  status?: InputMaybe<ProductSynchronizationStatusArgs>;
};

export type ProductSynchronizationArgsSorter = {
  createdAt?: InputMaybe<SortDirection>;
  productVariantCreatedCount?: InputMaybe<SortDirection>;
  productVariantDeletedCount?: InputMaybe<SortDirection>;
  productVariantUpdatedCount?: InputMaybe<SortDirection>;
};

export type ProductSynchronizationAuthorOutput = {
  __typename?: 'ProductSynchronizationAuthorOutput';
  authors: Array<AuthorOutput>;
};

export type ProductSynchronizationCreateInput = {
  catalogId: Scalars['ID']['input'];
  providerId: Scalars['ID']['input'];
};

export type ProductSynchronizationCreateOutput = {
  __typename?: 'ProductSynchronizationCreateOutput';
  catalog: ProductCatalog;
  provider: ProductProvider;
  synchronization: ProductSynchronization;
};

export type ProductSynchronizationEdge = Edge & {
  __typename?: 'ProductSynchronizationEdge';
  cursor: Scalars['ID']['output'];
  node: ProductSynchronization;
};

export enum ProductSynchronizationStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Pending = 'PENDING'
}

export type ProductSynchronizationStatusArgs = {
  in?: InputMaybe<Array<ProductSynchronizationStatus>>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<ProductSynchronizationStatus>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<ProductSynchronizationStatus>;
  notIn?: InputMaybe<Array<ProductSynchronizationStatus>>;
};

export type ProductSynchronizationsPagination = Pagination & {
  __typename?: 'ProductSynchronizationsPagination';
  edges: Array<ProductSynchronizationEdge>;
  nodes: Array<ProductSynchronization>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ProductTranslation = IProductTranslation & Node & Translation & {
  __typename?: 'ProductTranslation';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  locale: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ProductTranslationCreateInput = {
  localeId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type ProductTranslationCreateOutput = {
  __typename?: 'ProductTranslationCreateOutput';
  product: Product;
  productTranslation: ProductTranslation;
};

export type ProductTranslationDeleteOutput = {
  __typename?: 'ProductTranslationDeleteOutput';
  product: Product;
  productTranslation: ProductTranslation;
};

export type ProductTranslationEdge = Edge & TranslationEdge & {
  __typename?: 'ProductTranslationEdge';
  cursor: Scalars['ID']['output'];
  node: ProductTranslation;
};

export type ProductTranslationUpdateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ProductTranslationUpdateOutput = {
  __typename?: 'ProductTranslationUpdateOutput';
  product: Product;
  productTranslation: ProductTranslation;
};

export type ProductTranslationsPagination = Pagination & TranslationsPagination & {
  __typename?: 'ProductTranslationsPagination';
  edges: Array<ProductTranslationEdge>;
  nodes: Array<ProductTranslation>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export enum ProductType {
  GiftCard = 'GIFT_CARD',
  Ticket = 'TICKET'
}

export type ProductUpdateInput = {
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  expiresInDays?: InputMaybe<Scalars['Int']['input']>;
  taxRateIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ProductUpdateOutput = {
  __typename?: 'ProductUpdateOutput';
  product: Product;
};

export type ProductVariant = {
  SKU: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /**
   *
   *       -1 = unlimited.
   *       0 = out of stock.
   *       > 0 = available.
   *
   */
  inventoryQuantity: Scalars['Int']['output'];
  isActivated: Scalars['Boolean']['output'];
  priceCurrency: Scalars['Currency']['output'];
  product: Product;
  productArticlePagination: ProductArticlePagination;
  updatedAt: Scalars['DateTime']['output'];
};


export type ProductVariantProductArticlePaginationArgs = {
  filter?: InputMaybe<ProductArticlePaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<ProductArticlePaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductVariantActivateOutput = {
  __typename?: 'ProductVariantActivateOutput';
  productVariant: ProductVariant;
};

export type ProductVariantArgsFilter = {
  SKU?: InputMaybe<FilterStringArgs>;
  product?: InputMaybe<ProductPaginationArgsFilter>;
};

export type ProductVariantArgsSorter = {
  createdAt?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type ProductVariantCreateInput = {
  SKU: Scalars['String']['input'];
  priceCurrency: Scalars['Currency']['input'];
  priceValue: Scalars['Int']['input'];
};

export type ProductVariantCreateOutput = {
  __typename?: 'ProductVariantCreateOutput';
  productVariant: ProductVariant;
};

export type ProductVariantDeactivateOutput = {
  __typename?: 'ProductVariantDeactivateOutput';
  productVariant: ProductVariant;
};

export type ProductVariantEdge = Edge & {
  __typename?: 'ProductVariantEdge';
  cursor: Scalars['ID']['output'];
  node: ProductVariant;
};

export type ProductVariantGetDynamicPriceInput = {
  date: Scalars['Date']['input'];
  time?: InputMaybe<Scalars['String']['input']>;
};

export type ProductVariantGetDynamicPriceOutput = {
  __typename?: 'ProductVariantGetDynamicPriceOutput';
  /** Product value taxed without discount */
  amount: Scalars['Int']['output'];
  currency: Scalars['Currency']['output'];
  date: Scalars['DateTime']['output'];
  /** Discount value to apply on "amount" */
  discountAmount: Scalars['Int']['output'];
  inventoryQuantity: Scalars['Int']['output'];
  productVariant: ProductVariant;
  taxRate: Scalars['Float']['output'];
  /** Unit price discounted and taxed */
  unitPriceAfterTaxes: Scalars['Int']['output'];
  /** Unit price discounted without taxes */
  unitPriceBeforeTaxes: Scalars['Int']['output'];
};

export type ProductVariantPagination = Pagination & {
  __typename?: 'ProductVariantPagination';
  edges: Array<ProductVariantEdge>;
  nodes: Array<ProductVariant>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ProductVariantPriceDynamic = DeletableNode & Node & ProductVariant & {
  __typename?: 'ProductVariantPriceDynamic';
  SKU: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /**
   *
   *       -1 = unlimited.
   *       0 = out of stock.
   *       > 0 = available.
   *
   */
  inventoryQuantity: Scalars['Int']['output'];
  isActivated: Scalars['Boolean']['output'];
  priceCurrency: Scalars['Currency']['output'];
  priceValue: Scalars['Int']['output'];
  product: Product;
  productArticlePagination: ProductArticlePagination;
  updatedAt: Scalars['DateTime']['output'];
};


export type ProductVariantPriceDynamicProductArticlePaginationArgs = {
  filter?: InputMaybe<ProductArticlePaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<ProductArticlePaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductVariantPriceFixed = DeletableNode & Node & ProductVariant & {
  __typename?: 'ProductVariantPriceFixed';
  SKU: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /**
   *
   *       -1 = unlimited.
   *       0 = out of stock.
   *       > 0 = available.
   *
   */
  inventoryQuantity: Scalars['Int']['output'];
  isActivated: Scalars['Boolean']['output'];
  priceCurrency: Scalars['Currency']['output'];
  priceValue: Scalars['Int']['output'];
  product: Product;
  productArticlePagination: ProductArticlePagination;
  updatedAt: Scalars['DateTime']['output'];
};


export type ProductVariantPriceFixedProductArticlePaginationArgs = {
  filter?: InputMaybe<ProductArticlePaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<ProductArticlePaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductVariantPriceRange = DeletableNode & Node & ProductVariant & {
  __typename?: 'ProductVariantPriceRange';
  SKU: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /**
   *
   *       -1 = unlimited.
   *       0 = out of stock.
   *       > 0 = available.
   *
   */
  inventoryQuantity: Scalars['Int']['output'];
  isActivated: Scalars['Boolean']['output'];
  priceCurrency: Scalars['Currency']['output'];
  priceMax: Scalars['Int']['output'];
  priceMin: Scalars['Int']['output'];
  product: Product;
  productArticlePagination: ProductArticlePagination;
  updatedAt: Scalars['DateTime']['output'];
};


export type ProductVariantPriceRangeProductArticlePaginationArgs = {
  filter?: InputMaybe<ProductArticlePaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<ProductArticlePaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductVariantStockUpdateInput = {
  quantity: Scalars['Int']['input'];
};

export type ProductVariantStockUpdateOutput = {
  __typename?: 'ProductVariantStockUpdateOutput';
  productVariant: ProductVariant;
};

export type PublicSsoProvider = {
  __typename?: 'PublicSSOProvider';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type Publication = DeletableNode & Highlightable & Node & {
  __typename?: 'Publication';
  attachmentsPagination: FilesPagination;
  author?: Maybe<User>;
  cohort?: Maybe<CohortUser>;
  company?: Maybe<Company>;
  cover?: Maybe<Picture>;
  coverURL?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  descriptionDelta: Scalars['Delta']['output'];
  descriptionHTML: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isHighlighted: Scalars['Boolean']['output'];
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  target?: Maybe<PublicationTarget>;
  targetType?: Maybe<PublicationTargetType>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type PublicationAttachmentsPaginationArgs = {
  filter?: InputMaybe<FilesFilterArgs>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type PublicationAddAttachmentInput = {
  attachmentIds: Array<Scalars['ID']['input']>;
};

export type PublicationAddAttachmentOutput = {
  __typename?: 'PublicationAddAttachmentOutput';
  publication: Publication;
};

export type PublicationArgsFilter = {
  AND?: InputMaybe<Array<PublicationArgsFilter>>;
  OR?: InputMaybe<Array<PublicationArgsFilter>>;
  company?: InputMaybe<CompaniesArgsFilter>;
  publishedAt?: InputMaybe<FilterDateArgs>;
  targetId?: InputMaybe<FilterIdArgs>;
  targetType?: InputMaybe<PublicationArgsFilterTargetType>;
};

export type PublicationArgsFilterTargetType = {
  in?: InputMaybe<Array<PublicationTargetType>>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<PublicationTargetType>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<PublicationTargetType>;
  notIn?: InputMaybe<Array<PublicationTargetType>>;
};

export type PublicationArgsSorter = {
  createdAt?: InputMaybe<SortDirection>;
  publishedAt?: InputMaybe<SortDirection>;
};

export type PublicationCreateInput = {
  attachmentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  autoPublish?: InputMaybe<Scalars['Boolean']['input']>;
  cohortId?: InputMaybe<Scalars['ID']['input']>;
  cohortTarget?: InputMaybe<CohortTarget>;
  coverId?: InputMaybe<Scalars['ID']['input']>;
  description: Scalars['Delta']['input'];
  selectionMode?: InputMaybe<CohortSelectionMode>;
  title: Scalars['String']['input'];
  usersArgsFilter?: InputMaybe<UsersArgsFilter>;
};

export type PublicationCreateOutput = {
  __typename?: 'PublicationCreateOutput';
  publication: Publication;
};

export type PublicationCreatedData = EventData & WithCover & {
  __typename?: 'PublicationCreatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  cohortId?: Maybe<TrackableString>;
  cohortTarget?: Maybe<TrackableCohortTarget>;
  cover?: Maybe<Picture>;
  description: TrackableDelta;
  title: TrackableString;
};

export type PublicationDeleteOutput = {
  __typename?: 'PublicationDeleteOutput';
  publication: Publication;
};

export type PublicationDeletedData = EventData & {
  __typename?: 'PublicationDeletedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
};

export type PublicationEdge = Edge & {
  __typename?: 'PublicationEdge';
  cursor: Scalars['ID']['output'];
  node: Publication;
};

export type PublicationPagination = Pagination & {
  __typename?: 'PublicationPagination';
  edges: Array<PublicationEdge>;
  nodes: Array<Publication>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
  unreadCount: Scalars['Int']['output'];
};

export type PublicationPublishedData = EventData & {
  __typename?: 'PublicationPublishedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
};

export type PublicationRemoveAttachmentInput = {
  attachmentIds: Array<Scalars['ID']['input']>;
};

export type PublicationRemoveAttachmentOutput = {
  __typename?: 'PublicationRemoveAttachmentOutput';
  publication: Publication;
};

/** Represents the publication's target */
export type PublicationTarget = Article | Campaign | CompanyDocument | CompanyFolder | PointOfContact | SubventionDiscount | SubventionDraft | SubventionFund;

/** Represents the publication's target type */
export enum PublicationTargetType {
  Article = 'ARTICLE',
  Campaign = 'CAMPAIGN',
  CompanyDocument = 'COMPANY_DOCUMENT',
  CompanyFolder = 'COMPANY_FOLDER',
  PointOfContact = 'POINT_OF_CONTACT',
  Subvention = 'SUBVENTION'
}

export type PublicationUnpublishedData = EventData & {
  __typename?: 'PublicationUnpublishedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
};

export type PublicationUpdateInput = {
  attachmentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  autoPublish?: InputMaybe<Scalars['Boolean']['input']>;
  cohortId?: InputMaybe<Scalars['ID']['input']>;
  cohortTarget?: InputMaybe<CohortTarget>;
  coverId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['Delta']['input']>;
  selectionMode?: InputMaybe<CohortSelectionMode>;
  title?: InputMaybe<Scalars['String']['input']>;
  usersArgsFilter?: InputMaybe<UsersArgsFilter>;
};

export type PublicationUpdateOutput = {
  __typename?: 'PublicationUpdateOutput';
  publication: Publication;
};

export type PublicationUpdatedData = EventData & WithCover & {
  __typename?: 'PublicationUpdatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  cohortId?: Maybe<TrackableString>;
  cohortTarget?: Maybe<TrackableCohortTarget>;
  cover?: Maybe<Picture>;
  description?: Maybe<TrackableDelta>;
  title?: Maybe<TrackableString>;
};

export type PublicationsReadOutput = {
  __typename?: 'PublicationsReadOutput';
  count: Scalars['Int']['output'];
};

export type Publishable = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  publicationPagination: PublicationPagination;
  updatedAt: Scalars['DateTime']['output'];
};


export type PublishablePublicationPaginationArgs = {
  filter?: InputMaybe<PublicationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<PublicationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type PushToken = Node & {
  __typename?: 'PushToken';
  createdAt: Scalars['DateTime']['output'];
  deviceId: Scalars['String']['output'];
  deviceOS: DeviceOs;
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type PushTokenDeleteInput = {
  deviceId: Scalars['String']['input'];
};

export type PushTokenDeleteOutput = {
  __typename?: 'PushTokenDeleteOutput';
  pushToken: PushToken;
};

export type PushTokenUpsertInput = {
  deviceId: Scalars['String']['input'];
  deviceOS: DeviceOs;
  pushToken: Scalars['String']['input'];
};

export type PushTokenUpsertOutput = {
  __typename?: 'PushTokenUpsertOutput';
  pushToken: PushToken;
};

export type Query = {
  __typename?: 'Query';
  AppdragMigrationsSynchronization?: Maybe<AppdragMigrationSynchronization>;
  AppdragMigrationsSynchronizationsPagination: AppdragMigrationsSynchronizationsPagination;
  adminCommentPagination: AdminCommentPagination;
  alert?: Maybe<Alert>;
  alertPagination: AlertPagination;
  appdragCompanies: Array<AppdragCompany>;
  appdragOrder?: Maybe<AppdragOrder>;
  appdragPerks: Array<AppdragPerk>;
  article?: Maybe<Article>;
  articleCategoriesPagination: ArticleCategoriesPagination;
  articleCategory?: Maybe<ArticleCategory>;
  articlesPagination: ArticlesPagination;
  authorsSynchronizations: ProductSynchronizationAuthorOutput;
  beneficiary?: Maybe<Beneficiary>;
  brand?: Maybe<Brand>;
  brandsPagination: BrandsPagination;
  campaign?: Maybe<Campaign>;
  campaignCardInformations: Array<CampaignCardInfos>;
  /** @deprecated Use discover instead */
  campaignHighlightedPagination: CampaignsPagination;
  campaignIndexesName: Array<AlgoliaIndexName>;
  campaignPagination: CampaignPagination;
  campaignSelection?: Maybe<CampaignSelection>;
  campaignSelectionsPagination: CampaignSelectionsPagination;
  cart?: Maybe<Cart>;
  cartPagination: CartPagination;
  catalog: Catalog;
  catalogPagination: CatalogPagination;
  categoriesPagination: CategoriesPagination;
  category?: Maybe<Category>;
  cohort?: Maybe<CohortUnion>;
  cohortConfiguration: CohortConfiguration;
  companiesPagination: CompaniesPagination;
  company?: Maybe<Company>;
  companyDocument: CompanyDocument;
  companyFolder?: Maybe<CompanyFolder>;
  companyPublic?: Maybe<CompanyPublicOutput>;
  couponClaimablesPagination: CouponClaimablesPagination;
  discoverPagination: DiscoverPagination;
  favoritePagination?: Maybe<FavoritePagination>;
  game?: Maybe<Game>;
  gamesPagination: GamesPagination;
  gathering: Gathering;
  happening?: Maybe<Happening>;
  happeningPagination: HappeningPagination;
  highlightPagination: HighlightPagination;
  ifgOrderRedemptions: Array<IfgRedemption>;
  invitation?: Maybe<Invitation>;
  invitationsPagination: InvitationsPagination;
  localesPagination: RootLocalesPagination;
  mangopayBankAccount?: Maybe<MangopayBankAccount>;
  mangopayBankwireDirectPayIn: MangopayBankwireDirectPayIn;
  mangopayBankwireDirectPayInPagination: MangopayBankwireDirectPayInPagination;
  mangopayBankwirePayOutPagination?: Maybe<MangopayBankwirePayOutPagination>;
  mangopayKycDocument?: Maybe<MangopayKycDocument>;
  mangopayKycDocumentPagination: MangopayKycDocumentPagination;
  mangopayTransfer?: Maybe<MangopayTransfer>;
  mangopayTransferPagination: MangopayTransferPagination;
  newsletter?: Maybe<Newsletter>;
  newsletterPagination: NewsletterPagination;
  offer?: Maybe<Offer>;
  offersPagination: OffersPagination;
  order?: Maybe<Order>;
  orderItem?: Maybe<OrderItem>;
  orderPagination: OrderPagination;
  orderRefund?: Maybe<OrderRefund>;
  orderRefundPagination: OrderRefundPagination;
  /** @deprecated Do not use */
  picture?: Maybe<Picture>;
  pictureStock?: Maybe<PictureStock>;
  pictureStockPagination: PictureStockPagination;
  pointOfContact?: Maybe<PointOfContact>;
  previewGranteeCount: GranteeCountPreview;
  product?: Maybe<Product>;
  productArticle?: Maybe<ProductArticle>;
  productArticlePagination: ProductArticlePagination;
  productCatalogPagination: ProductCatalogPagination;
  productOrdersPagination: ProductOrdersPagination;
  productPagination: ProductPagination;
  productProvider?: Maybe<ProductProvider>;
  productProviderPagination: ProductProviderPagination;
  productSynchronization?: Maybe<ProductSynchronization>;
  productSynchronizationsPagination: ProductSynchronizationsPagination;
  productVariant?: Maybe<ProductVariant>;
  productVariantGetDynamicPrice: ProductVariantGetDynamicPriceOutput;
  productVariantPagination: ProductVariantPagination;
  publication?: Maybe<Publication>;
  publicationsPagination: PublicationPagination;
  question?: Maybe<Question>;
  redBinBrandsGeneratedTranslation: BrandsPagination;
  redBinBrandsMissingTranslation: BrandsPagination;
  redBinBrandsWithoutLogo: BrandsPagination;
  redBinCampaignsGeneratedTranslation: CampaignPagination;
  redBinCampaignsMissingTranslation: CampaignPagination;
  redBinCampaignsWithoutCover: CampaignPagination;
  redBinCampaignsWithoutThumbnail: CampaignPagination;
  redBinOfferWithLowClaimableCoupons: OffersPagination;
  redBinOffersCouponWithoutUrl: OffersPagination;
  redBinOffersDiscountOrVoucher: OffersPagination;
  redBinOffersEnded: OffersPagination;
  redBinOffersEndingSoon: OffersPagination;
  redBinOffersGeneratedTranslation: OffersPagination;
  redBinOffersMissingTranslation: OffersPagination;
  redBinOffersWithProductsExpiringSoon: OffersPagination;
  redBinOffersWithoutCategories: OffersPagination;
  redBinOffersWithoutLocalization: OffersPagination;
  redBinOrdersNotDelivered: OrderPagination;
  redBinProductsExpiringSoon: ProductPagination;
  redBinProductsWithLittleStockRemaining: ProductPagination;
  redBinStoresWithoutProvince: StorePagination;
  reducCeInvoices: Array<ReducCeInvoice>;
  reducCeOrderState: ReducCeOrderStateOutput;
  searchSynchronization?: Maybe<SearchSynchronization>;
  searchSynchronizationsPagination: SearchSynchronizationsPagination;
  ssoProvider?: Maybe<SsoProvider>;
  ssoProviderPagination: SsoProviderPagination;
  ssoProviders: Array<PublicSsoProvider>;
  store?: Maybe<Store>;
  storePagination: StorePagination;
  stripePaymentIntentsPagination: StripePaymentIntentsPagination;
  subvention?: Maybe<Subvention>;
  subventionGranteesEstimate: SubventionGranteesEstimation;
  subventionInvoice?: Maybe<SubventionInvoice>;
  subventionPagination: SubventionPagination;
  subventionReimbursementRequest?: Maybe<SubventionReimbursementRequest>;
  subventionReimbursementRequestPagination: SubventionReimbursementRequestPagination;
  subventionRequestReimbursementProof?: Maybe<File>;
  subventionSchema?: Maybe<SubventionSchema>;
  subventionSchemaPagination: SubventionSchemaPagination;
  taxRatePagination: TaxRatePagination;
  user?: Maybe<User>;
  userAlgoliaSecuredKeyAPi: Scalars['String']['output'];
  /** Super admin only */
  userIntegrationConnector?: Maybe<UserIntegrationConnector>;
  /** Super admin only */
  userIntegrationConnectorsPagination?: Maybe<UserIntegrationConnectorPagination>;
  usersPagination: UsersPagination;
  viewer?: Maybe<User>;
  /** Generate a hash for a user used by Intercom for identity verification. Used to prevent user impersonnation. Only available if logged-in */
  viewerIntercomHash?: Maybe<Scalars['String']['output']>;
  viewerSubventions: Array<Subvention>;
};


export type QueryAppdragMigrationsSynchronizationArgs = {
  AppdragMigrationsSynchronizationId: Scalars['ID']['input'];
};


export type QueryAppdragMigrationsSynchronizationsPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAdminCommentPaginationArgs = {
  filter?: InputMaybe<AdminCommentArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<AdminCommentArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAlertArgs = {
  alertId: Scalars['ID']['input'];
};


export type QueryAlertPaginationArgs = {
  filter?: InputMaybe<AlertArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<AlertArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAppdragPerksArgs = {
  fournisseurId: Scalars['Int']['input'];
};


export type QueryArticleArgs = {
  articleId: Scalars['ID']['input'];
};


export type QueryArticleCategoriesPaginationArgs = {
  filter?: InputMaybe<ArticleCategoryArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<ArticleCategoryArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryArticleCategoryArgs = {
  articleCategoryId: Scalars['ID']['input'];
};


export type QueryArticlesPaginationArgs = {
  filter?: InputMaybe<ArticlesPaginationFilterArgs>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<ArticlesPaginationSorterArgs>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryBeneficiaryArgs = {
  beneficiaryId: Scalars['ID']['input'];
};


export type QueryBrandArgs = {
  brandId: Scalars['ID']['input'];
};


export type QueryBrandsPaginationArgs = {
  filter?: InputMaybe<BrandsArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<BrandsArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCampaignArgs = {
  campaignId: Scalars['ID']['input'];
};


export type QueryCampaignCardInformationsArgs = {
  campaignIds: Array<Scalars['ID']['input']>;
};


export type QueryCampaignHighlightedPaginationArgs = {
  filter?: InputMaybe<CampaignsArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CampaignsArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCampaignPaginationArgs = {
  filter?: InputMaybe<CampaignPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CampaignPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCampaignSelectionArgs = {
  campaignSelectionId: Scalars['ID']['input'];
};


export type QueryCampaignSelectionsPaginationArgs = {
  filter?: InputMaybe<CampaignSelectionsArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CampaignSelectionsArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCartArgs = {
  cartId: Scalars['ID']['input'];
};


export type QueryCartPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CartArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCatalogPaginationArgs = {
  filter?: InputMaybe<CatalogPaginationFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCategoriesPaginationArgs = {
  filter?: InputMaybe<CategoriesArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CategoriesArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCategoryArgs = {
  categoryId: Scalars['ID']['input'];
};


export type QueryCohortArgs = {
  cohortId: Scalars['ID']['input'];
};


export type QueryCompaniesPaginationArgs = {
  filter?: InputMaybe<CompaniesArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CompaniesArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCompanyArgs = {
  companyId: Scalars['ID']['input'];
};


export type QueryCompanyDocumentArgs = {
  companyDocumentId: Scalars['ID']['input'];
};


export type QueryCompanyFolderArgs = {
  companyFolderId: Scalars['ID']['input'];
};


export type QueryCompanyPublicArgs = {
  companySlug: Scalars['ID']['input'];
};


export type QueryCouponClaimablesPaginationArgs = {
  filter?: InputMaybe<CouponClaimablesPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CouponClaimablesPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryDiscoverPaginationArgs = {
  filter?: InputMaybe<DiscoverPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFavoritePaginationArgs = {
  filter?: InputMaybe<FavoritePaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<FavoritePaginationSorterArgs>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGameArgs = {
  gameId: Scalars['ID']['input'];
};


export type QueryGamesPaginationArgs = {
  filter?: InputMaybe<GamesPaginationFilterArgs>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<GamesPaginationSorterArgs>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGatheringArgs = {
  gatheringId: Scalars['ID']['input'];
};


export type QueryHappeningArgs = {
  happeningId: Scalars['ID']['input'];
};


export type QueryHappeningPaginationArgs = {
  filter?: InputMaybe<HappeningPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<HappeningPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryHighlightPaginationArgs = {
  filter?: InputMaybe<HighlightArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryIfgOrderRedemptionsArgs = {
  ifgOrderId: Scalars['String']['input'];
};


export type QueryInvitationArgs = {
  invitationId: Scalars['ID']['input'];
};


export type QueryInvitationsPaginationArgs = {
  filter?: InputMaybe<InvitationsArgsFilterInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<NotificationsArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLocalesPaginationArgs = {
  filter?: InputMaybe<LocalesArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMangopayBankAccountArgs = {
  mangopayBankAccountId: Scalars['ID']['input'];
};


export type QueryMangopayBankwireDirectPayInArgs = {
  mangopayBankwireDirectPayInId: Scalars['ID']['input'];
};


export type QueryMangopayBankwireDirectPayInPaginationArgs = {
  filter?: InputMaybe<MangopayBankwireDirectPayInArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<MangopayBankwireDirectPayInArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMangopayBankwirePayOutPaginationArgs = {
  filter?: InputMaybe<MangopayBankwirePayOutArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<MangopayBankwirePayOutArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMangopayKycDocumentArgs = {
  mangopayKycDocumentId: Scalars['ID']['input'];
};


export type QueryMangopayKycDocumentPaginationArgs = {
  filter?: InputMaybe<MangopayKycDocumentPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<MangopayKycDocumentPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMangopayTransferArgs = {
  mangopayTransferId: Scalars['ID']['input'];
};


export type QueryMangopayTransferPaginationArgs = {
  filter?: InputMaybe<MangopayTransferArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<MangopayTransferArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryNewsletterArgs = {
  newsletterId: Scalars['ID']['input'];
};


export type QueryNewsletterPaginationArgs = {
  filter?: InputMaybe<NewsletterPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<NewsletterPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOfferArgs = {
  offerId: Scalars['ID']['input'];
};


export type QueryOffersPaginationArgs = {
  filter?: InputMaybe<OffersArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<OffersArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOrderArgs = {
  orderId: Scalars['ID']['input'];
};


export type QueryOrderItemArgs = {
  orderItemId: Scalars['ID']['input'];
};


export type QueryOrderPaginationArgs = {
  filter?: InputMaybe<OrderPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<OrderPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOrderRefundArgs = {
  orderRefundId: Scalars['ID']['input'];
};


export type QueryOrderRefundPaginationArgs = {
  filter?: InputMaybe<OrderRefundPaginationFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<Array<OrderRefundPaginationSorter>>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPictureArgs = {
  pictureId: Scalars['ID']['input'];
};


export type QueryPictureStockArgs = {
  pictureStockId: Scalars['ID']['input'];
};


export type QueryPictureStockPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPointOfContactArgs = {
  pointOfContactId: Scalars['ID']['input'];
};


export type QueryPreviewGranteeCountArgs = {
  filter?: InputMaybe<UsersArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<UsersArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProductArgs = {
  productId: Scalars['ID']['input'];
};


export type QueryProductArticleArgs = {
  productArticleId: Scalars['ID']['input'];
};


export type QueryProductArticlePaginationArgs = {
  filter?: InputMaybe<ProductArticlePaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<ProductArticlePaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProductCatalogPaginationArgs = {
  filter?: InputMaybe<ProductCatalogArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<ProductCatalogArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProductOrdersPaginationArgs = {
  filter?: InputMaybe<ProductOrdersArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProductPaginationArgs = {
  filter?: InputMaybe<ProductPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<ProductPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProductProviderArgs = {
  productProviderId: Scalars['ID']['input'];
};


export type QueryProductProviderPaginationArgs = {
  filter?: InputMaybe<ProductProviderArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<ProductProviderArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProductSynchronizationArgs = {
  productSynchronizationId: Scalars['ID']['input'];
};


export type QueryProductSynchronizationsPaginationArgs = {
  filter?: InputMaybe<ProductSynchronizationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<ProductSynchronizationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProductVariantArgs = {
  productVariantId: Scalars['ID']['input'];
};


export type QueryProductVariantGetDynamicPriceArgs = {
  input: ProductVariantGetDynamicPriceInput;
  productVariantId: Scalars['ID']['input'];
};


export type QueryProductVariantPaginationArgs = {
  filter?: InputMaybe<ProductVariantArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<ProductVariantArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPublicationArgs = {
  publicationId: Scalars['ID']['input'];
};


export type QueryPublicationsPaginationArgs = {
  filter?: InputMaybe<PublicationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<PublicationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryQuestionArgs = {
  questionId: Scalars['ID']['input'];
};


export type QueryRedBinBrandsGeneratedTranslationArgs = {
  filter?: InputMaybe<BrandsArgsFilter>;
  localeId: Scalars['String']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<BrandsArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRedBinBrandsMissingTranslationArgs = {
  filter?: InputMaybe<BrandsArgsFilter>;
  missingLocaleId: Scalars['String']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<BrandsArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRedBinBrandsWithoutLogoArgs = {
  filter?: InputMaybe<BrandsArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<BrandsArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRedBinCampaignsGeneratedTranslationArgs = {
  filter?: InputMaybe<CampaignsArgsFilter>;
  localeId: Scalars['String']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CampaignsArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRedBinCampaignsMissingTranslationArgs = {
  filter?: InputMaybe<CampaignsArgsFilter>;
  missingLocaleId: Scalars['String']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CampaignsArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRedBinCampaignsWithoutCoverArgs = {
  filter?: InputMaybe<CampaignPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CampaignPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRedBinCampaignsWithoutThumbnailArgs = {
  filter?: InputMaybe<CampaignPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CampaignPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRedBinOfferWithLowClaimableCouponsArgs = {
  filter?: InputMaybe<OffersArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<OffersArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRedBinOffersCouponWithoutUrlArgs = {
  filter?: InputMaybe<OffersArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<OffersArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRedBinOffersDiscountOrVoucherArgs = {
  filter?: InputMaybe<OffersArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<OffersArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRedBinOffersEndedArgs = {
  filter?: InputMaybe<OffersArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<OffersArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRedBinOffersEndingSoonArgs = {
  filter?: InputMaybe<OffersArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<OffersArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRedBinOffersGeneratedTranslationArgs = {
  filter?: InputMaybe<OffersArgsFilter>;
  localeId: Scalars['String']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<OffersArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRedBinOffersMissingTranslationArgs = {
  filter?: InputMaybe<OffersArgsFilter>;
  missingLocaleId: Scalars['String']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<OffersArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRedBinOffersWithProductsExpiringSoonArgs = {
  filter?: InputMaybe<OffersArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<OffersArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRedBinOffersWithoutCategoriesArgs = {
  filter?: InputMaybe<OffersArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<OffersArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRedBinOffersWithoutLocalizationArgs = {
  filter?: InputMaybe<OffersArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<OffersArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRedBinOrdersNotDeliveredArgs = {
  filter?: InputMaybe<OrderPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<OrderPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRedBinProductsExpiringSoonArgs = {
  filter?: InputMaybe<ProductPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<ProductPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRedBinProductsWithLittleStockRemainingArgs = {
  filter?: InputMaybe<ProductPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<ProductPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRedBinStoresWithoutProvinceArgs = {
  filter?: InputMaybe<StoreArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<StoreArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryReducCeOrderStateArgs = {
  reducCeOrderId: Scalars['String']['input'];
};


export type QuerySearchSynchronizationArgs = {
  searchSynchronizationId: Scalars['ID']['input'];
};


export type QuerySearchSynchronizationsPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySsoProviderArgs = {
  ssoProviderId: Scalars['ID']['input'];
};


export type QuerySsoProviderPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySsoProvidersArgs = {
  slug: Scalars['String']['input'];
};


export type QueryStoreArgs = {
  storeId: Scalars['ID']['input'];
};


export type QueryStorePaginationArgs = {
  filter?: InputMaybe<StoreArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<StoreArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryStripePaymentIntentsPaginationArgs = {
  filter?: InputMaybe<StripePaymentIntentsPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<StripePaymentIntentsPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySubventionArgs = {
  subventionId: Scalars['ID']['input'];
};


export type QuerySubventionGranteesEstimateArgs = {
  cohortId: Scalars['ID']['input'];
  granteesTypes?: InputMaybe<Array<SubventionGranteeType>>;
};


export type QuerySubventionInvoiceArgs = {
  subventionInvoiceId: Scalars['ID']['input'];
};


export type QuerySubventionPaginationArgs = {
  filter?: InputMaybe<SubventionArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<SubventionArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySubventionReimbursementRequestArgs = {
  subventionReimbursementRequestId: Scalars['ID']['input'];
};


export type QuerySubventionReimbursementRequestPaginationArgs = {
  filter?: InputMaybe<SubventionReimbursementRequestArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<SubventionReimbursementRequestArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySubventionRequestReimbursementProofArgs = {
  proofId: Scalars['ID']['input'];
  subventionReimbursementRequestId: Scalars['ID']['input'];
};


export type QuerySubventionSchemaArgs = {
  subventionSchemaId: Scalars['ID']['input'];
};


export type QuerySubventionSchemaPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<SubventionSchemaPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTaxRatePaginationArgs = {
  filter?: InputMaybe<TaxRateArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<TaxRateArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUserArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryUserIntegrationConnectorArgs = {
  connectorId: Scalars['ID']['input'];
};


export type QueryUserIntegrationConnectorsPaginationArgs = {
  filter?: InputMaybe<UserIntegrationConnectorPaginationFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<Array<UserIntegrationConnectorPaginationSorter>>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUsersPaginationArgs = {
  filter?: InputMaybe<UsersArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<UsersArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryViewerSubventionsArgs = {
  filter?: InputMaybe<SubventionViewerArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<SubventionArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type Question = Node & {
  __typename?: 'Question';
  author?: Maybe<User>;
  category: QuestionCategory;
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isAnonymous: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type QuestionCategory = Node & {
  __typename?: 'QuestionCategory';
  /** @deprecated Replaced by isActive */
  active: Scalars['Boolean']['output'];
  company: Company;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type QuestionCategoryArgsFilter = {
  AND?: InputMaybe<Array<QuestionCategoryArgsFilter>>;
  OR?: InputMaybe<Array<QuestionCategoryArgsFilter>>;
  createdAt?: InputMaybe<FilterDateArgs>;
  id?: InputMaybe<FilterIdArgs>;
  isActive?: InputMaybe<FilterBoolArgs>;
  updatedAt?: InputMaybe<FilterDateArgs>;
};

export type QuestionCategoryCreateInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
};

export type QuestionCategoryCreateOutput = {
  __typename?: 'QuestionCategoryCreateOutput';
  questionCategory: QuestionCategory;
};

export type QuestionCategoryDeleteOutput = {
  __typename?: 'QuestionCategoryDeleteOutput';
  questionCategory: QuestionCategory;
};

export type QuestionCategoryEdge = Edge & {
  __typename?: 'QuestionCategoryEdge';
  cursor: Scalars['ID']['output'];
  node: QuestionCategory;
};

export type QuestionCategoryPagination = Pagination & {
  __typename?: 'QuestionCategoryPagination';
  edges: Array<QuestionCategoryEdge>;
  nodes: Array<QuestionCategory>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type QuestionCategoryPaginationArgsFilter = {
  AND?: InputMaybe<Array<QuestionCategoryArgsFilter>>;
  OR?: InputMaybe<Array<QuestionCategoryArgsFilter>>;
  active?: InputMaybe<QuestionCategoryPaginationArgsFilterActive>;
  createdAt?: InputMaybe<FilterDateArgs>;
  id?: InputMaybe<FilterIdArgs>;
  isActive?: InputMaybe<FilterBoolArgs>;
  updatedAt?: InputMaybe<FilterDateArgs>;
};

export type QuestionCategoryPaginationArgsFilterActive = {
  exclude?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  include?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

export type QuestionCategoryPaginationArgsSorter = {
  createdAt?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type QuestionCategoryUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QuestionCategoryUpdateOutput = {
  __typename?: 'QuestionCategoryUpdateOutput';
  questionCategory: QuestionCategory;
};

export type QuestionCreateInput = {
  categoryId: Scalars['ID']['input'];
  content: Scalars['String']['input'];
  isAnonymous: Scalars['Boolean']['input'];
};

export type QuestionCreateOutput = {
  __typename?: 'QuestionCreateOutput';
  question: Question;
};

export type QuestionDeleteOutput = {
  __typename?: 'QuestionDeleteOutput';
  question: Question;
};

export type QuestionEdge = Edge & {
  __typename?: 'QuestionEdge';
  cursor: Scalars['ID']['output'];
  node: Question;
};

export type QuestionUpdateInput = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  isAnonymous?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuestionUpdateOutput = {
  __typename?: 'QuestionUpdateOutput';
  question: Question;
};

export type QuestionsPagination = Pagination & {
  __typename?: 'QuestionsPagination';
  edges: Array<QuestionEdge>;
  nodes: Array<Question>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type QuestionsPaginationFilters = {
  categories?: InputMaybe<QuestionsPaginationFiltersCategories>;
};

export type QuestionsPaginationFiltersCategories = {
  include?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ReducCeInvoice = {
  __typename?: 'ReducCeInvoice';
  clientId: Scalars['String']['output'];
  clientName: Scalars['String']['output'];
  date: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  paid: Scalars['Boolean']['output'];
  totalAfterTaxes: Scalars['Int']['output'];
  totalBeforeTaxes: Scalars['Int']['output'];
};

export type ReducCeOrderStateOutput = {
  __typename?: 'ReducCeOrderStateOutput';
  code: Scalars['String']['output'];
  items: Array<Scalars['JSONObject']['output']>;
  orderId: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export type Representative = {
  birthday: Scalars['Date']['input'];
  countryOfResidence: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  nationality: Scalars['String']['input'];
};

export type RewardDiscount = {
  __typename?: 'RewardDiscount';
  description: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
};

export type RootLocalesPagination = LocalesPagination & Pagination & {
  __typename?: 'RootLocalesPagination';
  edges: Array<LocaleEdge>;
  nodes: Array<Locale>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SsoProvider = {
  activatedAt?: Maybe<Scalars['DateTime']['output']>;
  allowCreateUser?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SsoProviderEdge = Edge & {
  __typename?: 'SSOProviderEdge';
  cursor: Scalars['ID']['output'];
  node: Node;
};

export type SsoProviderOidc = DeletableNode & Node & SsoProvider & {
  __typename?: 'SSOProviderOIDC';
  activatedAt?: Maybe<Scalars['DateTime']['output']>;
  allowCreateUser?: Maybe<Scalars['Boolean']['output']>;
  clientId: Scalars['String']['output'];
  clientSecret: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  issuer: Scalars['String']['output'];
  name: Scalars['String']['output'];
  scopes?: Maybe<Array<Scalars['String']['output']>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type SsoProviderOidcCreateInput = {
  clientId: Scalars['String']['input'];
  clientSecret: Scalars['String']['input'];
  issuer: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type SsoProviderOidcCreateOutput = {
  __typename?: 'SSOProviderOIDCCreateOutput';
  company: Company;
  provider: SsoProviderOidc;
};

export type SsoProviderOidcRule = Node & {
  __typename?: 'SSOProviderOIDCRule';
  conditions: Scalars['JSONObject']['output'];
  content: Scalars['JSONObject']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  type: SsoProviderOidcRuleType;
  updatedAt: Scalars['DateTime']['output'];
};

export enum SsoProviderOidcRuleType {
  MetadataUpdate = 'METADATA_UPDATE',
  SubsidiaryLogin = 'SUBSIDIARY_LOGIN'
}

export type SsoProviderPagination = Pagination & {
  __typename?: 'SSOProviderPagination';
  edges: Array<SsoProviderEdge>;
  nodes: Array<SsoProvider>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SsoProviderSaml = DeletableNode & Node & SsoProvider & {
  __typename?: 'SSOProviderSAML';
  activatedAt?: Maybe<Scalars['DateTime']['output']>;
  allowCreateUser?: Maybe<Scalars['Boolean']['output']>;
  certificate: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  emailAttribute?: Maybe<Scalars['String']['output']>;
  firstNameAttribute?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  identityId: Scalars['String']['output'];
  lastNameAttribute?: Maybe<Scalars['String']['output']>;
  loginUrl: Scalars['String']['output'];
  logoutUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  namedIdFormats: Array<Scalars['String']['output']>;
  pictureAttribute?: Maybe<Scalars['String']['output']>;
  supportedProtocols: Array<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type SsoProviderSamlCreateInput = {
  certificate: Scalars['String']['input'];
  emailAttribute?: InputMaybe<Scalars['String']['input']>;
  firstNameAttribute?: InputMaybe<Scalars['String']['input']>;
  identityId: Scalars['String']['input'];
  lastNameAttribute?: InputMaybe<Scalars['String']['input']>;
  loginUrl: Scalars['String']['input'];
  logoutUrl?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  namedIdFormats: Array<Scalars['String']['input']>;
  pictureAttribute?: InputMaybe<Scalars['String']['input']>;
  supportedProtocols: Array<Scalars['String']['input']>;
};

export type SsoProviderSamlCreateOutput = {
  __typename?: 'SSOProviderSAMLCreateOutput';
  company: Company;
  provider: SsoProviderSaml;
};

export type SsoUser = {
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SsoUserOidc = DeletableNode & Node & SsoUser & {
  __typename?: 'SSOUserOIDC';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SsoUserSaml = DeletableNode & Node & SsoUser & {
  __typename?: 'SSOUserSAML';
  attributes: Scalars['JSONObject']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  nameId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SearchSynchronization = Node & {
  __typename?: 'SearchSynchronization';
  author: User;
  createdAt: Scalars['DateTime']['output'];
  error?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  status: SearchSynchronizationStatus;
  type: SearchSynchronizationType;
  updatedAt: Scalars['DateTime']['output'];
};

export type SearchSynchronizationCreateInput = {
  type: SearchSynchronizationType;
};

export type SearchSynchronizationCreateOutput = {
  __typename?: 'SearchSynchronizationCreateOutput';
  synchronization: SearchSynchronization;
};

export type SearchSynchronizationEdge = Edge & {
  __typename?: 'SearchSynchronizationEdge';
  cursor: Scalars['ID']['output'];
  node: SearchSynchronization;
};

export enum SearchSynchronizationStatus {
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  Failed = 'FAILED'
}

export enum SearchSynchronizationType {
  Campaign = 'CAMPAIGN',
  CampaignSelection = 'CAMPAIGN_SELECTION',
  Category = 'CATEGORY'
}

export type SearchSynchronizationsPagination = Pagination & {
  __typename?: 'SearchSynchronizationsPagination';
  edges: Array<SearchSynchronizationEdge>;
  nodes: Array<SearchSynchronization>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SendgridCompanySubuserCreateInput = {
  /** Creates a cron (every hour) that will validate if the Domain Verification DNS provided by Sendgrid have been set. If you choose to handle this manually you will need to verify the Domain from Sendgrid's interface once the DNS are set. */
  autoVerify: Scalars['Boolean']['input'];
  /** A custom DKIM prefix that is unique to this subuser. Maximum 3 alphanumeric characters. */
  customDkim: Scalars['String']['input'];
  /** If not provided, defaults to `happypal.fr` */
  domain?: InputMaybe<Scalars['String']['input']>;
  /** If not provided, defaults to: `<environment>-<companySlug>` */
  name?: InputMaybe<Scalars['String']['input']>;
  subdomain?: InputMaybe<Scalars['String']['input']>;
};

export type SendgridCompanySubuserCreateOutput = {
  __typename?: 'SendgridCompanySubuserCreateOutput';
  company: Company;
  dnsRecords: Scalars['JSONObject']['output'];
};

export type SendgridSubuser = Node & {
  __typename?: 'SendgridSubuser';
  canSendEmail: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SessionRevokeOutput = {
  __typename?: 'SessionRevokeOutput';
  sessionsAffected: Scalars['Int']['output'];
};

export type SorewardsUser = Node & {
  __typename?: 'SorewardsUser';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  onboardingCompleted: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SorewardsUserCreateInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  birthDate: Scalars['Date']['input'];
  city?: InputMaybe<Scalars['String']['input']>;
  /** A string composed of two-letter country codes, compliant with ISO 3166 standard */
  country: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  gender?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  tos: Scalars['Boolean']['input'];
  username: Scalars['String']['input'];
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type SorewardsUserCreateOutput = {
  __typename?: 'SorewardsUserCreateOutput';
  sorewardsUser: SorewardsUser;
  user: User;
};

export type SorewardsUserGenerateLoginUrlOutput = {
  __typename?: 'SorewardsUserGenerateLoginUrlOutput';
  url: Scalars['String']['output'];
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Store = Node & {
  __typename?: 'Store';
  address: Address;
  brand: Brand;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  offerPagination: OffersPagination;
  updatedAt: Scalars['DateTime']['output'];
};


export type StoreOfferPaginationArgs = {
  filter?: InputMaybe<OffersArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<OffersArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type StoreArgsFilter = {
  AND?: InputMaybe<Array<StoreArgsFilter>>;
  OR?: InputMaybe<Array<StoreArgsFilter>>;
  brand?: InputMaybe<BrandsArgsFilter>;
  id?: InputMaybe<FilterIdArgs>;
  offers?: InputMaybe<OffersArgsFilter>;
};

export type StoreArgsSorter = {
  createdAt?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type StoreCreateInput = {
  address: AddressCreateInput;
};

export type StoreCreateOutput = {
  __typename?: 'StoreCreateOutput';
  store: Store;
};

export type StoreDeleteOutput = {
  __typename?: 'StoreDeleteOutput';
  store: Store;
};

export type StoreEdge = Edge & {
  __typename?: 'StoreEdge';
  cursor: Scalars['ID']['output'];
  node: Store;
};

export type StorePagination = Pagination & {
  __typename?: 'StorePagination';
  edges: Array<StoreEdge>;
  nodes: Array<Store>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type StoreUpdateInput = {
  address: AddressUpdateInput;
};

export type StoreUpdateOutput = {
  __typename?: 'StoreUpdateOutput';
  store: Store;
};

export type StoresAddOffersInput = {
  offerIds: Array<Scalars['ID']['input']>;
  storeIds: Array<Scalars['ID']['input']>;
};

export type StoresAddOffersOutput = {
  __typename?: 'StoresAddOffersOutput';
  offers: Array<Offer>;
  stores: Array<Store>;
};

export type StoresRemoveOffersInput = {
  offerIds: Array<Scalars['ID']['input']>;
  storeIds: Array<Scalars['ID']['input']>;
};

export type StoresRemoveOffersOutput = {
  __typename?: 'StoresRemoveOffersOutput';
  offers: Array<Offer>;
  stores: Array<Store>;
};

export type StripeConnectedAccount = Node & {
  __typename?: 'StripeConnectedAccount';
  companyId: Scalars['ID']['output'];
  connectedAccountId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type StripeConnectedAccountCreateOutput = {
  __typename?: 'StripeConnectedAccountCreateOutput';
  stripeConnectedAccount: StripeConnectedAccount;
};

export type StripeConnectedAccountLink = {
  __typename?: 'StripeConnectedAccountLink';
  createdAt: Scalars['DateTime']['output'];
  expiresAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
};

export type StripeConnectedAccountLinkCreateOutput = {
  __typename?: 'StripeConnectedAccountLinkCreateOutput';
  stripeConnectedAccountLink: StripeConnectedAccountLink;
};

export type StripePaymentIntent = Node & {
  __typename?: 'StripePaymentIntent';
  amount: Scalars['Int']['output'];
  amountReceived: Scalars['Int']['output'];
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  cancellationReason?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['Currency']['output'];
  id: Scalars['ID']['output'];
  status: StripePaymentIntentStatus;
  stripeUrl: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

export type StripePaymentIntentEdge = Edge & {
  __typename?: 'StripePaymentIntentEdge';
  cursor: Scalars['ID']['output'];
  node: StripePaymentIntent;
};

export enum StripePaymentIntentStatus {
  Canceled = 'canceled',
  Processing = 'processing',
  RequiresAction = 'requires_action',
  RequiresCapture = 'requires_capture',
  RequiresConfirmation = 'requires_confirmation',
  RequiresPaymentMethod = 'requires_payment_method',
  Succeeded = 'succeeded'
}

export type StripePaymentIntentsPagination = Pagination & {
  __typename?: 'StripePaymentIntentsPagination';
  edges: Array<StripePaymentIntentEdge>;
  nodes: Array<StripePaymentIntent>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type StripePaymentIntentsPaginationArgsFilter = {
  id?: InputMaybe<FilterIdArgs>;
  users?: InputMaybe<StripePaymentIntentsPaginationArgsFilterUsers>;
};

export type StripePaymentIntentsPaginationArgsFilterUsers = {
  include?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type StripePaymentIntentsPaginationArgsSorter = {
  createdAt?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type StripeRefund = Node & {
  __typename?: 'StripeRefund';
  amount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['Currency']['output'];
  failureReason: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  status: StripeRefundStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export enum StripeRefundStatus {
  Canceled = 'canceled',
  Failed = 'failed',
  Pending = 'pending',
  RequiresAction = 'requires_action',
  Succeeded = 'succeeded'
}

export type Subvention = {
  /** @deprecated Use amountByGrantee or amountDiscounted or productQuantity */
  amount?: Maybe<SubventionAmount>;
  auditTrailPagination: AuditTrailPagination;
  /** @deprecated Une campaignPagination instead */
  brands: BrandsPagination;
  /** @deprecated Use budgetConsumedBigInt instead */
  budgetConsumed: Scalars['Int']['output'];
  budgetConsumedBigInt: Scalars['BigInt']['output'];
  /** @deprecated Use budgetTotalBigInt instead */
  budgetTotal: Scalars['Int']['output'];
  budgetTotalBigInt: Scalars['BigInt']['output'];
  campaignPagination: CampaignPagination;
  categories: CategoriesPagination;
  cohort?: Maybe<CohortUser>;
  company?: Maybe<Company>;
  createdAt: Scalars['DateTime']['output'];
  cumulative?: Maybe<Scalars['Boolean']['output']>;
  currency: Scalars['Currency']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  detail?: Maybe<Scalars['String']['output']>;
  endsAt?: Maybe<Scalars['Date']['output']>;
  facetAttributes?: Maybe<Scalars['String']['output']>;
  granteesCount?: Maybe<SubventionGranteesPaginationDetailedCount>;
  granteesFrozen?: Maybe<Scalars['Boolean']['output']>;
  granteesPagination: SubventionGranteesPagination;
  granteesTypes?: Maybe<Array<SubventionGranteeType>>;
  id: Scalars['ID']['output'];
  isCustom: Scalars['Boolean']['output'];
  maxBudget?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  recurrence: SubventionRecurrence;
  /** @deprecated Use `reimbursementPolicy` instead */
  refundPolicy?: Maybe<Scalars['String']['output']>;
  reimbursementPolicy?: Maybe<Scalars['String']['output']>;
  spendingModes?: Maybe<Array<SubventionSpendingMode>>;
  startsAt?: Maybe<Scalars['Date']['output']>;
  state: SubventionState;
  subventionSchema?: Maybe<SubventionSchema>;
  supplyRate?: Maybe<Scalars['Int']['output']>;
  /** @deprecated use budgetTotal instead */
  totalBudget: Scalars['Int']['output'];
  transactionsPagination: SubventionConsumptionsPagination;
  type?: Maybe<SubventionType>;
  unusedUserCount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userPagination: SubventionUserPagination;
};


export type SubventionAuditTrailPaginationArgs = {
  filter?: InputMaybe<AuditTrailArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<AuditTrailArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type SubventionBrandsArgs = {
  filter?: InputMaybe<BrandsArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<BrandsArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type SubventionCampaignPaginationArgs = {
  filter?: InputMaybe<CampaignPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CampaignPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type SubventionCategoriesArgs = {
  filter?: InputMaybe<CategoriesArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CategoriesArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type SubventionGranteesPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<SubventionGranteesArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type SubventionTransactionsPaginationArgs = {
  filter?: InputMaybe<SubventionConsumptionsArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<SubventionConsumptionsArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type SubventionUserPaginationArgs = {
  filter?: InputMaybe<SubventionUserArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<SubventionUserArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type SubventionAbortOutput = {
  __typename?: 'SubventionAbortOutput';
  subvention: Subvention;
};

export type SubventionAmount = SubventionAmountDiscount | SubventionAmountFund;

export type SubventionAmountDiscount = {
  __typename?: 'SubventionAmountDiscount';
  amountDiscounted?: Maybe<Scalars['Int']['output']>;
  productQuantity?: Maybe<Scalars['Int']['output']>;
};

export type SubventionAmountFund = {
  __typename?: 'SubventionAmountFund';
  amountByGrantee?: Maybe<Scalars['Int']['output']>;
};

export type SubventionArgsFilter = {
  AND?: InputMaybe<Array<SubventionArgsFilter>>;
  OR?: InputMaybe<Array<SubventionArgsFilter>>;
  company?: InputMaybe<CompaniesArgsFilter>;
  endsAt?: InputMaybe<FilterDateArgs>;
  id?: InputMaybe<FilterIdArgs>;
  name?: InputMaybe<FilterStringArgs>;
  spendingMode?: InputMaybe<SubventionSpendingMode>;
  startsAt?: InputMaybe<FilterDateArgs>;
  state?: InputMaybe<SubventionArgsFilterState>;
  type?: InputMaybe<SubventionArgsFilterType>;
};

export type SubventionArgsFilterState = {
  in?: InputMaybe<Array<SubventionState>>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<SubventionState>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<SubventionState>;
  notIn?: InputMaybe<Array<SubventionState>>;
};

export type SubventionArgsFilterType = {
  in?: InputMaybe<Array<SubventionType>>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<SubventionType>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<SubventionType>;
  notIn?: InputMaybe<Array<SubventionType>>;
};

export type SubventionArgsSorter = {
  amountByGrantee?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  endsAt?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  startsAt?: InputMaybe<SortDirection>;
  state?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type SubventionCompleteOutput = {
  __typename?: 'SubventionCompleteOutput';
  subvention: Subvention;
};

export type SubventionConfirmInput = {
  amountByGrantee?: InputMaybe<Scalars['Int']['input']>;
  amountDiscounted?: InputMaybe<Scalars['Int']['input']>;
  cumulative?: InputMaybe<Scalars['Boolean']['input']>;
  endsAt: Scalars['Date']['input'];
  maxBudget?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  productQuantity?: InputMaybe<Scalars['Int']['input']>;
  recurrence?: InputMaybe<SubventionRecurrence>;
  startsAt: Scalars['Date']['input'];
};

export type SubventionConfirmOutput = {
  __typename?: 'SubventionConfirmOutput';
  subvention: Subvention;
};

export type SubventionConsumption = {
  amount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  order: Order;
  status: SubventionConsumptionStatus;
  subvention: Subvention;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

export type SubventionConsumptionArgsFilterStatus = {
  in?: InputMaybe<Array<SubventionConsumptionStatus>>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<SubventionConsumptionStatus>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<SubventionConsumptionStatus>;
  notIn?: InputMaybe<Array<SubventionConsumptionStatus>>;
};

export enum SubventionConsumptionStatus {
  Draft = 'DRAFT',
  Retained = 'RETAINED',
  Used = 'USED'
}

export type SubventionConsumptionsArgsFilter = {
  order?: InputMaybe<OrderPaginationArgsFilter>;
  status?: InputMaybe<SubventionConsumptionArgsFilterStatus>;
};

export type SubventionConsumptionsArgsSorter = {
  amount?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
};

export type SubventionConsumptionsEdge = Edge & {
  __typename?: 'SubventionConsumptionsEdge';
  cursor: Scalars['ID']['output'];
  node: SubventionConsumption;
};

export type SubventionConsumptionsPagination = Pagination & {
  __typename?: 'SubventionConsumptionsPagination';
  edges: Array<SubventionConsumptionsEdge>;
  nodes: Array<SubventionConsumption>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SubventionCreateInput = {
  isCustom: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

export type SubventionCreateOutput = {
  __typename?: 'SubventionCreateOutput';
  subvention: Subvention;
};

export type SubventionCreatedData = EventData & SubventionData & {
  __typename?: 'SubventionCreatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  amountByGrantee?: Maybe<TrackableNumber>;
  amountDiscounted?: Maybe<TrackableNumber>;
  brands?: Maybe<TrackableSubventionBrands>;
  campaigns?: Maybe<TrackableSubventionCampaigns>;
  categories?: Maybe<TrackableSubventionCategories>;
  cohortId?: Maybe<TrackableString>;
  companyId?: Maybe<TrackableString>;
  cumulative?: Maybe<TrackableBoolean>;
  detail?: Maybe<TrackableString>;
  endsAt?: Maybe<TrackableString>;
  granteesFrozen?: Maybe<TrackableBoolean>;
  granteesTypes?: Maybe<TrackableSubventionGranteeType>;
  isCustom?: Maybe<TrackableBoolean>;
  maxBudget?: Maybe<TrackableNumber>;
  name?: Maybe<TrackableString>;
  productQuantity?: Maybe<TrackableNumber>;
  recurrence?: Maybe<TrackableSubventionRecurrence>;
  refundPolicy?: Maybe<TrackableString>;
  reimbursementPolicy?: Maybe<TrackableString>;
  spendingModes?: Maybe<TrackableSubventionSpendingMode>;
  startsAt?: Maybe<TrackableString>;
  state?: Maybe<TrackableSubventionState>;
  supplyRate?: Maybe<TrackableNumber>;
  type?: Maybe<TrackableSubventionType>;
};

export type SubventionData = {
  amountByGrantee?: Maybe<TrackableNumber>;
  amountDiscounted?: Maybe<TrackableNumber>;
  brands?: Maybe<TrackableSubventionBrands>;
  campaigns?: Maybe<TrackableSubventionCampaigns>;
  categories?: Maybe<TrackableSubventionCategories>;
  cohortId?: Maybe<TrackableString>;
  companyId?: Maybe<TrackableString>;
  cumulative?: Maybe<TrackableBoolean>;
  detail?: Maybe<TrackableString>;
  endsAt?: Maybe<TrackableString>;
  granteesFrozen?: Maybe<TrackableBoolean>;
  granteesTypes?: Maybe<TrackableSubventionGranteeType>;
  isCustom?: Maybe<TrackableBoolean>;
  maxBudget?: Maybe<TrackableNumber>;
  name?: Maybe<TrackableString>;
  productQuantity?: Maybe<TrackableNumber>;
  recurrence?: Maybe<TrackableSubventionRecurrence>;
  refundPolicy?: Maybe<TrackableString>;
  reimbursementPolicy?: Maybe<TrackableString>;
  spendingModes?: Maybe<TrackableSubventionSpendingMode>;
  startsAt?: Maybe<TrackableString>;
  state?: Maybe<TrackableSubventionState>;
  supplyRate?: Maybe<TrackableNumber>;
  type?: Maybe<TrackableSubventionType>;
};

export type SubventionDeleteOutput = {
  __typename?: 'SubventionDeleteOutput';
  subvention: Subvention;
};

export type SubventionDeletedData = EventData & SubventionData & {
  __typename?: 'SubventionDeletedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  amountByGrantee?: Maybe<TrackableNumber>;
  amountDiscounted?: Maybe<TrackableNumber>;
  brands?: Maybe<TrackableSubventionBrands>;
  campaigns?: Maybe<TrackableSubventionCampaigns>;
  categories?: Maybe<TrackableSubventionCategories>;
  cohortId?: Maybe<TrackableString>;
  companyId?: Maybe<TrackableString>;
  cumulative?: Maybe<TrackableBoolean>;
  detail?: Maybe<TrackableString>;
  endsAt?: Maybe<TrackableString>;
  granteesFrozen?: Maybe<TrackableBoolean>;
  granteesTypes?: Maybe<TrackableSubventionGranteeType>;
  isCustom?: Maybe<TrackableBoolean>;
  maxBudget?: Maybe<TrackableNumber>;
  name?: Maybe<TrackableString>;
  productQuantity?: Maybe<TrackableNumber>;
  recurrence?: Maybe<TrackableSubventionRecurrence>;
  refundPolicy?: Maybe<TrackableString>;
  reimbursementPolicy?: Maybe<TrackableString>;
  spendingModes?: Maybe<TrackableSubventionSpendingMode>;
  startsAt?: Maybe<TrackableString>;
  state?: Maybe<TrackableSubventionState>;
  supplyRate?: Maybe<TrackableNumber>;
  type?: Maybe<TrackableSubventionType>;
};

export type SubventionDiscount = DeletableNode & Node & Publishable & Subvention & {
  __typename?: 'SubventionDiscount';
  /** @deprecated Use amountByGrantee or amountDiscounted or productQuantity */
  amount?: Maybe<SubventionAmount>;
  amountDiscounted: Scalars['Int']['output'];
  auditTrailPagination: AuditTrailPagination;
  /** @deprecated Une campaignPagination instead */
  brands: BrandsPagination;
  /** @deprecated Use budgetConsumedBigInt instead */
  budgetConsumed: Scalars['Int']['output'];
  budgetConsumedBigInt: Scalars['BigInt']['output'];
  /** @deprecated Use budgetTotalBigInt instead */
  budgetTotal: Scalars['Int']['output'];
  budgetTotalBigInt: Scalars['BigInt']['output'];
  campaignPagination: CampaignPagination;
  categories: CategoriesPagination;
  cohort?: Maybe<CohortUser>;
  company?: Maybe<Company>;
  consumptionQuantity: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  cumulative?: Maybe<Scalars['Boolean']['output']>;
  currency: Scalars['Currency']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  detail?: Maybe<Scalars['String']['output']>;
  endsAt?: Maybe<Scalars['Date']['output']>;
  facetAttributes?: Maybe<Scalars['String']['output']>;
  granteesCount?: Maybe<SubventionGranteesPaginationDetailedCount>;
  granteesFrozen?: Maybe<Scalars['Boolean']['output']>;
  granteesPagination: SubventionGranteesPagination;
  granteesTypes?: Maybe<Array<SubventionGranteeType>>;
  id: Scalars['ID']['output'];
  isCustom: Scalars['Boolean']['output'];
  maxBudget?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  productQuantity: Scalars['Int']['output'];
  publicationPagination: PublicationPagination;
  recurrence: SubventionRecurrence;
  /** @deprecated Use `reimbursementPolicy` instead */
  refundPolicy?: Maybe<Scalars['String']['output']>;
  reimbursementPolicy?: Maybe<Scalars['String']['output']>;
  spendingModes?: Maybe<Array<SubventionSpendingMode>>;
  startsAt?: Maybe<Scalars['Date']['output']>;
  state: SubventionState;
  subventionSchema?: Maybe<SubventionSchema>;
  supplyRate?: Maybe<Scalars['Int']['output']>;
  /** @deprecated use budgetTotal instead */
  totalBudget: Scalars['Int']['output'];
  /** totalQuantity is calculated based on the productQuantiy multiplied by the number of beneficiaries of the current user. */
  totalQuantity: Scalars['Int']['output'];
  transactionsPagination: SubventionConsumptionsPagination;
  type?: Maybe<SubventionType>;
  unusedUserCount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userPagination: SubventionUserPagination;
  viewerQuantityConsumed: Scalars['Int']['output'];
  viewerQuantityTotal: Scalars['Int']['output'];
};


export type SubventionDiscountAuditTrailPaginationArgs = {
  filter?: InputMaybe<AuditTrailArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<AuditTrailArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type SubventionDiscountBrandsArgs = {
  filter?: InputMaybe<BrandsArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<BrandsArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type SubventionDiscountCampaignPaginationArgs = {
  filter?: InputMaybe<CampaignPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CampaignPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type SubventionDiscountCategoriesArgs = {
  filter?: InputMaybe<CategoriesArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CategoriesArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type SubventionDiscountGranteesPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<SubventionGranteesArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type SubventionDiscountPublicationPaginationArgs = {
  filter?: InputMaybe<PublicationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<PublicationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type SubventionDiscountTransactionsPaginationArgs = {
  filter?: InputMaybe<SubventionConsumptionsArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<SubventionConsumptionsArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type SubventionDiscountUserPaginationArgs = {
  filter?: InputMaybe<SubventionUserArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<SubventionUserArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type SubventionDiscountDomain = SubventionDomain & {
  __typename?: 'SubventionDiscountDomain';
  /** Amount used  */
  amountDiscounted: Scalars['Int']['output'];
  /** End date of the subvention */
  endsAt: Scalars['Date']['output'];
  /** Icon of the subvention */
  icon?: Maybe<Picture>;
  /** Id of the subvention */
  id: Scalars['ID']['output'];
  /** Name of the subvention */
  name: Scalars['String']['output'];
  /** Quantity consumed for user on subvention */
  quantityConsumed: Scalars['Int']['output'];
  /** Quantity remaining for user on subvention */
  quantityRemaining: Scalars['Int']['output'];
  /** Quantity total for user on subvention */
  quantityTotal: Scalars['Int']['output'];
  /** Spending modes of the subvention */
  spendingModes: Array<SubventionSpendingMode>;
  /** Start date of the subvention */
  startsAt: Scalars['Date']['output'];
  state: SubventionState;
  type: SubventionType;
};

export type SubventionDiscountInAppConsumption = Node & SubventionConsumption & {
  __typename?: 'SubventionDiscountInAppConsumption';
  amount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  order: Order;
  orderItem: OrderItem;
  status: SubventionConsumptionStatus;
  subvention: Subvention;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

export type SubventionDiscountRefundConsumption = Node & SubventionConsumption & {
  __typename?: 'SubventionDiscountRefundConsumption';
  amount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  order: Order;
  status: SubventionConsumptionStatus;
  subvention: Subvention;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

export type SubventionDomain = {
  /** End date of the subvention */
  endsAt: Scalars['Date']['output'];
  /** Icon of the subvention */
  icon?: Maybe<Picture>;
  /** Id of the subvention */
  id: Scalars['ID']['output'];
  /** Name of the subvention */
  name: Scalars['String']['output'];
  /** Spending modes of the subvention */
  spendingModes: Array<SubventionSpendingMode>;
  /** Start date of the subvention */
  startsAt: Scalars['Date']['output'];
  state: SubventionState;
  type: SubventionType;
};

export type SubventionDraft = DeletableNode & Node & Publishable & Subvention & {
  __typename?: 'SubventionDraft';
  /** @deprecated Use amountByGrantee or amountDiscounted or productQuantity */
  amount?: Maybe<SubventionAmount>;
  amountByGrantee?: Maybe<Scalars['Int']['output']>;
  amountDiscounted?: Maybe<Scalars['Int']['output']>;
  auditTrailPagination: AuditTrailPagination;
  /** @deprecated Une campaignPagination instead */
  brands: BrandsPagination;
  /** @deprecated Use budgetConsumedBigInt instead */
  budgetConsumed: Scalars['Int']['output'];
  budgetConsumedBigInt: Scalars['BigInt']['output'];
  /** @deprecated Use budgetTotalBigInt instead */
  budgetTotal: Scalars['Int']['output'];
  budgetTotalBigInt: Scalars['BigInt']['output'];
  campaignPagination: CampaignPagination;
  categories: CategoriesPagination;
  cohort?: Maybe<CohortUser>;
  company?: Maybe<Company>;
  createdAt: Scalars['DateTime']['output'];
  cumulative?: Maybe<Scalars['Boolean']['output']>;
  currency: Scalars['Currency']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  detail?: Maybe<Scalars['String']['output']>;
  endsAt?: Maybe<Scalars['Date']['output']>;
  facetAttributes?: Maybe<Scalars['String']['output']>;
  granteesCount?: Maybe<SubventionGranteesPaginationDetailedCount>;
  granteesFrozen?: Maybe<Scalars['Boolean']['output']>;
  granteesPagination: SubventionGranteesPagination;
  granteesTypes?: Maybe<Array<SubventionGranteeType>>;
  id: Scalars['ID']['output'];
  isCustom: Scalars['Boolean']['output'];
  maxBudget?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  productQuantity?: Maybe<Scalars['Int']['output']>;
  publicationPagination: PublicationPagination;
  recurrence: SubventionRecurrence;
  /** @deprecated Use `reimbursementPolicy` instead */
  refundPolicy?: Maybe<Scalars['String']['output']>;
  reimbursementPolicy?: Maybe<Scalars['String']['output']>;
  spendingModes?: Maybe<Array<SubventionSpendingMode>>;
  startsAt?: Maybe<Scalars['Date']['output']>;
  state: SubventionState;
  subventionSchema?: Maybe<SubventionSchema>;
  supplyRate?: Maybe<Scalars['Int']['output']>;
  /** @deprecated use budgetTotal instead */
  totalBudget: Scalars['Int']['output'];
  transactionsPagination: SubventionConsumptionsPagination;
  type?: Maybe<SubventionType>;
  unusedUserCount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userPagination: SubventionUserPagination;
};


export type SubventionDraftAuditTrailPaginationArgs = {
  filter?: InputMaybe<AuditTrailArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<AuditTrailArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type SubventionDraftBrandsArgs = {
  filter?: InputMaybe<BrandsArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<BrandsArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type SubventionDraftCampaignPaginationArgs = {
  filter?: InputMaybe<CampaignPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CampaignPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type SubventionDraftCategoriesArgs = {
  filter?: InputMaybe<CategoriesArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CategoriesArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type SubventionDraftGranteesPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<SubventionGranteesArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type SubventionDraftPublicationPaginationArgs = {
  filter?: InputMaybe<PublicationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<PublicationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type SubventionDraftTransactionsPaginationArgs = {
  filter?: InputMaybe<SubventionConsumptionsArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<SubventionConsumptionsArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type SubventionDraftUserPaginationArgs = {
  filter?: InputMaybe<SubventionUserArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<SubventionUserArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type SubventionDuplicateOutput = {
  __typename?: 'SubventionDuplicateOutput';
  subvention: Subvention;
};

export type SubventionEdge = Edge & {
  __typename?: 'SubventionEdge';
  cursor: Scalars['ID']['output'];
  node: Subvention;
};

export type SubventionExtendInput = {
  endsAt: Scalars['Date']['input'];
};

export type SubventionExtendOutput = {
  __typename?: 'SubventionExtendOutput';
  subvention: Subvention;
};

export type SubventionFund = DeletableNode & Node & Publishable & Subvention & {
  __typename?: 'SubventionFund';
  /** @deprecated Use amountByGrantee or amountDiscounted or productQuantity */
  amount?: Maybe<SubventionAmount>;
  amountByGrantee: Scalars['Int']['output'];
  auditTrailPagination: AuditTrailPagination;
  /** @deprecated Une campaignPagination instead */
  brands: BrandsPagination;
  /** @deprecated Use budgetConsumedBigInt instead */
  budgetConsumed: Scalars['Int']['output'];
  budgetConsumedBigInt: Scalars['BigInt']['output'];
  /** @deprecated Use budgetTotalBigInt instead */
  budgetTotal: Scalars['Int']['output'];
  budgetTotalBigInt: Scalars['BigInt']['output'];
  campaignPagination: CampaignPagination;
  categories: CategoriesPagination;
  cohort?: Maybe<CohortUser>;
  company?: Maybe<Company>;
  createdAt: Scalars['DateTime']['output'];
  cumulative?: Maybe<Scalars['Boolean']['output']>;
  currency: Scalars['Currency']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  detail?: Maybe<Scalars['String']['output']>;
  endsAt?: Maybe<Scalars['Date']['output']>;
  facetAttributes?: Maybe<Scalars['String']['output']>;
  granteesCount?: Maybe<SubventionGranteesPaginationDetailedCount>;
  granteesFrozen?: Maybe<Scalars['Boolean']['output']>;
  granteesPagination: SubventionGranteesPagination;
  granteesTypes?: Maybe<Array<SubventionGranteeType>>;
  id: Scalars['ID']['output'];
  isCustom: Scalars['Boolean']['output'];
  maxBudget?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  publicationPagination: PublicationPagination;
  recurrence: SubventionRecurrence;
  /** @deprecated Use `reimbursementPolicy` instead */
  refundPolicy?: Maybe<Scalars['String']['output']>;
  reimbursementPolicy?: Maybe<Scalars['String']['output']>;
  spendingModes?: Maybe<Array<SubventionSpendingMode>>;
  startsAt?: Maybe<Scalars['Date']['output']>;
  state: SubventionState;
  subventionSchema?: Maybe<SubventionSchema>;
  supplyRate?: Maybe<Scalars['Int']['output']>;
  /** @deprecated use budgetTotal instead */
  totalBudget: Scalars['Int']['output'];
  transactionsPagination: SubventionConsumptionsPagination;
  type?: Maybe<SubventionType>;
  unusedUserCount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userPagination: SubventionUserPagination;
};


export type SubventionFundAuditTrailPaginationArgs = {
  filter?: InputMaybe<AuditTrailArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<AuditTrailArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type SubventionFundBrandsArgs = {
  filter?: InputMaybe<BrandsArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<BrandsArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type SubventionFundCampaignPaginationArgs = {
  filter?: InputMaybe<CampaignPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CampaignPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type SubventionFundCategoriesArgs = {
  filter?: InputMaybe<CategoriesArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CategoriesArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type SubventionFundGranteesPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<SubventionGranteesArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type SubventionFundPublicationPaginationArgs = {
  filter?: InputMaybe<PublicationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<PublicationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type SubventionFundTransactionsPaginationArgs = {
  filter?: InputMaybe<SubventionConsumptionsArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<SubventionConsumptionsArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type SubventionFundUserPaginationArgs = {
  filter?: InputMaybe<SubventionUserArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<SubventionUserArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type SubventionFundDomain = SubventionDomain & {
  __typename?: 'SubventionFundDomain';
  /** Amount consumed for user on subvention */
  amountConsumed: Scalars['BigInt']['output'];
  /** Amount remaining for user on subvention */
  amountRemaining: Scalars['BigInt']['output'];
  /** Amount total for user on subvention */
  amountTotal: Scalars['BigInt']['output'];
  /** End date of the subvention */
  endsAt: Scalars['Date']['output'];
  /** Icon of the subvention */
  icon?: Maybe<Picture>;
  /** Id of the subvention */
  id: Scalars['ID']['output'];
  /** Name of the subvention */
  name: Scalars['String']['output'];
  /** Spending modes of the subvention */
  spendingModes: Array<SubventionSpendingMode>;
  /** Start date of the subvention */
  startsAt: Scalars['Date']['output'];
  state: SubventionState;
  type: SubventionType;
};

export type SubventionFundInAppConsumption = Node & SubventionConsumption & {
  __typename?: 'SubventionFundInAppConsumption';
  amount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  order: Order;
  status: SubventionConsumptionStatus;
  subvention: Subvention;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

export type SubventionFundRefundConsumption = Node & SubventionConsumption & {
  __typename?: 'SubventionFundRefundConsumption';
  amount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  order: Order;
  status: SubventionConsumptionStatus;
  subvention: Subvention;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

export type SubventionFundReimbursementConsumption = Node & SubventionConsumption & {
  __typename?: 'SubventionFundReimbursementConsumption';
  amount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  order: Order;
  status: SubventionConsumptionStatus;
  subvention: Subvention;
  subventionReimbursementRequest: SubventionReimbursementRequest;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

export type SubventionGrantee = DeletableNode & Node & {
  __typename?: 'SubventionGrantee';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  target: GranteeTarget;
  updatedAt: Scalars['DateTime']['output'];
};

export type SubventionGranteeAddOutput = {
  __typename?: 'SubventionGranteeAddOutput';
  subventionGrantees: Array<SubventionGrantee>;
};

export type SubventionGranteeEdge = Edge & {
  __typename?: 'SubventionGranteeEdge';
  cursor: Scalars['ID']['output'];
  node: SubventionGrantee;
};

export enum SubventionGranteeType {
  Child = 'CHILD',
  Partner = 'PARTNER',
  User = 'USER'
}

export type SubventionGranteesArgsSorter = {
  createdAt?: InputMaybe<SortDirection>;
};

export type SubventionGranteesEstimation = {
  __typename?: 'SubventionGranteesEstimation';
  totalCount: Scalars['Int']['output'];
};

export type SubventionGranteesPagination = Pagination & {
  __typename?: 'SubventionGranteesPagination';
  /** @deprecated Use granteesCount on subvention instead */
  detailedCount: SubventionGranteesPaginationDetailedCount;
  edges: Array<SubventionGranteeEdge>;
  nodes: Array<SubventionGrantee>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SubventionGranteesPaginationDetailedCount = {
  __typename?: 'SubventionGranteesPaginationDetailedCount';
  children: Scalars['Int']['output'];
  partners: Scalars['Int']['output'];
  users: Scalars['Int']['output'];
};

export type SubventionGranteesRecomputeOutput = {
  __typename?: 'SubventionGranteesRecomputeOutput';
  subvention: Subvention;
};

export type SubventionInvoice = Node & {
  __typename?: 'SubventionInvoice';
  amount: Scalars['Int']['output'];
  company: Company;
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['Currency']['output'];
  /** @deprecated Use invoice.file instead */
  file: File;
  id: Scalars['ID']['output'];
  invoice: Invoice;
  periodEnd: Scalars['DateTime']['output'];
  periodStart: Scalars['DateTime']['output'];
  refunds: Array<SubventionInvoiceRefund>;
  updatedAt: Scalars['DateTime']['output'];
};

export type SubventionInvoiceArgsSorter = {
  createdAt?: InputMaybe<SortDirection>;
  periodEnd?: InputMaybe<SortDirection>;
  periodStart?: InputMaybe<SortDirection>;
};

export type SubventionInvoiceEdge = Edge & {
  __typename?: 'SubventionInvoiceEdge';
  cursor: Scalars['ID']['output'];
  node: SubventionInvoice;
};

export type SubventionInvoicePagination = Pagination & {
  __typename?: 'SubventionInvoicePagination';
  edges: Array<SubventionInvoiceEdge>;
  nodes: Array<SubventionInvoice>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SubventionInvoiceRefund = Node & {
  __typename?: 'SubventionInvoiceRefund';
  amount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  creditNote: CreditNote;
  currency: Scalars['Currency']['output'];
  id: Scalars['ID']['output'];
  periodEnd: Scalars['DateTime']['output'];
  periodStart: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SubventionLegacyConsumption = Node & SubventionConsumption & {
  __typename?: 'SubventionLegacyConsumption';
  amount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  order: Order;
  status: SubventionConsumptionStatus;
  subvention: Subvention;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

export type SubventionLockOutput = {
  __typename?: 'SubventionLockOutput';
  subvention: Subvention;
};

export type SubventionPagination = Pagination & {
  __typename?: 'SubventionPagination';
  edges: Array<SubventionEdge>;
  nodes: Array<Subvention>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SubventionPublishOutput = {
  __typename?: 'SubventionPublishOutput';
  subvention: Subvention;
};

export enum SubventionRecurrence {
  Month = 'MONTH',
  None = 'NONE',
  Quarter = 'QUARTER',
  Semester = 'SEMESTER'
}

export type SubventionRefund = Node & {
  __typename?: 'SubventionRefund';
  amount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  subvention: Subvention;
  updatedAt: Scalars['DateTime']['output'];
};

export type SubventionRefundEdge = Edge & {
  __typename?: 'SubventionRefundEdge';
  cursor: Scalars['ID']['output'];
  node: SubventionRefund;
};

export type SubventionRefundPagination = CorePagination & {
  __typename?: 'SubventionRefundPagination';
  edges: Array<SubventionRefundEdge>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SubventionReimbursementRequest = Node & {
  __typename?: 'SubventionReimbursementRequest';
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['Currency']['output'];
  customMerchant: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoiceDate: Scalars['Date']['output'];
  mangopayBankwirePayOutPagination: MangopayBankwirePayOutPagination;
  processedAt?: Maybe<Scalars['DateTime']['output']>;
  processedBy?: Maybe<User>;
  proofs: FilesPagination;
  reimbursementAmount: Scalars['Int']['output'];
  rejectedReason?: Maybe<Scalars['String']['output']>;
  status: SubventionReimbursementRequestStatus;
  subvention: SubventionFund;
  totalAmount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};


export type SubventionReimbursementRequestMangopayBankwirePayOutPaginationArgs = {
  filter?: InputMaybe<MangopayBankwirePayOutArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<MangopayBankwirePayOutArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type SubventionReimbursementRequestProofsArgs = {
  filter?: InputMaybe<FilesFilterArgs>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type SubventionReimbursementRequestArgsFilter = {
  AND?: InputMaybe<Array<SubventionReimbursementRequestArgsFilter>>;
  OR?: InputMaybe<Array<SubventionReimbursementRequestArgsFilter>>;
  createdAt?: InputMaybe<FilterDateArgs>;
  id?: InputMaybe<FilterIdArgs>;
  status?: InputMaybe<SubventionReimbursementRequestArgsFilterStatus>;
  subventions?: InputMaybe<SubventionArgsFilter>;
  user?: InputMaybe<UsersArgsFilter>;
};

export type SubventionReimbursementRequestArgsFilterStatus = {
  in?: InputMaybe<Array<SubventionReimbursementRequestStatus>>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<SubventionReimbursementRequestStatus>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<SubventionReimbursementRequestStatus>;
  notIn?: InputMaybe<Array<SubventionReimbursementRequestStatus>>;
};

export type SubventionReimbursementRequestArgsSorter = {
  createdAt?: InputMaybe<SortDirection>;
};

export type SubventionReimbursementRequestCreateInput = {
  currency?: InputMaybe<Scalars['Currency']['input']>;
  customMerchant: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  invoiceDate: Scalars['Date']['input'];
  proofIds: Array<Scalars['ID']['input']>;
  reimbursementAmount: Scalars['Int']['input'];
  subventions: Array<SubventionReimbursementRequestSubventionInput>;
  totalAmount: Scalars['Int']['input'];
};

export type SubventionReimbursementRequestCreateOutput = {
  __typename?: 'SubventionReimbursementRequestCreateOutput';
  subventionReimbursementRequest: SubventionReimbursementRequest;
};

export type SubventionReimbursementRequestEdge = Edge & {
  __typename?: 'SubventionReimbursementRequestEdge';
  cursor: Scalars['ID']['output'];
  node: SubventionReimbursementRequest;
};

export type SubventionReimbursementRequestOutput = {
  __typename?: 'SubventionReimbursementRequestOutput';
  subventionReimbursementRequest: SubventionReimbursementRequest;
};

export type SubventionReimbursementRequestPagination = Pagination & {
  __typename?: 'SubventionReimbursementRequestPagination';
  edges: Array<SubventionReimbursementRequestEdge>;
  nodes: Array<SubventionReimbursementRequest>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SubventionReimbursementRequestRejectInput = {
  rejectedReason: Scalars['String']['input'];
};

export type SubventionReimbursementRequestRelationFilter = {
  NONE?: InputMaybe<SubventionReimbursementRequestArgsFilter>;
  SOME?: InputMaybe<SubventionReimbursementRequestArgsFilter>;
};

export enum SubventionReimbursementRequestStatus {
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  PayOutCreated = 'PAY_OUT_CREATED',
  PayOutFailed = 'PAY_OUT_FAILED',
  PayOutSucceeded = 'PAY_OUT_SUCCEEDED',
  Rejected = 'REJECTED',
  WaitingForApproval = 'WAITING_FOR_APPROVAL'
}

export type SubventionReimbursementRequestSubventionInput = {
  id: Scalars['ID']['input'];
};

export type SubventionRenameInput = {
  name: Scalars['String']['input'];
};

export type SubventionRenameOutput = {
  __typename?: 'SubventionRenameOutput';
  subvention: Subvention;
};

export type SubventionScheduleInput = {
  startsAt: Scalars['Date']['input'];
};

export type SubventionScheduleOutput = {
  __typename?: 'SubventionScheduleOutput';
  subvention: Subvention;
};

export type SubventionSchema = DeletableNode & ISubventionSchemaTranslation & Node & Translation & {
  __typename?: 'SubventionSchema';
  accessType: SubventionSchemaAccessType;
  blacklistCompaniesPagination: CompaniesPagination;
  /** Used by subvention creation */
  campaignsPagination: CampaignsPagination;
  categoriesPagination: CategoriesPagination;
  childrenPagination: SubventionSchemaPagination;
  /** @deprecated Removed */
  cohortOffer: CohortOffer;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Picture>;
  id: Scalars['ID']['output'];
  legalNotice?: Maybe<Scalars['String']['output']>;
  locale: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** Super admin only */
  offerIds: Array<Scalars['ID']['output']>;
  offersPagination: OffersPagination;
  parent?: Maybe<SubventionSchema>;
  reimbursementPolicy?: Maybe<Scalars['String']['output']>;
  spendingModes: Array<SubventionSpendingMode>;
  status: SubventionSchemaStatus;
  /** Args is not used. */
  translationsPagination: SubventionSchemaTranslationsPagination;
  type: Array<SubventionType>;
  updatedAt: Scalars['DateTime']['output'];
  whitelistCompaniesPagination: CompaniesPagination;
};


export type SubventionSchemaBlacklistCompaniesPaginationArgs = {
  filter?: InputMaybe<CompaniesArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CompaniesArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type SubventionSchemaCampaignsPaginationArgs = {
  filter?: InputMaybe<CampaignsArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CampaignsArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type SubventionSchemaCategoriesPaginationArgs = {
  filter?: InputMaybe<CategoriesArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CategoriesArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type SubventionSchemaChildrenPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<SubventionSchemaPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type SubventionSchemaOffersPaginationArgs = {
  filter?: InputMaybe<OffersArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<OffersArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type SubventionSchemaTranslationsPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type SubventionSchemaWhitelistCompaniesPaginationArgs = {
  filter?: InputMaybe<CompaniesArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CompaniesArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

/** Schema access type */
export enum SubventionSchemaAccessType {
  Open = 'OPEN',
  Whitelist = 'WHITELIST'
}

export type SubventionSchemaBlacklistCompaniesAddInput = {
  blacklistCompanyIds: Array<Scalars['ID']['input']>;
};

export type SubventionSchemaBlacklistCompaniesAddOutput = {
  __typename?: 'SubventionSchemaBlacklistCompaniesAddOutput';
  subventionSchema: SubventionSchema;
};

export type SubventionSchemaBlacklistCompaniesRemoveInput = {
  blacklistCompanyIds: Array<Scalars['ID']['input']>;
};

export type SubventionSchemaBlacklistCompaniesRemoveOutput = {
  __typename?: 'SubventionSchemaBlacklistCompaniesRemoveOutput';
  subventionSchema: SubventionSchema;
};

export type SubventionSchemaCreateInput = {
  accessType: SubventionSchemaAccessType;
  description?: InputMaybe<Scalars['String']['input']>;
  iconId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  parentSubventionSchemaId?: InputMaybe<Scalars['ID']['input']>;
  reimbursementPolicy?: InputMaybe<Scalars['String']['input']>;
  spendingModes: Array<SubventionSpendingMode>;
  translations: Array<SubventionSchemaTranslationInput>;
  type: Array<SubventionType>;
};

export type SubventionSchemaCreateOutput = {
  __typename?: 'SubventionSchemaCreateOutput';
  subventionSchema: SubventionSchema;
};

export type SubventionSchemaDeleteOutput = {
  __typename?: 'SubventionSchemaDeleteOutput';
  subventionSchema: SubventionSchema;
};

export type SubventionSchemaOffersAddInput = {
  offerIds: Array<Scalars['ID']['input']>;
};

export type SubventionSchemaOffersAddOutput = {
  __typename?: 'SubventionSchemaOffersAddOutput';
  subventionSchema: SubventionSchema;
};

export type SubventionSchemaOffersRemoveInput = {
  offerIds: Array<Scalars['ID']['input']>;
};

export type SubventionSchemaOffersRemoveOutput = {
  __typename?: 'SubventionSchemaOffersRemoveOutput';
  subventionSchema: SubventionSchema;
};

export type SubventionSchemaPagination = Pagination & {
  __typename?: 'SubventionSchemaPagination';
  edges: Array<SubventionSchemaPaginationEdge>;
  nodes: Array<SubventionSchema>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SubventionSchemaPaginationArgsSorter = {
  createdAt?: InputMaybe<SortDirection>;
};

export type SubventionSchemaPaginationEdge = Edge & {
  __typename?: 'SubventionSchemaPaginationEdge';
  cursor: Scalars['ID']['output'];
  node: SubventionSchema;
};

export type SubventionSchemaPublishOutput = {
  __typename?: 'SubventionSchemaPublishOutput';
  subventionSchema: SubventionSchema;
};

export type SubventionSchemaRestoreOutput = {
  __typename?: 'SubventionSchemaRestoreOutput';
  subventionSchema: SubventionSchema;
};

/** Schema status */
export enum SubventionSchemaStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED'
}

export type SubventionSchemaTranslation = ISubventionSchemaTranslation & Node & Translation & {
  __typename?: 'SubventionSchemaTranslation';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  legalNotice?: Maybe<Scalars['String']['output']>;
  locale: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SubventionSchemaTranslationEdge = Edge & TranslationEdge & {
  __typename?: 'SubventionSchemaTranslationEdge';
  cursor: Scalars['ID']['output'];
  node: SubventionSchemaTranslation;
};

export type SubventionSchemaTranslationInput = {
  legalNotice?: InputMaybe<Scalars['String']['input']>;
  localeId?: InputMaybe<Scalars['ID']['input']>;
};

export type SubventionSchemaTranslationsPagination = Pagination & TranslationsPagination & {
  __typename?: 'SubventionSchemaTranslationsPagination';
  edges: Array<SubventionSchemaTranslationEdge>;
  nodes: Array<SubventionSchemaTranslation>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SubventionSchemaUnpublishOutput = {
  __typename?: 'SubventionSchemaUnpublishOutput';
  subventionSchema: SubventionSchema;
};

export type SubventionSchemaUpdateInput = {
  accessType?: InputMaybe<SubventionSchemaAccessType>;
  description?: InputMaybe<Scalars['String']['input']>;
  iconId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  reimbursementPolicy?: InputMaybe<Scalars['String']['input']>;
  spendingModes?: InputMaybe<Array<SubventionSpendingMode>>;
  translations?: InputMaybe<Array<SubventionSchemaTranslationInput>>;
  type?: InputMaybe<Array<SubventionType>>;
};

export type SubventionSchemaUpdateOutput = {
  __typename?: 'SubventionSchemaUpdateOutput';
  subventionSchema: SubventionSchema;
};

export type SubventionSchemaWhitelistCompaniesAddInput = {
  whitelistCompanyIds: Array<Scalars['ID']['input']>;
};

export type SubventionSchemaWhitelistCompaniesAddOutput = {
  __typename?: 'SubventionSchemaWhitelistCompaniesAddOutput';
  subventionSchema: SubventionSchema;
};

export type SubventionSchemaWhitelistCompaniesRemoveInput = {
  whitelistCompanyIds: Array<Scalars['ID']['input']>;
};

export type SubventionSchemaWhitelistCompaniesRemoveOutput = {
  __typename?: 'SubventionSchemaWhitelistCompaniesRemoveOutput';
  subventionSchema: SubventionSchema;
};

export enum SubventionSpendingMode {
  App = 'APP',
  Refund = 'REFUND',
  Reimbursement = 'REIMBURSEMENT'
}

export enum SubventionState {
  Aborted = 'ABORTED',
  AwaitingProvision = 'AWAITING_PROVISION',
  AwaitingScheduling = 'AWAITING_SCHEDULING',
  Completed = 'COMPLETED',
  Confirmed = 'CONFIRMED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Ready = 'READY'
}

export enum SubventionType {
  Discount = 'DISCOUNT',
  Fund = 'FUND'
}

export type SubventionUpdateInput = {
  amountByGrantee?: InputMaybe<Scalars['Int']['input']>;
  amountDiscounted?: InputMaybe<Scalars['Int']['input']>;
  brandIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  campaignIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  categoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  cohortId?: InputMaybe<Scalars['ID']['input']>;
  cumulative?: InputMaybe<Scalars['Boolean']['input']>;
  detail?: InputMaybe<Scalars['String']['input']>;
  endsAt?: InputMaybe<Scalars['Date']['input']>;
  granteesFrozen?: InputMaybe<Scalars['Boolean']['input']>;
  granteesTypes?: InputMaybe<Array<SubventionGranteeType>>;
  isCustom?: InputMaybe<Scalars['Boolean']['input']>;
  maxBudget?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  productQuantity?: InputMaybe<Scalars['Int']['input']>;
  recurrence?: InputMaybe<SubventionRecurrence>;
  refundPolicy?: InputMaybe<Scalars['String']['input']>;
  reimbursementPolicy?: InputMaybe<Scalars['String']['input']>;
  selectionMode?: InputMaybe<CohortSelectionMode>;
  spendingModes?: InputMaybe<Array<SubventionSpendingMode>>;
  startsAt?: InputMaybe<Scalars['Date']['input']>;
  subventionSchemaId?: InputMaybe<Scalars['ID']['input']>;
  supplyRate?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<SubventionType>;
  usersArgsFilter?: InputMaybe<UsersArgsFilter>;
};

export type SubventionUpdateOutput = {
  __typename?: 'SubventionUpdateOutput';
  subvention: Subvention;
};

export type SubventionUpdatedData = EventData & SubventionData & {
  __typename?: 'SubventionUpdatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  amountByGrantee?: Maybe<TrackableNumber>;
  amountDiscounted?: Maybe<TrackableNumber>;
  brands?: Maybe<TrackableSubventionBrands>;
  campaigns?: Maybe<TrackableSubventionCampaigns>;
  categories?: Maybe<TrackableSubventionCategories>;
  cohortId?: Maybe<TrackableString>;
  companyId?: Maybe<TrackableString>;
  cumulative?: Maybe<TrackableBoolean>;
  detail?: Maybe<TrackableString>;
  endsAt?: Maybe<TrackableString>;
  granteesFrozen?: Maybe<TrackableBoolean>;
  granteesTypes?: Maybe<TrackableSubventionGranteeType>;
  isCustom?: Maybe<TrackableBoolean>;
  maxBudget?: Maybe<TrackableNumber>;
  name?: Maybe<TrackableString>;
  productQuantity?: Maybe<TrackableNumber>;
  recurrence?: Maybe<TrackableSubventionRecurrence>;
  refundPolicy?: Maybe<TrackableString>;
  reimbursementPolicy?: Maybe<TrackableString>;
  spendingModes?: Maybe<TrackableSubventionSpendingMode>;
  startsAt?: Maybe<TrackableString>;
  state?: Maybe<TrackableSubventionState>;
  supplyRate?: Maybe<TrackableNumber>;
  type?: Maybe<TrackableSubventionType>;
};

export type SubventionUserArgsFilter = {
  AND?: InputMaybe<Array<UsersArgsFilter>>;
  OR?: InputMaybe<Array<UsersArgsFilter>>;
  amountConsumed?: InputMaybe<FilterIntArgs>;
  beneficiaries?: InputMaybe<BeneficiariesRelationFilter>;
  beneficiary?: InputMaybe<BeneficiaryTypeFilter>;
  birthdate?: InputMaybe<FilterDateOnlyArgs>;
  companyMembership?: InputMaybe<CompanyMembershipsArgsFilter>;
  createdAt?: InputMaybe<FilterDateArgs>;
  email?: InputMaybe<FilterStringArgs>;
  firstName?: InputMaybe<FilterStringArgs>;
  fullName?: InputMaybe<UsersArgsFilterFullName>;
  hasConsumed?: InputMaybe<FilterBoolArgs>;
  id?: InputMaybe<FilterIdArgs>;
  lastActiveAt?: InputMaybe<FilterDateArgs>;
  lastLoginAt?: InputMaybe<FilterDateArgs>;
  lastName?: InputMaybe<FilterStringArgs>;
  nationality?: InputMaybe<FilterNationalityArgs>;
  orders?: InputMaybe<UsersArgsFilterOrders>;
  preferences?: InputMaybe<UserPreferencesArgsFilter>;
  role?: InputMaybe<FilterRoleArgs>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<FilterStatusArgs>;
};

export type SubventionUserArgsSorter = {
  amountConsumed?: InputMaybe<SortDirection>;
  company?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  email?: InputMaybe<SortDirection>;
  firstName?: InputMaybe<SortDirection>;
  fullName?: InputMaybe<SortDirection>;
  lastActiveAt?: InputMaybe<SortDirection>;
  lastLoginAt?: InputMaybe<SortDirection>;
  lastName?: InputMaybe<SortDirection>;
  nationality?: InputMaybe<SortDirection>;
  orderCount?: InputMaybe<SortDirection>;
  quantityConsumed?: InputMaybe<SortDirection>;
  role?: InputMaybe<SortDirection>;
  status?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type SubventionUserEdge = Edge & {
  __typename?: 'SubventionUserEdge';
  /** Amount consumed on subvention for user */
  amountConsumed: Scalars['Int']['output'];
  cursor: Scalars['ID']['output'];
  grantees: Array<SubventionGrantee>;
  node: User;
  /** Product consumed on discount subvention for user */
  quantityConsumed?: Maybe<Scalars['Int']['output']>;
};

export type SubventionUserPagination = Pagination & {
  __typename?: 'SubventionUserPagination';
  edges: Array<SubventionUserEdge>;
  nodes: Array<User>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SubventionValidateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type SubventionValidateOutput = {
  __typename?: 'SubventionValidateOutput';
  subvention: Subvention;
};

export type SubventionValidateProvisionOutput = {
  __typename?: 'SubventionValidateProvisionOutput';
  subvention: Subvention;
};

export type SubventionViewerArgsFilter = {
  AND?: InputMaybe<Array<SubventionArgsFilter>>;
  OR?: InputMaybe<Array<SubventionArgsFilter>>;
  campaignId?: InputMaybe<Scalars['ID']['input']>;
  company?: InputMaybe<CompaniesArgsFilter>;
  endsAt?: InputMaybe<FilterDateArgs>;
  id?: InputMaybe<FilterIdArgs>;
  name?: InputMaybe<FilterStringArgs>;
  offerId?: InputMaybe<Scalars['ID']['input']>;
  spendingMode?: InputMaybe<SubventionSpendingMode>;
  startsAt?: InputMaybe<FilterDateArgs>;
  state?: InputMaybe<SubventionArgsFilterState>;
  type?: InputMaybe<SubventionArgsFilterType>;
};

export type Survey = DeletableNode & Node & {
  __typename?: 'Survey';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date and time when the survey ends */
  endsAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** Provider of the survey, e.g. GoogleForm, Typeform, etc. */
  provider: SurveyProvider;
  /** Id of the form supplied by the provider */
  providerId: Scalars['String']['output'];
  /** Date and time when the survey starts */
  startsAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SurveyBaseOutput = {
  survey: Survey;
};

export type SurveyCreateInput = {
  endsAt: Scalars['DateTime']['input'];
  provider: SurveyProvider;
  startsAt: Scalars['DateTime']['input'];
  url: Scalars['String']['input'];
};

export type SurveyCreateOutput = SurveyBaseOutput & {
  __typename?: 'SurveyCreateOutput';
  survey: Survey;
};

export type SurveyDeleteOutput = SurveyBaseOutput & {
  __typename?: 'SurveyDeleteOutput';
  survey: Survey;
};

export type SurveyFeatureBaseOutput = {
  surveyFeature: SurveyFeatureConfig;
};

export type SurveyFeatureConfig = FeatureConfig & {
  __typename?: 'SurveyFeatureConfig';
  createdAt: Scalars['DateTime']['output'];
  /** This id matches the company ID */
  id: Scalars['ID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SurveyFeatureDisableOutput = SurveyFeatureBaseOutput & {
  __typename?: 'SurveyFeatureDisableOutput';
  surveyFeature: SurveyFeatureConfig;
};

export type SurveyFeatureEnableOutput = SurveyFeatureBaseOutput & {
  __typename?: 'SurveyFeatureEnableOutput';
  surveyFeature: SurveyFeatureConfig;
};

export enum SurveyProvider {
  GoogleForm = 'GOOGLE_FORM',
  MicrosoftForm = 'MICROSOFT_FORM',
  Tally = 'TALLY',
  Typeform = 'TYPEFORM'
}

export type SurveyUpdateInput = {
  endsAt?: InputMaybe<Scalars['DateTime']['input']>;
  provider?: InputMaybe<SurveyProvider>;
  startsAt?: InputMaybe<Scalars['DateTime']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type SurveyUpdateOutput = SurveyBaseOutput & {
  __typename?: 'SurveyUpdateOutput';
  survey: Survey;
};

export type TaxLine = Node & {
  __typename?: 'TaxLine';
  amount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['Currency']['output'];
  id: Scalars['ID']['output'];
  taxRate: TaxRate;
  updatedAt: Scalars['DateTime']['output'];
};

export type TaxRate = DeletableNode & Node & {
  __typename?: 'TaxRate';
  country: CountryCode;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  percent: Scalars['Float']['output'];
  state?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type TaxRateArgsFilter = {
  AND?: InputMaybe<Array<TaxRateArgsFilter>>;
  OR?: InputMaybe<Array<TaxRateArgsFilter>>;
  country?: InputMaybe<TaxRateFilterArgsCountry>;
  displayName?: InputMaybe<FilterStringArgs>;
  id?: InputMaybe<FilterIdArgs>;
};

export type TaxRateArgsSorter = {
  createdAt?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type TaxRateCreateInput = {
  country: CountryCode;
  displayName: Scalars['String']['input'];
  percent: Scalars['Float']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
};

export type TaxRateCreateOutput = {
  __typename?: 'TaxRateCreateOutput';
  taxRate: TaxRate;
};

export type TaxRateCreatedData = EventData & TaxRateCreationDataFields & {
  __typename?: 'TaxRateCreatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  country: TrackableString;
  displayName: TrackableString;
  percent: TrackableNumber;
  state?: Maybe<TrackableString>;
};

export type TaxRateCreationDataFields = {
  country: TrackableString;
  displayName: TrackableString;
  percent: TrackableNumber;
  state?: Maybe<TrackableString>;
};

export type TaxRateDeleteOutput = {
  __typename?: 'TaxRateDeleteOutput';
  taxRate: TaxRate;
};

export type TaxRateDeletedData = EventData & {
  __typename?: 'TaxRateDeletedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
};

export type TaxRateEdge = Edge & {
  __typename?: 'TaxRateEdge';
  cursor: Scalars['ID']['output'];
  node: TaxRate;
};

export type TaxRateFilterArgsCountry = {
  in?: InputMaybe<Array<CountryCode>>;
  /** This field is used for null checks and exact matching on field type */
  is?: InputMaybe<CountryCode>;
  /** This field is used for null checks and exact matching on field type */
  isNot?: InputMaybe<CountryCode>;
  notIn?: InputMaybe<Array<CountryCode>>;
};

export type TaxRatePagination = Pagination & {
  __typename?: 'TaxRatePagination';
  edges: Array<TaxRateEdge>;
  nodes: Array<TaxRate>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TaxRateUpdateInput = {
  displayName?: InputMaybe<Scalars['String']['input']>;
};

export type TaxRateUpdateOutput = {
  __typename?: 'TaxRateUpdateOutput';
  taxRate: TaxRate;
};

export type TaxRateUpdatedData = EventData & {
  __typename?: 'TaxRateUpdatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  displayName?: Maybe<TrackableString>;
};

export type TrackableApiKeyScopes = {
  __typename?: 'TrackableApiKeyScopes';
  from?: Maybe<Array<Array<ApiKeyScope>>>;
  to?: Maybe<Array<ApiKeyScope>>;
};

export type TrackableBeneficiaryRelationship = {
  __typename?: 'TrackableBeneficiaryRelationship';
  from?: Maybe<BeneficiaryRelationship>;
  to?: Maybe<BeneficiaryRelationship>;
};

export type TrackableBoolean = {
  __typename?: 'TrackableBoolean';
  from?: Maybe<Scalars['Boolean']['output']>;
  to?: Maybe<Scalars['Boolean']['output']>;
};

export type TrackableCohortMetadata = {
  __typename?: 'TrackableCohortMetadata';
  from?: Maybe<Scalars['JSONObject']['output']>;
  to?: Maybe<Scalars['JSONObject']['output']>;
};

export type TrackableCohortRefreshOptions = {
  __typename?: 'TrackableCohortRefreshOptions';
  from?: Maybe<Scalars['JSONObject']['output']>;
  to?: Maybe<Scalars['JSONObject']['output']>;
};

export type TrackableCohortTarget = {
  __typename?: 'TrackableCohortTarget';
  from?: Maybe<CohortTarget>;
  to?: Maybe<CohortTarget>;
};

export type TrackableCompanyFeesCollectionMethod = {
  __typename?: 'TrackableCompanyFeesCollectionMethod';
  from?: Maybe<CompanyFeesCollectionMethod>;
  to?: Maybe<CompanyFeesCollectionMethod>;
};

export type TrackableCompanyMembershipRole = {
  __typename?: 'TrackableCompanyMembershipRole';
  from?: Maybe<CompanyMembershipRole>;
  to?: Maybe<CompanyMembershipRole>;
};

export type TrackableDate = {
  __typename?: 'TrackableDate';
  from?: Maybe<Scalars['Date']['output']>;
  to?: Maybe<Scalars['Date']['output']>;
};

export type TrackableDateTime = {
  __typename?: 'TrackableDateTime';
  from?: Maybe<Scalars['DateTime']['output']>;
  to?: Maybe<Scalars['DateTime']['output']>;
};

export type TrackableDelta = {
  __typename?: 'TrackableDelta';
  from?: Maybe<Scalars['Delta']['output']>;
  to?: Maybe<Scalars['Delta']['output']>;
};

export type TrackableNewsletterContents = {
  __typename?: 'TrackableNewsletterContents';
  from?: Maybe<Array<Scalars['JSONObject']['output']>>;
  to?: Maybe<Array<Scalars['JSONObject']['output']>>;
};

export type TrackableNumber = {
  __typename?: 'TrackableNumber';
  from?: Maybe<Scalars['Int']['output']>;
  to?: Maybe<Scalars['Int']['output']>;
};

export type TrackableOfferStatus = {
  __typename?: 'TrackableOfferStatus';
  from?: Maybe<OfferStatus>;
  to?: Maybe<OfferStatus>;
};

export type TrackableOfferType = {
  __typename?: 'TrackableOfferType';
  from?: Maybe<OfferType>;
  to?: Maybe<OfferType>;
};

export type TrackableRecord = {
  __typename?: 'TrackableRecord';
  from?: Maybe<Scalars['JSONObject']['output']>;
  to?: Maybe<Scalars['JSONObject']['output']>;
};

export type TrackableString = {
  __typename?: 'TrackableString';
  from?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['String']['output']>;
};

export type TrackableStringArray = {
  __typename?: 'TrackableStringArray';
  from?: Maybe<Array<Scalars['String']['output']>>;
  to?: Maybe<Array<Scalars['String']['output']>>;
};

export type TrackableSubventionBrands = {
  __typename?: 'TrackableSubventionBrands';
  from?: Maybe<Array<Scalars['String']['output']>>;
  to?: Maybe<Array<Scalars['String']['output']>>;
};

export type TrackableSubventionCampaigns = {
  __typename?: 'TrackableSubventionCampaigns';
  from?: Maybe<Array<Scalars['String']['output']>>;
  to?: Maybe<Array<Scalars['String']['output']>>;
};

export type TrackableSubventionCategories = {
  __typename?: 'TrackableSubventionCategories';
  from?: Maybe<Array<Scalars['String']['output']>>;
  to?: Maybe<Array<Scalars['String']['output']>>;
};

export type TrackableSubventionGranteeType = {
  __typename?: 'TrackableSubventionGranteeType';
  from?: Maybe<Array<SubventionGranteeType>>;
  to?: Maybe<Array<SubventionGranteeType>>;
};

export type TrackableSubventionRecurrence = {
  __typename?: 'TrackableSubventionRecurrence';
  from?: Maybe<SubventionRecurrence>;
  to?: Maybe<SubventionRecurrence>;
};

export type TrackableSubventionSpendingMode = {
  __typename?: 'TrackableSubventionSpendingMode';
  from?: Maybe<Array<SubventionSpendingMode>>;
  to?: Maybe<Array<SubventionSpendingMode>>;
};

export type TrackableSubventionState = {
  __typename?: 'TrackableSubventionState';
  from?: Maybe<SubventionState>;
  to?: Maybe<SubventionState>;
};

export type TrackableSubventionType = {
  __typename?: 'TrackableSubventionType';
  from?: Maybe<SubventionType>;
  to?: Maybe<SubventionType>;
};

export type TrailableNode = {
  auditTrailPagination: AuditTrailPagination;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type TrailableNodeAuditTrailPaginationArgs = {
  filter?: InputMaybe<AuditTrailArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<AuditTrailArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type Translatable = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type Translation = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  locale: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type TranslationEdge = {
  cursor: Scalars['ID']['output'];
  node: Translation;
};

export type TranslationsPagination = {
  edges: Array<TranslationEdge>;
  nodes: Array<Translation>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TwoFactor = DeletableNode & Node & {
  __typename?: 'TwoFactor';
  activatedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  mode: TwoFactorMode;
  updatedAt: Scalars['DateTime']['output'];
};

export type TwoFactorCreateInput = {
  mode: TwoFactorMode;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type TwoFactorCreateOutput = {
  __typename?: 'TwoFactorCreateOutput';
  twoFactor: TwoFactor;
};

export type TwoFactorDeleteInput = {
  mode: TwoFactorMode;
};

export type TwoFactorEnableInput = {
  mode: TwoFactorMode;
  token: Scalars['String']['input'];
};

export enum TwoFactorMode {
  Authenticator = 'AUTHENTICATOR',
  Email = 'EMAIL',
  Sms = 'SMS'
}

export type TwoFactorSendInput = {
  email: Scalars['String']['input'];
  mode: TwoFactorMode;
};

export type TwoFactoreDeleteOutput = {
  __typename?: 'TwoFactoreDeleteOutput';
  twoFactor: TwoFactor;
};

export type TwoFactoreEnableOutput = {
  __typename?: 'TwoFactoreEnableOutput';
  twoFactor: TwoFactor;
};

export type User = DeletableNode & Node & TrailableNode & {
  __typename?: 'User';
  activeCart: Cart;
  activeSubventionConsumedAmount: Scalars['Int']['output'];
  activeSubventionTotalAmount: Scalars['Int']['output'];
  activeSubventionsCount: Scalars['Int']['output'];
  activeSubventionsWithAvailableAmount: Array<Subvention>;
  appdragId?: Maybe<Scalars['Int']['output']>;
  appdragMetas?: Maybe<UserAppdragMetas>;
  appdragOrder?: Maybe<AppdragOrder>;
  appdragOrders: Array<AppdragOrder>;
  appdragOrdersCount: Scalars['Int']['output'];
  articlesPagination: ArticlesPagination;
  auditTrailPagination: AuditTrailPagination;
  avatar?: Maybe<Picture>;
  avatarURL?: Maybe<Scalars['String']['output']>;
  bannedAt?: Maybe<Scalars['DateTime']['output']>;
  bannedBy?: Maybe<User>;
  beneficiariesPagination: BeneficiariesPagination;
  billingAddress?: Maybe<Address>;
  birthdate?: Maybe<Scalars['Date']['output']>;
  cartPagination: CartPagination;
  claimedCouponClaimablesPagination: CouponClaimablesPagination;
  company?: Maybe<Company>;
  companyMembership?: Maybe<CompanyMembership>;
  /** Filter not applicable */
  companyMembershipPagination: CompanyMembershipsPagination;
  completedSubventionsCount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  devices: Array<PushToken>;
  email: Scalars['String']['output'];
  firstLoginAt?: Maybe<Scalars['DateTime']['output']>;
  firstName: Scalars['String']['output'];
  gatheringPagination: GatheringUserPagination;
  gatheringSessionPagination: GatheringSessionUserPagination;
  happeningPagination: HappeningPagination;
  id: Scalars['ID']['output'];
  invitationsReceived: InvitationsPagination;
  isSSOEnabled: Scalars['Boolean']['output'];
  isShippingSameAsBillingAddress: Scalars['Boolean']['output'];
  isSuperCare: Scalars['Boolean']['output'];
  isTwoFactorEnabled: Scalars['Boolean']['output'];
  isViewer: Scalars['Boolean']['output'];
  kycStatus?: Maybe<UserKycStatus>;
  lastActiveAt?: Maybe<Scalars['DateTime']['output']>;
  lastLoginAt?: Maybe<Scalars['DateTime']['output']>;
  lastName: Scalars['String']['output'];
  mangopayUser?: Maybe<MangopayUser>;
  nationality?: Maybe<CountryCode>;
  notificationsPagination: InAppNotificationsPagination;
  orderPagination: OrderPagination;
  paymentLimit?: Maybe<PaymentLimit>;
  paymentLimitBalance: Scalars['Int']['output'];
  paymentLimitCeiling: Scalars['Int']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  preferences?: Maybe<UserPreferences>;
  questionsAskedPagination: QuestionsPagination;
  role: UserRole;
  shippingAddress?: Maybe<Address>;
  sorewardsUser?: Maybe<SorewardsUser>;
  status: UserStatus;
  stripePaymentIntentsPagination: StripePaymentIntentsPagination;
  subventionConsumedAmount: Scalars['Int']['output'];
  subventionPagination: UserSubventionPagination;
  subventionTotalAmount: Scalars['Int']['output'];
  subventions: Array<SubventionDomain>;
  termsAcceptedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userIntegrationProfiles: Array<UserIntegrationProfileUser>;
};


export type UserAppdragOrderArgs = {
  appdragOrderId: Scalars['ID']['input'];
};


export type UserArticlesPaginationArgs = {
  filter?: InputMaybe<ArticlesPaginationFilterArgs>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<ArticlesPaginationSorterArgs>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type UserAuditTrailPaginationArgs = {
  filter?: InputMaybe<AuditTrailArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<AuditTrailArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type UserBeneficiariesPaginationArgs = {
  filter?: InputMaybe<BeneficiariesArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<BeneficiariesArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type UserCartPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CartArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type UserClaimedCouponClaimablesPaginationArgs = {
  filter?: InputMaybe<CouponClaimablesPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CouponClaimablesPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type UserCompanyMembershipPaginationArgs = {
  filter?: InputMaybe<CompanyMembershipsArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<CompanyMembershipsArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type UserGatheringPaginationArgs = {
  filter?: InputMaybe<GatheringUserPaginationFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<Array<ConnectionSorter>>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type UserGatheringSessionPaginationArgs = {
  filter?: InputMaybe<GatheringSessionUserPaginationFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<Array<ConnectionSorter>>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type UserHappeningPaginationArgs = {
  filter?: InputMaybe<HappeningPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<HappeningPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type UserInvitationsReceivedArgs = {
  filter?: InputMaybe<InvitationsArgsFilterInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<NotificationsArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type UserNotificationsPaginationArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type UserOrderPaginationArgs = {
  filter?: InputMaybe<OrderPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<OrderPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type UserQuestionsAskedPaginationArgs = {
  filter?: InputMaybe<QuestionsPaginationFilters>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type UserStripePaymentIntentsPaginationArgs = {
  filter?: InputMaybe<StripePaymentIntentsPaginationArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<StripePaymentIntentsPaginationArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type UserSubventionPaginationArgs = {
  filter?: InputMaybe<UserSubventionArgsFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<UserSubventionArgsSorter>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type UserSubventionsArgs = {
  filter?: InputMaybe<UserSubventionFilter>;
  sorter?: InputMaybe<Array<UserSubventionSorter>>;
};


export type UserUserIntegrationProfilesArgs = {
  companyId: Scalars['ID']['input'];
};

export type UserAppdragMetas = {
  __typename?: 'UserAppdragMetas';
  KYCIdentityProofDocument?: Maybe<Scalars['String']['output']>;
  autre?: Maybe<Scalars['String']['output']>;
  autre2?: Maybe<Scalars['String']['output']>;
  autre3?: Maybe<Scalars['String']['output']>;
  autre4?: Maybe<Scalars['String']['output']>;
  autre5?: Maybe<Scalars['String']['output']>;
  autre6?: Maybe<Scalars['String']['output']>;
  bypassSSO?: Maybe<Scalars['Boolean']['output']>;
  iban?: Maybe<Scalars['String']['output']>;
  ibanNom?: Maybe<Scalars['String']['output']>;
  lastConnection?: Maybe<Scalars['DateTime']['output']>;
  mangopayAccountID?: Maybe<Scalars['String']['output']>;
  mangopayKycID?: Maybe<Scalars['String']['output']>;
  mangopayKycStatus?: Maybe<Scalars['String']['output']>;
  mangopayUserID?: Maybe<Scalars['String']['output']>;
  migratedAt?: Maybe<Scalars['DateTime']['output']>;
  statut?: Maybe<Scalars['String']['output']>;
  statutMarital?: Maybe<Scalars['String']['output']>;
  trancheQuotient?: Maybe<Scalars['String']['output']>;
  trancheQuotientVerified?: Maybe<Scalars['Boolean']['output']>;
  walletId?: Maybe<Scalars['String']['output']>;
};

export type UserBannedData = EventData & {
  __typename?: 'UserBannedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  bannedAt: Scalars['DateTime']['output'];
  previousStatus: UserStatus;
};

export type UserCreateInput = {
  avatarId?: InputMaybe<Scalars['ID']['input']>;
  billingAddress?: InputMaybe<AddressCreateInput>;
  birthdate?: InputMaybe<Scalars['Date']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  isShippingSameAsBillingAddress?: InputMaybe<Scalars['Boolean']['input']>;
  lastName: Scalars['String']['input'];
  /** A string composed of two-letter country codes, compliant with ISO 3166 standard */
  nationality?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<UserRole>;
  shippingAddress?: InputMaybe<AddressCreateInput>;
};

export type UserCreateOutput = {
  __typename?: 'UserCreateOutput';
  user: User;
};

export type UserCreatedData = EventData & {
  __typename?: 'UserCreatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  email?: Maybe<TrackableString>;
};

export type UserDeleteOutput = {
  __typename?: 'UserDeleteOutput';
  user: User;
};

export type UserDiscountSubventionEdge = Edge & {
  __typename?: 'UserDiscountSubventionEdge';
  /** Amount consumed on subvention for user */
  amountConsumed: Scalars['Int']['output'];
  /** Amount total on subvention for user */
  amountTotal: Scalars['Int']['output'];
  cursor: Scalars['ID']['output'];
  node: SubventionDiscount;
  /** Quantity consumed on subvention for user */
  quantityConsumed?: Maybe<Scalars['Int']['output']>;
  /** Quantity total on subvention for user */
  quantityTotal?: Maybe<Scalars['Int']['output']>;
};

export type UserDiscountSubventionPagination = Pagination & {
  __typename?: 'UserDiscountSubventionPagination';
  edges: Array<UserDiscountSubventionEdge>;
  nodes: Array<SubventionDiscount>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserEdge = Edge & {
  __typename?: 'UserEdge';
  cursor: Scalars['ID']['output'];
  node: User;
};

export type UserIntegrationCompanyConfig = FeatureConfig & {
  __typename?: 'UserIntegrationCompanyConfig';
  connector?: Maybe<UserIntegrationConnector>;
  connectorsPagination: UserIntegrationConnectorPagination;
  createdAt: Scalars['DateTime']['output'];
  /** This id matches the company ID */
  id: Scalars['ID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  providerConfig?: Maybe<UserIntegrationProviderConfig>;
  providersConfig: Array<UserIntegrationProviderConfig>;
  updatedAt: Scalars['DateTime']['output'];
};


export type UserIntegrationCompanyConfigConnectorArgs = {
  connectorId: Scalars['ID']['input'];
};


export type UserIntegrationCompanyConfigConnectorsPaginationArgs = {
  filter?: InputMaybe<UserIntegrationConnectorPaginationFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<Array<UserIntegrationConnectorPaginationSorter>>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type UserIntegrationCompanyConfigProviderConfigArgs = {
  providerName: UserIntegrationProviderName;
};

export type UserIntegrationConnector = Node & {
  __typename?: 'UserIntegrationConnector';
  createdAt: Scalars['DateTime']['output'];
  credentials: UserIntegrationConnectorCredentials;
  id: Scalars['ID']['output'];
  nextSynchronisationDate?: Maybe<Scalars['DateTime']['output']>;
  providerName: UserIntegrationProviderName;
  synchronisation?: Maybe<UserIntegrationSynchronisation>;
  synchronisationFrequency?: Maybe<Scalars['String']['output']>;
  synchronisationsPagination: UserIntegrationSynchronisationPagination;
  updatedAt: Scalars['DateTime']['output'];
};


export type UserIntegrationConnectorSynchronisationArgs = {
  synchronisationId: Scalars['ID']['input'];
};


export type UserIntegrationConnectorSynchronisationsPaginationArgs = {
  filter?: InputMaybe<UserIntegrationSynchronisationPaginationFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<Array<UserIntegrationSynchronisationPaginationSorter>>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type UserIntegrationConnectorAddOutput = {
  connector: UserIntegrationConnector;
};

export type UserIntegrationConnectorCredentials = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type UserIntegrationConnectorDeleteOutput = {
  __typename?: 'UserIntegrationConnectorDeleteOutput';
  connector: UserIntegrationConnector;
};

export type UserIntegrationConnectorEdge = Edge & {
  __typename?: 'UserIntegrationConnectorEdge';
  cursor: Scalars['ID']['output'];
  node: UserIntegrationConnector;
};

export type UserIntegrationConnectorPagination = Connection & {
  __typename?: 'UserIntegrationConnectorPagination';
  edges: Array<UserIntegrationConnectorEdge>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserIntegrationConnectorPaginationFilter = {
  providerName?: InputMaybe<Array<UserIntegrationProviderName>>;
};

export type UserIntegrationConnectorPaginationSorter = {
  direction: SortDirection;
  field: UserIntegrationConnectorPaginationSorterField;
};

export enum UserIntegrationConnectorPaginationSorterField {
  CreatedAt = 'createdAt'
}

export type UserIntegrationConnectorSetFrequencyInput = {
  frequency?: InputMaybe<Scalars['String']['input']>;
};

export type UserIntegrationConnectorSetFrequencyOutput = {
  __typename?: 'UserIntegrationConnectorSetFrequencyOutput';
  connector: UserIntegrationConnector;
};

export type UserIntegrationConnectorUpdateOutput = {
  connector: UserIntegrationConnector;
};

export type UserIntegrationFeatureDisableOutput = {
  __typename?: 'UserIntegrationFeatureDisableOutput';
  userIntegrationCompanyConfig: UserIntegrationCompanyConfig;
};

export type UserIntegrationFeatureEnableOutput = {
  __typename?: 'UserIntegrationFeatureEnableOutput';
  userIntegrationCompanyConfig: UserIntegrationCompanyConfig;
};

export type UserIntegrationLuccaCredentials = Node & UserIntegrationConnectorCredentials & {
  __typename?: 'UserIntegrationLuccaCredentials';
  /** @deprecated Do not use, encrypted data */
  apiKey: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  domain: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type UserIntegrationLuccaCredentialsAddInput = {
  apiKey: Scalars['String']['input'];
  domain: Scalars['String']['input'];
};

export type UserIntegrationLuccaCredentialsAddOutput = UserIntegrationConnectorAddOutput & {
  __typename?: 'UserIntegrationLuccaCredentialsAddOutput';
  connector: UserIntegrationConnector;
};

export type UserIntegrationLuccaCredentialsUpdateInput = {
  apiKey: Scalars['String']['input'];
  domain: Scalars['String']['input'];
};

export type UserIntegrationLuccaCredentialsUpdateOutput = UserIntegrationConnectorUpdateOutput & {
  __typename?: 'UserIntegrationLuccaCredentialsUpdateOutput';
  connector: UserIntegrationConnector;
};

export type UserIntegrationProfileBeneficiary = Node & {
  __typename?: 'UserIntegrationProfileBeneficiary';
  beneficiary: Beneficiary;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  providerId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type UserIntegrationProfileBeneficiaryEdge = Edge & {
  __typename?: 'UserIntegrationProfileBeneficiaryEdge';
  cursor: Scalars['ID']['output'];
  node: UserIntegrationProfileBeneficiary;
};

export type UserIntegrationProfileBeneficiaryPagination = Connection & {
  __typename?: 'UserIntegrationProfileBeneficiaryPagination';
  edges: Array<UserIntegrationProfileBeneficiaryEdge>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserIntegrationProfileBeneficiaryPaginationFilter = {
  actions?: InputMaybe<Array<UserIntegrationSynchronisationProfileBeneficiaryAction>>;
};

export type UserIntegrationProfileBeneficiaryPaginationSorter = {
  direction: SortDirection;
  field: UserIntegrationProfileBeneficiaryPaginationSorterField;
};

export enum UserIntegrationProfileBeneficiaryPaginationSorterField {
  FirstName = 'firstName',
  LastName = 'lastName'
}

export type UserIntegrationProfileUser = Node & {
  __typename?: 'UserIntegrationProfileUser';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  providerEmail: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  providerName: UserIntegrationProviderName;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

export type UserIntegrationProfileUserEdge = Edge & {
  __typename?: 'UserIntegrationProfileUserEdge';
  action: UserIntegrationSynchronisationProfileUserAction;
  cursor: Scalars['ID']['output'];
  node: UserIntegrationProfileUser;
};

export type UserIntegrationProfileUserPagination = Connection & {
  __typename?: 'UserIntegrationProfileUserPagination';
  edges: Array<UserIntegrationProfileUserEdge>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserIntegrationProfileUserPaginationFilter = {
  actions?: InputMaybe<Array<UserIntegrationSynchronisationProfileUserAction>>;
  user?: InputMaybe<UserIntegrationProfileUserPaginationFilterUser>;
};

export type UserIntegrationProfileUserPaginationFilterUser = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type UserIntegrationProfileUserPaginationSorter = {
  direction: SortDirection;
  field: UserIntegrationProfileUserPaginationSorterField;
};

export enum UserIntegrationProfileUserPaginationSorterField {
  Action = 'action',
  Email = 'email',
  FirstName = 'firstName',
  LastName = 'lastName'
}

export type UserIntegrationProviderConfig = Node & {
  __typename?: 'UserIntegrationProviderConfig';
  /** Indicates if a connector can be added to this provider config based on the maxConnectorCount field */
  canAddConnector: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  maxConnectorCount: Scalars['Int']['output'];
  providerName: UserIntegrationProviderName;
  updatedAt: Scalars['DateTime']['output'];
};

export type UserIntegrationProviderDisableInput = {
  providerName: UserIntegrationProviderName;
};

export type UserIntegrationProviderDisableOutput = {
  __typename?: 'UserIntegrationProviderDisableOutput';
  userIntegrationProviderConfig: UserIntegrationProviderConfig;
};

export type UserIntegrationProviderEnableInput = {
  providerName: UserIntegrationProviderName;
};

export type UserIntegrationProviderEnableOutput = {
  __typename?: 'UserIntegrationProviderEnableOutput';
  userIntegrationProviderConfig: UserIntegrationProviderConfig;
};

export enum UserIntegrationProviderName {
  Lucca = 'LUCCA',
  SalesforceRcu = 'SALESFORCE_RCU'
}

export type UserIntegrationProviderUpdateInput = {
  maxConnectorCount?: InputMaybe<Scalars['Int']['input']>;
};

export type UserIntegrationProviderUpdateOutput = {
  __typename?: 'UserIntegrationProviderUpdateOutput';
  userIntegrationProviderConfig: UserIntegrationProviderConfig;
};

export type UserIntegrationSalesforceRcuCredentials = Node & UserIntegrationConnectorCredentials & {
  __typename?: 'UserIntegrationSalesforceRcuCredentials';
  clientId: Scalars['String']['output'];
  /** @deprecated Do not use, encrypted data */
  clientSecret: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  login: Scalars['String']['output'];
  /** @deprecated Do not use, encrypted data */
  password: Scalars['String']['output'];
  rootUrl: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type UserIntegrationSalesforceRcuCredentialsAddInput = {
  clientId: Scalars['String']['input'];
  clientSecret: Scalars['String']['input'];
  login: Scalars['String']['input'];
  password: Scalars['String']['input'];
  rootUrl: Scalars['String']['input'];
};

export type UserIntegrationSalesforceRcuCredentialsAddOutput = UserIntegrationConnectorAddOutput & {
  __typename?: 'UserIntegrationSalesforceRcuCredentialsAddOutput';
  connector: UserIntegrationConnector;
};

export type UserIntegrationSalesforceRcuCredentialsUpdateInput = {
  clientId: Scalars['String']['input'];
  clientSecret: Scalars['String']['input'];
  login: Scalars['String']['input'];
  password: Scalars['String']['input'];
  rootUrl: Scalars['String']['input'];
};

export type UserIntegrationSalesforceRcuCredentialsUpdateOutput = UserIntegrationConnectorUpdateOutput & {
  __typename?: 'UserIntegrationSalesforceRcuCredentialsUpdateOutput';
  connector: UserIntegrationConnector;
};

export type UserIntegrationSynchronisation = Node & {
  __typename?: 'UserIntegrationSynchronisation';
  createdAt: Scalars['DateTime']['output'];
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  failureCode?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  profileBeneficiariesPagination: UserIntegrationProfileBeneficiaryPagination;
  profileUsersPagination: UserIntegrationProfileUserPagination;
  /** @deprecated Use profileUsersPagination instead */
  profilesPagination: UserIntegrationProfileUserPagination;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  status: UserIntegrationSynchronisationStatus;
  updatedAt: Scalars['DateTime']['output'];
};


export type UserIntegrationSynchronisationProfileBeneficiariesPaginationArgs = {
  filter?: InputMaybe<UserIntegrationProfileBeneficiaryPaginationFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<Array<UserIntegrationProfileBeneficiaryPaginationSorter>>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type UserIntegrationSynchronisationProfileUsersPaginationArgs = {
  filter?: InputMaybe<UserIntegrationProfileUserPaginationFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<Array<UserIntegrationProfileUserPaginationSorter>>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type UserIntegrationSynchronisationProfilesPaginationArgs = {
  filter?: InputMaybe<UserIntegrationProfileUserPaginationFilter>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sorter?: InputMaybe<Array<UserIntegrationProfileUserPaginationSorter>>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type UserIntegrationSynchronisationAbortOutput = {
  __typename?: 'UserIntegrationSynchronisationAbortOutput';
  synchronisation: UserIntegrationSynchronisation;
};

export type UserIntegrationSynchronisationCreateOutput = {
  __typename?: 'UserIntegrationSynchronisationCreateOutput';
  synchronisation: UserIntegrationSynchronisation;
};

export type UserIntegrationSynchronisationEdge = Edge & {
  __typename?: 'UserIntegrationSynchronisationEdge';
  cursor: Scalars['ID']['output'];
  node: UserIntegrationSynchronisation;
};

export type UserIntegrationSynchronisationPagination = Connection & {
  __typename?: 'UserIntegrationSynchronisationPagination';
  edges: Array<UserIntegrationSynchronisationEdge>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserIntegrationSynchronisationPaginationFilter = {
  status?: InputMaybe<Array<UserIntegrationSynchronisationStatus>>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type UserIntegrationSynchronisationPaginationSorter = {
  direction: SortDirection;
  field: UserIntegrationSynchronisationPaginationSorterField;
};

export enum UserIntegrationSynchronisationPaginationSorterField {
  CreatedAt = 'createdAt'
}

export enum UserIntegrationSynchronisationProfileBeneficiaryAction {
  Created = 'CREATED',
  Deleted = 'DELETED',
  Updated = 'UPDATED'
}

export enum UserIntegrationSynchronisationProfileUserAction {
  Associated = 'ASSOCIATED',
  Created = 'CREATED',
  Deleted = 'DELETED',
  Failed = 'FAILED',
  Skipped = 'SKIPPED',
  Updated = 'UPDATED'
}

export enum UserIntegrationSynchronisationStatus {
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Waiting = 'WAITING'
}

export enum UserKycStatus {
  Invalid = 'INVALID',
  None = 'NONE',
  Pending = 'PENDING',
  Repealed = 'REPEALED',
  Unknown = 'UNKNOWN',
  Validated = 'VALIDATED'
}

export type UserPaginationFilter = {
  email?: InputMaybe<CoreFilterString>;
  firstName?: InputMaybe<CoreFilterString>;
  lastName?: InputMaybe<CoreFilterString>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type UserPreferences = Node & {
  __typename?: 'UserPreferences';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  properties: UserPreferencesProperties;
  updatedAt: Scalars['DateTime']['output'];
};

export type UserPreferencesArgsFilter = {
  customFields?: InputMaybe<UserPreferencesArgsFilterCustomFields>;
};

export type UserPreferencesArgsFilterCustomFields = {
  newsletters?: InputMaybe<UserPreferencesArgsFilterNewsletters>;
};

export type UserPreferencesArgsFilterNewsletters = {
  company?: InputMaybe<FilterBoolArgs>;
  global?: InputMaybe<FilterBoolArgs>;
};

export type UserPreferencesProperties = {
  __typename?: 'UserPreferencesProperties';
  language?: Maybe<Scalars['String']['output']>;
  newsletters?: Maybe<UserPreferencesPropertiesNewsletters>;
  pushNotifications?: Maybe<UserPreferencesPropertiesPushNotifications>;
};

export type UserPreferencesPropertiesNewsletters = {
  __typename?: 'UserPreferencesPropertiesNewsletters';
  company?: Maybe<Scalars['Boolean']['output']>;
  global?: Maybe<Scalars['Boolean']['output']>;
  partner?: Maybe<Scalars['Boolean']['output']>;
};

export type UserPreferencesPropertiesPushNotifications = {
  __typename?: 'UserPreferencesPropertiesPushNotifications';
  global?: Maybe<Scalars['Boolean']['output']>;
};

export type UserPreferencesUpdateInput = {
  language?: InputMaybe<Scalars['String']['input']>;
  newsletters?: InputMaybe<UserPreferencesUpdateInputNewsletters>;
  pushNotifications?: InputMaybe<UserPreferencesUpdateInputPushNotifications>;
};

export type UserPreferencesUpdateInputNewsletters = {
  company?: InputMaybe<Scalars['Boolean']['input']>;
  global?: InputMaybe<Scalars['Boolean']['input']>;
  partner?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserPreferencesUpdateInputPushNotifications = {
  global?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserPreferencesUpdateOutput = {
  __typename?: 'UserPreferencesUpdateOutput';
  user: User;
  userPreferences: UserPreferences;
};

/** User role */
export enum UserRole {
  Superadmin = 'SUPERADMIN',
  User = 'USER'
}

/** User status */
export enum UserStatus {
  Activated = 'ACTIVATED',
  Archived = 'ARCHIVED',
  Banned = 'BANNED',
  Created = 'CREATED',
  Invited = 'INVITED'
}

export type UserSubventionArgsFilter = {
  AND?: InputMaybe<Array<SubventionArgsFilter>>;
  OR?: InputMaybe<Array<SubventionArgsFilter>>;
  company?: InputMaybe<CompaniesArgsFilter>;
  endsAt?: InputMaybe<FilterDateArgs>;
  id?: InputMaybe<FilterIdArgs>;
  name?: InputMaybe<FilterStringArgs>;
  spendingMode?: InputMaybe<SubventionSpendingMode>;
  startsAt?: InputMaybe<FilterDateArgs>;
  state?: InputMaybe<SubventionArgsFilterState>;
  type?: InputMaybe<SubventionArgsFilterType>;
};

export type UserSubventionArgsSorter = {
  amountByGrantee?: InputMaybe<SortDirection>;
  amountRemaining?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  endsAt?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  startsAt?: InputMaybe<SortDirection>;
  state?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type UserSubventionEdge = Edge & {
  __typename?: 'UserSubventionEdge';
  /** Amount consumed on subvention for user */
  amountConsumed: Scalars['Int']['output'];
  /** Amount total on subvention for user */
  amountTotal: Scalars['Int']['output'];
  cursor: Scalars['ID']['output'];
  node: Subvention;
  /** Quantity consumed on subvention for user */
  quantityConsumed?: Maybe<Scalars['Int']['output']>;
  /** Quantity total on subvention for user */
  quantityTotal?: Maybe<Scalars['Int']['output']>;
};

export type UserSubventionFilter = {
  states?: InputMaybe<Array<SubventionState>>;
};

export type UserSubventionPagination = Pagination & {
  __typename?: 'UserSubventionPagination';
  edges: Array<UserSubventionEdge>;
  nodes: Array<Subvention>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserSubventionSorter = {
  direction: SortDirection;
  field: UserSubventionSorterField;
};

export enum UserSubventionSorterField {
  AmountRemaining = 'amountRemaining',
  EndsAt = 'endsAt'
}

export type UserTwoFactor = Node & {
  __typename?: 'UserTwoFactor';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type UserUnbannedData = EventData & {
  __typename?: 'UserUnbannedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  unbannedAt: Scalars['DateTime']['output'];
};

export type UserUpdateInput = {
  avatarId?: InputMaybe<Scalars['ID']['input']>;
  billingAddress?: InputMaybe<AddressCreateInput>;
  birthdate?: InputMaybe<Scalars['Date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  isShippingSameAsBillingAddress?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** A string composed of two-letter country codes, compliant with ISO 3166 standard */
  nationality?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<UserRole>;
  shippingAddress?: InputMaybe<AddressCreateInput>;
};

export type UserUpdateOutput = {
  __typename?: 'UserUpdateOutput';
  user: User;
};

export type UserUpdatedData = EventData & {
  __typename?: 'UserUpdatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  email?: Maybe<TrackableString>;
};

export type UsersArgsFilter = {
  AND?: InputMaybe<Array<UsersArgsFilter>>;
  OR?: InputMaybe<Array<UsersArgsFilter>>;
  beneficiaries?: InputMaybe<BeneficiariesRelationFilter>;
  beneficiary?: InputMaybe<BeneficiaryTypeFilter>;
  birthdate?: InputMaybe<FilterDateOnlyArgs>;
  companyMembership?: InputMaybe<CompanyMembershipsArgsFilter>;
  createdAt?: InputMaybe<FilterDateArgs>;
  email?: InputMaybe<FilterStringArgs>;
  firstName?: InputMaybe<FilterStringArgs>;
  fullName?: InputMaybe<UsersArgsFilterFullName>;
  id?: InputMaybe<FilterIdArgs>;
  lastActiveAt?: InputMaybe<FilterDateArgs>;
  lastLoginAt?: InputMaybe<FilterDateArgs>;
  lastName?: InputMaybe<FilterStringArgs>;
  nationality?: InputMaybe<FilterNationalityArgs>;
  orders?: InputMaybe<UsersArgsFilterOrders>;
  preferences?: InputMaybe<UserPreferencesArgsFilter>;
  role?: InputMaybe<FilterRoleArgs>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<FilterStatusArgs>;
};

export type UsersArgsFilterFullName = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type UsersArgsFilterMinMaxNumber = {
  max?: InputMaybe<Scalars['String']['input']>;
  min?: InputMaybe<Scalars['String']['input']>;
};

export type UsersArgsFilterOrders = {
  count?: InputMaybe<UsersArgsFilterMinMaxNumber>;
};

export type UsersArgsSorter = {
  company?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  email?: InputMaybe<SortDirection>;
  firstName?: InputMaybe<SortDirection>;
  fullName?: InputMaybe<SortDirection>;
  lastActiveAt?: InputMaybe<SortDirection>;
  lastLoginAt?: InputMaybe<SortDirection>;
  lastName?: InputMaybe<SortDirection>;
  nationality?: InputMaybe<SortDirection>;
  orderCount?: InputMaybe<SortDirection>;
  role?: InputMaybe<SortDirection>;
  status?: InputMaybe<SortDirection>;
  updatedAt?: InputMaybe<SortDirection>;
};

export type UsersPagination = Pagination & {
  __typename?: 'UsersPagination';
  edges: Array<UserEdge>;
  nodes: Array<User>;
  pageInfo: PaginationPageInfo;
  totalCount: Scalars['Int']['output'];
};

export type Voucher = {
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type VoucherAmountCurrencyUpdate = DeletableNode & Node & Voucher & {
  __typename?: 'VoucherAmountCurrencyUpdate';
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['Currency']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  nextAmount: Scalars['Int']['output'];
  previousAmount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type VoucherCreateInput = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  reward?: InputMaybe<Scalars['String']['input']>;
  type: VoucherType;
};

export type VoucherCreateOutput = {
  __typename?: 'VoucherCreateOutput';
  voucher: VoucherUnion;
};

export type VoucherCreatedData = EventData & {
  __typename?: 'VoucherCreatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  amount?: Maybe<TrackableNumber>;
  currency?: Maybe<TrackableString>;
  quantity?: Maybe<TrackableNumber>;
  reward?: Maybe<TrackableString>;
  type: VoucherType;
};

export type VoucherDeleteOutput = {
  __typename?: 'VoucherDeleteOutput';
  voucher: Voucher;
};

export type VoucherDeletedData = EventData & {
  __typename?: 'VoucherDeletedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
};

export type VoucherFlatDiscount = DeletableNode & Node & Voucher & {
  __typename?: 'VoucherFlatDiscount';
  amount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['Currency']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type VoucherFlatDiscountUpdateInput = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
};

export type VoucherFlatDiscountUpdateOutput = {
  __typename?: 'VoucherFlatDiscountUpdateOutput';
  voucher: VoucherFlatDiscount;
};

export type VoucherFlatDiscountUpdatedData = EventData & {
  __typename?: 'VoucherFlatDiscountUpdatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  amount?: Maybe<TrackableNumber>;
  currency?: Maybe<TrackableString>;
};

export type VoucherPercentDiscount = DeletableNode & Node & Voucher & {
  __typename?: 'VoucherPercentDiscount';
  amount: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type VoucherPercentDiscountUpdateInput = {
  amount?: InputMaybe<Scalars['Int']['input']>;
};

export type VoucherPercentDiscountUpdateOutput = {
  __typename?: 'VoucherPercentDiscountUpdateOutput';
  voucher: VoucherPercentDiscount;
};

export type VoucherPercentDiscountUpdatedData = EventData & {
  __typename?: 'VoucherPercentDiscountUpdatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  amount?: Maybe<TrackableNumber>;
};

export type VoucherReward = DeletableNode & Node & Voucher & {
  __typename?: 'VoucherReward';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  quantity: Scalars['Int']['output'];
  reward: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type VoucherRewardUpdateInput = {
  quantity?: InputMaybe<Scalars['Int']['input']>;
  reward?: InputMaybe<Scalars['String']['input']>;
};

export type VoucherRewardUpdateOutput = {
  __typename?: 'VoucherRewardUpdateOutput';
  voucher: VoucherReward;
};

export type VoucherRewardUpdatedData = EventData & {
  __typename?: 'VoucherRewardUpdatedData';
  /** Underscore is used to avoid conflicts */
  _type: Scalars['String']['output'];
  quantity?: Maybe<TrackableNumber>;
  reward?: Maybe<TrackableString>;
};

export enum VoucherType {
  VoucherFlatDiscount = 'VoucherFlatDiscount',
  VoucherPercentDiscount = 'VoucherPercentDiscount',
  VoucherReward = 'VoucherReward'
}

export type VoucherUnion = VoucherFlatDiscount | VoucherPercentDiscount | VoucherReward;

/** An object that can be authored */
export type WithAuthor = {
  author?: Maybe<User>;
};

export type WithCover = {
  cover?: Maybe<Picture>;
};

export type WithFile = {
  file: File;
};

export type WithFiles = {
  files: Array<File>;
};

export type WithLogo = {
  logo?: Maybe<Picture>;
};

export type WithPicture = {
  picture?: Maybe<Picture>;
};

export type WithThumbnail = {
  thumbnail?: Maybe<Picture>;
};

export const GQLOperations = {
  Query: {
    CartLineItemInventory: 'CartLineItemInventory',
    CompanyDocumentUpdateInfo: 'CompanyDocumentUpdateInfo',
    CompanyFolderUpdateInfo: 'CompanyFolderUpdateInfo',
    CompanyReimbursementDetail: 'CompanyReimbursementDetail',
    CompanySubventionInvoices: 'CompanySubventionInvoices',
    MobileNotificationButton: 'MobileNotificationButton',
    PublicationShare: 'PublicationShare',
    GetSubventionReimbProofs: 'GetSubventionReimbProofs',
    ReimbursementDetailStatus: 'ReimbursementDetailStatus',
    ReimbursementDetailViewerStatus: 'ReimbursementDetailViewerStatus',
    ReimbursementsPageHeader: 'ReimbursementsPageHeader',
    SubventionDetailGrantees: 'SubventionDetailGrantees',
    SubventionDetailInfoCard: 'SubventionDetailInfoCard',
    SubventionReimbursementsList: 'SubventionReimbursementsList',
    SubventionWalletBankingAlias: 'SubventionWalletBankingAlias',
    SubventionWalletNoBankingAlias: 'SubventionWalletNoBankingAlias',
    SubventionWalletTransfersListCompany: 'SubventionWalletTransfersListCompany',
    UserDataFormModalDefaults: 'UserDataFormModalDefaults',
    UserOrderAppdragItemOrder: 'UserOrderAppdragItemOrder',
    UserPaymentsStatus: 'UserPaymentsStatus',
    CompanyMembersPreview: 'CompanyMembersPreview',
    RaisedErrorCustomFields: 'RaisedErrorCustomFields',
    UserBeneficiaries: 'UserBeneficiaries',
    CompanyMembershipCustomFields: 'CompanyMembershipCustomFields',
    UserPage: 'UserPage',
    UserAdvantageDetail: 'UserAdvantageDetail',
    AdvantageDetailReimbursement: 'AdvantageDetailReimbursement',
    AdvantageReimbursementFunnel: 'AdvantageReimbursementFunnel',
    RefundAdvantageStep: 'RefundAdvantageStep',
    UserReimbursementCapacity: 'UserReimbursementCapacity',
    ArticlesList: 'ArticlesList',
    BeneficiariesVerificationList: 'BeneficiariesVerificationList',
    BeneficiariesVerificationManagement: 'BeneficiariesVerificationManagement',
    CampaignOffers: 'CampaignOffers',
    ViewerCartButton: 'ViewerCartButton',
    CategoriesPagination: 'CategoriesPagination',
    CompanyProfileInfo: 'CompanyProfileInfo',
    CompanySubventionsTabs: 'CompanySubventionsTabs',
    CompanyContactList: 'CompanyContactList',
    CoverLibrarySelectPictureStock: 'CoverLibrarySelectPictureStock',
    DashboardClicks: 'DashboardClicks',
    DashboardExpenses: 'DashboardExpenses',
    DashboardClicksDetails: 'DashboardClicksDetails',
    DashboardExpensesDetails: 'DashboardExpensesDetails',
    DashboardGeneralTab: 'DashboardGeneralTab',
    DashboardSubventionBudgetByCategory: 'DashboardSubventionBudgetByCategory',
    DashboardSubventionUseRate: 'DashboardSubventionUseRate',
    DashboardTopBrands: 'DashboardTopBrands',
    DiscoverCampaignSelections: 'DiscoverCampaignSelections',
    DiscoverCampaignsByCategoryCategoriesPagination: 'DiscoverCampaignsByCategoryCategoriesPagination',
    FolderView: 'FolderView',
    FolderViewFolders: 'FolderViewFolders',
    FolderViewFiles: 'FolderViewFiles',
    FolderViewHighlights: 'FolderViewHighlights',
    FolderViewDocumentsMostRecent: 'FolderViewDocumentsMostRecent',
    ViewerNotifications: 'ViewerNotifications',
    VariantDynamicPrice: 'VariantDynamicPrice',
    ProfileAvatar: 'ProfileAvatar',
    PublicationList: 'PublicationList',
    CompanyQuestionCategoryList: 'CompanyQuestionCategoryList',
    reimbursementDetail: 'reimbursementDetail',
    SelectionCampaignsList: 'SelectionCampaignsList',
    SubCategoriesList: 'SubCategoriesList',
    SubventionDetail: 'SubventionDetail',
    SubventionFunnel: 'SubventionFunnel',
    SubventionSchemas: 'SubventionSchemas',
    SubventionsList: 'SubventionsList',
    UserAdvantagesRefunds: 'UserAdvantagesRefunds',
    UserBankAccount: 'UserBankAccount',
    userIdConfirmation: 'userIdConfirmation',
    userKycErrors: 'userKycErrors',
    UserPendingReimbursementsList: 'UserPendingReimbursementsList',
    UserReimbursementsList: 'UserReimbursementsList',
    UserSettings: 'UserSettings',
    UsersCustomsFieldsAdditionalFieldsTab: 'UsersCustomsFieldsAdditionalFieldsTab',
    UsersInviteModalPagination: 'UsersInviteModalPagination',
    UsersWithPendingBeneficiaries: 'UsersWithPendingBeneficiaries',
    UsersList: 'UsersList',
    UsersManagement: 'UsersManagement',
    ContractsList: 'ContractsList',
    WorksitesList: 'WorksitesList',
    ArticlesCategoriesSelect: 'ArticlesCategoriesSelect',
    CampaignsAsyncSelect: 'CampaignsAsyncSelect',
    CompanyDateSelect: 'CompanyDateSelect',
    CompanyMetricsDateSelect: 'CompanyMetricsDateSelect',
    AccountTemplate: 'AccountTemplate',
    CatalogCampaignCarousel: 'CatalogCampaignCarousel',
    CatalogCategoriesCarousel: 'CatalogCategoriesCarousel',
    CatalogDiscoverCampaignCarousel: 'CatalogDiscoverCampaignCarousel',
    CohortManualSelector: 'CohortManualSelector',
    CohortWarningBanner: 'CohortWarningBanner',
    CohortUserPreview: 'CohortUserPreview',
    CohortUserCriteriaContract: 'CohortUserCriteriaContract',
    CohortUserCriteriaCustomFields: 'CohortUserCriteriaCustomFields',
    CohortUserCriteriaJobTitle: 'CohortUserCriteriaJobTitle',
    CohortUserCriteriaSubsidiary: 'CohortUserCriteriaSubsidiary',
    CohortUserCriteriaWorksite: 'CohortUserCriteriaWorksite',
    ArticleCategoriesCarousel: 'ArticleCategoriesCarousel',
    ArticlesCarousel: 'ArticlesCarousel',
    NewsletterContentCardArticle: 'NewsletterContentCardArticle',
    NewsletterContentCardDocument: 'NewsletterContentCardDocument',
    NewsletterContentCardPublication: 'NewsletterContentCardPublication',
    NewsletterContentCardSubvention: 'NewsletterContentCardSubvention',
    NewsletterTable: 'NewsletterTable',
    SelectArticleCategory: 'SelectArticleCategory',
    SpotlightCarousel: 'SpotlightCarousel',
    NewsletterFormContentSelectorModalSubventions: 'NewsletterFormContentSelectorModalSubventions',
    NewsletterFormContentSelectorModalPublications: 'NewsletterFormContentSelectorModalPublications',
    NewsletterFormContentSelectorModalCompanyDocuments: 'NewsletterFormContentSelectorModalCompanyDocuments',
    NewsletterFormContentSelectorModalArticle: 'NewsletterFormContentSelectorModalArticle',
    DownloadCompanyDocument: 'DownloadCompanyDocument',
    ArticleUpdateViewArticle: 'ArticleUpdateViewArticle',
    PublicationUpdateViewQuery: 'PublicationUpdateViewQuery',
    SuggestionsCombobox: 'SuggestionsCombobox',
    PictureSelectStock: 'PictureSelectStock',
    FavoritePage: 'FavoritePage',
    formatGatheringAccess: 'formatGatheringAccess',
    GatheringAdminCurrentEventsView: 'GatheringAdminCurrentEventsView',
    GatheringAdminParticipationsTable: 'GatheringAdminParticipationsTable',
    GatheringAdminEventUpdateAccessView: 'GatheringAdminEventUpdateAccessView',
    GatheringAdminEventUpdateInformationsView: 'GatheringAdminEventUpdateInformationsView',
    GatheringAdminEventUpdateSessionUpdateView: 'GatheringAdminEventUpdateSessionUpdateView',
    GatheringAdminHistoryEventsView: 'GatheringAdminHistoryEventsView',
    GatheringCartView: 'GatheringCartView',
    GatheringDashboardView: 'GatheringDashboardView',
    GatheringDashboardViewUserSessions: 'GatheringDashboardViewUserSessions',
    GatheringEventIdView: 'GatheringEventIdView',
    HomeView: 'HomeView',
    HomeViewContacts: 'HomeViewContacts',
    HomeViewDrive: 'HomeViewDrive',
    HomeViewSpotlightBlockReorderModal: 'HomeViewSpotlightBlockReorderModal',
    RemainingSubventionsButton: 'RemainingSubventionsButton',
    UserSubventionCardCarousel: 'UserSubventionCardCarousel',
    SuperAdminCampaignPreviewView: 'SuperAdminCampaignPreviewView',
    SurveyCreateView: 'SurveyCreateView',
    BeneficiaryCreator: 'BeneficiaryCreator',
    ViewerBeneficiaries: 'ViewerBeneficiaries',
    AdminContactPage: 'AdminContactPage',
    CustomizationPage: 'CustomizationPage',
    UsersPage: 'UsersPage',
    CampaignDetails: 'CampaignDetails',
    SelectionDetailsPage: 'SelectionDetailsPage',
    ViewerCart: 'ViewerCart',
    PaymentAcceptedPage: 'PaymentAcceptedPage',
    PaymentAddressForm: 'PaymentAddressForm',
    CohortUserCriterias: 'CohortUserCriterias',
    CohortUserManualSelector: 'CohortUserManualSelector',
    CohortUserPreviewOld: 'CohortUserPreviewOld',
    UsersTable: 'UsersTable',
    ContactPage: 'ContactPage',
    HappeningAdminEdit: 'HappeningAdminEdit',
    HappeningAdminGrantees: 'HappeningAdminGrantees',
    HappeningParticipantList: 'HappeningParticipantList',
    HappeningAdminRegistrationsTable: 'HappeningAdminRegistrationsTable',
    SummaryItemGrantees: 'SummaryItemGrantees',
    HappeningGranteeAdder: 'HappeningGranteeAdder',
    HappeningGranteesList: 'HappeningGranteesList',
    HappeningDetailsTab: 'HappeningDetailsTab',
    HappeningOrdersTab: 'HappeningOrdersTab',
    HappeningPaiementPage: 'HappeningPaiementPage',
    HappeningUserCards: 'HappeningUserCards',
    InProgressHappenings: 'InProgressHappenings',
    AdminHappeningDetailPage: 'AdminHappeningDetailPage',
    AdminHappeningsPage: 'AdminHappeningsPage',
    HappeningDetailsPage: 'HappeningDetailsPage',
    HappeningsPage: 'HappeningsPage',
    useArticleCategoryPage: 'useArticleCategoryPage',
    ArticleDetailsPage: 'ArticleDetailsPage',
    QuestionCategories: 'QuestionCategories',
    PublicationDetailsPage: 'PublicationDetailsPage',
    MobileHubPage: 'MobileHubPage',
    UserClaimedOffersPagination: 'UserClaimedOffersPagination',
    ViewerOrdersPayedV2: 'ViewerOrdersPayedV2',
    ViewerOrdersPayedV1Data: 'ViewerOrdersPayedV1Data',
    ViewerOrdersPayedV1Size: 'ViewerOrdersPayedV1Size',
    ShowStopperNewsletterViewer: 'ShowStopperNewsletterViewer',
    SorewardsOnboardingModal: 'SorewardsOnboardingModal',
    IntegrationsConnectorView: 'IntegrationsConnectorView',
    UserIntegrationConnectorSynchronisationHistoryListContent: 'UserIntegrationConnectorSynchronisationHistoryListContent',
    UserIntegrationSynchronisationView: 'UserIntegrationSynchronisationView',
    UserIntegrationSynchronisationProfilesTable: 'UserIntegrationSynchronisationProfilesTable',
    IntegrationsView: 'IntegrationsView',
    RootIndex: 'RootIndex',
    CatalogCategories: 'CatalogCategories',
    BankAccountRoute: 'BankAccountRoute',
    AccountInfoRoute: 'AccountInfoRoute',
    AdminCompanyRoute: 'AdminCompanyRoute',
    AdminDashboardExport: 'AdminDashboardExport',
    AdminDashboardGatheringIdRoute: 'AdminDashboardGatheringIdRoute',
    AdminMailNewsletterIdRoute: 'AdminMailNewsletterIdRoute',
    AdminRoute: 'AdminRoute',
    AdvantagesRoute: 'AdvantagesRoute',
    ReimbursementsRoute: 'ReimbursementsRoute',
    CatalogFilters: 'CatalogFilters',
    CatalogCampaignInformations: 'CatalogCampaignInformations',
    GameRoute: 'GameRoute',
    ActiveSurveyView: 'ActiveSurveyView',
    AuthenticatedLayout: 'AuthenticatedLayout',
    CampaignIndexNames: 'CampaignIndexNames',
    UserAlgoliaSecuredKeyAPI: 'UserAlgoliaSecuredKeyAPI',
    SuspendedAccountInfoRoute: 'SuspendedAccountInfoRoute',
    SuspendedHubRoute: 'SuspendedHubRoute',
    SuspendedRoute: 'SuspendedRoute',
    RegisterPage: 'RegisterPage',
    GetSSORouteProviders: 'GetSSORouteProviders',
    GetRouteCompanyPublic: 'GetRouteCompanyPublic',
    Maintenance: 'Maintenance',
    ViewerActiveOrder: 'ViewerActiveOrder',
    CompanyDrive: 'CompanyDrive',
    UserLanguagePreferences: 'UserLanguagePreferences',
    SubventionFundingAmounts: 'SubventionFundingAmounts',
    SubventionSchemaCategories: 'SubventionSchemaCategories',
    UsersCustomsFieldsAdditionalTableDisplay: 'UsersCustomsFieldsAdditionalTableDisplay'
  },
  Mutation: {
    ArticleItemHighlightCreate: 'ArticleItemHighlightCreate',
    ArticleItemHighlightDelete: 'ArticleItemHighlightDelete',
    CartItemUpdate: 'CartItemUpdate',
    cartEnableSingleSubvention: 'cartEnableSingleSubvention',
    cartDisableSingleSubvention: 'cartDisableSingleSubvention',
    CompanyDocumentCreate: 'CompanyDocumentCreate',
    CompanyDocumentDelete: 'CompanyDocumentDelete',
    CompanyDocumentLinePin: 'CompanyDocumentLinePin',
    CompanyDocumentLineUnpin: 'CompanyDocumentLineUnpin',
    CompanyDocumentUpdate: 'CompanyDocumentUpdate',
    CompanyFolderCreate: 'CompanyFolderCreate',
    CompanyFolderDelete: 'CompanyFolderDelete',
    CompanyFolderDownload: 'CompanyFolderDownload',
    CompanyFolderUpdate: 'CompanyFolderUpdate',
    CompanyReimbursementValidationApprove: 'CompanyReimbursementValidationApprove',
    CompanyReimbursementValidationReject: 'CompanyReimbursementValidationReject',
    AppdragExportTransactions: 'AppdragExportTransactions',
    NewsletterDeleteModal: 'NewsletterDeleteModal',
    PublicationHighlightCreate: 'PublicationHighlightCreate',
    PublicationHighlightRemove: 'PublicationHighlightRemove',
    PublicationPublish: 'PublicationPublish',
    PublicationDelete: 'PublicationDelete',
    PublicationShareUpdate: 'PublicationShareUpdate',
    PublicationSharePublish: 'PublicationSharePublish',
    SubventionWalletCreditingCreateBankwire: 'SubventionWalletCreditingCreateBankwire',
    CreateMangopayIban: 'CreateMangopayIban',
    UpdateMangopayIban: 'UpdateMangopayIban',
    UserAccountMissingDataUpdate: 'UserAccountMissingDataUpdate',
    UserIdConfirmation: 'UserIdConfirmation',
    InvitationCreate: 'InvitationCreate',
    InvitationSend: 'InvitationSend',
    ConfirmStep: 'ConfirmStep',
    ImportErrors: 'ImportErrors',
    ImportStep: 'ImportStep',
    acceptBeneficiary: 'acceptBeneficiary',
    rejectBeneficiary: 'rejectBeneficiary',
    followUpBeneficiaryReject: 'followUpBeneficiaryReject',
    AdminCompanyMembershipCreate: 'AdminCompanyMembershipCreate',
    AdminCompanyMembershipUpdate: 'AdminCompanyMembershipUpdate',
    ReimbursementCreate: 'ReimbursementCreate',
    ArticleArchive: 'ArticleArchive',
    ArticleUnarchive: 'ArticleUnarchive',
    BeneficiaryVerificationRowAcceptBeneficiary: 'BeneficiaryVerificationRowAcceptBeneficiary',
    CreateScore: 'CreateScore',
    UpdateScore: 'UpdateScore',
    DeleteScore: 'DeleteScore',
    CartEnableSubventions: 'CartEnableSubventions',
    CartDisableSubventions: 'CartDisableSubventions',
    CartUpdateItemQuantity: 'CartUpdateItemQuantity',
    CartCheckout: 'CartCheckout',
    CompanyProfileInfoUpdate: 'CompanyProfileInfoUpdate',
    ContactsListSetContactPosition: 'ContactsListSetContactPosition',
    ContactsListContactUpdate: 'ContactsListContactUpdate',
    ContactsListContactDelete: 'ContactsListContactDelete',
    FaqQuestionCreate: 'FaqQuestionCreate',
    CreatePublication: 'CreatePublication',
    ModalCancelRegistration: 'ModalCancelRegistration',
    ModalDeleteArticle: 'ModalDeleteArticle',
    ModalMobileLogin: 'ModalMobileLogin',
    MarkNotificationsAsRead: 'MarkNotificationsAsRead',
    CouponClaimableClaim: 'CouponClaimableClaim',
    OrderAddItem: 'OrderAddItem',
    PictureUploadPictureCreate: 'PictureUploadPictureCreate',
    ProfileAvatarUpdate: 'ProfileAvatarUpdate',
    QuestionCategoryItemUpdate: 'QuestionCategoryItemUpdate',
    QuestionCategoryListUpdate: 'QuestionCategoryListUpdate',
    cancelReimbursement: 'cancelReimbursement',
    SubventionFunnelUpdate: 'SubventionFunnelUpdate',
    SubventionValidate: 'SubventionValidate',
    SubventionConfirm: 'SubventionConfirm',
    SubventionRename: 'SubventionRename',
    SubventionCreationStepCreate: 'SubventionCreationStepCreate',
    SubventionGranteesStepCohortUpdate: 'SubventionGranteesStepCohortUpdate',
    SubventionsListDelete: 'SubventionsListDelete',
    UpdateQuestionCategoryModal: 'UpdateQuestionCategoryModal',
    UserSettingsUpdate: 'UserSettingsUpdate',
    UserTwoFactorCreate: 'UserTwoFactorCreate',
    UserTwoFactorDelete: 'UserTwoFactorDelete',
    CompanyCustomFieldCreate: 'CompanyCustomFieldCreate',
    CompanyCustomFieldDelete: 'CompanyCustomFieldDelete',
    InvitationCreateCompany: 'InvitationCreateCompany',
    InvitationSendCompany: 'InvitationSendCompany',
    FilteredUsersInvitationCreate: 'FilteredUsersInvitationCreate',
    FilteredUsersInvitationSend: 'FilteredUsersInvitationSend',
    SubventionDetailContextProviderUpdate: 'SubventionDetailContextProviderUpdate',
    NewsletterDetailsDuplicate: 'NewsletterDetailsDuplicate',
    NewsletterTableDuplicate: 'NewsletterTableDuplicate',
    NewsletterFormCreate: 'NewsletterFormCreate',
    NewsletterFormUpdate: 'NewsletterFormUpdate',
    NewsletterSendModal: 'NewsletterSendModal',
    NewsletterSendTestModal: 'NewsletterSendTestModal',
    ArticleCreateViewCreateArticle: 'ArticleCreateViewCreateArticle',
    ArticleCreateViewCreatePublication: 'ArticleCreateViewCreatePublication',
    ArticleUpdateViewUpdateArticle: 'ArticleUpdateViewUpdateArticle',
    ArticleUpdateViewUpdateArticleAddAttachments: 'ArticleUpdateViewUpdateArticleAddAttachments',
    ArticleUpdateViewUpdateArticleRemoveAttachments: 'ArticleUpdateViewUpdateArticleRemoveAttachments',
    PublicationCreateViewCreatePublication: 'PublicationCreateViewCreatePublication',
    PublicationCreateViewPublishPublication: 'PublicationCreateViewPublishPublication',
    PublicationUpdateViewQueryUpdatePublication: 'PublicationUpdateViewQueryUpdatePublication',
    PublicationUpdateViewQueryUpdateAddAttachments: 'PublicationUpdateViewQueryUpdateAddAttachments',
    PublicationUpdateViewQueryUpdateRemoveAttachments: 'PublicationUpdateViewQueryUpdateRemoveAttachments',
    FileUploadListCreate: 'FileUploadListCreate',
    PictureSelectCreate: 'PictureSelectCreate',
    PushTokenUpsert: 'PushTokenUpsert',
    userPreferencesPushNotificationsUpdate: 'userPreferencesPushNotificationsUpdate',
    CampaignFavoriteToggleCampaign: 'CampaignFavoriteToggleCampaign',
    FavoriteBadgeToggleCampaign: 'FavoriteBadgeToggleCampaign',
    HighlightGathering: 'HighlightGathering',
    UnHighlightGathering: 'UnHighlightGathering',
    CancelGathering: 'CancelGathering',
    GatheringUpdateMaxTicketCount: 'GatheringUpdateMaxTicketCount',
    GatheringPendingPaymentRedirect: 'GatheringPendingPaymentRedirect',
    GatheringSessionCancelModalCancelSession: 'GatheringSessionCancelModalCancelSession',
    GatheringUserDeleteTicket: 'GatheringUserDeleteTicket',
    GatheringCreate: 'GatheringCreate',
    GatheringUpdate: 'GatheringUpdate',
    GatheringAccessCreate: 'GatheringAccessCreate',
    GatheringAccessUpdate: 'GatheringAccessUpdate',
    GatheringDelete: 'GatheringDelete',
    GatheringPublish: 'GatheringPublish',
    GatheringSessionsCreate: 'GatheringSessionsCreate',
    GatheringSessionUpdate: 'GatheringSessionUpdate',
    GatheringSessionPricesCreate: 'GatheringSessionPricesCreate',
    gatheringAccessRemoveManuallyInvited: 'gatheringAccessRemoveManuallyInvited',
    gatheringRegistrationCancel: 'gatheringRegistrationCancel',
    GatheringCartCta: 'GatheringCartCta',
    GatheringCartCheckout: 'GatheringCartCheckout',
    GatheringRegistrationCancel: 'GatheringRegistrationCancel',
    GatheringUserCancelParticipation: 'GatheringUserCancelParticipation',
    HomeViewSpotlightBlockReorderModalReorder: 'HomeViewSpotlightBlockReorderModalReorder',
    HomeViewSpotlightBlockReorderModalDelete: 'HomeViewSpotlightBlockReorderModalDelete',
    UpdatePassword: 'UpdatePassword',
    PersonnalAddressFormUpdate: 'PersonnalAddressFormUpdate',
    PersonnalInformationsFormUpdate: 'PersonnalInformationsFormUpdate',
    createBeneficiary: 'createBeneficiary',
    updateBeneficiary: 'updateBeneficiary',
    deleteBeneficiary: 'deleteBeneficiary',
    AdminContactPageCreate: 'AdminContactPageCreate',
    AdminFaqPageCreate: 'AdminFaqPageCreate',
    CompanyInfoUpdate: 'CompanyInfoUpdate',
    CompanyHomeMessageUpdate: 'CompanyHomeMessageUpdate',
    PictureCompanyCreate: 'PictureCompanyCreate',
    logout: 'logout',
    CartRemoveItem: 'CartRemoveItem',
    FeedPageReadPublications: 'FeedPageReadPublications',
    UpdateHappening: 'UpdateHappening',
    HappeningAdminGranteesUpdate: 'HappeningAdminGranteesUpdate',
    HappeningAdminSummaryHappeningCreate: 'HappeningAdminSummaryHappeningCreate',
    HappeningAdminSummaryCohortCreate: 'HappeningAdminSummaryCohortCreate',
    HappeningCompanyUpdate: 'HappeningCompanyUpdate',
    HappeningHighlightCreate: 'HappeningHighlightCreate',
    HappeningHighlightRemove: 'HappeningHighlightRemove',
    HappeningAdminCancel: 'HappeningAdminCancel',
    HappeningOffersRegister: 'HappeningOffersRegister',
    cancelRegistration: 'cancelRegistration',
    HappeningRegistrationSetStatusToAwaitingStripe: 'HappeningRegistrationSetStatusToAwaitingStripe',
    ShowstopperNewsletterMutationPreferences: 'ShowstopperNewsletterMutationPreferences',
    ShowstopperNewsletterMutationTerms: 'ShowstopperNewsletterMutationTerms',
    SorewardsCardBannerConnectedGenerateLogin: 'SorewardsCardBannerConnectedGenerateLogin',
    SorewardsOnboardingFormSubmit: 'SorewardsOnboardingFormSubmit',
    UserIntegrationConnectorLuccaCreateFormConnectorCreate: 'UserIntegrationConnectorLuccaCreateFormConnectorCreate',
    UserIntegrationConnectorLuccaUpdateFormConnectorUpdate: 'UserIntegrationConnectorLuccaUpdateFormConnectorUpdate',
    UserIntegrationConnectorSalesforceRcuCreateFormConnectorCreate: 'UserIntegrationConnectorSalesforceRcuCreateFormConnectorCreate',
    UserIntegrationConnectorSalesforceRcuUpdateFormConnectorUpdate: 'UserIntegrationConnectorSalesforceRcuUpdateFormConnectorUpdate',
    IntegrationsModalDelete: 'IntegrationsModalDelete',
    UserIntegrationConnectorSynchronisationTrigger: 'UserIntegrationConnectorSynchronisationTrigger',
    AttachmentFileCreate: 'AttachmentFileCreate',
    AttachmentPictureCreate: 'AttachmentPictureCreate',
    CartItemUpdateQuantity: 'CartItemUpdateQuantity'
  },
  Fragment: {
    AttachmentCard: 'AttachmentCard',
    CampaignCategories: 'CampaignCategories',
    InputFileUpload: 'InputFileUpload',
    ProgressivePicture: 'ProgressivePicture',
    SubventionDiscountInfoBox: 'SubventionDiscountInfoBox',
    AdvantageCategoryItemCategory: 'AdvantageCategoryItemCategory',
    AdvantageDetailCampaigns: 'AdvantageDetailCampaigns',
    AdvantageDetailCategories: 'AdvantageDetailCategories',
    AdvantageDetailCategoryCategory: 'AdvantageDetailCategoryCategory',
    ArticleItemHighlight: 'ArticleItemHighlight',
    ArticleItem: 'ArticleItem',
    AdvantageExpireIn: 'AdvantageExpireIn',
    CampaignCard: 'CampaignCard',
    OfferProductDiscount: 'OfferProductDiscount',
    OfferCouponVoucher: 'OfferCouponVoucher',
    DiscountBadge: 'DiscountBadge',
    VoucherBadge: 'VoucherBadge',
    CampaignDiscountInsert: 'CampaignDiscountInsert',
    CampaignSelectionCard: 'CampaignSelectionCard',
    CartAllSubventionsPanel: 'CartAllSubventionsPanel',
    CartLineItem: 'CartLineItem',
    CartLineItemProductVariant: 'CartLineItemProductVariant',
    CartLineItemSubvention: 'CartLineItemSubvention',
    CartLineItemMedataData: 'CartLineItemMedataData',
    CartLineItemVariantMetadata: 'CartLineItemVariantMetadata',
    CartSubventionDetails: 'CartSubventionDetails',
    CategoryCardLink: 'CategoryCardLink',
    CategoryItem: 'CategoryItem',
    CircleGraphExpenses: 'CircleGraphExpenses',
    CircleGraphClicks: 'CircleGraphClicks',
    CircleGraphBudget: 'CircleGraphBudget',
    CompanyDocumentLine: 'CompanyDocumentLine',
    DocumentHighlight: 'DocumentHighlight',
    DocumentUpdateForm: 'DocumentUpdateForm',
    CompanyFolderLine: 'CompanyFolderLine',
    FolderUpdateForm: 'FolderUpdateForm',
    SubventionReimbursementDetail: 'SubventionReimbursementDetail',
    ContactItem: 'ContactItem',
    DashboardExportTab: 'DashboardExportTab',
    DashboardSavingsCard: 'DashboardSavingsCard',
    NotificationButton: 'NotificationButton',
    OfferExpiration: 'OfferExpiration',
    OfferInfos: 'OfferInfos',
    OfferInfosSubvention: 'OfferInfosSubvention',
    OfferDiscount: 'OfferDiscount',
    OfferReduction: 'OfferReduction',
    OfferVoucher: 'OfferVoucher',
    PublicationCard: 'PublicationCard',
    PublicationCardTarget: 'PublicationCardTarget',
    PublicationShare: 'PublicationShare',
    RefundAdvantageSelectCard: 'RefundAdvantageSelectCard',
    ReimbursementDetailProof: 'ReimbursementDetailProof',
    ReimbursementListItem: 'ReimbursementListItem',
    ReimbursementListItemShort: 'ReimbursementListItemShort',
    SubventionBudgetConsumptionCard: 'SubventionBudgetConsumptionCard',
    SubventionBudgetTimeLeftCard: 'SubventionBudgetTimeLeftCard',
    SubventionDetailGrantee: 'SubventionDetailGrantee',
    SubventionDetailHeader: 'SubventionDetailHeader',
    SubventionDetailInfoCardSubvention: 'SubventionDetailInfoCardSubvention',
    SubventionDetailReadyStartCardSubvention: 'SubventionDetailReadyStartCardSubvention',
    SubventionInvoiceGroupByYear: 'SubventionInvoiceGroupByYear',
    SubventionInvoiceRefundRow: 'SubventionInvoiceRefundRow',
    SubventionInvoiceRow: 'SubventionInvoiceRow',
    SubventionInvoicesMonthList: 'SubventionInvoicesMonthList',
    AdminSubventionReimbursement: 'AdminSubventionReimbursement',
    SubventionTransferSummary: 'SubventionTransferSummary',
    BankingAlias: 'BankingAlias',
    SubventionWalletCreditingTransfer: 'SubventionWalletCreditingTransfer',
    SubventionWalletCreditingCompany: 'SubventionWalletCreditingCompany',
    SubventionWalletTransfersListItem: 'SubventionWalletTransfersListItem',
    HighlightType: 'HighlightType',
    PublicationType: 'PublicationType',
    BrandViews: 'BrandViews',
    UserClaimedCouponItem: 'UserClaimedCouponItem',
    UserAccountCoreData: 'UserAccountCoreData',
    UserOrderAppdragItem: 'UserOrderAppdragItem',
    UserOrderItem: 'UserOrderItem',
    UserProductAppdragItem: 'UserProductAppdragItem',
    DownloadableProduct: 'DownloadableProduct',
    UserProductItem: 'UserProductItem',
    UsersInviteLineItem: 'UsersInviteLineItem',
    WelcomeCustomizationUser: 'WelcomeCustomizationUser',
    CompanyMemberAvatar: 'CompanyMemberAvatar',
    AdminSingleUpsertBannerData: 'AdminSingleUpsertBannerData',
    SingleUpsertUserAvatar: 'SingleUpsertUserAvatar',
    UserBeneficiary: 'UserBeneficiary',
    AdminSingleUpsertCompanyCustomFieldsData: 'AdminSingleUpsertCompanyCustomFieldsData',
    AdminSingleUpsertCompanyData: 'AdminSingleUpsertCompanyData',
    AdminSingleUpsertPersonnalData: 'AdminSingleUpsertPersonnalData',
    SingleUpsertUserAddressDetail: 'SingleUpsertUserAddressDetail',
    CompanyMembershipUserInfos: 'CompanyMembershipUserInfos',
    AdvantageDetail: 'AdvantageDetail',
    GeneralTab: 'GeneralTab',
    FunnelAdvantage: 'FunnelAdvantage',
    ArticleContent: 'ArticleContent',
    BeneficiariesOwnerUser: 'BeneficiariesOwnerUser',
    BeneficiaryVerificationRow: 'BeneficiaryVerificationRow',
    CampaignBrandHeadlines: 'CampaignBrandHeadlines',
    CampaignCover: 'CampaignCover',
    CampaignDescription: 'CampaignDescription',
    CampaignDetails: 'CampaignDetails',
    CampaignKeyPoints: 'CampaignKeyPoints',
    CampaignNps: 'CampaignNps',
    CampaignOfferActiveSubvention: 'CampaignOfferActiveSubvention',
    CartButton: 'CartButton',
    CartCheckoutOrderUserAddress: 'CartCheckoutOrderUserAddress',
    CartCheckout: 'CartCheckout',
    CartConfirmationSlide: 'CartConfirmationSlide',
    CartLineItemQuantityPicker: 'CartLineItemQuantityPicker',
    CartSubventions: 'CartSubventions',
    CartValidationCheckoutModal: 'CartValidationCheckoutModal',
    CategoriesList: 'CategoriesList',
    CompanyProfile: 'CompanyProfile',
    DashboardGeneralTabCompany: 'DashboardGeneralTabCompany',
    CurrentFolderTitle: 'CurrentFolderTitle',
    FranceBilletCard: 'FranceBilletCard',
    MobileHubViewer: 'MobileHubViewer',
    MiniNotificationCenterButton: 'MiniNotificationCenterButton',
    ViewerNotification: 'ViewerNotification',
    NotificationTargetSubvention: 'NotificationTargetSubvention',
    NotificationTargetOffer: 'NotificationTargetOffer',
    NotificationTargetBeneficiary: 'NotificationTargetBeneficiary',
    NotificationTargetArticle: 'NotificationTargetArticle',
    NotificationTargetUser: 'NotificationTargetUser',
    NotificationTargetPublication: 'NotificationTargetPublication',
    NotificationTargetDocument: 'NotificationTargetDocument',
    OfferAvailability: 'OfferAvailability',
    OfferStore: 'OfferStore',
    CouponClaimable: 'CouponClaimable',
    CouponLink: 'CouponLink',
    CouponPublic: 'CouponPublic',
    OfferCouponAction: 'OfferCouponAction',
    OfferItem: 'OfferItem',
    OfferItemSubvention: 'OfferItemSubvention',
    CustomVariant: 'CustomVariant',
    MultipleVariants: 'MultipleVariants',
    OfferProductAction: 'OfferProductAction',
    OfferProductVariant: 'OfferProductVariant',
    OfferProductActionSubvention: 'OfferProductActionSubvention',
    OffersListPlaceFilterModuleLocation: 'OffersListPlaceFilterModuleLocation',
    PublicationListCompany: 'PublicationListCompany',
    QuestionCategoryItem: 'QuestionCategoryItem',
    ReimbursementDetail: 'ReimbursementDetail',
    SelectionCover: 'SelectionCover',
    SelectionDescription: 'SelectionDescription',
    SubventionDetailSubvention: 'SubventionDetailSubvention',
    SubventionCategoriesPagination: 'SubventionCategoriesPagination',
    SubventionCategoryItem: 'SubventionCategoryItem',
    Subvention: 'Subvention',
    SubventionsListItem: 'SubventionsListItem',
    MangopayIBAN: 'MangopayIBAN',
    UserBankData: 'UserBankData',
    UserClaimedOffersList: 'UserClaimedOffersList',
    UserKycStatus: 'UserKycStatus',
    KycErrors: 'KycErrors',
    UserPreferences: 'UserPreferences',
    UserDevices: 'UserDevices',
    UsersCustomFields: 'UsersCustomFields',
    UsersListItem: 'UsersListItem',
    ArticleCategory: 'ArticleCategory',
    CampaignAsyncSelectItem: 'CampaignAsyncSelectItem',
    CatalogCampaignCard: 'CatalogCampaignCard',
    CatalogCampaignCardCampaign: 'CatalogCampaignCardCampaign',
    CatalogCampaignCardBestOffer: 'CatalogCampaignCardBestOffer',
    CatalogCampaignCarouselElement: 'CatalogCampaignCarouselElement',
    CatalogCategoriesCarouselCategory: 'CatalogCategoriesCarouselCategory',
    CatalogCategoryButton: 'CatalogCategoryButton',
    CatalogDiscoverCampaignCarouselElement: 'CatalogDiscoverCampaignCarouselElement',
    DropdownMenuCategories: 'DropdownMenuCategories',
    DropdownMenuSubventions: 'DropdownMenuSubventions',
    CatalogCategorySearchCarouselItem: 'CatalogCategorySearchCarouselItem',
    CatalogSearchTitle: 'CatalogSearchTitle',
    CohortUserCriteriaCustomFieldField: 'CohortUserCriteriaCustomFieldField',
    ArticleCard: 'ArticleCard',
    ArticleCategoriesCarouselCategory: 'ArticleCategoriesCarouselCategory',
    ArticleCategoryCard: 'ArticleCategoryCard',
    ArticlesCarouselArticle: 'ArticlesCarouselArticle',
    ContactCard: 'ContactCard',
    DriveDocumentCard: 'DriveDocumentCard',
    NewsletterDetail: 'NewsletterDetail',
    NewsletterEmailPreview: 'NewsletterEmailPreview',
    SpotlightCard: 'SpotlightCard',
    GatheringSpotlightCard: 'GatheringSpotlightCard',
    SpotlightCardArticle: 'SpotlightCardArticle',
    SpotlightCardCompanyDocument: 'SpotlightCardCompanyDocument',
    SpotlightCardCompanyFolder: 'SpotlightCardCompanyFolder',
    SpotlightCardHappening: 'SpotlightCardHappening',
    SpotlightCardPublication: 'SpotlightCardPublication',
    SpotlightCarouselHighlight: 'SpotlightCarouselHighlight',
    ArticleFormArticle: 'ArticleFormArticle',
    NewsletterForm: 'NewsletterForm',
    PublicationFormPublication: 'PublicationFormPublication',
    AppFooter: 'AppFooter',
    AppFooterCompany: 'AppFooterCompany',
    AppFooterConfig: 'AppFooterConfig',
    AppFooterLayout: 'AppFooterLayout',
    AppHeaderCompany: 'AppHeaderCompany',
    AppHeaderViewer: 'AppHeaderViewer',
    AppHeaderProfile: 'AppHeaderProfile',
    AppHeaderProfileSuspended: 'AppHeaderProfileSuspended',
    AppHeaderSuspended: 'AppHeaderSuspended',
    AppMenu: 'AppMenu',
    AppMenuElement: 'AppMenuElement',
    AppMenuFeatures: 'AppMenuFeatures',
    AppMenuEntry: 'AppMenuEntry',
    AppMenuFolder: 'AppMenuFolder',
    CompanyPicture: 'CompanyPicture',
    MobileAppHeaderProfileViewer: 'MobileAppHeaderProfileViewer',
    MobileAppHeaderProfileCompany: 'MobileAppHeaderProfileCompany',
    AvatarUser: 'AvatarUser',
    AvatarPointOfContact: 'AvatarPointOfContact',
    CampaignFavorite: 'CampaignFavorite',
    FavoritePageCardCampaign: 'FavoritePageCardCampaign',
    GatheringAdminCardGathering: 'GatheringAdminCardGathering',
    GatheringAdminSessionSettingsButtonSession: 'GatheringAdminSessionSettingsButtonSession',
    GatheringAdminSettingsButton: 'GatheringAdminSettingsButton',
    GatheringCancelModal: 'GatheringCancelModal',
    GatheringCancelRegistrationsConfirmModal: 'GatheringCancelRegistrationsConfirmModal',
    GatheringAdminHeader: 'GatheringAdminHeader',
    GatheringMaxGuestModal: 'GatheringMaxGuestModal',
    GatheringOpenRegistrationCard: 'GatheringOpenRegistrationCard',
    GatheringParticipationCardGatheringEdge: 'GatheringParticipationCardGatheringEdge',
    GatheringSessionCancelModalSession: 'GatheringSessionCancelModalSession',
    GatheringSessionParticipationsCardSession: 'GatheringSessionParticipationsCardSession',
    GatheringSessionParticipationsCard: 'GatheringSessionParticipationsCard',
    GatheringUntargetUsersConfirmModal: 'GatheringUntargetUsersConfirmModal',
    GatheringSessionUserDeleteTicket: 'GatheringSessionUserDeleteTicket',
    GatheringUserDetailBlock: 'GatheringUserDetailBlock',
    GatheringUserSessionBlock: 'GatheringUserSessionBlock',
    GatheringUserBlock: 'GatheringUserBlock',
    BaseGathering: 'BaseGathering',
    BaseGatheringAccess: 'BaseGatheringAccess',
    BaseGatheringSession: 'BaseGatheringSession',
    BaseGatheringSessionPrice: 'BaseGatheringSessionPrice',
    formatGatheringAccessGathering: 'formatGatheringAccessGathering',
    formatSessionPricesTargetSession: 'formatSessionPricesTargetSession',
    GatheringAdminCreateViewGathering: 'GatheringAdminCreateViewGathering',
    GatheringAdminEventIdView: 'GatheringAdminEventIdView',
    GatheringAdminInvitedUsersGathering: 'GatheringAdminInvitedUsersGathering',
    GatheringAdminParticipationsFilterGathering: 'GatheringAdminParticipationsFilterGathering',
    GatheringAdminParticipationsHeaderGathering: 'GatheringAdminParticipationsHeaderGathering',
    GatheringAdminParticipationsTable: 'GatheringAdminParticipationsTable',
    GatheringAdminParticipationsTableEntry: 'GatheringAdminParticipationsTableEntry',
    GatheringAdminParticipationsTableActionsCellGathering: 'GatheringAdminParticipationsTableActionsCellGathering',
    GatheringAdminParticipationsTableUser: 'GatheringAdminParticipationsTableUser',
    GatheringAdminSessionDetailsGathering: 'GatheringAdminSessionDetailsGathering',
    GatheringAdminSessionDetails: 'GatheringAdminSessionDetails',
    GatheringAdminSessions: 'GatheringAdminSessions',
    GatheringAdminTotalParticipationsGathering: 'GatheringAdminTotalParticipationsGathering',
    GatheringAdminEventUpdateAccessGathering: 'GatheringAdminEventUpdateAccessGathering',
    GatheringAdminEventUpdateInformationsGathering: 'GatheringAdminEventUpdateInformationsGathering',
    GatheringAdminEventUpdateSessionUpdateViewSession: 'GatheringAdminEventUpdateSessionUpdateViewSession',
    GatheringCartCta: 'GatheringCartCta',
    GatheringSessionCartCta: 'GatheringSessionCartCta',
    GatheringCartViewPriceQuota: 'GatheringCartViewPriceQuota',
    GatheringCartSession: 'GatheringCartSession',
    GatheringCart: 'GatheringCart',
    GatheringEventHeader: 'GatheringEventHeader',
    GatheringEventIdViewGathering: 'GatheringEventIdViewGathering',
    GatheringUserActiveParticipation: 'GatheringUserActiveParticipation',
    HomeViewCompany: 'HomeViewCompany',
    HomeViewCustomisationModules: 'HomeViewCustomisationModules',
    HomeViewArticleCategoriesBlock: 'HomeViewArticleCategoriesBlock',
    HomeViewArticlesBlock: 'HomeViewArticlesBlock',
    HomeViewBlock: 'HomeViewBlock',
    HomeViewBlocks: 'HomeViewBlocks',
    HomeViewCatalogCategoriesBlock: 'HomeViewCatalogCategoriesBlock',
    HomeViewContactsBlock: 'HomeViewContactsBlock',
    HomeViewDriveBlock: 'HomeViewDriveBlock',
    HomeViewQuestionBoxBlock: 'HomeViewQuestionBoxBlock',
    HomeViewSpotlightBlock: 'HomeViewSpotlightBlock',
    HomeViewSpotlightBlockReorderModalHighlight: 'HomeViewSpotlightBlockReorderModalHighlight',
    HomeViewSpotlightBlockReorderModalCardHighlight: 'HomeViewSpotlightBlockReorderModalCardHighlight',
    HomeViewWelcomeMessageConfig: 'HomeViewWelcomeMessageConfig',
    HomeViewWelcomeMessageCompany: 'HomeViewWelcomeMessageCompany',
    HomeViewWelcomeMessageUser: 'HomeViewWelcomeMessageUser',
    UserSubventionCard: 'UserSubventionCard',
    ActiveSurvey: 'ActiveSurvey',
    SurveyInProgress: 'SurveyInProgress',
    CampaignCardInformation: 'CampaignCardInformation',
    PasswordUpdateFormUser: 'PasswordUpdateFormUser',
    PersonnalAddressFormUser: 'PersonnalAddressFormUser',
    PersonnalInformationsFormUser: 'PersonnalInformationsFormUser',
    BeneficiaryDetail: 'BeneficiaryDetail',
    BeneficiaryForm: 'BeneficiaryForm',
    PersonalInfoPageViewer: 'PersonalInfoPageViewer',
    CustomizationPageCompany: 'CustomizationPageCompany',
    Picture: 'Picture',
    CohortUserManualSelectorRow: 'CohortUserManualSelectorRow',
    UserTableRecord: 'UserTableRecord',
    AdminHappeningsList: 'AdminHappeningsList',
    AdminHappeningsListItem: 'AdminHappeningsListItem',
    AskHappeningFund: 'AskHappeningFund',
    HappeningCover: 'HappeningCover',
    HappeningAdminEdit: 'HappeningAdminEdit',
    HappeningAdminGranteesItem: 'HappeningAdminGranteesItem',
    HappeningInfoRegistration: 'HappeningInfoRegistration',
    HappeningInfoTime: 'HappeningInfoTime',
    HappeningAdminRaisedFunds: 'HappeningAdminRaisedFunds',
    HappeningAdminRegistrationTableItem: 'HappeningAdminRegistrationTableItem',
    HappeningCompanyForm: 'HappeningCompanyForm',
    HappeningDetailHeader: 'HappeningDetailHeader',
    HappeningSearchGrantee: 'HappeningSearchGrantee',
    HappeningDetailsDate: 'HappeningDetailsDate',
    HappeningLargeCard: 'HappeningLargeCard',
    HappeningOffers: 'HappeningOffers',
    HappeningOrderCard: 'HappeningOrderCard',
    HappeningOrdersTabPagination: 'HappeningOrdersTabPagination',
    HappeningUserCard: 'HappeningUserCard',
    HappeningAdminFormContext: 'HappeningAdminFormContext',
    AdminHappeningDetail: 'AdminHappeningDetail',
    UserOrdersList: 'UserOrdersList',
    UserOrdersAppdragList: 'UserOrdersAppdragList',
    SorewardsCardBannerConnected: 'SorewardsCardBannerConnected',
    IntegrationConnectorCard: 'IntegrationConnectorCard',
    IntegrationProviderCard: 'IntegrationProviderCard',
    UserIntegrationConnectorUpdateModalConnector: 'UserIntegrationConnectorUpdateModalConnector',
    UserIntegrationConnectorLuccaUpdateFormConnectorCredentials: 'UserIntegrationConnectorLuccaUpdateFormConnectorCredentials',
    UserIntegrationConnectorSalesforceRcuUpdateFormConnectorCredentials: 'UserIntegrationConnectorSalesforceRcuUpdateFormConnectorCredentials',
    UserIntegrationUtilProfile: 'UserIntegrationUtilProfile',
    IntegrationsConnectorViewConnector: 'IntegrationsConnectorViewConnector',
    UserIntegrationConnectorCredentialsButtonConnector: 'UserIntegrationConnectorCredentialsButtonConnector',
    UserIntegrationConnectorDeleteButtonConnector: 'UserIntegrationConnectorDeleteButtonConnector',
    DeleteIntegrationsModalConnector: 'DeleteIntegrationsModalConnector',
    DeleteIntegrationsModalConnectorLucca: 'DeleteIntegrationsModalConnectorLucca',
    UserIntegrationConnectorHeaderConnector: 'UserIntegrationConnectorHeaderConnector',
    UserIntegrationConnectorLastsynchronisationSynchronisation: 'UserIntegrationConnectorLastsynchronisationSynchronisation',
    UserIntegrationConnectorLastsynchronisationConnector: 'UserIntegrationConnectorLastsynchronisationConnector',
    UserIntegrationConnectorSynchronisationHistoryListConnector: 'UserIntegrationConnectorSynchronisationHistoryListConnector',
    UserIntegrationConnectorSynchronisationHistoryListLastSynchronisation: 'UserIntegrationConnectorSynchronisationHistoryListLastSynchronisation',
    UserIntegrationConnectorSynchronisationHistoryListCardSynchronisation: 'UserIntegrationConnectorSynchronisationHistoryListCardSynchronisation',
    UserIntegrationConnectorSynchronisationHistoryListCardConnector: 'UserIntegrationConnectorSynchronisationHistoryListCardConnector',
    UserIntegrationConnectorSynchronisationHistoryListContentConnector: 'UserIntegrationConnectorSynchronisationHistoryListContentConnector',
    UserIntegrationConnectorSynchronisationHistoryListContentLastSynchronisation: 'UserIntegrationConnectorSynchronisationHistoryListContentLastSynchronisation',
    UserIntegrationConnectorSynchronisationStatSynchronisation: 'UserIntegrationConnectorSynchronisationStatSynchronisation',
    UserIntegrationConnectorSynchronisationTriggerConnector: 'UserIntegrationConnectorSynchronisationTriggerConnector',
    UserIntegrationSynchronisationProfilesTableRecord: 'UserIntegrationSynchronisationProfilesTableRecord',
    UserIntegrationSynchronisationViewNavSynchronisation: 'UserIntegrationSynchronisationViewNavSynchronisation',
    SelectedCompany: 'SelectedCompany',
    AuthenticatedUser: 'AuthenticatedUser',
    ConnectedUser: 'ConnectedUser',
    AmplitudeViewer: 'AmplitudeViewer',
    CustomerIOViewer: 'CustomerIOViewer',
    HotjarViewer: 'HotjarViewer',
    SatismeterViewer: 'SatismeterViewer',
    CatalogCategoryLink: 'CatalogCategoryLink',
    AdvantagesRouteViewer: 'AdvantagesRouteViewer',
    ReimbursementsRouteViewer: 'ReimbursementsRouteViewer',
    CampaignIndex: 'CampaignIndex',
    RegisterCompanyPublic: 'RegisterCompanyPublic',
    PublicSSOProvider: 'PublicSSOProvider',
    UserLanguage: 'UserLanguage'
  }
}