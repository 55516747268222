/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect } from 'react';

import { Identify, identify, setUserId } from '@amplitude/analytics-browser';
import { env } from '@env';

import { AmplitudeViewerFragment } from './amplitude.generated';

type AmplitudeProps = {
  viewer: AmplitudeViewerFragment;
};

export const AmplitudeViewer = (props: AmplitudeProps) => {
  if (!env.REACT_APP_AMPLITUDE_KEY) return;

  const { viewer } = props;

  useEffect(() => {
    const event = new Identify();
    setUserId(viewer.id);
    event.set('email', viewer.email);
    event.set('first_name', viewer.firstName);
    event.set('last_name', viewer.lastName);
    event.set('user_creation_date', viewer.createdAt.toISOString());
    event.set('user_type', viewer.company?.viewerIsAdmin ? 'admin' : 'user');

    if (viewer.company) {
      event.set('company_id', viewer.company.id);
      event.set('company_name', viewer.company.displayName);
    } else {
      event.unset('company_id');
      event.unset('company_name');
    }

    identify(event);
  }, [viewer]);

  return null;
};
