import { ComponentProps } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import './Tooltip.css';

type ReactTooltipProps = ComponentProps<typeof ReactTooltip>;

export type TooltipProps = {
  id: string;
  label?: string;
  labelDangerouslySetInnerHTML?: string;
  title?: string;
  disabled?: boolean;
  children: React.ReactNode;
  placement?: ReactTooltipProps['place'];
  clickable?: ReactTooltipProps['clickable'];
  className?: string;
  position?: ReactTooltipProps['position'];
  innerClassname?: string;
};

export const Tooltip = ({
  id,
  label,
  labelDangerouslySetInnerHTML,
  title,
  children,
  disabled,
  placement = 'right',
  clickable = false,
  className = '',
  position,
  innerClassname = '',
}: TooltipProps) => {
  function handleDescription() {
    if (!label && !labelDangerouslySetInnerHTML) {
      return;
    }
    if (labelDangerouslySetInnerHTML) {
      return labelDangerouslySetInnerHTML;
    }
    return `<p class="font-body tooltip-description-text">${label}</p>`;
  }

  const content = `${
    title ? `<p class="font-body tooltip-title-text">${title}</p>` : ''
  }${handleDescription()}`;

  return (
    <div data-tooltip-html={content} data-tooltip-id={id} className={className}>
      {children}
      <ReactTooltip
        id={id}
        hidden={disabled}
        variant="light"
        position={position}
        place={placement}
        clickable={clickable}
        className={`tooltip-rounded drop-shadow-lg ${innerClassname}`}
      />
    </div>
  );
};
