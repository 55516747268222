import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormInputDate } from '@components/molecules/FormInputDate/FormInputDate';
import { FormInputText } from '@components/molecules/FormInputText/FormInputText';
import { FormItem } from '@components/molecules/FormItem/FormItem';
import { useForm } from '@components/organisms/Form/useForm';
import { Form } from '@src/components/organisms/Form/Form';
import { TFunction } from 'i18next';
import * as Yup from 'yup';

const validationSchema = (t: TFunction) =>
  Yup.object({
    firstName: Yup.string()
      .typeError(t('ticketModalCustomerForm.formError'))
      .when('$firstName', {
        is: true,
        then: (schema) =>
          schema.required(t('ticketModalCustomerForm.formError')),
      }),
    lastName: Yup.string()
      .typeError(t('ticketModalCustomerForm.formError'))
      .when('$lastName', {
        is: true,
        then: (schema) =>
          schema.required(t('ticketModalCustomerForm.formError')),
      }),
    birthdate: Yup.date()
      .typeError(t('ticketModalCustomerForm.formError'))
      .when('$birthdate', {
        is: true,
        then: (schema) =>
          schema.required(t('ticketModalCustomerForm.formError')),
      }),
  });

export type CustomerFieldsRequired = {
  firstName?: boolean;
  lastName?: boolean;
  birthdate?: boolean;
};

export type TicketModalCustomerFormValues = {
  firstName?: null | string;
  lastName?: null | string;
  birthdate?: null | Date;
};

type TicketModalCustomerFormProps = {
  requiredFields: CustomerFieldsRequired;
  defaultValues?: TicketModalCustomerFormValues;
};

export type CustomerForm = {
  getValues: () => TicketModalCustomerFormValues | null;
};

export const TicketModalCustomerForm = forwardRef<
  CustomerForm,
  TicketModalCustomerFormProps
>((props: TicketModalCustomerFormProps, ref) => {
  const { requiredFields, defaultValues } = props;
  const { t } = useTranslation();
  const [isReady, setReady] = useState(false);

  const form = useForm({
    validationSchema: validationSchema(t),
    context: requiredFields,
    mode: 'onChange',
    defaultValues: {
      firstName: defaultValues?.firstName ?? undefined,
      lastName: defaultValues?.lastName ?? undefined,
      birthdate: defaultValues?.birthdate ?? undefined,
    },
  });

  useImperativeHandle(ref, () => {
    return {
      getValues: () => {
        form.trigger();
        return isReady ? form.getValues() : null;
      },
    };
  });

  useEffect(() => {
    if (defaultValues) form.trigger();
    setReady(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setReady(form.formState.isValid);
  }, [form.formState.isValid, form.formState.isDirty]);

  return (
    <Form form={form}>
      <div className="flex gap-4">
        {requiredFields.firstName && (
          <FormItem
            name="firstName"
            label={t('ticketModalCustomerForm.firstName')}
          >
            <FormInputText
              placeholder={t('ticketModalCustomerForm.firstNamePlaceholder')}
            />
          </FormItem>
        )}
        {requiredFields.lastName && (
          <FormItem
            name="lastName"
            label={t('ticketModalCustomerForm.lastName')}
          >
            <FormInputText
              placeholder={t('ticketModalCustomerForm.lastNamePlaceholder')}
            />
          </FormItem>
        )}
        {requiredFields.birthdate && (
          <FormItem
            name="birthdate"
            label={t('ticketModalCustomerForm.birthDate')}
          >
            <FormInputDate
              dayPickerProps={{
                toDate: new Date(),
              }}
            />
          </FormItem>
        )}
      </div>
    </Form>
  );
});
