import { ChangeEventHandler, useMemo, useState } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import * as Popover from '@radix-ui/react-popover';
import {
  format,
  formatISO,
  isValid,
  parse,
  parseISO,
} from '@services/dateManager.service';
import { cx } from 'class-variance-authority';

import { FormInputText } from '../FormInputText/FormInputText';
import { FormItemChildProps } from '../FormItem/FormItem';

import {
  FormInputDateContent,
  FormInputDateContentProps,
} from './FormInputDateContent';

type FormInputDateProps = FormItemChildProps & {
  className?: string;
  dayPickerProps?: Omit<
    FormInputDateContentProps,
    'selected' | 'onSelect' | 'month' | 'onMonthChange'
  >;
};

export const FormInputDate = (props: FormInputDateProps) => {
  const { className, name, dayPickerProps = {}, ...rest } = props;
  const { t } = useTranslation();

  // We cast to string since this component is only used inside a formItem
  const { field } = useController({ name: name as string });
  const [month, setMonth] = useState(new Date());
  const [value, setValue] = useState<string>(() => {
    try {
      return format(field.value, 'L');
    } catch (_) {
      return '';
    }
  });

  const handleSelect: FormInputDateContentProps['onSelect'] = (_, day) => {
    const value = formatISO(day);
    field.onChange(value);
    setValue(format(day, 'L'));
  };

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.currentTarget.value;
    setValue(value);

    try {
      field.onChange(formatISO(parse(value, 'L')));
    } catch (err) {
      field.onChange(value);
    }
  };

  const selectedDay = useMemo(() => {
    const parsedDate = parseISO(field.value);
    if (isValid(parsedDate)) {
      setMonth(parsedDate);
      return parsedDate;
    }
  }, [field.value]);

  return (
    <Popover.Root modal>
      <Popover.Trigger asChild>
        <button className="flex-1">
          <FormInputText
            {...rest}
            onBlur={field.onBlur}
            onChange={handleInputChange}
            value={value}
            className={cx(className)}
            placeholder={t(
              'forms.fields.inputDate.input.placeholder',
              'JJ/MM/AAAA',
            )}
          />
        </button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          sideOffset={4}
          className="pointer-events-auto z-popover"
        >
          <div className="flex-1">
            <Popover.Arrow />
            <FormInputDateContent
              {...dayPickerProps}
              onSelect={handleSelect}
              selected={selectedDay}
              month={month}
              onMonthChange={setMonth}
            />
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

FormInputDate.usesController = true;
