import { FooterCustomisationLayoutType } from '@src/graphql/generated/types';

import { AppFooterLayoutDefault } from './layouts/AppFooterLayoutDefault/AppFooterLayoutDefault';
import {
  AppFooterCompanyFragment,
  AppFooterFragment,
  AppFooterLayoutFragment,
} from './AppFooter.generated';

export interface AppFooterProps {
  className?: string;
  company: AppFooterFragment;
}

export const AppFooter = (props: AppFooterProps) => {
  const { className, company } = props;
  const config = company.featureConfig.footerCustomisation;

  if (!config?.isEnabled) return null;

  switch (config.layout?.type) {
    case FooterCustomisationLayoutType.Default:
      return (
        <AppFooterLayoutDefault
          layout={config.layout}
          company={company}
          className={className}
        />
      );
    default:
      return null;
  }
};

export interface AppFooterLayoutProps {
  className?: string;
  layout: AppFooterLayoutFragment;
  company: AppFooterCompanyFragment;
}
