import { Loader } from '@src/components/atoms/Loader';
import { createFileRoute, Navigate, notFound } from '@tanstack/react-router';

import {
  GameRouteDocument,
  GameRouteQuery,
  GameRouteQueryVariables,
} from './~route.generated';

export const Route = createFileRoute('/_authenticated-layout/game')({
  pendingComponent: () => <Loader />,
  notFoundComponent: () => <Navigate to="/home" />,
  loader: async ({ context }) => {
    const viewer = context.viewer;

    const res = await context.apolloClient.query<
      GameRouteQuery,
      GameRouteQueryVariables
    >({
      query: GameRouteDocument,
    });

    const game = res.data.viewer?.company?.gamesPagination.nodes[0];

    if (!viewer || !game) throw notFound();

    return {
      game,
      viewer,
    };
  },
});
