import { KeyboardEvent, RefObject } from 'react';

export const onListKeyDownNavigation =
  (itemsRef: RefObject<HTMLAnchorElement[]>) =>
  (e: KeyboardEvent<HTMLAnchorElement | 'a'>) => {
    const currentTarget = e.currentTarget;
    const currentItemIndex =
      itemsRef.current?.findIndex((node) => node === currentTarget) ?? -1;
    if (currentItemIndex === -1) return;
    if (e.key === 'ArrowDown') {
      if (currentItemIndex === itemsRef.current?.length) return;
      itemsRef.current?.[currentItemIndex + 1]?.focus();
    }
    if (e.key === 'ArrowUp') {
      if (currentItemIndex === 0) return;
      itemsRef.current?.[currentItemIndex - 1]?.focus();
    }
  };
