import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@components/atoms/Icon';
import { Separator } from '@components/atoms/Separator';
import { CampaignOffersContext } from '@components/organisms/CampaignOffers/CampaignOffers';
import { ProductType } from '@graphql/generated/types';
import { Button, Typography } from '@happypal-tech/design-system';
import { Link } from '@tanstack/react-router';
import { useFormatPrice } from '@utils/hooks/useFormatPrice';

import { CartConfirmationSlideFragment } from './CartConfirmationSlide.generated';

export type CartConfirmationSlideContent = {
  item: CartConfirmationSlideFragment;
  totalCount: number;
};

export const CartConfirmationSlide = () => {
  const { currentCartConfirmationSlide, setCurrentCartConfirmationSlide } =
    useContext(CampaignOffersContext);
  const { t } = useTranslation();
  const formatPrice = useFormatPrice();

  if (!currentCartConfirmationSlide) return null;

  const { item, totalCount } = currentCartConfirmationSlide;
  const brand = item.productVariant.product.brand;

  return (
    <div className="px-6 pt-20">
      <div className="flex gap-2">
        <Icon name="checkCircle" className="text-primary" />
        <Typography type="subtitle" bold>
          {t('cart.cartConfirmationSlide.title')}
        </Typography>
      </div>

      <Separator />

      <Typography type="subtitle" bold>
        {item.productVariant.product.name}
      </Typography>
      <Typography type="body" className="mb-2">
        {brand.name}
      </Typography>
      {item.productVariant.product.type === ProductType.GiftCard && (
        <Typography type="body">
          {t('cart.cartConfirmationSlide.giftCard')}{' '}
          <span className="font-bold">{formatPrice(item.amounts.amount)}.</span>
        </Typography>
      )}
      <Typography type="body" className="text-neutral-dark">
        {t('cart.cartConfirmationSlide.quantity')} : {item.quantity}
      </Typography>

      <div className="mt-10 flex gap-4">
        <Button
          variant="stroke"
          fluid
          onClick={() => setCurrentCartConfirmationSlide(null)}
          className="flex-1"
        >
          {t('cart.cartConfirmationSlide.continue')}
        </Button>
        <Link to="/cart">
          <Button fluid>
            {t('cart.cartConfirmationSlide.goToCart')} ({totalCount})
          </Button>
        </Link>
      </div>
    </div>
  );
};
