import React, { Children, cloneElement, ReactElement } from 'react';

import { Icon, IconType } from '@src/components/atoms/Icon/Icon';
import { DOMInteractionState } from '@src/utils/hooks/useDOMInteractionState';
import { cx } from 'class-variance-authority';

export type FormInputWrapperProps = {
  className?: string;
  state: DOMInteractionState | 'error' | 'warning';
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onMouseEnter?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLDivElement>) => void;
  decoratorLeft?: IconType | ReactElement;
  decoratorRight?: IconType | ReactElement;
  children: ReactElement;
};

export type FormInputWrapperChildProps = Pick<
  FormInputWrapperProps,
  'decoratorLeft' | 'decoratorRight'
>;

export const FormInputWrapper = (props: FormInputWrapperProps) => {
  const {
    className,
    children,
    onClick,
    state,
    decoratorLeft,
    decoratorRight,
    onMouseEnter,
    onMouseLeave,
  } = props;

  return (
    // The rules below are disabled because we use the div as a fallback, not a primary actioner
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={cx(className, 'input-wrapper flex-1', {
        'input-wrapper--disabled': state === 'disabled',
        'input-wrapper--hover': state === 'hovered',
        'input-wrapper--focus': state === 'focused',
        'input-wrapper--error': state === 'error',
        'input-wrapper--warning': state === 'warning',
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      {!!decoratorLeft && (
        <div className="mr-3 flex-none">
          {typeof decoratorLeft === 'string' ? (
            <Icon name={decoratorLeft} />
          ) : (
            decoratorLeft
          )}
        </div>
      )}
      {cloneElement(Children.only(children), {
        className: cx(children.props.className, 'flex-1'),
      })}
      {!!decoratorRight && (
        <div className="ml-3 flex-none">
          {typeof decoratorRight === 'string' ? (
            <Icon name={decoratorRight} />
          ) : (
            decoratorRight
          )}
        </div>
      )}
    </div>
  );
};
