import { TwistedStar } from '@assets/images';

interface SubventionLike {
  subventionSchema?: {
    icon?: {
      file: { url: string };
    } | null;
  } | null;
}

export const getIconFromSubvention = (subvention?: SubventionLike) => {
  return subvention?.subventionSchema?.icon?.file.url || TwistedStar;
};
