import { type RefObject, useLayoutEffect, useState } from 'react';

/** @description This function is only intended to be used on a small amount of core components. */
export function useScrollableParent(element: RefObject<HTMLElement>) {
  const [scrollableParent, setScrollableParent] = useState<HTMLElement | null>(
    null,
  );

  useLayoutEffect(() => {
    if (!element.current) {
      setScrollableParent(null);
      return;
    }

    setScrollableParent(getScrollParent(element.current) ?? null);
  }, [element]);

  return scrollableParent;
}

function getScrollParent(node: HTMLElement) {
  const parents = getParents(node.parentElement);

  for (let i = 0; i < parents.length; i += 1) {
    if (isScrollable(parents[i]!)) {
      return parents[i];
    }
  }

  return (document.scrollingElement as HTMLElement) ?? document.documentElement;
}

function getParents(
  node: HTMLElement | null,
  ancestors: HTMLElement[] = [],
): HTMLElement[] {
  if (node === null || node.parentElement === null) return ancestors;

  return getParents(node.parentElement, ancestors.concat([node]));
}

function isScrollable(node: HTMLElement) {
  return hasOverflow(node);
}

function getStyleValue(node: HTMLElement, prop: string) {
  return getComputedStyle(node, null).getPropertyValue(prop);
}

/**
 * @description Checks if the node has overflow with getStyleValue which returns a explicit and computed values.
 */
function hasOverflow(node: HTMLElement) {
  const regex = /(auto|scroll)/;

  const overflowValues = [
    getStyleValue(node, 'overflow'),
    getStyleValue(node, 'overflow-y'),
    getStyleValue(node, 'overflow-x'),
  ];

  return overflowValues.some((value) => regex.test(value));
}
