import { ReactNode, useMemo } from 'react';
import { InstantSearch } from 'react-instantsearch';

import { env } from '@env';
import { useCampaignIndex } from '@src/features-new/catalog/hooks/useCampaignIndex';
import {
  useCampaignIndexNamesSuspenseQuery,
  useUserAlgoliaSecuredKeyApiSuspenseQuery,
} from '@src/routes/_authenticated-layout/~route.generated';
import algoliasearch from 'algoliasearch/lite';

const FUTURE = { preserveSharedStateOnUnmount: true };

interface InstantSearchWrapperSuggestionsProps {
  children?: ReactNode;
}
export const InstantSearchWrapperSuggestions = ({
  children,
}: InstantSearchWrapperSuggestionsProps) => {
  const { data: securedApiKeyData } =
    useUserAlgoliaSecuredKeyApiSuspenseQuery();

  const searchClient = useMemo(
    () =>
      algoliasearch(
        env.REACT_APP_ALGOLIA_APP_ID,
        securedApiKeyData.userAlgoliaSecuredKeyAPi,
      ),
    [securedApiKeyData.userAlgoliaSecuredKeyAPi],
  );

  const { data } = useCampaignIndexNamesSuspenseQuery();

  const campaignIndex = useCampaignIndex(data.campaignIndexesName);

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={campaignIndex.txt}
      future={FUTURE}
    >
      {children}
    </InstantSearch>
  );
};
