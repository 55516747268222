import {
  type ForwardedRef,
  forwardRef,
  type Key,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useScrollableParent } from '@src/features-new/utils/useScrollableParent';
import { cx } from 'class-variance-authority';

declare module 'react' {
  // eslint-disable-next-line @typescript-eslint/ban-types
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

export interface FlatListProps<
  T extends Record<string, unknown> = Record<string, unknown>,
> {
  className?: string;
  wrapperClassName?: string;
  items: T[];
  header?: React.ReactNode;
  footer?: React.ReactNode;
  renderItem: (item: T, index: number) => React.ReactNode;
  renderItemSkeleton?: (index: number) => React.ReactNode;
  loading?: boolean;
  /**
   * @description Number of items to render if loading is true
   * @default 0
   */
  loadingCount?: number;
  /**
   * @description Used to extract a unique key for a given item at the specified index. Key is used for caching and as the react key to track item re-ordering.
   * @default (item, index) => item.key ?? item.id ?? item.cursor ?? index
   */
  keyExtractor?: (item: T, index: number) => Key;
  /**
   * @description Called once when the scroll position gets within onEndReachedThreshold of the rendered content.
   */
  onEndReached?: (entry: IntersectionObserverEntry) => void;
  /**
   * @description How far from the end (in units of visible length of the list) the bottom edge of the list must be from the end of the content to trigger the onEndReached callback. Thus a value of 0.5 will trigger onEndReached when the end of the content is within half the visible length of the list.
   * @default 0.5
   */
  endReachedThreshold?: number;
  /** @default vertical */
  direction?: 'horizontal' | 'vertical';
}

const FlatListInner = <
  T extends Record<string, unknown> = Record<string, unknown>,
>(
  props: FlatListProps<T>,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const {
    className,
    wrapperClassName,
    items,
    renderItem,
    keyExtractor = defaultExtractor,
    endReachedThreshold = 0.5,
    onEndReached,
    direction = 'vertical',
    loading = false,
    loadingCount = 0,
    renderItemSkeleton,
    header,
    footer,
  } = props;

  const rootRef = useRef<HTMLDivElement>(null);
  const [sentinelRef, setSentinelRef] = useState<HTMLDivElement | null>(null);
  const container = useScrollableParent(rootRef);
  const [containerLength, setContainerLength] = useState(0);

  useEffect(() => {
    if (!container || !sentinelRef) return;

    const handleResize: ResizeObserverCallback = (entries) => {
      if (!entries[0]) return;

      const { height = 0, width = 0 } = entries[0].contentRect;

      setContainerLength(direction === 'horizontal' ? width : height);
    };

    const handleIntersection: IntersectionObserverCallback = (entries) => {
      const entry = entries[0];

      if (entry?.isIntersecting) {
        onEndReached?.(entry);
      }
    };

    const sentinelObserver = new IntersectionObserver(handleIntersection, {
      root: container,
      threshold: 0,
    });

    const containerObserver = new ResizeObserver(handleResize);

    containerObserver.observe(container);
    sentinelObserver.observe(sentinelRef);

    return () => {
      sentinelObserver.disconnect();
      containerObserver.disconnect();
    };
  }, [container, direction, onEndReached, sentinelRef]);

  return (
    <div
      className={cx(className, 'grid', {
        'h-fit grid-flow-row': direction === 'vertical',
        'w-fit grid-flow-col': direction === 'horizontal',
      })}
      ref={ref}
    >
      {header}
      <div
        className={cx('relative grid', wrapperClassName, {
          'h-fit grid-flow-row': direction === 'vertical',
          'w-fit grid-flow-col': direction === 'horizontal',
        })}
        ref={rootRef}
      >
        {items.map((item, index) => {
          const key = keyExtractor(item, index);

          return (
            <div data-key={key} key={key}>
              {renderItem(item, index)}
            </div>
          );
        })}
        {loading &&
          !!renderItemSkeleton &&
          Array.from({ length: loadingCount }).map((_, index) => {
            return (
              <div data-key={index} key={`skeleton_${index}`}>
                {renderItemSkeleton(index)}
              </div>
            );
          })}
        {!loading && (
          <div
            className={cx('absolute')}
            aria-hidden
            style={{
              [direction === 'vertical' ? 'bottom' : 'right']:
                containerLength * Math.max(0, Math.min(1, endReachedThreshold)),
            }}
            ref={setSentinelRef}
          />
        )}
      </div>
      {footer}
    </div>
  );
};

FlatListInner.displayName = 'FlatList';

/**
 * @description A component that handles rendering a list of items and can trigger a callback when the end of the list is reached. Can also be used to render an infinite list by passing a callback to onEndReached that loads more items.
 */
export const FlatList = forwardRef(FlatListInner);

const defaultExtractor: NonNullable<FlatListProps['keyExtractor']> = (
  item,
  index,
) => {
  const keys = ['key', 'id', 'cursor'];

  for (const key of keys) {
    if (
      key in item &&
      (typeof item[key] === 'string' || typeof item[key] === 'number')
    ) {
      return item[key] as Key;
    }
  }

  return index;
};
