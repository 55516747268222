import { createFileRoute, Navigate } from '@tanstack/react-router';

const HappeningsDetailHappeningSplatRoute = () => {
  const { happeningId } = Route.useParams();
  return (
    <Navigate
      to="/happenings/detail/$happeningId"
      params={{ happeningId }}
      replace
    />
  );
};

export const Route = createFileRoute(
  '/_authenticated-layout/happenings/detail/$happeningId/$',
)({
  component: HappeningsDetailHappeningSplatRoute,
});
