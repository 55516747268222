import { useTranslation } from 'react-i18next';

import { Typography } from '@happypal-tech/design-system';
import { Button } from '@src/components/atoms/Button';
import { Picture, SocialNetwork } from '@src/features-new/design-system';
import {
  isBackgroundDark,
  isBackgroundLight,
} from '@src/features-new/ui/background';
import { cx } from 'class-variance-authority';

import { AppFooterLayoutProps } from '../../AppFooter';

type AppFooterLayoutDefaultProps = AppFooterLayoutProps;

export const AppFooterLayoutDefault = (props: AppFooterLayoutDefaultProps) => {
  const { className, layout, company } = props;
  const { t } = useTranslation('footer');

  const identity = company.featureConfig.identityCustomisation;

  const { bgSecondary, bgTertiary } = identity.isEnabled
    ? {
        bgSecondary: identity.colorSecondary ?? '#fff',
        bgTertiary: identity.colorTertiary ?? '#fff',
      }
    : {
        bgSecondary: '#fff',
        bgTertiary: '#eee',
      };

  return (
    <div className={cx(className)}>
      <div
        className={cx('px-4 py-10 lg:px-10', {
          'text-white': isBackgroundDark(bgSecondary),
          'text-neutral-darkest': isBackgroundLight(bgSecondary),
        })}
        style={{
          background: bgSecondary,
        }}
      >
        <div className="mx-auto flex w-full max-w-306 flex-col lg:flex-row lg:items-center lg:justify-center lg:gap-x-16">
          <div>
            {!!company.logoUrl && (
              <div className="mx-auto mb-4 h-20 w-20">
                <Picture
                  className="h-full w-full object-contain"
                  src={company.logoUrl}
                />
              </div>
            )}
            <div className="flex flex-col items-center">
              {layout.callToActions.map((cta) => (
                <a
                  target="_blank"
                  href={cta.href}
                  key={cta.id}
                  className="block"
                  rel="noreferrer noopener"
                >
                  <Button color="primary">{cta.label}</Button>
                </a>
              ))}
            </div>
          </div>
          {layout.socialNetworks.length > 0 && (
            <div>
              <Typography
                type="body"
                className="mt-8 text-center text-primary lg:mt-0 lg:text-left"
              >
                {t('layouts.default.socialNetworks.title', {
                  name: company.displayName,
                })}
              </Typography>
              <Typography
                type="large"
                bold
                className="mt-1 text-center uppercase lg:text-left"
              >
                {t('layouts.default.socialNetworks.subTitle')}
              </Typography>
              <div className="mt-6 flex flex-wrap justify-center gap-5">
                {layout.socialNetworks.map((socialNetwork) => (
                  <a
                    href={socialNetwork.href}
                    target="_blank"
                    key={socialNetwork.id}
                    rel="noreferrer noopener"
                  >
                    <SocialNetwork type={socialNetwork.type} />
                  </a>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className={cx('px-4 py-10 lg:py-6', {
          'text-white': isBackgroundDark(bgTertiary),
          'text-neutral-darkest': isBackgroundLight(bgTertiary),
        })}
        style={{
          background: bgTertiary,
        }}
      >
        <div className="mx-auto flex w-full max-w-306 flex-col items-center gap-8 lg:flex-row lg:justify-center lg:gap-x-8">
          {layout.urls.map((url) => (
            <a
              target="_blank"
              href={url.href}
              key={url.id}
              className="block"
              rel="noreferrer noopener"
            >
              <Typography type="note" className="text-center hover:underline">
                {url.label}
              </Typography>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};
