import { ButtonHTMLAttributes, MouseEventHandler } from 'react';

import { cx } from 'class-variance-authority';

import { Icon } from '../Icon';
import { IconType } from '../Icon/Icon';
import { Loader } from '../Loader';

import './Button.css';

export type ButtonProps = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  'color' | 'aria-disabled' | 'disabled'
> & {
  /** default: plain */
  variant?: 'plain' | 'stroke' | 'ghost';
  /** default: medium */
  size?: 'small' | 'medium';
  /** default: primary */
  color?: 'primary' | 'neutral' | 'danger' | 'inverse' | 'black';
  disabled?: boolean;
  /** Indicates wether or not the button should expand to the full width of its container */
  fluid?: boolean;
  iconLeft?: IconType;
  iconRight?: IconType;
  loading?: boolean;
  iconOnly?: IconType;
};

/**
 * @deprecated
 */
export const Button = (props: ButtonProps) => {
  const {
    children,
    className,
    variant = 'plain',
    size = 'medium',
    color = 'primary',
    disabled,
    loading,
    fluid,
    iconLeft,
    iconRight,
    iconOnly,
    onClick,
    type = 'button',
    ...rest
  } = props;

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (props.type === 'submit' && loading) {
      e.preventDefault();
      return;
    }
    if (disabled || loading) return;
    if (onClick) onClick(e);
  };

  if (iconOnly && (children || iconLeft || iconRight)) return null;

  return (
    <button
      {...rest}
      onClick={handleClick}
      aria-disabled={disabled}
      disabled={disabled}
      type={type}
      className={cx(
        className,
        `button`,
        {
          ['button--disabled']: disabled,
          ['button--loading']: loading,
          ['button--plain']: variant === 'plain',
          ['button--stroke']: variant === 'stroke',
          ['button--ghost']: variant === 'ghost',
          ['button--small']: size === 'small',
          ['button--medium']: size === 'medium',
          ['button--primary']: color === 'primary',
          ['button--neutral']: color === 'neutral',
          ['button--danger']: color === 'danger',
          ['button--inverse']: color === 'inverse',
          ['button--black']: color === 'black',
          ['button--icon-only']: iconOnly,
        },
        {
          'w-full': fluid,
        },
      )}
    >
      {iconLeft && (
        <span className="button__icon button__icon-left">
          <Icon name={iconLeft} size="small" />
        </span>
      )}
      {children && <span className="button__content">{children}</span>}
      {iconOnly && (
        <span className="button__icon">
          <Icon name={iconOnly} size="small" />
        </span>
      )}
      {iconRight && (
        <span className="button__icon button__icon-right">
          <Icon name={iconRight} size="small" />
        </span>
      )}
      <span className="button__loader">
        {loading && (
          <Loader
            size="medium"
            className={cx({
              ['loader--black']: color === 'black',
              ['loader--danger']: color === 'danger',
              ['loader--inverse']: color === 'inverse',
              ['loader--primary']: color === 'primary',
              ['loader--neutral']: color === 'neutral',
              ['loader--stroke']: variant === 'stroke',
            })}
          />
        )}
      </span>
    </button>
  );
};
