import React, {
  ChangeEventHandler,
  forwardRef,
  InputHTMLAttributes,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@components/atoms/Icon';
import { Tooltip } from '@components/atoms/Tooltip';
import { Typography } from '@happypal-tech/design-system';
import { useDOMInteractionState } from '@src/utils/hooks/useDOMInteractionState';
import { cx } from 'class-variance-authority';
import { debounce } from 'radash';

import {
  FormInputWrapper,
  FormInputWrapperChildProps,
} from '../FormInputWrapper/FormInputWrapper';
import { FormItemChildProps } from '../FormItem/FormItem';

export type FormInputTextProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'type' | 'aria-disabled' | 'disabled'
> &
  FormInputWrapperChildProps &
  Omit<FormItemChildProps, 'onChange'> & {
    type?: 'text' | 'password' | 'email' | 'number' | 'url';
    className?: string;
    inputClassName?: string;
    disabled?: boolean;
    /** Debounce in ms for onChange event */
    debounceDelay?: number;
    /** Override decoratorRIght */
    characterLimit?: number;
  };

export const FormInputText = forwardRef<HTMLInputElement, FormInputTextProps>(
  (props, ref) => {
    const {
      className,
      inputClassName,
      type = 'text',
      decoratorLeft,
      decoratorRight,
      disabled,
      status,
      onBlur,
      onFocus,
      onMouseEnter,
      onMouseLeave,
      characterLimit,
      onChange,
      debounceDelay,
      ...rest
    } = props;

    const { t } = useTranslation('forms');

    const inputRef = useRef<HTMLInputElement>(null);
    const [innerValue, setInnerValue] = useState('');
    const [visible, setVisible] = useState(false);

    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

    const { state, handlers } = useDOMInteractionState({
      onBlur,
      onFocus,
      onMouseEnter,
      onMouseLeave,
      disabled,
      status,
    });

    const handleOuterClick = () => {
      if (!inputRef.current || disabled) return;

      inputRef.current.focus();
    };

    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      setInnerValue(e.target.value);
      if (onChange) onChange(e);
    };

    const handleChangeDebounced = debounceDelay
      ? debounce({ delay: debounceDelay }, handleChange)
      : handleChange;

    useEffect(() => {
      if (!inputRef.current) return;

      setInnerValue(inputRef.current.value);
    }, []);

    const PasswordButton = (
      <Tooltip
        id="password"
        label={visible ? t('password.hide') : t('password.show')}
        placement="top"
      >
        <button
          type="button"
          className="flex items-center"
          onClick={(e) => {
            e.preventDefault();
            setVisible(!visible);
          }}
        >
          <Icon
            name={visible ? 'eyeOff' : 'eyeOn'}
            className="text-neutral-dark"
            size="small"
          />
        </button>
      </Tooltip>
    );

    return (
      <FormInputWrapper
        className={className}
        state={state}
        onClick={handleOuterClick}
        onMouseEnter={handlers.onMouseEnter}
        onMouseLeave={handlers.onMouseLeave}
        decoratorLeft={decoratorLeft}
        decoratorRight={
          typeof characterLimit === 'number' ? (
            <Typography
              className={cx(
                'transition-colors',
                [
                  disabled && 'text-neutral-disabled',
                  innerValue.length > characterLimit && 'text-error',
                  ['focused', 'hovered'].includes(state) && 'text-neutral-dark',
                  'text-neutral',
                ].filter(Boolean)[0],
              )}
              type="body"
              asChild
            >
              <span>{characterLimit - innerValue.length}</span>
            </Typography>
          ) : type === 'password' ? (
            PasswordButton
          ) : (
            decoratorRight
          )
        }
      >
        <input
          className={cx(
            inputClassName,
            'w-full flex-1 cursor-text bg-transparent text-sm leading-6',
            {
              'text-neutral-darkest placeholder-neutral': !disabled,
              'text-neutral-disabled placeholder-neutral-disabled': disabled,
            },
          )}
          type={visible ? 'text' : type}
          disabled={disabled}
          aria-disabled={disabled}
          ref={inputRef}
          onBlur={() => {
            handlers.onBlur();
          }}
          onFocus={handlers.onFocus}
          onChange={handleChangeDebounced}
          {...rest}
        />
      </FormInputWrapper>
    );
  },
);
