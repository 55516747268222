import { z } from '@happypal-tech/design-system';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_authenticated-layout/cart/')({
  validateSearch: z.object({
    proceed: z
      .boolean()
      .optional()
      .catch(() => undefined),
  }),
});
