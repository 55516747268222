import { cx } from 'class-variance-authority';

import { CampaignCategoriesFragment } from './CampaignCategories.generated';

type CampaignCategoriesProps = {
  campaign: CampaignCategoriesFragment;
  className?: string;
};

export function CampaignCategories(props: CampaignCategoriesProps) {
  const { campaign, className } = props;

  const categoriesCount = campaign?.parentCategoriesPagination.totalCount || 0;

  let headline =
    campaign?.parentCategoriesPagination?.edges
      .slice(0, 3)
      .map((edge) => edge.node.name)
      .join(', ') || '';

  if (categoriesCount > 3) {
    headline += ` (+${categoriesCount - 3})`;
  }

  return (
    <span className={cx('uppercase tracking-widest', className)}>
      {headline}
    </span>
  );
}
