import { useTranslation } from 'react-i18next';

import { Button, Spin } from '@happypal-tech/design-system';
import { EmptyState } from '@src/components/molecules/EmptyState';
import { createFileRoute, Link, notFound } from '@tanstack/react-router';

import {
  AdminDashboardGatheringIdRouteDocument,
  AdminDashboardGatheringIdRouteQuery,
  AdminDashboardGatheringIdRouteQueryVariables,
} from './~route.generated';

export const Route = createFileRoute(
  '/_authenticated-layout/admin/gathering/$gatheringId',
)({
  loader: async ({ context, params }) => {
    const { gatheringId } = params;
    const res = await context.apolloClient.query<
      AdminDashboardGatheringIdRouteQuery,
      AdminDashboardGatheringIdRouteQueryVariables
    >({
      query: AdminDashboardGatheringIdRouteDocument,
      variables: { gatheringId },
      notifyOnNetworkStatusChange: true,
    });

    const gathering = res.data.gathering;

    if (!gathering) {
      throw notFound();
    }

    return { gathering };
  },
  pendingComponent: () => <Spin className="m-auto" />,
  notFoundComponent: () => <AdminGatheringGatheringIdNotFoundComponent />,
});

const AdminGatheringGatheringIdNotFoundComponent = () => {
  const { t } = useTranslation('gathering', {
    keyPrefix: 'views',
  });

  return (
    //TODO: Upgrade wording after validation
    //Issue of EmptyState position in mobile du to PagePaddingContainer
    <EmptyState
      title={t('adminEventIdView.title')}
      message={t('adminEventIdView.message')}
      className="h-full flex-1 items-center"
    >
      <Link to="/admin/gathering/dashboard/">
        <Button type="button" iconRight="ArrowRightOutline">
          {t('adminEventIdView.buttonTitle')}
        </Button>
      </Link>
    </EmptyState>
  );
};
