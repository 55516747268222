import { NotificationType } from '@src/graphql/generated/types';
import { TFunction } from 'i18next';

export function getNotificationI18n(
  t: TFunction,
): Record<NotificationType, { name: string; content: string }> {
  return {
    [NotificationType.ArticlePublished]: {
      name: t('notifications.notificationCenter.type.ARTICLE_PUBLISHED.name'),
      content: t(
        'notifications.notificationCenter.type.ARTICLE_PUBLISHED.content',
      ),
    },
    [NotificationType.BeneficiaryAccepted]: {
      name: t(
        'notifications.notificationCenter.type.BENEFICIARY_ACCEPTED.name',
      ),
      content: t(
        'notifications.notificationCenter.type.BENEFICIARY_ACCEPTED.content',
      ),
    },
    [NotificationType.BeneficiaryDeleted]: {
      name: t('notifications.notificationCenter.type.BENEFICIARY_DELETED.name'),
      content: t(
        'notifications.notificationCenter.type.BENEFICIARY_DELETED.content',
      ),
    },
    [NotificationType.BeneficiaryRejected]: {
      name: t(
        'notifications.notificationCenter.type.BENEFICIARY_REJECTED.name',
      ),
      content: t(
        'notifications.notificationCenter.type.BENEFICIARY_REJECTED.content',
      ),
    },
    [NotificationType.BeneficiaryRejectedFollowUp]: {
      name: t(
        'notifications.notificationCenter.type.BENEFICIARY_REJECTED_FOLLOW_UP.name',
      ),
      content: t(
        'notifications.notificationCenter.type.BENEFICIARY_REJECTED_FOLLOW_UP.content',
      ),
    },
    [NotificationType.DocumentPublished]: {
      name: t('notifications.notificationCenter.type.DOCUMENT_PUBLISHED.name'),
      content: t(
        'notifications.notificationCenter.type.DOCUMENT_PUBLISHED.content',
      ),
    },
    [NotificationType.MangopayBankwireDirectPayInUpdated]: {
      name: t(
        'notifications.notificationCenter.type.MANGOPAY_BANKWIRE_DIRECT_PAY_IN_UPDATED.name',
      ),
      content: t(
        'notifications.notificationCenter.type.MANGOPAY_BANKWIRE_DIRECT_PAY_IN_UPDATED.content',
      ),
    },
    [NotificationType.MangopayTransferFailed]: {
      name: t(
        'notifications.notificationCenter.type.MANGOPAY_TRANSFER_FAILED.name',
      ),
      content: t(
        'notifications.notificationCenter.type.MANGOPAY_TRANSFER_FAILED.content',
      ),
    },
    [NotificationType.OfferPublished]: {
      name: t('notifications.notificationCenter.type.OFFER_PUBLISHED.name'),
      content: t(
        'notifications.notificationCenter.type.OFFER_PUBLISHED.content',
      ),
    },
    [NotificationType.PublicationPublished]: {
      name: t(
        'notifications.notificationCenter.type.PUBLICATION_PUBLISHED.name',
      ),
      content: t(
        'notifications.notificationCenter.type.PUBLICATION_PUBLISHED.content',
      ),
    },
    [NotificationType.SubventionInvoiceCreated]: {
      name: t(
        'notifications.notificationCenter.type.SUBVENTION_INVOICE_CREATED.name',
      ),
      content: t(
        'notifications.notificationCenter.type.SUBVENTION_INVOICE_CREATED.content',
      ),
    },
    [NotificationType.SubventionPublished]: {
      name: t(
        'notifications.notificationCenter.type.SUBVENTION_PUBLISHED.name',
      ),
      content: t(
        'notifications.notificationCenter.type.SUBVENTION_PUBLISHED.content',
      ),
    },
    [NotificationType.UserEmailChanged]: {
      name: t('notifications.notificationCenter.type.USER_EMAIL_CHANGED.name'),
      content: t(
        'notifications.notificationCenter.type.USER_EMAIL_CHANGED.content',
      ),
    },
  };
}
