import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';

import { InputSelect } from '@components/atoms/InputSelect';
import { Option } from '@components/atoms/InputSelect/InputSelect';
import { MultipleVariantsFragment } from '@components/organisms/OfferProductAction/MultipleVariants/MultipleVariants.generated';
import { useFormatPrice } from '@utils/hooks/useFormatPrice';

import { OfferProductVariantFragment } from '../OfferProductAction.generated';

type MultipleVariantsProps = {
  defaultVariant: MultipleVariantsFragment;
  variants: MultipleVariantsFragment[];
  onVariantChange: (variant: OfferProductVariantFragment) => void;
  hasExpired: boolean;
};

export const MultipleVariants = ({
  defaultVariant,
  variants,
  onVariantChange,
  hasExpired,
}: MultipleVariantsProps) => {
  const { t } = useTranslation();
  const formatPrice = useFormatPrice();

  function formatVariantAsOption(variant: MultipleVariantsFragment): Option {
    const { id, priceCurrency, priceValue } = variant;
    return {
      label: formatPrice(priceValue, priceCurrency),
      value: id,
    };
  }

  const handleVariantChange = (newValue: SingleValue<Option>) => {
    const newVariantSelected = variants.find(
      (node) => node.id === newValue?.value,
    );

    if (!newVariantSelected) return;

    onVariantChange(newVariantSelected as OfferProductVariantFragment);
  };

  return (
    <div className="flex flex-col">
      <span className="mb-4 text-sm font-medium text-black">
        {t(
          'campaignDetails.offers.offer.product.select.valueBeforeDiscount',
          'Valeur avant réduction',
        )}
      </span>
      <InputSelect
        defaultValue={formatVariantAsOption(defaultVariant)}
        options={variants.map(formatVariantAsOption)}
        onChange={handleVariantChange}
        disabled={hasExpired}
      />
    </div>
  );
};
