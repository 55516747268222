import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Notification } from '@components/molecules/Notification';
import { GQLOperations } from '@graphql/generated/types';
import { Text } from '@src/components/atoms/Text';
import { SkeletonMiniNotification } from '@src/components/molecules/Notification/Notification';
import { Link } from '@tanstack/react-router';
import { useOuterClick } from '@utils/hooks';

import { extractNotificationData } from '../notificationsHelper';
import {
  useMarkNotificationsAsReadMutation,
  useViewerNotificationsQuery,
} from '../ViewerNotifications.generated';

interface MiniNotificationCenterProps {
  onClickOutside: (e: CustomEvent) => void;
  onSeeAllClick: () => void;
  onNotificationClick: (notificationId: string) => void;
}

export const MiniNotificationCenter = ({
  onClickOutside,
  onNotificationClick,
  onSeeAllClick,
}: MiniNotificationCenterProps) => {
  const { t } = useTranslation();
  const [markNotificationsAsReadMutation] =
    useMarkNotificationsAsReadMutation();
  const innerRef = useOuterClick((e) => onClickOutside(e));

  const { data, loading } = useViewerNotificationsQuery({
    variables: { take: 4 },
    errorPolicy: 'all',
  });

  useEffect(() => {
    markNotificationsAsReadMutation({
      refetchQueries: [GQLOperations.Query.ViewerNotifications],
    });
  }, [markNotificationsAsReadMutation]);

  const hasNotifications = !!data?.viewer?.notificationsPagination.totalCount;

  return (
    <div
      className="absolute -right-1 top-11 flex w-94 flex-col rounded-xl bg-white text-neutral-darkest shadow-medium"
      ref={innerRef}
    >
      {!data &&
        loading &&
        [...Array(3)].map((item, i) => (
          <div key={i} className="mx-5 mt-4">
            <SkeletonMiniNotification />
          </div>
        ))}
      {data?.viewer?.notificationsPagination.nodes
        .filter((notification) => !!notification.target)
        .slice(0, 4)
        .map(extractNotificationData)
        .map((notification) => (
          <Notification
            id={notification.id}
            key={`mini-notification-${notification.id}`}
            from={notification.from}
            type={notification.type}
            picture={
              notification.picture || data?.viewer?.company?.logo || undefined
            }
            title={notification.title}
            content={notification.content}
            notification={notification}
            read={!!notification.readAt}
            createdAt={notification.createdAt}
            onNotificationClick={onNotificationClick}
          />
        ))}

      {!loading && hasNotifications && (
        <Link to="/notifications" className="text-center">
          <button
            onClick={onSeeAllClick}
            className="block w-full p-5 text-center text-tiny hover:text-black-pure"
          >
            {t('notifications.notificationCenter.seeAll', 'Voir tout')}
          </button>
        </Link>
      )}

      {!loading && !hasNotifications && (
        <span className="w-full p-5 text-center">
          <Text type="bodyRegularText">
            {t(
              'notifications.notificationCenter.noNotification',
              'Aucune notification',
            )}
          </Text>
        </span>
      )}
    </div>
  );
};
