import { ComponentProps, ReactNode } from 'react';

import * as RxAccordion from '@radix-ui/react-accordion';
import { Icon } from '@src/components/atoms/Icon';
import { cn } from '@src/features-new/ui/cn';
import { cx } from 'class-variance-authority';

import './Accordion.css';

export type AccordionProps = ComponentProps<typeof RxAccordion.Root>;

export const Accordion = (props: AccordionProps) => {
  return <RxAccordion.Root {...props} />;
};

// Content
export interface AccordionContentProps {
  children: ReactNode;
  className?: string;
}

const AccordionContent = (props: AccordionContentProps) => {
  const { children, className } = props;

  return (
    <RxAccordion.Content className={cx(className, 'AccordionContent')}>
      {/* Need this is else animation is broken */}
      <div>{children}</div>
    </RxAccordion.Content>
  );
};

// Item
export type AccordionItemProps = Pick<
  ComponentProps<typeof RxAccordion.Item>,
  'children' | 'value'
>;

const AccordionItem = (props: AccordionItemProps) => {
  const { ...rest } = props;

  return (
    <RxAccordion.Item
      {...rest}
      className={
        'flex w-full flex-col border-b-8 md:border-b border-neutral-lighter first:pt-0 last:border-b-0'
      }
    />
  );
};

// Trigger
export interface AccordionTriggerProps {
  children?: ReactNode;
  className?: string;
  subClassName?: string;
}

const AccordionTrigger = (props: AccordionTriggerProps) => {
  const { children, className, subClassName } = props;

  return (
    <RxAccordion.AccordionTrigger
      className={cn(
        'group flex items-center gap-2 font-body text-base font-bold',
        className,
      )}
    >
      {children && <span className={subClassName}>{children}</span>}
      <Icon
        name="chevronUp"
        size="small"
        className="transition-transform group-data-[state='closed']:-rotate-180"
      />
    </RxAccordion.AccordionTrigger>
  );
};

Accordion.Item = AccordionItem;
Accordion.Trigger = AccordionTrigger;
Accordion.Content = AccordionContent;
Accordion.Header = RxAccordion.Header;
