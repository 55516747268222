import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Dinero, { Currency } from 'dinero.js';

export function useFormatPrice(currency?: string) {
  const {
    i18n: { language: locale },
  } = useTranslation();
  // FIXME: getCurrency was used but default Dinero currency is 'usd'
  // const currentCurrency = currency || getCurrency(currentLocale);
  const currentCurrency = currency || 'EUR';

  return useCallback(
    (amount: number, overrideCurency?: string, withDecimals?: boolean) =>
      Dinero({
        amount,
        currency:
          (overrideCurency as Currency) || (currentCurrency as Currency),
      })
        .setLocale(locale)
        .toFormat(amount % 100 === 0 && !withDecimals ? '$0,0' : '$0,0.00'),
    [currentCurrency, locale],
  );
}
