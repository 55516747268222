import { Spin } from '@happypal-tech/design-system';
import {
  AccountInfoRouteDocument,
  AccountInfoRouteQuery,
  AccountInfoRouteQueryVariables,
} from '@src/routes/_authenticated-layout/account/index/~route.generated';
import { createFileRoute, notFound } from '@tanstack/react-router';

export const Route = createFileRoute('/_authenticated-layout/account/')({
  loader: async ({ context }) => {
    const { data } = await context.apolloClient.query<
      AccountInfoRouteQuery,
      AccountInfoRouteQueryVariables
    >({ query: AccountInfoRouteDocument });

    const { viewer } = data;

    if (!viewer) {
      throw notFound();
    }

    return { viewer };
  },
  pendingComponent: () => <Spin />,
});
