import { HTMLAttributes } from 'react';

import { cx } from 'class-variance-authority';

export type TextPossibleTypes =
  | 'custom' // Should be used in very rare cases
  | 'displayTitleXL'
  | 'displayTitleL'
  | 'displayTitleM'
  | 'displayTitleS'
  | 'displaySubtitle'
  | 'displayButton'
  | 'bodySubtitle'
  | 'bodyLargeText'
  | 'bodyCategories'
  | 'bodyRegularText'
  | 'bodySmallTextBold'
  | 'bodySmallText';

export type TextProps = {
  children: React.ReactNode;
  type: TextPossibleTypes;
  className?: HTMLAttributes<HTMLImageElement>['className'];
  whitespacePreLine?: boolean;
};

type TagTypes = 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5';

/**
 * @deprecated
 */
export const Text = ({
  children,
  type,
  whitespacePreLine,
  className: additionalStyle,
}: TextProps) => {
  let TAG: TagTypes = 'p';
  let className;

  switch (type) {
    case 'displayTitleXL':
      TAG = 'h1';
      className = 'font-display font-bold md:text-5xl text-2xl';
      break;

    case 'displayTitleL':
      TAG = 'h2';
      className = 'font-display font-bold text-2xl';
      break;

    case 'displayTitleM':
      TAG = 'h3';
      className = 'font-display font-bold md:text-2xl text-1.25xl';
      break;

    case 'displayTitleS':
      TAG = 'h4';
      className = 'font-display font-bold md:text-slg text-lg';
      break;

    case 'displaySubtitle':
      TAG = 'h5';
      className = 'font-display font-bold text-md';
      break;

    case 'displayButton':
      TAG = 'p';
      className = 'font-display font-semibold text-sm';
      break;

    case 'bodySubtitle':
      TAG = 'p';
      className = 'font-body font-bold text-md';
      break;

    case 'bodyLargeText':
      TAG = 'p';
      className = 'font-body font-medium text-sm';
      break;

    case 'bodyCategories':
      TAG = 'p';
      className = 'font-body font-medium md:text-xs text-2xxs';
      break;

    case 'bodyRegularText':
      TAG = 'p';
      className = 'font-body text-2tiny';
      break;

    case 'bodySmallTextBold':
      TAG = 'p';
      className = 'font-body font-semibold text-tiny';
      break;

    case 'bodySmallText':
      TAG = 'p';
      className = 'font-body md:text-tiny text-2xs';
      break;

    case 'custom':
    default:
      TAG = 'p';
      break;
  }
  return (
    <TAG
      className={cx(className, additionalStyle, {
        'whitespace-pre-line': whitespacePreLine,
      })}
    >
      {children}
    </TAG>
  );
};
