import { Loader } from '@src/components/atoms/Loader';
import {
  AdminHappeningDetailPageDocument,
  AdminHappeningDetailPageQuery,
  AdminHappeningDetailPageQueryVariables,
} from '@src/features/happenings/pages/AdminHappeningDetailPage/AdminHappeningDetailPage.generated';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/_authenticated-layout/admin/happenings/detail/$happeningId/',
)({
  loader: async ({ context, params }) => {
    const { happeningId } = params;
    const res = await context.apolloClient.query<
      AdminHappeningDetailPageQuery,
      AdminHappeningDetailPageQueryVariables
    >({
      query: AdminHappeningDetailPageDocument,
      variables: {
        happeningId,
      },
    });

    return { happening: res.data.happening };
  },
  pendingComponent: () => <Loader />,
});
