import { useEffect } from 'react';

import { App, URLOpenListenerEvent } from '@capacitor/app';
import { useRouter } from '@tanstack/react-router';
import { isMobileNative } from '@utils/isMobileNative';
import Url from 'url-parse';

export const useListenToDeeplinks = () => {
  const router = useRouter();

  useEffect(() => {
    if (!isMobileNative()) {
      return;
    }
    const subscription = App.addListener(
      'appUrlOpen',
      (event: URLOpenListenerEvent) => {
        // URL parses url with custom protocols differently on ios and android
        // using url-parse for consistency
        const url = new Url(event.url);
        const slug = url.pathname;
        if (slug) {
          router.history.push(slug);
        }
      },
    );
    return () => {
      subscription.remove();
    };
  }, [router]);
};
