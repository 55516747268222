import { useRef, useState } from 'react';

import { NotificationButton } from '@components/molecules/NotificationButton';
import { MiniNotificationCenter } from '@components/organisms/Notifications/MiniNotificationCenter';

import { MiniNotificationCenterButtonFragment } from './MiniNotificationCenterButton.generated';

type MiniNotificationCenterButtonProps = {
  viewer?: MiniNotificationCenterButtonFragment;
  isBackgroundDark?: boolean;
};

export const MiniNotificationCenterButton = ({
  viewer,
  isBackgroundDark,
}: MiniNotificationCenterButtonProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const notifButtonRef = useRef<HTMLButtonElement>(null);

  const onToggle = () => {
    setIsOpen((prev) => !prev);
  };

  const onClickOutside = (e: CustomEvent) => {
    const target = e.target !== null ? (e.target as HTMLElement) : null;
    const isNotificationButton =
      target?.closest('button') === notifButtonRef.current;
    if (isNotificationButton) {
      return;
    }
    setIsOpen((prev) => !prev);
  };

  return (
    <div className="relative z-header">
      <NotificationButton
        ref={notifButtonRef}
        isBackgroundDark={isBackgroundDark}
        viewer={viewer}
        onClick={onToggle}
      />
      {isOpen && (
        <MiniNotificationCenter
          onClickOutside={onClickOutside}
          onNotificationClick={onToggle}
          onSeeAllClick={onToggle}
        />
      )}
    </div>
  );
};
