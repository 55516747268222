import { ApolloLink } from '@apollo/client';
import * as Sentry from '@sentry/react';

export const sentryLink = new ApolloLink((operation, forward) => {
  const observable = forward(operation);
  const transaction = Sentry.getCurrentHub().getScope()?.getTransaction();
  const span = transaction?.startChild({
    op: 'graphql',
    description: operation.operationName,
  });
  operation.setContext({
    headers: {
      ['sentry-trace']: span?.toTraceparent(),
    },
  });

  return observable.map((result) => {
    span?.finish();

    return result;
  });
});
