import { createContext, ReactNode, useContext, useEffect } from 'react';

import { configureScope } from '@sentry/react';

import {
  AuthenticatedUserFragment,
  SelectedCompanyFragment,
} from './authenticated-context.generated';

type AuthenticatedContextProviderProps = SelectedCompanyContextType & {
  children: ReactNode;
};

type SelectedCompanyContextType = {
  company: SelectedCompanyFragment;
  viewer: AuthenticatedUserFragment;
};

const AuthenticatedContext = createContext<SelectedCompanyContextType | null>(
  null,
);

export type AuthenticatedUser = AuthenticatedUserFragment;
export type SelectedCompany = SelectedCompanyFragment;

export function AuthenticatedContextProvider(
  props: AuthenticatedContextProviderProps,
) {
  const { children, company, viewer } = props;

  useEffect(() => {
    configureScope((scope) => {
      if (viewer) {
        scope.setUser({ id: viewer.id, email: viewer.email });
      } else {
        scope.setUser(null);
      }
    });
  }, [viewer]);

  return (
    <AuthenticatedContext.Provider value={{ company, viewer }}>
      {children}
    </AuthenticatedContext.Provider>
  );
}

export function useSelectedCompany() {
  const context = useContext(AuthenticatedContext);

  if (!context) {
    throw new Error(
      'useSelectedCompany must be used within AuthenticatedLayout',
    );
  }

  return context.company;
}

export function useAuthenticatedUser() {
  const context = useContext(AuthenticatedContext);

  if (!context) {
    throw new Error(
      'useAuthenticatedUser must be used within AuthenticatedLayout',
    );
  }

  return context.viewer;
}

export function useViewer() {
  return useContext(AuthenticatedContext)?.viewer;
}
