import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@happypal-tech/design-system';
import { BadgeFilter } from '@src/features-new/catalog/components/BadgeFilter';
import { FiltersDrawer } from '@src/features-new/catalog/components/FiltersDrawer/FiltersDrawer';
import { useCountSearchRefinements } from '@src/features-new/catalog/hooks/useCountSearchRefinements';
import { cx } from 'class-variance-authority';

interface FiltersButtonProps {
  className?: string;
  children: ReactNode;
}

export const FiltersButton = ({ className, children }: FiltersButtonProps) => {
  const { t } = useTranslation('catalog', {
    keyPrefix: 'components.CatalogFiltersDrawer',
  });
  const [drawerOpen, setDrawerOpen] = useState(false);

  const count = useCountSearchRefinements();

  return (
    <div className={cx('relative w-fit h-fit', className)}>
      <Button
        variant="stroke"
        color={count === 0 ? 'neutral' : 'primary'}
        icon="FilterOutline"
        onClick={() => {
          setDrawerOpen(true);
        }}
      />
      <BadgeFilter showDot={count !== 0} />

      <FiltersDrawer
        opened={drawerOpen}
        onOpenChange={(nextOpen) => {
          setDrawerOpen(nextOpen);
        }}
      >
        {children}
        <Button
          className="mt-4"
          fluid
          color="neutral"
          variant="stroke"
          onClick={() => {
            setDrawerOpen(false);
          }}
        >
          {t('close')}
        </Button>
      </FiltersDrawer>
    </div>
  );
};
