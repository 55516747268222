import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

import { cn } from '@src/features-new/ui/cn';
import { DropdownMenuItem } from '@src/features-new/ui/DropdownMenu/DropdownMenu';

export const AppHeaderProfileMenuItem = forwardRef<
  ElementRef<typeof DropdownMenuItem>,
  ComponentPropsWithoutRef<typeof DropdownMenuItem>
>((props, ref) => {
  const { className, disabled, ...rest } = props;

  return (
    <DropdownMenuItem
      ref={ref}
      className={cn(
        'px-3 py-2 gap-3 text-sm/6 font-body font-semibold text-neutral-darkest',
        { 'cursor-pointer': !disabled },
        className,
      )}
      disabled={disabled}
      {...rest}
    />
  );
});
