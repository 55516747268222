import { Spin } from '@happypal-tech/design-system';
import { createFileRoute, redirect } from '@tanstack/react-router';

import {
  ActiveSurveyViewDocument,
  ActiveSurveyViewQuery,
  ActiveSurveyViewQueryVariables,
} from './~route.generated';

export const Route = createFileRoute('/_authenticated-layout/survey')({
  loader: async ({ context }) => {
    const { data } = await context.apolloClient.query<
      ActiveSurveyViewQuery,
      ActiveSurveyViewQueryVariables
    >({
      query: ActiveSurveyViewDocument,
    });

    const survey = data.viewer?.company?.activeSurvey;

    if (!survey) throw redirect({ to: '/' });

    return { survey };
  },
  pendingComponent: () => <Spin />,
});
