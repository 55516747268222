import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { RedBox } from '@assets/images';
import { Typography } from '@happypal-tech/design-system';
import { Picture } from '@src/features-new/design-system';
import { cx } from 'class-variance-authority';

export type EmptyStateProps = {
  imgSrc?: string;
  title?: string;
  message?: ReactNode;
  action?: ReactNode;
  className?: string;
  buttonTitle?: string;
  onClick?: () => void;
  children?: ReactNode;
};

export const EmptyState = (props: EmptyStateProps) => {
  const { title, message, imgSrc, className, action, children } = props;
  const { t } = useTranslation('common');

  return (
    <div
      className={cx(
        className,
        'flex-start m-auto flex justify-center gap-2 px-6',
      )}
    >
      <div className="flex flex-col items-center gap-8">
        <div className="flex flex-col items-center gap-2 text-center text-neutral-darkest">
          <div className="h-16 w-16">
            <Picture
              src={imgSrc || RedBox}
              alt={t('emptyState.pictureDescription')}
              className="h-full w-full object-contain"
            />
          </div>
          <Typography bold type="heading-3" className="mt-2 max-w-[720px]">
            {title || t('emptyState.title')}
          </Typography>
          <Typography type="body" className="max-w-[480px]">
            {message || t('emptyState.message')}
          </Typography>
        </div>
        {action && <div className="mt-8">{action}</div>}
        {children}
      </div>
    </div>
  );
};
