import { useTranslation } from 'react-i18next';

import { Icon, Typography } from '@happypal-tech/design-system';
import { useAnalytics } from '@hooks/use-analytics';
import {
  ButtonImage,
  FolderImage,
  HeartImage2HD,
  LockImage,
  SettingsImage,
} from '@src/assets/images';
import useLogout from '@src/features/auth/hooks/useLogout';
import { VisitMyFavoritesOrigin } from '@src/features-new/analytics/constants/track-event.constant';
import { AppHeaderProfileMenuImage } from '@src/features-new/core/AppHeader/AppHeaderProfile/MenuImage';
import { AppHeaderProfileMenuItem } from '@src/features-new/core/AppHeader/AppHeaderProfile/MenuItem';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '@src/features-new/ui/Avatar/Avatar';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@src/features-new/ui/DropdownMenu/DropdownMenu';
import { Link, useMatchRoute } from '@tanstack/react-router';

import { AppHeaderProfileFragment } from './AppHeaderProfile.generated';

type AppHeaderProfileProps = {
  className?: string;
  viewer: AppHeaderProfileFragment;
};

export const AppHeaderProfile = (props: AppHeaderProfileProps) => {
  const { className, viewer } = props;
  const { t } = useTranslation();
  const matchRoute = useMatchRoute();
  const isOnFavoritePage = Boolean(matchRoute({ to: '/favorites/' }));

  const { track } = useAnalytics();
  const logout = useLogout();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className={className}>
        <div className="flex gap-3 items-center">
          <Avatar className="h-11 w-11 rounded-lg">
            {!!viewer.avatarURL && (
              <AvatarImage
                src={viewer.avatarURL}
                alt={`${viewer.firstName} ${viewer.lastName}`}
              />
            )}
            <AvatarFallback>
              {viewer.firstName[0]}
              {viewer.lastName[0]}
            </AvatarFallback>
          </Avatar>
          <Typography type="body" semibold>
            {viewer.firstName}
          </Typography>
          <Icon name="ChevronDownOutline" size={16} />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="end"
        side="bottom"
        className="min-w-80 p-2 rounded-xl"
      >
        <AppHeaderProfileMenuItem
          textValue={t('navigation.header.menu.favorites')}
          asChild
        >
          <Link
            to="/favorites"
            onClick={() => {
              if (!isOnFavoritePage) {
                track({
                  type: 'visit my favorites page',
                  payload: {
                    visit_my_favorites_origin: VisitMyFavoritesOrigin.userMenu,
                  },
                });
              }
            }}
          >
            <AppHeaderProfileMenuImage src={HeartImage2HD} />
            {t('navigation.header.menu.favorites')}
          </Link>
        </AppHeaderProfileMenuItem>
        <AppHeaderProfileMenuItem
          textValue={t('navigation.header.menu.orders')}
          asChild
        >
          <Link to="/orders">
            <AppHeaderProfileMenuImage src={FolderImage} />
            {t('navigation.header.menu.orders')}
          </Link>
        </AppHeaderProfileMenuItem>
        <AppHeaderProfileMenuItem
          textValue={t('navigation.header.menu.account')}
          asChild
        >
          <Link to="/account/">
            <AppHeaderProfileMenuImage src={LockImage} />
            {t('navigation.header.menu.account')}
          </Link>
        </AppHeaderProfileMenuItem>
        <AppHeaderProfileMenuItem
          textValue={t('navigation.header.menu.settings')}
          asChild
        >
          <Link to="/settings">
            <AppHeaderProfileMenuImage src={SettingsImage} />
            {t('navigation.header.menu.settings')}
          </Link>
        </AppHeaderProfileMenuItem>
        <AppHeaderProfileMenuItem
          textValue={t('navigation.header.menu.logOut')}
          onSelect={() => logout()}
          className="text-error"
        >
          <AppHeaderProfileMenuImage src={ButtonImage} />
          {t('navigation.header.menu.logOut')}
        </AppHeaderProfileMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
