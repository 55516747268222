import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

export const Route = createFileRoute(
  '/_authenticated-layout/admin/subventions/funnel',
)({
  validateSearch: z.object({
    step: z
      .number()
      .optional()
      .catch(() => undefined),
  }),
});
