import { ChangeEvent, FocusEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { InputNumber } from '@components/atoms/InputNumber';
import { CustomVariantFragment } from '@components/organisms/OfferProductAction/CustomVariant/CustomVariant.generated';
import { useFormatPrice } from '@utils/hooks/useFormatPrice';
import { Currency } from 'dinero.js';

const PRICE_SCALE = 100;

type CustomVariantProps = {
  productVariant: CustomVariantFragment;
  handleAmountChange: (newAmt: number) => void;
  isOutOfStock: boolean;
  hasExpired: boolean;
};

export const CustomVariant = ({
  productVariant,
  handleAmountChange,
  isOutOfStock,
  hasExpired,
}: CustomVariantProps) => {
  const { t } = useTranslation();
  const formatPrice = useFormatPrice(productVariant.priceCurrency);

  const handleChangeValue = (
    e: FocusEvent<HTMLInputElement> | ChangeEvent<HTMLInputElement>,
  ) => {
    handleAmountChange(parseInt(e.target.value, 10) * PRICE_SCALE);
  };

  const { priceMax, priceMin } = productVariant;

  return (
    <div className="flex flex-col">
      <span className="min-w-max flex-grow text-sm font-medium leading-4 text-black">
        {t(
          'campaignDetails.offers.offer.product.select.valueBeforeDiscount',
          'Valeur avant réduction',
        )}
      </span>
      <span className="mb-4 ml-0.5 min-w-max flex-grow text-tiny font-medium leading-4 text-grey">
        {t(
          'campaignDetails.offers.offer.product.custom.priceEdges',
          '(min. {{minValue}} -max. {{maxValue}})',
          {
            minValue: formatPrice(priceMin),
            maxValue: formatPrice(priceMax),
          },
        )}
      </span>
      <div className="w-28">
        <InputNumber
          disabled={isOutOfStock || hasExpired}
          placeholder={t(
            'campaignDetails.offers.offer.product.custom.placeholder',
            'Ex : {{minValue}}',
            {
              minValue: formatPrice(priceMin),
            },
          )}
          min={priceMin / PRICE_SCALE}
          max={priceMax / PRICE_SCALE}
          onChange={handleChangeValue}
          onBlur={handleChangeValue}
          currency={productVariant.priceCurrency as Currency}
          noControls
        />
      </div>
    </div>
  );
};
