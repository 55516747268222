import { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ButtonProps } from '@src/components/atoms/Button/Button';
import { useIsScrollable } from '@utils/hooks';
import { motion, Variants } from 'framer-motion';

type ExpandableBoxProps = {
  childKey: string;
  labelExpanded?: string;
  labelClosed?: string;
  buttonProps: ButtonProps;
  children: ReactNode;
  variants?: Variants;
  duration?: number;
  height?: string;
  className?: string;
  initialState?: 'expanded' | 'collapsed';
};

export const ExpandableBox = ({
  childKey,
  buttonProps,
  children,
  variants,
  duration = 0.3,
  height = '80px',
  labelExpanded,
  labelClosed,
  className = '',
  initialState = 'collapsed',
}: ExpandableBoxProps) => {
  const { t } = useTranslation();
  const elementRef = useRef(null);
  const [{ v }] = useIsScrollable(elementRef);
  const [expandAvailable, setExpandAvailable] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (v.bottom) {
      setExpandAvailable(true);
    }
  }, [v.bottom]);

  const motionDivVariants = {
    collapsed: {
      height,
    },
    expanded: {
      height: 'fit-content',
      maxHeight: '100%',
      transition: {
        staggerChildren: 0.5,
      },
    },
    ...variants,
  };

  return (
    <>
      <motion.div
        initial={initialState}
        key={childKey}
        animate={expanded ? 'expanded' : 'collapsed'}
        variants={motionDivVariants}
        transition={{
          duration,
        }}
        ref={elementRef}
        className={`quill-html-content reset-list-style overflow-hidden ${className}`}
      >
        {children}
      </motion.div>
      {expandAvailable && (
        <Button onClick={toggleExpanded} {...buttonProps}>
          {expanded
            ? labelExpanded || t('common:buttons.seeLess')
            : labelClosed || t('common:buttons.seeAll', 'Tout afficher')}
        </Button>
      )}
    </>
  );
};
