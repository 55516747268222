import { useTranslation } from 'react-i18next';

import { fallbackLng, LOCALES } from '@src/i18n/i18n';
import { CampaignIndexFragment } from '@src/routes/_authenticated-layout/~route.generated';

const CAMPAIGN_KEY = 'campaign';

const isCurrentLanguageLocale = (
  currentLanguage: string,
): currentLanguage is LOCALES => {
  return Object.values(LOCALES).includes(currentLanguage);
};
export const useCampaignIndex = (campaignIndices: CampaignIndexFragment[]) => {
  const { i18n } = useTranslation();

  const currentLocale = isCurrentLanguageLocale(i18n.language)
    ? i18n.language
    : fallbackLng;

  const localeCampaign = campaignIndices.find(
    ({ entity, locale }) => entity === CAMPAIGN_KEY && locale === currentLocale,
  );

  if (!localeCampaign) {
    throw new Error('No Algolia campaign found');
  }

  return localeCampaign;
};
