import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from '@happypal-tech/design-system';
import { BadgeFilter } from '@src/features-new/catalog/components/BadgeFilter';
import { FilterGeo } from '@src/features-new/catalog/components/FilterGeo/FilterGeo';
import {
  PopoverArrow,
  PopoverContent,
  PopoverPortal,
  PopoverRoot,
  PopoverTrigger,
} from '@src/features-new/ui/Popover/Popover';

interface PopoverGeoProps extends ComponentProps<typeof FilterGeo> {}
export const PopoverGeo = ({ value, ...rest }: PopoverGeoProps) => {
  const { t } = useTranslation('catalog', {
    keyPrefix: 'components.CatalogSearchFilters',
  });

  return (
    <PopoverRoot>
      <PopoverTrigger className="group" asChild>
        <Button variant="stroke" type="button" color="neutral">
          <div className="flex gap-2 items-center">
            {t('geo')}
            <Icon
              name="ChevronUpOutline"
              size={16}
              className="transition-transform group-data-[state='closed']:-rotate-180"
            />
          </div>
          <BadgeFilter showDot={value !== null} />
        </Button>
      </PopoverTrigger>
      <PopoverPortal>
        <PopoverContent
          onOpenAutoFocus={(e) => e.preventDefault()}
          className="min-h-[240px] z-modal"
        >
          <FilterGeo value={value} {...rest} />
          <PopoverArrow />
        </PopoverContent>
      </PopoverPortal>
    </PopoverRoot>
  );
};
