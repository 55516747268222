import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Text } from '@components/atoms/Text';
import { Progress } from '@src/components/atoms/Progress';
import { useFormatPrice } from '@src/utils/hooks/useFormatPrice';

import { CampaignDiscountInsertFragment } from './CampaignDiscountInsert.generated';

type CampaignDiscountInsertProps = {
  activeSubvention: CampaignDiscountInsertFragment;
};

export function CampaignDiscountInsert(props: CampaignDiscountInsertProps) {
  const { activeSubvention } = props;

  const { t } = useTranslation();
  const formatPrice = useFormatPrice();

  const formatedDiscountValue = formatPrice(
    activeSubvention.node.amountDiscounted,
  );

  const {
    node: { company },
  } = activeSubvention;
  const companyName = company?.displayName || company?.name;

  return (
    <div className="mb-3 flex gap-4 md:p-4 lg:rounded-2xl lg:bg-white lg:p-7 lg:shadow">
      <div>
        <Text type="displayTitleS">
          {companyName ? (
            <Trans
              i18nKey="CampaignDiscountInsert.title"
              values={{
                name: companyName,
                value: formatedDiscountValue,
              }}
              components={{
                funded: <span className="text-primary" />,
              }}
              defaults="{{ name }} vous fait bénéficier d’une réduction de <funded>{{ value }} par billet* !</funded>"
            />
          ) : (
            <Trans
              i18nKey="CampaignDiscountInsert.titleNoCompany"
              values={{
                value: formatedDiscountValue,
              }}
              components={{
                funded: <span className="text-primary" />,
              }}
              defaults="Vous bénéficiez d’une réduction de <funded>{{ value }} par billet* !</funded>"
            />
          )}
        </Text>
        <Text type="bodySmallText" className="mt-1 text-grey">
          *
          {t('CampaignDiscountInsert.subtitle', 'Sur une sélection d’articles')}
        </Text>
      </div>
      <div className="min-w-24">
        <Text type="displaySubtitle" className="text-right text-grey">
          <span className="text-2xl text-primary">
            {(activeSubvention?.quantityTotal || 0) -
              (activeSubvention?.quantityConsumed || 0)}
          </span>
          /{activeSubvention?.quantityTotal || 0}
        </Text>
        <Text type="bodySmallText" className="mb-2 text-right">
          {t('CampaignDiscountInsert.itemLeft', 'articles restants')}
        </Text>
        <Progress
          value={Math.round(
            100 -
              ((activeSubvention?.quantityConsumed || 0) /
                (activeSubvention?.quantityTotal || 1)) *
                100,
          )}
          variant={'thick'}
        />
      </div>
    </div>
  );
}
