import { Button } from '@components/atoms/Button';
import { Badge } from '@components/molecules/Badge/Badge';
import { Icon } from '@src/components/atoms/Icon';
import { Link } from '@tanstack/react-router';

import { useViewerCartButtonQuery } from './CartButton.generated';

type CartButtonProps = {
  mobileMode?: boolean;
  isBackgroundDark?: boolean;
  className?: string;
};

export const CartButton = ({
  isBackgroundDark,
  className,
}: CartButtonProps) => {
  const { data } = useViewerCartButtonQuery();

  const cartItemCount = data?.viewer?.activeCart.cartItemsQuantity ?? 0;

  return (
    <Link className={className} to="/cart">
      <div className="mr-2">
        <Badge value={cartItemCount} isOutline={false}>
          <Button
            iconOnly="cart"
            variant="ghost"
            color={isBackgroundDark ? 'primary' : 'neutral'}
          />
        </Badge>
      </div>
    </Link>
  );
};

interface CartFloatingActionButtonProps {
  className?: string;
}
export const CartFloatingActionButton = ({
  className,
}: CartFloatingActionButtonProps) => {
  const { data } = useViewerCartButtonQuery();

  const cartItemCount = data?.viewer?.activeCart.cartItemsQuantity ?? 0;

  return (
    cartItemCount > 0 && (
      <Link className={className} to="/cart">
        <div className="absolute bottom-18 right-4 z-[97] flex h-14 w-14 items-center justify-center rounded-full bg-primary drop-shadow">
          <Icon name="cart" size="medium" className="text-white" />
          <div className="absolute -right-1 -top-1 flex h-6 w-6 items-center justify-center rounded-full bg-white">
            <span className="text-tiny font-bold text-primary">
              {cartItemCount}
            </span>
          </div>
        </div>
      </Link>
    )
  );
};
