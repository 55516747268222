import { z } from '@happypal-tech/design-system';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/_authenticated-layout/happenings/detail/$happeningId/payment',
)({
  validateSearch: z.object({
    quantity: z.number(),
    client_secret: z.string(),
    happening_registration_id: z.string(),
  }),
});
