import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  HeartHands,
  LogoImageWhite,
  PhoneHand,
  VictoryHand,
} from '@assets/images';
import { Button } from '@src/components/atoms/Button/Button';
import { useInterval } from '@utils/hooks/useInterval';
import { cx } from 'class-variance-authority';

enum scrollDirection {
  RIGHT = 'RIGHT',
  LEFT = 'LEFT',
}

type OnboardingCarouselProps = {
  className?: string;
};

export const OnboardingCarousel = (props: OnboardingCarouselProps) => {
  const { className } = props;
  const { t } = useTranslation('auth', {
    keyPrefix: 'components.OnboardingCarousel',
  });

  const carrousel = useRef<HTMLDivElement>(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const SLIDES = [
    {
      image: HeartHands,
      text: (
        <>
          {t('screen1.lign1', 'HappyPal,')}
          <span className="block">{t('screen1.lign2', 'votre compagnon')}</span>
          <span className="block">
            {t('screen1.lign3', 'bien-être au quotidien !')}
          </span>
        </>
      ),
    },
    {
      image: PhoneHand,
      text: (
        <>
          {t('screen2.lign1', 'Des offres qui vous')}
          <span className="block">{t('screen2.lign2', 'correspondent,')}</span>
          <span className="block">
            {t('screen2.lign3', 'à deux pas de chez vous !')}
          </span>
        </>
      ),
    },
    {
      image: VictoryHand,
      text: <>{t('screen3.label')}</>,
    },
  ];

  useInterval(() => {
    handleScroll(scrollDirection.RIGHT);
  }, 4000);

  function handleScroll(direction: scrollDirection) {
    let newIndex;
    if (direction === scrollDirection.RIGHT) {
      newIndex = currentSlide !== SLIDES.length - 1 ? currentSlide + 1 : 0;
    } else {
      newIndex = currentSlide === 0 ? SLIDES.length - 1 : currentSlide - 1;
    }
    jumpToSlide(newIndex);
  }

  function jumpToSlide(newIndex: number) {
    if (!carrousel.current) return;

    setCurrentSlide(newIndex);
    carrousel.current.scrollTo({
      left: newIndex * carrousel.current.clientWidth,
      behavior: 'smooth',
    });
  }

  return (
    <div className={cx(className, 'flex flex-col')}>
      <div className="flex justify-center pb-16 pt-6 lg:hidden">
        <img src={LogoImageWhite} alt="Logo HappyPal" title="HappyPal" />
      </div>
      <div className="flex place-items-center bg-primary lg:h-full">
        <div className="relative flex w-full flex-col">
          <div
            ref={carrousel}
            className="scrollbar-hide relative flex max-w-full items-start overflow-y-auto"
          >
            {SLIDES.map((elem, index) => (
              <div
                key={index}
                className="flex min-w-full flex-col items-center"
              >
                <div className="flex h-60 max-md:max-h-[20vh] items-center justify-center">
                  <img
                    src={elem.image}
                    alt="Mains"
                    className="max-h-full max-w-60 max-md:max-w-[20vh]"
                  />
                </div>
                <h2 className="mx-auto mt-20 text-center font-display text-2xl font-semibold leading-tight text-white">
                  {elem.text}
                </h2>
              </div>
            ))}
          </div>

          <ul className="absolute left-1/2 md:top-64 max-md:bottom-6 flex -translate-x-1/2 translate-y-1/2 transform list-none justify-center gap-2.5">
            {SLIDES.map((elem, index) => (
              <li key={index}>
                <button onClick={() => jumpToSlide(index)}>
                  <span
                    className={`block rounded-full p-1 ${
                      index === currentSlide
                        ? 'w-4 bg-white'
                        : 'bg-white opacity-60'
                    }`}
                  />
                </button>
              </li>
            ))}
          </ul>

          <div className="mb-10 mt-2 flex w-full justify-center gap-6 lg:mb-0 lg:mt-8">
            <Button
              color="inverse"
              variant="ghost"
              onClick={() => handleScroll(scrollDirection.LEFT)}
              iconOnly="arrowLeft"
            />
            <Button
              color="inverse"
              variant="ghost"
              onClick={() => handleScroll(scrollDirection.RIGHT)}
              iconOnly="arrowRight"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
