import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@src/components/atoms/Icon';

import { OfferAvailabilityFragment } from './OfferAvailability.generated';
import { OfferAvailabilityStores } from './OfferAvailabilityStores';

interface OfferAvailabilityProps {
  offerAvailability: OfferAvailabilityFragment;
}

export const OfferAvailability = ({
  offerAvailability,
}: OfferAvailabilityProps) => {
  let title = '';
  let detail = '';
  let discover = false;
  const { t } = useTranslation();

  const isOnline = offerAvailability.availableOnline;
  const isNational = offerAvailability.availableCountry;
  const inMultipleStores = offerAvailability.storePagination.totalCount > 1;
  const inSingleStore = offerAvailability.storePagination.totalCount === 1;
  const isPhysicallyAvailable = inMultipleStores || inSingleStore;
  const brand = offerAvailability.brand?.name || '';
  const [isStoresListOpen, setIsStoresListOpen] = useState<boolean>(false);

  if (isPhysicallyAvailable && isOnline) {
    title = t(
      'campaignDetails.offers.offer.availability.multipleStoresAndOnline.title',
      {
        count: offerAvailability.storePagination.totalCount,
      },
    );
    detail = t('campaignDetails.offers.offer.availability.discoverButton');
    discover = true;
  } else if (isPhysicallyAvailable) {
    if (inMultipleStores) {
      title = t(
        'campaignDetails.offers.offer.availability.multipleStores.title',
        {
          numberOfStores: offerAvailability.storePagination.totalCount,
        },
      );
      detail = t('campaignDetails.offers.offer.availability.discoverButton');
      discover = true;
    } else if (inSingleStore) {
      title =
        offerAvailability.storePagination.edges[0]!.node.address.city || '';
      if (offerAvailability.storePagination.edges[0]!.node.address.address1) {
        detail += `${offerAvailability.storePagination.edges[0]!.node.address.address1}, `;
      }
      detail += offerAvailability.storePagination.edges[0]!.node.address.zip;
    } else {
      return null;
    }
  } else if (isOnline) {
    if (isNational) {
      title = t('campaignDetails.offers.offer.availability.everywhere.title');
      detail = t(
        'campaignDetails.offers.offer.availability.everywhere.detail',
        { brand },
      );
    } else {
      title = t('campaignDetails.offers.offer.availability.online.title');
    }
  } else if (isNational) {
    title = t('campaignDetails.offers.offer.availability.national.title');
    detail = t('campaignDetails.offers.offer.availability.national.detail', {
      brand,
    });
  } else {
    return null;
  }

  return (
    <>
      <div className="mb-6 flex items-center text-tiny">
        <Icon name="geoloc" className="text-primary" size="small" />
        <div className="ml-4 flex flex-col">
          <strong className="text-sm font-bold">{title}</strong>
          <span className="text-grey">
            {discover ? (
              <button
                className="underline"
                onClick={() => setIsStoresListOpen(true)}
              >
                {detail}
              </button>
            ) : (
              detail
            )}
          </span>
        </div>
      </div>
      {discover && (
        <OfferAvailabilityStores
          isOpen={isStoresListOpen}
          onClose={() => setIsStoresListOpen(false)}
          stores={offerAvailability.storePagination.edges}
          offerName={offerAvailability.name || ''}
        />
      )}
    </>
  );
};
