import { useTranslation } from 'react-i18next';

import { Text } from '@components/atoms/Text';
import { OfferDiscountFragment } from '@components/molecules/OfferInfos/OfferReduction/OfferDiscount/OfferDiscount.generated';
import { OfferEmphasiedOff } from '@components/molecules/OfferInfos/OfferReduction/OfferEmphasiedOff';
import { OfferOldPrice } from '@components/molecules/OfferInfos/OfferReduction/OfferOldPrice';
import { OfferSellingPrice } from '@components/molecules/OfferInfos/OfferReduction/OfferSellingPrice';
import { useFormatPrice } from '@utils/hooks/useFormatPrice';
import { applyPercentage } from '@utils/textUtils';

import { OfferInfosSubventionFragment } from '../../OfferInfos.generated';

type OfferDiscountProps = {
  product: OfferDiscountFragment;
  subventionEdge?: OfferInfosSubventionFragment;
};

export const OfferDiscount = (props: OfferDiscountProps) => {
  const { product, subventionEdge } = props;
  const { t } = useTranslation();
  const formatPrice = useFormatPrice();

  const [productVariant] = product.productVariantPagination.nodes;

  if (
    !productVariant ||
    !product.productVariantPagination.nodes.length ||
    !product.discount
  ) {
    // Product without variants nor discount
    return null;
  }

  const renderOfferEmphasiedOff = () => {
    switch (product.discount?.__typename) {
      case 'DiscountPercentage':
        if (!product.discount.percentOff) return null;
        return (
          <OfferEmphasiedOff
            type="percent"
            valueOff={product.discount.percentOff}
          />
        );
      case 'DiscountFlat':
        if (!product.discount.amountOff) return null;
        return (
          <OfferEmphasiedOff
            type="amount"
            valueOff={product.discount.amountOff}
            currency={productVariant.priceCurrency}
          />
        );
      default:
        return null;
    }
  };

  switch (productVariant.__typename) {
    case 'ProductVariantPriceDynamic':
      return (
        <div className="flex flex-col">
          <div className="flex items-center justify-end gap-3">
            <Text type="bodySmallText" className="mr-1 text-grey">
              {t(
                'campaignDetails.offers.offer.product.dynamicVariant.from',
                'à partir de',
              )}
            </Text>
            <Text type="bodySmallText" className="mr-1 text-grey line-through">
              {formatPrice(productVariant.priceValue)}
            </Text>
          </div>
          <div className="flex justify-end">
            <OfferSellingPrice
              amount={
                product.discount.__typename === 'DiscountPercentage'
                  ? Math.round(
                      applyPercentage(
                        productVariant.priceValue -
                          (subventionEdge?.node.amountDiscounted || 0),
                        product.discount.percentOff,
                      ),
                    )
                  : productVariant.priceValue -
                    (subventionEdge?.node.amountDiscounted || 0) -
                    product.discount.amountOff
              }
              currency={productVariant.priceCurrency}
              hasSubvention={
                subventionEdge?.node.amountDiscounted &&
                subventionEdge.node.amountDiscounted > 0
                  ? true
                  : false
              }
            />
          </div>
        </div>
      );
    case 'ProductVariantPriceRange':
      return renderOfferEmphasiedOff();

    case 'ProductVariantPriceFixed':
      // Multiple ProductVariantPriceFixed will result in a select render
      if (product.productVariantPagination.totalCount > 1) {
        return renderOfferEmphasiedOff();
      }
      return (
        <div className="flex flex-col items-end">
          <div className="flex items-center gap-3">
            {renderOfferEmphasiedOff()}
            {((product.discount.__typename === 'DiscountFlat' &&
              product.discount.amountOff) ||
              (product.discount.__typename === 'DiscountPercentage' &&
                product.discount.percentOff)) && (
              <div>
                <OfferOldPrice
                  amount={productVariant.priceValue}
                  currency={productVariant.priceCurrency}
                />
              </div>
            )}
          </div>
          <OfferSellingPrice
            amount={
              product.discount.__typename === 'DiscountPercentage'
                ? Math.round(
                    applyPercentage(
                      productVariant.priceValue -
                        (subventionEdge?.node.amountDiscounted || 0),
                      product.discount.percentOff,
                    ),
                  )
                : productVariant.priceValue -
                  product.discount.amountOff -
                  (subventionEdge?.node.amountDiscounted || 0)
            }
            currency={productVariant.priceCurrency}
            hasSubvention={
              subventionEdge?.node.amountDiscounted &&
              subventionEdge.node.amountDiscounted > 0
                ? true
                : false
            }
          />
        </div>
      );
    default:
      return null;
  }
};
