import { AuthCarouselLayout } from '@src/features-new/auth/components/AuthCarouselLayout';
import { createFileRoute, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/auth/_auth-carousel-layout')({
  component: () => (
    <AuthCarouselLayout>
      <Outlet />
    </AuthCarouselLayout>
  ),
});
