import { useRef } from 'react';

import Slider, { SliderTooltip } from 'rc-slider';

import 'rc-slider/assets/index.css';
import './RadiusPicker.css';

const { Handle } = Slider;

type HandleProps = {
  className: string;
  prefixCls?: string;
  vertical?: boolean;
  offset: number;
  value: number;
  dragging?: boolean;
  disabled?: boolean;
  min?: number;
  index: number;
};

interface RadiusPickerProps {
  defaultRadius: number;
  onChange: (radius: number) => void;
  label?: string;
}

export const RadiusPicker = ({
  defaultRadius = 5,
  onChange,
  label,
}: RadiusPickerProps) => {
  const container = useRef<HTMLDivElement>(null);

  const handle = (props: HandleProps) => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <SliderTooltip
        prefixCls="rc-slider-tooltip"
        overlay={`${value} km`}
        visible={true}
        placement="top"
        key={index}
        getTooltipContainer={(node: HTMLElement) =>
          container?.current ? container.current : node
        }
      >
        <Handle value={value} {...restProps} />
      </SliderTooltip>
    );
  };
  //absolute top-4
  return (
    <div
      ref={container}
      className="z-10 mt-2 h-[140px] w-full rounded-xl bg-white px-9 pb-12 pt-7 shadow"
    >
      {label && (
        <strong className="mb-4 block text-sm font-bold">{label}</strong>
      )}
      <Slider
        dots={false}
        min={1}
        max={100}
        defaultValue={defaultRadius}
        handle={handle}
        marks={{ 1: '1 km', 100: '100 km' }}
        onChange={onChange}
      />
    </div>
  );
};
