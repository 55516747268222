import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  components,
  ControlProps,
  GroupHeadingProps,
  SingleValue,
} from 'react-select';

import { InputSelect } from '@components/atoms/InputSelect';
import { Option } from '@components/atoms/InputSelect/InputSelect';
import { Icon } from '@src/components/atoms/Icon/Icon';
import { useAnalytics } from '@src/hooks/use-analytics';
import { ensureUserPosition } from '@utils/geoloc';
import { debounce } from 'radash';

import { RadiusPicker } from './RadiusPicker';

const DEFAULT_RADIUS = 100;

interface PlaceFilterProps {
  current?: string; // current value
  currentRadius?: number;
  options: Option[];
  placeholder?: string;
  withGeolocation?: boolean;
  onChange: (value?: SingleValue<Option>) => void;
  onGeolocate?: (searchRadius: number) => void;
  onReset?: () => void;
}

const SelectControlComponent = ({
  children,
  ...props
}: ControlProps<Option, false>) => (
  <components.Control {...props}>
    <span className="ml-3 mr-2">
      <Icon name="geoloc" size="small" className="text-primary" />
    </span>
    {children}
  </components.Control>
);

export const PlaceFilter = ({
  current,
  currentRadius,
  options,
  placeholder,
  withGeolocation = true,
  onChange,
  onGeolocate,
  onReset,
}: PlaceFilterProps) => {
  const [openMenuOnClick, setOpenMenuOnClick] = useState(true); // workaround to prevent that the menu to open on android on delete.... https://stackoverflow.com/questions/61472882/how-do-i-stop-the-react-select-input-dropdown-menu-from-opening-when-click-the-i
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const { t } = useTranslation('common');
  const { track } = useAnalytics();
  // currentSearch is mainly used to determine if we are currently searching or not
  const [currentSearch, setCurrentSearch] = useState<string | undefined>(
    current,
  );
  const [showRadiusPicker, setShowRadiusPicker] = useState<boolean>(false);
  const [isGeolocalisedSearch, setIsGeolocalisedSearch] =
    useState<boolean>(false);
  const [isTaping, setIsTaping] = useState(false);

  let selectedOption: Option | undefined;

  const handleGeolocateDebounced = useMemo(
    () => debounce({ delay: 300 }, (radius: number) => onGeolocate?.(radius)),
    [onGeolocate],
  );

  const onRadiusChange = useCallback(
    (radius: number) => {
      if (withGeolocation && onGeolocate) {
        handleGeolocateDebounced(radius);
      }
    },
    [handleGeolocateDebounced, onGeolocate, withGeolocation],
  );

  const reset = useCallback(() => {
    setIsGeolocalisedSearch(false);
    setShowRadiusPicker(false);
    onChange(undefined);
    onReset && onReset();
  }, [onChange, onReset]);

  const displayRadiusPicker = useCallback(
    (radius = DEFAULT_RADIUS) => {
      if (withGeolocation) {
        ensureUserPosition(() => {
          setShowRadiusPicker(true);
          setIsGeolocalisedSearch(true);
          // Trigger a search with the default radius if none is provided
          onRadiusChange(radius);
        });
      }
    },
    [onRadiusChange, withGeolocation],
  );

  useEffect(() => {
    if (currentRadius && !isGeolocalisedSearch) {
      displayRadiusPicker(currentRadius);
      track({
        type: 'click button around me',
      });
    } else if (!currentRadius && isGeolocalisedSearch) {
      reset();
    }
  }, [currentRadius, displayRadiusPicker, isGeolocalisedSearch, reset, track]);

  if (isGeolocalisedSearch) {
    // Force selected option to be "Around me" when doing a geolocalised search
    selectedOption = {
      label: t('filters.location.select.aroundMe', 'Autour de moi'),
      value: 'aroundMe',
    };
  } else {
    selectedOption = options.find((option) => option.value === current);
  }

  return (
    <>
      <div>
        <InputSelect
          skipRootEl
          isSearchable={true}
          placeholder={placeholder}
          options={[
            {
              label: isTaping
                ? t('filters.location.select.results', 'Résultats')
                : t('filters.location.select.suggestions', 'Suggestions'),
              options,
            },
          ]}
          value={selectedOption}
          onChange={(value) => {
            setIsGeolocalisedSearch(false);
            setShowRadiusPicker(false);
            onChange(value);
          }}
          onInputChange={onInputChange}
          inputSpacing={{ x: 4 }}
          // FIXME: soon to be killed
          maxMenuHeight={250}
          isClearable={menuIsOpen && !!selectedOption}
          openMenuOnClick={openMenuOnClick}
          onMenuClose={() => setMenuIsOpen(false)}
          onMenuOpen={() => setMenuIsOpen(true)}
          customComponents={{
            Control: SelectControlComponent,
            ClearIndicator,
            GroupHeading:
              !withGeolocation || (currentSearch && currentSearch.length > 0)
                ? NormalGroupHeading
                : HeadingWithGeolocationOption,
          }}
          key={`placeFilterSelect__${selectedOption?.value}`} // Ensures complete re-render when value changes
        />
      </div>
      {showRadiusPicker && (
        <div className="relative">
          <RadiusPicker
            defaultRadius={currentRadius || DEFAULT_RADIUS}
            onChange={onRadiusChange}
            label={t('filters.location.radiusLabel', 'Dans un rayon autour de')}
          />
        </div>
      )}
    </>
  );

  function onInputChange(value: string) {
    setCurrentSearch(value);
    setIsTaping(!!value);
  }
  // A header group with the "Around me" button inside
  function HeadingWithGeolocationOption(
    props: GroupHeadingProps<Option>,
  ): JSX.Element {
    return (
      <div>
        <button
          onClick={() => displayRadiusPicker()}
          className="mb-5 flex w-full items-center rounded-lg py-2 pl-2 text-sm hover:bg-grey-light"
        >
          <Icon name="geoloc" size="small" className="text-primary" />
          <div className="ml-2">
            {t('filters.location.select.aroundMe', 'Autour de moi')}
          </div>
        </button>
        <components.GroupHeading {...props} />
      </div>
    );
  }

  function NormalGroupHeading(props: GroupHeadingProps<Option>): JSX.Element {
    return <components.GroupHeading {...props} />;
  }

  // Home made ClearIndicator
  function ClearIndicator() {
    return (
      <button
        className="relative z-100 ml-3"
        onTouchStart={(e) => {
          e.stopPropagation();
          setOpenMenuOnClick(false);
          reset();
          setTimeout(() => setOpenMenuOnClick(true), 300); // workaround for android to prevent the menu to open
        }}
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
          reset();
        }}
      >
        <Icon
          name="xCircle"
          variant="fill"
          size="small"
          className="text-grey"
        />
      </button>
    );
  }
};
