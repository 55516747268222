import { FC, MouseEventHandler } from 'react';

import { cx } from 'class-variance-authority';

type CardProps = {
  children: JSX.Element;
  width?: number | string;
  height?: number | string;
  bgColor?: string;
  shadowed?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  selected?: boolean;
  className?: string;
  asSpan?: boolean;
};

export const Card: FC<CardProps> = ({
  children,
  width,
  height,
  bgColor,
  shadowed,
  onClick,
  selected,
  className,
  asSpan,
}) => {
  const Component = asSpan ? 'span' : 'button';

  return (
    <Component
      type="button"
      onClick={onClick}
      className={cx(
        'cursor-pointer rounded-2xl',
        width ? `w-${width}` : '',
        height ? `h-${height}` : '',
        bgColor ? `bg-${bgColor}` : 'bg-grey-light',
        className,
        {
          'border border-solid border-primary': selected,
          'shadow-medium transition-all duration-200 hover:shadow-lg': shadowed,
        },
      )}
      {...(asSpan
        ? {
            role: 'button',
            tabIndex: 0,
            onKeyDown: () => null,
          }
        : {})}
    >
      {children}
    </Component>
  );
};
