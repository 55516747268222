import { useMemo } from 'react';

import { Typography } from '@happypal-tech/design-system';
import { Icon } from '@src/components/atoms/Icon';
import { IconType } from '@src/components/atoms/Icon/Icon';
import { MenuCustomisationElementIcon } from '@src/graphql/generated/types';
import { Link, useMatches } from '@tanstack/react-router';
import { cx } from 'class-variance-authority';

import { AppMenuEntryFragment } from './AppMenuEntry.generated';

interface AppMenuEntryData
  extends Omit<AppMenuEntryFragment, '__typename' | 'id'> {
  onClick?: () => void;
}

interface AppMenuEntryProps {
  entry: AppMenuEntryData;
  /** @default 0 */
  depth?: number;
  isBackgroundDark?: boolean;
}

const ICONS: Record<MenuCustomisationElementIcon, IconType> = {
  [MenuCustomisationElementIcon.Advantage]: 'advantage',
  [MenuCustomisationElementIcon.Calendar]: 'calendar',
  [MenuCustomisationElementIcon.Communication]: 'communication',
  [MenuCustomisationElementIcon.Deal]: 'deal',
  [MenuCustomisationElementIcon.Feed]: 'feed',
  [MenuCustomisationElementIcon.Game]: 'game',
  [MenuCustomisationElementIcon.Gift]: 'gift',
  [MenuCustomisationElementIcon.Grid]: 'gridColumn',
  [MenuCustomisationElementIcon.Home]: 'home',
  [MenuCustomisationElementIcon.Newspaper]: 'newspaper',
  [MenuCustomisationElementIcon.Search]: 'search',
  [MenuCustomisationElementIcon.Subvention]: 'subvention',
  [MenuCustomisationElementIcon.Suitcase]: 'suitcase',
  [MenuCustomisationElementIcon.Users]: 'users',
};

export const AppMenuEntry = (props: AppMenuEntryProps) => {
  const { entry, depth = 0, isBackgroundDark } = props;

  const matches = useMatches();
  const currentPathname = matches.at(-1)?.pathname || '/';

  const isActive = useMemo(() => {
    const validPathnames = [entry.href, ...(entry.aliases || [])];
    let isMatching = false;
    validPathnames.forEach((el) => {
      if (typeof el === 'string' && currentPathname.includes(el)) {
        isMatching = true;
      }
    });
    return isMatching;
  }, [currentPathname, entry]);

  return (
    <Link to={entry.href ?? undefined} onClick={entry.onClick}>
      <div
        className={cx(
          'flex items-center gap-3 rounded-xl px-4 transition-colors',
          {
            'h-11 px-3': depth === 0,
            'h-9 px-6': depth > 0,
            'bg-primary/25 text-primary': isBackgroundDark && isActive,
            'bg-primary-lightest text-primary': !isBackgroundDark && isActive,
            'text-white/70 hover:text-white hover:bg-neutral-lightest/25':
              isBackgroundDark && !isActive,
            'text-neutral-darkest/70 hover:text-neutral-darkest hover:bg-neutral-lightest':
              !isBackgroundDark && !isActive,
          },
        )}
      >
        {depth === 0 ? (
          <>
            <Icon
              name={ICONS[entry.icon]}
              // @ts-expect-error Tricky typing
              variant={isActive ? 'fill' : 'default'}
              className="flex-none"
            />
            <Typography className="flex-1" type="body" semibold>
              {entry.label}
            </Typography>
          </>
        ) : (
          <Typography className="flex-1" type="body" semibold>
            {entry.label}
          </Typography>
        )}
      </div>
    </Link>
  );
};
