import { useEffect, useState } from 'react';

import { useMatchRoute, useNavigate, useSearch } from '@tanstack/react-router';

export const useCatalogSearch = () => {
  const matchRoute = useMatchRoute();
  const q = useSearch({
    strict: false,
    select: (search) => ('q' in search ? search.q ?? '' : ''),
  });

  const [value, setValue] = useState(q);
  const navigate = useNavigate();

  const refine = (q: string) => {
    const queryIsEmpty = q === '';
    const nextSearch = queryIsEmpty ? {} : { q };
    const isCatalogRoute = matchRoute({ to: '/catalog' });
    if (isCatalogRoute) {
      return navigate({
        from: '/catalog',
        to: '/catalog',
        search: ({ q: prevQ, ...rest }) => ({
          ...rest,
          ...nextSearch,
        }),
      });
    }
    if (queryIsEmpty) {
      return;
    }
    return navigate({ to: '/catalog', search: nextSearch });
  };

  useEffect(() => {
    setValue(q);
  }, [q]);

  return [value, setValue, refine] as const;
};
