import { useEffect, useState } from 'react';

export function useRetry(
  callback: () => unknown,
  interval = 100,
  options = { maxRetries: Infinity },
) {
  const { maxRetries } = options;
  const [hasResolved, setHasResolved] = useState(false);

  useEffect(() => {
    let retries = 0;

    const id = setInterval(() => {
      if (callback()) {
        setHasResolved(true);
        clearInterval(id);
      } else if (retries >= maxRetries) {
        clearInterval(id);
      } else {
        retries += 1;
      }
    });
    return () => clearInterval(id);
  }, [interval, maxRetries, callback]);

  return hasResolved;
}
