import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { OfferType } from '@graphql/generated/types';
import {
  Chip,
  Picture,
  Tooltip,
  Typography,
} from '@happypal-tech/design-system';
import { useAnalytics } from '@hooks/use-analytics';
import { FavoritesOrigin } from '@src/features-new/analytics/constants/track-event.constant';
import { DiscountBadge } from '@src/features-new/catalog/components/DiscountBadge/DiscountBadge';
import { ChipOfferType } from '@src/features-new/core/ChipOfferType';
import { FavoriteBadge } from '@src/features-new/favorites/components/FavoriteBadge/FavoriteBadge';
import { cn } from '@src/features-new/ui/cn';
import { sortOfferTypes } from '@src/features-new/utils/offerType';
import { useAlgoliaSearchInsights } from '@src/lib/algolia/search-insights';
import { Link } from '@tanstack/react-router';
import { cx } from 'class-variance-authority';

import { CampaignCardInformationFragment } from './CampaignCard.generated';

export type CampaignCardProps = {
  brand: string;
  campaignId: string;
  campaignThumbnailUrl: string | null | undefined;
  brandLogoUrl?: string | null;
  types: OfferType[];
  categories: string[];
  name: string;
  queryID?: string;
  position?: number;
  className?: string;
  campaignInfo?: CampaignCardInformationFragment;
  onFavoriteStatusChange?: () => void;
  pageTrackingValue: FavoritesOrigin;
};

export const CampaignCard = (props: CampaignCardProps) => {
  const {
    className,
    campaignInfo,
    onFavoriteStatusChange,
    brand,
    campaignId,
    campaignThumbnailUrl,
    brandLogoUrl,
    types,
    categories,
    name,
    queryID,
    position,
    pageTrackingValue,
  } = props;

  const fundSubventionsCount = campaignInfo?.fundSubventionsCount || 0;
  const discountSubventionsCount = campaignInfo?.discountSubventionsCount || 0;

  const { t } = useTranslation('core', {
    keyPrefix: 'components.CatalogCampaignCard',
  });
  const { track } = useAnalytics();
  const { clickedObjectIds } = useAlgoliaSearchInsights();

  const trackCardClick = () => {
    track({
      type: 'click catalog campaign',
      payload: {
        link_position: 'catalog campaign flat list',
        selection_name: name,
      },
    });
    clickedObjectIds({
      objectIDs: [campaignId],
      eventName: 'Catalog Campaign Clicked',
      positions: position ? [position] : undefined,
      queryID,
    });
  };

  const hasMultipleOfferTypes = types.length > 1;
  const offerTypes = sortOfferTypes(types);

  const categoriesCount = categories.length;
  const firstCategory = categories[0];

  const categoryDisplayed = useMemo(() => {
    if (!categoriesCount) return '';

    const andMore = categoriesCount > 1 ? ` (+${categoriesCount - 1})` : '';

    return firstCategory?.toUpperCase() + andMore;
  }, [categoriesCount, firstCategory]);

  return (
    <Link
      className={cn('flex flex-col items-start gap-2 grow', className)}
      to="/explore/$campaignId"
      params={{ campaignId }}
      search={{ queryID }}
      onClick={trackCardClick}
    >
      <div
        className="relative h-[200px] w-full shadow-md rounded-xl overflow-hidden bg-white bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${campaignThumbnailUrl})`,
        }}
      >
        <div
          className="absolute inset-0"
          style={{
            background:
              'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 23.53%, rgba(0, 0, 0, 0.75) 100%)',
          }}
        />
        <div className="flex flex-col justify-between h-full">
          <div className="z-0 mt-4 flex flex-row-reverse items-center justify-between">
            {campaignInfo?.discount && (
              <DiscountBadge discount={campaignInfo.discount} />
            )}
          </div>

          <div className="mx-2 mb-2 flex justify-between items-end z-0">
            {brandLogoUrl ? (
              <Picture
                src={brandLogoUrl}
                alt={brand}
                fit="contain"
                className="rounded-xl border border-white bg-white border-x-4 border-y-4 shadow-md h-[64px] w-[64px]"
              />
            ) : (
              <span />
            )}

            <div className="flex justify-between gap-1 ">
              {offerTypes[0] && (
                <ChipOfferType
                  color="black"
                  size="medium"
                  variant="plain"
                  offerType={offerTypes[0]}
                />
              )}
              {hasMultipleOfferTypes && (
                <Chip
                  color="black"
                  size="medium"
                  variant="plain"
                  label={`+ ${offerTypes.length - 1}`}
                />
              )}
            </div>
          </div>
        </div>
        <span
          className="absolute top-2 left-2"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          role="button"
          tabIndex={0}
          onKeyDown={() => null}
        >
          <FavoriteBadge
            isFavorite={!!campaignInfo?.isFavorite}
            campaignId={campaignId}
            campaignName={name}
            queryID={queryID}
            onFavoriteStatusChange={() => onFavoriteStatusChange?.()}
            pageTrackingValue={pageTrackingValue}
          />
        </span>
      </div>
      <div className="flex flex-col items-start gap-1 w-full">
        <div
          className={cx('flex justify-between items-center gap-4 w-full', {
            'flex-row-reverse': categories.length === 0,
          })}
        >
          {categories.length > 0 && (
            <Typography
              type="caption"
              semibold
              className="text-neutral-dark grow py-1"
            >
              {categoryDisplayed}
            </Typography>
          )}
          <div className="flex items-center justify-between gap-2">
            {fundSubventionsCount > 0 && (
              <Tooltip
                side="top"
                content={t('fundSubventionsCount', {
                  count: fundSubventionsCount,
                })}
                className="mr-2 hidden md:block"
              >
                <Chip
                  iconLeft="PiggyBankOutline"
                  label={`${fundSubventionsCount}`}
                  color="primary"
                />
              </Tooltip>
            )}
            {discountSubventionsCount > 0 && (
              <Tooltip
                side="bottom"
                content={t('discountSubventionsCount', {
                  count: discountSubventionsCount,
                })}
                className="mr-2 hidden md:block"
              >
                <Chip
                  iconLeft="BadgePercentOutline"
                  label={`${discountSubventionsCount}`}
                  color="primary"
                />
              </Tooltip>
            )}
          </div>
        </div>
        <Typography type="heading-4" bold className="line-clamp-2">
          {name}
        </Typography>
      </div>
    </Link>
  );
};
