import { FunctionComponent } from 'react';

import { cx } from 'class-variance-authority';

import Facebook from './assets/facebook.svg?react';
import Instagram from './assets/instagram.svg?react';
import LinkedIn from './assets/linkedin.svg?react';
import Twitter from './assets/twitter.svg?react';
import Youtube from './assets/youtube.svg?react';

const mapper: Record<
  SocialNetworkProps['type'],
  { icon: FunctionComponent; background: string }
> = {
  FACEBOOK: { icon: Facebook, background: '#3A579B' },
  INSTAGRAM: {
    icon: Instagram,
    background: 'linear-gradient(180deg, #C61BBA 0%, #F9AF0E 100%)',
  },
  TWITTER: { icon: Twitter, background: '#4FACF2' },
  YOUTUBE: { icon: Youtube, background: '#D01D15' },
  LINKEDIN: { icon: LinkedIn, background: '#007AB9' },
};

interface SocialNetworkProps {
  className?: string;
  type: 'FACEBOOK' | 'INSTAGRAM' | 'LINKEDIN' | 'TWITTER' | 'YOUTUBE';
}

export const SocialNetwork = (props: SocialNetworkProps) => {
  const { className, type } = props;

  const Icon = mapper[type].icon;

  return (
    <div
      className={cx(
        className,
        'flex h-8 w-8 items-center justify-center rounded-lg p-2',
      )}
      style={{ background: mapper[type].background }}
    >
      <Icon />
    </div>
  );
};
