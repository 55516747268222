/* eslint-disable jsx-a11y/anchor-has-content */
import { useTranslation } from 'react-i18next';

import {
  Button,
  Form,
  type FormSubmitHandler,
  toast,
  Typography,
  useForm,
} from '@happypal-tech/design-system';
import useLogout from '@src/features/auth/hooks/useLogout';
import {
  UserPreferenceShowstopperForm,
  UserPreferenceShowstopperFormValidationSchemaType,
  useUserPreferenceShowstopperFormValidationSchema,
} from '@src/features-new/user/forms/UserPreferenceShowstopperForm/UserPreferenceShowstopperForm';
import {
  useAuthenticatedUser,
  useSelectedCompany,
} from '@src/hooks/authenticated-context/authenticated-context';
import { cx } from 'class-variance-authority';

import {
  useShowstopperNewsletterMutationPreferencesMutation,
  useShowstopperNewsletterMutationTermsMutation,
  useShowStopperNewsletterViewerQuery,
} from './ShowstopperNewsletter.generated';

export type ShowstopperNewsletterProps = {
  className?: string;
};

export const ShowstopperNewsletter = (props: ShowstopperNewsletterProps) => {
  const { className } = props;
  const viewer = useAuthenticatedUser();
  const company = useSelectedCompany();
  const { data } = useShowStopperNewsletterViewerQuery();
  const isMailingEnabled = [
    company?.features.communication === true,
    company?.features.mailing === true,
  ].every(Boolean);
  const logout = useLogout();
  const { t } = useTranslation('user', {
    keyPrefix: 'components.showStopperNewsletter',
  });

  const [preferencesMutation] =
    useShowstopperNewsletterMutationPreferencesMutation();
  const [termsMutation] = useShowstopperNewsletterMutationTermsMutation();

  const shouldShowCGU = !data?.viewer?.termsAcceptedAt;
  const globalNewsletter =
    data?.viewer?.preferences?.properties.newsletters?.global;
  const shouldShowGlobalNewsletter = !globalNewsletter;
  const shouldShowCompanyNewsletter =
    isMailingEnabled &&
    !data?.viewer?.preferences?.properties.newsletters?.company;
  const shouldShowPartnerNewsletter =
    !data?.viewer?.preferences?.properties.newsletters?.partner;

  const form = useForm({
    validationSchema:
      useUserPreferenceShowstopperFormValidationSchema(shouldShowCGU),
    defaultValues: {
      globalNewsletter: globalNewsletter ?? false,
      companyNewsletter:
        data?.viewer?.preferences?.properties.newsletters?.company ?? false,
      partnerNewsletter:
        data?.viewer?.preferences?.properties.newsletters?.partner ?? false,
    },
  });

  const handleValid: FormSubmitHandler<
    UserPreferenceShowstopperFormValidationSchemaType
  > = async (values) => {
    try {
      if (values.terms) {
        await termsMutation();
      }
      await preferencesMutation({
        variables: {
          viewerId: viewer!.id,
          input: {
            newsletters: {
              company: isMailingEnabled
                ? values.companyNewsletter ?? false
                : undefined,
              global: values.globalNewsletter ?? false,
              partner: values.partnerNewsletter ?? false,
            },
          },
        },
      });
    } catch {
      toast.error(t('apiErrors'));
    }
  };
  if (!viewer) return null;

  return (
    <Form
      form={form}
      onValid={handleValid}
      className={cx(className, 'text-neutral-darkest')}
    >
      <Typography type="heading-2" className="text-center lg:text-left" bold>
        {t('title', { name: viewer.firstName })}
      </Typography>
      <UserPreferenceShowstopperForm
        shouldShowCGU={shouldShowCGU}
        shouldShowCompanyNewsletter={shouldShowCompanyNewsletter}
        shouldShowGlobalNewsletter={shouldShowGlobalNewsletter}
        shouldShowPartnerNewsletter={shouldShowPartnerNewsletter}
        company={company.displayName}
      >
        <div className="my-8 grid gap-4">
          <Button loading={form.formState.isSubmitting} type="submit">
            {t('submit')}
          </Button>
          <Button onClick={logout} color="neutral">
            {t('logout')}
          </Button>
        </div>
      </UserPreferenceShowstopperForm>
    </Form>
  );
};

ShowstopperNewsletter.shouldRender = function ShouldRender() {
  const { data } = useShowStopperNewsletterViewerQuery();
  const company = useSelectedCompany();
  if (!data?.viewer) return false;

  const isMailingEnabled = [
    company?.features.communication === true,
    company?.features.mailing === true,
  ].every(Boolean);

  const conditions = [
    typeof data.viewer.preferences?.properties.newsletters?.global !==
      'boolean',
    typeof data.viewer.preferences?.properties.newsletters?.partner !==
      'boolean',
    !data.viewer.termsAcceptedAt,
  ];

  if (isMailingEnabled) {
    conditions.push(
      typeof data.viewer.preferences?.properties.newsletters?.company !==
        'boolean',
    );
  }
  return conditions.some(Boolean);
};
