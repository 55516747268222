import i18next from 'i18next';

type DistanceToken =
  | 'lessThanXSeconds'
  | 'xSeconds'
  | 'halfAMinute'
  | 'lessThanXMinutes'
  | 'xMinutes'
  | 'aboutXHours'
  | 'xHours'
  | 'xDays'
  | 'aboutXWeeks'
  | 'xWeeks'
  | 'aboutXMonths'
  | 'xMonths'
  | 'aboutXYears'
  | 'xYears'
  | 'overXYears'
  | 'almostXYears';

export type DistanceFormat = 'default' | 'short';

// t('common:dates.tokens.distances.default.in')
// t('common:dates.tokens.distances.default.ago')
// t('common:dates.tokens.distances.default.halfAMinute')
// t('common:dates.tokens.distances.default.xSeconds', { count })
// t('common:dates.tokens.distances.default.lessThanXMinutes', { count })
// t('common:dates.tokens.distances.default.xMinutes', { count })
// t('common:dates.tokens.distances.default.aboutXHours', { count })
// t('common:dates.tokens.distances.default.xHours', { count })
// t('common:dates.tokens.distances.default.xDays', { count })
// t('common:dates.tokens.distances.default.aboutXWeeks', { count })
// t('common:dates.tokens.distances.default.xWeeks', { count })
// t('common:dates.tokens.distances.default.aboutXMonths', { count })
// t('common:dates.tokens.distances.default.xMonths', { count })
// t('common:dates.tokens.distances.default.aboutXYears', { count })
// t('common:dates.tokens.distances.default.xYears', { count })
// t('common:dates.tokens.distances.default.overXYears', { count })
// t('common:dates.tokens.distances.default.almostXYears', { count })

// t('common:dates.tokens.distances.short.in')
// t('common:dates.tokens.distances.short.ago')
// t('common:dates.tokens.distances.short.halfAMinute')
// t('common:dates.tokens.distances.short.xSeconds', { count })
// t('common:dates.tokens.distances.short.lessThanXMinutes', { count })
// t('common:dates.tokens.distances.short.xMinutes', { count })
// t('common:dates.tokens.distances.short.aboutXHours', { count })
// t('common:dates.tokens.distances.short.xHours', { count })
// t('common:dates.tokens.distances.short.xDays', { count })
// t('common:dates.tokens.distances.short.aboutXWeeks', { count })
// t('common:dates.tokens.distances.short.xWeeks', { count })
// t('common:dates.tokens.distances.short.aboutXMonths', { count })
// t('common:dates.tokens.distances.short.xMonths', { count })
// t('common:dates.tokens.distances.short.aboutXYears', { count })
// t('common:dates.tokens.distances.short.xYears', { count })
// t('common:dates.tokens.distances.short.overXYears', { count })
// t('common:dates.tokens.distances.short.almostXYears', { count })

/**
 * DO NOT USE THIS FUNCTION DIRECTLY
 */
export function __formatDistance(
  token: DistanceToken,
  count: number,
  options: {
    addSuffix?: boolean;
    comparison?: number;
    format?: DistanceFormat;
  } | null,
) {
  const format = options?.format ?? 'default';

  let value: string;

  if (token === 'halfAMinute') {
    // @ts-expect-error FIXME
    value = i18next.t(`common:dates.tokens.distances.${format}.halfAMinute`, {
      ns: 'common',
    });
  } else {
    // @ts-expect-error dynamic typing are ark to match
    value = i18next.t(`common:dates.tokens.distances.${format}.${token}`, {
      ns: 'common',
      count,
    }) as unknown as string;
  }

  if (options?.addSuffix) {
    if (options.comparison && options.comparison > 0) {
      // @ts-expect-error FIXME
      return i18next.t(`common:dates.tokens.distances.${format}.in`, {
        ns: 'common',
        value,
      });
    } else {
      // @ts-expect-error FIXME
      return i18next.t(`common:dates.tokens.distances.${format}.ago`, {
        ns: 'common',
        value,
      });
    }
  }

  return value;
}
