import { ApolloClient, from, InMemoryCache } from '@apollo/client';
import { env } from '@env';

import fragmentMatcher from './generated/fragment-matcher.json';
import { amplitudeLink } from './links/amplitude.link';
import { sessionLink } from './links/session.link';
import {
  errorLink,
  geoLink,
  httpLink,
  i18nLink,
  namedLink,
  scalarsLink,
  sentryLink,
} from './links';
import { typePolicies } from './typePolicies';

export const client = new ApolloClient({
  link: from([
    scalarsLink,
    sentryLink,
    geoLink,
    sessionLink,
    i18nLink,
    errorLink,
    amplitudeLink,
    ...(import.meta.env.DEV ? [namedLink] : []),
    httpLink,
  ]),

  cache: new InMemoryCache({
    possibleTypes: fragmentMatcher.possibleTypes,
    typePolicies,
  }),
  name: env.REACT_APP_APOLLO_CLIENT_NAME,
  version: env.REACT_APP_APOLLO_CLIENT_VERSION,
  connectToDevTools: env.REACT_APP_ENV !== 'production',
});
