import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';

const AuthLayout = () => {
  return (
    <div className="min-h-screen">
      <Outlet />
    </div>
  );
};

export const Route = createFileRoute('/auth')({
  component: AuthLayout,
  beforeLoad: ({ context }) => {
    if (context.viewer) {
      throw redirect({
        to: '/home',
      });
    }
  },
});
