import ContentLoader from 'react-content-loader';
import { useTranslation } from 'react-i18next';

import { motion } from 'framer-motion';

import { CampaignDescriptionFragment } from './CampaignDescription.generated';

type CampaignDescriptionProps = {
  campaign: CampaignDescriptionFragment | null;
  loading: boolean;
};

export const CampaignDescription = ({
  campaign,
  loading,
}: CampaignDescriptionProps) => {
  const { t } = useTranslation();

  return (
    <motion.div>
      {loading && <SkeletonCampaignDescription />}
      {!!campaign && (
        <>
          {!!campaign.brand.descriptionHTML && (
            <div className="mb-5 md:mb-9">
              <p className="text-base font-bold leading-6 text-neutral-darkest">
                {t('common:theBrand')}
              </p>
              <div className="mb-1" />
              <p
                className="quill-html-content reset-list-style text-sm leading-6 text-neutral-darkest"
                dangerouslySetInnerHTML={{
                  __html: campaign.brand.descriptionHTML,
                }}
              />
            </div>
          )}
          {!!campaign.descriptionHTML && (
            <div className="mb-5 md:mb-9">
              <p className="text-base font-bold leading-6 text-neutral-darkest">
                {t('campaignDetails.description')}
              </p>
              <div className="mb-1" />
              <p
                className="quill-html-content reset-list-style text-sm leading-6 text-neutral-darkest"
                dangerouslySetInnerHTML={{
                  __html: campaign.descriptionHTML,
                }}
              />
            </div>
          )}
          {campaign.instructionsHTML && (
            <div className="ql-snow">
              <p className="mb-1 text-base font-bold leading-6 text-neutral-darkest">
                {t('campaignDetails.instructions')}
              </p>
              <p
                className="quill-html-content ql-editor reset-list-style text-sm leading-6 text-neutral-darkest"
                dangerouslySetInnerHTML={{
                  __html: campaign.instructionsHTML,
                }}
              />
            </div>
          )}
        </>
      )}
    </motion.div>
  );
};

const SkeletonCampaignDescription = () => {
  return (
    <ContentLoader
      speed={1}
      width="100%"
      height={384}
      backgroundColor="#F8F9FB"
      foregroundColor="#CCCFE1"
    >
      <rect x="0" y="0" rx="4" ry="4" width="180" height="24" />
      <rect x="0" y="40" rx="4" ry="4" width="100%" height="280" />
    </ContentLoader>
  );
};
