import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ApolloError } from '@apollo/client';
import { InputDateTime } from '@components/atoms/InputDateTime/InputDateTime';
import { QuantityModifier } from '@components/atoms/QuantityModifier';
import { FormItem } from '@components/molecules/FormItem/FormItem';
import { TicketModalCustomerFormValues } from '@components/molecules/NamedTicketModal/TicketModalCustomerForm/TicketModalCustomerForm';
import { CampaignOffersContext } from '@components/organisms/CampaignOffers/CampaignOffers';
import { Form } from '@components/organisms/Form/Form';
import { useForm } from '@components/organisms/Form/useForm';
import { MultipleVariantsFragment } from '@components/organisms/OfferProductAction/MultipleVariants/MultipleVariants.generated';
import { Modal, Typography } from '@happypal-tech/design-system';
import { addYears, format } from '@services/dateManager.service';
import { Button } from '@src/components/atoms/Button';
import { NamedTicketModal } from '@src/components/molecules/NamedTicketModal';
import { OfferExpiration } from '@src/components/molecules/OfferExpiration';
import { useAlgoliaSearchInsights } from '@src/lib/algolia/search-insights';
import { apiErrorCatcher } from '@src/utils/apiErrorCatcher';
import { useCart } from '@utils/hooks/useCart/useCart';
import { getProductVariantQuantity } from '@utils/offerUtils';
import { applyPercentage, formatPrice } from '@utils/textUtils';
import { cx } from 'class-variance-authority';
import { TFunction } from 'i18next';
import * as Yup from 'yup';

import { CustomVariant } from './CustomVariant';
import { MultipleVariants } from './MultipleVariants';
import {
  OfferProductActionFragment,
  OfferProductActionSubventionFragment,
  OfferProductVariantFragment,
  useOrderAddItemMutation,
  useVariantDynamicPriceLazyQuery,
} from './OfferProductAction.generated';

type OfferProductActionProps = {
  offer: OfferProductActionFragment;
  setVariantOutOfStock: (value: boolean) => void;
  subventionEdge?: OfferProductActionSubventionFragment;
  offerExpired: boolean;
  queryID?: string;
  campaignId: string;
};

const now = new Date();
const today = new Date();
today.setHours(0, 0, 0, 0);

const validationSchema = (t: TFunction) =>
  Yup.object({
    formDate: Yup.date()
      .when('$withTime', {
        is: true,
        then: (schema) =>
          schema.typeError(t('offerProductAction.dateTimeError.required')),
        otherwise: (schema) =>
          schema.typeError(t('offerProductAction.dateError.required')),
      })
      .when('$withTime', {
        is: true,
        then: (schema) =>
          schema.min(now, t('offerProductAction.dateTimeError.min')),
        otherwise: (schema) =>
          schema.min(today, t('offerProductAction.dateError.min')),
      })
      .when('$withTime', {
        is: true,
        then: (schema) =>
          schema.required(t('offerProductAction.dateTimeError.required')),
        otherwise: (schema) =>
          schema.required(t('offerProductAction.dateError.required')),
      }),
  });

const FEW_ITEMS_LEFT_THRESHOLD = 10;

export const OfferProductAction = (props: OfferProductActionProps) => {
  const {
    offer,
    setVariantOutOfStock,
    subventionEdge,
    offerExpired,
    queryID,
    campaignId,
  } = props;
  const { addedToCartObjectIds } = useAlgoliaSearchInsights();

  const { setCurrentCartConfirmationSlide } = useContext(CampaignOffersContext);
  const { t } = useTranslation();

  const [quantity, setQuantity] = useState(1);
  const [isSubventionQuantityExceeded, setIsSubventionQuantityExceeded] =
    useState(false);
  const [customAmount, setCustomAmount] = useState(0);
  const [showNamedTicketModal, setShowNamedTicketModal] = useState(false);
  const [availableQuantity, setAvailableQuantity] = useState<number | null>(
    null,
  );
  const [warningMessage, setWarningMessage] = useState<string | null>();

  const variants = offer.product.productVariantPagination.nodes;

  const [currentDynamicPrice, setCurrentDynamicPrice] = useState(0);
  const [dynamicDiscount, setDynamicDiscount] = useState(0);

  const offerMetadata = offer.product.metadata;

  const [productVariant, setProductVariant] =
    // @ts-expect-error - fix me
    useState<OfferProductVariantFragment>(variants[0]);
  const hasDynamicPrice =
    productVariant.__typename === 'ProductVariantPriceDynamic';

  const needCustomerInfos = [
    offerMetadata.beneficiaryBirthdateRequired,
    offerMetadata.beneficiaryFirstNameRequired,
    offerMetadata.beneficiaryLastNameRequired,
  ].some(Boolean);

  const selectedVariantQuantity = getProductVariantQuantity(
    productVariant.inventoryQuantity,
  );
  const isOutOfStock = !selectedVariantQuantity;
  const localeQuantity = selectedVariantQuantity - quantity;
  const isLocaleOutOfStock = localeQuantity < 0;
  const hasFewItemsLeft = localeQuantity <= FEW_ITEMS_LEFT_THRESHOLD;
  const isPriceRangeType = variants.every(
    (node) => node.__typename === 'ProductVariantPriceRange',
  );

  useEffect(() => {
    // @ts-expect-error - fix me
    handleProductVariantChange(variants[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleProductVariantChange(newValue: OfferProductVariantFragment) {
    setProductVariant(newValue);
    setQuantity(1);
    const newVariantQuantity = getProductVariantQuantity(
      newValue.inventoryQuantity,
    );
    setVariantOutOfStock(newVariantQuantity === 0);
  }

  function getInitialPrice(): number {
    switch (productVariant.__typename) {
      case 'ProductVariantPriceFixed':
        return productVariant.priceValue;
      case 'ProductVariantPriceRange':
        return customAmount;
      case 'ProductVariantPriceDynamic':
        return currentDynamicPrice;
    }
  }

  function getProductPrice(): number {
    const { discount } = offer.product;

    let finalValue = getInitialPrice();

    if (discount && !hasDynamicPrice) {
      switch (discount.__typename) {
        case 'DiscountFlat':
          finalValue -= discount.amountOff;
          break;
        case 'DiscountPercentage':
          finalValue = applyPercentage(finalValue, discount.percentOff);
          break;
      }
    }

    if (hasDynamicPrice) {
      finalValue -= dynamicDiscount;
    }

    return Math.max(
      finalValue - (subventionEdge?.node.amountDiscounted || 0),
      0,
    );
  }

  const { getOrCreateCart } = useCart();

  const [orderAddItem, { loading }] = useOrderAddItemMutation();

  const handleOrderButtonClicked = () => {
    if (needCustomerInfos) {
      setShowNamedTicketModal(true);
    } else {
      handleOrder();
    }
  };

  const handleOrder = async (values?: TicketModalCustomerFormValues[]) => {
    setShowNamedTicketModal(false);

    try {
      const cart = await getOrCreateCart();
      const date = useFormReturn.getValues('formDate');

      const metadata = {
        ...(offerMetadata.dateRequired && date ? { date } : {}),
        ...(offerMetadata.timeRequired && date
          ? { time: format(date, 'HH[:]mm') }
          : {}),
        ...(needCustomerInfos && values ? { beneficiaries: values } : {}),
      };
      const { data } = await orderAddItem({
        variables: {
          cartId: cart?.id || '',
          productVariantId: productVariant.id,
          input: {
            ...(isPriceRangeType ? { customAmount: customAmount } : {}),
            quantity,
            metadata,
          },
          ...(subventionEdge
            ? { discountSubventionId: subventionEdge.node.id }
            : {}),
        },
      });

      if (!data?.cartItemCreate.cartItem.id) {
        throw new Error('No lineitem created');
      }

      const initalPrice = getInitialPrice();
      const productPrice = getProductPrice();
      const discount = initalPrice - productPrice;

      addedToCartObjectIds({
        eventName: queryID
          ? 'Product Added To Cart After Search'
          : 'Product Added To Cart',
        objectIDs: [campaignId],
        currency: 'EUR',
        value: (productPrice * quantity) / 100,
        objectData: [
          {
            quantity,
            price: productPrice / 100,
            discount: discount / 100,
          },
        ],
        queryID,
      });

      setCurrentCartConfirmationSlide({
        item: data.cartItemCreate.cartItem,
        totalCount: data.cartItemCreate.cart.cartItemsQuantity,
      });
    } catch (error) {
      if (
        error instanceof ApolloError &&
        error.graphQLErrors.find(
          (e) => e.extensions?.code === 'cart-item/exceed-subvention-quantity',
        )
      ) {
        setIsSubventionQuantityExceeded(true);
      } else {
        apiErrorCatcher(error);
      }
    }
  };

  const [getAvailablityByDateQuery, { loading: availablityLoading }] =
    useVariantDynamicPriceLazyQuery();

  const useFormReturn = useForm({
    validationSchema: validationSchema(t),
    mode: 'onChange',
    context: {
      withTime: !!offerMetadata.timeRequired,
    },
  });

  const resetPriceAndQuantity = () => {
    setCurrentDynamicPrice(0);
    setDynamicDiscount(0);
    setAvailableQuantity(null);
  };

  const getAvailablityByDate = useCallback(async () => {
    const isFormValid = await useFormReturn.trigger();
    setWarningMessage(null);
    if (!isFormValid) {
      resetPriceAndQuantity();
      return;
    }

    try {
      const date = useFormReturn.getValues('formDate');
      if (!date) return;
      const resp = await getAvailablityByDateQuery({
        variables: {
          productVariantId: productVariant.id,
          input: {
            date,
          },
        },
      });
      if (!resp.data) {
        resetPriceAndQuantity();
        setWarningMessage(t('offerProductAction.dateError.noData'));
        return;
      }
      setCurrentDynamicPrice(resp.data.productVariantGetDynamicPrice.amount);
      setAvailableQuantity(
        resp.data.productVariantGetDynamicPrice.inventoryQuantity,
      );
      setDynamicDiscount(
        resp.data.productVariantGetDynamicPrice.discountAmount,
      );
    } catch (e) {
      apiErrorCatcher(e);
      resetPriceAndQuantity();
    }
  }, [getAvailablityByDateQuery, productVariant.id, t, useFormReturn]);

  const formDate = useFormReturn.getValues('formDate');
  useEffect(() => {
    if (useFormReturn.formState.isDirty) {
      getAvailablityByDate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formDate]);

  const displayQuantity = !hasDynamicPrice || !!currentDynamicPrice;
  const stockInventoryQuantityFewItemsLeft = hasFewItemsLeft && !isOutOfStock;
  const dynamicAvailableQuantityItemsLeft = (availableQuantity ?? 0) - quantity;
  const dynamicAvailableQuantityFewItemsLeft =
    hasDynamicPrice &&
    availableQuantity !== null &&
    dynamicAvailableQuantityItemsLeft <= FEW_ITEMS_LEFT_THRESHOLD;

  if (!offer || !offer.product.productVariantPagination.totalCount) return null;

  const getTotalPrice = () => {
    const productPrice = getProductPrice() ?? 0;
    const productPricePerQuantity = productPrice * quantity;
    return productPricePerQuantity
      ? formatPrice(productPricePerQuantity, productVariant.priceCurrency)
      : formatPrice(0, productVariant.priceCurrency);
  };

  const totalPrice = getTotalPrice();

  return (
    <div>
      <div
        className={`my-6 ml-0 flex w-full justify-start ${
          variants.length > 1 || isPriceRangeType ? 'items-end' : 'items-center'
        }`}
      >
        {variants.length > 1 &&
          productVariant.__typename === 'ProductVariantPriceFixed' && (
            <MultipleVariants
              defaultVariant={productVariant}
              variants={
                variants.filter(
                  (variant) =>
                    variant.__typename === 'ProductVariantPriceFixed',
                ) as MultipleVariantsFragment[]
              }
              onVariantChange={handleProductVariantChange}
              hasExpired={offerExpired}
            />
          )}
        {isPriceRangeType &&
          productVariant.__typename === 'ProductVariantPriceRange' && (
            <CustomVariant
              productVariant={productVariant}
              handleAmountChange={setCustomAmount}
              isOutOfStock={isOutOfStock}
              hasExpired={offerExpired}
            />
          )}

        <div className="flex-1">
          {offerMetadata.dateRequired && (
            <Form form={useFormReturn}>
              <FormItem
                name="formDate"
                valueAsDate
                label={
                  offerMetadata.timeRequired
                    ? t('offerProductAction.dateTimeLabel')
                    : t('offerProductAction.dateLabel')
                }
              >
                <InputDateTime
                  disabled={availablityLoading}
                  disabledDays={{
                    before: today,
                  }}
                  fromYear={today.getFullYear()}
                  toYear={addYears(today, 5).getFullYear()}
                  hideTime={!offerMetadata.timeRequired}
                />
              </FormItem>
            </Form>
          )}

          {displayQuantity && !availablityLoading && (
            <div
              className={cx('mb-0.5 flex flex-grow justify-center md:mb-1.5', {
                'mt-6': hasDynamicPrice,
              })}
            >
              <QuantityModifier
                min={1}
                max={Math.min(
                  hasDynamicPrice
                    ? availableQuantity || 0
                    : selectedVariantQuantity,
                  10,
                )}
                quantity={isOutOfStock ? 0 : quantity}
                onQuantityChange={setQuantity}
                disabled={offerExpired}
              />
            </div>
          )}
        </div>
      </div>

      {warningMessage && (
        <div className="mb-4 flex h-10 items-center">
          <span className="text-tiny font-medium text-red">
            {warningMessage}
          </span>
        </div>
      )}

      {stockInventoryQuantityFewItemsLeft && (
        <div className="mb-4 flex h-10 items-center">
          <span className="text-tiny font-medium text-red">
            {t(
              `campaignDetails.offers.offer.product.quantity.lowStockRemaining`,
              { count: localeQuantity },
            )}
          </span>
        </div>
      )}

      {dynamicAvailableQuantityFewItemsLeft && (
        <div className="mb-4 flex h-10 items-center">
          <span className="text-tiny font-medium text-red">
            {t(
              `campaignDetails.offers.offer.product.quantity.lowStockRemaining`,
              { count: dynamicAvailableQuantityItemsLeft },
            )}
          </span>
        </div>
      )}

      {isSubventionQuantityExceeded && (
        <div className="-mt-5 mb-6 flex h-10 items-center">
          <span className="text-tiny font-medium text-red">
            {t(`campaignDetails.offers.offer.subventionQuantityExceeded`)}
          </span>
        </div>
      )}

      {(!!offer.product.expiresAt || !!offer.product.expiresInDays) && (
        <div className="mb-6">
          <OfferExpiration offer={offer} />
        </div>
      )}

      <Button
        type="button"
        fluid
        disabled={
          isLocaleOutOfStock ||
          offerExpired ||
          (isPriceRangeType && !customAmount) ||
          (hasDynamicPrice && !currentDynamicPrice)
        }
        onClick={handleOrderButtonClicked}
        loading={loading || availablityLoading}
      >
        <div className="flex items-center">
          <span className="ml-6 text-sm font-semibold">
            {t('campaignDetails.offers.offer.product.button.addToCart')}
          </span>
          <Typography type="body" bold className="ml-0.5 w-12 text-left">
            {totalPrice}
          </Typography>
        </div>
      </Button>

      <Modal.Dialog
        description={t('namedTicketModal.description')}
        descriptionHidden
        onOpenChange={() => setShowNamedTicketModal(false)}
        opened={showNamedTicketModal}
        rootClassName="z-modal"
        title={t('namedTicketModal.title')}
      >
        <NamedTicketModal
          onSubmit={(item) => handleOrder(item)}
          quantity={quantity}
          onClose={() => setShowNamedTicketModal(false)}
          requiredFields={{
            firstName: !!offerMetadata.beneficiaryFirstNameRequired,
            lastName: !!offerMetadata.beneficiaryLastNameRequired,
            birthdate: !!offerMetadata.beneficiaryBirthdateRequired,
          }}
        />
      </Modal.Dialog>
    </div>
  );
};
