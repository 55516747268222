import { ReactNode } from 'react';

import { cx } from 'class-variance-authority';

export type FloatingOverlayProps = {
  children?: ReactNode;
  className?: string;
};

export const FloatingOverlay = (props: FloatingOverlayProps) => {
  const { className, children } = props;

  return (
    <div
      style={{
        backgroundColor: 'hsla(232, 15%, 30%, 0.5)',
      }}
      className={cx('z-drawer h-full w-full', className)}
    >
      {children}
    </div>
  );
};
