import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntercom } from 'react-use-intercom';

import { type Types } from '@amplitude/analytics-browser';
import { Button } from '@happypal-tech/design-system';
import { useAnalytics } from '@hooks/use-analytics';
import { useUserModeContext } from '@src/contexts/userMode/userMode.hooks';
import { TrackEvents } from '@src/features-new/analytics/constants/track-event.constant';
import { AdminModeToggle } from '@src/features-new/core/AppMenu/components/AdminModeToggle/AdminModeToggle';
import { CompanyPicture } from '@src/features-new/core/CompanyPicture/CompanyPicture';
import {
  getBackgroundColor,
  isBackgroundDark,
} from '@src/features-new/ui/background';
import { MenuCustomisationElementIcon } from '@src/graphql/generated/types';
import { Link } from '@tanstack/react-router';
import { cx } from 'class-variance-authority';
import { TFunction } from 'i18next';

import { AppMenuEntry } from './components/AppMenuEntry/AppMenuEntry';
import { AppMenuFolder } from './components/AppMenuFolder/AppMenuFolder';
import {
  AppMenuElementFragment,
  AppMenuFeaturesFragment,
  AppMenuFragment,
} from './AppMenu.generated';

interface AppMenuElementFragmentWithOnClick extends AppMenuElementFragment {
  onClick?: () => void;
}

interface AppMenusProps {
  className?: string;
  company: AppMenuFragment;
}

export const AppMenu = (props: AppMenusProps) => {
  const { className, company } = props;
  const { t } = useTranslation('core');
  const { isUserMode } = useUserModeContext();
  const mode = isUserMode ? 'user' : 'admin';
  const { track } = useAnalytics();

  const { identityCustomisation, menuCustomisation } = company.featureConfig;

  const background = getBackgroundColor(
    identityCustomisation.isEnabled,
    identityCustomisation.colorSecondary,
  );

  const backgroundIsDark = isBackgroundDark(background);

  const items = useMemo<AppMenuElementFragmentWithOnClick[]>(() => {
    if (mode === 'admin') {
      return getAdminItems(t, company);
    }
    if (menuCustomisation?.isEnabled) {
      return menuCustomisation.elements;
    }
    return getUserItems(t, track, company);
  }, [
    company,
    mode,
    menuCustomisation?.isEnabled,
    menuCustomisation.elements,
    track,
    t,
  ]);

  const viewerIsAdmin = company.viewerIsAdmin;
  const intercom = useIntercom();

  const handleHelpClick = () => {
    if (intercom.isOpen) {
      intercom.hide();
    } else {
      intercom.show();
    }
  };

  return (
    <div
      className={cx(className, 'overflow-y-auto overflow-x-hidden py-6')}
      style={{
        boxShadow: '0px 5px 24px rgba(0, 0, 0, 0.05)',
        background: background,
      }}
    >
      <div className="flex px-6">
        <Link
          to={mode === 'user' ? '/home' : '/admin/dashboard'}
          className="m-auto h-32 w-32 overflow-hidden rounded-xl"
        >
          <CompanyPicture logoUrl={company.logoUrl} />
        </Link>
      </div>
      {viewerIsAdmin && <AdminModeToggle value={mode} className="mt-6" />}
      <menu className="mt-6 flex flex-col gap-y-3 px-4 flex-1">
        {items.map((item) => {
          if (item.elements.length) {
            return (
              <AppMenuFolder
                isBackgroundDark={backgroundIsDark}
                folder={item}
                key={item.id}
              />
            );
          }
          return (
            <AppMenuEntry
              isBackgroundDark={backgroundIsDark}
              entry={item}
              key={item.id}
            />
          );
        })}
        <Link
          to={mode === 'admin' ? '/admin/suggestion' : '/suggestion'}
          className="mt-auto mb-0"
          onClick={() => {
            if (mode === 'admin') {
              track({
                type: 'visit submit idea admin',
              });
            } else {
              track({
                type: 'visit submit idea user',
              });
            }
          }}
        >
          <Button
            variant="ghost"
            iconRight="SparksOutline"
            fluid
            className="mb-0"
          >
            {t('components.appMenu.userItems.sendIdea')}
          </Button>
        </Link>
        <Button
          onClick={handleHelpClick}
          className="mb-0"
          variant="stroke"
          color="neutral"
        >
          {t('components.appMenu.userItems.needHelp')}
        </Button>
      </menu>
    </div>
  );
};

export function getAdminItems(
  t: TFunction<'core'>,
  company?: AppMenuFeaturesFragment | null,
): AppMenuElementFragmentWithOnClick[] {
  return [
    {
      label: t('components.appMenu.adminItems.dashboard'),
      icon: MenuCustomisationElementIcon.Home,
      href: '/admin/dashboard',
    },
    {
      label: t('components.appMenu.adminItems.communication'),
      icon: MenuCustomisationElementIcon.Communication,
      href: '/admin/relation',
      aliases: ['/admin/feed'],
      hidden: !company?.features?.communication,
    },
    {
      label: t('components.appMenu.adminItems.events'),
      icon: MenuCustomisationElementIcon.Calendar,
      href: '/admin/happenings',
      hidden: !company?.features?.happenings,
    },
    {
      label: t('components.appMenu.adminItems.gathering'),
      icon: MenuCustomisationElementIcon.Calendar,
      href: '/admin/gathering',
      hidden: !company?.featureConfig?.gathering.isEnabled,
    },
    {
      label: t('components.appMenu.adminItems.subventions'),
      icon: MenuCustomisationElementIcon.Subvention,
      href: '/admin/subventions',
      hidden: !company?.features?.subventions,
    },
    {
      label: t('components.appMenu.adminItems.users'),
      icon: MenuCustomisationElementIcon.Users,
      href: '/admin/users',
    },
    {
      label: t('components.appMenu.adminItems.company'),
      icon: MenuCustomisationElementIcon.Suitcase,
      href: '/admin/company',
      aliases: ['/admin/integrations'],
    },
  ]
    .filter((v) => !v.hidden)
    .map((v, i) => ({
      __typename: 'MenuCustomisationElement',
      id: i.toString(),
      elements: [],
      ...v,
    }));
}

export function getUserItems(
  t: TFunction<'core'>,
  track: (event: TrackEvents, options?: Types.EventOptions | undefined) => void,
  company?: AppMenuFeaturesFragment | null,
): AppMenuElementFragmentWithOnClick[] {
  return [
    {
      label: t('components.appMenu.userItems.home'),
      icon: MenuCustomisationElementIcon.Home,
      href: '/home',
      hidden: !company?.features.communication,
    },
    {
      label: t('components.appMenu.userItems.survey'),
      icon: MenuCustomisationElementIcon.Communication,
      href: '/survey',
      hidden: !company?.activeSurvey,
    },
    {
      label: t('components.appMenu.userItems.games'),
      icon: MenuCustomisationElementIcon.Game,
      href: '/game',
      hidden:
        !company?.features.games || (company?.gameCount.totalCount ?? 0) <= 0,
    },
    {
      label: t('components.appMenu.userItems.catalog'),
      icon: MenuCustomisationElementIcon.Search,
      href: '/discover',
      aliases: ['/catalog', '/explore'],
    },
    {
      label: t('components.appMenu.userItems.benefits'),
      icon: MenuCustomisationElementIcon.Advantage,
      href: '/advantages',
      hidden: !company?.features.subventions,
    },
    {
      label: t('components.appMenu.userItems.feed'),
      icon: MenuCustomisationElementIcon.Feed,
      href: '/feed',
      hidden: !company?.features?.communication,
    },
    {
      label: t('components.appMenu.userItems.events'),
      icon: MenuCustomisationElementIcon.Calendar,
      href: '/happenings',
      hidden: !company?.features?.happenings,
    },
    {
      label: t('components.appMenu.userItems.gathering'),
      icon: MenuCustomisationElementIcon.Calendar,
      href: '/gathering',
      hidden: !company?.featureConfig?.gathering.isEnabled,
    },
    {
      label: t('components.appMenu.userItems.happyMedia'),
      icon: MenuCustomisationElementIcon.Newspaper,
      href: '/partners/happymedia',
      onClick: () => {
        track({
          type: 'click happymedia',
        });
      },
      hidden: !company?.featureConfig?.happyMedia.isEnabled,
    },
  ]
    .filter((v) => !v.hidden)
    .map((v, i) => ({
      __typename: 'MenuCustomisationElement',
      id: i.toString(),
      elements: [],
      ...v,
    }));
}
