import { ComponentProps } from 'react';

import { BadgeStandalone } from '@happypal-tech/design-system';
import { cx } from 'class-variance-authority';

interface BadgeFilterProps
  extends Omit<ComponentProps<typeof BadgeStandalone>, 'count'> {}
export const BadgeFilter = ({ showDot, ...props }: BadgeFilterProps) => {
  return (
    <BadgeStandalone
      className={cx(
        'absolute top-[-0.125rem] right-[-0.125rem] border-[1.5px] content box-content',
      )}
      showDot={showDot}
      color="primary"
      {...props}
    />
  );
};
