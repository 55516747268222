import { ValuesFromZ } from '@happypal-tech/design-system';
import { createFileRoute, notFound } from '@tanstack/react-router';
import { z } from 'zod';

import {
  ReimbursementsRouteDocument,
  ReimbursementsRouteQuery,
  ReimbursementsRouteQueryVariables,
} from './~route.generated';

const validateSearch = z.object({
  subventionId: z.string().optional(),
  reimbursementId: z.string().optional(),
  drawerName: z.enum(['reimbursementFunnel', 'reimbursementDetail']).optional(),
});
export type ReimbursementRouteSearch = ValuesFromZ<typeof validateSearch> & {
  drawerTab: never;
};

export const Route = createFileRoute(
  '/_authenticated-layout/advantages/reimbursements',
)({
  loader: async ({ context }) => {
    const res = await context.apolloClient.query<
      ReimbursementsRouteQuery,
      ReimbursementsRouteQueryVariables
    >({
      query: ReimbursementsRouteDocument,
    });

    const viewer = res.data.viewer;

    if (!viewer) throw notFound();
    return { viewer };
  },
  validateSearch,
});
