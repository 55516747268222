import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import { LOCALES } from '@src/i18n/i18n';
import dayjs from 'dayjs';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
import duration, { DurationUnitType } from 'dayjs/plugin/duration';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import RelativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(LocalizedFormat);
dayjs.extend(RelativeTime);
dayjs.extend(CustomParseFormat);
dayjs.extend(duration);

export type Interval = {
  start: Date | number;
  end: Date | number;
};

export type FormatDurationUnitType = DurationUnitType;

export function addDays(date: Date | number, amount: number): Date {
  return dayjs(date).add(amount, 'day').toDate();
}

export function format(date: Date | number, format: string): string {
  return dayjs(date).format(format);
}

export function formatDistanceToNow(
  date: Date | number,
  addSuffix?: boolean,
): string {
  return dayjs(date).fromNow(!addSuffix);
}

export function formatDistanceStrict(
  date: Date | number,
  baseDate: Date | number,
): string {
  return dayjs(date).from(baseDate);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isValid(date: any): boolean {
  if (!date) return false;
  return dayjs(date).isValid();
}

export function isFuture(date: Date | number): boolean {
  return dayjs().isBefore(dayjs(date));
}

export function addYears(date: Date | number, amount: number): Date {
  return dayjs(date).add(amount, 'year').toDate();
}

export function subYears(date: Date | number, amount: number): Date {
  return dayjs(date).subtract(amount, 'year').toDate();
}

export function differenceInDays(
  dateLeft: Date | number,
  dateRight: Date | number,
): number {
  return dayjs(dateLeft).diff(dayjs(dateRight), 'day');
}

export function intervalToDuration(interval: Interval): Duration {
  return durationAsObject(
    dayjs.duration(dayjs(interval.end).diff(dayjs(interval.start))),
  );
}

export function durationAsObject(duration: plugin.Duration): Duration {
  return {
    days: duration.get('days'),
    hours: duration.get('hours'),
    minutes: duration.get('minutes'),
    months: duration.get('months'),
    seconds: duration.get('seconds'),
    years: duration.get('years'),
  };
}

export function formatDuration(
  duration: Duration,
  format?: DurationUnitType,
): string {
  const dayjsDuration = dayjs.duration(duration).get(format ?? 'hours');
  return dayjs.duration(dayjsDuration, format ?? 'hours').humanize();
}

export function setDateOptions(lng: LOCALES) {
  dayjs.locale(lng);
}

export function isSameDay(
  dateLeft: Date | number,
  dateRight: Date | number,
): boolean {
  return dayjs(dateLeft).isSame(dayjs(dateRight), 'day');
}

export function isAfter(
  date: Date | number,
  dateToCompare: Date | number,
): boolean {
  return dayjs(date).isAfter(dayjs(dateToCompare));
}

export function getYear(date: Date | number): number {
  return dayjs(date).year();
}

export function differenceInMonths(
  dateLeft: Date | number,
  dateRight: Date | number,
): number {
  return dayjs(dateLeft).diff(dayjs(dateRight), 'month');
}

export function subDays(date: Date | number, amount: number): Date {
  return dayjs(date).subtract(amount, 'day').toDate();
}

export function differenceInYears(
  dateLeft: Date | number,
  dateRight: Date | number,
): number {
  return dayjs(dateLeft).diff(dayjs(dateRight), 'year');
}

export function isBefore(
  date: Date | number,
  dateToCompare: Date | number,
): boolean {
  return dayjs(date).isBefore(dayjs(dateToCompare));
}

export function differenceInHours(
  dateLeft: Date | number,
  dateRight: Date | number,
): number {
  return dayjs(dateLeft).diff(dayjs(dateRight), 'hour');
}

export function isPast(date: Date | number): boolean {
  return dayjs(date).isBefore(dayjs());
}

export function parse(dateString: string, formatString: string): Date {
  return dayjs(dateString, formatString).toDate();
}

export function isEqual(
  dateLeft: Date | number,
  dateRight: Date | number,
): boolean {
  return dayjs(dateLeft).isSame(dayjs(dateRight));
}

export function parseISO(argument: string): Date {
  return dayjs(argument).toDate();
}

export function formatISO(date: Date | number): string {
  return dayjs(date).format('YYYY-MM-DD');
}

export function isThisYear(date: Date | number): boolean {
  return dayjs(date).isSame(dayjs(), 'year');
}

export function isSameMonth(
  dateLeft: Date | number,
  dateRight: Date | number,
): boolean {
  return dayjs(dateLeft).isSame(dayjs(dateRight), 'month');
}

export function eachMonthOfInterval(interval: Interval): Date[] {
  const months: Date[] = [];
  let start = dayjs(interval.start).day(0);
  const end = dayjs(interval.end);
  months.push(start.toDate());
  while (start.isBefore(end)) {
    start = start.add(1, 'month');
    months.push(start.toDate());
  }
  return months;
}

export function addMonths(date: Date | number, amount: number): Date {
  return dayjs(date).add(amount, 'month').toDate();
}

export function subMonths(date: Date | number, amount: number): Date {
  return dayjs(date).subtract(amount, 'month').toDate();
}

export function differenceInCalendarYears(
  dateLeft: Date | number,
  dateRight: Date | number,
): number {
  return dayjs(dateLeft).get('years') - dayjs(dateRight).get('years');
}

export function getMonth(date: Date | number): number {
  return dayjs(date).month();
}
