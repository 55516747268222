import { setContext } from '@apollo/client/link/context';

export const geoLink = setContext(async (_request, previousContext) => {
  // FIXME: Try not to call localStorage.getItem with every request
  return {
    ...previousContext,
    headers: {
      ...previousContext.headers,
      ['viewer-latitude']: localStorage.getItem('userPosition:latitude'),
      ['viewer-longitude']: localStorage.getItem('userPosition:longitude'),
    },
  };
});
