import { ElementRef, forwardRef } from 'react';

import { Badge } from '@components/molecules/Badge/Badge';
import { Button } from '@happypal-tech/design-system';

import { NotificationButtonFragment } from './NotificationButton.generated';

interface NotificationButtonProps {
  className?: string;
  type?: 'filled' | 'transparent';
  onClick: () => void;
  viewer?: NotificationButtonFragment;
  isBackgroundDark?: boolean;
}

export const NotificationButton = forwardRef<
  ElementRef<typeof Button>,
  NotificationButtonProps
>(
  (
    { onClick, type = 'transparent', className, viewer, isBackgroundDark },
    forwardedRef,
  ) => {
    const unreadCount = viewer?.notificationsPagination?.unreadCount ?? 0;
    const formattedUnreadCount = unreadCount > 99 ? '99+' : unreadCount;

    const containerClass =
      type === 'filled'
        ? 'rounded-lg w-12 h-12 flex items-center justify-center'
        : '';

    return (
      <div
        className={`relative z-10 ${containerClass} ${
          className ? className : ''
        }`}
      >
        <Badge
          value={formattedUnreadCount || undefined}
          style="danger"
          isOutline={false}
        >
          <Button
            ref={forwardedRef}
            icon="NotificationOutline"
            variant="ghost"
            onClick={onClick}
            color={isBackgroundDark ? 'primary' : 'neutral'}
          />
        </Badge>
      </div>
    );
  },
);
