import ContentLoader from 'react-content-loader';
import { useTranslation } from 'react-i18next';

import { LogoImage } from '@assets/images';
import { ProgressivePicture } from '@components/atoms/ProgressivePicture';
import { ProgressivePictureFragment } from '@components/atoms/ProgressivePicture/ProgressivePicture.generated';
import { NotificationType } from '@graphql/generated/types';
import { formatDistanceToNow } from '@services/dateManager.service';
import { ViewerNotificationFragment } from '@src/components/organisms/Notifications/ViewerNotifications.generated';
import { NotificationLink } from '@src/features-new/core/notifications/NotificationLink';
import { cx } from 'class-variance-authority';

import { getNotificationI18n } from './Notification.i18n';

export interface NotificationProps {
  size?: NotificationSize;
  id: string;
  from?: string;
  type: NotificationType;
  picture?: ProgressivePictureFragment | string;
  title: string;
  content?: string;
  read: boolean;
  createdAt: Date;
  onNotificationClick?: (notificationId: string) => void;
  notification: ViewerNotificationFragment;
}

export enum NotificationSize {
  SMALL = 'small',
  FULL = 'full',
}

export const Notification = ({
  size = NotificationSize.SMALL,
  from,
  type,
  picture,
  title,
  content,
  read,
  createdAt,
  notification,
  onNotificationClick,
}: NotificationProps) => {
  const { t } = useTranslation();
  let contentTranslation: string;
  const isSmall = size === NotificationSize.SMALL;

  if (!content) {
    contentTranslation = getNotificationI18n(t)[type].content;
  } else {
    contentTranslation = content;
  }

  const notificationContent = (
    <>
      {!isSmall && (
        <div className="text-tiny font-bold">
          {t('notifications.notificationCenter.from', 'De {{author}}', {
            author: from || 'HappyPal',
          })}
        </div>
      )}
      <div
        className={`relative ${isSmall ? 'px-5 py-5 pb-0 pt-4' : 'mb-2 pt-4'}`}
      >
        <div
          className={`flex items-start break-words border-b border-blue-light ${
            isSmall ? 'pb-3 pr-4' : 'pb-5 pr-4'
          }`}
        >
          {notificationPicture()}
          <div className={`flex flex-col ${isSmall ? 'ml-3' : 'ml-6 mt-2'}`}>
            <div
              className={`text-xs uppercase tracking-wider text-grey ${
                isSmall ? 'pb-0.5' : 'pb-1'
              }`}
            >
              {getNotificationI18n(t)[type].name}
            </div>
            <div
              className={`line-clamp-2 font-display text-md font-bold group-hover:underline ${
                isSmall ? 'pb-0.5' : 'pb-1'
              }`}
              style={{
                overflowWrap: 'anywhere', // Not present in tailwind
              }}
            >
              {title}
            </div>
            <div
              className={`${read ? 'text-grey' : 'text-black'} ${
                isSmall ? 'text-tiny ' : 'text-sm text-grey'
              }`}
            >
              {contentTranslation}
            </div>
          </div>
        </div>

        <div className="absolute right-4 top-4 flex items-center text-tiny text-grey">
          <span className="md:hidden">
            {formatDistanceToNow(createdAt, false)}
          </span>
          <span className="hidden md:inline">
            {formatDistanceToNow(createdAt, !isSmall)}
          </span>
          {!read && (
            <span
              className={cx('block h-2 w-2 rounded-full bg-blue', {
                'ml-1': isSmall,
                'ml-3': !isSmall,
              })}
            />
          )}
        </div>
      </div>
    </>
  );
  return (
    <NotificationLink
      className="w-full"
      title={title}
      notification={notification}
      onClick={onNotificationClick}
    >
      {notificationContent}
    </NotificationLink>
  );

  function notificationPicture() {
    const isIcon = typeof picture === 'string';

    if (picture && isIcon) {
      return (
        <div
          className={cx(
            'inline-flex flex-shrink-0 items-center justify-center rounded-md bg-grey-light px-2',
            {
              'mt-2 h-[52px] w-8': isSmall,
              'h-20 w-[62px]': !isSmall,
            },
          )}
        >
          <img src={picture} alt={title} className="w-10" />
        </div>
      );
    } else if (picture && !isIcon) {
      return (
        <ProgressivePicture
          picture={picture}
          alt={title}
          className={`rounded-md object-cover ${
            isSmall ? 'mt-2 h-[52px] w-8' : 'h-20 w-[62px]'
          }`}
          dimension={
            isSmall
              ? { width: 'w-8', height: 'h-[52px]' }
              : { width: 'w-[62px]', height: 'h-20' }
          }
        />
      );
    } else {
      return (
        <img
          src={LogoImage}
          alt={title}
          className={`rounded-md object-contain ${
            isSmall ? 'mt-2 h-[52px] w-8' : 'h-20 w-[62px]'
          }`}
        />
      );
    }
  }
};

export const SkeletonMiniNotification = () => {
  return (
    <ContentLoader
      speed={1}
      width="100%"
      height={88}
      backgroundColor="#F8F9FB"
      foregroundColor="#CCCFE1"
    >
      <rect x="0" y="10" rx="4" ry="4" width="32" height="52" />
      <rect x="44" y="4" rx="4" ry="4" width="180" height="18" />
      <rect x="44" y="27" rx="4" ry="4" width="200" height="18" />
      <rect x="44" y="50" rx="4" ry="4" width="250" height="18" />
      <rect x="0" y="80" rx="4" ry="4" width="100%" height="1" />
    </ContentLoader>
  );
};

export const SkeletonLargeNotification = () => {
  return (
    <ContentLoader
      speed={1}
      width="100%"
      height={143}
      backgroundColor="#F8F9FB"
      foregroundColor="#CCCFE1"
    >
      <rect x="0" y="0" rx="4" ry="4" width="100" height="18" />
      <rect x="0" y="34" rx="4" ry="4" width="62" height="80" />
      <rect x="86" y="42" rx="4" ry="4" width="180" height="18" />
      <rect x="86" y="65" rx="4" ry="4" width="200" height="18" />
      <rect x="86" y="88" rx="4" ry="4" width="250" height="18" />
      <rect x="0" y="132" rx="4" ry="4" width="100%" height="1" />
    </ContentLoader>
  );
};
