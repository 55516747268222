import { useTranslation } from 'react-i18next';

import { Button } from '@happypal-tech/design-system';
import { useCountSearchRefinements } from '@src/features-new/catalog/hooks/useCountSearchRefinements';
import { cn } from '@src/features-new/ui/cn';
import { Link } from '@tanstack/react-router';

export const ButtonClearRefinementsMobile = () => {
  const { t } = useTranslation('catalog', {
    keyPrefix: 'components.ButtonClearRefinements',
  });

  const count = useCountSearchRefinements();

  return (
    <Link
      className={cn('md:hidden', count === 0 && 'invisible')}
      from="/catalog"
      to="/catalog"
      search={(prev) => (prev.q ? { q: prev.q } : {})}
    >
      <Button type="button" variant="ghost" color="primary">
        {t('label', { count })}
      </Button>
    </Link>
  );
};
