import * as amplitude from '@amplitude/analytics-browser';
import { setContext } from '@apollo/client/link/context';

export const amplitudeLink = setContext(async (_request, previousContext) => {
  const sessionId = amplitude.getSessionId();
  const deviceId = amplitude.getDeviceId();

  const payload: Record<string, string> = {};

  if (sessionId) {
    payload['amplitude-session-id'] = sessionId.toString();
  }

  if (deviceId) {
    payload['amplitude-device-id'] = deviceId;
  }

  return {
    ...previousContext,
    headers: {
      ...previousContext.headers,
      ...payload,
    },
  };
});
