import { CampaignDetails } from '@components/organisms/CampaignDetails';
import { NotFoundState } from '@src/features-new/core/DefaultNotFound/NotFoundState/NotFoundState';
import { useAnalytics } from '@src/hooks/use-analytics';

import { useCampaignDetailsQuery } from './CampaignDetailsPage.generated';

type CampaignDetailsPageProps = {
  campaignId: string;
  queryID?: string;
};

export const CampaignDetailsPageComponent = (
  props: CampaignDetailsPageProps,
) => {
  const { track } = useAnalytics();
  const { campaignId, queryID } = props;

  const { data, loading, error } = useCampaignDetailsQuery({
    onCompleted: (data) => {
      if (!data.catalog.campaign) return;

      track({
        type: 'view campaign page',
        payload: {
          campaign_id: data.catalog.campaign.id,
          campaign_name: data.catalog.campaign.name,
          brand_id: data.catalog.campaign.brand.id,
          brand_name: data.catalog.campaign.brand.name,
        },
      });
    },
    variables: {
      campaignId,
    },
  });

  if (error || !campaignId || (!data?.catalog.campaign && !loading)) {
    return (
      <div className="mt-10 flex justify-center w-full">
        <NotFoundState />
      </div>
    );
  }

  return (
    <div className="flex h-full w-full flex-col items-center">
      <div className="w-full max-w-306 flex-grow">
        <CampaignDetails
          campaignId={campaignId}
          campaign={data?.catalog.campaign || null}
          loading={loading}
          queryID={queryID}
        />
      </div>
    </div>
  );
};
