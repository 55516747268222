import { ReactNode } from 'react';

import {
  NotificationTargetArticleFragment,
  NotificationTargetDocumentFragment,
  NotificationTargetOfferFragment,
  NotificationTargetPublicationFragment,
  ViewerNotificationFragment,
} from '@src/components/organisms/Notifications/ViewerNotifications.generated';
import { NotificationType } from '@src/graphql/generated/types';
import { Link } from '@tanstack/react-router';
import { cx } from 'class-variance-authority';

interface NotificationTrackerButtonProps {
  id: string;
  title: string;
  type: NotificationType;
  children: ReactNode;
  onClick?: (id: string) => void;
  className?: string;
}
const NotificationTrackerButton = ({
  id,
  children,
  onClick,
  className,
}: NotificationTrackerButtonProps) => {
  return (
    <button
      className={cx('text-left', className)}
      onClick={() => {
        onClick?.(id);
      }}
    >
      {children}
    </button>
  );
};

interface NotificationLinkProps {
  notification: ViewerNotificationFragment;
  children: ReactNode;
  className?: string;
  title: string;
  onClick?: (id: string) => void;
}

export const NotificationLink = ({
  notification,
  className,
  children,
  onClick,
  title,
}: NotificationLinkProps) => {
  const { type, id } = notification;

  if (type === NotificationType.SubventionPublished) {
    return (
      <NotificationTrackerButton
        onClick={onClick}
        title={title}
        type={type}
        id={id}
        className={className}
      >
        <Link to="/advantages" className={className}>
          {children}
        </Link>
      </NotificationTrackerButton>
    );
  }
  if (type === NotificationType.OfferPublished) {
    const target = notification.target as NotificationTargetOfferFragment;
    const campaignId = target.campaign?.id;
    if (!campaignId) {
      throw new Error('NotificationLink: no campaignId');
    }
    return (
      <NotificationTrackerButton
        onClick={onClick}
        title={title}
        type={type}
        id={id}
        className={className}
      >
        <Link
          to="/explore/$campaignId"
          params={{ campaignId }}
          className={className}
        >
          {children}
        </Link>
      </NotificationTrackerButton>
    );
  }
  if (
    [
      NotificationType.BeneficiaryAccepted,
      NotificationType.BeneficiaryRejected,
      NotificationType.BeneficiaryDeleted,
      NotificationType.BeneficiaryRejectedFollowUp,
    ].includes(type)
  ) {
    return (
      <NotificationTrackerButton
        onClick={onClick}
        title={title}
        type={type}
        id={id}
        className={className}
      >
        <Link to="/account/beneficiaries" className={className}>
          {children}
        </Link>
      </NotificationTrackerButton>
    );
  }
  if (type === NotificationType.ArticlePublished) {
    const target = notification.target as NotificationTargetArticleFragment;
    const articleId = target.id;
    return (
      <NotificationTrackerButton
        onClick={onClick}
        title={title}
        type={type}
        id={id}
        className={className}
      >
        <Link
          to="/home/articles/$articleId"
          params={{ articleId }}
          className={className}
        >
          {children}
        </Link>
      </NotificationTrackerButton>
    );
  }
  if (type === NotificationType.PublicationPublished) {
    const target = notification.target as NotificationTargetPublicationFragment;
    const publicationId = target.id;
    return (
      <NotificationTrackerButton
        onClick={onClick}
        title={title}
        type={type}
        id={id}
        className={className}
      >
        <Link
          to="/home/publications/$publicationId"
          params={{ publicationId }}
          className={className}
        >
          {children}
        </Link>
      </NotificationTrackerButton>
    );
  }
  if (type === NotificationType.DocumentPublished) {
    const target = notification.target as NotificationTargetDocumentFragment;
    const folderId = target.parent.id;
    return (
      <NotificationTrackerButton
        onClick={onClick}
        title={title}
        type={type}
        id={id}
        className={className}
      >
        <Link
          to="/home/documents/$folderId"
          params={{ folderId }}
          className={className}
        >
          {children}
        </Link>
      </NotificationTrackerButton>
    );
  }
  if (type === NotificationType.UserEmailChanged) {
    return (
      <NotificationTrackerButton
        onClick={onClick}
        title={title}
        type={type}
        id={id}
        className={className}
      >
        <Link to="/account/" className={className}>
          {children}
        </Link>
      </NotificationTrackerButton>
    );
  }
  if (type === NotificationType.SubventionInvoiceCreated) {
    return (
      <NotificationTrackerButton
        onClick={onClick}
        title={title}
        type={type}
        id={id}
        className={className}
      >
        <Link to="/admin/company/subventions-invoices" className={className}>
          {children}
        </Link>
      </NotificationTrackerButton>
    );
  }
  return <div>{children}</div>;
};
