import { format } from '@services/dateManager.service';
import { withScalars } from 'apollo-link-scalars';
import { buildClientSchema, IntrospectionQuery } from 'graphql';

import introspectionResult from '../generated/schema.json';

const schema = buildClientSchema(
  introspectionResult as unknown as IntrospectionQuery,
);

export const scalarsLink = withScalars({
  schema,
  typesMap: {
    // @ts-expect-error FIXME
    DateTime: {
      serialize: (date: Date | string) =>
        typeof date === 'string' ? date : date.toISOString(),
      parseValue: (date: string) => new Date(date),
    },
    // @ts-expect-error FIXME
    Date: {
      serialize: (date: Date | string) => {
        const serialized =
          typeof date === 'string' ? date : format(date, 'YYYY-MM-DD');
        return serialized;
      },
      parseValue: (date: string) =>
        new Date(new Date(date).setHours(0, 0, 0, 0)),
    },
  },
});
