import { ReactNode } from 'react';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

import { cx } from 'class-variance-authority';

type ResponsiveSkeletonElement = {
  contentLoader: IContentLoaderProps;
  children: ReactNode;
};

type ResponsiveSkeletonProps = {
  sm?: ResponsiveSkeletonElement;
  md?: ResponsiveSkeletonElement;
  lg?: ResponsiveSkeletonElement;
  xl?: ResponsiveSkeletonElement;
};

export const ResponsiveSkeleton = (props: ResponsiveSkeletonProps) => {
  const { sm, md, lg, xl } = props;
  return (
    <>
      {sm && (
        <span
          className={cx({
            'md:hidden': !!md,
            'lg:hidden': !!lg,
            'xl:hidden': !!xl,
          })}
        >
          <ContentLoader
            speed={1}
            backgroundColor="#F8F9FB"
            foregroundColor="#CCCFE1"
            {...sm.contentLoader}
          >
            {sm.children}
          </ContentLoader>
        </span>
      )}
      {md && (
        <span
          className={cx('hidden md:block', {
            'lg:hidden': !!lg,
            'xl:hidden': !!xl,
          })}
        >
          <ContentLoader
            speed={1}
            backgroundColor="#F8F9FB"
            foregroundColor="#CCCFE1"
            {...md.contentLoader}
          >
            {md.children}
          </ContentLoader>
        </span>
      )}
      {lg && (
        <span
          className={cx('hidden lg:block', {
            'xl:hidden': !!xl,
          })}
        >
          <ContentLoader
            speed={1}
            backgroundColor="#F8F9FB"
            foregroundColor="#CCCFE1"
            {...lg.contentLoader}
          >
            {lg.children}
          </ContentLoader>
        </span>
      )}
      {xl && (
        <span className="hidden xl:block">
          <ContentLoader
            speed={1}
            backgroundColor="#F8F9FB"
            foregroundColor="#CCCFE1"
            {...xl.contentLoader}
          >
            {xl.children}
          </ContentLoader>
        </span>
      )}
    </>
  );
};
