import { useTranslation } from 'react-i18next';

import { Text } from '@src/components/atoms/Text';
import { cx } from 'class-variance-authority';

type OfferExpiredProps = {
  className?: string;
};

export const OfferExpired = ({ className }: OfferExpiredProps) => {
  const { t } = useTranslation();
  return (
    <Text type="bodySmallTextBold" className={cx('text-red', className)}>
      {t('campaignDetails.offers.offer.expired', 'Offre expirée !')}
    </Text>
  );
};
