import { ElementRef, forwardRef, ReactNode, useMemo } from 'react';

import * as RPopover from '@radix-ui/react-popover';
import { cn } from '@src/features-new/ui/cn';

export const PopoverRoot = RPopover.Root;
export const PopoverTrigger = RPopover.Trigger;
export const PopoverPortal = RPopover.Portal;

interface PopoverContentProps {
  className?: string;
  /** @default bottom */
  side?: 'top' | 'right' | 'bottom' | 'left';
  /** @default center */
  align?: 'start' | 'center' | 'end';
  onOpenAutoFocus?: RPopover.PopoverContentImplProps['onOpenAutoFocus'];
  children: ReactNode;
}
export const PopoverContent = forwardRef<
  ElementRef<typeof RPopover.Content>,
  PopoverContentProps
>(
  (
    { className, side = 'bottom', align = 'center', children, onOpenAutoFocus },
    forwardedRef,
  ) => {
    const { hasXPadding, hasYPadding, hasWidth } = useMemo(() => {
      const hasXPadding = !!className?.match(/(\s|^)(p(x|l|r)?-\d+)/);
      const hasYPadding = !!className?.match(/(\s|^)(p(y|t|b)?-\d+)/);
      const hasW = !!className?.match(/(\s|^)(w-\S+)/);

      return {
        hasXPadding,
        hasYPadding,
        hasWidth: hasW,
      };
    }, [className]);
    return (
      <RPopover.Content
        ref={forwardedRef}
        className={cn(
          className,
          'overflow-auto rounded-[10px] bg-white shadow-md',
          'max-h-[calc(var(--radix-popper-available-height)_-_16px)]',
          {
            'px-5': !hasXPadding,
            'py-4': !hasYPadding,
            'w-[var(--radix-popper-anchor-width)] min-w-[320px]': !hasWidth,
          },
        )}
        sideOffset={4}
        side={side}
        align={align}
        onOpenAutoFocus={onOpenAutoFocus}
      >
        {children}
      </RPopover.Content>
    );
  },
);

export const PopoverArrow = () => (
  <RPopover.Arrow height={8} width={16} className="fill-white" />
);
