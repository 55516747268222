import { createFileRoute, Navigate } from '@tanstack/react-router';
import { z } from 'zod';

export const Route = createFileRoute(
  '/auth/_auth-carousel-layout/reset-password',
)({
  validateSearch: z.object({
    token: z.string(),
  }),
  errorComponent: () => <Navigate to="/auth/login" />,
});
