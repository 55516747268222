import { useSearch } from '@tanstack/react-router';

export const useCountSearchRefinements = () => {
  const { lat, lng, radius, q, ...rest } = useSearch({
    from: '/_authenticated-layout/catalog/',
  });

  const hasGeo = lat && lng && radius;
  const geoCount = hasGeo ? 1 : 0;
  const count = geoCount + Object.values(rest).flatMap((value) => value).length;

  return count;
};
