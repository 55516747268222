import { useFormatPrice } from '@utils/hooks/useFormatPrice';

type OfferOldPriceProps = {
  amount: number;
  currency: string;
};

export const OfferOldPrice = ({ amount, currency }: OfferOldPriceProps) => {
  const formatPrice = useFormatPrice(currency);

  return (
    <span className="font-display text-sm font-semibold text-grey line-through">
      {formatPrice(amount)}
    </span>
  );
};
