import {
  AdminCompanyRouteDocument,
  AdminCompanyRouteQuery,
  AdminCompanyRouteQueryVariables,
} from '@src/routes/_authenticated-layout/admin/company/~route.generated';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_authenticated-layout/admin/company')({
  beforeLoad: async ({ context }) => {
    const { data } = await context.apolloClient.query<
      AdminCompanyRouteQuery,
      AdminCompanyRouteQueryVariables
    >({
      query: AdminCompanyRouteDocument,
    });

    const company = data.viewer?.company;

    return {
      ...context,
      company: {
        ...context.company!,
        featureConfig: {
          ...context.company?.featureConfig,
          ...company?.featureConfig,
        },
      },
    };
  },
});
