import { Chip, ChipProps, Icon, IconName } from '@happypal-tech/design-system';
import { cx } from 'class-variance-authority';

export type DetailsKeyPointsProps = {
  className?: string;
  title: string;
  tags: { label: ChipProps['label']; type: IconName }[];
};

export function DetailsKeyPoints(props: DetailsKeyPointsProps) {
  const { className, tags, title } = props;

  return (
    <div className={cx(className, 'flex items-center')}>
      <div className="hidden md:block">
        <Icon name="AlignLeftLightOutline" size={24} className="text-primary" />
      </div>
      <div className="md:mr-5" />
      <div className="flex flex-col">
        <h3 className="hidden text-base font-bold text-black md:block">
          {title}
        </h3>
        <div className="mb-3 mt-2 flex flex-wrap gap-2">
          {tags.map(({ label, type }, index) => {
            return label ? (
              <Chip
                key={`${label}-${index}`}
                label={label}
                color="black"
                iconLeft={type}
                className="overflow-hidden"
              />
            ) : null;
          })}
        </div>
      </div>
    </div>
  );
}
