import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { env } from '@env';
import { Button, Modal, Typography } from '@happypal-tech/design-system';
import { useAnalytics } from '@src/hooks/use-analytics';
import { Link, useNavigate } from '@tanstack/react-router';

interface AppMenuModalGDPRProps {
  redirect?: string;
}

export const AppMenuModalGDPR = (props: AppMenuModalGDPRProps) => {
  const { track } = useAnalytics();
  const { redirect = '/' } = props;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation('core', {
    keyPrefix: 'components.appMenu.modalGDPR',
  });
  const HAPPY_MEDIA_HREF = new URL(
    `/v1/happy-media/redirect?returnTo=${redirect}`,
    env.REACT_APP_API_URL,
  ).toString();

  const handleClick = () => {
    track({
      type: 'click continue happymedia',
    });
    setLoading(true);
    window.location.href = HAPPY_MEDIA_HREF;
  };

  const onOpenChange = () => {
    navigate({ to: '/' });
  };

  return (
    <Modal.Drawer
      opened
      onOpenChange={onOpenChange}
      title={t('title')}
      description={t('description')}
      descriptionHidden={true}
      rootClassName="z-modal"
    >
      <div className="flex flex-col gap-4">
        <Typography type="body" className="whitespace-pre-line">
          <Trans
            t={t}
            i18nKey="description"
            components={{
              1: <strong />,
              2: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  className="text-primary underline"
                  href="https://www.happypal.fr/politique-de-confidentialite"
                  target="_blank"
                  rel="noreferrer noopener"
                />
              ),
            }}
          />
        </Typography>
        <div className="flex gap-4 pt-4">
          <Link to="/" className="w-full">
            <Button type="button" color="neutral" variant="stroke" fluid>
              {t('actions.cancel')}
            </Button>
          </Link>
          <Button
            type="button"
            color="primary"
            fluid
            onClick={handleClick}
            loading={loading}
          >
            {t('actions.submit')}
          </Button>
        </div>
      </div>
    </Modal.Drawer>
  );
};
