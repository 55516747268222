import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Highlighter, Typography } from '@happypal-tech/design-system';
import { FavoritesOrigin } from '@src/features-new/analytics/constants/track-event.constant';
import { ChipOfferType } from '@src/features-new/core/ChipOfferType';
import { FavoriteBadge } from '@src/features-new/favorites/components/FavoriteBadge/FavoriteBadge';
import { sortOfferTypes } from '@src/features-new/utils/offerType';
import { OfferType } from '@src/graphql/generated/types';
import { cx } from 'class-variance-authority';

const MAX_CATEGORIES = 2;

export type Suggestion = {
  objectID: string;
  campaignThumbnailUrl?: string;
  types: OfferType[];
  categories: string[];
  name: string;
};

interface SuggestionsEntryProps extends Omit<Suggestion, 'objectID'> {
  inputValue: string;
  isFavorite: boolean;
  campaignId: string;
  isHighlighted?: boolean;
}
export const SuggestionsEntry = (props: SuggestionsEntryProps) => {
  const {
    campaignThumbnailUrl,
    types,
    categories,
    name,
    inputValue,
    isFavorite,
    campaignId,
    isHighlighted,
  } = props;

  const { t } = useTranslation('core', {
    keyPrefix: 'components.CatalogSearchBar.suggestions.entry',
  });

  const displayedCategories = categories.slice(
    0,
    Math.min(MAX_CATEGORIES, categories.length),
  );
  const extraCategoriesCount = Math.max(categories.length - MAX_CATEGORIES, 0);
  const offerTypes = sortOfferTypes(types);
  return (
    <div className="flex gap-4 flex-grow">
      <div className="flex-1 flex gap-4 flex-grow">
        <img
          src={campaignThumbnailUrl ?? '/placeholder.svg'}
          className="flex h-14 w-14 p-1 shrink-0 justify-center object-cover shadow-small rounded-xl overflow-hidden bg-neutral-container border-2 border-neutral-lighter"
          alt=""
        />
        <div className="flex flex-col flex-grow">
          <div className="flex items-center">
            <Highlighter
              className="font-semibold text-neutral-darkest"
              highlightClassName="bg-transparent text-primary"
              search={inputValue}
              text={name}
            />
            <FavoriteBadge
              isFavorite={isFavorite}
              campaignName={name}
              campaignId={campaignId}
              variant="ghost"
              className={cx('hidden md:flex', {
                'opacity-0': !isFavorite && !isHighlighted,
              })}
              pageTrackingValue={FavoritesOrigin.suggestionsDropdown}
            />
          </div>
          <div className="flex items-center gap-2 flex-wrap">
            <Typography type="note" asChild>
              <p>
                {t('from')}
                <Typography
                  type="note"
                  semibold
                  className="text-neutral-darkest"
                  asChild
                >
                  <span>
                    {displayedCategories.join(',')}
                    {extraCategoriesCount > 0 && (
                      <>{t('extra', { count: extraCategoriesCount })}</>
                    )}
                  </span>
                </Typography>
              </p>
            </Typography>
            <div className="flex items-center gap-2">
              {offerTypes.map((type) => (
                <ChipOfferType
                  key={type}
                  color="neutral"
                  variant="ghost"
                  offerType={type}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <FavoriteBadge
        isFavorite={isFavorite}
        campaignName={name}
        campaignId={campaignId}
        variant="ghost"
        className="md:hidden"
        pageTrackingValue={FavoritesOrigin.suggestionsDropdown}
      />
    </div>
  );
};
