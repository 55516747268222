import { BaseSyntheticEvent, ReactNode } from 'react';
import {
  FieldNamesMarkedBoolean,
  FieldValues,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler as RHSubmitHandler,
  UnpackNestedValue,
} from 'react-hook-form';

import { AnyObjectSchema, Asserts } from 'yup';

import { UseFormReturn } from './useForm';
import { FormRulesProvider } from './useFormRules';

export type FormProps<TFieldValues extends FieldValues = FieldValues> = {
  id?: string;
  form: UseFormReturn<AnyObjectSchema, TFieldValues>;
  children: ReactNode;
  className?: string;
  onValid?: SubmitHandler<TFieldValues>;
  onInvalid?: SubmitErrorHandler<TFieldValues>;
  // The methods below will be implemeted if needed in the future. They'll allow the form to automatically handle mutations similar to how the BO does it.
  // onSuccess?: (
  //   result: M,
  //   data: UnpackNestedValue<TFieldValues>,
  // ) => void | Promise<void>;
  // onFailure?: (
  //   error: unknown,
  //   data: UnpackNestedValue<TFieldValues>,
  // ) => void | Promise<void>;
};

export type SubmitHandler<TFieldValues extends FieldValues> = (
  values: UnpackNestedValue<TFieldValues>,
  dirty: Partial<UnpackNestedValue<TFieldValues>>,
  event?: BaseSyntheticEvent,
) => void | Promise<void>;

export const Form = <T extends FieldValues = FieldValues>(
  props: FormProps<T>,
) => {
  const {
    children,
    className,
    form,
    onValid = () => {},
    onInvalid,
    id,
  } = props;
  const _triggerProxy = form.formState.dirtyFields; // make sure formState is read before render to enable the Proxy

  const handleValid: RHSubmitHandler<T> = async (values, event) => {
    // @ts-expect-error - fix me
    const dirty = dirtyValues(form.formState.dirtyFields, values);
    // @ts-expect-error - fix me
    await onValid(values, dirty, event);
  };

  return (
    <FormProvider {...form}>
      <FormRulesProvider validationSchema={form.validationSchema}>
        <form
          id={id}
          className={className}
          onSubmit={form.handleSubmit(handleValid, onInvalid)}
        >
          {children}
        </form>
      </FormRulesProvider>
    </FormProvider>
  );
};

export type FormValuesFromSchema<S extends AnyObjectSchema> = Asserts<S>;

// Map RHF's dirtyFields over the `data` received by `handleSubmit` and return the changed subset of that data.
export function dirtyValues<T extends FieldValues = FieldValues>(
  dirtyFields: FieldNamesMarkedBoolean<T>,
  allValues: UnpackNestedValue<T>,
): Partial<T> {
  // If *any* item in an array was modified, the entire array must be submitted, because there's no way to indicate
  // "placeholders" for unchanged elements. `dirtyFields` is true for leaves.
  if (dirtyFields === true || Array.isArray(dirtyFields)) return allValues;

  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [
      key,
      dirtyValues(dirtyFields[key], allValues[key]),
    ]),
  ) as Partial<T>;
}
