import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Picture } from '@happypal-tech/design-system';
import { LogoImage } from '@src/assets/images';
import { OnboardingCarousel } from '@src/components/organisms/OnboardingCarousel';
import { PageFooter } from '@src/features-new/auth/components/PageFooter';
import { cx } from 'class-variance-authority';

type AuthCarouselLayoutProps = {
  children: ReactNode;
  companyLogo?: string;
  companyName?: string;
};
export const AuthCarouselLayout = (props: AuthCarouselLayoutProps) => {
  const { children, companyLogo, companyName } = props;
  const { t } = useTranslation('auth', {
    keyPrefix: 'components.AuthCarouselLayout',
  });
  const [showLandingPage, setShowLandingPage] = useState(true);

  return (
    <div className="grid h-full grid-cols-1 md:grid-cols-3 min-h-screen">
      <div className="col-span-2 p-4 md:p-10 flex flex-col justify-between pt-safe pb-safe">
        <div className="w-[120px] h-[120px]">
          <Picture
            src={companyLogo ?? LogoImage}
            fit="contain"
            fitPosition="left"
            alt={companyName ?? 'Happypal logo'}
            className="h-full w-full text-left"
          />
        </div>
        <div className="grid h-full md:content-center">
          <div className="md:mx-auto md:w-full md:max-w-md min-h-[448px] flex flex-col">
            {children}
          </div>
        </div>
        <div
          className={cx(
            {
              flex: showLandingPage,
              hidden: !showLandingPage,
            },
            'absolute inset-0 z-10 h-full flex-col md:hidden bg-white',
          )}
        >
          <OnboardingCarousel className="bg-primary pt-safe" />
          <svg
            width="100%"
            viewBox="0 0 750 80"
            xmlns="http://www.w3.org/2000/svg"
            className="flex-none fill-primary stroke-primary md:hidden"
          >
            <path d="M 0 0 Q 375 130 750 0 M 0 0" />
          </svg>
          <div className="flex flex-col gap-4 p-4 md:hidden items-center">
            <Button
              fluid
              onClick={() => {
                setShowLandingPage(false);
              }}
            >
              {t('login')}
            </Button>
            <PageFooter classNames="md:hidden" />
          </div>
        </div>
        <PageFooter classNames="hidden md:flex" />
      </div>
      <OnboardingCarousel className="hidden md:flex bg-primary" />
    </div>
  );
};
