import { useTranslation } from 'react-i18next';

import { Button, Icon } from '@happypal-tech/design-system';
import { BadgeFilter } from '@src/features-new/catalog/components/BadgeFilter';
import { DropdownElementLine } from '@src/features-new/catalog/components/DropdownElement/Line';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@src/features-new/ui/DropdownMenu/DropdownMenu';

export const catalogSearchTag = [
  'ticket',
  'gift_card',
  'promo_code',
  'url',
] as const;

export type AlgoliaOfferType = (typeof catalogSearchTag)[number];

interface DropdownMenuOfferTypesProps {
  className?: string;
  onSelect: (type: AlgoliaOfferType) => void;
  activeOfferTypes: AlgoliaOfferType[];
}

export const DropdownMenuOfferTypes = (props: DropdownMenuOfferTypesProps) => {
  const { onSelect, activeOfferTypes } = props;

  const { t } = useTranslation('catalog', {
    keyPrefix: 'components.CatalogSearchFilters',
  });

  const { t: tOfferTypes } = useTranslation('core', {
    keyPrefix: 'offerTypes',
  });

  const count = activeOfferTypes.length;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="group" asChild>
        <Button variant="stroke" type="button" color="neutral">
          <div className="flex gap-2 items-center">
            {t('types')}
            <Icon
              name="ChevronUpOutline"
              size={16}
              className="transition-transform group-data-[state='closed']:-rotate-180"
            />
          </div>
          <BadgeFilter showDot={count !== 0} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-80 max-h-[28rem] overflow-auto">
        {catalogSearchTag.map((offerType) => (
          <DropdownElementLine
            key={offerType}
            id={offerType}
            label={tOfferTypes(offerType)}
            onSelect={() => onSelect(offerType)}
            isActive={activeOfferTypes.includes(offerType)}
          />
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
