import { ReactElement } from 'react';

import { useSelectedCompany } from '@src/hooks/authenticated-context/authenticated-context';

import { useRemainingSubventionsButtonQuery } from './RemainingSubventionsButton.generated';

interface ViewerRemainingSubventionProxyProps {
  children: (amount: number) => ReactElement;
}

export function ViewerRemainingSubventionProxy(
  props: ViewerRemainingSubventionProxyProps,
) {
  const { children } = props;
  const { data } = useRemainingSubventionsButtonQuery();
  const activeSubventionTotalAmount = data?.viewer?.activeSubventionTotalAmount;
  const company = useSelectedCompany();
  const activeSubventionConsumedAmount =
    data?.viewer?.activeSubventionConsumedAmount;
  if (
    typeof activeSubventionTotalAmount !== 'number' ||
    !company.features?.subventions
  ) {
    return null;
  }
  return children(
    activeSubventionTotalAmount - (activeSubventionConsumedAmount || 0),
  );
}
