import { useState } from 'react';

import { OfferExpiration } from '@src/components/molecules/OfferExpiration';
import { cx } from 'class-variance-authority';

import { CouponLink } from './CouponLink/CouponLink';
import { CouponClaimable } from './CouponClaimable';
import { CouponPublic } from './CouponPublic';
import { OfferCouponActionFragment } from './OfferCouponAction.generated';

type OfferCouponActionProps = {
  alreadyClaimed?: boolean;
  buttonLabel?: string;
  offer: OfferCouponActionFragment;
  offerExpired: boolean;
  queryID?: string;
  campaignId: string;
};

export const OfferCouponAction = ({
  alreadyClaimed,
  offer,
  buttonLabel,
  offerExpired,
  queryID,
  campaignId,
}: OfferCouponActionProps) => {
  let initCouponValue: string | undefined;
  if (offer.__typename === 'OfferCouponClaimable') {
    const node =
      offer.viewerClaimedCouponsPagination?.totalCount > 0 &&
      offer.viewerClaimedCouponsPagination.nodes[0];
    if (node && node.claimedAt) {
      if (alreadyClaimed) {
        initCouponValue = node.value;
      } else {
        const claimInterval = (offer.claimInterval || 0) * 1000 * 60;
        const claimAgainDate = new Date(
          new Date(node.claimedAt || 0).getTime() + claimInterval,
        );
        if (claimAgainDate > new Date()) {
          initCouponValue = node.value;
        }
      }
    }
  } else if (offer.__typename === 'OfferCouponPublic') {
    initCouponValue = offer.coupon?.value;
  }

  const [couponValue, setCouponValue] = useState(initCouponValue);

  const updateCouponValue = (couponValue: string | undefined) =>
    setCouponValue(couponValue);

  const checkOfferExpiration = () => {
    switch (offer?.__typename) {
      case 'OfferCouponClaimable':
        return !!offer.expiresAt;
        break;
      case 'OfferCouponPublic':
        return !!offer.coupon?.expiresAt;
        break;
      default:
        return false;
    }
  };

  const offerHasExpiration = checkOfferExpiration();

  return (
    <>
      {couponValue && (
        <CouponPublic
          expired={offerExpired}
          buttonLabel={buttonLabel}
          couponValue={couponValue}
          queryID={queryID}
          campaignId={campaignId}
          offerUrl={offer.url?.href || ''}
        />
      )}
      {offer.__typename === 'OfferCouponClaimable' && !couponValue && (
        <CouponClaimable
          offer={offer}
          queryID={queryID}
          campaignId={campaignId}
          expired={offerExpired}
          updateCouponValue={updateCouponValue}
          claimedCouponsCount={offer.viewerClaimedCouponsPagination.totalCount}
        />
      )}
      {offer.__typename === 'OfferUrl' && (
        <CouponLink campaignId={campaignId} queryID={queryID} offer={offer} />
      )}
      <div className="mb-4" />
      {offerHasExpiration && (
        <div className={cx({ 'md:flex md:justify-end': alreadyClaimed })}>
          <OfferExpiration offer={offer} />
        </div>
      )}
    </>
  );
};
