import React, {
  createContext,
  createRef,
  ReactNode,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';

import { isRouteUserMode } from '@src/utils/isRouteUserMode';
import { useRouterState } from '@tanstack/react-router';

interface UserModeContextProps {
  isUserMode: boolean;
}

const UserModeContext = createContext<UserModeContextProps | null>(null);

export const UserModeContextRef = createRef<UserModeContextProps | null>();

export const useUserModeContext = () => {
  const userModeContext = useContext(UserModeContext);

  if (userModeContext === null) {
    throw new Error(
      'useUserModeContext must be used within a UserModeContextProvider',
    );
  }
  return userModeContext;
};

interface UserModeContextProviderProps {
  children: ReactNode;
}
export const UserModeContextProvider = ({
  children,
}: UserModeContextProviderProps) => {
  const router = useRouterState();
  const currentPathname = router.location.pathname;

  const [isUserMode, setIsUserMode] = useState<boolean>(
    isRouteUserMode(currentPathname),
  ); // we want to be user mode by default
  const routeIsUserMode = isRouteUserMode(currentPathname);

  useEffect(() => {
    setIsUserMode(routeIsUserMode);
  }, [routeIsUserMode]);

  const value = useMemo(
    () => ({
      isUserMode,
    }),
    [isUserMode],
  );

  useImperativeHandle(UserModeContextRef, () => value);

  return (
    <UserModeContext.Provider value={value}>
      {children}
    </UserModeContext.Provider>
  );
};

export type UserModeProviderProps = {
  children: ReactNode;
};
