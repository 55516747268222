import { useEffect } from 'react';

import { Device } from '@capacitor/device';
import { PushNotifications } from '@capacitor/push-notifications';
import { DeviceOs } from '@graphql/generated/types';
import { usePushTokenUpsertMutation } from '@src/features-new/core/notifications/usePushNotitfications.generated';
import { ConnectedUserFragment } from '@src/index.generated';
import { isMobileNative } from '@src/utils/isMobileNative';

/* eslint-disable no-console */
export const usePushNotifications = (viewer: ConnectedUserFragment | null) => {
  const [pushTokenUpsert] = usePushTokenUpsertMutation();

  useEffect(() => {
    if (!isMobileNative()) {
      return;
    }
    PushNotifications.addListener('registration', async (token) => {
      const deviceId = await Device.getId();
      const info = await Device.getInfo();

      const input = {
        pushToken: token.value,
        deviceId: deviceId.identifier || '',
        deviceOS: info.platform === 'android' ? DeviceOs.Android : DeviceOs.Ios,
      };
      console.info(`[notif]: registering device: ${JSON.stringify(input)}`);
      await pushTokenUpsert({
        variables: {
          input: input,
        },
      });
    });

    PushNotifications.addListener('registrationError', (err) => {
      console.error('[notif]: Registration error: ', err.error);
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification) => {
        console.log('[notif]: Push notification received: ', notification);
      },
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification) => {
        console.log(
          '[notif]: Push notification action performed',
          notification.actionId,
          notification.inputValue,
        );
      },
    );
    return () => {
      PushNotifications.removeAllListeners();
    };
  }, [pushTokenUpsert]);

  useEffect(() => {
    if (!isMobileNative()) {
      return;
    }
    if (viewer) {
      registerNotifications();
    } else {
      PushNotifications.removeAllListeners();
    }
  }, [viewer]);

  const registerNotifications = async () => {
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      throw new Error('[notif]: User denied permissions!');
    }

    await PushNotifications.register();
  };
};
