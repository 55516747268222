import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/_authenticated-layout/admin/company/integrations/$',
)({
  loader: ({ context, navigate }) => {
    if (!context.company?.featureConfig.userIntegration?.isEnabled) {
      return navigate({ to: '/admin/company', replace: true });
    }
  },
});
