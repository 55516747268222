/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect } from 'react';

import { env } from '@env';
import HotjarSDK from '@hotjar/browser';
import { useEffectOnce } from '@src/hooks/use-effect-once';
import { useRetry } from '@src/hooks/use-retry';

import { HotjarViewerFragment } from './hotjar.generated';

type HotjarProps = {
  viewer: HotjarViewerFragment;
};

export const Hotjar = (props: HotjarProps) => {
  const { viewer } = props;

  if (!env.REACT_APP_HOTJAR_SITE_ID) return null;

  useEffectOnce(() => {
    HotjarSDK.init(parseInt(env.REACT_APP_HOTJAR_SITE_ID), 6);
  });

  const isReady = useRetry(() => HotjarSDK.isReady());

  useEffect(() => {
    if (!isReady) return;

    HotjarSDK.identify(viewer.id, {
      email: viewer.email,
      firstName: viewer.firstName,
      lastName: viewer.lastName,
      user_type: viewer.company?.viewerIsAdmin ? 'admin' : 'user',
      ...(viewer.company
        ? {
            company_id: viewer.company.id,
            company_name: viewer.company.displayName,
          }
        : {}),
    });
  }, [isReady, viewer]);

  return null;
};
