import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PlaceFilter } from '@components/organisms/PlaceFilter';
import { Modal, Typography } from '@happypal-tech/design-system';
import { Separator } from '@src/components/atoms/Separator';

import { OfferStoreFragment } from './OfferAvailability.generated';

interface OfferAvailabilityStoresProps {
  isOpen: boolean;
  onClose: () => void;
  stores: OfferStoreFragment[];
  offerName: string;
}

export const OfferAvailabilityStores = ({
  isOpen,
  onClose,
  stores,
  offerName,
}: OfferAvailabilityStoresProps) => {
  const { t } = useTranslation();
  const [currentCity, setCurrentCity] = useState<string | undefined>();

  const citiesList = useMemo(() => {
    return (
      stores
        .filter(
          (store) =>
            !!store.node.address.city && store.node.address.city !== '',
        )
        .map((store) => store.node.address.city || '')
        .sort() || []
    );
  }, [stores]);

  let filteredStores = stores;
  if (currentCity && currentCity !== '') {
    filteredStores = stores.filter(
      (store) => store.node.address.city === currentCity,
    );
  }

  const handleDisplayStoreLocation = () => {
    return filteredStores.map((store) => {
      const { address1, zip } = store.node.address;
      return (
        <div
          className="mb-3 box-border flex w-1/2 flex-col md:odd:pr-3 md:even:pl-3"
          key={`store-${store.node.id}`}
        >
          <strong className="font-display text-sm font-bold">
            {store.node.address.city}
          </strong>
          <span className="text-tiny text-grey">
            {address1 ? `${address1}, ` : null}
            {zip}
          </span>
        </div>
      );
    });
  };

  return (
    <Modal.Dialog
      rootClassName="z-modal"
      opened={isOpen}
      onOpenChange={onClose}
      title={t('campaignDetails.offers.offer.availability.stores.title')}
      description={t(
        'campaignDetails.offers.offer.availability.stores.description',
      )}
      descriptionHidden
    >
      <>
        <Typography type="body" semibold>
          {offerName}
        </Typography>
        <Separator top={4} bottom={6} />
        <PlaceFilter
          placeholder={t(
            'campaignDetails.offers.offer.availability.stores.inputPlaceholder',
          )}
          current={currentCity}
          options={citiesList.map((city) => ({ value: city, label: city }))}
          onChange={(city) => setCurrentCity(city?.value)}
          withGeolocation={false}
        />
        <div className="flex flex-col flex-wrap justify-between md:flex-row mt-4">
          {handleDisplayStoreLocation()}
        </div>
      </>
    </Modal.Dialog>
  );
};
