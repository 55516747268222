import { ApolloError } from '@apollo/client';
import { ApiRestError } from '@src/constants/Errors';
import { isAxiosError } from 'axios';

type ApiErrorMapper = {
  default: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [code: string]: (payload?: Record<string, any>) => void;
};

export function handleApiError(err: unknown, mapper: ApiErrorMapper) {
  if (isAxiosError(err)) {
    const apiError = err?.response?.data as ApiRestError;

    const handler = apiError.code && mapper[apiError.code];
    if (handler) return handler(apiError);
    return mapper.default();
  }
  if (err instanceof ApolloError) {
    err.graphQLErrors.map((graphError) => {
      const extension = graphError.extensions;
      const code = extension?.code as string | undefined;

      if (code && mapper[code]) {
        return mapper[code]!(extension);
      }
      return mapper.default();
    });
  }
}

export class FakeApiError extends Error {
  constructor(code: string) {
    super(`FakeApiError: ${code}`);
  }
}
