import { Trans } from 'react-i18next';

import { Text } from '@components/atoms/Text';
import { Icon } from '@src/components/atoms/Icon';
import { useFormatPrice } from '@src/utils/hooks/useFormatPrice';

import { SubventionDiscountInfoBoxFragment } from './SubventionDiscountInfoBox.generated';

type SubventionDiscountInfoBoxProps = {
  subventionEdge: SubventionDiscountInfoBoxFragment;
};

export function SubventionDiscountInfoBox(
  props: SubventionDiscountInfoBoxProps,
) {
  const { subventionEdge } = props;

  const formatPrice = useFormatPrice();
  return (
    <div className="mt-4 flex items-center gap-2 rounded-lg bg-primary-lightest px-4 py-3">
      <Icon name="infoCircle" className="text-primary" />
      <Text type="bodyRegularText" className="text-primary">
        <Trans
          i18nKey="OfferInfos.discount"
          values={{
            discount: formatPrice(subventionEdge.node.amountDiscounted),
            quantity:
              (subventionEdge.quantityTotal || 0) -
              (subventionEdge.quantityConsumed || 0),
          }}
          components={{
            funded: <span className="font-bold" />,
          }}
          defaults="<funded>-{{ discount }} par billet</funded> est appliqué, jusqu’à <funded>{{ quantity }} billets</funded> maximum."
        />
      </Text>
    </div>
  );
}
