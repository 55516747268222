import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/_authenticated-layout/admin/subventions',
)({
  loader: ({ context, navigate }) => {
    if (!context.company?.features.subventions) {
      throw navigate({ to: '/admin', replace: true });
    }
  },
});
