import { useTranslation } from 'react-i18next';

import PalQuestion3D from '@assets/images/PalQuestion3D.png';
import { Button, Picture, Typography } from '@happypal-tech/design-system';
import { Link } from '@tanstack/react-router';

export const NotFoundState = () => {
  const { t } = useTranslation('core', {
    keyPrefix: 'components.NotFoundState',
  });

  return (
    <div className="max-w-[400px] text-center flex items-center flex-col">
      <Picture
        alt="Pal Question"
        className="w-30 h-[120px]"
        fit="cover"
        src={PalQuestion3D}
      />
      <Typography asChild type="heading-3" bold className="mt-4">
        <h1>{t('title')}</h1>
      </Typography>
      <Typography asChild type="body" className="mt-2">
        <p>{t('text')}</p>
      </Typography>
      <Link to="/" className="mt-8">
        <Button iconRight="ArrowRightOutline">{t('cta')}</Button>
      </Link>
    </div>
  );
};
