import { ReactNode, useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';

import { FloatingOverlay } from './FloatingOverlay';
import { FloatingProviderContext } from './FloatingProvider.context';

export type FloatingProviderProps = {
  children?: ReactNode;
};

export const FLOATING_PROVIDER_PORTAL_ID = 'floating-provider-portal';

export const FloatingProvider = (props: FloatingProviderProps) => {
  const { children } = props;
  const [floatingItemCount, setFloatingItemCount] = useState(0);

  return (
    <FloatingProviderContext.Provider
      value={{ floatingItemCount, setFloatingItemCount }}
    >
      {children}

      <AnimatePresence>
        {floatingItemCount > 0 && (
          <motion.div
            transition={{
              duration: 0.15,
            }}
            key="overlay"
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
          >
            <FloatingOverlay className="fixed left-0 right-0 top-0 z-2" />
          </motion.div>
        )}
      </AnimatePresence>

      <div
        id={FLOATING_PROVIDER_PORTAL_ID}
        className="fixed left-0 right-0 top-0 z-drawer"
      />
    </FloatingProviderContext.Provider>
  );
};
