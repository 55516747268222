import { setContext } from '@apollo/client/link/context';
import { UserModeContextRef } from '@src/contexts/userMode/userMode.hooks';

export const sessionLink = setContext(async (_request, previousContext) => {
  const isUserMode = UserModeContextRef.current?.isUserMode ?? false;

  return {
    ...previousContext,
    headers: {
      ...previousContext.headers,
      ['admin-mode-enabled']: !isUserMode,
    },
  };
});
