import { useTranslation } from 'react-i18next';
import { useIntercom } from 'react-use-intercom';

import { Icon, Typography } from '@happypal-tech/design-system';
import {
  ButtonImage,
  FolderImage,
  HelpImage,
  LockImage,
  SettingsImage,
} from '@src/assets/images';
import useLogout from '@src/features/auth/hooks/useLogout';
import { AppHeaderProfileMenuImage } from '@src/features-new/core/AppHeader/AppHeaderProfile/MenuImage';
import { AppHeaderProfileMenuItem } from '@src/features-new/core/AppHeader/AppHeaderProfile/MenuItem';
import { AppHeaderProfileSuspendedFragment } from '@src/features-new/core/AppHeader/AppHeaderProfile/Suspended/AppHeaderProfileSuspended.generated';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '@src/features-new/ui/Avatar/Avatar';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@src/features-new/ui/DropdownMenu/DropdownMenu';
import { Link } from '@tanstack/react-router';

type AppHeaderProfileProps = {
  className?: string;
  viewer: AppHeaderProfileSuspendedFragment;
};

export const AppHeaderProfileSuspended = (props: AppHeaderProfileProps) => {
  const { className, viewer } = props;
  const { t } = useTranslation();
  const intercom = useIntercom();

  const handleHelpClick = () => {
    if (intercom.isOpen) {
      intercom.hide();
    } else {
      intercom.show();
    }
  };

  const logout = useLogout();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className={className}>
        <div className="flex gap-3 items-center">
          <Avatar className="h-11 w-11 rounded-lg">
            {!!viewer.avatarURL && (
              <AvatarImage
                src={viewer.avatarURL}
                alt={`${viewer.firstName} ${viewer.lastName}`}
              />
            )}
            <AvatarFallback>
              {viewer.firstName[0]}
              {viewer.lastName[0]}
            </AvatarFallback>
          </Avatar>
          <Typography type="body" semibold>
            {viewer.firstName}
          </Typography>
          <Icon name="ChevronDownOutline" size={16} />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="end"
        side="bottom"
        className="min-w-80 p-2 rounded-xl"
      >
        <AppHeaderProfileMenuItem
          textValue={t('navigation.header.menu.orders')}
          asChild
        >
          <Link to="/suspended/orders/">
            <AppHeaderProfileMenuImage src={FolderImage} />
            {t('navigation.header.menu.orders')}
          </Link>
        </AppHeaderProfileMenuItem>
        <AppHeaderProfileMenuItem
          textValue={t('navigation.header.menu.account')}
          asChild
        >
          <Link to="/suspended/account/">
            <AppHeaderProfileMenuImage src={LockImage} />
            {t('navigation.header.menu.account')}
          </Link>
        </AppHeaderProfileMenuItem>
        <AppHeaderProfileMenuItem
          textValue={t('navigation.header.menu.settings')}
          asChild
        >
          <Link to="/suspended/settings">
            <AppHeaderProfileMenuImage src={SettingsImage} />
            {t('navigation.header.menu.settings')}
          </Link>
        </AppHeaderProfileMenuItem>

        <AppHeaderProfileMenuItem
          textValue={t('navigation.header.menu.help')}
          onSelect={handleHelpClick}
        >
          <AppHeaderProfileMenuImage src={HelpImage} />
          <span>{t('navigation.header.menu.help')}</span>
        </AppHeaderProfileMenuItem>
        <AppHeaderProfileMenuItem
          textValue={t('navigation.header.menu.logOut')}
          onSelect={() => logout()}
          className="text-error"
        >
          <AppHeaderProfileMenuImage src={ButtonImage} />
          {t('navigation.header.menu.logOut')}
        </AppHeaderProfileMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
