import { useTranslation } from 'react-i18next';

import {
  BadgeStandalone,
  Icon,
  RadioGroup,
  Typography,
} from '@happypal-tech/design-system';
import { AccordionContent } from '@radix-ui/react-accordion';
import { BlueBoxImageHD, CubeImageHD } from '@src/assets/images';
import { DropdownMenuSubventionsFragment } from '@src/features-new/catalog/components/DropdownMenuSubventions/DropdownMenuSubventions.generated';
import { Accordion } from '@src/features-new/design-system/components/atoms/Accordion/Accordion';
import { cx } from 'class-variance-authority';

const getSubventionIcon = (subvention: DropdownMenuSubventionsFragment) => {
  const subventionHasIcon = subvention.subventionSchema?.icon?.file.url;

  if (subventionHasIcon) {
    return subventionHasIcon;
  }

  return subvention.isCustom ? CubeImageHD : BlueBoxImageHD;
};

interface AccordionSubventionsProps {
  activeSubventions: string[];
  onSelect: (id: string) => void;
  subventions: DropdownMenuSubventionsFragment[];
}
export const AccordionSubventions = (props: AccordionSubventionsProps) => {
  const { subventions, activeSubventions, onSelect } = props;

  const { t } = useTranslation('catalog', {
    keyPrefix: 'components.CatalogSearchFilters',
  });

  return (
    <Accordion.Item value="subventions">
      <Accordion.Trigger
        className={cx(
          'group p-4',
          'data-[state=open]:text-neutral-darkest data-[state=closed]:text-neutral-dark',
        )}
        subClassName="w-full"
      >
        <Typography
          type="subtitle"
          semibold
          className={cx(
            'flex gap-2 items-center',
            'group-data-[state=open]:text-neutral-darkest group-data-[state=closed]:text-neutral-dark',
          )}
        >
          {t('subventions')}
          <BadgeStandalone
            color="primary"
            showDot={activeSubventions.length !== 0}
            title={t('subventions')}
          />
        </Typography>
      </Accordion.Trigger>
      <AccordionContent className="w-80 max-h-[28rem] overflow-auto mx-4">
        {subventions.length ? (
          <RadioGroup
            onChange={(subventionId: string) => onSelect(subventionId)}
            itemClassName="font-semibold w-full p-3"
            items={subventions.map((subvention) => {
              return {
                value: subvention.id,
                label: subvention.name,
                icon: (
                  <img
                    src={getSubventionIcon(subvention)}
                    className="flex justify-center h-6 shrink-0 object-contain"
                    alt={subvention.name}
                  />
                ),
              };
            })}
            className="flex flex-col gap-1"
            value={activeSubventions[0] ?? undefined}
          />
        ) : (
          <Typography
            type="body"
            className="flex gap-2 text-neutral-dark p-3 items-center"
          >
            <Icon size={16} name="InfoCircleOutline" />
            {t('emptySubvention')}
          </Typography>
        )}
      </AccordionContent>
    </Accordion.Item>
  );
};
