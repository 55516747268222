import { useEffect, useMemo, useRef, useState } from 'react';

import { extractImageComponents } from '@utils/convertImageToHash';
import { decode } from 'blurhash';
import { cx } from 'class-variance-authority';

import { ProgressivePictureFragment } from './ProgressivePicture.generated';

export type ProgressivePictureProps = {
  alt?: string;
  dimension?: {
    width?: string;
    height?: string;
  };
  className?: string;
  pictureClassNamesArgument?: Parameters<typeof cx>[0];
  picture?: ProgressivePictureFragment | null;
  preventBlur?: boolean;
};

export const ProgressivePicture = ({
  className,
  dimension,
  picture,
  pictureClassNamesArgument = { absolute: true },
  alt,
  preventBlur = false,
}: ProgressivePictureProps) => {
  const [pictureLoaded, setPictureLoaded] = useState(false);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { componentX, componentY } = extractImageComponents(
    picture?.width || 0,
    picture?.height || 0,
  );

  const elementDimensions = useMemo(
    () => `${dimension?.width || 'w-full'} ${dimension?.height || 'h-full'}`,
    [dimension],
  );

  useEffect(() => {
    if (canvasRef.current && picture) {
      const pixels = decode(picture.hash, componentX, componentY, 1);
      const ctx = canvasRef.current.getContext(
        '2d',
      ) as CanvasRenderingContext2D;
      const imageData = ctx.createImageData(componentX, componentY);
      imageData.data.set(pixels);
      ctx.putImageData(imageData, 0, 0);
    }
  }, [componentX, componentY, picture]);

  return (
    <div
      className={cx(elementDimensions, 'relative inline-block flex-shrink-0')}
    >
      {!preventBlur && (
        <canvas
          className={cx(
            className,
            elementDimensions,
            'absolute inset-0 z-2 h-full w-full transition-opacity duration-500',
            { 'opacity-0': pictureLoaded, 'opacity-100': !pictureLoaded },
          )}
          width={componentX}
          height={componentY}
          ref={canvasRef}
        />
      )}
      <img
        alt={alt}
        className={cx(
          className,
          elementDimensions,
          pictureClassNamesArgument,
          'inset-0 z-0 h-full w-full',
        )}
        src={picture?.file.url}
        onLoad={() => setPictureLoaded(true)}
      />
    </div>
  );
};
