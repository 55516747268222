import { OfferType } from '@src/graphql/generated/types';

const OfferTypeOrder = [
  OfferType.Ticket,
  OfferType.GiftCard,
  OfferType.CouponClaimable,
  OfferType.CouponPublic,
  OfferType.Url,
];

export const sortOfferTypes = (offerTypes: OfferType[]): OfferType[] => {
  return offerTypes.sort(
    (a, b) => OfferTypeOrder.indexOf(a) - OfferTypeOrder.indexOf(b),
  );
};
