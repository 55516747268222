import { type MouseEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { GQLOperations } from '@graphql/generated/types';
import {
  FavoriteBadge as FavoriteBadgeDS,
  toast,
} from '@happypal-tech/design-system';
import { useAnalytics } from '@hooks/use-analytics';
import {
  FavoritesOrigin,
  VisitMyFavoritesOrigin,
} from '@src/features-new/analytics/constants/track-event.constant';
import { useFavoriteBadgeToggleCampaignMutation } from '@src/features-new/favorites/components/FavoriteBadge/FavoriteBadge.generated';
import { useAlgoliaSearchInsights } from '@src/lib/algolia/search-insights';
import { Link, useMatchRoute } from '@tanstack/react-router';

type FavoriteBadgeProps = {
  className?: string;
  isFavorite: boolean;
  campaignId: string;
  campaignName: string;
  onFavoriteStatusChange?: () => void;
  queryID?: string | undefined;
  variant?: 'inverse' | 'ghost';
  pageTrackingValue: FavoritesOrigin;
};

export const FavoriteBadge = (props: FavoriteBadgeProps) => {
  const {
    className,
    isFavorite,
    campaignId,
    campaignName,
    onFavoriteStatusChange,
    queryID,
    variant = 'inverse',
    pageTrackingValue,
  } = props;
  const { track } = useAnalytics();
  const { convertedObjectIds } = useAlgoliaSearchInsights();

  const matchRoute = useMatchRoute();
  const isOnFavoritePage = Boolean(matchRoute({ to: '/favorites/' }));

  const { t } = useTranslation('core', {
    keyPrefix: 'components.FavoriteBadge',
  });

  const [mutation] = useFavoriteBadgeToggleCampaignMutation();
  const handleClick = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const shouldConvert = !isFavorite;

    try {
      const resp = await mutation({
        variables: {
          campaignId: campaignId,
        },
        refetchQueries: [
          GQLOperations.Query.CampaignDetails,
          GQLOperations.Query.CatalogDiscoverCampaignCarousel,
          GQLOperations.Query.CatalogCampaignCarousel,
          GQLOperations.Query.SuggestionsCombobox,
          GQLOperations.Query.FavoritePage,
        ],
      });
      onFavoriteStatusChange?.();

      const isNewFavorite = Boolean(resp.data?.favoriteCampaignToggle);

      if (isNewFavorite) {
        track({
          type: 'add to favorites',
          payload: {
            campaign_name: campaignName,
            favorites_origin: pageTrackingValue,
          },
        });
      } else {
        track({
          type: 'remove from favorites',
          payload: {
            campaign_name: campaignName,
            remove_favorites_origin: pageTrackingValue,
          },
        });
      }

      toast.success(
        <Trans
          i18nKey={
            isNewFavorite
              ? 'core:components.FavoriteBadge.added'
              : 'core:components.FavoriteBadge.removed'
          }
          values={{
            name: campaignName,
          }}
          components={{
            bold: <span className="font-bold" />,
            clickable: (
              <Link
                to="/favorites"
                className="underline"
                onClick={() => {
                  if (!isOnFavoritePage) {
                    toast.dismiss();
                    track({
                      type: 'visit my favorites page',
                      payload: {
                        visit_my_favorites_origin: isNewFavorite
                          ? VisitMyFavoritesOrigin.addToaster
                          : VisitMyFavoritesOrigin.removeToaster,
                      },
                    });
                  }
                }}
              />
            ),
          }}
        />,
      );

      if (shouldConvert) {
        convertedObjectIds({
          eventName: queryID
            ? 'Campaign Added To Favorites After Search'
            : 'Campaign Added To Favorites',
          objectIDs: [campaignId],
          queryID,
        });
      }
    } catch (e) {
      toast.error(t('error'));
    }
  };

  return (
    <FavoriteBadgeDS
      className={className}
      type="button"
      style={variant}
      isActive={isFavorite}
      onClick={handleClick}
    />
  );
};
