import { AppMenuEntry } from '../AppMenuEntry/AppMenuEntry';

import { AppMenuFolderFragment } from './AppMenuFolder.generated';

interface AppMenuFolderProps {
  folder: AppMenuFolderFragment;
  depth?: number;
  isBackgroundDark?: boolean;
}

export const AppMenuFolder = (props: AppMenuFolderProps) => {
  const { folder, depth = 0, isBackgroundDark } = props;

  return (
    <div>
      <AppMenuEntry
        entry={folder}
        depth={depth}
        isBackgroundDark={isBackgroundDark}
      />
      <div className="grid gap-y-1 py-2">
        {folder.elements.map((item) => (
          <AppMenuEntry
            depth={depth + 1}
            entry={item}
            key={item.id}
            isBackgroundDark={isBackgroundDark}
          />
        ))}
      </div>
    </div>
  );
};
