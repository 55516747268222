import { useFormatPrice } from '@utils/hooks/useFormatPrice';

type OfferEmphasiedOffProps =
  | { valueOff: number; type: 'percent' }
  | { valueOff: number; type: 'amount'; currency: string }
  | { reward: string; type: 'reward'; quantity: number };

export const OfferEmphasiedOff = (props: OfferEmphasiedOffProps) => {
  const formatPrice = useFormatPrice();
  const getValue = () => {
    if (props.type === 'percent') {
      if (props.valueOff === 0) return null;
      return `${Math.round(Math.abs(props.valueOff) * -1)}%`;
    }
    if (props.type === 'amount') {
      if (props.valueOff === 0) return null;
      return formatPrice(Math.abs(props.valueOff) * -1, props.currency);
    }
    if (props.type === 'reward') {
      return props.quantity > 0
        ? `${props.quantity} ${props.reward}`
        : props.reward;
    }
  };

  const value = getValue();
  return (
    <div className="flex items-center justify-center rounded-full rounded-bl-full bg-primary px-1.5 leading-5">
      <span className="whitespace-nowrap font-display text-sm font-semibold tracking-wider text-white">
        {value}
      </span>
    </div>
  );
};
