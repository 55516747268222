import { useCallback } from 'react';

import {
  track as amplitudeTrack,
  type Types,
} from '@amplitude/analytics-browser';
import { env } from '@env';
import { TrackEvents } from '@src/features-new/analytics/constants/track-event.constant';
import { useViewer } from '@src/hooks/authenticated-context/authenticated-context';
import { useEffectOnce } from '@src/hooks/use-effect-once';

export function useAnalytics() {
  const viewer = useViewer();

  const track = useCallback(
    (event: TrackEvents, options?: Types.EventOptions) => {
      amplitudeTrack(event.type, 'payload' in event ? event.payload : {}, {
        ...options,
        app_version: env.REACT_APP_VERSION,
        user_id: viewer?.id,
      });
    },
    [viewer?.id],
  );

  return {
    track,
  };
}

export function useTrackOnce(event: TrackEvents, options?: Types.EventOptions) {
  const { track } = useAnalytics();

  useEffectOnce(() => {
    track(event, options);
  });
}
