import { createContext, ReactNode, useContext } from 'react';

import { AnyObjectSchema } from 'yup';

type FormRulesContext = {
  validationSchema: AnyObjectSchema;
};

const context = createContext<FormRulesContext | null>(null);

type RulesProviderProps = {
  validationSchema: AnyObjectSchema;
  children: ReactNode;
};

export const FormRulesProvider = (props: RulesProviderProps) => {
  const { children, validationSchema } = props;

  return (
    <context.Provider value={{ validationSchema }}>{children}</context.Provider>
  );
};

type FormRules = {
  isRequired?: boolean;
  isEmail?: boolean;
  isUrl?: boolean;
  isUUID?: boolean;
  min?: number;
  max?: number;
};

export const useFormRules = (field: string): FormRules => {
  const contextValue = useContext(context);

  if (!contextValue) throw new Error('useFormRules must be used within a Form');

  const { validationSchema } = contextValue;

  const fieldSchema = getFieldSchema(validationSchema, field);
  const describe = fieldSchema?.describe();

  const rules: FormRules = {};

  describe?.tests.forEach((test) => {
    switch (test.name) {
      case 'required':
        rules.isRequired = true;
        break;
      case 'email':
        rules.isEmail = true;
        break;
      case 'url':
        rules.isUrl = true;
        break;
      case 'uuid':
        rules.isUUID = true;
        break;
      case 'min':
        rules.min = test.params?.min as number;
        break;
      case 'max':
        rules.max = test.params?.max as number;
        break;
    }
  });

  return rules;
};

function getFieldSchema(
  validationSchema: AnyObjectSchema,
  field: string,
): AnyObjectSchema | undefined {
  if (field.includes('.')) {
    const [first, ...rest] = field.split('.');

    // @ts-expect-error - fix me
    return getFieldSchema(validationSchema.fields[first], rest.join('.'));
  }

  // @ts-expect-error - FIXME
  return validationSchema.fields[field];
}
