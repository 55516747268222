import { Loader } from '@src/components/atoms/Loader';
import { EmptyState } from '@src/components/molecules/EmptyState';
import {
  HappeningDetailsPageDocument,
  HappeningDetailsPageQuery,
  HappeningDetailsPageQueryVariables,
} from '@src/features/happenings/pages/HappeningDetailsPage/HappeningDetailsPage.generated';
import { createFileRoute, notFound } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/_authenticated-layout/happenings/detail/$happeningId/orders',
)({
  loader: async ({ context, params }) => {
    const { happeningId } = params;
    const res = await context.apolloClient.query<
      HappeningDetailsPageQuery,
      HappeningDetailsPageQueryVariables
    >({
      query: HappeningDetailsPageDocument,
      variables: { happeningId },
    });

    const happening = res.data.happening;

    if (!happening) {
      throw notFound();
    }

    return { happening };
  },
  pendingComponent: () => <Loader />,
  notFoundComponent: () => <EmptyState />,
});
