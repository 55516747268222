import { UserRole } from '@src/graphql/generated/types';
import { createFileRoute, Outlet } from '@tanstack/react-router';

const SuperAdminLayout = () => {
  return (
    <div className="flex min-h-screen flex-col">
      <Outlet />
    </div>
  );
};

export const Route = createFileRoute('/_super-admin-layout')({
  loader: ({ context, navigate }) => {
    const viewer = context.viewer;

    if (!viewer || viewer.role !== UserRole.Superadmin) {
      navigate({ to: '/auth/login' });
    }

    return { viewer };
  },
  component: SuperAdminLayout,
});
