import { useFormatPrice } from '@utils/hooks/useFormatPrice';
import { cx } from 'class-variance-authority';

type OfferSellingPriceProps = {
  amount: number;
  currency: string;
  hasSubvention?: boolean;
};

export const OfferSellingPrice = ({
  amount,
  currency,
  hasSubvention,
}: OfferSellingPriceProps) => {
  const formatPrice = useFormatPrice(currency);

  return (
    <span
      className={cx('font-display text-xl font-bold', {
        'text-primary': hasSubvention,
        'text-black': !hasSubvention,
      })}
    >
      {formatPrice(Math.max(amount, 0))}
    </span>
  );
};
