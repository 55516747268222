import { createEnv } from '@t3-oss/env-core';
import { z } from 'zod';

const environmentNames = [
  'local',
  'development',
  'epic',
  'staging',
  'demo',
  'production',
] as const;

export const env = createEnv({
  /**
   * The prefix that client-side variables must have. This is enforced both at
   * a type-level and at runtime.
   */
  clientPrefix: 'REACT_APP_',

  client: {
    REACT_APP_ENV: z.enum(environmentNames),
    REACT_APP_VERSION: z.string().optional(),

    REACT_APP_ALGOLIA_APP_ID: z.string(),
    REACT_APP_APP_URL: z.string().url(),
    REACT_APP_API_URL: z.string().url(),
    REACT_APP_API_URL_GQL: z.string().url(),
    REACT_APP_BO_URL: z.string().url(),

    REACT_APP_SENTRY_DSN: z.string().url().optional(),
    REACT_APP_SENTRY_ENV: z.enum(environmentNames).optional(),
    REACT_APP_SENTRY_TRACE_SAMPLE_RATE: z.coerce.number().optional().default(0),
    REACT_APP_SENTRY_RELEASE: z.string().optional(),

    REACT_APP_STORYLY_TOKEN: z.string().optional(),

    REACT_APP_LINK_APP_IOS: z.string(),
    REACT_APP_LINK_APP_ANDROID: z.string(),

    REACT_APP_APOLLO_CLIENT_NAME: z.string(),
    REACT_APP_APOLLO_CLIENT_VERSION: z.string().optional(),

    REACT_APP_CUSTOMERIO_ORGANIZATION_ID: z.string(),
    REACT_APP_CUSTOMERIO_SITE_ID: z.string(),

    REACT_APP_AMPLITUDE_KEY: z.string(),

    REACT_APP_HOTJAR_SITE_ID: z.string(),

    REACT_APP_SATISMETER_KEY: z.string().optional(),

    REACT_APP_MOBILE_APP_UNIVERSAL_LINK: z.string().url(),

    REACT_APP_STRIPE_PUBLISHABLE_KEY: z.string(),

    REACT_APP_IS_MOBILE_NATIVE: z.coerce.boolean().optional().default(false),

    REACT_APP_INTERCOM_ID: z.string(),
  },

  /**
   * What object holds the environment variables at runtime. This is usually
   * `process.env` or `import.meta.env`.
   */
  runtimeEnv: import.meta.env,

  /**
   * By default, this library will feed the environment variables directly to
   * the Zod validator.
   *
   * This means that if you have an empty string for a value that is supposed
   * to be a number (e.g. `PORT=` in a ".env" file), Zod will incorrectly flag
   * it as a type mismatch violation. Additionally, if you have an empty string
   * for a value that is supposed to be a string with a default value (e.g.
   * `DOMAIN=` in an ".env" file), the default value will never be applied.
   *
   * In order to solve these issues, we recommend that all new projects
   * explicitly specify this option as true.
   */
  emptyStringAsUndefined: true,
});
