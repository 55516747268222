import { ReactNode } from 'react';

import {
  Checkbox,
  CheckedState,
  Chip,
  Typography,
} from '@happypal-tech/design-system';
import { Accordion } from '@src/features-new/design-system/components/atoms/Accordion/Accordion';
import { cn } from '@src/features-new/ui/cn';
import { DropdownMenuItem } from '@src/features-new/ui/DropdownMenu/DropdownMenu';

interface DropdownElementGroupProps {
  isActive?: CheckedState;
  onSelect?: (id: string) => void;
  className?: string;
  src?: string;
  id: string;
  label: string;
  children?: ReactNode;
  count?: number;
}
export const DropdownElementGroup = ({
  isActive,
  onSelect,
  className,
  src,
  id,
  label,
  children,
  count,
}: DropdownElementGroupProps) => {
  return (
    <Accordion.Item value={id}>
      <Accordion.Header asChild>
        <div className="flex p-2 items-center gap-2">
          <DropdownMenuItem
            asChild
            className={cn(
              'grow gap-2 justify-between group rounded-[0.625rem] px-3 py-2',
              className,
            )}
          >
            <Checkbox
              checked={isActive}
              onCheckedChange={() => {
                onSelect?.(id);
              }}
              className={cn(
                'w-full hover:bg-neutral-lightest focus:bg-neutral-lightest',
                'data-[state=checked]:bg-primary-lightest data-[state=checked]:hover:bg-primary-lighter data-[state=checked]:focus:bg-primary-lighter',
              )}
              label={
                <div className="flex gap-2 grow items-center">
                  <img
                    src={src}
                    alt={label}
                    className="flex justify-center w-6 h-6 shrink-0"
                  />
                  <Typography type="body" semibold>
                    {label}
                  </Typography>
                  {count && <Chip label={count.toString()} />}
                </div>
              }
            />
          </DropdownMenuItem>
          <Accordion.Trigger className="w-auto p-2" />
        </div>
      </Accordion.Header>
      <Accordion.Content className="pl-8 pr-2 pb-2">
        {children}
      </Accordion.Content>
    </Accordion.Item>
  );
};
