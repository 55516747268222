import { useState } from 'react';

import { OfferInfos } from '@components/molecules/OfferInfos';
import { OfferCouponAction } from '@components/organisms/OfferCouponAction';
import { OfferProductAction } from '@components/organisms/OfferProductAction';
import { hasExpired } from '@utils/offerUtils';

import {
  OfferItemFragment,
  OfferItemSubventionFragment,
} from './OfferItem.generated';

type OfferItemProps = {
  item: OfferItemFragment;
  subventionEdge?: OfferItemSubventionFragment;
  queryID?: string;
  campaignId: string;
};

export function OfferItem(props: OfferItemProps) {
  const { item, subventionEdge, queryID, campaignId } = props;

  const [variantOutOfStock, setVariantOutOfStock] = useState<boolean | null>(
    null,
  );

  const offerExpired = hasExpired(item);

  const renderOffer = () => {
    switch (item.__typename) {
      case 'OfferCouponClaimable':
      case 'OfferCouponPublic':
      case 'OfferUrl':
        return (
          <OfferCouponAction
            queryID={queryID}
            offer={item}
            offerExpired={offerExpired}
            campaignId={campaignId}
          />
        );
      case 'OfferGiftCard':
      case 'OfferTicket':
        return (
          <OfferProductAction
            offer={item}
            queryID={queryID}
            campaignId={campaignId}
            offerExpired={offerExpired}
            setVariantOutOfStock={setVariantOutOfStock}
            subventionEdge={subventionEdge}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="px-4 lg:px-0">
      <div>
        <OfferInfos
          offer={item}
          variantOutOfStock={variantOutOfStock}
          subventionEdge={subventionEdge}
          offerExpired={offerExpired}
        />
        <div className="mt-6 md:mt-7" />
        {renderOffer()}
      </div>
    </div>
  );
}
