import { Checkbox, Chip, Typography } from '@happypal-tech/design-system';
import { cn } from '@src/features-new/ui/cn';
import { DropdownMenuItem } from '@src/features-new/ui/DropdownMenu/DropdownMenu';

interface DropdownElementLineProps<T extends string> {
  isActive?: boolean;
  className?: string;
  src?: string;
  id: T;
  onSelect?: (id: T) => void;
  label: string;
  count?: number;
}
export const DropdownElementLine = <T extends string>({
  isActive,
  onSelect,
  className,
  src,
  id,
  label,
  count,
}: DropdownElementLineProps<T>) => {
  return (
    <DropdownMenuItem
      asChild
      onSelect={(e) => {
        onSelect?.(id);
        e.preventDefault();
      }}
      className={cn(
        'grow gap-2 justify-between group rounded-[0.625rem]',
        className,
      )}
    >
      <Checkbox
        checked={isActive}
        onCheckedChange={() => {
          onSelect?.(id);
        }}
        className={cn(
          'w-full px-3 py-2 hover:bg-neutral-lightest focus:bg-neutral-lightest',
          'data-[state=checked]:bg-primary-lightest data-[state=checked]:hover:bg-primary-lighter data-[state=checked]:focus:bg-primary-lighter',
        )}
        label={
          <div className="flex gap-2 grow items-center">
            {src && (
              <img
                src={src}
                alt={label}
                className="flex justify-center w-6 h-6 shrink-0"
              />
            )}
            <Typography type="body" semibold>
              {label}
            </Typography>
            {count && <Chip label={count.toString()} />}
          </div>
        }
      />
    </DropdownMenuItem>
  );
};
