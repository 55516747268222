import { CompanyPicture } from '@src/features-new/core/CompanyPicture/CompanyPicture';
import { Link } from '@tanstack/react-router';
import { cx } from 'class-variance-authority';

interface AppMenusProps {
  className?: string;
}

export const AppMenuSuspended = ({ className }: AppMenusProps) => {
  const background = '#fff';

  return (
    <div
      className={cx(className, 'overflow-y-auto overflow-x-hidden py-6')}
      style={{
        boxShadow: '0px 5px 24px rgba(0, 0, 0, 0.05)',
        background,
      }}
    >
      <div className="flex px-6">
        <Link
          to={'/suspended/orders/'}
          className="m-auto h-32 w-32 overflow-hidden rounded-xl"
        >
          <CompanyPicture />
        </Link>
      </div>
      <menu className="mt-6 flex flex-col gap-y-3 px-4" />
    </div>
  );
};
