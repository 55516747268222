import { ComponentProps, forwardRef } from 'react';
import { useInstantSearch } from 'react-instantsearch';

import { Button } from '@happypal-tech/design-system';

export const ButtonResetSuggestions = forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof Button>
>(({ onClick, ...rest }, forwardedRef) => {
  const { setIndexUiState } = useInstantSearch();

  return (
    <Button
      ref={forwardedRef}
      onClick={(e) => {
        setIndexUiState({ query: '' });
        onClick?.(e);
      }}
      {...rest}
    />
  );
});
