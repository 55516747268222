import { useTranslation } from 'react-i18next';

import { Loader } from '@src/components/atoms/Loader';
import { EmptyState } from '@src/components/molecules/EmptyState';
import { createFileRoute } from '@tanstack/react-router';

const NotFound = () => {
  const { t } = useTranslation('auth');

  return <EmptyState title={t('auth.errors.userNotFound')} />;
};

export const Route = createFileRoute('/_authenticated-layout/home/')({
  pendingComponent: () => <Loader className="flex h-screen items-center" />,
  notFoundComponent: NotFound,
});
