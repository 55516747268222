import {
  SuspendedHubRouteDocument,
  SuspendedHubRouteQuery,
  SuspendedHubRouteQueryVariables,
} from '@src/routes/_suspended-layout/suspended/hub/~route.generated';
import { createFileRoute, notFound } from '@tanstack/react-router';

export const Route = createFileRoute('/_suspended-layout/suspended/hub')({
  loader: async ({ context }) => {
    const { data } = await context.apolloClient.query<
      SuspendedHubRouteQuery,
      SuspendedHubRouteQueryVariables
    >({
      query: SuspendedHubRouteDocument,
    });

    const { viewer } = data;

    if (!viewer) {
      throw notFound();
    }

    return { viewer };
  },
});
