import { MutableRefObject, useEffect, useRef } from 'react';

export function useOuterClick<InnerElement extends HTMLDivElement>(
  callback: (e: CustomEvent) => void,
  eventType?: 'mousedown' | 'click',
  // onlyCatchPortalEvents: Prevent outerClick event if no portal found
  onlyCatchPortalEvents = false,
): MutableRefObject<InnerElement | null> {
  const innerRef = useRef<InnerElement | null>(null);
  const callbackRef = useRef<(e: CustomEvent) => void>();

  // set current callback in ref, before second useEffect uses it
  useEffect(() => {
    // useEffect wrapper to be safe for concurrent mode
    callbackRef.current = callback;
  });

  useEffect(() => {
    // Target closest portal if there is one, otherwise the event handler is ignored in portals
    const rootEl =
      innerRef.current?.closest('.portal') ||
      (onlyCatchPortalEvents === false ? document : null);

    if (rootEl === null) {
      return;
    }

    rootEl.addEventListener(
      eventType || 'mousedown',
      handleClick as EventListener,
    );
    return () =>
      rootEl.removeEventListener(
        eventType || 'mousedown',
        handleClick as EventListener,
      );

    // read most recent callback and innerRef dom node from refs
    function handleClick(e: CustomEvent) {
      if (
        e?.target &&
        callbackRef.current &&
        !innerRef.current?.contains(e.target as Node)
      ) {
        callbackRef.current(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // no need for callback + innerRef dep

  return innerRef; // return ref; client can omit `useRef`
}
