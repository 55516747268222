import { ReactNode, useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  CustomerFieldsRequired,
  CustomerForm,
  TicketModalCustomerForm,
  TicketModalCustomerFormValues,
} from '@components/molecules/NamedTicketModal/TicketModalCustomerForm/TicketModalCustomerForm';
import { Button, toast, Typography } from '@happypal-tech/design-system';
import { Icon } from '@src/components/atoms/Icon';
import { Separator } from '@src/components/atoms/Separator';

type NamedTicketModalProps = {
  onSubmit: (values: TicketModalCustomerFormValues[]) => void;
  quantity: number;
  onClose: () => void;
  requiredFields: CustomerFieldsRequired;
  defaultValues?: TicketModalCustomerFormValues[];
};

export const NamedTicketModal = ({
  quantity,
  onSubmit,
  onClose,
  requiredFields,
  defaultValues,
}: NamedTicketModalProps) => {
  const { t } = useTranslation();
  const formRefs = useRef<CustomerForm[]>([]);

  useEffect(() => {
    if (defaultValues && defaultValues.length !== quantity) {
      toast.error(t('errors:default'));
      onClose();
    }
  }, [defaultValues, quantity, onClose, t]);

  const renderForms = () => {
    formRefs.current = [];
    const elems: ReactNode[] = [];
    for (let i = 0; i < quantity; i++) {
      elems.push(
        <div key={i}>
          <Typography type="heading-4" className="mb-6" semibold>
            {t('namedTicketModal.subtitle')} {i + 1}
          </Typography>
          <TicketModalCustomerForm
            ref={(element: CustomerForm) => (formRefs.current[i] = element)}
            requiredFields={requiredFields}
            defaultValues={defaultValues?.[i]}
          />
          <Separator />
        </div>,
      );
    }
    return elems;
  };

  const submit = () => {
    const formsValues: Array<TicketModalCustomerFormValues> = [];
    formRefs.current.forEach((elem) => {
      const values = elem.getValues();
      if (values) {
        formsValues.push(values);
      }
    });
    if (formsValues.length < quantity) return;
    onSubmit(formsValues);
  };

  return (
    <>
      <div className="mt-4 flex items-center gap-4 rounded-lg bg-grey-light px-4 py-3">
        <Icon name="warningCircle" className="text-grey" />
        <Typography type="body">
          <Trans
            i18nKey="namedTicketModal.info"
            components={{ bold: <span className="font-bold" /> }}
          />
        </Typography>
      </div>
      <Separator />
      {renderForms()}
      <div className="flex gap-4">
        <Button variant="stroke" color="neutral" fluid onClick={onClose}>
          {t('common:forms.confirmBeforeLeaveModal.submit.cancel')}
        </Button>
        <Button onClick={submit} fluid color="primary">
          {t('common:buttons.register')}
        </Button>
      </div>
    </>
  );
};
