import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@components/atoms/Tooltip';
import { toast } from '@happypal-tech/design-system';
import { Icon } from '@src/components/atoms/Icon/Icon';
import { useAlgoliaSearchInsights } from '@src/lib/algolia/search-insights';
import { cx } from 'class-variance-authority';
import { AnimatePresence, motion } from 'framer-motion';

type CouponPublicTypeProps = {
  couponValue: string;
  offerUrl: string;
  buttonLabel?: string;
  expired: boolean;
  queryID?: string;
  campaignId?: string;
};

let copyThresholdTimeout: ReturnType<typeof setTimeout>;

function removeTimeout() {
  clearTimeout(copyThresholdTimeout);
}

export const CouponPublic = ({
  couponValue,
  offerUrl,
  buttonLabel,
  expired,
  queryID,
  campaignId,
}: CouponPublicTypeProps) => {
  const { t } = useTranslation();
  const [couponCopied, setCouponCopied] = useState(false);
  const { convertedObjectIds } = useAlgoliaSearchInsights();

  const handleCopy = () => {
    navigator.clipboard.writeText(couponValue);
    toast.success(
      t(
        'campaignDetails.offers.offer.toast.codeCopied',
        'Code promo copié dans le presse-papier.',
      ),
    );
    setCouponCopied(true);

    if (campaignId) {
      convertedObjectIds({
        eventName: queryID
          ? 'Coupon Public Copied After Search'
          : 'Coupon Public Copied',
        objectIDs: [campaignId],
        queryID,
      });
    }

    if (copyThresholdTimeout) {
      removeTimeout();
    }
    copyThresholdTimeout = setTimeout(() => {
      setCouponCopied(false);
    }, 2000);
  };

  useEffect(() => {
    return () => clearTimeout(copyThresholdTimeout);
  }, []);

  return (
    <div className="flex flex-row items-center justify-end">
      <div className="relative flex w-full flex-grow items-center justify-between rounded-xl border-2 border-dashed border-grey-medium py-2 pl-3.5 pr-1.5 md:max-w-56">
        <div className="absolute -top-4 left-0 right-0 flex items-center justify-center">
          <div className=" bg-white px-0.5">
            <span className="text-tiny">
              {t('campaignDetails.offers.types.discountCode', 'Code promo')}
            </span>
          </div>
        </div>
        <span className="line-clamp-2 font-display text-base font-bold leading-none">
          {couponValue}
        </span>
        <AnimatePresence>
          <Tooltip
            id="copyTooltip"
            label={t(
              'campaignDetails.offers.offer.tooltip.copyCode',
              'Copier le code',
            )}
            placement="top"
            disabled={expired}
          >
            <div className="flex h-full w-full items-center justify-center">
              {!couponCopied ? (
                <motion.button
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  type="button"
                  disabled={expired}
                  onClick={handleCopy}
                >
                  <Icon name="fileDuplicate" className="text-grey" />
                </motion.button>
              ) : (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <Icon
                    name="checkCircle"
                    variant="fill"
                    className="text-green"
                  />
                </motion.div>
              )}
            </div>
          </Tooltip>
        </AnimatePresence>
      </div>
      <div className="ml-3 flex-shrink-0">
        {
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <a
            className={cx(
              'flex h-11 items-center justify-center rounded-2xl px-6 font-display text-sm font-semibold text-white transition-all duration-300 md:rounded-xl',
              {
                'cursor-default bg-primary-disabled': expired,
                'bg-primary hover:bg-primary-dark': !expired,
              },
            )}
            onClick={() =>
              campaignId
                ? convertedObjectIds({
                    eventName: 'Offer Link Clicked',
                    objectIDs: [campaignId],
                    queryID,
                  })
                : null
            }
            {...(!expired
              ? { href: offerUrl, target: '_blank', rel: 'noreferrer noopener' }
              : {})}
          >
            {buttonLabel ||
              t('campaignDetails.offers.offer.seeOfferButton', "Voir l'offre")}
          </a>
        }
      </div>
    </div>
  );
};
