import color from 'color';

export const DEFAULT_BACKGROUND_COLOR = '#fff';

export const isBackgroundDark = (backgroundColor: string) =>
  color(backgroundColor).isDark();

export const isBackgroundLight = (backgroundColor: string) =>
  color(backgroundColor).isLight();

export const getBackgroundColor = (
  isEnabled: boolean,
  backgroundColor?: string | null,
) => {
  if (!isEnabled) return DEFAULT_BACKGROUND_COLOR;
  return backgroundColor ?? DEFAULT_BACKGROUND_COLOR;
};
