import { useTranslation } from 'react-i18next';
import PullToRefreshBase from 'react-simple-pull-to-refresh';

import { Spin, Typography } from '@happypal-tech/design-system';
import { isMobileNative } from '@utils/isMobileNative';

type PullToRefreshProps = {
  children: JSX.Element;
  onRefresh: () => Promise<void>;
};

export const PullToRefresh = (props: PullToRefreshProps) => {
  const { children, onRefresh } = props;
  const { t } = useTranslation('core', {
    keyPrefix: 'components.pullToRefresh',
  });

  const isNative = isMobileNative();

  const pullingContent = (
    <Typography type="body" className="text-center">
      {t('label')}
    </Typography>
  );

  return (
    <PullToRefreshBase
      onRefresh={onRefresh}
      pullingContent={pullingContent}
      refreshingContent={<Spin size="large" className="mx-auto my-2" />}
      isPullable={!!isNative}
    >
      {children}
    </PullToRefreshBase>
  );
};
