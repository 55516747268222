import { memo } from 'react';

import { LogoImage } from '@src/assets/images';
import { AnimatePresence, motion } from 'framer-motion';

import { ShowstopperNewsletter } from './pages/ShowstopperNewsletter/ShowstopperNewsletter';

interface ShowstopperProps {
  companyLogo?: string | null;
  companyDisplayName?: string;
}
export const Showstopper = memo(
  ({ companyLogo, companyDisplayName }: ShowstopperProps) => {
    const showstoppers = [ShowstopperNewsletter];

    const FirstShowstopper = showstoppers.find((s) => s.shouldRender());

    if (!FirstShowstopper) return null;

    return (
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="fixed inset-0 z-toast flex flex-col overflow-auto bg-white px-4 pb-8 pt-10"
        >
          <div className="absolute left-0 top-0 hidden lg:flex lg:px-10 lg:pt-8 2xl:pt-10">
            <div className="w-32 items-center justify-center rounded-xl">
              <img
                src={companyLogo || LogoImage}
                alt={companyDisplayName || 'HappyPal'}
              />
            </div>
          </div>
          <div className="m-auto grid w-full max-w-md">
            <FirstShowstopper className="m-auto" />
          </div>
        </motion.div>
      </AnimatePresence>
    );
  },
  () => true,
);
