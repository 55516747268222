import { useTranslation } from 'react-i18next';

import { PinImage } from '@assets/images';
import { SubventionDiscountInfoBox } from '@components/atoms/SubventionDiscountInfoBox';
import { ExpandableBox } from '@components/molecules/ExpandableBox';
import { OfferAvailability } from '@components/organisms/OfferAvailability';

import { OfferExpired } from '../OfferExpired';

import {
  OfferInfosFragment,
  OfferInfosSubventionFragment,
} from './OfferInfos.generated';
import { OfferReduction } from './OfferReduction';

interface OfferInfosProps {
  offer: OfferInfosFragment;
  variantOutOfStock: boolean | null;
  subventionEdge?: OfferInfosSubventionFragment;
  offerExpired: boolean;
}

export const OfferInfos = (props: OfferInfosProps) => {
  const { offer, subventionEdge, offerExpired } = props;
  const { t } = useTranslation();

  return (
    <div>
      <OfferReduction {...props} />
      {subventionEdge && (
        <SubventionDiscountInfoBox subventionEdge={subventionEdge} />
      )}

      {offerExpired && <OfferExpired className="mt-5" />}

      <div className="mt-4" />
      <OfferAvailability offerAvailability={offer} />
      {!!offer.descriptionHTML && (
        <div className="relative rounded-lg bg-grey-light px-5 py-3">
          <div className="flex flex-row items-center justify-between">
            <span className="font-display text-base font-bold">
              {t(
                'campaignDetails.offers.offer.toReadAbsolutely',
                'A lire absolument',
              )}
            </span>
            <img src={PinImage} alt="toRead" className="h-5 w-5" />
          </div>
          <div className="mt-2" />
          <ExpandableBox
            childKey={'great_unique_key' + offer.id}
            height="90px"
            className="mt-4 flex flex-wrap gap-4 pb-2"
            buttonProps={{
              variant: 'ghost',
              fluid: true,
              size: 'small',
              className:
                'inline-flex w-full items-center justify-center font-bold',
            }}
            labelClosed={t('common:buttons.seeMore')}
          >
            <div
              className="text-tiny font-medium text-black"
              dangerouslySetInnerHTML={{
                __html: offer.descriptionHTML,
              }}
            />
          </ExpandableBox>
        </div>
      )}
    </div>
  );
};
