import {
  ChangeEventHandler,
  Children,
  cloneElement,
  FocusEventHandler,
  ReactElement,
  ReactNode,
  useEffect,
} from 'react';
import {
  FieldError,
  FieldErrors,
  useFormContext,
  useFormState,
} from 'react-hook-form';

import { Typography } from '@happypal-tech/design-system';
import { Icon } from '@src/components/atoms/Icon/Icon';
import { cx } from 'class-variance-authority';
import { AnimatePresence, motion } from 'framer-motion';
import { get } from 'radash';

type FormItemProps = {
  name: string;
  children?:
    | ReactElement<FormItemChildProps>
    | ReactElement<FormItemChildProps>[];
  className?: string;
  label?: ReactNode;
  appendToLabel?: string | ReactNode;
  note?: ReactNode;
  status?: 'error'; // 'success' | 'warning';
  valueAsDate?: boolean;
  valueAsNumber?: boolean;
  deps?: string[];
  required?: boolean;
  /** Used to tel a children is a controller and you skip ref passing */
  skipRef?: boolean;
};

export type FormItemChildProps<V = unknown> = {
  id?: string;
  name?: string;
  status?: 'error' | 'warning';
  value?: V;
  onChange?: ChangeEventHandler;
  onBlur?: FocusEventHandler;
  onFocus?: FocusEventHandler;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: any;
};

export const FormItem = (props: FormItemProps) => {
  const {
    className,
    children,
    label,
    appendToLabel,
    name,
    note,
    valueAsDate,
    valueAsNumber,
    deps,
    skipRef,
    required = false,
    ...rest
  } = props;

  const { register, watch, trigger } = useFormContext();
  const { errors } = useFormState({ name });
  const fieldErrors = getFieldErrorMessages(errors, name);

  useEffect(() => {
    if (!deps?.length) return;

    const subscription = watch((_values, info) => {
      if (info.name && deps.includes(info.name)) {
        trigger(name);
      }
    });

    return subscription.unsubscribe;
  }, [deps, name, trigger, watch]);

  return (
    <div className={cx(className, 'text-left')} {...rest}>
      {label && (
        <label
          htmlFor={name}
          className="mb-3 flex text-sm font-semibold text-neutral-darkest"
        >
          {label}
          {required && <span className="ml-1 text-error">*</span>}
          <span className="ml-auto">{appendToLabel}</span>
        </label>
      )}
      {!!children && (
        <div className="flex">
          {Children.map(children, (child) => {
            // If a child FormInput exposes a usesController property, we should pass down the ref.
            const usesController =
              typeof child.type !== 'string' && 'usesController' in child.type;

            const { ref, ...registerOptions } = register(name, {
              valueAsDate,
              // Use setValueAs instead of valueAsNumber to avoid NaN returned
              setValueAs: valueAsNumber
                ? (v) => {
                    const parsed = parseFloat(v);

                    return Number.isNaN(parsed) ? null : parsed;
                  }
                : undefined,
            });

            return cloneElement(child, {
              id: name,
              status: fieldErrors.length > 0 ? 'error' : undefined,
              ref: skipRef || usesController ? undefined : ref,
              ...registerOptions,
            });
          })}
        </div>
      )}
      {[note, ...fieldErrors].some(Boolean) && (
        <div className="mt-2 flex flex-col gap-y-1">
          {note && (
            <div key="note" className="mt-2 flex text-neutral-dark">
              <Icon name="infoCircle" size="small" className="mr-2 flex-none" />
              <Typography type="note" asChild className="flex-1">
                <p>{note}</p>
              </Typography>
            </div>
          )}
          <AnimatePresence initial={false}>
            {fieldErrors.map((fieldError) => (
              <motion.div
                key={fieldError.key}
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: 'auto', opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.25 }}
                className="text-tiny text-error"
              >
                {fieldError.message}
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
      )}
    </div>
  );
};

function getFieldErrorMessages(errors: FieldErrors, name: string) {
  const fieldError = get(errors, name) as FieldError;

  if (!fieldError) return [];

  if (!fieldError.types) {
    return [{ key: `${name}.${fieldError.type}`, message: fieldError.message }];
  }

  return Object.entries(fieldError.types)
    .map(([type, message]) =>
      typeof message === 'object'
        ? message?.map((message) => ({
            key: `${name}.${type}.${message}`,
            message,
          }))
        : {
            key: `${name}.${type}`,
            message: message,
          },
    )
    .flat()
    .filter((e) => e.message) as { key: string; message: string }[];
}
