import type { CohortUserModeTrackingValues } from '@src/features-new/cohort/components/CohortModeSelector/CohortModeSelector';
import { OfferType, SurveyProvider } from '@src/graphql/generated/types';

export type TrackEvents =
  | AddToFavorite
  | BannerSubventionsClick
  | CancelGathering
  | CancelGatheringSession
  | CatalogSorterChanged
  | ClickAllResultsNoSuggestions
  | ClickAroundMe
  | ClickCatalogCampaign
  | ClickCatalogBenefit
  | ClickCatalogButtonAroundMe
  | ClickCategory
  | ClickDiscoverFranceBillet
  | ClickDiscoverOurCategories
  | ClickFranceBilletConsent
  | ClickHappyMedia
  | ClickHappyMediaContinue
  | ClickHeaderSubventions
  | ClickResetCatalogFilter
  | ClickResetFilter
  | ClickSeeMoreOffers
  | ClickViewAllPerks
  | CreateSession
  | CompleteCatalogSearch
  | CompleteSearch
  | CreateGathering
  | DeleteComment
  | DeleteVote
  | DownloadDocuments
  | FilterCampaigns
  | FilterCatalogCampaigns
  | ForgetPassword
  | GatheringDetailsViewMore
  | GatheringExportParticipationsList
  | GatheringStartCreateSession
  | GatheringViewEventPage
  | GatheringViewGlobalEventsPageAdmin
  | GatheringViewGlobalEventsPageUser
  | GatheringViewSessionPage
  | GatheringWithdrawFromSession
  | HideCatalogFilter
  | Login
  | Logout
  | OpenArticle
  | OpenDocument
  | RecoverPassword
  | RegisterToSession
  | RemoveFromFavorite
  | RemoveGatheringInvitation
  | RemoveGatheringParticipation
  | ScrollSectionHorizontally
  | SearchBarClickAllResults
  | StartCreateEvent
  | ShowCatalogFilter
  | SubmitComment
  | SubmitVote
  | SubscriptionSuccess
  | SurveyAdminProviderClick
  | SurveyAdminSubmit
  | SurveyAdminVisiualizeForm
  | SurveyAdminUpdateButton
  | SurveyAdminVisitCreatePage
  | SurveyAdminVisitInProgressPage
  | SurveyUserVisitPage
  | UpdateGathering
  | UpdateGatheringSession
  | ViewBenefitsPage
  | ViewCampaignPage
  | ViewCart
  | ViewDiscoverPage
  | ViewExplorePage
  | ViewHomePage
  | ViewSelectionPage
  | ViewFavoritePage
  | VisitSubmitIdeaAdmin
  | VisitSubmitIdeaUser
  | ClickShowSubventionDetail;

export type TrackOfferType =
  | OfferType.CouponClaimable
  | OfferType.CouponPublic
  | OfferType.GiftCard
  | OfferType.Ticket
  | OfferType.Url
  | 'WITH_DISCOUNT';

interface BannerSubventionsClick {
  type: 'banner subventions click';
  payload: {
    subsidy_amount_available: number;
  };
}

interface CancelGathering {
  type: 'cancel event';
  payload: {
    event_id: string;
    event_name: string;
    event_participations_quota?: number | null;
    event_participations_number: number;
    event_sessions_number: number;
  };
}

interface CancelGatheringSession {
  type: 'cancel session';
  payload: {
    event_id: string;
    session_id: string;
    session_name: string;
    session_participants_number?: number;
    session_participations_number?: number;
  };
}

interface CatalogSorterChanged {
  type: 'sorter changed';
  payload: {
    filter_sort?: string;
  };
}

interface ClickAllResultsNoSuggestions {
  type: 'click all results no suggestions';
}

interface ClickCatalogBenefit {
  type: 'click catalog benefit';
  payload: {
    filter_category?: string;
    filter_place?: string;
    filter_subcategory?: string[];
    selection_name?: string;
  };
}

interface ClickCatalogButtonAroundMe {
  type: 'click catalog button around me';
}

interface ClickCatalogCampaign {
  type: 'click catalog campaign';
  payload: {
    link_position?: string;
    selection_name?: string;
  };
}

interface ClickCategory {
  type: 'click category';
  payload: {
    category?: string;
    link_position?: string;
  };
}

interface ClickHappyMedia {
  type: 'click happymedia';
}

interface ClickHappyMediaContinue {
  type: 'click continue happymedia';
}

interface ClickResetCatalogFilter {
  type: 'click reset catalog filter';
}
interface ClickResetFilter {
  type: 'click reset filter';
}

interface ClickViewAllPerks {
  type: 'click view all perks';
}

interface ClickSeeMoreOffers {
  type: 'click button see more offers';
}

interface ClickFranceBilletConsent {
  type: 'click france billet consent';
}

interface ClickDiscoverOurCategories {
  type: 'click button discover our categories';
}

interface ClickDiscoverFranceBillet {
  type: 'click france billet banner';
}

interface ClickAroundMe {
  type: 'click button around me';
}

interface CreateSession {
  type: 'create session';
  payload: {
    event_id: string;
    event_name: string;
    session_creation_origin?: string;
    session_end_date?: string;
    session_start_date?: string;
    session_id: string;
    session_name: string;
    session_registration_start_date?: string;
    session_registration_end_date?: string;
    session_participation_quota?: number;
  };
}

interface CompleteCatalogSearch {
  type: 'complete catalog search';
  payload: {
    click_all_results: boolean;
    click_enter_key: boolean;
    click_submit_button: boolean;
    click_suggestions: boolean;
    search_keyword?: string;
    search_output?: string;
  };
}

interface CompleteSearch {
  type: 'complete search';
  payload: {
    search_keyword?: string;
    search_output?: string;
  };
}

interface CreateGathering {
  type: 'create event';
  payload: {
    event_id: string;
    event_name: string;
    event_participations_quota?: number;
  };
}

interface DownloadDocuments {
  type: 'download document';
  payload: {
    document_id: string;
    document_name: string;
  };
}

interface FilterCampaigns {
  type: 'filter campaigns';
  payload: {
    filter_category?: string;
    filter_subcategory?: string[];
    filter_offer_type?: TrackOfferType[];
    filter_benefit?: string[];
    filter_place?: string;
    filter_sort?: string;
  };
}

interface FilterCatalogCampaigns {
  type: 'filter catalog campaigns';
  payload: {
    category_filter_name?: string;
    filter_type:
      | 'category'
      | 'subcategory'
      | 'place'
      | 'benefit'
      | 'offer_type';
  };
}

interface HideCatalogFilter {
  type: 'hide catalog filter';
}

interface ForgetPassword {
  type: 'forgot password';
}

interface ClickHeaderSubventions {
  type: 'header subventions click';
  payload: {
    subsidy_amount_available: number;
  };
}
interface Login {
  type: 'log in';
}

interface Logout {
  type: 'log out';
}

interface OpenArticle {
  type: 'open article';
  payload: {
    article_category?: string;
    article_id?: string;
    article_name?: string;
    company_id?: string;
    company_name?: string;
  };
}

interface OpenDocument {
  type: 'open document';
}

interface RecoverPassword {
  type: 'recover password';
}

interface RegisterToSession {
  type: 'register to session';
  payload: {
    event_id: string;
    event_name: string;
    session_id: string;
    session_name: string;
    session_participations_booked_by_participant?: number;
    session_start_date?: string;
    session_participations_quota?: number;
    session_participations_number?: number;
  };
}

interface RemoveGatheringInvitation {
  type: 'remove invitation';
  payload: {
    event_id: string;
    user_id: string;
  };
}

interface SearchBarClickAllResults {
  type: 'click_all_results';
}
interface ScrollSectionHorizontally {
  type: 'click perk from section';
  payload: {
    section_name?: string;
    offer_name?: string;
    offer_index?: number;
  };
}

interface GatheringDetailsViewMore {
  type: 'click display more button';
  payload: {
    event_id: string;
    event_name: string;
    event_option_highlighted?: boolean;
    event_participations_number?: number;
  };
}

interface GatheringExportParticipationsList {
  type: 'export event participations list';
  payload: {
    event_id: string;
  };
}

interface GatheringStartCreateSession {
  type: 'start create session';
}

interface GatheringViewEventPage {
  type: 'view event page';
  payload: {
    event_id: string;
    event_name: string;
    event_participations_quota?: number;
  };
}

interface GatheringViewGlobalEventsPageAdmin {
  type: 'view global events page admin';
}

interface GatheringViewGlobalEventsPageUser {
  type: 'view global events page';
}

interface GatheringViewSessionPage {
  type: 'view session page';
  payload: {
    event_id: string;
    event_name: string;
    session_id: string;
    session_name: string;
    session_participations_number?: number;
    session_participations_quota?: number;
    session_start_date?: string;
    session_end_date?: string;
    session_registration_start_date?: string;
    session_registration_end_date?: string;
  };
}
interface GatheringWithdrawFromSession {
  type: 'withdraw from session';
  payload: {
    event_id: string;
    session_id: string;
    session_end_date?: string;
  };
}

interface StartCreateEvent {
  type: 'start create event';
}

interface RemoveGatheringParticipation {
  type: 'remove participation';
  payload: {
    event_id: string;
  };
}

interface ShowCatalogFilter {
  type: 'show catalog filter';
}

interface SubscriptionSuccess {
  type: 'subscription success';
}

interface SurveyAdminProviderClick {
  type: 'click admin survey provider';
  payload: {
    survey_provider: SurveyProvider;
  };
}

interface SurveyAdminSubmit {
  type: 'click admin survey submission form button';
  payload: {
    click_origine: 'button' | 'text';
  };
}

interface SurveyAdminVisiualizeForm {
  type: 'click admin survey visualize button';
  payload: {
    survey_provider: SurveyProvider;
    survey_id: string;
  };
}

interface SurveyAdminUpdateButton {
  type: 'click admin survey update button';
  payload: {
    survey_provider: SurveyProvider;
    survey_id: string;
  };
}

interface SurveyAdminVisitCreatePage {
  type: 'visit admin create survey page';
}

interface SurveyAdminVisitInProgressPage {
  type: 'visit admin survey in progress page';
}

interface SurveyUserVisitPage {
  type: 'visit user survey page';
}

interface UpdateGathering {
  type: 'update event';
  payload: {
    event_id: string;
    event_name: string;
    event_option_highlighted?: boolean;
    event_participations_quota?: number;
    event_targeting?: CohortUserModeTrackingValues;
  };
}

interface UpdateGatheringSession {
  type: 'update session';
  payload: {
    event_id: string;
    event_name: string;
    session_end_date?: string;
    session_id: string;
    session_name: string;
    session_participations_quota?: number;
    session_registration_end_date?: string;
    session_registration_start_date?: string;
    session_start_date?: string;
  };
}

interface ViewBenefitsPage {
  type: 'view benefits page';
}
interface ViewCampaignPage {
  type: 'view campaign page';
  payload: {
    brand_id: string;
    brand_name: string;
    campaign_id: string;
    campaign_name: string;
  };
}
interface ViewCart {
  type: 'view cart';
}

interface ViewDiscoverPage {
  type: 'view discover page';
  payload: {
    previous_page?: string;
  };
}
interface ViewExplorePage {
  type: 'view explore page';
  payload: {
    previous_page?: string;
  };
}

interface ViewHomePage {
  type: 'view home page';
  payload: {
    previous_page?: string;
  };
}
interface ViewSelectionPage {
  type: 'view selection page';
  payload: {
    selection_name?: string;
  };
}

interface ViewFavoritePage {
  type: 'visit my favorites page';
  payload: {
    visit_my_favorites_origin: VisitMyFavoritesOrigin;
  };
}

interface AddToFavorite {
  type: 'add to favorites';
  payload: {
    campaign_name: string;
    favorites_origin: FavoritesOrigin;
  };
}

interface RemoveFromFavorite {
  type: 'remove from favorites';
  payload: {
    campaign_name: string;
    remove_favorites_origin: FavoritesOrigin;
  };
}

interface SubmitVote {
  type: 'submit vote';
  payload: {
    campaign_name: string;
    emoji_type: NpsEmoji;
  };
}

interface DeleteVote {
  type: 'delete vote';
  payload: {
    campaign_name: string;
  };
}

interface SubmitComment {
  type: 'submit comment';
  payload: {
    campaign_name: string;
  };
}

interface DeleteComment {
  type: 'delete comment';
  payload: {
    campaign_name: string;
  };
}

interface VisitSubmitIdeaUser {
  type: 'visit submit idea user';
}

interface VisitSubmitIdeaAdmin {
  type: 'visit submit idea admin';
}

interface ClickShowSubventionDetail {
  type: 'click en savoir plus';
}

export enum NpsEmoji {
  like = 'like',
  neutral = 'neutral',
  dislike = 'dislike',
}

export enum VisitMyFavoritesOrigin {
  userMenu = 'user_menu',
  addToaster = 'add_toaster',
  removeToaster = 'remove_toaster',
}

export enum FavoritesOrigin {
  campaignPage = 'campaign_page',
  catalogPage = 'catalog_page',
  suggestionsDropdown = 'suggestions_dropdown',
  discoverPage = 'discover_page',
  favoritesPage = 'favorites_page',
}
