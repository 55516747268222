import React, {
  forwardRef,
  InputHTMLAttributes,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { DayPicker, Matcher } from 'react-day-picker';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Popover } from 'react-tiny-popover';

import { Option } from '@components/atoms/InputSelect/InputSelect';
import { InputSelectDS } from '@components/molecules/InputSelectDS/InputSelectDS';
import {
  addYears,
  isValid,
  parse,
  subYears,
} from '@services/dateManager.service';
import { Icon } from '@src/components/atoms/Icon/Icon';
import { useLocalization } from '@src/i18n/localization.context';
import { useDOMInteractionState } from '@src/utils/hooks/useDOMInteractionState';
import { useOuterClick } from '@utils/hooks';
import { cx } from 'class-variance-authority';

import './InputDateTime.css';
import 'react-day-picker/dist/style.css';

const getHourOptions = () => {
  const options = [];
  for (let i = 0; i < 24; i++) {
    options.push({
      label: `${i < 10 ? '0' : ''}${i}h`,
      value: i.toString(),
    });
  }
  return options;
};

const HOURS_OPTIONS = getHourOptions();
const today = new Date();

type InputDateTimeProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'type' | 'aria-disabled'
> & {
  className?: string;
  inputClassName?: string;
  iconClassName?: string;
  disabledDays?: Matcher | Matcher[];
  disableFutureDays?: boolean;
  disablePastDays?: boolean;
  fromYear?: number;
  toYear?: number;
  hideTime?: boolean;
};

export const InputDateTime = forwardRef<HTMLInputElement, InputDateTimeProps>(
  (props, ref) => {
    const {
      className,
      inputClassName,
      disabled,
      disabledDays,
      disableFutureDays,
      disablePastDays,
      placeholder,
      fromYear,
      toYear,
      iconClassName,
      id: inputId,
      hideTime = false,
      ...rest
    } = props;
    const { t } = useTranslation();
    const { dateFnsLocale } = useLocalization();

    const { setValue, trigger, watch, getValues } = useFormContext();

    const [isDatePickerOpen, setDatePickerOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState<Date>();
    const [selectedHour, setSelectedHour] = useState<Option>();

    if (!inputId) {
      throw new Error(
        `InputDateTime requires an id, current value is : ${inputId}`,
      );
    }

    useEffect(() => {
      setSelectedHour(
        HOURS_OPTIONS.find(
          (elem) => parseInt(elem.value) === getValues(inputId)?.getHours(),
        ) || HOURS_OPTIONS[0],
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const overlayPopperRef = useOuterClick(
      () => setDatePickerOpen(false),
      'mousedown',
      true,
    );

    const pickerDisabledDays = useMemo(() => {
      let ret: Matcher[] = [];

      if (disabledDays) {
        if (Array.isArray(disabledDays)) {
          ret = [...ret, ...disabledDays];
        } else {
          ret.push(disabledDays);
        }
      }
      if (disableFutureDays) {
        ret.push({ after: today });
      }
      if (disablePastDays) {
        ret.push({ before: today });
      }

      return ret;
    }, [disabledDays, disableFutureDays, disablePastDays]);

    const defaultMonth = useMemo(() => {
      let specifiedPlaceholderDate;

      if (typeof placeholder === 'string') {
        const parsedDate = parse(placeholder, 'P');
        if (isValid(parsedDate)) specifiedPlaceholderDate = parsedDate;
      } else if (isValid(placeholder)) {
        specifiedPlaceholderDate = placeholder;
      }

      return specifiedPlaceholderDate;
    }, [placeholder]);

    const { state, handlers } = useDOMInteractionState({
      ...rest,
      disabled,
      status,
    });

    function handleDaySelect(date?: Date) {
      date?.setHours(parseInt(selectedHour?.value || '0'));
      setSelectedDate(date);
      if (inputId) {
        setValue(inputId, date, { shouldDirty: true });
      }
    }

    const handleTimeSelect = (option: Option | null) => {
      setSelectedHour(option || undefined);
      const date = getValues(inputId);
      if (date) {
        const newDate = new Date(date);
        newDate.setHours(parseInt(option?.value || '0'));
        setValue(inputId, newDate.toString(), { shouldDirty: true });
        trigger(inputId);
      }
    };

    const inputValue = watch(inputId);

    if (!inputId) return null;

    return (
      <div
        className={cx(className, 'flex flex-1 gap-2')}
        ref={overlayPopperRef}
        onBlur={() => trigger(inputId)}
      >
        <input ref={ref} hidden />
        <Popover
          align="start"
          positions={['bottom', 'top']}
          onClickOutside={() => setDatePickerOpen(false)}
          containerStyle={{ zIndex: '9999' }}
          containerClassName="bg-white rounded shadow pointer-events-auto"
          isOpen={isDatePickerOpen}
          content={() => (
            <DayPicker
              mode="single"
              initialFocus={isDatePickerOpen}
              defaultMonth={selectedDate ?? defaultMonth}
              selected={selectedDate || undefined}
              disabled={pickerDisabledDays}
              onSelect={handleDaySelect}
              locale={dateFnsLocale}
              fromYear={fromYear ?? subYears(today, 10).getFullYear()}
              toYear={toYear ?? addYears(today, 10).getFullYear()}
              captionLayout="dropdown"
              modifiersClassNames={{
                selected: 'rdp-button-selected',
              }}
              modifiersStyles={{
                outside: {
                  border: 'none',
                },
              }}
            />
          )}
        >
          <div
            className={cx(className, 'input-wrapper relative flex-1 p-0', {
              'input-wrapper--disabled': state === 'disabled',
              'input-wrapper--hover': state === 'hovered',
              'input-wrapper--focus': state === 'focused',
              'input-wrapper--error': state === 'error',
              'input-wrapper--warning': state === 'warning',
            })}
          >
            <input
              className={cx(
                inputClassName,
                'flex-1 bg-transparent px-4 py-2.5 text-sm',
              )}
              value={
                isValid(inputValue)
                  ? t('common:dates.short', { date: inputValue })
                  : ''
              }
              disabled={disabled}
              aria-disabled={disabled}
              size={1}
              {...rest}
              {...handlers}
              onFocus={() => setDatePickerOpen(true)}
              placeholder={t('forms.fields.inputDate.input.placeholder')}
            />
            <div className="absolute inset-y-0 right-3.5 flex items-center justify-center">
              <button type="button" onClick={() => setDatePickerOpen(true)}>
                <Icon
                  size="small"
                  name="calendar"
                  variant="v2"
                  className={cx(iconClassName, 'relative fill-red-500', {
                    'input-date-time-icon--disabled': state === 'disabled',
                    'input-date-time-icon--hover': state === 'hovered',
                    'input-date-time-icon--focus': state === 'focused',
                    'input-date-time-icon--error': state === 'error',
                    'input-date-time-icon--warning': state === 'warning',
                  })}
                />
              </button>
            </div>
          </div>
        </Popover>
        {!hideTime && (
          <InputSelectDS
            disabled={disabled}
            options={HOURS_OPTIONS}
            value={selectedHour}
            onChange={(option) => handleTimeSelect(option)}
            className="w-24"
          />
        )}
      </div>
    );
  },
);
