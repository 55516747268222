import { Trans, useTranslation } from 'react-i18next';

import { Button, Picture, Typography } from '@happypal-tech/design-system';
import { RedBox } from '@src/assets/images';
import { replaceUnwantedChars } from '@src/features-new/catalog/utils/replaceUnwantedChars';
import { Link } from '@tanstack/react-router';

type CatalogEmptyStateProps = {
  search?: string;
  showResetFilters?: boolean;
};

export const CatalogEmptyState = ({
  search,
  showResetFilters,
}: CatalogEmptyStateProps) => {
  const { t } = useTranslation('catalog', {
    keyPrefix: 'components.EmptyState',
  });

  return (
    <div className="m-auto flex flex-col items-center justify-center gap-2 min-h-screen/2 max-w-[28rem]">
      <Picture
        src={RedBox}
        alt={t('pictureDescription')}
        fit="contain"
        className="h-14 w-14 md:h-24 md:w-24"
      />
      <Typography type="heading-2" bold>
        {t('title')}
      </Typography>
      {search ? (
        <Typography type="body" className="text-center">
          <Trans
            i18nKey="components.EmptyState.searched_message"
            ns="catalog"
            values={{
              search: replaceUnwantedChars(search),
            }}
            components={{
              bold: <p className="font-bold inline" />,
              linebreak: <br />,
            }}
          />
        </Typography>
      ) : (
        <Typography type="body" className="text-center">
          <Trans
            i18nKey="components.EmptyState.message"
            ns="catalog"
            components={{
              1: <br />,
            }}
          />
        </Typography>
      )}
      {showResetFilters && (
        <Link from="/catalog" to="/catalog" search={{}}>
          <Button
            className="hidden md:inline-flex mt-6"
            type="button"
            variant="plain"
            color="primary"
          >
            {t('resetFilters')}
          </Button>
        </Link>
      )}
    </div>
  );
};
