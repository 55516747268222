import { SVGProps } from 'react';

import { icons } from './Icons';

export type IconNames = keyof typeof icons;

type IconComponent = React.FC<SVGProps<SVGSVGElement>>;

export enum IconSizes {
  xsmall = 12, // use this size only for SVGs with 12px variant available
  small = 16,
  medium = 24,
  large = 32,
  xlarge = 48,
}

export type IconType = keyof typeof icons;

export type IconProps<Icon extends IconType> = {
  className?: string;
  name: Icon;
  variant?: keyof (typeof icons)[Icon];
  size?: keyof typeof IconSizes;
  // TO-DO Add Aria props
};

/**
 * @deprecated
 */
export const Icon = <Icon extends keyof typeof icons>(
  props: IconProps<Icon>,
) => {
  const { size = 'medium', name, variant = 'default', className } = props;

  const svgSize = IconSizes[size];
  const SvgIcon = icons[name][variant] as IconComponent;

  return <SvgIcon width={svgSize} height={svgSize} className={className} />;
};
