import { createFileRoute, notFound } from '@tanstack/react-router';

import {
  AdminDashboardExportDocument,
  AdminDashboardExportQuery,
  AdminDashboardExportQueryVariables,
} from './~route.generated';

export const Route = createFileRoute(
  '/_authenticated-layout/admin/dashboard/export',
)({
  loader: async ({ context }) => {
    const res = await context.apolloClient.query<
      AdminDashboardExportQuery,
      AdminDashboardExportQueryVariables
    >({
      query: AdminDashboardExportDocument,
    });

    const viewer = res.data.viewer;
    const company = viewer?.company;

    if (!company) throw notFound();

    return { company, viewer };
  },
});
