import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/auth/_auth-carousel-layout/two-factor')({
  loader: ({ location, navigate }) => {
    const { email, password } = location.state;

    if (!email || !password) {
      throw navigate({ to: '/auth/login', replace: true });
    }

    return { email, password };
  },
});
