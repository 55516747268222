import { ComponentProps, ReactNode } from 'react';

import { Modal } from '@happypal-tech/design-system';
import { Accordion } from '@src/features-new/design-system/components/atoms/Accordion/Accordion';

interface FiltersDrawerProps
  extends Pick<ComponentProps<typeof Modal.Drawer>, 'opened' | 'onOpenChange'> {
  children: ReactNode;
}

export const FiltersDrawer = ({
  opened,
  onOpenChange,
  children,
}: FiltersDrawerProps) => {
  return (
    <Modal.Drawer
      // FIXME: we should have title + description
      title=""
      description=""
      descriptionHidden
      opened={opened}
      onOpenChange={onOpenChange}
      rootClassName="md:hidden z-100"
      childrenClassName="mt-0"
    >
      <Accordion type="multiple">{children}</Accordion>
    </Modal.Drawer>
  );
};
