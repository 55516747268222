import { MutableRefObject, useCallback, useEffect, useState } from 'react';

import { throttle } from 'radash';

type HorizontalDirection = { right: boolean; left: boolean };
type VerticalDirection = { top: boolean; bottom: boolean };

export type CanScrollDirections = {
  v: VerticalDirection;
  h: HorizontalDirection;
};

export const useIsScrollable = (
  el: MutableRefObject<HTMLDivElement | null>,
): [CanScrollDirections, () => void] => {
  const [vertical, setVertical] = useState({ top: false, bottom: false });
  const [horizontal, setHorizontal] = useState({ right: false, left: false });

  const updatePossibleActions = useCallback(() => {
    if (!el.current) return;

    setHorizontal({
      right:
        el.current.scrollWidth > el.current.clientWidth &&
        el.current.scrollWidth >
          el.current.clientWidth + Math.ceil(el.current.scrollLeft) + 1,
      left: el.current.scrollLeft !== 0,
    });

    setVertical({
      bottom:
        el.current.scrollHeight > el.current.clientHeight &&
        el.current.scrollHeight >
          el.current.clientHeight + Math.ceil(el.current.scrollTop) + 1,
      top: el.current.scrollTop !== 0,
    });
  }, [el]);

  useEffect(() => {
    if (!el.current) return;

    updatePossibleActions(); // Setting the first values
    const throttledFunction = throttle(
      { interval: 100 },
      updatePossibleActions,
    );

    el.current.addEventListener('scroll', throttledFunction);
    window.addEventListener('resize', throttledFunction);

    return () => {
      window.removeEventListener('scroll', throttledFunction);
      window.removeEventListener('resize', throttledFunction);
    };
  }, [el, updatePossibleActions]);

  return [
    {
      v: vertical,
      h: horizontal,
    },
    updatePossibleActions,
  ];
};
