import { useTranslation } from 'react-i18next';

import {
  BadgeStandalone,
  Checkbox,
  Typography,
} from '@happypal-tech/design-system';
import { AccordionContent } from '@radix-ui/react-accordion';
import { Accordion } from '@src/features-new/design-system/components/atoms/Accordion/Accordion';
import { cx } from 'class-variance-authority';

export const catalogSearchTag = [
  'url',
  'ticket',
  'gift_card',
  'promo_code',
] as const;

export type AlgoliaOfferType = (typeof catalogSearchTag)[number];

interface AccordionOfferTypesProps {
  activeOfferTypes: AlgoliaOfferType[];
  onSelect: (type: AlgoliaOfferType) => void;
}

export const AccordionOfferTypes = (props: AccordionOfferTypesProps) => {
  const { onSelect, activeOfferTypes } = props;

  const { t } = useTranslation('catalog', {
    keyPrefix: 'components.CatalogSearchFilters',
  });

  const { t: tOfferTypes } = useTranslation('core', {
    keyPrefix: 'offerTypes',
  });

  const count = activeOfferTypes.length;

  return (
    <Accordion.Item value="offerTypes">
      <Accordion.Trigger
        className={cx(
          'group p-4',
          'data-[state=open]:text-neutral-darkest data-[state=closed]:text-neutral-dark',
        )}
        subClassName="w-full"
      >
        <Typography
          type="subtitle"
          semibold
          className={cx(
            'flex gap-2 items-center',
            'group-data-[state=open]:text-neutral-darkest group-data-[state=closed]:text-neutral-dark',
          )}
        >
          {t('types')}
          <BadgeStandalone
            color="primary"
            showDot={count !== 0}
            title={t('types')}
          />
        </Typography>
      </Accordion.Trigger>

      <AccordionContent className="w-80 max-h-[28rem] overflow-auto mx-4">
        {catalogSearchTag.map((offerType) => (
          <Checkbox
            onCheckedChange={() => onSelect(offerType)}
            checked={activeOfferTypes.includes(offerType)}
            key={offerType}
            className="w-full p-3"
            label={
              <div className="flex gap-2 items-center">
                <Typography type="body" semibold>
                  {tOfferTypes(offerType)}
                </Typography>
              </div>
            }
          />
        ))}
      </AccordionContent>
    </Accordion.Item>
  );
};
