import { HTMLAttributes, Reducer, useReducer } from 'react';

type UseInteractionStateProps<
  E = unknown,
  S extends string | null = null,
> = Pick<HTMLAttributes<E>, 'onBlur' | 'onFocus'> &
  Pick<HTMLAttributes<HTMLDivElement>, 'onMouseEnter' | 'onMouseLeave'> & {
    disabled?: boolean;
    /** If provided will be returned before state but after disabled */
    status?: S;
  };

/**
 * Returns a state that indicate if the element is focused, hovered, disabled or any other status provided.
 *
 * The handlers must be attached to the element or depending on the use case, to a parent element or both.
 * Be careful to never use the same handler twice!
 *
 * @see [FormInputCheckbox](https://github.com/happypal-tech/happypal-app-web/blob/d9b8fa5649ec5b02c30ed1f696493720960f2da0/src/components/molecules/FormInputCheckbox/FormInputCheckbox.tsx#L67-L68)
 */
export const useDOMInteractionState = <
  E = unknown,
  S extends string | null = null,
>(
  props: UseInteractionStateProps<E, S>,
): DOMInteractionStateReturn<E, S> => {
  const {
    onBlur,
    onFocus,
    onMouseEnter,
    onMouseLeave,
    disabled = false,
    status,
  } = props;

  const [{ focused, hovered }, dispatch] = useReducer(reducer, {
    hovered: false,
    focused: false,
  });

  const handleFocus = (event?: React.FocusEvent<E>) => {
    if (event) onFocus?.(event);
    dispatch('FOCUS');
  };

  const handleBlur = (event?: React.FocusEvent<E>) => {
    if (event) onBlur?.(event);
    dispatch('BLUR');
  };

  const handleMouseEnter = (event?: React.MouseEvent<HTMLDivElement>) => {
    if (event) onMouseEnter?.(event);
    dispatch('MOUSE_ENTER');
  };

  const handleMouseLeave = (event?: React.MouseEvent<HTMLDivElement>) => {
    if (event) onMouseLeave?.(event);
    dispatch('MOUSE_LEAVE');
  };

  const states: (boolean | DOMInteractionState | S)[] = [
    disabled && 'disabled',
    !!status && status,
    focused && 'focused',
    hovered && 'hovered',
    'default',
  ];

  const state = states.find((s) => s !== false) as S extends null
    ? DOMInteractionState
    : DOMInteractionState | S;

  return {
    state,
    handlers: {
      onFocus: handleFocus,
      onBlur: handleBlur,
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave,
    },
  };
};

export type DOMInteractionState =
  | 'default'
  | 'disabled'
  | 'hovered'
  | 'focused';

type DOMInteractionStateReturn<E = unknown, S = string | null> = {
  state: S extends null ? DOMInteractionState : DOMInteractionState | S;
  handlers: {
    onFocus: (event?: React.FocusEvent<E>) => void;
    onBlur: (event?: React.FocusEvent<E>) => void;
    onMouseEnter: (event?: React.MouseEvent<HTMLDivElement>) => void;
    onMouseLeave: (event?: React.MouseEvent<HTMLDivElement>) => void;
  };
};

type State = { hovered: boolean; focused: boolean };
type Action = 'MOUSE_ENTER' | 'MOUSE_LEAVE' | 'FOCUS' | 'BLUR';

const reducer: Reducer<State, Action> = (state, action) => {
  switch (action) {
    case 'MOUSE_ENTER':
      return { ...state, hovered: true };
    case 'MOUSE_LEAVE':
      return { ...state, hovered: false };
    case 'FOCUS':
      return { ...state, focused: true };
    case 'BLUR':
      return { ...state, focused: false };
    default:
      return state;
  }
};
