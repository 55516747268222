import type { FieldPolicy } from '@apollo/client';

import { TypedTypePolicies } from './generated/apollo-helpers';
import { CorePagination, Pagination } from './generated/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type KeyArgs = FieldPolicy<any>['keyArgs'];

// Inspired by https://github.com/apollographql/apollo-client/blob/main/src/utilities/policies/pagination.ts#L33-L49
function corePagination(keyArgs: KeyArgs = false): FieldPolicy<CorePagination> {
  return {
    keyArgs,
    merge(existing, incoming, { args }) {
      const edges = existing?.edges ? existing?.edges.slice(0) : [];

      if (incoming?.edges) {
        if (args) {
          // Assume an offset of 0 if args.offset omitted.
          const { skip = 0 } = args;
          for (let i = 0; i < incoming.edges.length; ++i) {
            edges[skip + i] = incoming.edges[i]!;
          }
        } else {
          // It's unusual (probably a mistake) for a paginated field not
          // to receive any arguments, so you might prefer to throw an
          // exception here, instead of recovering by appending incoming
          // onto the existing array.
          throw new Error('Pagination should receive arguments');
        }
      }

      return {
        ...existing,
        ...incoming,
        totalCount: incoming?.totalCount || existing?.totalCount || 0,
        edges,
        pageInfo: incoming?.pageInfo,
      };
    },
  };
}

export function simpleArrayMerge<T>(
  keyArgs: KeyArgs = false,
): FieldPolicy<T[]> {
  return {
    keyArgs,
    merge(existing, incoming) {
      return incoming ?? existing ?? [];
    },
  };
}

/** @deprecated Please use corePagination wherever possible */
export function customPagination(
  keyArgs: KeyArgs = false,
): FieldPolicy<Pagination> {
  return {
    keyArgs,
    merge(existing, incoming, { args }) {
      const nodes = existing?.nodes ? existing?.nodes.slice(0) : [];
      const edges = existing?.edges ? existing?.edges.slice(0) : [];

      // FIXME: Remove || - when backend is ready
      const start = args
        ? args.skip || 0
        : nodes.length > 0
          ? nodes.length
          : edges.length;
      const end = start + (incoming.nodes || incoming.edges || []).length;

      // Acceptable double for-loop since we never use both edges and nodes at the same time

      if (incoming.nodes) {
        for (let i = start; i < end; ++i) {
          nodes[i] = incoming.nodes[i - start]!;
        }
      }

      if (incoming.edges) {
        for (let i = start; i < end; ++i) {
          edges[i] = incoming.edges[i - start]!;
        }
      }

      return {
        ...existing,
        ...incoming,
        edges,
        nodes,
      };
    },
  };
}

export const typePolicies: TypedTypePolicies = {
  Query: {
    fields: {
      campaignPagination: customPagination(['filter', 'sorter']),
      brandsPagination: customPagination(['filter', 'sorter']),
      categoriesPagination: customPagination(['filter', 'sorter']),
      offersPagination: customPagination(['filter', 'sorter']),
      articlesPagination: customPagination(['filter', 'sorter']),
      happeningPagination: customPagination(['filter', 'sorter']),
      subventionReimbursementRequestPagination: customPagination([
        'filter',
        'sorter',
      ]),
      favoritePagination: corePagination(['filter', 'sorter']),
    },
  },
  CampaignCardInfos: {
    merge: true,
    keyFields: ['campaignId'],
  },
  CompanyAdmin: {
    fields: {
      gatheringPagination: corePagination(['filter', 'sorter']),
    },
  },
  Gathering: {
    fields: {
      sessions: simpleArrayMerge(),
      sessionsInfo: {
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming);
        },
      },
    },
  },
  GatheringSession: {
    fields: {
      prices: simpleArrayMerge(),
    },
  },
  Campaign: {
    fields: {
      offersPagination: customPagination(['filter', 'sorter']),
    },
  },
  CampaignSelection: {
    fields: {
      campaignPagination: corePagination(['filter', 'sorter']),
    },
  },
  Brand: {
    fields: {
      offersPagination: customPagination(['filter', 'sorter']),
    },
  },
  Category: {
    fields: {
      childrenPagination: customPagination(['filter', 'sorter']),
      campaignPagination: customPagination(['filter', 'sorter']),
    },
  },
  ArticleCategory: {
    fields: {
      articlesPagination: customPagination(['filter', 'sorter']),
    },
  },
  User: {
    fields: {
      notificationsPagination: customPagination(['filter', 'sorter']),
      subventionPagination: corePagination(['filter', 'sorter']),
      happeningPagination: customPagination(['filter', 'sorter']),
      gatheringPagination: corePagination(['filter', 'sorter']),
      orderPagination: customPagination(['filter', 'sorter']),
    },
  },
  UserPreferences: {
    fields: {
      properties: {
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming);
        },
      },
    },
  },
  Company: {
    fields: {
      discoverCatalogCampaignPagination: corePagination(),
      newDiscoverCampaignPagination: customPagination(['filter', 'sorter']),
      publicationsPagination: customPagination(['filter', 'sorter']),
      happeningPagination: customPagination(['sorter']),
      subventionPagination: corePagination(['filter', 'sorter']),
      subventionInvoicePagination: customPagination(['filter', 'sorter']),
      features: {
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming);
        },
      },
      featureConfig: {
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming);
        },
      },
      beneficiaryPagination: corePagination(),
      pointsOfContactPagination: customPagination(['filter', 'sorter']),
    },
  },
  MangopayWallet: {
    fields: {
      mangopayBankwireDirectPayInPagination: customPagination([
        'filter',
        'sorter',
      ]),
    },
  },
  Happening: {
    fields: {
      viewerRegistrationsPagination: customPagination(['filter', 'sorter']),
      attachmentsPagination: customPagination(['filter', 'sorter']),
      registrationsPagination: customPagination(['filter', 'sorter']),
    },
  },
  Catalog: {
    fields: {
      campaignPagination: corePagination(['filter', 'sorter', 'position']),
    },
  },
  UserIntegrationConnectorConfig: {
    fields: {
      synchronisationsPagination: corePagination(['filter', 'sorter']),
    },
  },
};
