import { useEffect } from 'react';
import { useInstantSearchContext } from 'react-instantsearch';

import aa from 'search-insights';
import type { InsightsClickObjectIDsEvent } from 'search-insights/dist/click';
import type { InsightsSearchConversionObjectIDsEvent } from 'search-insights/dist/conversion';

type AlgoliaSearchInsightsProps = {
  apiKey: string;
  appId: string;
  viewer: { id: string };
};

export const AlgoliaSearchInsights = (props: AlgoliaSearchInsightsProps) => {
  const { apiKey, appId, viewer } = props;

  useEffect(() => {
    aa('init', {
      appId,
      apiKey,
      authenticatedUserToken: viewer.id,
    });
  }, [apiKey, appId, viewer]);

  return null;
};

export const useAlgoliaSearchInsights = () => {
  try {
    // Used to prevent throwing an error when the context is not available during super admin preview
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { indexName } = useInstantSearchContext();

    return {
      addedToCartObjectIds: ({
        queryID,
        ...options
      }: Omit<
        InsightsSearchConversionObjectIDsEvent,
        'index' | 'userToken' | 'authenticatedUserToken'
      > & { queryID?: string }) =>
        queryID
          ? aa('addedToCartObjectIDsAfterSearch', {
              index: indexName,
              queryID,
              ...options,
            })
          : aa('addedToCartObjectIDs', {
              index: indexName,
              ...options,
            }),
      purchasedObjectIds: ({
        queryID,
        ...options
      }: Omit<
        InsightsSearchConversionObjectIDsEvent,
        'index' | 'userToken' | 'authenticatedUserToken'
      > & { queryID?: string }) =>
        queryID
          ? aa('purchasedObjectIDsAfterSearch', {
              index: indexName,
              queryID,
              ...options,
            })
          : aa('purchasedObjectIDs', {
              index: indexName,
              ...options,
            }),
      convertedObjectIds: ({
        queryID,
        ...options
      }: Omit<
        InsightsSearchConversionObjectIDsEvent,
        'index' | 'userToken' | 'authenticatedUserToken'
      > & { queryID?: string }) =>
        queryID
          ? aa('convertedObjectIDsAfterSearch', {
              index: indexName,
              queryID,
              ...options,
            })
          : aa('convertedObjectIDs', {
              index: indexName,
              ...options,
            }),
      clickedObjectIds: ({
        queryID,
        positions,
        ...options
      }: Omit<
        InsightsClickObjectIDsEvent,
        'index' | 'userToken' | 'authenticatedUserToken'
      > & { queryID?: string; positions?: number[] }) =>
        queryID && positions
          ? aa('clickedObjectIDsAfterSearch', {
              index: indexName,
              queryID,
              positions,
              ...options,
            })
          : aa('clickedObjectIDs', {
              index: indexName,
              ...options,
            }),
    };
  } catch (error) {
    return {
      addedToCartObjectIds: () => {},
      purchasedObjectIds: () => {},
      convertedObjectIds: () => {},
      clickedObjectIds: () => {},
    };
  }
};
