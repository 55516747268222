import {
  ButtonImage,
  DocumentImage,
  DocumentsImageHD,
  Drum2Image,
  FeedImageHD,
  SettingsImage,
} from '@assets/images';
import { ProgressivePictureFragment } from '@components/atoms/ProgressivePicture/ProgressivePicture.generated';
import { format } from '@services/dateManager.service';
import {
  Article,
  Beneficiary,
  CompanyDocument,
  NotificationType,
  Offer,
  Publication,
  Subvention,
  SubventionInvoice,
  User,
} from '@src/graphql/generated/types';
import { getIconFromSubvention } from '@utils/getIconFromSubvention';
import i18next from 'i18next';

import { ViewerNotificationFragment } from './ViewerNotifications.generated';

interface ExtractedNotificationData {
  title: string;
  from?: string;
  picture?: ProgressivePictureFragment | string | null;
  content?: string;
  link?: string;
}

export const extractNotificationData = (
  notification: ViewerNotificationFragment,
) => {
  let extractedNotificationData: ExtractedNotificationData;
  let icon;
  let target;
  const { type, author: originalAuthor } = notification;

  switch (type) {
    // SUBVENTION
    case NotificationType.SubventionPublished:
      target = notification.target as unknown as Subvention;

      extractedNotificationData = {
        title: `${target.name}`,
        picture: getIconFromSubvention(target),
        content: target.subventionSchema?.description || undefined,
      };
      break;

    // OFFERS
    case NotificationType.OfferPublished:
      target = notification.target as unknown as Offer;

      extractedNotificationData = {
        title: `${target.brand?.name} - ${target.name}`,
        picture: target.campaign?.cover as ProgressivePictureFragment,
        content: undefined,
      };
      break;

    // BENEFICIARIES
    case NotificationType.BeneficiaryAccepted:
    case NotificationType.BeneficiaryRejected:
    case NotificationType.BeneficiaryDeleted:
    case NotificationType.BeneficiaryRejectedFollowUp:
      target = notification.target as Beneficiary;

      if (target.__typename === 'Beneficiary')
        icon = [
          NotificationType.BeneficiaryAccepted,
          NotificationType.BeneficiaryRejected,
          NotificationType.BeneficiaryRejectedFollowUp,
        ].includes(notification.type)
          ? ButtonImage
          : Drum2Image;

      extractedNotificationData = {
        title: `${target.firstName} ${target.lastName}`,
        picture: icon,
      };

      break;

    // ARTICLES
    case NotificationType.ArticlePublished:
      target = notification.target as Article;

      extractedNotificationData = {
        title: `${target.title}`,
        picture: target.cover as ProgressivePictureFragment,
      };
      break;

    // PUBLICATIONS
    case NotificationType.PublicationPublished:
      target = notification.target as Publication;

      extractedNotificationData = {
        title: `${target.title}`,
        picture: FeedImageHD,
      };
      break;

    // DOCUMENTS
    case NotificationType.DocumentPublished:
      target = notification.target as CompanyDocument & {
        documentName: string;
      };

      extractedNotificationData = {
        title: `${target.documentName}`,
        picture: DocumentsImageHD,
      };
      break;

    // USERS
    case NotificationType.UserEmailChanged:
      target = notification.target as User;

      extractedNotificationData = {
        title: '',
        content: i18next.t(
          'translation:notifications.notificationCenter.type.USER_EMAIL_CHANGED.content',
          'Votre nouvel email est : {{email}}',
          {
            email: target.email,
          },
        ),
        picture: SettingsImage,
      };
      break;

    // Invoices
    case NotificationType.SubventionInvoiceCreated:
      target = notification.target as unknown as SubventionInvoice;
      // eslint-disable-next-line no-case-declarations
      const periodStart = target.periodStart || new Date();
      extractedNotificationData = {
        title: i18next.t(
          'translation:notifications.notificationCenter.type.SUBVENTION_INVOICE_CREATED.title',
          'Une nouvelle facture est disponible',
        ),
        content: i18next.t(
          'translation:notifications.notificationCenter.type.SUBVENTION_INVOICE_CREATED.content',
          'Votre facture de consommation des subventions pour {{month}} {{year}} est désormais disponible depuis votre espace facturation.',
          {
            month: format(periodStart, 'MMMM'),
            year: format(periodStart, 'YYYY'),
          },
        ),
        picture: DocumentImage,
      };
      break;

    // DEFAULT
    default:
      extractedNotificationData = {
        title: '',
      };
      break;
  }

  extractedNotificationData.from =
    originalAuthor?.__typename === 'Company'
      ? originalAuthor.displayName
      : originalAuthor?.company?.displayName;

  return {
    ...extractedNotificationData,
    ...notification,
  };
};
