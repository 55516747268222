import { useListenToDeeplinks } from '@src/features-new/core/deeplinks/useListenToDeepLinks';
import { usePushNotifications } from '@src/features-new/core/notifications/useNotifications';
import { ConnectedUserFragment } from '@src/index.generated';

interface AppEngineProps {
  viewer: ConnectedUserFragment | null;
}
export const AppEngine = ({ viewer }: AppEngineProps) => {
  usePushNotifications(viewer);
  useListenToDeeplinks();

  return null;
};
