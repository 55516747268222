import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  addDays,
  format,
  formatDistanceStrict,
} from '@services/dateManager.service';
import { Icon } from '@src/components/atoms/Icon';
import { Text } from '@src/components/atoms/Text';

import { OfferExpirationFragment } from './OfferExpiration.generated';

export type OfferExpirationProps = {
  offer: OfferExpirationFragment;
  showIcon?: boolean;
};

export const OfferExpiration = ({
  offer,
  showIcon = true,
}: OfferExpirationProps) => {
  const { t } = useTranslation();
  const [expiresAt, setExpiresAt] = useState<Date>();
  const [expiresInDays, setExpiresInDays] = useState<number>();

  useEffect(() => {
    if (
      offer.__typename === 'OfferGiftCard' ||
      offer.__typename === 'OfferTicket'
    ) {
      if (offer.product.expiresAt) setExpiresAt(offer.product.expiresAt);
      else if (offer.product.expiresInDays)
        setExpiresInDays(offer.product.expiresInDays);
    } else {
      switch (offer.__typename) {
        case 'OfferCouponClaimable':
          if (offer.expiresAt) setExpiresAt(offer.expiresAt);
          break;
        case 'OfferCouponPublic':
          if (offer.coupon?.expiresAt) setExpiresAt(offer.coupon.expiresAt);
          break;
      }
    }
  }, [offer]);

  const dateLabel = useMemo(() => {
    if (expiresAt) {
      return format(expiresAt, 'L');
    } else if (expiresInDays) {
      const distance = formatDistanceStrict(
        addDays(new Date(), expiresInDays),
        new Date(),
      );
      return `${t(
        'campaignDetails.offers.offer.expiration.expiresInDays',
        "{{distance}} après l'achat",
        { distance },
      )}`;
    }
    return t(
      'campaignDetails.offers.offer.expiration.unknown',
      'Non renseignée',
    );
  }, [expiresAt, expiresInDays, t]);

  return (
    <div className="flex items-center space-x-1">
      {showIcon && <Icon name="calendar" />}
      <div className="flex items-center space-x-1">
        <Text type="bodyLargeText" className="leading-4 text-black">
          {t(
            'campaignDetails.offers.offer.expiration.label',
            "Valide jusqu'à :",
          )}
        </Text>
        <Text type="bodyRegularText" className="leading-4 text-black">
          {dateLabel}
        </Text>
      </div>
    </div>
  );
};
