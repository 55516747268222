import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { toast } from '@happypal-tech/design-system';
import { Loader } from '@src/components/atoms/Loader';
import {
  AdminHappeningDetailPageDocument,
  AdminHappeningDetailPageQuery,
  AdminHappeningDetailPageQueryVariables,
} from '@src/features/happenings/pages/AdminHappeningDetailPage/AdminHappeningDetailPage.generated';
import { createFileRoute, Navigate, notFound } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/_authenticated-layout/admin/happenings/detail/$happeningId',
)({
  loader: async ({ context, params }) => {
    const { happeningId } = params;
    const res = await context.apolloClient.query<
      AdminHappeningDetailPageQuery,
      AdminHappeningDetailPageQueryVariables
    >({
      query: AdminHappeningDetailPageDocument,
      variables: {
        happeningId,
      },
    });
    const happening = res.data.happening;
    if (!happening) {
      throw notFound();
    }

    return { happening };
  },
  pendingComponent: () => <Loader />,
  notFoundComponent: function NotFoundComponent() {
    const { t } = useTranslation('happeningAdmin');
    useEffect(() => {
      toast.error(t('adminHappeningDetailPage.notFound'));
    }, [t]);
    return <Navigate to="/admin/happenings" replace />;
  },
});
