import { cx } from 'class-variance-authority';

import './Progress.css';

export enum ProgressVariant {
  default = 'default', // standard
  thick = 'thick',
  advanced = 'advanced',
}

export enum ProgressColor {
  primary = 'primary',
  inverse = 'inverse',
}

export type ProgressProps = {
  value: number;
  className?: string;
  variant?: keyof typeof ProgressVariant;
  color?: keyof typeof ProgressColor;
  valueClassName?: string;
  isDisabled?: boolean;
};

export const Progress = ({
  value,
  className,
  variant = 'default',
  color = 'primary',
  valueClassName,
  isDisabled = false,
}: ProgressProps) => {
  const cappedValue = value > 100 ? 100 : value;

  switch (variant) {
    case 'thick':
      return (
        <div
          className={cx(
            `progress--thick-container`,
            {
              'w-full ': !className,
              ['progress--thick-container-primary']:
                variant === 'thick' && color === 'primary',
              ['progress--thick-container-primary-disabled']:
                variant === 'thick' && color === 'primary' && isDisabled,
              ['progress--thick-container-inverse']:
                variant === 'thick' && color === 'inverse',
              ['progress--thick-container-inverse-disabled']:
                variant === 'thick' && color === 'inverse' && isDisabled,
            },
            className,
          )}
        >
          <div
            className={cx(`progress--thick-fill`, {
              ['progress--thick-fill-primary']:
                variant === 'thick' && color === 'primary',
              ['progress--thick-fill-primary-disabled']:
                variant === 'thick' && color === 'primary' && isDisabled,
              ['progress--thick-fill-inverse']:
                variant === 'thick' && color === 'inverse',
              ['progress--thick-fill-inverse-disabled']:
                variant === 'thick' && color === 'inverse' && isDisabled,
            })}
            style={{
              width: `${cappedValue}%`,
              transition: 'width 0.2s ease-in-out',
            }}
          />
        </div>
      );
    case 'advanced':
      return (
        <div
          className={cx(
            `flex items-center ${value !== 0 && 'space-x-2'}`,
            className,
          )}
        >
          <span
            className={cx(
              `progress--advanced-fill`,
              {
                ['progress--advanced-fill-primary']:
                  variant === 'advanced' && color === 'primary',
                ['progress--advanced-fill-primary-disabled']:
                  variant === 'advanced' && color === 'primary' && isDisabled,
                ['progress--advanced-fill-inverse']:
                  variant === 'advanced' && color === 'inverse',
                ['progress--advanced-fill-inverse-disabled']:
                  variant === 'advanced' && color === 'inverse' && isDisabled,
              },
              value,
            )}
            style={{ width: `${cappedValue}%` }}
          />
          <span
            className={cx(`progress--advanced-container`, {
              ['progress--advanced-container-primary']:
                variant === 'advanced' && color === 'primary',
              ['progress--advanced-container-primary-disabled']:
                variant === 'advanced' && color === 'primary' && isDisabled,
              ['progress--advanced-container-inverse']:
                variant === 'advanced' && color === 'inverse',
              ['progress--advanced-container-inverse-disabled']:
                variant === 'advanced' && color === 'inverse' && isDisabled,
            })}
            style={{ width: `${100 - cappedValue}%` }}
          />
        </div>
      );
    default:
      return (
        <div
          className={cx(
            `progress--default-container`,
            {
              ['progress--default-container-primary']:
                variant === 'default' && color === 'primary',
              ['progress--default-container-primary-disabled']:
                variant === 'default' && color === 'primary' && isDisabled,
              ['progress--default-container-inverse']:
                variant === 'default' && color === 'inverse',
              ['progress--default-container-inverse-disabled']:
                variant === 'default' && color === 'inverse' && isDisabled,
            },
            className,
          )}
        >
          <div
            className={cx(
              'progress--default-fill',
              {
                ['progress--default-fill-primary']:
                  variant === 'default' && color === 'primary',
                ['progress--default-fill-primary-disabled']:
                  variant === 'default' && color === 'primary' && isDisabled,
                ['progress--default-fill-inverse']:
                  variant === 'default' && color === 'inverse',
                ['progress--default-fill-inverse-disabled']:
                  variant === 'default' && color === 'inverse' && isDisabled,
              },
              valueClassName,
            )}
            style={{
              width: `${cappedValue}%`,
              transition: 'width 0.2s ease-in-out',
            }}
          />
        </div>
      );
  }
};
