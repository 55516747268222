import { UserIntegrationProviderName } from '@src/graphql/generated/types';
import { createFileRoute, notFound } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/_authenticated-layout/admin/integrations/create/$providerName',
)({
  loader: ({ context, params }) => {
    const company = context?.viewer?.company;

    const values = Object.values(UserIntegrationProviderName) as string[];

    if (!values.includes(params.providerName) || !company) {
      throw notFound();
    }

    return {
      company,
      providerName: params.providerName as UserIntegrationProviderName,
    };
  },
});
