import { Picture } from '@happypal-tech/design-system';

import FallbackLogo from './assets/menu-logo.svg?react';

interface CompanyPictureProps {
  logoUrl?: string | null;
}
export const CompanyPicture = ({ logoUrl }: CompanyPictureProps) => {
  if (!logoUrl) {
    return <FallbackLogo className="h-full w-full object-contain" />;
  }
  return <Picture className="h-full w-full" fit="contain" src={logoUrl} />;
};
