import { env } from '@env';
import { z } from '@happypal-tech/design-system';
import { createFileRoute, Navigate } from '@tanstack/react-router';

const SsoFederated = () => {
  const { redirectTo } = Route.useSearch();

  if (!redirectTo) return <Navigate to="/" replace />;

  if (redirectTo.startsWith('/')) {
    return <Navigate to={redirectTo} replace />;
  }
  const isValidExternalUrl =
    new URL(redirectTo).origin === env.REACT_APP_APP_URL;

  if (isValidExternalUrl) {
    return window.location.replace(redirectTo);
  }
  return <Navigate to="/" replace />;
};

export const Route = createFileRoute('/sso/federated')({
  component: SsoFederated,
  validateSearch: z.object({
    redirectTo: z.string().optional(),
  }),
});
