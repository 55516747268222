import { useTranslation } from 'react-i18next';

import { useAlgoliaSearchInsights } from '@src/lib/algolia/search-insights';

import { CouponLinkFragment } from './CouponLink.generated';

type CouponLinkProps = {
  offer: CouponLinkFragment;
  campaignId: string;
  queryID?: string;
};

export const CouponLink = (props: CouponLinkProps) => {
  const { offer, campaignId, queryID } = props;

  const { t } = useTranslation();
  const { convertedObjectIds } = useAlgoliaSearchInsights();

  const handleClick = () => {
    convertedObjectIds({
      eventName: queryID
        ? 'Offer Link Clicked After Search'
        : 'Offer Link Clicked',
      objectIDs: [campaignId],
      queryID,
    });
  };

  return (
    <div className="flex-1">
      <a
        onClick={handleClick}
        className="flex h-11 flex-grow items-center justify-center rounded-2xl bg-primary px-6 font-display text-sm font-semibold text-white transition-all duration-300 hover:bg-primary-dark md:rounded-xl"
        href={offer.url?.href}
        target="_blank"
        rel="noreferrer noopener"
      >
        {t('campaignDetails.offers.offer.goToOffer', "Accéder à l'offre")}
      </a>
    </div>
  );
};
