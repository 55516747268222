/* eslint-disable react-hooks/rules-of-hooks */
import { init } from '@amplitude/analytics-browser';
import { env } from '@env';
import { useEffectOnce } from '@src/hooks/use-effect-once';

export const AmplitudeScript = () => {
  if (!env.REACT_APP_AMPLITUDE_KEY) return;

  useEffectOnce(() => {
    init(env.REACT_APP_AMPLITUDE_KEY, {
      defaultTracking: {
        sessions: true,
        pageViews: false,
        formInteractions: false,
        fileDownloads: true,
      },
      serverZone: 'EU',
      appVersion: env.REACT_APP_VERSION,
    });
  });

  return null;
};
