/* eslint-disable @typescript-eslint/no-explicit-any */
import { type Column, type Row } from '@tanstack/react-table';
import { cx } from 'class-variance-authority';

import { TableBodyCell } from './TableBodyCell';

interface TableBodyRowProps {
  row: Row<any>;
}

export const TableBodyRow = (props: TableBodyRowProps) => {
  const { row } = props;

  const isSelected = row.getIsSelected();

  return (
    <div
      className={cx('flex w-full px-1', 'transition-colors', {
        'bg-white hover:bg-neutral-lightest': !isSelected,
        'bg-primary-lightest hover:bg-primary-lighter': isSelected,
      })}
    >
      {row.getVisibleCells().map((cell) => (
        <TableBodyCell key={cell.id} cell={cell} />
      ))}
    </div>
  );
};

interface TableBodyRowSkeletonProps {
  columns: Array<Column<any, any>>;
}

const TableBodyRowSkeleton = (props: TableBodyRowSkeletonProps) => {
  const { columns } = props;

  return (
    <div className="flex w-full px-1">
      {columns.map((column) => (
        <TableBodyCell.Skeleton column={column} key={column.id} />
      ))}
    </div>
  );
};

TableBodyRow.Skeleton = TableBodyRowSkeleton;
