import {
  AdminRouteDocument,
  AdminRouteQuery,
  AdminRouteQueryVariables,
} from '@src/routes/_authenticated-layout/admin/~route.generated';
import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_authenticated-layout/admin')({
  beforeLoad: async ({ context }) => {
    const { data, error } = await context.apolloClient.query<
      AdminRouteQuery,
      AdminRouteQueryVariables
    >({
      query: AdminRouteDocument,
    });

    const { viewer } = data;

    if (error || !viewer) {
      throw redirect({ to: '/home' });
    }

    const adminViewer = { ...context.viewer, ...viewer };

    return {
      viewer: adminViewer,
    };
  },
});
