import { useTranslation } from 'react-i18next';

import {
  Button,
  Icon,
  RadioGroup,
  Typography,
} from '@happypal-tech/design-system';
import { BlueBoxImageHD, CubeImageHD } from '@src/assets/images';
import { BadgeFilter } from '@src/features-new/catalog/components/BadgeFilter';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@src/features-new/ui/DropdownMenu/DropdownMenu';

import { DropdownMenuSubventionsFragment } from './DropdownMenuSubventions.generated';

const getSubventionIcon = (subvention: DropdownMenuSubventionsFragment) => {
  const subventionHasIcon = subvention.subventionSchema?.icon?.file.url;

  if (subventionHasIcon) {
    return subventionHasIcon;
  }

  return subvention.isCustom ? CubeImageHD : BlueBoxImageHD;
};

interface DropdownMenuSubventionsProps {
  activeSubventions: string[];
  onSelect: (id: string) => void;
  subventions: DropdownMenuSubventionsFragment[];
}
export const DropdownMenuSubventions = (
  props: DropdownMenuSubventionsProps,
) => {
  const { subventions, activeSubventions, onSelect } = props;

  const { t } = useTranslation('catalog', {
    keyPrefix: 'components.CatalogSearchFilters',
  });

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="group" asChild>
        <Button variant="stroke" type="button" color="neutral">
          <div className="flex gap-2 items-center">
            {t('subventions')}
            <Icon
              name="ChevronUpOutline"
              size={16}
              className="transition-transform group-data-[state='closed']:-rotate-180"
            />
          </div>
          <BadgeFilter showDot={activeSubventions.length !== 0} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-80 max-h-[28rem] overflow-auto">
        {subventions.length ? (
          <RadioGroup
            onChange={(subventionId: string) => onSelect(subventionId)}
            itemClassName="font-semibold w-full p-3"
            items={subventions.map((subvention) => {
              return {
                value: subvention.id,
                label: subvention.name,
                icon: (
                  <img
                    src={getSubventionIcon(subvention)}
                    className="flex justify-center h-6 shrink-0 object-contain"
                    alt={subvention.name}
                  />
                ),
              };
            })}
            className="flex flex-col gap-1"
            value={activeSubventions[0] ?? undefined}
          />
        ) : (
          <Typography
            type="body"
            className="flex gap-2 text-neutral-dark p-3 items-center"
          >
            <Icon size={16} name="InfoCircleOutline" />
            {t('emptySubvention')}
          </Typography>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
