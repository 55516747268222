import { AppHeaderProfileSuspended } from '@src/features-new/core/AppHeader/AppHeaderProfile/Suspended';
import { AppHeaderSuspendedFragment } from '@src/features-new/core/AppHeader/Suspended/AppHeaderSuspended.generated';
import {
  getBackgroundColor,
  isBackgroundDark,
} from '@src/features-new/ui/background';
import { cn } from '@src/features-new/ui/cn';

type AppHeaderSuspendedProps = {
  className?: string;
  viewer: AppHeaderSuspendedFragment;
};

export const AppHeaderSuspended = ({
  className,
  viewer,
}: AppHeaderSuspendedProps) => {
  const backgroundColor = getBackgroundColor(false);
  const backgroundIsDark = isBackgroundDark(backgroundColor);

  return (
    <div
      className={cn(
        'flex justify-end items-center border-b border-blue-light px-6 gap-4',
        {
          'text-white': backgroundIsDark,
          'text-neutral-darkest': !backgroundIsDark,
        },
        className,
      )}
      style={{ backgroundColor }}
    >
      <div className="flex-1 hidden lg:block" />
      <AppHeaderProfileSuspended viewer={viewer} />
    </div>
  );
};
