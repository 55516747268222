import { createFileRoute, notFound } from '@tanstack/react-router';

import {
  GetRouteCompanyPublicDocument,
  GetRouteCompanyPublicQuery,
  GetRouteCompanyPublicQueryVariables,
} from './~route.generated';

export const Route = createFileRoute('/auth/sso/$slug')({
  loader: async ({ context, params }) => {
    const { data } = await context.apolloClient.query<
      GetRouteCompanyPublicQuery,
      GetRouteCompanyPublicQueryVariables
    >({
      query: GetRouteCompanyPublicDocument,
      variables: {
        slug: params.slug,
      },
    });

    const companyPublic = data.companyPublic;
    if (!companyPublic) throw notFound();
    return { companyPublic };
  },
});
