import {
  forwardRef,
  type ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Typography } from '@happypal-tech/design-system';
import * as RCheckbox from '@radix-ui/react-checkbox';
import * as RLabel from '@radix-ui/react-label';
import { Icon } from '@src/components/atoms/Icon';
import { cx } from 'class-variance-authority';

import { withFormControl } from '../Form/withFormControl.hoc';

export type CheckedState = boolean | 'indeterminate';

interface CheckboxProps {
  id?: string;
  className?: string;
  checked?: CheckedState;
  /** @default false */
  defaultChecked?: CheckedState;
  onCheckedChange?: (checked: CheckedState) => void;
  disabled?: boolean;
  label?: ReactNode;
  name?: string;
}

/**
 * @link https://www.radix-ui.com/docs/primitives/components/checkbox
 */
export const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>(
  (props, ref) => {
    const {
      className,
      disabled,
      checked,
      defaultChecked = false,
      id,
      onCheckedChange,
      label,
      name,
    } = props;

    const _id = useMemo(
      () => id ?? Math.random().toString(36).slice(2, 11),
      [id],
    );

    const [_checked, setChecked] = useState<CheckedState>(defaultChecked);

    const handleCheckedChange = (value: CheckedState) => {
      onCheckedChange?.(value);
      if (checked !== undefined) return;
      setChecked(value);
    };

    useEffect(() => {
      if (checked !== undefined) {
        setChecked(checked);
      }
    }, [checked]);

    return (
      <div
        className={cx(className, 'checkbox', {
          'checkbox--disabled': disabled,
          'checkbox--checked': _checked === true,
          'checkbox--indeterminate': _checked === 'indeterminate',
        })}
      >
        <RCheckbox.Root
          id={_id}
          checked={_checked}
          disabled={disabled}
          className="checkbox__root rounded-lg"
          onCheckedChange={handleCheckedChange}
          ref={ref}
          name={name}
        >
          <RCheckbox.Indicator className="checkbox__indicator">
            {_checked === true && <Icon size="xsmall" name="check" />}
            {_checked === 'indeterminate' && (
              <Icon size="xsmall" name="minus" />
            )}
          </RCheckbox.Indicator>
        </RCheckbox.Root>
        {!!label && (
          <RLabel.Root asChild htmlFor={_id} className="checkbox__label">
            <Typography asChild type="body">
              <label>{label}</label>
            </Typography>
          </RLabel.Root>
        )}
      </div>
    );
  },
);

export const FormCheckbox = withFormControl<CheckboxProps>(
  (props, { fieldState, field }) => {
    return (
      <Checkbox
        aria-invalid={fieldState.invalid}
        {...props}
        ref={field.ref}
        name={field.name}
        checked={field.value}
        onCheckedChange={field.onChange}
      />
    );
  },
);
