import { useTranslation } from 'react-i18next';

import PalQuestion3D from '@assets/images/PalQuestion3D.png';
import { Picture, Typography } from '@happypal-tech/design-system';

export const AppError = () => {
  const { t } = useTranslation('core', {
    keyPrefix: 'components.AppError',
  });

  return (
    <div className="fixed inset-0 flex items-center justify-center">
      <div className="max-w-[400px] text-center flex items-center flex-col">
        <Picture
          alt="Pal Question"
          className="w-30 h-[120px]"
          fit="cover"
          src={PalQuestion3D}
        />
        <Typography asChild type="heading-3" bold className="mt-4">
          <h1>{t('title')}</h1>
        </Typography>
        <Typography asChild type="body" className="mt-2">
          <p>{t('subtitle')}</p>
        </Typography>
        <Typography asChild type="body" className="mt-4">
          <p>{t('text')}</p>
        </Typography>
        <Typography asChild type="body">
          <a
            href="mailto:support@hpal.fr"
            className="text-primary underline"
            target="_blank"
            rel="noreferrer noopener"
          >
            {t('link')}
          </a>
        </Typography>
      </div>
    </div>
  );
};
