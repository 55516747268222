import { useEffect } from 'react';

import { CompanyMembershipRole } from '@src/graphql/generated/types';

import { CustomerIoViewerFragment } from './customer-io.generated';

type CustomerIOProps = {
  viewer: CustomerIoViewerFragment;
};

export const CustomerIO = (props: CustomerIOProps) => {
  const { viewer } = props;

  useEffect(() => {
    window._cio.identify({
      id: viewer.id,
      email: viewer.email,
      created_at: Math.trunc(viewer.createdAt.getTime() / 1000),
      updated_at: Math.trunc(viewer.updatedAt.getTime() / 1000),
      lang: viewer.preferences?.properties.language ?? 'fr',
      first_name: viewer.firstName,
      last_name: viewer.lastName,
      company_id: viewer.company?.id,
      company_name: viewer.company?.name,
      company_role: viewer.company?.viewerIsAdmin
        ? CompanyMembershipRole.Admin
        : CompanyMembershipRole.Member,
      unsubscribed:
        viewer.preferences?.properties.newsletters?.global === undefined
          ? false
          : !viewer.preferences?.properties.newsletters?.global,
    });
  }, [viewer]);

  return null;
};

export const useCustomerIO = () => {
  return {
    identify: window._cio.identify,
    reset: window._cio.reset,
  };
};

declare global {
  interface Window {
    _cio: {
      _version: string;
      _trackUrl: string;
      identify: <T extends { id: string }>(user: T) => void;
      reset: () => void;
      track: () => void;
      page: () => void;
      on: () => void;
      off: () => void;
    };
  }
}
