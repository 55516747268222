import { useTranslation } from 'react-i18next';

import { Spin, toast } from '@happypal-tech/design-system';
import { handleApiError } from '@src/features-new/utils/handleApiError';
import { createFileRoute, useNavigate } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/_authenticated-layout/gathering/$gatheringId/',
)({
  pendingComponent: () => <Spin className="m-auto" />,
  errorComponent: function ErrorComponent({ error }) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return handleApiError(error, {
      default: () => {
        toast.error(t('apiError'));
        navigate({ to: '/gathering/dashboard/', replace: true });
      },
      'entity/not-found': () => {
        return;
      },
    });
  },
});
