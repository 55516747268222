import { Trans, useTranslation } from 'react-i18next';

import { GQLOperations } from '@graphql/generated/types';
import { Button, toast } from '@happypal-tech/design-system';
import { useAnalytics } from '@hooks/use-analytics';
import {
  FavoritesOrigin,
  VisitMyFavoritesOrigin,
} from '@src/features-new/analytics/constants/track-event.constant';
import { useAlgoliaSearchInsights } from '@src/lib/algolia/search-insights';
import { Link } from '@tanstack/react-router';

import {
  CampaignFavoriteFragment,
  useCampaignFavoriteToggleCampaignMutation,
} from './CampaignFavorite.generated';

type CampaignFavoriteProps = {
  campaign: CampaignFavoriteFragment;
  className?: string;
  fluid?: boolean;
  queryID?: string;
};

export const CampaignFavorite = (props: CampaignFavoriteProps) => {
  const { campaign, className, fluid, queryID } = props;
  const { t } = useTranslation('core', {
    keyPrefix: 'components.FavoriteBadge',
  });
  const { track } = useAnalytics();
  const { convertedObjectIds } = useAlgoliaSearchInsights();
  const [mutation, { loading }] = useCampaignFavoriteToggleCampaignMutation();
  const toggleFavorite = async () => {
    const shouldConvert = !campaign.isFavorite;

    if (loading) return;
    try {
      const resp = await mutation({
        variables: {
          campaignId: campaign.id,
        },
        refetchQueries: [
          GQLOperations.Query.CampaignDetails,
          GQLOperations.Query.FavoritePage,
        ],
      });
      const isNewFavorite = Boolean(resp.data?.favoriteCampaignToggle);
      if (isNewFavorite) {
        track({
          type: 'add to favorites',
          payload: {
            campaign_name: campaign.name,
            favorites_origin: FavoritesOrigin.campaignPage,
          },
        });
      } else {
        track({
          type: 'remove from favorites',
          payload: {
            campaign_name: campaign.name,
            remove_favorites_origin: FavoritesOrigin.campaignPage,
          },
        });
      }
      toast.success(
        <Trans
          i18nKey={
            isNewFavorite
              ? 'core:components.FavoriteBadge.added'
              : 'core:components.FavoriteBadge.removed'
          }
          values={{
            name: campaign.name,
          }}
          components={{
            bold: <span className="font-bold" />,
            clickable: (
              <Link
                to="/favorites"
                className="underline"
                onClick={() => {
                  toast.dismiss();
                  track({
                    type: 'visit my favorites page',
                    payload: {
                      visit_my_favorites_origin: isNewFavorite
                        ? VisitMyFavoritesOrigin.addToaster
                        : VisitMyFavoritesOrigin.removeToaster,
                    },
                  });
                }}
              />
            ),
          }}
        />,
      );

      if (shouldConvert) {
        convertedObjectIds({
          eventName: queryID
            ? 'Campaign Added To Favorites After Search'
            : 'Campaign Added To Favorites',
          objectIDs: [campaign.id],
          queryID,
        });
      }
    } catch (e) {
      toast.error(t('error'));
    }
  };

  return (
    <Button
      variant={campaign.isFavorite ? 'plain' : 'stroke'}
      color={campaign.isFavorite ? 'primary' : 'neutral'}
      onClick={toggleFavorite}
      iconLeft={campaign.isFavorite ? 'HeartSolid' : 'HeartOutline'}
      fluid={!!fluid}
      className={className}
      loading={loading}
    >
      {campaign.isFavorite ? t('addedToFavorites') : t('addToFavorites')}
    </Button>
  );
};
