import { cx } from 'class-variance-authority';

import { Button } from '../Button';

type QuantityFunctionType = (quantity: number) => void;

type QuantityModifierProps = {
  quantity: number;
  min?: number;
  max?: number;
  disabled?: boolean;
  error?: boolean;
  onQuantityChange?: QuantityFunctionType;
  onMinusClicked?: QuantityFunctionType;
  onPlusClicked?: QuantityFunctionType;
};

export const QuantityModifier = ({
  quantity,
  onQuantityChange,
  onPlusClicked,
  onMinusClicked,
  min = 1,
  max,
  disabled,
  error,
}: QuantityModifierProps) => {
  const canMinus = !disabled && quantity > min;
  const canPlus = !disabled && quantity < (max ?? Infinity);

  return (
    <div className="flex items-center">
      <Button
        type="button"
        disabled={!canMinus || disabled}
        onClick={handleMinusClicked}
        iconOnly="minus"
        color="neutral"
      />
      <span
        className={cx(
          'w-9 select-none text-center font-display text-base font-semibold md:w-11',
          { 'text-red': error },
        )}
      >
        {quantity.toString()}
      </span>
      <Button
        type="button"
        disabled={!canPlus || disabled}
        onClick={handlePlusClicked}
        iconOnly="plus"
        color="neutral"
      />
    </div>
  );

  function handleMinusClicked() {
    onQuantityChange?.(quantity - 1);
    onMinusClicked?.(quantity - 1);
  }

  function handlePlusClicked() {
    onQuantityChange?.(quantity + 1);
    onPlusClicked?.(quantity + 1);
  }
};
