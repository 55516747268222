import { ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { NewsletterPal } from '@assets/images';
import {
  Form,
  FormCheckbox,
  Typography,
  ValuesFromZ,
  z,
} from '@happypal-tech/design-system';

export const useUserPreferenceShowstopperFormValidationSchema = (
  shouldShowCGU?: boolean,
) => {
  const { t } = useTranslation('user', {
    keyPrefix: 'forms.userPreferenceShowstopper',
  });

  return z.object({
    terms: z
      .boolean()
      .optional()
      .refine((value) => (shouldShowCGU ? value === true : true), {
        message: t('fields.tos.errors.isTrue'),
      }),
    companyNewsletter: z.boolean().optional(),
    globalNewsletter: z.boolean().optional(),
    partnerNewsletter: z.boolean().optional(),
  });
};

export type UserPreferenceShowstopperFormValidationSchemaType = ValuesFromZ<
  ReturnType<typeof useUserPreferenceShowstopperFormValidationSchema>
>;

type UserPreferenceShowstopperFormProps = {
  shouldShowCGU: boolean;
  shouldShowCompanyNewsletter: boolean;
  shouldShowGlobalNewsletter: boolean;
  shouldShowPartnerNewsletter: boolean;
  company: string;
  hideCguLabel?: boolean;
  children?: ReactNode;
};

export const UserPreferenceShowstopperForm = (
  props: UserPreferenceShowstopperFormProps,
) => {
  const {
    shouldShowCGU,
    shouldShowCompanyNewsletter,
    shouldShowGlobalNewsletter,
    shouldShowPartnerNewsletter,
    company,
    hideCguLabel,
    children,
  } = props;
  const { t } = useTranslation('user', {
    keyPrefix: 'forms.userPreferenceShowstopper',
  });

  return (
    <>
      <div>
        {shouldShowCGU && (
          <div className="mt-6">
            {!hideCguLabel && (
              <Typography type="body">{t('fields.cgu.label')}</Typography>
            )}
            <Form.Field name="terms">
              <FormCheckbox
                label={
                  <Typography type="body">
                    <Trans
                      t={t}
                      i18nKey="fields.tos.label"
                      components={{
                        a1: (
                          // eslint-disable-next-line jsx-a11y/anchor-has-content
                          <a
                            className="text-primary underline"
                            href="https://www.happypal.fr/cgu"
                            target="_blank"
                            rel="noopener noreferrer"
                          />
                        ),
                        a2: (
                          // eslint-disable-next-line jsx-a11y/anchor-has-content
                          <a
                            className="text-primary underline"
                            href="https://www.happypal.fr/politique-de-confidentialite"
                            target="_blank"
                            rel="noopener noreferrer"
                          />
                        ),
                      }}
                    />
                  </Typography>
                }
                className="mt-4"
              />
            </Form.Field>
          </div>
        )}
        <hr className="mt-6 border-neutral-lighter first:hidden last:hidden" />
        {(shouldShowCompanyNewsletter ||
          shouldShowGlobalNewsletter ||
          shouldShowPartnerNewsletter) && (
          <div className="mt-6">
            <Typography type="heading-4" semibold>
              {t('communication.title')}
            </Typography>
            <img
              src={NewsletterPal}
              alt="Happy pal with smile"
              className="w-full mt-2"
            />
            <Typography className="mt-4" type="body">
              <Trans
                t={t}
                i18nKey={'communication.text'}
                components={{ bold: <span className="font-bold" /> }}
              />
            </Typography>
            <div className="flex flex-col gap-2 mt-4">
              <Typography type="body">{t('communication.wish')}</Typography>
              {shouldShowCompanyNewsletter && (
                <Form.Field name="companyNewsletter">
                  <FormCheckbox
                    label={
                      <Trans
                        t={t}
                        i18nKey="fields.newsletter.company"
                        values={{ company }}
                        components={{ bold: <span className="font-bold" /> }}
                      />
                    }
                  />
                </Form.Field>
              )}
              {shouldShowGlobalNewsletter && (
                <Form.Field name="globalNewsletter">
                  <FormCheckbox
                    label={
                      <Trans
                        t={t}
                        i18nKey="fields.newsletter.global"
                        components={{ bold: <span className="font-bold" /> }}
                      />
                    }
                  />
                </Form.Field>
              )}
              {shouldShowPartnerNewsletter && (
                <Form.Field name="partnerNewsletter">
                  <FormCheckbox
                    label={
                      <Trans
                        t={t}
                        i18nKey="fields.newsletter.partner"
                        components={{ bold: <span className="font-bold" /> }}
                      />
                    }
                  />
                </Form.Field>
              )}
            </div>
          </div>
        )}
      </div>
      {children}
      <div>
        <Typography type="note" className="text-neutral-dark">
          {t('legal.info')}
        </Typography>
        <Typography type="note" className="text-neutral-dark mt-2">
          <Trans
            t={t}
            i18nKey="legal.knowMore"
            components={{
              a1: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  className="underline"
                  href="https://www.happypal.fr/politique-de-confidentialite"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />
        </Typography>
      </div>
    </>
  );
};
