import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import { Chip, IconName } from '@happypal-tech/design-system';
import { OfferType } from '@src/graphql/generated/types';
import { TFunction } from 'i18next';

type InternalChipOfferTypeProps = {
  label: string;
  iconLeft: IconName;
};

const getOfferTypeChipProps = (
  t: TFunction<'core', 'offerTypes'>,
  offerType?: OfferType,
): InternalChipOfferTypeProps => {
  switch (offerType) {
    case OfferType.Url:
      return { label: t('url'), iconLeft: 'ExternalLinkOutline' };
    case OfferType.CouponClaimable:
    case OfferType.CouponPublic:
      return {
        label: t('promo_code'),
        iconLeft: 'LabelOutline',
      };
    case OfferType.GiftCard:
      return { label: t('gift_card'), iconLeft: 'GiftOutline' };
    case OfferType.Ticket:
      return { label: t('ticket'), iconLeft: 'TicketOutline' };
    default:
      return { label: t('discounted'), iconLeft: 'DiscountOutline' };
  }
};

interface ChipOfferTypeProps
  extends Omit<ComponentProps<typeof Chip>, keyof InternalChipOfferTypeProps> {
  offerType: OfferType;
}
export const ChipOfferType = ({ offerType, ...rest }: ChipOfferTypeProps) => {
  const { t } = useTranslation('core', {
    keyPrefix: 'offerTypes',
  });

  const offerTypeChipProps = getOfferTypeChipProps(t, offerType);

  return <Chip {...rest} {...offerTypeChipProps} />;
};
