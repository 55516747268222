import { useIntercom } from 'react-use-intercom';

import { useApolloClient } from '@apollo/client';
import { useLogoutMutation } from '@src/features/auth/hooks/useLogout/useLogout.generated';
import { useAnalytics } from '@src/hooks/use-analytics';
import { useCustomerIO } from '@src/lib/customer-io/customer-io';

const useLogout = () => {
  const cio = useCustomerIO();
  const { cache } = useApolloClient();
  const { track } = useAnalytics();
  const intercom = useIntercom();

  const [logoutUser] = useLogoutMutation();

  const logout = async () => {
    track({
      type: 'log out',
    });
    await logoutUser();
    await cache.reset();
    intercom.shutdown();
    // cio.reset can be undefined with an adblocker
    cio.reset?.();
    location.href = '/auth/login';
  };

  return logout;
};

export default useLogout;
