// This file contains the list of the errors we can receive from the API
import axios, { AxiosError } from 'axios';

export type FieldValidationError = {
  [fieldKey: string]: {
    [error: string]: string;
  };
};

export type ApiRestError = {
  name: ApiErrorType;
  /** Informative message DO NOT USE */
  message: string;
  statusCode: number;
  code?: string;
  extensions?: {
    code?: ApiErrorCode;
  };
};

export function isApiRestError(err: unknown): err is AxiosError<ApiRestError> {
  if (axios.isAxiosError(err)) {
    return true;
  } else {
    return false;
  }
}

export function isFieldValidationError(
  error: unknown,
): error is FieldValidationError {
  if (typeof error !== 'object' || !error) {
    return false;
  }
  const hasField = !!Object.keys(error).length;
  const hasValue = !!Object.values(error).length;
  if (!hasField || !hasValue) return false;

  return true;
}

export function isKnownApiErrorCode(error: unknown): error is ApiErrorCode {
  return ALL_API_ERROR_CODE.includes(error as ApiErrorCode);
}

export type ApiErrorType =
  | 'BadRequestError'
  | 'InternalServerError'
  | 'ForbiddenError'
  | 'NotFoundError'
  | 'NotImplementedError'
  | 'ServiceUnavailableError'
  | 'UnauthorizedError';

export const ALL_API_ERROR_CODE = [
  'address/cannot-locate',
  'address/cannot-retrieve-lat-lon',
  'address/not-found',
  'auth/email-already-exists',
  'auth/error',
  'auth/invalid-password',
  'auth/login-required',
  'auth/refresh-token-abuse',
  'auth/refresh-token-consumed',
  'auth/refresh-token-logged-out',
  'auth/refresh-token-not-found',
  'auth/refresh-token-revoked',
  'auth/refresh-token-suspicious',
  'auth/reset-token-consumed',
  'auth/reset-token-expired',
  'auth/reset-token-not-found',
  'auth/token-expired',
  'auth/token-invalid',
  'auth/token-missing',
  'auth/tos-not-accepted',
  'auth/user-disabled',
  'auth/user-not-found',
  'auth/user-not-migrated',
  'auth/user-without-password',
  'beneficiary/cannot-validate-self-beneficiary',
  'brand-translation/not-found',
  'brand/last-translation',
  'brand/not-found',
  'cart/cart-product-not-found',
  'cart/no-active-cart',
  'cart/subvention-expired',
  'cart-item/exceed-inventory-quantity',
  'category-translation/not-found',
  'category/last-translation',
  'category/not-found',
  'company/membership-required',
  'company/not-found',
  'company/role-admin-required',
  'company/wallet-not-provisionned',
  'coupon-claimable/claim-exceeded',
  'coupon-claimable/claimed-interval-too-short',
  'coupon-claimable/no-claim-left',
  'coupon-claimable/out-of-stock',
  'default',
  'file/payload-too-large',
  'forbidden/super-admin-required',
  'locale/not-found',
  'newsletter/email-missing-subuser',
  'newsletter/company-cannot-send-emails',
  'not-implemented',
  'offer/not-found',
  'order/not-found',
  'order/not-owner',
  'order/product-variant-exceed-stock',
  'picture/not-found',
  'product/not-found',
  'twoFactor/invalid-code',
  'user/email-cannot-be-updated',
  'user/not-found',
  'user/viewer-is-not-user',
  'validation-error',
  'viewer/latitude-required',
  'appdrag-export-transactions/export-failed',
  'appdrag-export-transactions/export-forbidden',
] as const;

export type ApiErrorCode = (typeof ALL_API_ERROR_CODE)[number];
