import { useViewerActiveOrderQuery } from './useCart.generated';

export function useCart() {
  const { data } = useViewerActiveOrderQuery({ errorPolicy: 'ignore' });

  const getOrCreateCart = async () => {
    return data?.viewer?.activeCart;
  };

  return {
    cart: data?.viewer?.activeCart,
    createCart: getOrCreateCart,
    getOrCreateCart: getOrCreateCart,
  };
}
