import { forwardRef, useState } from 'react';

import { Typography } from '@happypal-tech/design-system';
import * as RToggleGroup from '@radix-ui/react-toggle-group';
import { cx } from 'class-variance-authority';

import { withFormControl } from '../Form/withFormControl.hoc';

interface ToggleGroupOption {
  label: string;
  value: string;
  disabled?: boolean;
}

interface ToggleGroupClearableProps {
  clearable: true;
  onChange?: (value: string | undefined) => void;
}

interface ToggleGroupNonClearableProps {
  clearable?: false;
  onChange?: (value: string) => void;
}

type ToggleGroupProps = {
  className?: string;
  /** @default small */
  size?: 'small' | 'medium';
  options: ToggleGroupOption[];
  value?: string;
  disabled?: boolean;
} & (ToggleGroupClearableProps | ToggleGroupNonClearableProps);

/**
 * @link https://www.radix-ui.com/docs/primitives/components/toggle-group
 */
export const ToggleGroup = forwardRef<HTMLDivElement, ToggleGroupProps>(
  (props, ref) => {
    const {
      className,
      size = 'small',
      options,
      value,
      clearable,
      onChange,
      disabled,
    } = props;

    const [_value, setValue] = useState(value);

    const handleValueChange = (value: string | undefined) => {
      if (clearable) {
        setValue(value);
        onChange?.(value);
      } else if (value) {
        setValue(value);
        onChange?.(value);
      }
    };

    return (
      <RToggleGroup.Root
        className={cx(className, 'toggle-group', {
          'toggle-group--small': size === 'small',
          'toggle-group--medium': size === 'medium',
        })}
        type="single"
        value={_value}
        onValueChange={handleValueChange}
        disabled={disabled}
        ref={ref}
      >
        {options.map((option) => (
          <RToggleGroup.Item
            className={cx('toggle-group__option', {
              'toggle-group__option--small': size === 'small',
              'toggle-group__option--medium': size === 'medium',
            })}
            value={option.value}
            disabled={option.disabled}
            key={option.value}
          >
            <Typography type="body">{option.label}</Typography>
          </RToggleGroup.Item>
        ))}
      </RToggleGroup.Root>
    );
  },
);

export const FormToggleGroup = withFormControl<ToggleGroupProps>(
  (props, { fieldState, field }) => {
    return (
      <ToggleGroup aria-invalid={fieldState.invalid} {...props} {...field} />
    );
  },
);
