import { Spin } from '@happypal-tech/design-system';
import {
  SuspendedAccountInfoRouteDocument,
  SuspendedAccountInfoRouteQuery,
  SuspendedAccountInfoRouteQueryVariables,
} from '@src/routes/_suspended-layout/suspended/account/index/~route.generated';
import { createFileRoute, notFound } from '@tanstack/react-router';

export const Route = createFileRoute('/_suspended-layout/suspended/account/')({
  loader: async ({ context }) => {
    const { data } = await context.apolloClient.query<
      SuspendedAccountInfoRouteQuery,
      SuspendedAccountInfoRouteQueryVariables
    >({ query: SuspendedAccountInfoRouteDocument });

    const { viewer } = data;

    if (!viewer) {
      throw notFound();
    }

    return { viewer };
  },
  pendingComponent: () => <Spin />,
});
