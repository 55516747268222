import { useMemo } from 'react';

import { Typography, TypographyType } from '@happypal-tech/design-system';
import { cx } from 'class-variance-authority';

interface SkeletonProps {
  className?: string;
  style?: Pick<
    React.CSSProperties,
    'width' | 'maxWidth' | 'minWidth' | 'height' | 'minHeight' | 'maxHeight'
  >;
}

export const Skeleton = (props: SkeletonProps) => {
  const { className, style } = props;

  return <span className={cx(className, 'skeleton')} style={style} />;
};

type SkeletonTypographyProps = Omit<SkeletonProps, 'height' | 'width'> & {
  rows?: number | number[];
  type: TypographyType;
};

const SkeletonTypography = (props: SkeletonTypographyProps) => {
  const { className, type, rows = 1 } = props;

  const widths = useMemo(() => {
    if (Array.isArray(rows)) return rows;

    const widths = [];

    for (let i = 0; i < rows; i++) {
      if (i === rows - 1) widths.push(randomIntFromInterval(35, 90));
      else widths.push(randomIntFromInterval(95, 100));
    }

    return widths;
  }, [rows]);

  return (
    <Typography type={type}>
      {widths.map((width, index) => (
        <Skeleton
          key={index}
          className={cx(className, 'skeleton--typography')}
          style={{ width: `${width}%` }}
        />
      ))}
    </Typography>
  );
};

interface SkeletonAvatarProps {
  className?: string;
}

const SkeletonAvatar = (props: SkeletonAvatarProps) => {
  const { className } = props;

  const classNameIncludesHeight = className?.includes('h-');
  const classNameIncludesWidth = className?.includes('w-');
  const classNameIncludesRounded = className?.includes('rounded-');

  return (
    <Skeleton
      className={cx(className, 'skeleton--avatar', {
        'h-12': !classNameIncludesHeight,
        'w-12': !classNameIncludesWidth,
        'rounded-2xl': !classNameIncludesRounded,
      })}
    />
  );
};

Skeleton.Typography = SkeletonTypography;
Skeleton.Avatar = SkeletonAvatar;

function randomIntFromInterval(min: number, max: number) {
  return Math.trunc(Math.random() * (max - min + 1) + min);
}
