import { DetailsCover } from '@src/components/molecules/DetailsCover/DetailsCover';

import { CampaignCoverFragment } from './CampaignCover.generated';

type CampaignCoverProps = {
  campaign: CampaignCoverFragment | null;
  loading: boolean;
};

export const CampaignCover = (props: CampaignCoverProps) => {
  const { campaign, loading } = props;

  let headline =
    campaign?.parentCategoriesPagination.edges
      .slice(0, 3)
      .map((edge) => edge.node.name)
      .join(', ') || '';

  const categoriesCount = campaign?.parentCategoriesPagination.totalCount || 0;

  if (categoriesCount > 3) {
    headline += ` (+${categoriesCount - 3})`;
  }

  return (
    <DetailsCover
      title={campaign?.brand.name || ''}
      subtitle={headline}
      loading={loading}
      cover={campaign?.cover}
    />
  );
};
