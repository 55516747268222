import { forwardRef } from 'react';

import { Chip } from '@happypal-tech/design-system';
import { cn } from '@src/features-new/ui/cn';

interface Refinement {
  label: string;
  value: string;
}

interface ChipsCurrentRefinementsProps {
  className?: string;
  refinements: Refinement[];
  onRemove: (refinement: Refinement) => void;
}

export const ChipsCurrentRefinements = forwardRef<
  HTMLDivElement,
  ChipsCurrentRefinementsProps
>((props, forwardedRef) => {
  const { className, refinements, onRemove } = props;

  return (
    <div
      ref={forwardedRef}
      className={cn(
        'flex gap-2 flex-wrap items-start justify-start',
        className,
      )}
    >
      {refinements.map((refinement) => (
        <Chip
          color="primary"
          className="px-4"
          variant="plain"
          size="large"
          key={refinement.value}
          label={refinement.label}
          onClick={() => onRemove(refinement)}
          iconRight="CrossOutline"
        />
      ))}
    </div>
  );
});
