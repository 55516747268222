import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import { BadgeStandalone, Typography } from '@happypal-tech/design-system';
import { FilterGeo } from '@src/features-new/catalog/components/FilterGeo/FilterGeo';
import { Accordion } from '@src/features-new/design-system/components/atoms/Accordion/Accordion';
import { cn } from '@src/features-new/ui/cn';

interface AccordionGeoProps extends ComponentProps<typeof FilterGeo> {}
export const AccordionGeo = ({ value, ...rest }: AccordionGeoProps) => {
  const { t } = useTranslation('catalog', {
    keyPrefix: 'components.CatalogFiltersDrawer',
  });

  return (
    <Accordion.Item value="location">
      <Accordion.Trigger
        className={cn(
          'group p-4',
          'data-[state=open]:text-neutral-darkest data-[state=closed]:text-neutral-dark',
        )}
        subClassName="w-full"
      >
        <Typography
          type="subtitle"
          semibold
          className={cn(
            'flex gap-2 items-center',
            'group-data-[state=open]:text-neutral-darkest group-data-[state=closed]:text-neutral-dark',
          )}
        >
          {t('geo')}
          <BadgeStandalone
            color="primary"
            showDot={value !== null}
            title={t('geo')}
          />
        </Typography>
      </Accordion.Trigger>
      <Accordion.Content className="mx-4">
        <FilterGeo value={value} {...rest} />
      </Accordion.Content>
    </Accordion.Item>
  );
};
