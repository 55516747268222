import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { zeroRightClassName } from 'react-remove-scroll-bar';

import { toast } from '@happypal-tech/design-system';
import { CartFloatingActionButton } from '@src/components/organisms/CartButton/CartButton';
import { MobileTabBarSuspended } from '@src/components/organisms/MobileTabBar/Suspended';
import { AppHeaderSuspended } from '@src/features-new/core/AppHeader/Suspended';
import { AppMenuSuspended } from '@src/features-new/core/AppMenu/Suspended';
import {
  SuspendedRouteDocument,
  SuspendedRouteQuery,
  SuspendedRouteQueryVariables,
} from '@src/routes/_suspended-layout/~route.generated';
import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';

const SuspendedLayout = () => {
  const { t } = useTranslation();

  const { viewer } = Route.useLoaderData();

  useEffect(() => {
    toast.error(t('navigation.toast.suspendedAccount'), {
      duration: Infinity,
      id: 'suspended',
    });
  }, [t]);

  return (
    <>
      <div>
        <AppHeaderSuspended
          className={`hidden md:flex fixed top-0 left-64 right-0 z-50 h-16 ${zeroRightClassName}`}
          viewer={viewer}
        />
        <AppMenuSuspended className="hidden md:block fixed left-0 top-0 bottom-0 w-64 z-50 bg-white" />
        <main className="md:pl-64 md:pt-16 pb-14 md:pb-0 relative">
          <div className="min-h-[calc(100vh-56px)] md:min-h-[calc(100vh-64px)] flex flex-col">
            <Outlet />
          </div>
        </main>
        <CartFloatingActionButton className="block md:hidden" />
        <MobileTabBarSuspended className="md:hidden fixed left-0 right-0 bottom-0 z-50 h-14" />
      </div>
    </>
  );
};

export const Route = createFileRoute('/_suspended-layout')({
  beforeLoad: async ({ context }) => {
    if (!context.viewer) throw redirect({ to: '/auth/login' });
  },
  loader: async ({ context }) => {
    const { data } = await context.apolloClient.query<
      SuspendedRouteQuery,
      SuspendedRouteQueryVariables
    >({
      query: SuspendedRouteDocument,
    });

    const { viewer } = data;

    if (!viewer) {
      throw redirect({ to: '/auth/login' });
    }

    return { viewer };
  },
  component: SuspendedLayout,
});
