import { useTranslation } from 'react-i18next';

import { Button } from '@happypal-tech/design-system';
import { useCountSearchRefinements } from '@src/features-new/catalog/hooks/useCountSearchRefinements';
import { Link } from '@tanstack/react-router';

export const ButtonClearRefinements = () => {
  const { t } = useTranslation('catalog', {
    keyPrefix: 'components.ButtonClearRefinements',
  });

  const count = useCountSearchRefinements();

  if (count === 0) {
    return null;
  }

  return (
    <Link
      from="/catalog"
      to="/catalog"
      search={(prev) => (prev.q ? { q: prev.q } : {})}
    >
      <Button
        className="hidden md:inline-flex"
        aria-label={t('label', { count })}
        type="button"
        variant="plain"
        color="primary"
        icon="CrossOutline"
      />
    </Link>
  );
};
