import { useTranslation } from 'react-i18next';

import { Typography } from '@happypal-tech/design-system';
import { cx } from 'class-variance-authority';

type PageFooterProps = {
  classNames?: string;
};

export const PageFooter = (props: PageFooterProps) => {
  const { t } = useTranslation('auth', {
    keyPrefix: 'components.PageFooter',
  });
  const { classNames } = props;
  return (
    <div
      className={cx(
        ' gap-2 flex justify-center items-start text-neutral-dark underline md:gap-6 flex-wrap',
        classNames,
      )}
    >
      <Typography asChild type="body">
        <a href="https://www.happypal.fr/cse" target="_blank" rel="noreferrer">
          {t('ces')}
        </a>
      </Typography>
      <div className="flex gap-4">
        <Typography asChild type="body">
          <a
            href="https://www.happypal.fr/cgu"
            target="_blank"
            rel="noreferrer"
          >
            {t('cgu')}
          </a>
        </Typography>
        <Typography asChild type="body">
          <a
            href="https://www.happypal.fr/mentions-legales"
            target="_blank"
            rel="noreferrer"
          >
            {t('legalTerms')}
          </a>
        </Typography>
      </div>
    </div>
  );
};
