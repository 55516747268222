import { Trans, useTranslation } from 'react-i18next';

import { Typography } from '@happypal-tech/design-system';
import { replaceUnwantedChars } from '@src/features-new/catalog/utils/replaceUnwantedChars';
import { cx } from 'class-variance-authority';

type CatalogTitleProps = {
  totalCount: number | undefined;
  search?: string;
  className?: string;
};

export const CatalogTitle = (props: CatalogTitleProps) => {
  const { totalCount, search, className } = props;
  const { t } = useTranslation('catalog', {
    keyPrefix: 'components.CatalogTitle',
  });

  return (
    <div className={cx('flex flex-col gap-1', className)}>
      {search ? (
        <Typography type="heading-2" bold>
          <Trans
            i18nKey="catalog:components.CatalogTitle.searched"
            values={{
              count: totalCount,
              search: replaceUnwantedChars(search),
            }}
            components={{
              1: <p className="font-bold text-primary inline" />,
            }}
          />
        </Typography>
      ) : (
        <Typography type="heading-2" bold>
          {t('all', { count: totalCount })}
        </Typography>
      )}
    </div>
  );
};
