import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

import { setDateOptions } from '@services/dateManager.service';
import { Locale } from 'date-fns';
import dateFnsFrLocale from 'date-fns/locale/fr';
import type { i18n } from 'i18next';

import { LOCALES } from './i18n';
import { __formatDistance } from './locale-format-distance';

type LocalizationContext = {
  dateFnsLocale: Locale;
};

export const LocalizationContext = createContext<LocalizationContext | null>(
  null,
);

type LocalizationProviderProps = {
  children: ReactNode;
  i18n: i18n;
};

export const LocalizationProvider = (props: LocalizationProviderProps) => {
  const { children, i18n } = props;
  const [dateFnsLocale, setDateFnsLocale] = useState(dateFnsFrLocale);

  useEffect(() => {
    const handleLanguageChanged = async (lng: LOCALES) => {
      const locale = await dateFnsLocaleImports[lng]();
      await i18n.loadNamespaces(['common', 'errors']);

      locale.formatDistance = __formatDistance;

      setDateOptions(lng);
      setDateFnsLocale(locale);
    };
    i18n.on('languageChanged', handleLanguageChanged);
  }, [i18n]);

  return (
    <LocalizationContext.Provider value={{ dateFnsLocale }}>
      {children}
    </LocalizationContext.Provider>
  );
};

export const useLocalization = () => {
  const context = useContext(LocalizationContext);

  if (context === null) {
    throw new Error(
      'useLocalization must be used within a LocalizationProvider',
    );
  }

  return context;
};

const dateFnsLocaleImports: Record<LOCALES, () => Promise<Locale>> = {
  [LOCALES.FR]: () => import('date-fns/locale/fr').then((m) => m.default),
  [LOCALES.EN]: () => import('date-fns/locale/en-GB').then((m) => m.default),
};
