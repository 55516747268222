import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CampaignNpsFragment,
  useCreateScoreMutation,
  useDeleteScoreMutation,
  useUpdateScoreMutation,
} from '@components/organisms/CampaignDetails/CampaignNps/CampaignNps.generated';
import { GQLOperations } from '@graphql/generated/types';
import {
  Button,
  Form,
  FormInputTextArea,
  Typography,
  useForm,
  ValuesFromZ,
  z,
} from '@happypal-tech/design-system';
import { useAnalytics } from '@hooks/use-analytics';
import { NpsEmoji } from '@src/features-new/analytics/constants/track-event.constant';
import { cx } from 'class-variance-authority';

enum CampaignScore {
  good = 2,
  neutral = 1,
  bad = 0,
}

const getEmojiValue = (val: CampaignScore) => {
  if (val === CampaignScore.good) return NpsEmoji.like;
  if (val === CampaignScore.neutral) return NpsEmoji.neutral;
  return NpsEmoji.dislike;
};

const COMMENT_MAX_CHAR = 500;

const useNpsCommentValidationSchema = () => {
  const { t } = useTranslation('core', {
    keyPrefix: 'components.CampaignNps.validation',
  });

  return z.object({
    comment: z
      .string({ required_error: t('minChar') })
      .trim()
      .min(1, t('minChar'))
      .max(COMMENT_MAX_CHAR, t('maxChar', { count: COMMENT_MAX_CHAR })),
  });
};

type NpsCommentValidationSchemaType = ValuesFromZ<
  ReturnType<typeof useNpsCommentValidationSchema>
>;

type CampaignNpsProps = {
  className?: string;
  campaign: CampaignNpsFragment;
};

export const CampaignNps = (props: CampaignNpsProps) => {
  const { campaign, className } = props;
  const { netPromoterScore } = campaign;
  const { t } = useTranslation('core', {
    keyPrefix: 'components.CampaignNps',
  });
  const { track } = useAnalytics();
  const [isFormOpen, setFormOpen] = useState(false);

  const [createMutation, { loading: createLoading }] = useCreateScoreMutation();
  const [updateMutation, { loading: updateLoading }] = useUpdateScoreMutation();
  const [deleteMutation, { loading: deleteLoading }] = useDeleteScoreMutation();

  const form = useForm({
    validationSchema: useNpsCommentValidationSchema(),
    defaultValues: {
      comment: netPromoterScore?.comment || '',
    },
  });

  const deleteScore = async () => {
    if (!netPromoterScore) return; // type guard
    await deleteMutation({
      variables: {
        id: netPromoterScore.id,
      },
      refetchQueries: [GQLOperations.Query.CampaignDetails],
      awaitRefetchQueries: true,
    });
    track({
      type: 'delete vote',
      payload: {
        campaign_name: campaign.name,
      },
    });
  };

  const createScore = async (val: CampaignScore) => {
    await createMutation({
      variables: {
        campaignId: campaign.id,
        input: {
          score: val,
        },
      },
      refetchQueries: [GQLOperations.Query.CampaignDetails],
      awaitRefetchQueries: true,
    });

    track({
      type: 'submit vote',
      payload: {
        campaign_name: campaign.name,
        emoji_type: getEmojiValue(val),
      },
    });
  };

  const updateScore = async (val: CampaignScore) => {
    if (!netPromoterScore) return; // type guard
    await updateMutation({
      variables: {
        id: netPromoterScore.id,
        input: {
          score: val,
        },
      },
    });

    track({
      type: 'submit vote',
      payload: {
        campaign_name: campaign.name,
        emoji_type: getEmojiValue(val),
      },
    });

    if (netPromoterScore.comment) {
      setFormOpen(true);
    }
  };

  const handleCommentSubmit = (val: NpsCommentValidationSchemaType) => {
    void updateComment(val.comment);
  };

  const updateComment = async (val: string | null) => {
    if (!netPromoterScore) return; // type guard
    await updateMutation({
      variables: {
        id: netPromoterScore.id,
        input: {
          comment: val,
        },
      },
    });
    setFormOpen(false);
    if (!netPromoterScore.comment && val !== null) {
      track({
        type: 'submit comment',
        payload: {
          campaign_name: campaign.name,
        },
      });
    }
    if (val === null) {
      track({
        type: 'delete comment',
        payload: {
          campaign_name: campaign.name,
        },
      });
    }
  };

  const handleIconClick = (val: CampaignScore) => {
    if (!netPromoterScore) {
      void createScore(val);
      return;
    }
    if (netPromoterScore?.score === val) {
      void deleteScore();
      return;
    }
    void updateScore(val);
  };

  const cancelChange = () => {
    form.setValue('comment', netPromoterScore?.comment || '');
    setFormOpen(false);
  };

  const deleteComment = async () => {
    await updateComment(null);
    form.setValue('comment', '');
    setFormOpen(true);
  };

  const loading = createLoading || updateLoading || deleteLoading;

  return (
    <div className={cx(className, 'flex items-center flex-col')}>
      <Typography type="heading-4" semibold asChild>
        <h4>{t('title')}</h4>
      </Typography>
      <div
        className={
          'bg-neutral-lightest rounded-2xl px-4 md:px-10 w-full max-w-[480px] mt-4 md:mb-10'
        }
      >
        <div className="flex gap-4 py-4">
          <Button
            color={
              netPromoterScore?.score === CampaignScore.good
                ? 'primary'
                : 'inverse'
            }
            onClick={() => handleIconClick(CampaignScore.good)}
            fluid
            disabled={loading}
          >
            <Typography type="heading-3">😀</Typography>
          </Button>
          <Button
            color={
              netPromoterScore?.score === CampaignScore.neutral
                ? 'primary'
                : 'inverse'
            }
            onClick={() => handleIconClick(CampaignScore.neutral)}
            fluid
            disabled={loading}
          >
            <Typography type="heading-3">😐</Typography>
          </Button>
          <Button
            color={
              netPromoterScore?.score === CampaignScore.bad
                ? 'primary'
                : 'inverse'
            }
            onClick={() => handleIconClick(CampaignScore.bad)}
            fluid
            disabled={loading}
          >
            <Typography type="heading-3">🙁</Typography>
          </Button>
        </div>

        {netPromoterScore?.score !== undefined && (
          <div className="mt-2 mb-4 md:mb-6">
            {isFormOpen ? (
              <div>
                <Form form={form} onValid={handleCommentSubmit}>
                  <Form.Field name="comment" label={t('addComment')}>
                    <FormInputTextArea
                      placeholder={t('placeholder')}
                      charCountMessage={(count: number) =>
                        t('charactersLeft', { count })
                      }
                      className="min-h-28"
                      resizable
                    />
                  </Form.Field>
                  <div className="flex gap-4 mt-6">
                    <Button
                      fluid
                      color="neutral"
                      variant="stroke"
                      onClick={cancelChange}
                    >
                      {t('cancel')}
                    </Button>
                    <Button fluid type="submit">
                      {t('save')}
                    </Button>
                  </div>
                </Form>
              </div>
            ) : (
              <>
                {netPromoterScore.comment ? (
                  <>
                    <Typography type="large" bold asChild>
                      <p>{t('yourComment')}</p>
                    </Typography>
                    <Typography type="large" italic asChild>
                      <p>&quot;{netPromoterScore.comment}&quot;</p>
                    </Typography>
                    <div className="flex gap-4 mt-6">
                      <Button
                        fluid
                        color="danger"
                        variant="stroke"
                        onClick={() => void deleteComment()}
                      >
                        {t('delete')}
                      </Button>
                      <Button
                        fluid
                        color="neutral"
                        variant="stroke"
                        onClick={() => setFormOpen(true)}
                      >
                        {t('edit')}
                      </Button>
                    </div>
                  </>
                ) : (
                  <Button
                    fluid
                    color="neutral"
                    variant="stroke"
                    onClick={() => setFormOpen(true)}
                  >
                    {t('addComment')}
                  </Button>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
