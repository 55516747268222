import { createFileRoute, notFound } from '@tanstack/react-router';

export const Route = createFileRoute('/_authenticated-layout/home/faq/')({
  loader: ({ context }) => {
    const company = context.viewer?.company;

    if (!company) {
      throw notFound();
    }

    return { company };
  },
});
