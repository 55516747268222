import { IconSizes } from '@src/components/atoms/Icon/Icon';
import { cx } from 'class-variance-authority';

import './Loader.css';

export type LoaderProps = {
  type?: 'dark' | 'light';
  size?: keyof typeof IconSizes;
  className?: string;
};

export const Loader = ({
  type = 'dark',
  size = 'large',
  className,
}: LoaderProps) => {
  return (
    <div
      className={cx(className, 'loader', {
        /* COLORS */
        ['loader--dark']: type === 'dark',
        ['loader--light']: type === 'light',

        /* SIZE */
        ['loader--small']: size === 'small',
        ['loader--medium']: size === 'medium',
        ['loader--large']: size === 'large',
      })}
    >
      <div className="loader__item" />
      <div className="loader__item" />
    </div>
  );
};
