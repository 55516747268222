import { Typography } from '@happypal-tech/design-system';
import { useAnalytics } from '@src/hooks/use-analytics';
import { useFormatPrice } from '@src/utils/hooks/useFormatPrice';
import { Link } from '@tanstack/react-router';
import { cx } from 'class-variance-authority';

import SmallPigImage from './assets/SmallPigImage@2x.png';
import { ViewerRemainingSubventionProxy } from './ProxyRemainingSubventionsButtons';

interface RemainingSubventionsHeaderButtonProps {
  amount: number;
  className?: string;
  onClick?: () => void;
}

export const RemainingSubventionsHeaderButton = (
  props: RemainingSubventionsHeaderButtonProps,
) => {
  const { amount, className, onClick } = props;
  const formatPrice = useFormatPrice();
  const formattedAmount = formatPrice(amount);

  return (
    <Link
      to="/advantages"
      onClick={onClick}
      className={cx(
        'rounded-xl px-3 py-2 transition-colors duration-300 ease-out',
        className,
        {
          'bg-neutral-lightest text-neutral-dark hover:bg-neutral-lighter   ':
            amount === 0,
          'bg-primary-lightest text-primary-dark  hover:bg-primary-lighter':
            amount > 0,
        },
      )}
    >
      <div className="flex items-center justify-center gap-2 p-0 text-inherit">
        <img
          src={SmallPigImage}
          alt="Small Pig"
          className="h-6 w-6 object-contain"
        />
        <Typography
          type="heading-4"
          className={cx('font-extrabold text-inherit')}
        >
          {formattedAmount}
        </Typography>
      </div>
    </Link>
  );
};

interface RemainingSubventionsHeaderButtonWithProxyProps {
  className?: string;
}

const RemainingSubventionsHeaderButtonWithProxy = (
  props: RemainingSubventionsHeaderButtonWithProxyProps,
) => {
  const { className } = props;
  const { track } = useAnalytics();
  return (
    <ViewerRemainingSubventionProxy>
      {(amount) => (
        <RemainingSubventionsHeaderButton
          className={className}
          amount={amount}
          onClick={() => {
            track({
              type: 'header subventions click',
              payload: {
                subsidy_amount_available: amount,
              },
            });
          }}
        />
      )}
    </ViewerRemainingSubventionProxy>
  );
};

RemainingSubventionsHeaderButton.withProxy =
  RemainingSubventionsHeaderButtonWithProxy;
