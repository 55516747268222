import { useTranslation } from 'react-i18next';

import { OfferDiscount } from '@components/molecules/OfferInfos/OfferReduction/OfferDiscount/OfferDiscount';
import { OfferVoucher } from '@components/molecules/OfferInfos/OfferReduction/OfferVoucher/OfferVoucher';
import { Chip } from '@happypal-tech/design-system';
import { Text } from '@src/components/atoms/Text';
import { getOfferPictoInfos, getOfferType } from '@utils/offerUtils';
import { cx } from 'class-variance-authority';

import { OfferInfosSubventionFragment } from '../OfferInfos.generated';

import { OfferReductionFragment } from './OfferReduction.generated';

type OfferReductionProps = {
  offer: OfferReductionFragment;
  variantOutOfStock: boolean | null;
  subventionEdge?: OfferInfosSubventionFragment;
};

export const OfferReduction = (props: OfferReductionProps) => {
  const { offer, variantOutOfStock, subventionEdge } = props;
  const { t } = useTranslation();

  const pictoValue = getOfferPictoInfos(offer.__typename, t);
  const offerType = getOfferType(offer.__typename);
  const displayVoucher = ['coupon', 'internal'].includes(offerType);

  return (
    <div className="flex w-full flex-col flex-nowrap">
      {/* Offer title */}
      <Text
        type="custom"
        className={cx(
          'w-8/12 font-display text-lg font-bold leading-6 md:w-auto md:text-2xl',
          { 'text-grey': variantOutOfStock, 'text-black': !variantOutOfStock },
        )}
      >
        {offer.name}
      </Text>
      {variantOutOfStock && (
        <p className="mt-4 text-tiny font-semibold text-red">
          {t('campaignDetails.offers.offer.product.quantity.outOfStock')}
        </p>
      )}

      <div
        className={`mt-4 flex flex-grow flex-row items-center justify-between`}
      >
        <div>
          <Chip
            label={pictoValue.t_key}
            iconLeft={pictoValue.icon}
            color="black"
          />
        </div>
        {displayVoucher && !!offer.voucher && (
          <OfferVoucher voucher={offer.voucher} />
        )}
        {(offer.__typename === 'OfferGiftCard' ||
          offer.__typename === 'OfferTicket') && (
          <OfferDiscount
            product={offer.product}
            subventionEdge={subventionEdge}
          />
        )}
      </div>
    </div>
  );
};
