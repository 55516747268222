import { Typography } from '@happypal-tech/design-system';
import { useFormatPrice } from '@src/utils/hooks/useFormatPrice';

type DiscountBadgeProps = {
  discount: FlatDiscount | PercentDiscount | RewardDiscount;
};

type FlatDiscount = {
  amount: number;
};

type PercentDiscount = {
  percent: number;
};

type RewardDiscount = {
  quantity: number;
  description: string;
};

export const DiscountBadge = ({ discount }: DiscountBadgeProps) => {
  const formatPrice = useFormatPrice();

  const renderDiscountContent = () => {
    if ('amount' in discount) {
      return (
        <Typography type="subtitle" bold>
          {`-${formatPrice(discount.amount)}`}
        </Typography>
      );
    }
    if ('percent' in discount) {
      return (
        <Typography type="subtitle" bold>
          {`-${Math.round(discount.percent)}%`}
        </Typography>
      );
    }
    if ('quantity' in discount) {
      return (
        <Typography type="subtitle" semibold>
          {discount.quantity > 0 && (
            <span className="font-bold">{discount.quantity} </span>
          )}
          {discount.description}
        </Typography>
      );
    }
  };
  const discountContent = renderDiscountContent();

  return (
    <div className="flex flex-col items-center justify-center rounded-bl-lg rounded-tl-2xl bg-white py-1 px-2 text-center shadow-md max-w-60">
      {discountContent}
    </div>
  );
};
