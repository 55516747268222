import {
  ComponentProps,
  forwardRef,
  KeyboardEvent,
  useCallback,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useConfigure,
  useHits,
  useInstantSearch,
  useSearchBox,
} from 'react-instantsearch';

import {
  Button,
  InputText,
  Modal,
  Typography,
} from '@happypal-tech/design-system';
import { CatalogEmptyState } from '@src/features-new/core/CatalogEmptyState/CatalogEmptyState';
import { ButtonResetSuggestions } from '@src/features-new/core/CatalogSearchBar/ButtonResetSuggestions';
import { CatalogSearchBarEvents } from '@src/features-new/core/CatalogSearchBar/events';
import { hasStringQuery } from '@src/features-new/core/CatalogSearchBar/hasStringQuery';
import { onListKeyDownNavigation } from '@src/features-new/core/CatalogSearchBar/onListKeyDownNavigation';
import { useSuggestionsComboboxQuery } from '@src/features-new/core/CatalogSearchBar/SuggestionsCombobox.generated';
import {
  Suggestion,
  SuggestionsEntry,
} from '@src/features-new/core/CatalogSearchBar/SuggestionsEntry';
import { Link } from '@tanstack/react-router';
import { cx } from 'class-variance-authority';
import { debounce } from 'radash';

interface SuggestionsDrawerProps
  extends Pick<ComponentProps<typeof Modal.Drawer>, 'opened'>,
    Pick<
      ComponentProps<typeof InputText>,
      'append' | 'onKeyDown' | 'placeholder' | 'className'
    >,
    CatalogSearchBarEvents {
  search?: string;
  onSearch?: (value: string) => void;
  onBlur?: () => void;
  onOpenChange: (nextIsOpen: boolean) => void;
}

export const SuggestionsDrawer = forwardRef<
  HTMLInputElement,
  SuggestionsDrawerProps
>(
  (
    {
      opened,
      onOpenChange,
      className,
      placeholder,
      search,
      onSearch,
      append,
      onKeyDown,
      onBlur,
      onExploreCampaign,
      onClickCompleteSearch,
      onClickSeeAll,
    },
    forwardedRef,
  ) => {
    const { t } = useTranslation('core', {
      keyPrefix: 'components.CatalogSearchBar',
    });
    const listLabelId = useId();

    const drawerInputRef = useRef<HTMLInputElement>(null);
    const [drawerSearch, setDrawerSearch] = useState(search ?? '');
    const itemsRef = useRef<HTMLAnchorElement[]>([]);
    const onItemRef = (index: number) => (node: HTMLAnchorElement | null) => {
      if (node === null) return;
      itemsRef.current[index] = node;
    };

    const [favoriteCampaignIds, setFavoriteCampaignIds] = useState<string[]>(
      [],
    );

    const { hits, results } = useHits<Suggestion>();
    const { nbHits, query } = results ?? { nbHits: 0, query: undefined };

    const { data } = useSuggestionsComboboxQuery({
      variables: {
        campaignIds: hits.map((el) => el.objectID),
      },
      fetchPolicy: 'cache-and-network',
      skip: hits.length === 0,
    });

    useEffect(() => {
      if (data) {
        // we use a state to prevent child component blink when data is undefined
        setFavoriteCampaignIds(
          data.campaignCardInformations
            .filter((el) => el.isFavorite === true)
            .map((el) => el.campaignId),
        );
      }
    }, [data, setFavoriteCampaignIds]);

    useSearchBox();
    const { setIndexUiState } = useInstantSearch();

    const refine = useCallback(
      (query: string) => {
        setIndexUiState({
          query,
        });
      },
      [setIndexUiState],
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const refineDebounced = useCallback(debounce({ delay: 400 }, refine), []);

    const handleSearch = useCallback(
      (nextSearch: string) => {
        setDrawerSearch(nextSearch);
        refineDebounced(nextSearch);
      },
      [setDrawerSearch, refineDebounced],
    );

    useConfigure({
      offset: 0,
      length: 4,
    });

    const handleOpenChange = (nextOpened: boolean) => {
      onOpenChange(nextOpened);
      if (nextOpened === false) {
        onBlur?.();
        return;
      }
    };

    const onFocus = () => {
      onOpenChange(true);
      requestAnimationFrame(() => {
        drawerInputRef.current?.focus();
      });
    };

    const onClearClick = () => {
      handleSearch('');
    };

    const onClickItem = () => {
      onExploreCampaign();
      handleSearch('');
      handleOpenChange(false);
    };

    const onClickSeeResults = () => {
      onOpenChange(false);
      if (nbHits > 0 && hasStringQuery(query)) {
        return onClickCompleteSearch(query);
      }

      handleSearch('');
      onSearch?.('');
      return onClickSeeAll();
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        onOpenChange(!opened);
      }
      return onKeyDown?.(e);
    };

    useEffect(() => {
      if (opened) {
        handleSearch(search ?? '');
      }
    }, [opened, handleSearch, search]);

    return (
      <>
        <InputText
          ref={forwardedRef}
          onFocus={onFocus}
          append={append}
          className={className}
          value={search}
          placeholder={placeholder}
        />
        <Modal.Drawer
          title=""
          description=""
          descriptionHidden
          opened={opened}
          onOpenChange={handleOpenChange}
          rootClassName="z-drawer"
          className="h-full"
          wrapperClassName="flex pb-0"
          childrenClassName="mt-0 flex flex-col flex-grow gap-2 items-start"
        >
          <Button
            type="button"
            color="neutral"
            variant="ghost"
            onClick={() => {
              handleOpenChange?.(false);
            }}
          >
            {t('suggestions.cancel')}
          </Button>
          <div className="flex flex-col gap-6 w-full grow">
            <InputText
              ref={drawerInputRef}
              placeholder={placeholder}
              onChange={handleSearch}
              append={
                <ButtonResetSuggestions
                  className={cx(drawerSearch === '' && 'hidden')}
                  icon="CrossOutline"
                  variant="ghost"
                  size="small"
                  color="neutral"
                  aria-label={t('clear')}
                  onClick={onClearClick}
                />
              }
              value={drawerSearch}
              onKeyDown={handleKeyDown}
            />
            <Typography
              type="caption"
              semibold
              asChild
              className="mb-3 text-neutral-dark"
            >
              <p id={listLabelId}>{t('suggestions.title')}</p>
            </Typography>
            {query !== '' && (
              <>
                {hits.length > 0 ? (
                  <ul role="listbox" aria-labelledby={listLabelId}>
                    {hits.map(
                      (
                        {
                          objectID,
                          name,
                          types,
                          categories,
                          campaignThumbnailUrl,
                        },
                        index,
                      ) => (
                        <li
                          key={objectID}
                          className={cx(
                            'flex items-center gap-3',
                            'cursor-pointer rounded-[10px] px-3 py-2 transition-colors',
                            'text-neutral-dark',
                            'hover:bg-neutral-lightest hover:text-neutral-darkest',
                            'focus-within:bg-neutral-lightest focus-within:text-neutral-darkest',
                          )}
                        >
                          <Link
                            ref={onItemRef(index)}
                            onKeyDown={onListKeyDownNavigation(itemsRef)}
                            key={objectID}
                            to="/explore/$campaignId"
                            params={{ campaignId: objectID }}
                            onClick={onClickItem}
                            className="flex-1"
                          >
                            <SuggestionsEntry
                              name={name}
                              types={types}
                              categories={categories}
                              campaignThumbnailUrl={campaignThumbnailUrl}
                              inputValue={search ?? ''}
                              isFavorite={favoriteCampaignIds.includes(
                                objectID,
                              )}
                              campaignId={objectID}
                            />
                          </Link>
                        </li>
                      ),
                    )}
                  </ul>
                ) : (
                  <CatalogEmptyState search={query} />
                )}
              </>
            )}
          </div>
          <div className="sticky bottom-0 pb-8 bg-white w-full">
            <Button
              type="button"
              variant="plain"
              iconRight={nbHits > 0 ? 'SearchOutline' : 'ArrowRightOutline'}
              onMouseDown={(e) => {
                e.preventDefault();
              }}
              onClick={onClickSeeResults}
              fluid
            >
              {nbHits > 0
                ? t('suggestions.seeAllResults', { count: nbHits })
                : t('suggestions.seeAllPerks')}
            </Button>
          </div>
        </Modal.Drawer>
      </>
    );
  },
);
