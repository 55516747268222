import { OfferEmphasiedOff } from '@components/molecules/OfferInfos/OfferReduction/OfferEmphasiedOff';
import { formatPrice } from '@src/utils/textUtils';

import { OfferVoucherFragment } from './OfferVoucher.generated';

type OfferVoucherProps = {
  voucher: OfferVoucherFragment;
};

export const OfferVoucher = (props: OfferVoucherProps) => {
  const { voucher } = props;

  switch (voucher.__typename) {
    case 'VoucherReward':
      return (
        <OfferEmphasiedOff
          type="reward"
          reward={voucher.reward}
          quantity={voucher.quantity}
        />
      );
      break;
    case 'VoucherPercentDiscount':
      return (
        <OfferEmphasiedOff type="percent" valueOff={voucher.percentAmount} />
      );
      break;
    case 'VoucherFlatDiscount':
      return (
        <OfferEmphasiedOff
          type="amount"
          valueOff={voucher.flatAmount}
          currency={voucher.currency}
        />
      );
      break;
    case 'VoucherAmountCurrencyUpdate':
      return (
        <div className="flex items-center md:text-right">
          <span className="mr-3 font-display text-sm font-semibold text-grey line-through">
            {formatPrice(voucher.previousAmount, voucher.currency)}
          </span>
          <span className="font-display text-xl font-bold text-grey">
            {formatPrice(voucher.nextAmount, voucher.currency)}
          </span>
        </div>
      );
      break;
    default:
      return null;
  }
};
