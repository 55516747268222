import { useTranslation } from 'react-i18next';

import { PlaceFilter } from '@components/organisms/PlaceFilter';

import { OffersListPlaceFilterModuleLocationFragment } from './OffersListPlaceFilterModule.generated';

type OffersListPlaceFilterModuleProps = {
  locations: OffersListPlaceFilterModuleLocationFragment[];
  onChange: (city: string | null) => void;
  value?: string | null;
};

export function OffersListPlaceFilterModule(
  props: OffersListPlaceFilterModuleProps,
) {
  const { onChange, value, locations } = props;
  const { t } = useTranslation();

  const options = locations.map((l) => ({
    label: l.value || '',
    value: l.value || '',
  }));

  return (
    <div className="mb-12 px-4 lg:px-0">
      <h3 className="mb-4 font-display text-md font-bold">
        {t(
          'campaignDetails.offers.offer.filters.location.title',
          'Sélectionnez votre ville pour bénéficier du tarif en vigueur.',
        )}
      </h3>
      <PlaceFilter
        current={value || ''}
        options={options}
        onChange={(v) => onChange(v?.value || null)}
        onReset={() => onChange(null)}
        withGeolocation={false}
        placeholder={t(
          'campaignDetails.offers.offer.filters.location.placeholder',
          'Ex : Lyon',
        )}
      />
    </div>
  );
}
